import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/core/api/api.service';
import { ColorManipulationService } from 'src/app/core/color-manipulation/color-manipulation.service';
import { DataService } from 'src/app/core/data/data.service';
import { ObjectInitializationService } from 'src/app/core/object-initialization/object-initialization.service';
import { UtilsService } from 'src/app/core/utils/utils.service';
import { IUserSessionData } from 'src/app/shared/models/interfaces/iuser-session-data.model';
import { IChartConfigData } from 'src/app/shared/models/interfaces/iutil.model';
import { DeviceDetailsByYearComponent } from '../../dialogs/device-details-by-year/device-details-by-year.component';
import { ChartTypeChartjs, RequestMethodHTTP } from 'src/app/shared/enums/common-enums.enum';
import { ValidationService } from 'src/app/core/validation/validation.service';
import { ApiEndpointSgc } from 'src/app/shared/enums/api-endpoint-sgc.enum';

interface ISummaryDevicesGroupedByDeviceModel{
  device_model: string,
  total: number
}
interface ISummaryDevicesGroupedByTelephoneOperator{
    telephone_operator_name: string,
    telephone_operator_id: number,
    total: number

}
interface IDeviceBillingPlansSummary{
  unit_price: string,
  code: string,
  total: number
}
@Component({
  selector: 'app-home-manager-user',
  templateUrl: './home-manager-user.component.html',
  styleUrls: ['./home-manager-user.component.scss']
})
export class HomeManagerUserComponent implements OnInit {
    
  loading: boolean = false;//para loading
  user: IUserSessionData;

  totalDevices: number = 0;//para indicar el total de vehiculos en el encabezado para usuarios gerentes

  linkedVehicleData: any = {};//para el grafico de lineas
  QuantityvehicleData: any = [];//array para almacenar los valores de cantidad de vehiculos por mes de cada año, es decri 2022-01,2022-02
  allVehicleData: any = [];//almacena todos los datos traidos de la solicitud a intgps

  filterByYear: number = 0;//para filtrar por año en grafico lineal
  maxYear: number = 0;//para indicar el maximo a ño a filtrar en el grafico lineal

  //grafico de lineas
  VehiclesPerMonthAndYear: any = {};
  allDevicesListByMonth: any = {};//almacena la lista de meses con sus respectivos dispositivos
  devicesListByMonthForChart: any[] = [];//almacena la lista de meses con sus respectivos dispositivos pero solo del año mostrado en el grafico

  linkingChartDataIndexes: any = [{ name: "new", index: 0 }, { name: "renewed", index: 1 }, { name: "expired", index: 2 }, { name: "unlimited", index: 3 }];
  lineChartDatasetItems = [
    { label: "Nuevos", color: '#44aa44' }, { label: "Renovados", color: '#FFC300' }, { label: "Expirados", color: '#E74C3C' }
  ];
  public onLineChartClick(event: any, array: any) {
    if (array.length > 0) {
      let dataToShow: any = [];
      for (let set of array) {
        let data: any = [];
        const dataSetIndex: number = set.datasetIndex;
        const indexInDataset: number = set.index;
        let key: string = Object.keys(this.allVehicleData).sort()[indexInDataset];
        data = this.allVehicleData[key][this.linkingChartDataIndexes[dataSetIndex].name];
        if (typeof data !== "undefined")
          dataToShow = dataToShow.concat(data);
      }
      if (dataToShow.length < 1)
        return;
      const dialogRef = this.matDialog.open(DeviceDetailsByYearComponent, { data: dataToShow });
      dialogRef.afterClosed().subscribe((response: boolean) => {  });
    }
  }
  //fin grafico de lineas

  /** @type {IChartConfigData} Configuracion de datos para grafico  de vehiculos segun su tipo */
  configVehicleChartAccordingToType!:IChartConfigData;
  configDevicesByLinkingStatus!: IChartConfigData;
  
  /** @type {IChartConfigData} Configuracion de datos para grafico  de resumen de planes de facturacion */
  configDeviceBillingPlanSummary!:IChartConfigData;
  
  summaryDevicesGroupedByDeviceModel: ISummaryDevicesGroupedByDeviceModel[] = [];
  configDevicesGroupedByDeviceModel!:IChartConfigData;

    
  summaryDevicesGroupedByTelephoneOperator: ISummaryDevicesGroupedByTelephoneOperator[] = [];
  configDevicesGroupedByTelephoneOperator!:IChartConfigData;

  constructor(
    private api: ApiService,
    public dataService: DataService,
    private utils: UtilsService,
    private router: Router,
    public matDialog: MatDialog,
    private colorManipulationService:ColorManipulationService,
    private objectInitializationService: ObjectInitializationService,
    private validationService: ValidationService
  ){
    this.user = this.dataService.getData("user");
  }
  ngOnInit(): void {
    
    this.configVehicleChartAccordingToType = this.objectInitializationService.initializeIChartConfigData("Vehículos según su tipo", ChartTypeChartjs.BAR,
    {labels:[],datasets:[]}, false, false);

    this.configDeviceBillingPlanSummary = this.objectInitializationService.initializeIChartConfigData("Dispositivos según el precio de plan de facturación", ChartTypeChartjs.BAR,
      {labels:[],datasets:[]}, false, false);

    this.configDevicesByLinkingStatus = this.objectInitializationService.initializeIChartConfigData("Dispositivos según estado de vinculación", ChartTypeChartjs.LINE,
    {labels:[],datasets:[]}, false);

    this.configDevicesGroupedByDeviceModel = this.objectInitializationService.initializeIChartConfigData("Dispositivos según su marca", ChartTypeChartjs.BAR,
    {labels:[],datasets:[]}, false,false);

    this.configDevicesGroupedByTelephoneOperator = this.objectInitializationService.initializeIChartConfigData("Dispositivos según su operador móvil", ChartTypeChartjs.PIE,
    {labels:[],datasets:[]}, false,false);

    this.filterByYear = new Date().getFullYear();
    this.maxYear = this.filterByYear;

    for (let set of this.lineChartDatasetItems) {
      this.configDevicesByLinkingStatus.data.datasets.push(
        {
          data: [],
          label: set.label,
          borderColor: set.color,
          backgroundColor: set.color,
          pointBackgroundColor: set.color,
          pointBorderColor: set.color,
          pointHoverBackgroundColor: set.color,
          pointHoverBorderColor: set.color,
          fill: false,
          pointRadius: 5,
          pointHoverRadius(ctx: any, options: any) {
            return 8;
          },
        }
      );
    }
    this.configVehicleChartAccordingToType.data.datasets.push( this.initializeBarChartDataset("Tipo de vehículo") );
    this.configDeviceBillingPlanSummary.data.datasets.push( this.initializeBarChartDataset("Dispositivos según el Plan de facturación") );
    this.configDevicesGroupedByDeviceModel.data.datasets.push( this.initializeBarChartDataset("Marca de dispositívo") );
    
    this.configDevicesGroupedByTelephoneOperator.data.datasets.push(
      {
        data: [],
        label: "Operador móvil de dispositivo",
        borderColor: this.colorManipulationService.DEFAULT_COLORS,
        backgroundColor: this.colorManipulationService.DEFAULT_COLORS,
        pointBackgroundColor: this.colorManipulationService.DEFAULT_COLORS,
        pointBorderColor: this.colorManipulationService.DEFAULT_COLORS,
        pointHoverBackgroundColor: this.colorManipulationService.DEFAULT_COLORS,
        pointHoverBorderColor: this.colorManipulationService.DEFAULT_COLORS,
        fill: false
      }
    );
    this.configDevicesByLinkingStatus.chart_options!.onClick = this.onLineChartClick.bind(this);
    this.getList();
  }

    
  async getList(): Promise<void> {
    this.loading = true;
    let data = "?&user_api_hash=" + this.user.hash;
    try {
      let [responseDevicesByLinkingStatus, responseGroupedByDeviceModel, responseQuantityByVehicleType, responseGroupedByTelephoneOperator, responseDeviceBillingPlansSummary] = await Promise.all([
        this.api.getData( ApiEndpointSgc.DEVICE, data + "&action=getByLinkingStatus"+"&get_expired_adjusted_to_expiry=true&group_by_observation=true&year=" + this.filterByYear, true), 
        this.api.getData( ApiEndpointSgc.DEVICE, data + "&action=getDeviceCountGroupedByDeviceModel", true),
        this.api.getData( ApiEndpointSgc.DEVICE, "?action=showQuantityByVehicleType&user_id=" + this.user.id + "&user_api_hash=" + this.user.hash, true),
        this.api.getData( ApiEndpointSgc.DEVICE, data + "&action=getDeviceCountGroupedByTelephoneOperator", true),
        this.api.getDataPost( ApiEndpointSgc.PERSONALIZED_PLAN , {user_api_hash:this.user.hash, action: "getDeviceBillingPlansSummary"}, true),
      ]);
      if(this.validationService.isResponseApi(responseDevicesByLinkingStatus) && responseDevicesByLinkingStatus.status == 1){
        this.allVehicleData = typeof responseDevicesByLinkingStatus.data != "undefined" ? responseDevicesByLinkingStatus.data : {};
        this.totalDevices = responseDevicesByLinkingStatus.total ?? 0;

        let devicesByLinkingStatus: any = { renewed: [], expired: [], new: [] };
        let keys: any = Object.keys(this.allVehicleData).sort();
        const date = new Date();
        let monthsPassed: number = this.filterByYear < date.getFullYear() ? 12 : date.getMonth() + 1;
        for (let iteratedMonths: number = 0; iteratedMonths < monthsPassed; iteratedMonths++) {
          devicesByLinkingStatus.renewed.push(!this.utils.isNullOrEmpty(this.allVehicleData[keys[iteratedMonths]].renewed) ? this.allVehicleData[keys[iteratedMonths]].renewed.length : 0);
          devicesByLinkingStatus.expired.push(!this.utils.isNullOrEmpty(this.allVehicleData[keys[iteratedMonths]].expired) ? this.allVehicleData[keys[iteratedMonths]].expired.length : 0);
          devicesByLinkingStatus.new.push(!this.utils.isNullOrEmpty(this.allVehicleData[keys[iteratedMonths]].new) ? this.allVehicleData[keys[iteratedMonths]].new.length : 0);
        }
        this.linkedVehicleData = 0;
        this.configDevicesByLinkingStatus.data.datasets[0].data = devicesByLinkingStatus.new;
        this.configDevicesByLinkingStatus.data.datasets[1].data = devicesByLinkingStatus.renewed;
        this.configDevicesByLinkingStatus.data.datasets[2].data = devicesByLinkingStatus.expired;
        this.configDevicesByLinkingStatus.data.labels = this.utils.getMonthsLabels(Object.keys(this.allVehicleData).length);
        this.configDevicesByLinkingStatus.updated_chart = true;
      }
      //else throw new Error("la lista de dispositivos según su estado de vinculación");      

      if(this.validationService.isResponseApi(responseQuantityByVehicleType) && responseQuantityByVehicleType.status == 1 ){
        let QuantityByVehicleTypeData = responseQuantityByVehicleType.data as any[];
        let vehicleTypesLabels: string[] = [];//las etiquetas usadas por el usuario para clasificar sus vehiculos

        for (let vehicleType of QuantityByVehicleTypeData) {
          (vehicleType.vehicle_type == null) ? vehicleTypesLabels.push("sin especificar") : vehicleTypesLabels.push(vehicleType.vehicle_type_name);
        }
        this.configVehicleChartAccordingToType.data.labels = vehicleTypesLabels;
        this.configVehicleChartAccordingToType.data.datasets[0].data = QuantityByVehicleTypeData.map((vehicleQuantity) => vehicleQuantity.quantity);
        this.configVehicleChartAccordingToType.updated_chart = true;
      }
      //else throw new Error("la lista de vehículos según su tipo");
      if(this.validationService.isResponseApi(responseGroupedByDeviceModel)){
        if(responseGroupedByDeviceModel.status == 1){
          this.summaryDevicesGroupedByDeviceModel = responseGroupedByDeviceModel.data;
          this.configDevicesGroupedByDeviceModel.data.labels = this.summaryDevicesGroupedByDeviceModel.map((item: ISummaryDevicesGroupedByDeviceModel)=>item.device_model);
          this.configDevicesGroupedByDeviceModel.data.datasets[0].data = this.summaryDevicesGroupedByDeviceModel.map((item: ISummaryDevicesGroupedByDeviceModel)=>item.total);
          this.configDevicesGroupedByDeviceModel.updated_chart = true;
        }
      }
      //else throw new Error("la lista de dispositivos agrupados según su modelo");

      if(this.validationService.isResponseApi(responseGroupedByTelephoneOperator)){
        if(responseGroupedByTelephoneOperator.status == 1){
          this.summaryDevicesGroupedByTelephoneOperator = responseGroupedByTelephoneOperator.data;

          //dado a que es de tipo circular, entonces se debe de convertir a procentaes
          let total: number = 0; 
          this.summaryDevicesGroupedByTelephoneOperator.forEach((value: ISummaryDevicesGroupedByTelephoneOperator)=> total += Number(value.total));
          this.configDevicesGroupedByTelephoneOperator.data.labels = this.summaryDevicesGroupedByTelephoneOperator.map((item: ISummaryDevicesGroupedByTelephoneOperator)=>item.telephone_operator_name);
          this.configDevicesGroupedByTelephoneOperator.data.datasets[0].data = this.summaryDevicesGroupedByTelephoneOperator.map((item: ISummaryDevicesGroupedByTelephoneOperator)=>Number((item.total * 100 / total).toFixed(1)));
          this.configDevicesGroupedByTelephoneOperator.updated_chart = true;

        }
      }
      //else throw new Error("la lista de dispositivos agrupados según su operador móvil");

      if(this.validationService.isResponseApi(responseDeviceBillingPlansSummary)){
        console.log(responseDeviceBillingPlansSummary);
        let deviceBillingPlansSummary = responseDeviceBillingPlansSummary.data as IDeviceBillingPlansSummary[];
        let chartLabels: string[] = [];//las etiquetas usadas para el grafico

        for (let billingPlan of deviceBillingPlansSummary) {
          let price = billingPlan.code == this.dataService.UNKNOWN_ITEM_TAG.CODE || billingPlan.unit_price == null ? 0 : Number(billingPlan.unit_price);
          console.log(price,price % 2 == 0);
          price = price % 2 == 0 ? parseInt(price.toString()) : price;
          (billingPlan.code == this.dataService.UNKNOWN_ITEM_TAG.CODE) ? chartLabels.push(this.dataService.UNKNOWN_ITEM_TAG.LABEL) : chartLabels.push(price.toLocaleString() + " " + billingPlan.code);
        }
        this.configDeviceBillingPlanSummary.data.labels = chartLabels;
        this.configDeviceBillingPlanSummary.data.datasets[0].data = deviceBillingPlansSummary.map((billingPlan: IDeviceBillingPlansSummary) => billingPlan.total);
        this.configDeviceBillingPlanSummary.updated_chart = true;
      }
    } catch (error:unknown) {
      if(error instanceof Error)
        this.utils.showResultRequest("error","Información",this.api.getDefaultMessage(error.message??"los gráficos",true, false,RequestMethodHTTP.GET));
    }finally{
      this.utils.hideLoading( () => this.loading =false);
    }
  }
  applyFilter(event: Event) {
    this.configDevicesByLinkingStatus.updated_chart = false;
    event.preventDefault();
    this.getList();
  }
  /**
   * Inicializa el dataset para los graficos de barras
   * @returns Ini
   */
  initializeBarChartDataset(title: string){
    return {
      data: [],
      label: title,
      borderColor: this.colorManipulationService.DEFAULT_COLORS[0],
      backgroundColor: this.colorManipulationService.DEFAULT_COLORS,
      pointBackgroundColor: this.colorManipulationService.DEFAULT_COLORS[0],
      pointBorderColor: this.colorManipulationService.DEFAULT_COLORS[0],
      pointHoverBackgroundColor: this.colorManipulationService.DEFAULT_COLORS[0],
      pointHoverBorderColor: this.colorManipulationService.DEFAULT_COLORS[0],
      fill: false
    };
  }
}