<div class="login-box animate__animated animate__fadeIn " style="position:relative;">
    <div class="container">
        <div class="login-image">
            <img src="../../assets/img/{{fileNameToLoad}}" alt="logo inicio de sesión">
        </div>
        <form class="login-form position-relative" enctype="multipart/form-data" (submit)="login($event)">
            <h2 class="login-title">Iniciar Sesión</h2>
            <div class="forms-inputs">
                <div class="row">
                    <label for="inputEmail" class="label">Correo electrónico</label>
                    <div class="input-group">
                        <label for="inputEmail"><i class="fa-solid fa-envelope"></i></label>
                        <input id="inputEmail" type="email" name="email" [(ngModel)]="userData.email">
                    </div>
                    
                </div>
                <div class="row">
                    <label for="inputPassword" class="label">Contraseña</label>
                    <div class="input-group">
                        <label for="inputPassword"><i class="fa-solid fa-lock"></i></label>
                        <input id="inputPassword"  autocomplete="off" class="input-password" [type]="typePassword" name="password">
                        <label class="view-password" *ngIf="typePassword=='text'" (click)="changeTypePasswordInput()" matTooltip="ocultar contraseña"><i class="fa-solid fa-eye"></i></label>
                        <label class="view-password" *ngIf="typePassword=='password'" (click)="changeTypePasswordInput()" matTooltip="ver contraseña"><i class="fa-solid fa-eye-slash"></i></label>
                    </div>
                </div>
                <div class="row justify-content-end">
                    <button class="btn" [disabled]="loading">Ingresar</button>
                    <a (click)="goToSpecialLogin(dataService.SPECIAL_USER.user_driver)" aria-label="Ir a login de conductor" class="link link-white">Soy conductor <mat-icon class="icon rotate-y-180">arrow_back</mat-icon></a>
                </div>
            </div>
            <div class="loader-login animate__animated animate__bounceIn" *ngIf="loading">
                <img src="../../assets/loader.svg">
            </div>
        </form>
    </div>
</div>