import { Component,OnInit,Inject } from '@angular/core';
import { MatDialog,MAT_DIALOG_DATA } from "@angular/material/dialog";
@Component({
  selector: 'app-dialog-user-devices-by-expiration',
  templateUrl: './dialog-user-devices-by-expiration.component.html',
  styleUrls: ['./dialog-user-devices-by-expiration.component.scss']
})
export class DialogUserDevicesByExpirationComponent   implements OnInit{
  userDevices:any=[];
  title:string="";
  constructor(
    @Inject(MAT_DIALOG_DATA) public data:any    
  ){
  }
  ngOnInit(): void {
    this.title =this.data.title;
    this.userDevices=this.data.userDevices;

  }
}
