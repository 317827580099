<section class="form-box-container animate__animated animate__fadeIn">
    <section class="form-box-container__header">
      <span class="form-box-container__header-back" (click)="closeComponent()"><span class="description">Volver</span> </span>
        <h2 class="form-box-container__header-title"><span class="icon"><i class="fa-brands fa-whatsapp text-primary"></i></span>Lineas registradas</h2>
        <div  class="form-box-container__header-options"></div>
    </section>
    <div class="form-box animate__animated animate__fadeIn">
      <section class="form-box__content form-box__content-h-auto" >
        <div class="w100 overflow-auto">
          <table mat-table matSort (matSortChange)="sortData($event)" [dataSource]="phoneLineList">
    
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Ordenar por nombre"> Cuenta</th>
              <td mat-cell *matCellDef="let phoneLine">
                <mat-form-field appearance="outline" class="me-1">
                  <input matInput 
                  [maxLength]="messagingServiceApiPhoneLineService.MAX_FIELD_LENGTH.name" 
                  required="" 
                  type="text" 
                  name="phoneLineName"
                  placeholder="Ingresa un nombre" 
                  [(ngModel)]="phoneLine.name">
                  <mat-error>El campo es requerido</mat-error>
                  <mat-hint align="end">{{phoneLine.name.length || 0}}/{{messagingServiceApiPhoneLineService.MAX_FIELD_LENGTH.name }}</mat-hint>
                </mat-form-field>
                <button mat-icon-button mat-button class="text-primary" [disabled]="phoneLine.name==''" matTooltip="Guardar edición" (click)="updatePhoneLine(phoneLine)" [disabled]="loading"><mat-icon class="text-primary">edit</mat-icon></button>          
              </td>
            </ng-container>
            <ng-container matColumnDef="phone_number">
              <th mat-header-cell *matHeaderCellDef  mat-sort-header sortActionDescription="Ordenar por número de teléfono"  class="mat-header-center"> Teléfono </th>
              <td mat-cell *matCellDef="let phoneLine">
                  {{(phoneLine.phone_number !=null && phoneLine.phone_number != ''? ('+ ' + phoneLine.phone_number):'')}}   
              </td>
            </ng-container>
            <!--<ng-container matColumnDef="available_message_count">
              <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Ordenar por cantidad de mensajes disponibles"> Mensajes disponibles</th>
              <td mat-cell *matCellDef="let phoneLine" class="text-center"> 
                <span  class="badge text-bg-light-success">
                  <i class="fa-solid fa-message"></i>
                  {{ phoneLine.available_message_count??10 | number:'1.0-0' }}
                </span>
              </td>
            </ng-container>-->
            <ng-container matColumnDef="connected">
              <th mat-header-cell *matHeaderCellDef  mat-sort-header sortActionDescription="Ordenar por estado de conexión"  class="mat-header-center"> Estado de conexión </th>
              <td mat-cell *matCellDef="let phoneLine"  class="text-center"> 
                <span class="badge" 
                  [ngClass]="
                  {'text-bg-success': phoneLine.connected == phoneLineConnectionValues.CONNECTED && phoneLine.activation_status == phoneLineActivationStatus.ACTIVE , 
                  'text-bg-danger': (phoneLine.connected == phoneLineConnectionValues.DISCONNECTED && phoneLine.activation_status == phoneLineActivationStatus.ACTIVE) || phoneLine.activation_status == phoneLineActivationStatus.INACTIVE, 
                  'text-bg-warning': phoneLine.activation_status == phoneLineActivationStatus.PENDING}">
                  <ng-container *ngIf="phoneLine.activation_status == phoneLineActivationStatus.ACTIVE;else elseActivationStatus">
                    <ng-container *ngIf="phoneLine.connected == phoneLineConnectionValues.CONNECTED">
                      <span><i class="fa-solid fa-phone"></i>                      
                        {{  CONNECTION_STATUS[phoneLineConnectionValues.CONNECTED].LABEL }}</span>
                    </ng-container>
                    <ng-container *ngIf="phoneLine.connected == phoneLineConnectionValues.DISCONNECTED">
                      <span><i class="fa-solid fa-phone-slash"></i>
                        {{CONNECTION_STATUS[phoneLineConnectionValues.DISCONNECTED].LABEL}}</span>
                    </ng-container>   
                  </ng-container>
                  <ng-template #elseActivationStatus>
                    <ng-container *ngIf="phoneLine.activation_status == phoneLineActivationStatus.PENDING;then thenActivationPendingStatus ; else elseActivationInactiveStatus"></ng-container>
                    
                    <ng-template #thenActivationPendingStatus >
                      <i class="fa-solid fa-clock"></i> {{ ACTIVATION_STATUS[phoneLineActivationStatus.PENDING].LABEL }}
                    </ng-template>
                    <ng-template #elseActivationInactiveStatus>
                      <i class="fa-solid fa-ban"></i> {{ ACTIVATION_STATUS[phoneLineActivationStatus.INACTIVE].LABEL }}
                    </ng-template>
                  
                  </ng-template>                 
                </span> 
              </td>
            </ng-container>
            <ng-container matColumnDef="connect">
              <th mat-header-cell *matHeaderCellDef mat-sort-header disabled class="mat-header-center"> Conectar </th>
              <td mat-cell *matCellDef="let phoneLine"> 
                <div class="actions-container">
                  <button matRipple class="custom-action btn btn-primary"  [disabled]="phoneLine.connected == phoneLineConnectionValues.CONNECTED || phoneLine.activation_status != phoneLineActivationStatus.ACTIVE" (click)="requestQrCode(phoneLine)"> <i class="fa-solid fa-qrcode"></i></button>
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="disconnect">
              <th mat-header-cell *matHeaderCellDef class="mat-header-center"> Desconectar </th>
              <td mat-cell *matCellDef="let phoneLine"> 
                <div class="actions-container">
                  <button matRipple class="custom-action btn btn-outline-danger" [disabled]="phoneLine.connected == phoneLineConnectionValues.DISCONNECTED" (click)="disconnectPhoneLine(phoneLine)"><i class="fa-solid fa-power-off"></i></button>
                </div>
              </td>
            </ng-container>
    
            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            <tr *matNoDataRow>
              <td class="mat-cell empty py-1" [attr.colspan]="displayedColumns.length">Sin datos</td>
          </tr>
          </table>
        </div>
        <mat-paginator  [length]="pagination.length_page" [pageSize]="pagination.page_size" [pageIndex]="pagination.current_page"
          [pageSizeOptions]="pagination.page_size_options" (page)="paginator($event)"
          aria-label="Selecciona página de la lista de automatizaciones">
        </mat-paginator>
      </section>
    </div>
    <div class="loader" *ngIf="loading">
        <img src="../../../../assets/loader.svg">
    </div>
  </section>