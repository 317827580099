import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DriverDeviceStateManagementComponent } from './views/driver-device-state-management/driver-device-state-management.component';
import { DriverDeviceChecklistManagementComponent } from './views/driver-device-checklist-management/driver-device-checklist-management.component';
import { accessControlGuard } from '../core/guards/access-control/access-control.guard';
import { RouteConstant } from '../core/constant/RouteConstant';

const routes: Routes = [
  { path: '', 
    children:[
      {
        path:RouteConstant.DRIVER_DEVICE_STATE_MANAGEMENT.url, component: DriverDeviceStateManagementComponent,
        canActivate:[accessControlGuard],data:{componentId:RouteConstant.DRIVER_DEVICE_STATE_MANAGEMENT.id},
      },
      {
        path:RouteConstant.DRIVER_DEVICE_CHECKLIST_MANAGEMENT.url, component: DriverDeviceChecklistManagementComponent,
        canActivate:[accessControlGuard],data:{componentId:RouteConstant.DRIVER_DEVICE_CHECKLIST_MANAGEMENT.id},
      }
    ]
  }
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UserDriverRoutingModule { }
