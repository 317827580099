import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardRoutingModule } from './dashboard-routing.module';

import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { WebTemplateEditMenuComponent } from './menus/web-template-edit-menu/web-template-edit-menu.component';
import {MatMenuModule} from '@angular/material/menu';
import { UserDriverModule } from '../user-driver/user-driver.module';
import { AngularMaterialModule } from '../angular-material.module';

@NgModule({
  declarations: [
    WebTemplateEditMenuComponent
  ],
  imports: [
    CommonModule,
    DashboardRoutingModule,
    HttpClientModule,
    MatMenuModule,
    FormsModule,
    UserDriverModule,
    AngularMaterialModule
  ],
  exports:[WebTemplateEditMenuComponent]
})
export class DashboardModule { }
