<div class="search">
    <mat-form-field id="searchSelectionData" appearance="fill"matTooltip="Selecciona el dispositivo a usar">
        <mat-select (closed)="clearSearchDevice()" [value]="currentDrivenVehicle.id" (selectionChange)="changeAvailability($event)" placeholder="Buscar...">
        <mat-option><!--           [hideClearSearchButton]="true"  -->
            <ngx-mat-select-search 
            [noEntriesFoundLabel]="'Sin resultados'" 
            placeholderLabel="Buscar dispositivo a ocupar..." 
            [(ngModel)]="dataToSearch" 
            (keyup)="searchDevice()" 
            name="dataToSearch">
            <mat-icon (click)="clearSearchDevice()" ngxMatSelectSearchClear>close</mat-icon>
            </ngx-mat-select-search>
        </mat-option>
        <mat-option [disabled]="(device.current_driver_id != null && device.current_driver_id != user.id)" *ngFor="let device of availableDeviceListFiltered" [value]="device.id"  color="primary">{{device.name + (!validationService.isNullOrEmpty(device.plate_number) ? (" ("+device.plate_number+")") : "")}}</mat-option>                      
        </mat-select>
    </mat-form-field>
</div>