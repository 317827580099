import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/core/api/api.service';
import { DataService } from 'src/app/core/data/data.service';
import { MessageBoxService } from 'src/app/core/message-box/message-box.service';
import { ObjectInitializationService } from 'src/app/core/object-initialization/object-initialization.service';
import { UtilsService } from 'src/app/core/utils/utils.service';
import { ValidationService } from 'src/app/core/validation/validation.service';
import { IReport, IReportFormat, IReportType } from 'src/app/shared/models/interfaces/ireport.model';
import { IUserPlanModule, IUserSessionData } from 'src/app/shared/models/interfaces/iuser-session-data.model';
import { DeviceReport } from 'src/app/shared/types/device-report.type';
import { RequestMethodHTTP } from 'src/app/shared/enums/common-enums.enum';
import { ApiEndpointSgc } from 'src/app/shared/enums/api-endpoint-sgc.enum';
import { IApiRequestData } from 'src/app/shared/models/interfaces/iapi-request-data.model';
import { ReportFormats } from 'src/app/shared/enums/report.enum';
import { MatDatetimePickerInputEvent } from '@angular-material-components/datetime-picker';

type MaxFieldLength = Pick <IReport,"title">;

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {
  user:IUserSessionData;
  loading: boolean = false;

  /**
   * @type {IReport} El reporte a generar
   */
  report: IReport;

  reportTypes: IReportType[] = [];
  reportFormats: IReportFormat[] = [];
  optionSelectAllDevices: DeviceReport = {id:0,name:"Seleccionar todos",imei:"", plate_number: ""};
  /**
   *@type {Device} Los dispositivos del usuario
   */
  userDevices: DeviceReport[] = [];
  /**
   *@type {Device} Los dispositivos del usuario posterior a filtros de busqueda
  */
  filteredUserDevices: DeviceReport[] = [];
  /**
   * @type {boolean} Indica si todos los dispositivos  asociar al conductor estan seleccionados
   */
  allSelectedDevices:boolean = false;
  deviceToSearch: string = "";

  maxFieldslength!: Record<keyof MaxFieldLength,number>;

  /**  @type {IUserPlanModule} Permisos sobre el módulo */
  permissionsDataSgc:IUserPlanModule;
  moduleId:number = 28;
  constructor(
    private api: ApiService,
    public dataService: DataService,
    public utils:UtilsService,
    public messageBox: MessageBoxService,
    private objectInitializationService: ObjectInitializationService,
    public validationService: ValidationService,
    private route:Router
  ){
    this.maxFieldslength = {title:100};

    this.user = this.dataService.getData("user") as IUserSessionData;
    this.permissionsDataSgc = this.objectInitializationService.initializeIUserPlanModule();
    this.report = this.objectInitializationService.initializeIReport();
  }
  ngOnInit(): void {
    this.dataService.checkPermissionModule(this.moduleId).then((permissions: IUserPlanModule) => {
      this.permissionsDataSgc = permissions;
      this.getInitialData();
    }).catch(() => {
      this.utils.showMsg("Página no autorizada","No tiene permisos para ver esta página, contacte al administrador");
      this.route.navigate(['/']);
    });
  }
  async getInitialData(){
    await Promise.all([this.getDeviceList(),this.getReportTypes(), this.getReportFormats()]);
  }
  validateRequest(): {status: boolean, errors:string[]}{
    let response: {status: boolean, errors: string[]} = {status:false,errors: []};
    if(this.report.title == null || this.report.title.length  < 1)
      response.errors.push("Debe de indicar un titulo para el reporte");
    if(this.report.format == null || !Object.values(ReportFormats).includes(this.report.format) )
      response.errors.push("Debe de indicar un formato para la generación del reporte");
    if(this.report.report_type_id == null || this.report.report_type_id < 1)
      response.errors.push("Debe de indicar un tipo de reporte");
    if(typeof this.report.devices == "undefined" || this.report.devices!.length == 0 || (this.report.devices.length == 1 && this.report.devices[0] == this.optionSelectAllDevices.id))
      response.errors.push("Debe de indicar mínimo un dispositivo");
    if(!(this.report.start_datetime instanceof Date))
      response.errors.push("Debe de indicar una fecha de inicio");
    if(!(this.report.end_datetime instanceof Date))
      response.errors.push("Debe de indicar una fecha final");
    if(this.report.start_datetime instanceof Date && this.report.end_datetime instanceof Date && this.report.end_datetime < this.report.start_datetime)
      response.errors.push("la fecha final debe ser mayor a la fecha de inicio");

    let indexOptionAll = this.report.devices?.findIndex((item:number)=>item == this.optionSelectAllDevices.id);
    if(typeof indexOptionAll != "undefined" && indexOptionAll !== -1)
      this.report.devices?.splice(indexOptionAll,1);
    response.status = response.errors.length == 0;
    return response;
  }

  async generateReport(){
    let validate = this.validateRequest();
    if(!validate.status){
      let message = "<p>Los datos suministrados deben ser corregidos</p>";
      for(let item of validate.errors){
        message += "<span class='text-start text-small d-block pb-1'>"+ '<i class="fa-solid fa-circle-exclamation text-danger"></i>' + item + "</span>";
      }
      return this.utils.showResultRequest("error","Información",message);
    }
    const formdata:any =  new FormData();
    formdata.append("user_api_hash", this.user.hash);
    formdata.append("action","generate");

    type ReportEntry = [keyof IReport, IReport[keyof IReport]];

    const entries: ReportEntry[] = Object.entries(this.report) as ReportEntry[];

    entries.forEach(([key, value]) => {
      if((key == "start_datetime" || key == "end_datetime") && value instanceof Date)
        value = this.utils.getUTCDatetime(value, true);
      else if(key == "devices" && Array.isArray(value)){
        for(let deviceId of value){
          formdata.append(key+"[]", deviceId.toString());
        }        
        return;
      }
      formdata.append(key,value);
    });
    this.loading = true;
    try {
      let response:unknown = await this.api.requestBlobData(formdata,ApiEndpointSgc.REPORT);
      if(this.validationService.isBlob(response))
        this.utils.downloadFile(response , this.report.title.replace(" ","_") + "-" + (this.utils.getDateTimeNumericString()), this.report.format!);
      else
        this.messageBox.openSnackBar("Error en el procesamiento de la solicitud del archivo","Aceptar");
    } catch (error) {
      this.messageBox.openSnackBar("Error al generar archivo","Aceptar");
    }finally{
      this.utils.hideLoading(()=> this.loading = false);
    }
  }
  getReportTypes(){
    let dataToSend: IApiRequestData = this.api.structureParametersForConsult( RequestMethodHTTP.POST,"getReportTypes" , this.user.hash) as IApiRequestData;
    this.api.getDataPost(ApiEndpointSgc.REPORT , dataToSend , true).then((response: unknown)=>{
      if(this.validationService.isResponseApi(response)){
        this.reportTypes = response.status == 1 ? (response.data as IReportType[]) : [];        
      }
    }).catch((error:any)=>{});
  }
  getReportFormats(){
    let dataToSend: IApiRequestData = this.api.structureParametersForConsult( RequestMethodHTTP.POST,"getReportFormats" , this.user.hash) as IApiRequestData;
    this.api.getDataPost(ApiEndpointSgc.REPORT , dataToSend , true).then((response: unknown)=>{
      if(this.validationService.isResponseApi(response) && Array.isArray(response.data)){
        this.reportFormats = response.status == 1 ? (response.data as IReportFormat[]) : [];        
      }
    }).catch((error:any)=>{});
  }
  getDeviceList(){
    let dataToSend: string = this.api.structureParametersForConsult( RequestMethodHTTP.GET,"get" , this.user.hash, undefined,undefined,undefined,undefined,undefined,["id","imei","plate_number","name"]) as string;
    dataToSend +="&include_associated_users=false&include_device_plan=false";
    this.api.getData(ApiEndpointSgc.DEVICE , dataToSend , true).then((response: unknown)=>{
      if(this.validationService.isResponseApi(response)){
        this.userDevices = response.status == 1 ? [ this.utils.copyObject(this.optionSelectAllDevices) , ... (response.data as DeviceReport[]) ] : [ this.utils.copyObject(this.optionSelectAllDevices) ];
        this.filteredUserDevices = this.utils.copyObject(this.userDevices);
        
        if(typeof this.report.devices !="undefined" && this.report.devices.length == this.userDevices.length - 1){
          this.report.devices.unshift(this.optionSelectAllDevices.id);
          this.allSelectedDevices = true;
        }
      }
    }).catch((error:any)=>{});
  }
  clearSearchDevice(){
    this.deviceToSearch = "";
    this.searchDevice();
  }
  /**
   * Busca un dispositivo dentro de la lista disponible a ser elegidos
   */
  searchDevice(){
    this.deviceToSearch = this.deviceToSearch.toLowerCase();
    let FilteredDeviceList = !this.validationService.isNullOrEmpty(this.deviceToSearch) && this.deviceToSearch.length > 0? this.userDevices.filter((device: any) => device.imei.includes(this.deviceToSearch) || device.plate_number.toLowerCase().includes(this.deviceToSearch)):this.userDevices;
    this.filteredUserDevices = this.utils.copyObject(FilteredDeviceList);
  }
  toggleSelectAllDevicesToAssociate(){
    this.report.devices = this.allSelectedDevices ? [] : this.userDevices.map( (device:DeviceReport)=> device.id );
    this.allSelectedDevices = !this.allSelectedDevices;
  }

  /**
   * 
   * @param {boolean} isStartDate indica si la fecha a actualizar es la de inicio, en caso contrario se toma como referencia la fecha final
   */
  changeDate(event: MatDatetimePickerInputEvent<Date>,isStartDate: boolean){  
    if(event.target.value != null){
      event.target.value.setSeconds(0);
      if(isStartDate)
        this.report.start_datetime = event.value = event.target.value;
      else
        this.report.end_datetime = event.value = event.target.value;
    }
  }
}