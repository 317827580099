<section class="form-box-container animate__animated animate__fadeIn" >
    <section class="form-box-container__header">
        <span *ngIf="enableItems.button_back" class="form-box-container__header-back" (click)="closeComponent()"><span class="description">Volver</span> </span>
        <h2 class="form-box-container__header-title"><span class="icon"><i class="fa-solid fa-file-invoice-dollar"></i></span>{{titleComponent}} </h2>
        <div  class="form-box-container__header-options">                     
            <button [matMenuTriggerFor]="filterOptions"  matTooltip="Ver opciones de filtro" class="btn btn-primary" matTooltipPosition="above" aria-label="Opciones de filtro"><i class="fa-solid fa-filter"></i> </button>
            <button *ngIf="enableItems.generate_invoice" class="btn btn-outline-primary"(click)="openInvoiceGenerator()"> <i class="fa-solid fa-file-invoice"></i><span class="description"> Facturar</span></button>
            
            <button matToolTip="Exportar datos" class="btn btn-outline-secondary" (click)="exportFile()"><i class="fa-solid fa-file-export icon"></i> <span  class="description"> Exportar</span></button>
            <div class="search">
                <input id="inputSearch" type="search" placeholder="Buscar" (input)="searchValueInTable()" [(ngModel)]="searchValue">
                <label for="inputsearch"><i class="fa-solid fa-magnifying-glass fa-sm"></i></label>
            </div>
            <mat-menu #filterOptions="matMenu" class="actions">
              <button mat-menu-item (click)="$event.stopPropagation()"  class="text-primary" matTooltipPosition="left" matTooltip="Filtrar por estados">
                <i class="fa-solid fa-list-check"></i> <span  class="description"> Estados</span> 
                <div class="invisible-element">
                    <mat-form-field >
                        <mat-select  [(ngModel)]="invoiceObservationSelected" (selectionChange)="searchValueInTable()" multiple>
                            <mat-option *ngFor="let status of INVOICE_STATUS" [value]="status">{{status}}</mat-option>
                        </mat-select>
                    </mat-form-field>    
                </div>                    
            </button>
            <button mat-menu-item  *ngIf="enableItems.payment_systems_filter" (click)="$event.stopPropagation()" class="text-primary" matTooltipPosition="left" matTooltip="Filtrar por opcion de pago">
              <i class="fa-solid fa-money-check-dollar"></i> <span  class="description"> Sistema de pago</span> 
              <div class="invisible-element">
                  <mat-form-field >
                      <mat-select  [(ngModel)]="paymentOptionsSelected" (selectionChange)="searchValueInTable()" multiple>
                          <mat-option *ngFor="let option of PAYMENT_OPTIONS" [value]="option">{{option.LABEL}}</mat-option>
                      </mat-select>
                  </mat-form-field>    
              </div>                    
          </button>
          <button mat-menu-item *ngIf="!enableItems.date_range_filter" matTooltipPosition="left" [formGroup]="dateRangeToConsult" (click)="$event.stopPropagation()" class="text-primary" matTooltip="filtrar por mes">
            <i class="fa-solid fa-calendar-days"></i> Mes de registro
            <input formControlName = "start" [max]="currentDateForDatepicker" (dateChange)="searchValueInTable()"  matInput [matDatepicker]="dp" class="invisible invisible-size">
            <mat-datepicker-toggle matSuffix [for]="dp" class="full-cover-container transparent"></mat-datepicker-toggle>
            <mat-datepicker #dp startView="year"  (monthSelected)="filterByMonthAndYear($event, dp)">
            </mat-datepicker>
          </button>

          <button *ngIf="enableItems.date_range_filter" mat-menu-item matTooltipPosition="left" class="text-primary" matTooltip="Filtrar por rango de fecha">
            <mat-datepicker-toggle  class="full-cover-container transparent" matIconSuffix [for]="dateRangeToConsultPicker"></mat-datepicker-toggle>
                <i class="fa-solid fa-calendar-week"></i> <span  class="description"> Fecha de facturación</span>
                <mat-date-range-input disabled [formGroup]="dateRangeToConsult" [rangePicker]="dateRangeToConsultPicker">
                  <input formControlName="start"   matStartDate>
                  <input formControlName="end" matEndDate>
                </mat-date-range-input>
                <mat-date-range-picker #dateRangeToConsultPicker disabled="false">
                  <mat-date-range-picker-actions>
                      <button class="btn btn-outline-secondary" mat-button matDateRangePickerCancel>Cancelar</button>
                      <button class="btn btn-primary" mat-raised-button color="primary" (click)="searchValueInTable()"  matDateRangePickerApply>Aceptar</button>
                  </mat-date-range-picker-actions>
                </mat-date-range-picker>
            
        </button>
          </mat-menu>
        </div>
    </section>
    <div class="form-box two-panel two-panel--vertical animate__animated animate__fadeIn">
        <!--d-none d-md-flex-->
        <input type="checkbox" class="form-box__content-collapsible-container" matTooltip="Contrae / expande el resumen de datos" >
        <div *ngIf="!loading && billingList.length != 0" class="animate__animated form-box__content mobile-no-padding form-box__content-auto transparent-scroll-vertical order-mobile-first">

          <section class="count-panel-container count-panel-container--horizontal count-panel-container--horizontal-scroll-auto  ">
            <h2 class="count-panel-container__icon count-panel-container__icon--with-hover animate__animated animate__backInLeft custom-animate__delay-1000ms"> <mat-icon matTooltip="Resumen de datos mostrados en la tabla" matTooltipPosition="left" class="icon cursor-pointer text-warning">info</mat-icon> </h2>
              <ng-container *ngFor="let group of paymentSummaryItemsGroup;let index = index">
                <section class="count-panel-container-section count-panel-container-section--horizontal" >
                  <h3 class="count-panel-container-section__label" *ngIf="paymentSummaryItemsGroup.length > 1">  {{ group[0].monetary_unit_code}} </h3>
                    <article class="count-panel-item count-panel-item--width-autofit animate__animated animate__fadeInRight {{item.class_css_animate_delay + ' ' + item.class_css }}" *ngFor="let item of group">
                      <span class="count-panel-item-content">
                          <span class="count-panel-item-content__title">{{item.title}} </span>
                          <strong class="count-panel-item-content__quantity count-panel-item-content__quantity--small">{{item.total | currency :item.monetary_unit_code }}</strong>
                      </span>
                      <span class="count-panel-item-icon"><i class="{{item.icon}}"></i></span>
                  </article>
                </section>
                <span class="count-panel-container-section-divider" *ngIf="index < (paymentSummaryItemsGroup.length - 1) "></span>
              </ng-container>
          </section>
        </div>
      
        <div class="form-box__content form-box__content-shadow form-box__content-fill transparent-scroll-vertical form-box__content-h-auto">
            <ng-container *ngIf="billingList.length > 0">
              <div class="w-100 overflow-auto">
                  <table mat-table multiTemplateDataRows matSort (matSortChange)="sortData($event)" [dataSource]="billingList">
                    <ng-container *ngFor="let field of columnStructureToDisplay">
                        <ng-container *ngIf="field.visible"  matColumnDef="{{field.name}}">
                            <ng-container *ngIf="!field.sorted">
                              <th mat-header-cell *matHeaderCellDef [ngClass]="{'mat-header-cell-center': field.align_header == alignDataTable.CENTER, 'mat-header-cell-end': field.align_header == alignDataTable.RIGHT, 'text-info':field.name == 'total_price', 'text-success':field.name == 'total_amount_paid', 'text-danger':field.name == 'total_outstanding_balance'}" matTooltipPosition="above" matTooltip="{{field.header_help_tooltip_text !='' ? field.header_help_tooltip_text : (field.label.length>dataService.maxColumLabelLength?field.label:'')}}"> {{field.label.length>dataService.maxColumLabelLength ? field.label.substring(0,dataService.maxColumLabelLength):field.label}} <i *ngIf="field.header_help_tooltip_text !='' " class="fa-regular fa-circle-question icon"></i> </th>
                            </ng-container>
                            <ng-container *ngIf="field.sorted">
                              <th mat-header-cell *matHeaderCellDef [ngClass]="{'mat-header-cell-center': field.align_header == alignDataTable.CENTER, 'mat-header-cell-end': field.align_header == alignDataTable.RIGHT, 'text-info':field.name == 'total_price', 'text-success':field.name == 'total_amount_paid', 'text-danger':field.name == 'total_outstanding_balance'}" mat-sort-header sortActionDescription="Ordenar por {{field.label}}" matTooltipPosition="above" matTooltip="{{field.header_help_tooltip_text !='' ? field.header_help_tooltip_text : (field.label.length>dataService.maxColumLabelLength?field.label:'')}}">{{ field.label.length>dataService.maxColumLabelLength ? field.label.substring(0,dataService.maxColumLabelLength)+"...":field.label}} <i *ngIf="field.header_help_tooltip_text !='' " class="fa-regular fa-circle-question icon"></i></th>
                            </ng-container>
                            
                            <ng-container *ngIf="field.is_action_column;else elseDataColumn">
                                <td mat-cell *matCellDef="let invoice;let i = index"> 
                                    <div class="actions-container">
                                      <button mat-mini-fab (click)="invoice.is_expanded = !invoice.is_expanded" class="custom-action text-primary" aria-label="Ver detalles de facturación"><mat-icon>{{invoice.is_expanded ? 'expand_less' : 'expand_more'}}</mat-icon></button>
                                      <ng-container *ngIf="enableItems.generate_invoice">
                                        <button mat-mini-fab [matMenuTriggerFor]="actionsInvoice"  class="custom-action text-primary" aria-label="Ver opciones sobre factura"><mat-icon>settings</mat-icon></button>
                                        <mat-menu #actionsInvoice="matMenu" class="actions">
                                            <button class="text-primary" mat-menu-item matTooltip="Genera nuevas facturas con los dispositivos registrados en ella" matTooltipPosition="right" (click)="openInvoiceGenerator(invoice,undefined)"><i class="fa-solid fa-copy"></i> Generar Próximas facturas</button>
                
                                            <button class="text-primary" mat-menu-item matTooltip="Ver o agregar notas" matTooltipPosition="right" (click)="openNotesManagementDialog(invoice)"><i class="fa-solid fa-note-sticky"></i> Gestionar notas</button>
                                            
                                            <button class="text-primary" mat-menu-item matTooltip="generar soporte" matTooltipPosition="right" (click)="generatePaymentSupport(invoice,undefined)"><i class="fa-solid fa-file-arrow-down"></i> Generar soporte</button>
                                            <button class="text-primary" mat-menu-item matTooltip="Extender plazo" matTooltipPosition="right" (click)="openPaymentAgreementDialog(invoice)" *ngIf="invoice.observation == invoiceObservation.PENDING"><i class="fa-solid fa-handshake"></i> Extender plazo</button>
                                            <button class="text-primary" mat-menu-item matTooltip="Abonar" matTooltipPosition="right" *ngIf="invoice.observation == invoiceObservation.PENDING" (click)="openPaymentDialog(invoice,undefined,'apply')"><i class="fa-solid fa-file-invoice-dollar"></i> Abonar</button>
                                            <button class="text-primary" mat-menu-item matTooltip="Editar registro de cartera"matTooltipPosition="right" *ngIf="invoice.observation ==  invoiceObservation.PENDING" (click)="openPaymentDialog(invoice,undefined,'edit')"><i class="fa-solid fa-pen-to-square"></i> Editar</button>
                                            <button class="text-primary" mat-menu-item matTooltip="Esta acción cambiará el estado a anulada"matTooltipPosition="right"*ngIf="invoice.observation !== invoiceObservation.CANCELLED" (click)="deletePortfolioRecord(invoice,undefined,'invoice')"><i class="fa-solid fa-xmark"></i> Anular</button>
                                            <button class="text-primary" mat-menu-item matTooltip="Notificar al cliente"matTooltipPosition="right" (click)="notifyToClient(invoice)"><i class="fa-solid fa-bell"></i> Notificar al cliente</button>
                                        </mat-menu>
                                      </ng-container>
                                      
                                    </div>
                                </td>
                            </ng-container>
                            <ng-template #elseDataColumn>
                                <td mat-cell *matCellDef="let invoice;let i = index" [ngClass]="{'text-center': field.align_data == alignDataTable.CENTER,'text-end': field.align_data == alignDataTable.RIGHT}"  > 
                                <ng-container *ngIf="field.name == 'id';else elseDataId">
                                  <span
                                  matBadge="{{getGenerationModeDetails(invoice.generation_mode).abbreviation }}" 
                                  matBadgePosition="before" 
                                  matBadgeSize="small" 
                                  matBadgeColor="accent" 
                                  matBadgeOverlap="false"
                                  [matBadgeDisabled]="invoice.generation_mode == ''"
                                  matTooltip="{{getGenerationModeDetails(invoice.generation_mode).description}}" 
                                  matTooltipPosition="left" 
                                  >
                                    {{ invoice[field.name]}}
                                  </span>
                                </ng-container>
                                <ng-template #elseDataId>
                                  <ng-container *ngIf="field.name == 'observation';else elseDataObservation">
                                    <div class="d-flex justify-content-start align-items-center"><strong [ngClass]="{'text-danger': invoice.observation == invoiceObservation.PENDING ,'text-success':invoice.observation == invoiceObservation.PAID,'text-black':invoice.observation == invoiceObservation.CANCELLED}">{{invoice[field.name]}}</strong></div>
                                  </ng-container>
                                  <ng-template #elseDataObservation>
                                    <ng-container *ngIf="field.field_tooltip != null;else elseFieldTooltip">
                                      <span class="d-flex align-items-center justify-content-between flex-wrap-reverse">
                                        {{ field.pipe_type == pipeTypes.CURRENCY && invoice.monetary_unit_code??'' != null ? (invoice[field.name] | currency:invoice.monetary_unit_code ) : ( field.pipe_type == pipeTypes.DATE ? (invoice[field.name] | date: field.pipe_option) :  
                                        ( field.name == 'client_name' ? (invoice[field.name] + (  invoice['client_phone_number']??'' != '' ? (' (' + (invoice['client_phone_number']) + ')') : '' ) ) : (invoice[field.name]) ) )}}
                                        <button mat-icon-button (click)="field.tooltip_action != null && field.name == 'payment_agreement_last_date' ? field.tooltip_action(invoice) : null">
                                          <mat-icon *ngIf="field.field_tooltip != null && invoice[field.name] != null && invoice[field.name] !='' && invoice[field.field_tooltip] != ''" [matTooltip]=" field.initial_tooltip_text + ' ' + invoice[field.field_tooltip]" aria-hidden="false" [ngClass]="{'text-warning': field.tooltip_data_state_type == dataStatesTypes.WARNING, 'text-info': field.tooltip_data_state_type == dataStatesTypes.INFO}" aria-label="dato informativo" fontIcon="info"></mat-icon>
  
                                        </button>
                                      </span>
                                    </ng-container>
                                    <ng-template #elseFieldTooltip>
                                      <span  [ngClass]="{'badge': paymentBalanceColumns.includes(field.name), 'text-bg-light-info':field.name == 'total_price', 'text-bg-light-success':field.name == 'total_amount_paid', 'text-bg-light-danger':field.name == 'total_outstanding_balance'}">
                                        {{ field.pipe_type == pipeTypes.CURRENCY && invoice.monetary_unit_code??'' != null ? (invoice[field.name] | currency:invoice.monetary_unit_code ) : ( field.pipe_type == pipeTypes.DATE ? (invoice[field.name] | date: field.pipe_option) :  
                                        ( field.name == 'client_name' ? ((invoice[field.name] ?? invoice['client_email'] ?? '') + (  invoice['client_phone_number']??'' != '' && invoice['client_phone_number'] != 'null' ? (' (' + (invoice['client_phone_number']) + ')') : '' ) ) : (invoice[field.name]) )  
                                        ) }}
                                      </span>
                                    </ng-template>  
                                  </ng-template>
                                </ng-template>
                                
                                </td>
                            </ng-template>
                          </ng-container>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr> 
                    <tr mat-row *matRowDef="let element; columns:  displayedColumns;let i = index"></tr>                
                    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="no-hover"></tr>
                    <tr mat-footer-row *matFooterRowDef="['footer']"></tr> <!--;sticky:true-->  
                    <ng-container matColumnDef="footer">
                      <td mat-footer-cell *matFooterCellDef [colSpan]="displayedColumns.length"  class="text-small">
                          <strong>{{'Filtros aplicados | '}}</strong> {{ ( this.enableItems.payment_systems_filter ? (('Referencia de pago: '+ getSelectedPaymentOptionLabels()+ '; con ')) : '' )+ 'Estados de pago: '+ invoiceObservationSelected.join(', ') }}; de {{enableItems.date_range_filter ? ((dateRangeToConsult.value.start |date:'mediumDate')+ ' al ' + (dateRangeToConsult.value.end |date:'mediumDate')) : dateRangeToConsult.value.start |date:'MMMM, y'}}
                      </td>
                  </ng-container>
                    <ng-container matColumnDef="expandedDetail">
                        <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
                          <div [@detailExpand]="element.is_expanded ? 'expanded' : 'collapsed'" class="form-box__content form-box__content-h-auto transparent-scroll-vertical" [ngClass]="{'py-0': !element.is_expanded,'py-1':element.is_expanded}">
                            <table  mat-table  [dataSource]="element.details ">
                                <ng-container *ngFor="let field of detailsColumnStructureToDisplay">
                                    <ng-container  matColumnDef="{{field.name}}">
                                        <ng-container *ngIf="!field.sorted">
                                          <th mat-header-cell *matHeaderCellDef [ngClass]="{'mat-header-cell-center': field.align_header == alignDataTable.CENTER, 'mat-header-cell-end': field.align_header == alignDataTable.RIGHT, 'text-info':field.name == 'price', 'text-success':field.name == 'amount_paid', 'text-danger':field.name == 'outstanding_balance'}"  matTooltip="{{field.label.length>dataService.maxColumLabelLength?field.label:''}}"> {{field.label.length>dataService.maxColumLabelLength ? field.label.substring(0,dataService.maxColumLabelLength):field.label}}</th>
                                        </ng-container>
                                        <ng-container *ngIf="field.sorted">
                                          <th mat-header-cell *matHeaderCellDef mat-sort-header  [ngClass]="{'mat-header-cell-center': field.align_data == alignDataTable.CENTER, 'mat-header-cell-end': field.align_data == alignDataTable.RIGHT, 'text-info':field.name == 'price', 'text-success':field.name == 'amount_paid', 'text-danger':field.name == 'outstanding_balance'}" sortActionDescription="Ordenar por {{field.label}}" matTooltip="{{field.label.length>dataService.maxColumLabelLength?field.label:''}}">{{field.label.length>dataService.maxColumLabelLength ? field.label.substring(0,dataService.maxColumLabelLength)+"...":field.label}}</th>
                                        </ng-container>

                                        <ng-container *ngIf="field.is_action_column;else elseDetailDataColumn">
                                          <td mat-cell *matCellDef="let detail;let i = index" > 
                                              <div class="actions-container">
                                                <ng-container *ngIf=" enableItems.actions_on_details && detail.observation == invoiceObservation.PENDING && element.observation =='pendiente'">
                                                  <button mat-mini-fab [matMenuTriggerFor]="actionsInvoiceDetail"  class="custom-action text-primary" aria-label="Ver opciones sobre detalles de factura"><mat-icon>settings</mat-icon></button>                                                  
                                                  <mat-menu #actionsInvoiceDetail="matMenu" class="actions">
                                                          <!--<button  class="text-primary" mat-menu-item matTooltip="Abonar" matTooltipPosition="left" (click)="openPaymentDialog(element,detail,'apply')"><i class="fa-solid fa-file-invoice-dollar"></i> Abonar</button>-->
                                                          <button  class="text-primary" mat-menu-item matTooltip="Editar registro de cartera" matTooltipPosition="left" (click)="openPaymentDialog(element,detail,'edit')"><i class="fa-solid fa-pen-to-square"></i> Editar</button>
                                                          <button  class="text-primary" mat-menu-item matTooltip="Eliminar registro de cartera"matTooltipPosition="left"(click)="deletePortfolioRecord(element,detail,'detail')"><i class="fa-solid fa-trash-can"></i> Eliminar</button>
                                                          <button  class="text-primary" mat-menu-item matTooltip="Notificar al cliente" matTooltipPosition="left"(click)="notifyToClient(element,detail)"><i class="fa-solid fa-bell"></i> Notificar al cliente</button>                                                        
                                                  </mat-menu>
                                              </ng-container>
                                              </div>
                                          </td>
                                      </ng-container>
                                      <ng-template #elseDetailDataColumn>
                                          <td mat-cell *matCellDef="let detail;let i = index" [ngClass]="{'text-center': field.align_data == alignDataTable.CENTER,'text-end': field.align_data == alignDataTable.RIGHT}"  > 
                                          <ng-container *ngIf="field.name == 'observation';else elseDataObservation">
                                            <div class="d-flex justify-content-start align-items-center"><strong [ngClass]="{'text-danger': detail.observation == invoiceObservation.PENDING ,'text-success':detail.observation == invoiceObservation.PAID,'text-black':detail.observation == invoiceObservation.CANCELLED}">{{detail[field.name]}}</strong></div>
                                          </ng-container>
                                          <ng-template #elseDataObservation>
                                            <ng-container *ngIf="field.field_tooltip != null;else elseFieldTooltip">
                                              <span class="d-flex align-items-center justify-content-between flex-wrap-reverse">
                                                {{ field.pipe_type == pipeTypes.CURRENCY ? (detail[field.name] | currency:detail.monetary_unit_code ) : detail[field.name]}}
                                                <mat-icon *ngIf="field.field_tooltip != null && detail[field.field_tooltip] != ''" [matTooltip]=" field.initial_tooltip_text + ' ' + detail[field.field_tooltip]" aria-hidden="false" [ngClass]="{'text-warning': field.tooltip_data_state_type == dataStatesTypes.WARNING, 'text-info': field.tooltip_data_state_type == dataStatesTypes.INFO}" class="" aria-label="dato informativo" fontIcon="info"></mat-icon>
                                              </span>
                                            </ng-container>
                                            <ng-template #elseFieldTooltip>
                                              <span  [ngClass]="{'badge': paymentBalancedetailColumns.includes(field.name), 'text-bg-light-info':field.name == 'price', 'text-bg-light-success':field.name == 'amount_paid', 'text-bg-light-danger':field.name == 'outstanding_balance'}">
                                                {{ field.pipe_type == pipeTypes.CURRENCY ? (detail[field.name] | currency:detail.monetary_unit_code ) : detail[field.name]}}                                              
                                              </span>

                                            </ng-template>                                            
                                          </ng-template>
                                          </td>
                                      </ng-template>

                                        <td mat-cell *matCellDef="let detail;let i = index" [ngClass]="{'text-end': field.align_data == alignDataTable.RIGHT,'text-center':field.align_data == alignDataTable.CENTER}"> {{ field.name == 'price' ? (detail[field.name] |  currency:detail.monetary_unit_code) : (detail[field.name])}} </td>
                                      </ng-container>
                                </ng-container>
                                <tr mat-header-row *matHeaderRowDef="displayedDetailsColumns;let i = index"></tr>
                                <tr mat-row *matRowDef="let item; columns: displayedDetailsColumns;let i = index"></tr>
                                <tr class="mat-row" *matNoDataRow>
                                    <td class="mat-cell empty py-1" [attr.colspan]="displayedDetailsColumns.length">Sin datos</td>
                                </tr>
                            </table>
                         
                          </div>
                        </td>
                    </ng-container>
                    <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell empty py-1" [attr.colspan]="displayedColumns.length">Sin datos</td>
                    </tr>
                  </table>
                  <mat-paginator [length]="pagination.length_page" [pageSize]="pagination.page_size" [pageIndex]="pagination.current_page"
                  [pageSizeOptions]="pagination.page_size_options"  (page)="paginator($event)"
                  aria-label="Selecciona pagina de la lista de registros">
                </mat-paginator>
                </div>
              <!--mat-paginator aqui -->
            </ng-container>
            <div *ngIf="billingList.length == 0" class="form-box__content form-box__content--vertical-centered ">
              <div class="form-box__content-empty" >
                  <img src="../../../../assets/img/calculator-two-color.svg">
                  <span class="form-box__content-empty-label">No se han encontrado datos para el {{ enableItems.date_range_filter ? ('rango de fecha: '+ (dateRangeToConsult.value.start |date:'mediumDate')+ ' al ' + (dateRangeToConsult.value.end |date:'mediumDate')) : (' mes de ' + (dateRangeToConsult.value.start |date:'MMMM, y'))}}</span>
              </div>
            </div>
        </div>
    </div>
    <div class="loader" *ngIf="loading">
        <img src="../../../../../assets/loader.svg">
    </div>
</section>