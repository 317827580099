

<section class="form-box-container animate__animated animate__fadeIn" *ngIf="viewsAvailable['driver_management'].show_view">
  <section class="form-box-container__header">
      <h2 class="form-box-container__header-title"><span class="icon"><i class="fa-solid fa-id-card"></i></span>Gestión de conductores </h2>
      <div  class="form-box-container__header-options">                     
        <button matTooltip="crear Conductor" class="btn btn-primary" (click)="openDriverForm()"><i class="fa-solid fa-plus icon-plus icon"></i><span class="description"> Crear</span></button>
        <button matTooltip="Exportar datos" class="btn btn-outline-secondary" (click)="exportFile()"><i class="fa-solid fa-file-export icon"></i><span class="description"> Exportar</span></button>
        <div class="search">
            <input id="inputSearch" type="search" placeholder="Buscar" (input)="searchValueInData()" [(ngModel)]="searchValue">
            <label for="inputsearch"><i class="fa-solid fa-magnifying-glass icon"></i></label>
        </div>
      </div>
  </section>
  <div class="form-box animate__animated animate__fadeIn">

      <div class="form-box__content form-box__content-h-auto">

        <div class="w-100 overflow-auto">
          <table mat-table matSort (matSortChange)="sortData($event)" [dataSource]="driverList">
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Ordenar por nombre"> Nombre </th>
              <td mat-cell *matCellDef="let driver"> {{driver.name}} </td>
            </ng-container>
            <ng-container matColumnDef="document_number">
              <th mat-header-cell *matHeaderCellDef  mat-sort-header sortActionDescription="Ordenar por Número de documento"> Número de documento </th>
              <td mat-cell *matCellDef="let driver"> {{driver.document_number}} </td>
            </ng-container>
            <ng-container matColumnDef="phone">
              <th mat-header-cell *matHeaderCellDef  mat-sort-header sortActionDescription="Ordenar por número de teléfono"> Número de teléfono </th>
              <td mat-cell *matCellDef="let driver"> {{driver.phone}} </td>
            </ng-container>
            <ng-container matColumnDef="rfid">
              <th mat-header-cell *matHeaderCellDef  mat-sort-header sortActionDescription="Ordenar por rfid"> RFID </th>
              <td mat-cell *matCellDef="let driver"> {{driver.rfid}} </td>
            </ng-container>
            <ng-container matColumnDef="email">
              <th mat-header-cell *matHeaderCellDef  mat-sort-header sortActionDescription="Ordenar por email"> Email </th>
              <td mat-cell *matCellDef="let driver"> {{driver.email}} </td>
            </ng-container>
            <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef mat-sort-header disabled class="mat-header-center"> Acciones </th>
              <td mat-cell *matCellDef="let driver" class="td text-center"> 
                <div class="actions-container">
                  <button mat-button [matMenuTriggerFor]="actions"><i class="fa-solid fa-gear"></i></button>
                  <mat-menu #actions="matMenu">
                      <button class="text-primary" mat-menu-item matTooltip="Editar conductor" (click)="openDriverForm(driver)"><i class="fa-solid fa-pen-to-square fa-sm"></i> Editar</button>
                      <button class="text-primary" mat-menu-item matTooltip="Eliminar conductor" (click)="deleteDriver(driver)"><i class="fa-solid fa-trash-can fa-sm"></i> Eliminar</button>
                  </mat-menu>
                </div>  
              </td>
            </ng-container>
    
            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            <tr *matNoDataRow>
              <td class="mat-cell empty py-1" [attr.colspan]="displayedColumns.length">Sin datos</td>
            </tr>
          </table>
        </div>
        <mat-paginator [length]="lengthPage" [pageSize]="pageSize" [pageIndex]="currentPage"
          [pageSizeOptions]="pageSizeOptions" (page)="paginator($event)"
          aria-label="Selecciona pagina de la lista de dispositivos">
        </mat-paginator>
      </div>
  </div>
  <div class="loader" *ngIf="loading">
    <img src="../../../assets/loader.svg">
  </div>
</section>

<app-driver-form *ngIf="viewsAvailable['driver_form'].show_view" [inputNewDriver]="selectedDriver" [isUpdate]="!validationService.isNullOrEmpty(selectedDriver.id)"  (close)="closeDriverForm($event)"></app-driver-form>