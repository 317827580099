import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/core/api/api.service';
import { IRouteConstant, RouteConstant } from 'src/app/core/constant/RouteConstant';
import { DataService } from 'src/app/core/data/data.service';
import { UtilsService } from 'src/app/core/utils/utils.service';
import { IApiRequestData } from 'src/app/shared/models/interfaces/iapi-request-data.model';
import { IUserSessionData } from 'src/app/shared/models/interfaces/iuser-session-data.model';
import { ValidationService } from '../../../../core/validation/validation.service';
import { RequestMethodHTTP } from 'src/app/shared/enums/common-enums.enum';
import { IResponseApi } from 'src/app/shared/models/interfaces/iresponse-api.model';
import { ApiEndpointSgc } from 'src/app/shared/enums/api-endpoint-sgc.enum';

interface ITargetItemforSummary{
  endpoint: ApiEndpointSgc.DEVICE | ApiEndpointSgc.DEVICE_CHECKLIST_ANSWERED | ApiEndpointSgc.DRIVER
  label: string,
  action: string,
  total: number,
  request_method_http: RequestMethodHTTP,
  icon?:string,
  title?:string,
  class_css_animate_delay: string
}
interface ITargetForSummary{
  device: ITargetItemforSummary,
  driver: ITargetItemforSummary,
  device_checklist_answered: ITargetItemforSummary,
}
@Component({
  selector: 'app-home-end-user',
  templateUrl: './home-end-user.component.html',
  styleUrls: ['./home-end-user.component.scss']
})
export class HomeEndUserComponent implements OnInit {

  user!:IUserSessionData;
  /**
   * @type {RouteConstant} Indica la url para acceso a la seccion de inicio
   */
  urlHome:IRouteConstant = RouteConstant.DASHBOARD_HOME;
  targetForSummary: ITargetForSummary;
  totalsReport: ITargetItemforSummary[] = [];
  loading:boolean = false;
  constructor(
    private api:ApiService,
    private dataService:DataService,
    public utils: UtilsService,
    public validationService:ValidationService
  ){
    this.user = this.dataService.getData("user") as IUserSessionData;
    this.targetForSummary = {
      device_checklist_answered:{endpoint:ApiEndpointSgc.DEVICE_CHECKLIST_ANSWERED,label:"lista de checklist resueltos", action:"get", total: 0, request_method_http: RequestMethodHTTP.POST, icon:"fa-solid fa-clipboard-list",title:"Preoperativos",class_css_animate_delay:"custom-animate__delay-200ms"},
      device:{endpoint:ApiEndpointSgc.DEVICE,label:"dispositivos", action:"getUserDevices", total: 0, request_method_http: RequestMethodHTTP.GET,icon:"fa-solid fa-car",title:"Dispositivos", class_css_animate_delay:"custom-animate__delay-400ms"},
      driver:{endpoint:ApiEndpointSgc.DRIVER,label:"conductores", action:"get", total: 0, request_method_http: RequestMethodHTTP.GET,icon:"fa-solid fa-id-card",title:"Conductores", class_css_animate_delay:"custom-animate__delay-600ms"},
    }; 
    this.totalsReport = [...Object.values(this.targetForSummary)];
  }
  ngOnInit(): void {
    Object.values(this.targetForSummary).forEach(async (item: ITargetItemforSummary) => {
      let response = await this.makeRequest({},item.endpoint,item.action,item.label, item.request_method_http, true);
      if(this.validationService.isResponseApi(response))
        item.total = response.data??0;
    });
  }
  /**
   * 
   * @param {Record<string, string>} dataToSend los datos adicionales  a enviar, en caso de haberlos
   * @param {string} target el elemento a consultar
   * @param {RequestMethodHTTP} action La accion HTTP a realizar
   * @param {boolean} getTotal Indica si se va a recuperar solo el total de datos para cuando la consulta, action, es "get"
   * @param {boolean} isGetDataPost Indica si es una consulta get via post
   * @param {string} targetName El  nombre de etiqueta del target, para mostrar, en casso de error, en mensajes informativos
   * @returns 
   */
  async makeRequest( dataToSend:IApiRequestData, target: ApiEndpointSgc.DEVICE | ApiEndpointSgc.DEVICE_CHECKLIST_ANSWERED | ApiEndpointSgc.DRIVER ,action: string, targetName:string, requestMethodHTTP: RequestMethodHTTP, getTotal: boolean = false): Promise<IResponseApi>{
    this.loading = true;
    let data: IApiRequestData = { user_api_hash:this.user.hash, action:action,...dataToSend, get_total: getTotal};
    try{
      let dataStr = this.api.getCommonQueryParameters(action,this.user.hash,undefined,undefined,undefined,undefined, undefined, true);
      let response:unknown = requestMethodHTTP == RequestMethodHTTP.GET ? await this.api.getData(target, dataStr, true) : await this.api.getDataPost( target, data, true );
      if(this.validationService.isResponseApi(response))
        return response;
    }catch(error:any){
      this.utils.showResultRequest("error","Información",this.api.getDefaultMessage(  targetName,false, false,RequestMethodHTTP.GET));
      return {status:0};
    }finally{
      this.utils.hideLoading(()=>this.loading=false); 
    }
    return {status:0};
  }
  dataRequestCompleted(event:any){
    !!event ? this.utils.hideLoading(()=>this.loading = false) : null;
  }
}