import { Injectable, Input } from '@angular/core';
import { IApiRequestData } from '../../models/interfaces/iapi-request-data.model';
import { IUserSessionData } from '../../models/interfaces/iuser-session-data.model';
import { ApiService } from 'src/app/core/api/api.service';
import { DataService } from 'src/app/core/data/data.service';
import { UtilsService } from 'src/app/core/utils/utils.service';
import { ValidationService } from 'src/app/core/validation/validation.service';
import { IDeviceStatusRecord } from '../../models/interfaces/idevice-status-option.model';
import { Subject } from 'rxjs';
import { MatMenuTrigger } from '@angular/material/menu';
import { IResponseApi } from '../../models/interfaces/iresponse-api.model';

@Injectable({
  providedIn: 'root'
})
export class DeviceStatusRecordSummaryPopoverService {
  loading = true;
  user!:IUserSessionData;
  deviceStatusRecordList:IDeviceStatusRecord[] = []; 
  constructor(
    public utils: UtilsService,
    private dataService: DataService,
    private api: ApiService,
    public validationService: ValidationService
  ) {
    this.user = this.dataService.getData("user");

   }
    /**
   * @description Obtiene el historial de registro de estados del dispositivo actual del conductor
   * @param {number} deviceId Indica el id del dispositivo al cual se le busca los datos de resumen de estados
    */
    async getStateChangeHistory(deviceId:number):Promise<IResponseApi>{
      let data:IApiRequestData = { user_api_hash:this.user.hash, action:"get",device_id: deviceId,include_details:true, order:{by:"id",direction:"desc"}};
      try {
        let response:unknown = await this.api.getDataPost("deviceStatusRecord",data,true); 
        if(this.validationService.isResponseApi(response)){
          return response;
        }
        return {status:0};
      } catch (error) {
       return {status:0}; 
      }
    }
}
