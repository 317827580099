import { LOCALE_ID,NgModule,CUSTOM_ELEMENTS_SCHEMA  } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AngularMaterialModule } from "./angular-material.module";

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LoginComponent } from './login/login.component';
import { ClientsComponent } from './views/clients/clients.component';
import { MyGpsComponent } from './views/my-gps/my-gps.component';
import { DistributorsComponent } from './views/distributors/distributors.component';
import { HomeComponent } from './views/home/home.component';
import { BillingComponent } from './views/billing/billing.component';
import { PaymentsComponent } from './views/payments/payments.component';
import { DashboardModule } from './dashboard/dashboard.module';
import { NoFoundComponent } from './no-found/no-found.component';
import { DialogUserDevicesByExpirationComponent } from './views/billing/dialogs/dialog-user-devices-by-expiration/dialog-user-devices-by-expiration.component';
import { TasksComponent } from './views/tasks/tasks.component';
import { EmployeesComponent } from './views/tasks/dialogs/employees/employees.component';
import { CommentsComponent } from './views/tasks/dialogs/comments/comments.component';

import { DatePipe } from '@angular/common';

import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { NgChartsModule } from 'ng2-charts';


/** Derivados de angular */
import {
  NgxMatDatetimePickerModule, 
  NgxMatNativeDateModule, 
  NgxMatTimepickerModule 
} from '@angular-material-components/datetime-picker';

//se importa para cambiar el idioma en los pipes, tambien se agrega 
//LOCALE_ID en el import de ngModule, y en providers se agrega {provide: LOCALE_ID,useValue:'es'}
import localeEs from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { FullCalendarModule } from '@fullcalendar/angular';
import esLocale from '@fullcalendar/core/locales/es';
import { PaymentOptionsComponent } from './views/payments/dialogs/payment-options/payment-options.component';
import { UpcomingDeviceInvoicesComponent } from './shared/dialogs/upcoming-device-invoices/upcoming-device-invoices.component';

import { NgxPayPalModule } from 'ngx-paypal';
// Import library module
import { NgxSpinnerModule } from 'ngx-spinner';

import { SharedModule } from './shared/shared.module';

import { AngularSignaturePadModule } from '@almothafar/angular-signature-pad';

import { MyPortfolioComponent } from './views/my-portfolio/my-portfolio.component';
import { InvoiceRecordComponent } from './views/my-portfolio/dialogs/invoice-record/invoice-record.component';
import { NotificationComponent } from './views/my-portfolio/dialogs/notification/notification.component';
import { MyAccountComponent } from './views/my-account/my-account.component';
import { InvoiceCreationComponent } from './views/my-portfolio/dialogs/invoice-creation/invoice-creation.component';
import { AdminPlansComponent } from './views/admin-plans/admin-plans.component';
import { DeviceDetailsByYearComponent } from './views/home/dialogs/device-details-by-year/device-details-by-year.component';
import { IntegrationsComponent } from './views/integrations/integrations.component';
import { ControltComponent } from './views/integrations/web-services-list/controlt/controlt.component';
import { SgcPermissionsComponent } from './views/distributors/components/sgc-permissions/sgc-permissions.component';
import { WebsiteComponent } from './views/website/website.component';
import { DeviceFormComponent } from './shared/components/device-form/device-form.component';
import { ManagerDevicesListComponent } from './shared/components/manager-devices-list/manager-devices-list.component';
import { UserDevicesListComponent } from './shared/components/user-devices-list/user-devices-list.component';
import { PlatformPlanFormComponent } from './shared/components/platform-plan-form/platform-plan-form.component';
import { DisconnectedDevicesListComponent } from './shared/components/disconnected-devices-list/disconnected-devices-list.component';
import { LogsControltComponent } from './views/integrations/web-services-list/controlt/logs-controlt/logs-controlt.component';
import { BasicDataComponent } from './views/website/components/basic-data/basic-data.component';
import { DesignComponent } from './views/website/components/design/design.component';
import { WebsiteTemplateComponent } from './views/website/components/website-template/website-template.component';
import { DashboardMenuComponent } from './shared/components/dashboard-menu/dashboard-menu.component';
import { MyAccountBasicDataComponent } from './views/my-account/components/my-account-basic-data/my-account-basic-data.component';
import { PaymentOptionsBasicDataComponent } from './views/my-account/components/payment-options-basic-data/payment-options-basic-data.component';
import { AssignableModuleOperationsComponent } from './views/distributors/components/assignable-module-operations/assignable-module-operations.component';
import { PegasoComponent } from './views/integrations/web-services-list/pegaso/pegaso.component';
import { LogsPegasoComponent } from './views/integrations/web-services-list/pegaso/logs-pegaso/logs-pegaso.component';
import { ClientsSgcPermissionsComponent } from './views/clients/components/clients-sgc-permissions/clients-sgc-permissions.component';
import { FleetControlComponent } from './views/fleet-control/fleet-control.component';
import { ConfirmationSnackbarComponent } from './shared/components/confirmation-snackbar/confirmation-snackbar.component';
import { DriverManagementComponent } from './views/driver-management/driver-management.component';
import { DriverFormComponent } from './views/driver-management/driver-form/driver-form.component';
import { DetailPopoverComponent } from './shared/components/detail-popover/detail-popover.component';
import { DeviceChecklistManagementComponent } from './views/fleet-control/components/device-checklist-management/device-checklist-management.component';
import { DeviceStateManagementComponent } from './views/fleet-control/components/device-state-management/device-state-management.component';
import { DeviceChecklistFormComponent } from './views/fleet-control/components/device-checklist-management/device-checklist-form/device-checklist-form.component';
import { DeviceChecklistQuestionCategoryComponent } from './views/fleet-control/components/device-checklist-management/device-checklist-question-category/device-checklist-question-category.component';
import { RouteControlReportComponent } from './views/pvs-reports/components/route-control-report/route-control-report.component';
import { DriverBehaviorComponent } from './views/pvs-reports/components/driver-behavior/driver-behavior.component';
import { FleetProductivityComponent } from './views/pvs-reports/components/fleet-productivity/fleet-productivity.component';
import { CircularChartComponent } from './shared/components/charts/circular-chart/circular-chart.component';
import { UserDriverModule } from './user-driver/user-driver.module';
import { SpecialUserLoginComponent } from './login/special-user-login/special-user-login.component';
import { HomeEndUserComponent } from './views/home/components/home-end-user/home-end-user.component';
import { DevicePaymentRecordsComponent } from './shared/components/device-payment-records/device-payment-records.component';
import { DeviceNotesComponent } from './shared/components/device-notes/device-notes.component';
import { BarLineChartComponent } from './shared/components/charts/bar-line-chart/bar-line-chart.component';
import { UserNotesComponent } from './shared/components/user-notes/user-notes.component';
import { DeviceRenewalBillingComponent } from './views/billing/components/device-renewal-billing/device-renewal-billing.component';
import { DeviceLinkingBillingComponent } from './views/billing/components/device-linking-billing/device-linking-billing.component';
import { AtuWebServiceComponent } from './views/integrations/web-services-list/atu-web-service/atu-web-service.component';
import { HomeManagerUserComponent } from './views/home/components/home-manager-user/home-manager-user.component';
import { HomeAdminUserComponent } from './views/home/components/home-admin-user/home-admin-user.component';
import { PaymentAgreementComponent } from './views/my-portfolio/dialogs/payment-agreement/payment-agreement.component';
import { CaseronesOwlWebServiceComponent } from './views/integrations/web-services-list/caserones-owl-web-service/caserones-owl-web-service.component';
import { CaseronesOwlLogsWebServiceComponent } from './views/integrations/web-services-list/caserones-owl-web-service/caserones-owl-logs-web-service/caserones-owl-logs-web-service.component';
import { AutomationsComponent } from './views/automations/automations.component';
import { AutomationFormComponent } from './views/automations/components/automation-form/automation-form.component';
import { AutomationTargetUserComponent } from './views/automations/components/automation-target-user/automation-target-user.component';
import { MessagingServiceAccountSettingComponent } from './views/my-account/components/messaging-service-account-setting/messaging-service-account-setting.component';
import { PhoneLineIntegrationDetailsComponent } from './views/my-account/components/messaging-service-account-setting/phone-line-integration-details/phone-line-integration-details.component';
import { PhoneLineRegistrationConfirmationComponent } from './views/my-account/components/messaging-service-account-setting/phone-line-registration-confirmation/phone-line-registration-confirmation.component';
import { MessagingServicePhoneLineListComponent } from './views/my-account/components/messaging-service-account-setting/messaging-service-phone-line-list/messaging-service-phone-line-list.component';
import { PhoneLineQrCodeScannerComponent } from './views/my-account/components/messaging-service-account-setting/phone-line-qr-code-scanner/phone-line-qr-code-scanner.component';
import { WialonIpsWebServiceComponent } from './views/integrations/web-services-list/wialon-ips-web-service/wialon-ips-web-service.component';
import { ReportsComponent } from './views/reports/reports.component';
import { InvoiceNotesComponent } from './views/my-portfolio/dialogs/invoice-notes/invoice-notes.component';

registerLocaleData(localeEs,'es');
@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    LoginComponent,
    ClientsComponent,
    MyGpsComponent,
    DistributorsComponent,
    HomeComponent,
    BillingComponent,
    PaymentsComponent,
    NoFoundComponent,
    DialogUserDevicesByExpirationComponent,
    TasksComponent,
    EmployeesComponent,
    CommentsComponent,
    PaymentOptionsComponent,
    MyPortfolioComponent,
    InvoiceRecordComponent,
    NotificationComponent,
    MyAccountComponent,
    InvoiceCreationComponent,
    AdminPlansComponent,
    UpcomingDeviceInvoicesComponent,
    DeviceDetailsByYearComponent,
    IntegrationsComponent,
    ControltComponent,
    SgcPermissionsComponent,
    WebsiteComponent,
    DeviceFormComponent,
    ManagerDevicesListComponent,
    UserDevicesListComponent,
    PlatformPlanFormComponent,
    DisconnectedDevicesListComponent,
    LogsControltComponent,
    BasicDataComponent,
    DesignComponent,
    WebsiteTemplateComponent,
    DashboardMenuComponent,
    MyAccountBasicDataComponent,
    PaymentOptionsBasicDataComponent,
    AssignableModuleOperationsComponent,
    PegasoComponent,
    LogsPegasoComponent,
    ClientsSgcPermissionsComponent,
    FleetControlComponent,
    ConfirmationSnackbarComponent,
    DriverManagementComponent,
    DriverFormComponent,
    DetailPopoverComponent,
    DeviceChecklistManagementComponent,
    DeviceStateManagementComponent,
    DeviceChecklistFormComponent,
    DeviceChecklistQuestionCategoryComponent,
    RouteControlReportComponent,
    DriverBehaviorComponent,
    FleetProductivityComponent,
    CircularChartComponent,
    SpecialUserLoginComponent,
    HomeEndUserComponent,
    DevicePaymentRecordsComponent,
    DeviceNotesComponent,
    BarLineChartComponent,
    UserNotesComponent,
    DeviceRenewalBillingComponent,
    DeviceLinkingBillingComponent,
    AtuWebServiceComponent,
    HomeManagerUserComponent,
    HomeAdminUserComponent,
    PaymentAgreementComponent,
    CaseronesOwlWebServiceComponent,
    CaseronesOwlLogsWebServiceComponent,
    AutomationsComponent,
    AutomationFormComponent,
    AutomationTargetUserComponent,
    MessagingServiceAccountSettingComponent,
    PhoneLineIntegrationDetailsComponent,
    PhoneLineRegistrationConfirmationComponent,
    MessagingServicePhoneLineListComponent,
    PhoneLineQrCodeScannerComponent,
    WialonIpsWebServiceComponent,
    ReportsComponent,
    InvoiceNotesComponent
  ],
  entryComponents:[DialogUserDevicesByExpirationComponent],
  imports: [
    AngularMaterialModule,
    BrowserModule,
    AppRoutingModule,
    DashboardModule,
    SharedModule,
    FormsModule,
    NgChartsModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    AngularSignaturePadModule,
    
    FullCalendarModule,
    NgxPayPalModule,
    NgxSpinnerModule,
    UserDriverModule,


    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatNativeDateModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [[DatePipe],{provide: LOCALE_ID,useValue:'es'}],
  bootstrap: [AppComponent]
})
export class AppModule { }