import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/core/api/api.service';
import { DataService } from 'src/app/core/data/data.service';
import { UtilsService } from 'src/app/core/utils/utils.service';
import { ValidationService } from 'src/app/core/validation/validation.service';
import { IDeviceBasicData } from 'src/app/shared/models/interfaces/idevice.model';
import { IUserSessionData } from 'src/app/shared/models/interfaces/iuser-session-data.model';
import { IApiRequestDataForReport, PvsReportsService } from '../../pvs-reports.service';
import { ISort } from 'src/app/shared/models/interfaces/iapi-request-data.model';
import { ObjectInitializationService } from 'src/app/core/object-initialization/object-initialization.service';

/**
 * La lista de datos de dispositivo que se muestra en esta vista
 */
interface IDeviceList{
  id:number,
  device_date:string,
  plate_number:string,
  start_address:string,
  end_address:string,
  start_latitude:number,
  start_longitude:number,
  end_latitude:number,
  end_longitude:number,
  distance:number,
  current_driver_name:string
}
@Component({
  selector: 'app-route-control-report',
  templateUrl: './route-control-report.component.html',
  styleUrls: ['./route-control-report.component.scss']
})
export class RouteControlReportComponent implements OnInit {

  loading:boolean=false;
  user!:IUserSessionData;
  /**@type {any} LaLista de datos a mostrar de los dispositivos. HAY QUE DEFINIR LA INTERFACE PARA LOS DATOS QUE ALMACENA */
  deviceList:IDeviceList[]=[];
  sort!: ISort;
  /** Indica las fechas de filtro usadas */
  footerData!:{start_date:string,end_date:string};
  /** @type { IDeviceBasicData[] } Lista de dispositivos disponibles para el usuario */
  availableDeviceList: IDeviceBasicData[] = [];
  
  /** @type {number} Indica la cantidad de kilometros recorridos segun el filtro indicado */
  totalKilometersQuantity:number = 0;
  /** @type { IDeviceBasicData[] } Lista de dispositivos disponibles para el usuario, filtrados */
  availableDeviceListFiltered: IDeviceBasicData[] = [];
  /** @type {string} el valor a buscar dentro de la lista de dispositivos seleccionables */
  deviceToSearch:string = "";
  
  /** @type {number[]} Los dispositivos seleccionados para consultar */
  devicesSelected:number[] = [];
  /** @type {boolean} Indica si todos los dispositivoss de la lista han sido seleccionados */
  allSelectedDevices:boolean = false;

  optionSelectAllDevices:IDeviceBasicData = {id:0, name:"Seleccionar todos", plate_number:"", imei:""};

  //para paginacion
  lengthPage:number=0;
  pageSize!:number;
  pageSizeOptions:number[]=[];
  pageEvent!:PageEvent;
  currentPage=0;

  /** @type {FormGroup} Indica el rango de fecha para filtrar datos por fecha, cuando la seleccion es un solo dispositivo */
  dateRangeToConsult!:FormGroup;
  /** @type {Date} Indica la fecha para filtrar datos por fecha, cuando la seleccion es de varios dispositivos */
  dateToConsult:Date = new Date();
  displayedColumns:any =[this.pvsReportsService.NUMBERING_COLUMN_HEADER.NAME,"device_date","plate_number","start_address","end_address","distance","current_driver_name"];

  moduleId=21;
  permissionsDataSgc:any=[];//permisos sobre el modulo
  constructor(
    private router:Router,
    public utils: UtilsService,
    public validationService:ValidationService,
    private api:ApiService,
    private dataService:DataService,
    public pvsReportsService:PvsReportsService,
    private objectInitializationService:ObjectInitializationService
  ){
    this.sort = this.objectInitializationService.initializeISort();
    this.user = this.dataService.getData("user") as IUserSessionData;
    const date:Date = new Date();
    this.dateRangeToConsult = new FormGroup({
      start: new FormControl(new Date(date.getFullYear(),date.getMonth(),date.getDate()-14)),
      end: new FormControl(new Date(date.getFullYear(),(date.getMonth()),date.getDate())),
    });
    this.footerData = {start_date:"",end_date:""};
  }
  ngOnInit(): void {
    this.dataService.checkPermissionModule(this.moduleId).then((permissions: any) => {
      this.permissionsDataSgc = permissions;
      this.pageSizeOptions = [20];
      this.pageSize = this.pageSizeOptions[0];
      this.getDeviceList();
    }).catch(() => {
      this.utils.showMsg("Página no autorizada","No tiene permisos para ver esta página, contacte al administrador");
      this.router.navigate(['/']);
    });
  }
  paginator(event:PageEvent){
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;    
    this.getDeviceList();
  }
  sortData(sort:Sort){
      this.sort.direction = sort.direction;
      this.sort.by = sort.active;
      this.getDeviceList();   
  }
  async getDeviceList(){
    this.loading = true;
    let reponse:{status:boolean,available_device_list?:IDeviceBasicData[],request_data?:IApiRequestDataForReport,devices_selected?:number[]} = await this.pvsReportsService.getDataForDeviceSummaryQuery("getRouteSummary",this.sort,this.availableDeviceList,this.optionSelectAllDevices.id,this.devicesSelected,this.dateRangeToConsult,this.dateToConsult,this.pageSize,this.currentPage);
    if(!reponse.status)
      return this.utils.hideLoading(()=>this.loading = false);
    let dataTosend = reponse.request_data;
    this.availableDeviceList = reponse.available_device_list!;
    this.availableDeviceListFiltered = this.utils.copyObject(this.availableDeviceList);
    this.devicesSelected = reponse.devices_selected!;
    this.footerData = {start_date:dataTosend!.start_date,end_date:dataTosend!.end_date};
    this.api.getDataPost("report",dataTosend,true).then((data: any) => {
      if((this.devicesSelected.length - (this.devicesSelected.some((item:number)=>item==this.optionSelectAllDevices.id)?1:0)) == dataTosend!.device_id_list?.length){
        this.allSelectedDevices = true;
      }
      this.deviceList = data.status == 1 ? data.data:[];
      this.lengthPage = data.status ? (dataTosend!.device_id_list!.length>1 ? this.availableDeviceList.length: this.deviceList.length) : 0;
      this.totalKilometersQuantity = 0;
      data.status ? this.deviceList.forEach((item:IDeviceList)=>this.totalKilometersQuantity += item.distance):null;
      this.utils.hideLoading(()=>this.loading=false);
    }).catch((error)=>{
      this.utils.showResultRequest("error","Información",this.api.getDefaultMessage("la lista de estados de dispositivos",false,false,"GET"));
      this.utils.hideLoading(()=>this.loading=false);
    });
  }
  clearSearchDevice(){
    this.deviceToSearch = "";
    this.searchDevice();
  }
  /** Busca un dispositivo dentro de la lista disponible a ser elegidos */
  searchDevice(){
    this.deviceToSearch = this.deviceToSearch.toLowerCase();
    let FilteredDeviceList = !this.validationService.isNullOrEmpty(this.deviceToSearch) && this.deviceToSearch.length > 0? this.availableDeviceList.filter((device: any) => device.plate_number.includes(this.deviceToSearch) || device.name.toLowerCase().includes(this.deviceToSearch)):this.availableDeviceList;
    this.availableDeviceListFiltered = this.utils.copyObject(FilteredDeviceList);
  }
  toggleSelectAllDevicesToAssociate(){
    this.devicesSelected = this.allSelectedDevices?[]:this.availableDeviceList.map((device:IDeviceBasicData)=>device.id);
    if(!this.allSelectedDevices)
      this.devicesSelected.unshift(this.optionSelectAllDevices.id);
    this.allSelectedDevices = !this.allSelectedDevices;
  }
  isAllDevicesSelected(){  
    const {allSelectedDevices, devicesSelected} = this.pvsReportsService.isAllDevicesSelected(this.devicesSelected,this.availableDeviceList,this.allSelectedDevices,this.optionSelectAllDevices.id); 
    this.allSelectedDevices = allSelectedDevices;
    this.devicesSelected = devicesSelected;
  }
  exportFile(fileType:"PDF"|"CSV"){
    if(fileType=="CSV"){
      let data:IDeviceList[] = this.utils.copyObject(this.deviceList);
      let headers:string[] = this.displayedColumns.slice();
      headers.splice(this.displayedColumns.findIndex((item:string)=>item==this.pvsReportsService.NUMBERING_COLUMN_HEADER.NAME),1);
      data.push({
        id:0,plate_number:"",start_address:"",end_address:"Total km",start_latitude:0,
        start_longitude:0,end_latitude:0,end_longitude:0,distance:this.totalKilometersQuantity,device_date:"",current_driver_name:""
      });
      this.utils.processFileDownload("device_report_",
      async()=>{return {data:data,headers:headers}},
      (response:boolean)=>this.loading = response);
    }
  }
}