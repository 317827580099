<ng-container *ngIf="showOnlyTable;then summaryTable else formBoxSummaryTable"></ng-container>
<ng-template #formBoxSummaryTable>
<section class="form-box-container animate__animated animate__fadeIn" >
    <section class="form-box-container__header">
        <span class="form-box-container__header-back" (click)="closeComponent()"><span class="description">Volver</span> </span>
        <h2 class="form-box-container__header-title"><span class="icon"><i class="fa-solid fa-list-check"></i></span>Histórico de checklist</h2>
    </section>
    <div class="form-box animate__animated animate__fadeIn">
      <ng-container *ngTemplateOutlet="summaryTable"></ng-container>
    </div>
    <div class="loader" *ngIf="loading">
        <img src="../../../../../assets/loader.svg">
    </div>
</section>
</ng-template>

<ng-template #summaryTable>
  <div class="form-box__content form-box__content-h-auto transparent-scroll-vertical">
    <ng-container *ngIf="deviceChecklistAnswerdList.length > 0">
      <div class="w-100 overflow-auto">
          <table mat-table  matSort (matSortChange)="sortData($event)" [dataSource]="deviceChecklistAnswerdList">
              <ng-container matColumnDef="id" >
                <th mat-header-cell *matHeaderCellDef> ID </th>
                <td mat-cell *matCellDef="let element"> {{element.id}} </td>
            </ng-container>
              <ng-container matColumnDef="created_at" >
                  <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Ordenar por fecha de registro"> Fecha de registro </th>
                  <td mat-cell *matCellDef="let element"> {{element.created_at | date: "YYYY/MM/dd, h:mm a"}} </td>
              </ng-container>
              <ng-container matColumnDef="device_plate_number" >
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Ordenar por placa de dispositivo" > Placa </th>
                <td mat-cell *matCellDef="let element"> 
                  <span class="d-flex align-items-center justify-content-between ">
                    {{validationService.isNullOrEmpty(element.device_plate_number) ? element.device_name : element.device_plate_number}}
                    <mat-icon matTooltip="Nombre del dispositivo (Sin placa definida)" *ngIf="validationService.isNullOrEmpty(element.device_plate_number)" aria-hidden="false" class="text-warning" aria-label="Sin placa definida" fontIcon="info"></mat-icon>
                  </span>
                </td>
              </ng-container>
              <ng-container matColumnDef="driver_name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Ordenar por nombre de conductor"> Conductor </th>
                <td mat-cell *matCellDef="let element"> {{element.driver_name}} </td>
              </ng-container>
              <ng-container matColumnDef="device_checklist_name" >
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Ordenar por nombre de checklist"> Nombre checklist </th>
                <td mat-cell *matCellDef="let element"> {{element.device_checklist_name}}</td>
              </ng-container>
              <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef class="mat-header-cell-center"> Acciones </th>
                <td mat-cell *matCellDef="let element" >
                    <div class="actions-container" [matTooltip]="'Ver detalles'">
                        <button class="text-primary" [disabled]="loading" (click)="viewDeviceChecklistDetails(element)" ><i class="fa-solid fa-eye"></i></button>
                    </div>
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              <tr class="mat-row" *matNoDataRow>
                  <td class="mat-cell empty py-1" [attr.colspan]="displayedColumns.length">Sin datos</td>
              </tr>
          </table>
      </div>
      <mat-paginator [length]="lengthPage" [pageSize]="pageSize" [pageIndex]="currentPage"
      [pageSizeOptions]="pageSizeOptions" (page)="paginator($event)"
      aria-label="Selecciona pagina de la lista de dispositivos">
    </mat-paginator>
  </ng-container>
      
    <div *ngIf="deviceChecklistAnswerdList.length == 0" class="form-box__content form-box__content--vertical-centered ">
      <div class="form-box__content-empty" >
          <img src="../../../../assets/img/empty-checklist.svg">
          <span class="form-box__content-empty-label">No hay datos de respuestas de checklists</span>
      </div>
  </div>
  </div>
</ng-template>