<div class="chart"*ngIf="circularChartConfigData.data.datasets[0].data.length > 0">
    <div class="chart__content">
        <div class="chart__content-header-title" *ngIf="circularChartConfigData.title !=''">{{circularChartConfigData.title}}</div>
        <div class="chart__content-canvas animate__animated animate__fadeIn" *ngIf="circularChartConfigData.updated_chart">
            <canvas baseChart
                [data]="circularChartConfigData.data"
                [options]="pieChartOptions"
                [plugins]="pieChartPlugins"
                [type]="pieChartType">
            </canvas>
        </div>
    </div>
</div>