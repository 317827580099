<section class="form-box-container animate__animated animate__fadeIn">
    <section class="form-box-container__header">
        <h2 class="form-box-container__header-title"><span class="icon"><i class="fa-solid fa-list-check"></i></span>Gestión de estados</h2>
        <h3 class="form-box-container__header-subtitle">{{!validationService.isNullOrEmpty(currentDrivenVehicle.current_driver_id) ? (!validationService.isNullOrEmpty(currentDrivenVehicle.plate_number) ? currentDrivenVehicle.plate_number : currentDrivenVehicle.name) : "Sin dispositivo" }} <mat-icon matTooltip="Dispositivo actual">help</mat-icon> </h3>
        <div class="form-box-container__header-options">
            <app-device-status-record-summary-popover [deviceToConsultStatusRecord] = "currentDrivenVehicle"  showTriggerType="button"></app-device-status-record-summary-popover>
            <app-driver-device-selector (currentDeviceHasBeenChanged)="currentDrivenVehicle = $event;getLastDeviceStatusRecord();"></app-driver-device-selector>
        </div>
    </section>
    <section class="form-box animate__animated animate__fadeIn">
        <div  class="form-box__content form-box__content-h-auto">
            <div *ngIf="deviceStatusList.length > 0" class="form-box__content-group-fields">
                <mat-card *ngFor="let status of deviceStatusList;index as i" class="card fill">
                    <mat-card-title-group>
                        <mat-card-title >
                            
                            <div class="d-flex justify-content-start align-items-center g-1">
                                <div class="status" [ngClass]="{'text-gray': !isLastRecordSelected(status), 'text-success': isLastRecordSelected(status)}" ><i class="fa-solid fa-circle-chevron-right"></i></div>
                                <span>
                                    {{(i+1)+". "+(getTitleDeviceStatusOption(status)) }}
                                </span>
                            </div>
                        </mat-card-title>
                        <mat-card-subtitle> {{isLastRecordSelected(status) ? 'Selección actual' : 'Nombre de estado'}}</mat-card-subtitle>
                        <img mat-card-sm-image src="../../../../../assets/img/world-connection-two-color.svg" alt="imagen decorativa" >

                    </mat-card-title-group>
                    <mat-card-content>
                        {{getDescriptionDeviceStatusOption(status)}}
                    </mat-card-content>
                    <mat-card-actions align="end" class="card__actions">
                        <mat-slide-toggle color="primary" [checked]="isLastRecordSelected(status)" (change)="addDeviceStatusRecords($event,status)" aria-label="Seleccionar estado" matTooltipPosition="left" [matTooltip]="isLastRecordSelected(status) ? 'Estado actualmente seleccionado':'Seleccionar estado'"></mat-slide-toggle>
                    </mat-card-actions>
                </mat-card>
            </div>
            <div *ngIf="deviceStatusList.length == 0" class="form-box__content-empty">
                <img src="../../../../../assets/img/travel-plans-two-color.svg">
                <span class="form-box__empty-label">No tiene opciones de estados disponibles. Comunicate con el usuario gestor de la flota </span>
            </div>
        </div>
    </section>
    <div class="loader" *ngIf="loading">
        <img src="../../../../../assets/loader.svg">
    </div>
</section>
