<section class="form-box-container animate__animated animate__fadeIn">
    <section class="form-box-container__header">
        <h2 class="form-box-container__header-title"><span class="icon"><i class="fa-solid fa-robot"></i></span>Reportes</h2>
        <div  class="form-box-container__header-options">                     

        </div>
    </section>
    <div class="form-box animate__animated animate__fadeIn">
        <form  class="form-box__content form-box__content-h-auto">
            <div class="form-box__content-inputs">
                <div class="form-box__content-group-fields ">
                    <mat-form-field appearance="fill" class="fill"  hintLabel="Max {{maxFieldslength.title}} carácteres">
                        <mat-label>Título</mat-label>
                        <input matInput required="" type="text" name="title" minlength="1" [(ngModel)]="report.title" [maxLength]="maxFieldslength.title">
                        <mat-hint align="end">{{report.title.length}}/{{maxFieldslength.title}}</mat-hint>
                        <mat-error>El titulo es requerido</mat-error>
                      </mat-form-field>

                      <mat-form-field appearance="fill" class="fill" >
                        <mat-label>Tipo de reporte</mat-label>
                        <mat-select required="" name="report_types" [(ngModel)]="report.report_type_id">
                          <mat-option value="0" disabled="">-- Seleccione una opción --</mat-option>
                          <mat-option *ngFor="let type of reportTypes" [value]="type.id">
                            {{type.title}}
                          </mat-option>
                        </mat-select>
                        <mat-error>El tipo de reporte es requerido</mat-error>
                      </mat-form-field>

                      <mat-form-field appearance="fill">
                        <mat-label>Formato</mat-label>
                        <mat-select required="" name="format" [(ngModel)]="report.format">
                          <mat-option *ngFor="let format of reportFormats" [value]="format.code">
                            {{format.label}}
                          </mat-option>
                        </mat-select>
                        <mat-error>El formato es requerido</mat-error>
                      </mat-form-field>
                      
                      <mat-form-field appearance="fill"class="animate__animated animate__faster" >
                        <mat-label>Dispositivos</mat-label>
                        <mat-select required="" (closed)="clearSearchDevice()" [multiple]="true" name="devices" [(ngModel)]="report.devices">
                            <mat-option>
                                <ngx-mat-select-search [noEntriesFoundLabel]="'Sin resultados'" placeholderLabel="Buscar dispositivo..." [(ngModel)]="deviceToSearch" name="deviceToSearch" (keyup)="searchDevice()">
                                    <mat-icon (click)="clearSearchDevice()" ngxMatSelectSearchClear>close</mat-icon>
                                </ngx-mat-select-search>
                            </mat-option>
                            <ng-container *ngFor="let device of filteredUserDevices">
                                <mat-option *ngIf="device.id == 0" (click)="toggleSelectAllDevicesToAssociate()" [value]="device.id">{{ device.name }}</mat-option>
                                <mat-option *ngIf="device.id != 0" [value]="device.id"  >{{device.name + ' ('+device.imei+")" }}</mat-option>
                            </ng-container>
                        </mat-select>
                      <mat-error>Debe seleccionar mínimo un dispositivo</mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="fill">
                      <mat-label>Fecha inicio</mat-label>
                      <input required=""  (dateChange)="changeDate($event,true)" [max]="report.end_datetime"  matInput name="start_datetime" [(ngModel)]="report.start_datetime" readonly="" [ngxMatDatetimePicker]="startPicker">
                      <mat-datepicker-toggle matSuffix [for]="$any(startPicker)"></mat-datepicker-toggle>
                      <ngx-mat-datetime-picker [disabled]="false" #startPicker [showSpinners]="true" [showSeconds]="false"
                                              [stepHour]="1" [stepMinute]="1" [stepSecond]="1"
                                              [touchUi]="false" [enableMeridian]="true"
                                              [disableMinute]="false" [hideTime]="false">
                      </ngx-mat-datetime-picker>
                      <mat-error>Campo requerido</mat-error>
                    </mat-form-field>
                  
                    <mat-form-field appearance="fill">
                      <mat-label>Fecha final</mat-label>
                      <input required="" (dateChange)="changeDate($event,false)" readonly="" [min]="report.start_datetime" matInput name="end_datetime" [(ngModel)]="report.end_datetime" [ngxMatDatetimePicker]="endPicker" placeholder="Fecha final">
                      <mat-datepicker-toggle matSuffix [for]="$any(endPicker)"></mat-datepicker-toggle>
                      <ngx-mat-datetime-picker [disabled]="false" #endPicker [showSpinners]="true" [showSeconds]="false"
                                              [stepHour]="1" [stepMinute]="1" [stepSecond]="1"
                                              [touchUi]="false" [enableMeridian]="true"
                                              [disableMinute]="false" [hideTime]="false">
                      </ngx-mat-datetime-picker>
                      <mat-error>Campo requerido</mat-error>
                    </mat-form-field>
                    <mat-checkbox class="fill" color="primary" name="set_current_device" [(ngModel)]="report.group_by_device">Mostrar datos agrupados por dispositivos</mat-checkbox>

                </div>
            </div>
            <div class="form-box__content-actions form-box__content-actions--align-end">
              <button mat-button class="btn btn-primary" type="submit" (click)="generateReport()">Generar</button>
            </div>
        </form>

    </div>
    <div class="loader" *ngIf="loading">
        <img src="../../../../assets/loader.svg">
    </div>
  </section>