import { Injectable } from '@angular/core';
import { IUserSetting } from 'src/app/shared/models/interfaces/user-data.model';
import { IWialonIPSTranmissionWebServiceConfiguration } from 'src/app/views/integrations/models/interfaces/iweb-service.model';

@Injectable({
  providedIn: 'root'
})
export class ObjectValidatorService {

  constructor() { }

  public isIUserSetting(obj:any):obj is IUserSetting{
    return typeof obj !="undefined" && obj != null && (typeof obj.user_id != "undefined" && !isNaN(obj.user_id));
  }
  public isIWialonIPSTranmissionWebServiceConfiguration(obj: any): obj is IWialonIPSTranmissionWebServiceConfiguration{
    return typeof obj != "undefined" && typeof obj.destination_ip_address != "undefined";
  }
}
