<section class="form-box-container animate__animated animate__fadeIn" *ngIf="this.viewsAvailable['binding_list'].show_view">
    <section class="form-box-container__header">
        
        <h2 class="form-box-container__header-title"><span class="icon"><i class="fa-solid fa-money-bill-trend-up"></i></span>Facturación - Vinculaciones</h2>
        <div class="form-box-container__header-options">
            <button class="btn btn-outline-secondary animate__animated" [ngClass]="{'animate__fadeOutLeft': !isUserDevicesSelected,' animate__fadeInDown': isUserDevicesSelected}" matTooltip="Quitar selección de la tabla de distribuidores" (click)="loadAllDevices()" ><i class="fa-solid fa-list-ul"></i></button>
            <button matTooltip="Renovaciones" class="btn btn-primary" (click)="openRenovationModal()"><i class="fa-solid fa-file-signature"></i><span class="description"> Renovaciones</span></button>                
            
            <button mat-icon-button [matMenuTriggerFor]="moreOptionsMenu" matTooltip="Ver más opciones" matTooltipPosition="above" aria-label="Opciones"><mat-icon>more_vert</mat-icon></button>
            
            <mat-menu #moreOptionsMenu="matMenu">
                <button mat-menu-item matTooltipPosition="left" class="text-primary" matTooltip="Ver registro de pagos" (click)="utils.setSelectedView(this.viewsAvailable,this.viewsAvailable['payment_record_list'].name)"><i class="fa-solid fa-file-invoice"></i> Registro de pagos</button>
                <button mat-menu-item class="text-primary" [matMenuTriggerFor]="exportMenu"><i class="fa-solid fa-file-arrow-down"></i>  Exportar datos</button>
            </mat-menu>
            <mat-menu #exportMenu="matMenu">
                <button class="text-primary  animate__animated animate__fadeIn animate__faster" mat-menu-item  matTooltip="Tabla de resumen de distribuidores"(click)="exportFile(distributorsByTotalGpsSorted,['email','name','devicesTotal'],'distByTotalRegisteredGps')" matTooltipPosition="right"><i class="fa-solid fa-users-viewfinder"></i> Distribuidores</button>
                <button class="text-primary animate__animated animate__fadeIn animate__faster"mat-menu-item   matTooltip="Tabla de dispositivos" (click)="exportFile(devicesWithBindDate,devicesBindHeader,'registeredDevices')" matTooltipPosition="right" ><i class="fa-solid fa-microchip"></i> Dispositivos</button>
            </mat-menu>
            <div class="search">
                <input id="inputSearchEmailInBindings" type="search" placeholder="Buscar email"(input)="search('bindings','distributorsByTotalGps')" [(ngModel)]="searchEmailByBinding">
                <label for="inputSearchEmailInBindings"><i class="fa-solid fa-magnifying-glass fa-sm"></i></label>
            </div>
        </div>
        
    </section>
    <div class="form-box two-panel two-panel--vertical  animate__animated animate__fadeIn">
        <div class="form-box__content two-panel two-panel--horizontal form-box__content-fill" >
            <section class="form-box__content-section form-box__content-fill">
                <div class="w100 overflow-auto">
                    <table mat-table matSort (matSortChange)="sortData($event,'distributorsByTotalGps')" [dataSource]="distributorsByTotalGpsSorted">
              
                      <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Ordenar por nombre"> Nombre </th>
                        <td mat-cell *matCellDef="let distributor" > {{distributor.name!=''?distributor.name: distributor.email}} </td>
                      </ng-container>
                      <ng-container matColumnDef="devicesTotal">
                        <th mat-header-cell *matHeaderCellDef  mat-sort-header sortActionDescription="Ordenar por fecha de modelo"> GPS </th>
                        <td mat-cell *matCellDef="let distributor"> {{distributor.devicesTotal}} </td>
                      </ng-container>
                      <ng-container matColumnDef="actions">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header disabled class="mat-header-center"> Acciones </th>
                        <td mat-cell *matCellDef="let distributor; index as counter" class="text-center"> 
                          <div class="actions-container">
                            <button (click)="loadUserDevices(range.value.start,range.value.end,distributor.id,true,counter,distributor.email)" matTooltip="Ver dispositivos"><i class="fa-solid fa-eye fa-sm"></i></button>
                          </div>
                        </td>
                      </ng-container>
              
                      <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell empty py-1" [attr.colspan]="displayedColumnsInDataSummary.length">Sin datos</td>
                    </tr>
                      <tr mat-header-row *matHeaderRowDef="displayedColumnsInDataSummary; sticky: true"></tr>
                      <tr mat-row *matRowDef="let row;index as counter; columns: displayedColumnsInDataSummary;"  [ngClass]="{'text-bg-warning':deviceIndexList[counter]}" ></tr>
                    
                    </table>
                  </div>
            </section>
            <section class="form-box__content-section form-box__content-auto">
                <strong class="form-box__content-title">Rango de fecha de datos filtrados</strong>
                <!--<section class="form-box__content-header">
                    <span class="form-box__content-subtitle"> Del {{(range.value.start |date:'longDate') + " - " + (range.value.end |date:'longDate')}}</span>
                </section>-->
                <div class="d-flex justify-content-center align-items-center ">
                    <mat-form-field appearance="fill">
                        <mat-label>Ingrese un rango de fecha</mat-label>
                        <mat-date-range-input  disabled [formGroup]="range" [rangePicker]="rangePicker">
                        <input formControlName="start"   matStartDate placeholder="yyyy-mm-dd">
                        <input formControlName="end" matEndDate placeholder="yyyy-mm-dd">
                        </mat-date-range-input>
                        <mat-datepicker-toggle matIconSuffix [for]="rangePicker"></mat-datepicker-toggle>
                        <mat-date-range-picker  #rangePicker disabled="false">
                        <mat-date-range-picker-actions>
                            <button class="btn btn-outline-secondary" style="margin:0.1rem;" mat-button matDateRangePickerCancel>Cancelar</button>
                            <button class="btn btn-primary" mat-raised-button color="primary" (click)="filterDistributorsBydateRange(range.value.start,range.value.end,true)"  matDateRangePickerApply>Aceptar</button>
                        </mat-date-range-picker-actions>
                        </mat-date-range-picker>
                    </mat-form-field> 
                </div>
            </section>
        </div>

        <div class="form-box__content form-box__content-fill" >
            <section class="form-box__content-section">
                <div class="w100 overflow-auto">
                    <table mat-table matSort (matSortChange)="sortData($event,'devicesWithBindDate')" [dataSource]="devicesWithBindDateSorted">
                
                        <ng-container matColumnDef="index">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Ordenar por nombre"> Nombre </th>
                        <td mat-cell *matCellDef="let device; index as counter"> {{counter + 1}} </td>
                        </ng-container>
                        <ng-container matColumnDef="observation">
                        <th mat-header-cell *matHeaderCellDef  mat-sort-header sortActionDescription="Ordenar por observación"> Vinculación </th>
                        <td mat-cell *matCellDef="let device">
                            <div class="d-flex justify-content-start align-items-center"><span [ngClass]="{'success': device.observation==this.dataService.deviceActivationStates.new.name,'warning':device.observation==this.dataService.deviceActivationStates.renewed.name,'danger':device.observation==this.dataService.deviceActivationStates.expired.name}">{{dataService.deviceActivationStates[device.observation]?.label??''}}</span></div>
                        </td>
                        </ng-container>
                        <ng-container matColumnDef="distributorName" >
                        <th mat-header-cell *matHeaderCellDef  mat-sort-header sortActionDescription="Ordenar por nombre de distribuidor"> Distribuidor </th>
                        <td mat-cell *matCellDef="let device">
                            {{device.distributorName || device.distributorEmail}}
                        </td>
                        </ng-container>

                        <ng-container matColumnDef="imei">
                        <th mat-header-cell *matHeaderCellDef  mat-sort-header sortActionDescription="Ordenar por imei"> Imei </th>
                        <td mat-cell *matCellDef="let device">{{device.imei}}</td>
                        </ng-container>

                        <ng-container matColumnDef="deviceName">
                        <th mat-header-cell *matHeaderCellDef  mat-sort-header sortActionDescription="Ordenar por nombre de dispositivo"> Nombre </th>
                        <td mat-cell *matCellDef="let device">{{device.name}}</td>
                        </ng-container>

                        <ng-container matColumnDef="plate_number">
                        <th mat-header-cell *matHeaderCellDef  mat-sort-header sortActionDescription="Ordenar por numero de placa"> Placa </th>
                        <td mat-cell *matCellDef="let device">{{device.plate_number}}</td>
                        </ng-container>

                        <ng-container matColumnDef="server_time">
                        <th mat-header-cell *matHeaderCellDef  mat-sort-header sortActionDescription="Ordenar por Última conexión"> Última conexión </th>
                        <td mat-cell *matCellDef="let device">{{device.server_time}}</td>
                        </ng-container>

                        <ng-container matColumnDef="created_at">
                        <th mat-header-cell *matHeaderCellDef  mat-sort-header sortActionDescription="Ordenar por fecha de creación"> Fecha de creación </th>
                        <td mat-cell *matCellDef="let device">{{device.created_at}}</td>
                        </ng-container>

                        <ng-container matColumnDef="activation_date">
                        <th mat-header-cell *matHeaderCellDef  mat-sort-header sortActionDescription="Ordenar por fecha de vinculación"> Fecha de vinculación </th>
                        <td mat-cell *matCellDef="let device">{{device.activation_date}}</td>
                        </ng-container>

                        <ng-container matColumnDef="expiration_date">
                        <th mat-header-cell *matHeaderCellDef  mat-sort-header sortActionDescription="Ordenar por fecha de expiración"> Fecha de expiración </th>
                        <td mat-cell *matCellDef="let device">{{device.expiration_date}}</td>
                        </ng-container>

                        <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell empty py-1" [attr.colspan]="displayedColumnsInGeneralTable.length - (!isUserDevicesSelected ? 0 : 1)">Sin datos</td>
                    </tr>
                        <tr mat-header-row *matHeaderRowDef="displayedColumnsInGeneralTable; sticky: true"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumnsInGeneralTable;"></tr>
                    
                    </table>
                    </div>
                    <div class="loader" *ngIf="loadingDeviceList">
                    <img src="../../../../../assets/loader.svg">
                </div>
            </section>

        </div>
    </div>
    <div class="loader" *ngIf="loading">
        <img src="../../../../../assets/loader.svg">
    </div>
</section>


<app-device-payment-records
(close)="utils.setSelectedView(this.viewsAvailable,this.viewsAvailable['binding_list'].name)"
*ngIf="viewsAvailable['payment_record_list'].show_view"
></app-device-payment-records>