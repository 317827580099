import { Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { Sort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/core/api/api.service';
import { DataService } from 'src/app/core/data/data.service';
import { MessageBoxService } from 'src/app/core/message-box/message-box.service';
import { ObjectInitializationService } from 'src/app/core/object-initialization/object-initialization.service';
import { UtilsService } from 'src/app/core/utils/utils.service';
import { ValidationService } from 'src/app/core/validation/validation.service';
import { ApiEndpointSgc } from 'src/app/shared/enums/api-endpoint-sgc.enum';
import { MODULE_OPERATIONS_SGC, RequestMethodHTTP } from 'src/app/shared/enums/common-enums.enum';
import { IApiRequestData, IPagination, ISort } from 'src/app/shared/models/interfaces/iapi-request-data.model';
import { IAutomation} from 'src/app/shared/models/interfaces/iautomation.model';
import { IResponseApi } from 'src/app/shared/models/interfaces/iresponse-api.model';
import { IUserPlanModule, IUserSessionData } from 'src/app/shared/models/interfaces/iuser-session-data.model';
import { IViewsAvailable } from 'src/app/shared/models/interfaces/views-available';

export enum AUTOMATION_TYPE_LIST {
  BILLING = 1,
  PORTFOLIO = 2,
  AFTER_SALE = 3
};
type TableColumnName = Pick<IAutomation,  "id" | "name" | "active" > & {"actions" :  boolean, "select": boolean};

@Component({
  selector: 'app-automations',
  templateUrl: './automations.component.html',
  styleUrls: ['./automations.component.scss']
})
export class AutomationsComponent implements OnInit {

  viewsAvailable: IViewsAvailable = {
    automations_list:{name:"automations_list", show_view: true},  
    automation_form:{name: "automation_form", show_view: false}
  };

  readonly MODULE_OPERATIONS_SGC = MODULE_OPERATIONS_SGC;
  user:IUserSessionData;
  loading: boolean = false;

  sort: ISort;
  pagination: IPagination;
  searchValue: string = "";

  displayedColumns: (keyof TableColumnName)[]= ["select", "name","active","actions"];

  automationsList: IAutomation[] = [];

  moduleId:number = 26;
  /**  @type {IUserPlanModule} Permisos sobre el módulo */
  permissionsDataSgc:IUserPlanModule;
  
  automationToEdit: IAutomation;
  /** @type {boolean}  Indica si  la automatizacion a editar es un registro nuevo */
  isNewAutomationToEdit: boolean = false;
  constructor(
    private api: ApiService,
    public dataService: DataService,
    public utils:UtilsService,
    public messageBox: MessageBoxService,
    private objectInitializationService: ObjectInitializationService,
    private validationService: ValidationService,
    private route:Router
  ){
    this.permissionsDataSgc = this.objectInitializationService.initializeIUserPlanModule();
    this.pagination = this.objectInitializationService.initializeIPagination(this.dataService.PAGE_SIZE_OPTION);
    this.pagination.page_size = this.dataService.PAGE_SIZE_OPTION[0];
    this.sort = this.objectInitializationService.initializeISort();
    this.user = this.dataService.getData("user") as IUserSessionData;
    this.automationToEdit = this.objectInitializationService.initializeIAutomation(0,undefined,this.user.id, 0);
  }
  ngOnInit(): void {
    this.dataService.checkPermissionModule(this.moduleId).then((permissions: IUserPlanModule) => {
        this.permissionsDataSgc = permissions;
        this.getAutomationsList();
    }).catch(() => {
      this.utils.showMsg("Página no autorizada","No tiene permisos para ver esta página, contacte al administrador");
      this.route.navigate(['/']);
    });
  }
  sortData(sort: Sort) {
    this.sort.direction = sort.direction;
    this.sort.by = sort.active;
    this.getAutomationsList();
  }
  paginator(event:PageEvent):void{
    this.pagination.current_page = event.pageIndex;    
    this.pagination.page_size = event.pageSize;
    this.getAutomationsList();
  }
  /**
   *  @description Abre la vista de edición de automatización
   * @param automation La automaizacion a editar
   */
  openAutomationform(automation?: IAutomation){
    this.automationToEdit =  typeof automation != "undefined" ? automation : this.objectInitializationService.initializeIAutomation(0,undefined,this.user.id, 0);
    this.isNewAutomationToEdit = typeof automation == "undefined";
    this.utils.setSelectedView(this.viewsAvailable,this.viewsAvailable['automation_form'].name);
  }
  closeAutomationForm(event: {updated: boolean, automation_result: IAutomation}){
    if(this.isNewAutomationToEdit && event.updated)
      this.automationsList.unshift(event.automation_result);
    this.utils.setSelectedView(this.viewsAvailable,this.viewsAvailable['automations_list'].name);
  }
  getAutomationsList(){
    this.loading = true;
    this.automationsList = [];
    this.pagination.length_page = 0;
    let data:string =  this.api.getCommonQueryParameters("get",this.user.hash,this.searchValue,this.pagination.current_page,this.pagination.page_size,this.sort.by!,this.sort.direction!);
    this.api.getData(ApiEndpointSgc.AUTOMATION,data,true).then((data: unknown)=>{
      if(this.validationService.isResponseApi(data) && data.status == 1){
        this.pagination.length_page = data.total ?? 0;
        this.automationsList = data.data as IAutomation[];   
      }
    }).catch((error:unknown)=>{
      this.utils.showResultRequest("error","Información",this.api.getDefaultMessage("la lista de automatizaciones",false,false,"GET"));
    }).finally(()=>this.utils.hideLoading(()=>this.loading = false));
  }
  updateStatus(event: MatSlideToggleChange, automation: IAutomation){
    let dataToSend: IApiRequestData & Pick<IAutomation, "active" | "id"> = {
      action: "update",
      user_api_hash: this.user.hash,
      id: automation.id,
      active:event.checked ? 1 : 0,
    };
    this.api.createData(dataToSend, ApiEndpointSgc.AUTOMATION,true).then((response: unknown) => {
      if(this.validationService.isResponseApi(response)){
        this.messageBox.openSnackBar( response.status == 1 ? "Estado actualizado" : "No se pudo cambiar el estado","Aceptar");
        if(response.status == 1)
          automation.active = dataToSend.active;
      }
    }).catch((error: unknown) => {
      this.utils.showResultRequest("error","Información",this.api.getDefaultMessage("el estado",true, false,RequestMethodHTTP.POST));
    }).finally(()=>this.utils.hideLoading(()=>this.loading = false));
  }
  deleteAutomation(automation:IAutomation){
    this.utils.showConfirm("Confirmar acción", "¿Esta seguro de eliminar la automatización? ", "Eliminar", "Cancelar").then(async () => {
      this.loading = true;
      let response: IResponseApi | null | unknown = null;
      try {
        let dataToSend: IApiRequestData = { user_api_hash: this.user.hash, action: "delete", id: automation.id};
        response = await this.api.createData(dataToSend, ApiEndpointSgc.AUTOMATION, true);   
      } catch (error: unknown) {
        this.utils.showResultRequest("error","Información",this.api.getDefaultMessage("el estado",true, false, RequestMethodHTTP.DELETE));
      } finally {
        this.utils.hideLoading(() => this.loading = false);
        if (this.validationService.isResponseApi(response)) {
          this.messageBox.openSnackBar( response.message ?? "","Aceptar");
          if (response.status == 1)
            this.getAutomationsList();
        }
      }
    }).catch(() => { })
    .finally(()=>this.utils.hideLoading(()=>this.loading = false));
  }
}