import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

//importacion de las vistas, componentes
import { BillingComponent } from '../views/billing/billing.component';
import { ClientsComponent } from '../views/clients/clients.component';
import { DistributorsComponent } from '../views/distributors/distributors.component';
import { HomeComponent } from '../views/home/home.component';
import { MyGpsComponent } from '../views/my-gps/my-gps.component';
import { PaymentsComponent } from '../views/payments/payments.component';
import { TasksComponent } from "../views/tasks/tasks.component";
import { MyPortfolioComponent } from "../views/my-portfolio/my-portfolio.component";
import { DashboardComponent } from './dashboard.component';
import { MyAccountComponent } from "../views/my-account/my-account.component";
import { AdminPlansComponent } from "../views/admin-plans/admin-plans.component";
import { IntegrationsComponent } from "../views/integrations/integrations.component";
import { WebsiteComponent } from '../views/website/website.component';
import { FleetControlComponent } from '../views/fleet-control/fleet-control.component';
import { DriverManagementComponent } from '../views/driver-management/driver-management.component';
import { DeviceChecklistManagementComponent } from '../views/fleet-control/components/device-checklist-management/device-checklist-management.component';
import { DeviceStateManagementComponent } from '../views/fleet-control/components/device-state-management/device-state-management.component';
import { RouteControlReportComponent } from '../views/pvs-reports/components/route-control-report/route-control-report.component';
import { DriverBehaviorComponent } from '../views/pvs-reports/components/driver-behavior/driver-behavior.component';
import { FleetProductivityComponent } from '../views/pvs-reports/components/fleet-productivity/fleet-productivity.component';
import { AutomationsComponent } from '../views/automations/automations.component';
import { ReportsComponent } from '../views/reports/reports.component';

const routes: Routes = [
  { path: '', 
    component: DashboardComponent,
    children:[
      {
        path:'billing', component: BillingComponent,
      },
      {
        path:'clients', component:ClientsComponent,
      },
      {
        path:'fleet-control', component:FleetControlComponent,
      },
      {
        path:'fleet-control/device-checklist-management', component:DeviceChecklistManagementComponent,
      },
      {
        path:'fleet-control/device-state-management', component:DeviceStateManagementComponent,
      },
      {
        path:'pvs-reports/route-control-report', component:RouteControlReportComponent,
      },
      {
        path:'pvs-reports/driver-behavior', component:DriverBehaviorComponent,
      },
      {
        path:'pvs-reports/fleet-productivity', component:FleetProductivityComponent,
      },
      {
        path:'driver-management', component:DriverManagementComponent,
      },
      {
        path:'reports', component:ReportsComponent,
      },
      {
        path:'distributors', component:DistributorsComponent,
      },
      {
        path:'home', component:HomeComponent,
      },
      {
        path:'my-gps', component:MyGpsComponent,
      },
      {
        path:'payments', component:PaymentsComponent,
      },
      {
        path:'my-portfolio', component:MyPortfolioComponent,
      },
      {
        path:'tasks', component:TasksComponent,
      },
      {
        path:'website', component:WebsiteComponent
      },
      {
        path:'my-account', component:MyAccountComponent
      },
      {
        path:'admin-plans', component:AdminPlansComponent
      },
      {
        path:'integrations', component:IntegrationsComponent
      },
      {
        path:'automations', component:AutomationsComponent
      },
      {
        path:'',
        loadChildren: () => import('../user-driver/user-driver.module').then(m => m.UserDriverModule) 
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DashboardRoutingModule { }
