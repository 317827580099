import { Component } from '@angular/core';

@Component({
  selector: 'app-phone-line-integration-details',
  templateUrl: './phone-line-integration-details.component.html',
  styleUrls: ['./phone-line-integration-details.component.scss']
})
export class PhoneLineIntegrationDetailsComponent {

}
