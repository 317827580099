<div class="border-bottom-secondary d-flex justify-content-between ">
  <h2 *ngIf="viewsAvailable['invoice_creation'].show_view">Facturación </h2>
  <small >{{currentDate | date:"longDate"}}</small>
</div>
<mat-dialog-content class="mat-typography form-box-container">
<ng-container *ngIf="viewsAvailable['invoice_creation'].show_view ">
  <section class="form-box animate__animated animate__fadeIn" *ngIf="invoiceCreationOption.create_invoice_list.selected">
    <div class="form-box__content form-box__content-h-auto">
      <div  class="form-box__content-inputs">
        <div  class="form-box__content-group-fields">
          <mat-form-field appearance="fill" floatLabel="always">
            <mat-label>Cantidad de facturas a registrar</mat-label>
            <input matInput type="number" min="1" placeholder="0" [(ngModel)]="invoiceCreationOption.create_invoice_list.quantity_to_create" spellcheck="false" name="quantity">
            <mat-icon matSuffix class="text-info" matTooltip="Indica la cantidad de registros futuros a crear. Estos se crean según el plan de cada dispositivo seleccionado">help</mat-icon>
          </mat-form-field>
        </div>
      </div>
      <section class="form-box__content-section">
        <div class="overflow-auto w-100">
          <table  mat-table [dataSource]="dataSourceForInvoiceList">
  
              <ng-container matColumnDef="select">
                <th mat-header-cell *matHeaderCellDef>
                  <mat-checkbox (change)="$event ? toggleAllRows() : null"
                                [checked]="selection.hasValue() && isAllSelected()"
                                [indeterminate]="selection.hasValue() && !isAllSelected()"
                                color="primary"
                                >
                  </mat-checkbox>
                </th>
                <td mat-cell *matCellDef="let row">
                  <mat-checkbox (click)="$event.stopPropagation()"
                                (change)="$event ? selection.toggle(row) : null"
                                [checked]="selection.isSelected(row)"
                                color="primary"
                                >
                  </mat-checkbox>
                </td>
              </ng-container>
            <ng-container matColumnDef="device_plate_number">
              <th mat-header-cell *matHeaderCellDef> Placa </th>
              <td mat-cell *matCellDef="let element"> {{element.device_plate_number}} </td>
            </ng-container>
              <ng-container matColumnDef="device_name">
                <th mat-header-cell *matHeaderCellDef> Nombre </th>
                <td mat-cell *matCellDef="let element"> {{element.device_name}} </td>
              </ng-container>
              <ng-container matColumnDef="vehicle_type_name">
                <th mat-header-cell *matHeaderCellDef> Tipo de dispositivo </th>
                <td mat-cell *matCellDef="let element"> {{element.vehicle_type_name}} </td>
              </ng-container>
              <ng-container matColumnDef="device_plan_periodicity_name">
                <th mat-header-cell *matHeaderCellDef> Periodicidad del plan </th>
                <td mat-cell *matCellDef="let element"> {{element.device_plan_periodicity_name}} </td>
              </ng-container>
              <ng-container matColumnDef="price">
                <th mat-header-cell *matHeaderCellDef> Precio </th>
                <td mat-cell *matCellDef="let element"> {{element.price | currency:element.monetary_unit_code}} </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumnsForInvoiceList"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumnsForInvoiceList;"
                  (click)="selection.toggle(row)">
              </tr>
          </table>   
        </div>
      </section>
    </div>
  </section>
  
  <section class="form-box animate__animated animate__fadeIn" *ngIf="invoiceCreationOption.create_an_invoice.selected">
    <div class="form-box__content form-box__content-h-auto">
      <div  class="form-box__content-inputs">
        <div  class="form-box__content-group-fields">
          <mat-form-field class="fill">
            <mat-label>Selecciona un cliente</mat-label>
            <mat-select (closed)="clearClientSearchFilter()" [(ngModel)]="invoiceCreationOption.create_an_invoice.invoice.buyer_id" (selectionChange)="getSelectedUserDevices()" placeholder="Buscar...">
              <mat-option>
                <ngx-mat-select-search [noEntriesFoundLabel]="'No se encontraron resultados'" placeholderLabel="Buscar cliente..." [(ngModel)]="inputSearchClient"  (keyup)="searchClient()">
                  <mat-icon (click)="clearClientSearchFilter()" ngxMatSelectSearchClear>close</mat-icon>
                </ngx-mat-select-search>
              </mat-option>
          
              <mat-option *ngFor="let user of filteredClientList" [value]="user.id">
                {{user.email}} <small *ngIf="user.name !=''">{{" - "+user.name}}</small>                   
              </mat-option>
            </mat-select>
          </mat-form-field>
  
          <mat-form-field appearance="fill">
            <mat-label>Metodo de pago</mat-label>
            <mat-select [(ngModel)]="invoiceCreationOption.create_an_invoice.invoice.payment_method_id" name="paymentMethod">
              <mat-option *ngFor="let option of paymentMethodList" [value]="option.id">
                {{option.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
  
          <mat-form-field appearance="fill">
            <mat-label>Observación</mat-label>
            <mat-select [(ngModel)]="invoiceCreationOption.create_an_invoice.invoice.observation" name="observation">
              <mat-option value="pendiente">pendiente</mat-option>
              <mat-option value="pagada">pagada</mat-option>
            </mat-select>
          </mat-form-field>
  
  
          <ng-container *ngIf="invoiceCreationOption.create_an_invoice.invoice.observation=='pagada'">
            <mat-form-field appearance="fill">
              <mat-label>Medio de pago</mat-label>
              <mat-select [(ngModel)]="invoiceCreationOption.create_an_invoice.invoice.payment.payment_medium_id" name="paymentMedium">
                <mat-option *ngFor="let item of paymentMediumList"[value]="item.id">{{item.name |titlecase}}</mat-option>
              </mat-select>
            </mat-form-field>
            <div class="d-flex justify-content-center align-items-center gutter-1" *ngIf="isAFinancialProductSelected()">
              <mat-form-field appearance="fill" class="fill">
                <mat-label>Entidad financiera</mat-label>
                <mat-select [(ngModel)]="invoiceCreationOption.create_an_invoice.invoice.payment.financial_institution_id" name="paymentMethod">
                  <mat-option *ngFor="let item of financialInstitutionList"[value]="item.id">{{item.name |titlecase}}</mat-option>
                </mat-select>
              </mat-form-field>
              <button class="btn btn-primary"matTooltip="Agregar otra entidad financiera" (click)="showViewPaymentOption()"><i class="fa-solid fa-plus"></i></button>
            </div>
          </ng-container>
  
          <mat-form-field 
          class="fill animate__animated  animate__faster " [ngClass]="{'animate__fadeIn': deviceList.length>0,'animate__fadeOutDown': deviceList.length==0}">
            <mat-label>Dispositivos a facturar</mat-label>
            <mat-select (closed)="clearSearchDevice()" [multiple]="true"[(ngModel)]="invoiceCreationOption.create_an_invoice.invoice.devices" (selectionChange)="updateDataSourceForAInvoiceTable()"  placeholder="Buscar...">
              <mat-option>
                <ngx-mat-select-search [noEntriesFoundLabel]="'No se encontraron resultados'" placeholderLabel="Buscar dispositivo..." [(ngModel)]="inputSearchDevice"  (keyup)="searchDevice()">
                  <mat-icon (click)="clearSearchDevice()" ngxMatSelectSearchClear>close</mat-icon>
                </ngx-mat-select-search>
              </mat-option>
              <mat-option *ngFor="let device of filteredDeviceList" [value]="device">
                {{device!.name}} <span class="description"> {{!utils.isNullOrEmpty(device!.plate_number)?(' - placa: '+device!.plate_number):(' - imei: '+device!.imei) }}</span>
              </mat-option>
            </mat-select>
            <mat-icon matSuffix class="text-info" matTooltip="Seleccione cada uno de los dispositivos a incluir en la factura">help</mat-icon>
          </mat-form-field>
        </div>
      </div>
  
      <section class="form-box__content-section animate__animated animate__fadeInUp animate__faster" *ngIf="invoiceCreationOption.create_an_invoice.invoice.devices.length>0">
        <div class="overflow-auto w-100">
          <table mat-table [dataSource]="dataSourceForAInvoiceTable">
            <ng-container matColumnDef="imei">
              <th mat-header-cell *matHeaderCellDef> Imei </th>
              <td mat-cell *matCellDef="let element"> {{element.imei}} </td>
            </ng-container>
            <ng-container matColumnDef="plate_number">
              <th mat-header-cell *matHeaderCellDef> Placa </th>
              <td mat-cell *matCellDef="let element"> {{element.plate_number}} </td>
            </ng-container>
              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef> Nombre </th>
                <td mat-cell *matCellDef="let element"> {{element.name}} </td>
              </ng-container>
              <ng-container matColumnDef="personalized_plan.billing_frequency_name">
                <th mat-header-cell *matHeaderCellDef> Periodicidad del plan </th>
                <td mat-cell *matCellDef="let element"> {{element.personalized_plan.billing_frequency_name}} </td>
              </ng-container>
              <ng-container matColumnDef="personalized_plan.unit_price">
                <th mat-header-cell *matHeaderCellDef> Precio </th>
                <td mat-cell *matCellDef="let element"> {{element.personalized_plan.unit_price | currency:element.personalized_plan.monetary_unit_code}} </td>
              </ng-container>
              <ng-container matColumnDef="descriptionTotalPrice">
                <td mat-footer-cell *matFooterCellDef colspan="7" class="text-end">
                    Total: <span class="fw-bold">{{ printTotalPriceForAInvoiceTable()}}</span>
                </td>
            </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumnsForAInvoice"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumnsForAInvoice;"
                  (click)="selection.toggle(row)">
              </tr>
              <tr mat-footer-row *matFooterRowDef="['descriptionTotalPrice']"></tr>
          </table>   
        </div>
      </section>
    </div>
  </section>
    <div class="loader" *ngIf="loading">
        <img src="../../../assets/loader.svg">
    </div>
  
</ng-container>

<app-payment-options-basic-data 
(close)="showViewInvoiceCreation()"
*ngIf="viewsAvailable['payment_option'].show_view" [addOnlyPaymentOptionName]="true"></app-payment-options-basic-data>

</mat-dialog-content>
<mat-dialog-actions align="end" *ngIf="viewsAvailable['invoice_creation'].show_view">
    <button  class="btn btn-primary me-1"(click)="createInvoice()" [disabled]="invoiceCreationOption.create_an_invoice.selected && invoiceCreationOption.create_an_invoice.invoice.devices.length<1" >Registrar</button>  
    <button mat-button [mat-dialog-close]="invoiceCreated" class="btn btn-outline-secondary">Cerrar</button>  
</mat-dialog-actions>

