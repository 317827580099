<section class="form-box-container animate__animated animate__fadeIn">
    <section class="form-box-container__header">
        <h2 class="form-box-container__header-title"><span class="icon"><i class="fa-solid fa-route"></i></span>Reporte control de rutas</h2>
        <div class="form-box-container__header-options">
            <button [disabled]="devicesSelected.length<1" *ngIf="devicesSelected.length < 2" class="btn btn-primary mat-datepicker overflow-hidden"  matTooltip="Filtrar por rango de fecha">
                <mat-datepicker-toggle [disabled]="devicesSelected.length<1" style="display:inline-block; font-size: 0.5rem;opacity:0;position:absolute;inset:0;" matIconSuffix [for]="queryDateRange"></mat-datepicker-toggle>
                <i class="fa-solid fa-calendar-week fa-sm"></i> <span  class="description"> Filtrar</span>
                <mat-form-field class="animate__animated animate__fadeIn " style="width: 0;height: 0;position:absolute;opacity: 0;"  appearance="fill">
                    <mat-date-range-input  class="d-none"  disabled [formGroup]="dateRangeToConsult" [rangePicker]="queryDateRange">
                      <input formControlName="start"   matStartDate placeholder="yyyy-mm-dd">
                      <input formControlName="end" matEndDate placeholder="yyyy-mm-dd">
                    </mat-date-range-input>
                    
                    <mat-date-range-picker class="d-none" #queryDateRange disabled="false">
                    <mat-date-range-picker-actions>
                        <button class="btn btn-outline-secondary" style="margin:0.1rem;" mat-button matDateRangePickerCancel>Cancelar</button>
                        <button class="btn btn-primary" mat-raised-button color="primary" (click)="getDeviceList()"  matDateRangePickerApply>Aceptar</button>
                    </mat-date-range-picker-actions>
                    </mat-date-range-picker>
                </mat-form-field>
            </button>
            <button [disabled]="devicesSelected.length<1" *ngIf="devicesSelected.length >1" class="btn btn-primary mat-datepicker overflow-hidden ff"  matTooltip="Filtrar por fecha">
              <mat-datepicker-toggle [disabled]="devicesSelected.length<1" style="display:inline-block; font-size: 0.5rem;opacity:0;position:absolute;inset:0;"  matSuffix [for]="queryByDate"></mat-datepicker-toggle>
              <i class="fa-solid fa-calendar-week fa-sm"></i> <span  class="description"> Filtrar</span>
              
              <mat-form-field appearance="fill" class="animate__animated animate__fadeIn " style="width: 0;height: 0;position:absolute;opacity: 0;">
                <input matInput [matDatepicker]="queryByDate" [(ngModel)] ="dateToConsult">
                <mat-datepicker #queryByDate>
                  <mat-datepicker-actions>
                    <button mat-button matDatepickerCancel>Cancelar</button>
                    <button mat-raised-button color="primary" (click)="getDeviceList()" matDatepickerApply>Aceptar</button>
                  </mat-datepicker-actions>
                </mat-datepicker>
              </mat-form-field>
              
            </button>
            <button class="btn btn-outline-secondary" name="exportMenu" matTooltip="Exportar datos" matTooltipPosition="below"
            [matMenuTriggerFor]="exportMenu"><i class="fa-solid fa-download fa-sm"></i><span  class="description"> Exportar</span></button>
            <mat-menu #exportMenu="matMenu" > 
                <!--<button class="text-primary" mat-menu-item (click)="exportFile('PDF')"><i class="fa-solid fa-file-pdf"></i> PDF</button>-->
                <button class="text-primary" mat-menu-item (click)="exportFile('CSV')"><i class="fa-solid fa-file-csv"></i> CSV</button>         
            </mat-menu>
            
            <div class="search">
              <mat-form-field id="deviceList" appearance="fill"matTooltip="Selecciona el dispositivo">
                <mat-select (closed)="clearSearchDevice()" multiple=""  (selectionChange)="isAllDevicesSelected()" [(ngModel)]="devicesSelected" placeholder="Buscar...">
                  <mat-option>
                    <ngx-mat-select-search [noEntriesFoundLabel]="'Sin resultados'" placeholderLabel="Buscar dispositivo..." [hideClearSearchButton]="true" [(ngModel)]="deviceToSearch" (keyup)="searchDevice()" name="deviceToSearch">
                      <mat-icon (click)="clearSearchDevice()" ngxMatSelectSearchClear>close</mat-icon>
                    </ngx-mat-select-search>
                  </mat-option>
                  <mat-option (click)="toggleSelectAllDevicesToAssociate()" [value]="optionSelectAllDevices.id">{{optionSelectAllDevices.name}}</mat-option>
                  <mat-option *ngFor="let device of availableDeviceListFiltered" [value]="device.id" color="primary">
                    {{validationService.isNullOrEmpty(device.plate_number)?device.name:device.plate_number}}                   
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
        </div>
    </section>
    <div class="form-box animate__animated animate__fadeIn">
        <div class="form-box__content form-box__content-h-auto">
            <div class="w-100 overflow-auto">
                <table mat-table matSort (matSortChange)="sortData($event)" [dataSource]="deviceList">
                    <ng-container [matColumnDef]="pvsReportsService.NUMBERING_COLUMN_HEADER.NAME">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Ordenar por fecha"> {{pvsReportsService.NUMBERING_COLUMN_HEADER.LABEL}} </th>
                      <td mat-cell *matCellDef="let i = index"> {{i+1}} </td>
                    </ng-container>
                    <ng-container matColumnDef="device_date">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Ordenar por fecha"> Fecha </th>
                      <td mat-cell *matCellDef="let device"> {{device.device_date}} </td>
                    </ng-container>
                    <ng-container matColumnDef="plate_number">
                      <th mat-header-cell *matHeaderCellDef> Placa </th>
                      <td mat-cell *matCellDef="let device"> 
                        {{validationService.isNullOrEmpty(device.plate_number)?device.name:device.plate_number}}
                        <!--<mat-icon aria-hidden="false" class="text-warning" aria-label="dispositivo no tiene definido un número de placa" fontIcon="info"></mat-icon>-->
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="start_address">
                      <th mat-header-cell *matHeaderCellDef> Inicio recorrido </th>
                      <td mat-cell *matCellDef="let device">
                        <span class="d-flex align-items-center justify-content-between">{{device.start_address}} 
                          <button mat-icon-button>
                            <mat-icon aria-hidden="false" (click)="utils.redirectToMap(device.start_latitude, device.start_longitude)" class="text-info" aria-label="Ver ubicación" fontIcon="location_on"></mat-icon>                         
                          </button>
                        </span>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="end_address">
                      <th mat-header-cell *matHeaderCellDef> Fin recorrido </th>
                      <td mat-cell *matCellDef="let device"> 
                        <span class="d-flex align-items-center justify-content-between">{{device.end_address}} 
                          <button mat-icon-button>
                            <mat-icon aria-hidden="false" class="text-info" (click)="utils.redirectToMap(device.end_latitude, device.end_longitude)" aria-label="Ver ubicación" fontIcon="location_on"></mat-icon>                         
                          </button>
                        </span>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="distance">
                      <th mat-header-cell *matHeaderCellDef> Kilometraje </th>
                      <td mat-cell *matCellDef="let device"> {{device.distance | number:'1.0-2' }} </td>
                    </ng-container>
                    <ng-container matColumnDef="current_driver_name">
                      <th mat-header-cell *matHeaderCellDef> Conductor </th>
                      <td mat-cell *matCellDef="let device"> {{device.current_driver_name }} </td>
                    </ng-container>
                    <ng-container matColumnDef="footer">
                      <td mat-footer-cell *matFooterCellDef [colSpan]="displayedColumns.length"  class="text-small">
                        <span class="d-flex justify-content-between align-items-center">
                          <span>Filtro {{!(devicesSelected.length >1) ?('desde '+(footerData.start_date | date:'mediumDate')+' a '+(footerData.end_date | date:'mediumDate')):('del día '+(footerData.start_date | date:'mediumDate'))}}</span>
                          <span><strong>Total km: </strong>{{totalKilometersQuantity | number:'1.0-2'}}<mat-icon matTooltip="Este campo indica el total de km de la página actual" class="cursor-pointer fs-normal text-warning">info</mat-icon> </span>
                        </span>
                      </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    <tr mat-footer-row *matFooterRowDef="['footer'];sticky:true"></tr>
                    <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell empty py-1" [attr.colspan]="displayedColumns.length">Sin datos</td>
                    </tr>
                </table>
            </div>
              <mat-paginator [length]="lengthPage" [pageSize]="pageSize" [pageIndex]="currentPage"
              [pageSizeOptions]="pageSizeOptions" (page)="paginator($event)"
              aria-label="Selecciona pagina de la lista de dispositivos">
            </mat-paginator>
        </div>
    </div>
    <div class="loader" *ngIf="loading">
        <img src="../../../../../assets/loader.svg">
    </div>
</section>