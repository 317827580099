import { Component, OnInit,Inject } from '@angular/core';
import { DataService } from '../../../../core/data/data.service';
import { ApiService } from 'src/app/core/api/api.service';
import { UtilsService } from 'src/app/core/utils/utils.service';
import { MatDialog,MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import {SelectionModel} from '@angular/cdk/collections';
import {MatTableDataSource} from '@angular/material/table';
import { Router } from '@angular/router';
import { IInvoice, IInvoiceDetail } from 'src/app/shared/models/interfaces/iinvoice.model';
import { IFinancialInstitution, IPaymentMedium, IPaymentMethod } from 'src/app/shared/models/interfaces/ipayment.model';
import { ValidationService } from 'src/app/core/validation/validation.service';
import { RequestMethodHTTP } from 'src/app/shared/enums/common-enums.enum';
import { IDeviceGeneral } from 'src/app/shared/models/interfaces/idevice.model';
import { IViewsAvailable } from 'src/app/shared/models/interfaces/views-available';
import { IUserSessionData } from 'src/app/shared/models/interfaces/iuser-session-data.model';

interface IClientNotification {
  notify_via_email: boolean,
  notify_via_text_message: boolean,
  contact_email: string,
  contact_phone_number: string
}
@Component({
  selector: 'app-invoice-creation',
  templateUrl: './invoice-creation.component.html',
  styleUrls: ['./invoice-creation.component.scss']
})
export class InvoiceCreationComponent implements OnInit {
  loading:boolean=false;
  invoiceCreated:boolean=false;//indica si se realiza la creacion de las facturas
  user:IUserSessionData;

  /**
   *@type {string} Almacena la fecha actual como un string
   */
  currentDate: string = "";
  /** Almacena la lista de vistas disponibles en el componente */
  viewsAvailable:IViewsAvailable={
    payment_option:{name:"payment_option",show_view:false},
    invoice_creation:{name:"invoice_creation",show_view:true}
  };
  displayedColumnsForInvoiceList: string[] = ['select', 'device_plate_number', 'device_name', 'vehicle_type_name','device_plan_periodicity_name', 'price'];
  dataSourceForInvoiceList:any = new MatTableDataSource();
  selection = new SelectionModel<any>(true, []);

  /**
   * Indica los items de si se notificara al cliente la generacion de la factura y a traves de que medios
   */
  clientNotification: IClientNotification = this.initializeIClientNotification();
  invoiceCreationOption:any={
    create_invoice_list:{
      selected:false,
      quantity_to_create:1,
      invoice:null,
    },
    create_an_invoice:{
      selected:false,
      invoice:{
        buyer_id:null,
        observation:"pendiente",
        payment_method_id:null,
        devices:[],
        payment:{
          payment_medium_id:null,
          financial_institution_id:null
        }
      }

    }
  }
  //variables para la opcion de crear factura
  inputSearchDevice:string="";
  deviceList:Partial<IDeviceGeneral[]>=[];//lista de dispositivos del usuario
  filteredDeviceList:Partial<IDeviceGeneral[]>=[];

  inputSearchClient:string="";
  clientList:any=[];
  filteredClientList:any=[];  
  
  displayedColumnsForAInvoice: string[] = ['imei','name','plate_number','personalized_plan.billing_frequency_name', 'personalized_plan.unit_price'];
  dataSourceForAInvoiceTable:any = new MatTableDataSource();
  
  paymentMediumList: IPaymentMedium[] = [];
  paymentMethodList: IPaymentMethod[] = [];
  financialInstitutionList: IFinancialInstitution[] = [];

  moduleId:number=7;
  permissionsData:any=[];//permisos sobre el modulo
  constructor(
    private dialogRef: MatDialogRef<InvoiceCreationComponent>,
    private dataService:DataService,
    private api:ApiService,
    public utils:UtilsService,
    private router:Router,
    public validationService: ValidationService,
    @Inject(MAT_DIALOG_DATA) public data: {action: "createInvoiceList"| "createInvoice", invoice?:IInvoice,detail?:IInvoiceDetail}

  ){
    this.currentDate = this.utils.getDatetime();
    this.user = this.dataService.getData("user");
  }
  ngOnInit(): void {

    this.dataService.checkPermissionModule(this.moduleId).then((permissions: any) => {
      this.permissionsData = permissions;
      if(this.data.action == "createInvoiceList"){
        this.invoiceCreationOption.create_invoice_list.selected=true;
        this.invoiceCreationOption.create_invoice_list.invoice =this.data.invoice;
        this.dataSourceForInvoiceList = new MatTableDataSource(this.data.invoice!.details);
      }else{//cuando es crear una factura
        this.invoiceCreationOption.create_an_invoice.selected=true;
        this.getBillingOptionsList();
        this.getUtilsList();
      }
    }).catch(() => {
      this.utils.showMsg("Página no autorizada","No tiene permisos para ver esta página, contacte al administrador");
      this.router.navigate(['/']);
    });

  }
  showViewInvoiceCreation(){
    this.getFinancialInstitutionList();
    this.utils.setSelectedView(this.viewsAvailable,this.viewsAvailable["invoice_creation"].name);
  }
  showViewPaymentOption(){
    this.utils.setSelectedView(this.viewsAvailable,this.viewsAvailable["payment_option"].name);
  }
  getBillingOptionsList(){
    this.api.getData("user","?action=getResponsibleUsers&user_api_hash="+this.user.hash,true).then((responsibleResult:any)=>{
      if(responsibleResult.status==1){
        this.filteredClientList= responsibleResult.data;
        this.clientList= responsibleResult.data;
      }
    }).catch((error:any)=>{});
  }
  async getFinancialInstitutionList(): Promise<void> {
    try {
      const financialInstitutionList = await this.api.getData("financialInstitution","?action=get&user_api_hash="+this.user.hash,true);
      if(this.validationService.isResponseApi(financialInstitutionList) && financialInstitutionList.status == 1)
        this.financialInstitutionList = financialInstitutionList.data as IFinancialInstitution[];
      else throw new Error("la lista de instituciones financieras");
    } catch (error:unknown) {
      if(error instanceof Error)
        this.utils.showResultRequest("error","Información",this.api.getDefaultMessage(error.message,true, false,RequestMethodHTTP.GET));
    }
  }
  async getUtilsList(){
    try {
      const [paymentMediumList, paymentMethodList, financialInstitutionList] = await Promise.all([this.api.getData("paymentMedium","?action=get&user_api_hash="+this.user.hash,true), 
        this.api.getData("paymentMethod","?action=get&user_api_hash="+this.user.hash,true), this.api.getData("financialInstitution","?action=get&user_api_hash="+this.user.hash,true)]);
      if(this.validationService.isResponseApi(paymentMediumList) && paymentMediumList.status == 1)
        this.paymentMediumList = paymentMediumList.data as IPaymentMedium[];
      else throw new Error("la lista de medios de pagos");
      if(this.validationService.isResponseApi(paymentMethodList) && paymentMethodList.status == 1)
        this.paymentMethodList = paymentMethodList.data as IPaymentMethod[];
      else throw new Error("la lista de métodos de pagos");
      if(this.validationService.isResponseApi(financialInstitutionList) && financialInstitutionList.status == 1)
        this.financialInstitutionList = financialInstitutionList.data as IFinancialInstitution[];
      else throw new Error("la lista de instituciones financieras");
    } catch (error:unknown) {
      if(error instanceof Error)
        this.utils.showResultRequest("error","Información",this.api.getDefaultMessage(error.message,true, false,RequestMethodHTTP.GET));
    }    
  }
  createInvoice(){
    this.loading = true;
    let dataToSend:any = { notify: this.initializeIClientNotification() , user_api_hash:this.user.hash,action:"generateInvoice"},error="",titleModal = "",messageModal="";
    if(this.invoiceCreationOption.create_invoice_list.selected){
      titleModal = "Confirmar generación de próximas facturas";
      messageModal = "<small>Los dispositivos seleccionados se registrarán de acuerdo al plan establecido para cada uno.</small>";
      let data ={
        details:this.selection.selected,
        quantity:this.invoiceCreationOption.create_invoice_list.quantity_to_create,
        on_previous_invoice:this.invoiceCreationOption.create_invoice_list.invoice,
        buyer_id:this.invoiceCreationOption.create_invoice_list.invoice.buyer_id,
        details_to_invoice:this.selection.selected
      };
      dataToSend = Object.assign(dataToSend,data);

      if(dataToSend.quantity<1)
        error="La cantidad de registros a crear debe ser mayor a 0";
      else if(this.selection.selected.length<1)
        error ="Debe seleccionar al menos un dispositivo de la lista";

    }else if(this.invoiceCreationOption.create_an_invoice.selected){
      titleModal ="Confirmar creación de factura";
      messageModal = "<small>Una vez creada esta aparecerá en la lista de facturas del mes actual</small>";
      dataToSend = Object.assign(dataToSend,this.invoiceCreationOption.create_an_invoice.invoice);
      if(dataToSend.buyer_id ==null){
        error = "Tiene que indicar a qué cliente se factura";
      }else if(dataToSend.devices.length<1){
        error = "Tiene que indicar al menos un dispositivo a facturar";
      }else if(dataToSend.observation=="pagada" && dataToSend.payment.payment_medium_id==null){
        error = "Indique el medio de pago con el que se realizó el pago";
      }
      if(this.clientNotification.notify_via_email || this.clientNotification.notify_via_text_message){
        let errorMessage: string[] = [];
        if(this.clientNotification.notify_via_email && !this.validationService.isValidEmail(this.clientNotification.contact_email))
          errorMessage.push("Debe de indicar un email válido.");
        else if(this.clientNotification.notify_via_email){
          dataToSend.notify.contact_email = this.clientNotification.contact_email;
          dataToSend.notify.notify_via_email = this.clientNotification.notify_via_email;
        }
        if(this.clientNotification.notify_via_text_message && (!this.validationService.isOnlyNumbers(this.clientNotification.contact_phone_number) || !this.validationService.isPhoneValid(this.clientNotification.contact_phone_number) ) )
          errorMessage.push("Debe de indicar un número de teléfono válido");
        else if(this.clientNotification.notify_via_text_message){
          dataToSend.notify.contact_phone_number = this.clientNotification.contact_phone_number;
          dataToSend.notify.notify_via_text_message = this.clientNotification.notify_via_text_message;
        }
        if(errorMessage.length > 0 ){
          this.utils.hideLoading(()=>this.loading = false);
          return this.utils.showResultRequest("error", "Los datos para notificar al cliente son inválidos", errorMessage.join("\n"));
        }
      }
    }
    if(error != ""){
      this.utils.showMsg("Información incompleta",error);
      this.utils.hideLoading(()=>this.loading = false);
      return;
    }
    this.utils.showConfirm(titleModal, messageModal, "Continuar", "Cancelar").then(() => {
      this.api.createData(dataToSend, "invoice",true).then((data: unknown) => {
        if(this.validationService.isResponseApi(data)){
          if(data.status==1){
            this.invoiceCreated=true;
            this.utils.showResultRequest("success","Información",data.message);
            this.dialogRef.close(this.invoiceCreated);
          } else 
            this.utils.showResultRequest("error","Información",data.message);
        }
      }).catch((err: any) => {
        this.utils.hideLoading(()=>this.loading = false);
        this.utils.showMsg("","Se ha presentado un error al procesar la solicitud. Contacta al administrador");
      }).finally(()=>this.utils.hideLoading(()=>this.loading = false));
    }).catch(()=>{this.utils.hideLoading(()=>this.loading = false);});
  }
  clearClientSearchFilter(){
    this.inputSearchClient = "";
    this.searchClient();
  }
  searchClient(){
    this.filteredClientList = this.clientList.filter((item:any)=>(item.name??"").toLowerCase().includes(this.inputSearchClient.toLowerCase()) || item.email.includes(this.inputSearchClient.toLowerCase()));
  }
  clearSearchDevice(){
    this.inputSearchDevice = "";
    this.searchDevice();
  }
  searchDevice(){
    this.filteredDeviceList = this.deviceList.filter((device:any)=>((device.name??"").toLowerCase().includes(this.inputSearchDevice.toLowerCase()) || device.plate_number.toLowerCase().includes(this.inputSearchDevice.toLowerCase()) || device.imei.includes(this.inputSearchDevice)));
  }
  printTotalPriceForAInvoiceTable(){
    let devices = this.invoiceCreationOption.create_an_invoice.invoice.devices;
    if(devices.length>0){
      let totalPrice=0;
      let monetaryUnitCode=devices[0].personalized_plan.monetary_unit_code;
      for(let device of devices){
        totalPrice +=Number(device.personalized_plan.unit_price);
      }
      return "$ "+ totalPrice.toLocaleString()+" "+monetaryUnitCode;
    }else{
      return "$ 0.00";
    }
  }
  updateDataSourceForAInvoiceTable(){
    this.dataSourceForAInvoiceTable = new MatTableDataSource(this.invoiceCreationOption.create_an_invoice.invoice.devices);
  }
  getClientContactDetails(){
    this.clientNotification.contact_email = this.clientNotification.contact_phone_number = "";
    this.clientNotification.notify_via_email = this.clientNotification.notify_via_text_message = false;
    this.api.getData("user", "?action=getContactDetails&user_api_hash=" + this.user.hash + "&user_id=" + this.invoiceCreationOption.create_an_invoice.invoice.buyer_id, true).
    then((response:unknown)=>{
      if(this.validationService.isResponseApi(response) && response.status){
        this.clientNotification.notify_via_email = false;
        this.clientNotification.contact_email = response.data.email ?? "";
        //this.clientNotification.notify_via_email = !!(this.clientNotification.contact_email = response.data.email ?? "");
        this.clientNotification.notify_via_text_message = this.validationService.isValidPhone((this.clientNotification.contact_phone_number = (response.data.phone_prefix ?? "") + (response.data.phone_number ?? "")));
      }
    }).
    catch(()=>{})
  }
  getSelectedUserDevices(){
    this.deviceList = [];
    this.filteredDeviceList = [];
    let selectedUser =this.invoiceCreationOption.create_an_invoice.invoice.buyer_id;
    this.invoiceCreationOption.create_an_invoice.invoice.devices=[];
    this.api.getData("device","?action=getDevicesByResponsibleId&user_api_hash="+this.user.hash+"&user_id="+selectedUser,true).then((result:any)=>{
      if(result.status==1){
        this.deviceList = result.data;
        this.filteredDeviceList = result.data;
      }else{
        this.deviceList = [];
        this.filteredDeviceList = [];
        if(typeof result.devices_planless!="undefined"){
          let listDevices="<p><small>El usuario cuenta con dispositivos a cargo pero estos no tienen un plan definido completamente</small></p><fieldset><legend>Dispositivos</legend><ul class='list-devices-planless'>";
          for(let device of result.devices_planless){
            listDevices +='<li>'+((device.name!="")?('Nombre: '+device.name):"")+" - Imei: "+(device.imei)+'</li>';
          }
          listDevices +="</ul></fieldset>";
          this.utils.showMsg("Información",listDevices);
        } else         
        this.utils.showMsg("","No se han encontrado dispositivos a cargo del usuario");
      }
    }).catch((error:any)=>{
    });
  }
  isAFinancialProductSelected(){
    const noFinancialProductsId:any=[1,null];//efectivo:id 1,
    if((!noFinancialProductsId.some((item:number)=>this.invoiceCreationOption.create_an_invoice.invoice.payment.payment_medium_id==item)))
      return true;
    this.invoiceCreationOption.create_an_invoice.invoice.payment.financial_institution_id=null;
    return false;
  }
  //metodos para la tabla de datos de detalles
  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSourceForInvoiceList.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSourceForInvoiceList.data);
  }
  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
  }
  toggleClientNotificationSelection(completed: boolean){
    //this.clientNotification.notify_via_email = 
    this.clientNotification.notify_via_text_message = completed;
  }
  initializeIClientNotification(): IClientNotification{
    return {
      notify_via_email: false,
      notify_via_text_message: false,
      contact_email: "",
      contact_phone_number: ""
    };
  }
}