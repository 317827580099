import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LoginComponent } from './login/login.component';
import { NoFoundComponent } from './no-found/no-found.component';
import { SpecialUserLoginComponent } from './login/special-user-login/special-user-login.component';
import { DeviceChecklistViewerComponent } from './shared/components/device-checklist-viewer/device-checklist-viewer.component';
import { RouteConstant } from './core/constant/RouteConstant';
import { accessControlGuard } from './core/guards/access-control/access-control.guard';

const routes: Routes = [
  { path: '',   redirectTo: '/'+RouteConstant.LOGIN.url, pathMatch: 'full' },
  { path: RouteConstant.LOGIN.url, component: LoginComponent },
  { path: RouteConstant.SPECIAL_USER_LOGIN.url, component: SpecialUserLoginComponent },
  { 
    path: 'dashboard', 
    children: [
      {
        path:'',
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule) 
      },
    ]
  },
  { path: RouteConstant.DEVICE_CHECKLIST_VIEWER.url, component: DeviceChecklistViewerComponent, canActivate:[accessControlGuard],data:{componentId:RouteConstant.DEVICE_CHECKLIST_VIEWER.id} },
  { path: RouteConstant.DEVICE_CHECKLIST_ANSWERED_VIEWER.url, component: DeviceChecklistViewerComponent, canActivate:[accessControlGuard],data:{componentId:RouteConstant.DEVICE_CHECKLIST_VIEWER.id} },
  { path: '**', component: NoFoundComponent },
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }