<section class="form-box-container">
    <section class="form-box-container__header">
        <!--<span class="form-box-container__header-back" (click)="back()"><span class="description">Volver</span> </span>-->
        <h2 class="form-box-container__header-title"><span class="icon"><i class="fa-solid fa-bars-progress"></i></span>Gestión de estados</h2>
        <div class="form-box-container__header-options">
            <button class="btn btn-primary"(click)="prepareStatusToEdit()" [matMenuTriggerFor]="deviceStatusForm" matTooltip="Agregar un nuevo estado"> <i class="fa-solid fa-circle-plus"></i><span class="description"> Nuevo</span></button>
        </div> 
    </section>
    <div class="form-box animate__animated animate__fadeIn">
        <form  class="form-box__content form-box__content-h-auto">
            <div  cdkDropList class="draggable-list w-100" (cdkDropListDropped)="drop($event)">
                <div class="draggable-box draggable-box-flex" *ngFor="let status of deviceStatusList;index as i"  cdkDrag>
                    <span class=" draggable-box__title">{{(i+1)+". "+(status.user_id ==null ?(!validationService.isNullOrEmpty(status.device_status_option_configuration?.custom_name??"")?status.device_status_option_configuration?.custom_name:status.name):status.name) }}</span>
                    <span class="draggable-box__description">{{(status.user_id ==null ?(!validationService.isNullOrEmpty(status.device_status_option_configuration?.custom_description??"")?status.device_status_option_configuration?.custom_description:status.description):status.description)}}</span>
                    <span class="draggable-box__actions">
                        <button class="btn btn-outline-primary" aria-hidden="false" aria-label="Información adicional"
                            [matMenuTriggerFor]="deviceStatusForm" aria-label="Editar estado" (click)="prepareStatusToEdit(status)"><i class="fa-solid fa-pen-to-square"></i>
                        </button>
                        <button class="btn btn-outline-danger" aria-label="Eliminar estado" (click)="deleteStatus(status)"><i class="fa-solid fa-trash"></i></button>
                    </span>
                </div>
            </div>
        </form>
    </div>
    <div class="loader" *ngIf="loading">
        <img src="../../../../../assets/loader.svg">
    </div>
</section>
<!-- Form para la actualizacion o creacion de estados-->
<mat-menu #deviceStatusForm="matMenu" class="detail-popover">
    <span class="detail-popover__btn-close"></span>
    <span class="detail-popover__title">
        <h2 class="detail-popover__title-value">{{deviceStatusSelected.user_id ==null?(!validationService.isNullOrEmpty(deviceStatusSelected.device_status_option_configuration?.custom_name)?deviceStatusSelected.device_status_option_configuration?.custom_name:deviceStatusSelected.name):"Creación de estado"}}</h2>
        <small class="detail-popover__title-label" *ngIf="this.deviceStatusSelected.id != null">{{"Actualización de estado"}}</small>
    </span>
    <span mat-menu-item [disableRipple]="true" (click)="$event.stopPropagation()" class="detail-popover-content">
        <form class="detail-popover-content__form">
            <mat-checkbox [(ngModel)]="deviceStatusToUpdate.enabled" name="isEnabled" color="primary">
                <span class="description">
                    Activo <mat-icon class="icon" aria-hidden="false" aria-label="Indica si habilita / deshabilita el estado" fontIcon="question_mark" matTooltip="Indica si habilita / deshabilita el estado"></mat-icon>
                </span>
            </mat-checkbox>
            <mat-form-field appearance="fill" hintLabel="Max {{maxFieldLength.name }} carácteres">
                <mat-label>Título</mat-label>
                <input matInput maxlength="{{maxFieldLength.name}}" placeholder="Ex. En mantenimiento" name="name" [(ngModel)]="deviceStatusToUpdate.name">
                <mat-hint align="end">{{deviceStatusToUpdate.name.length || 0}}/{{maxFieldLength.name }}</mat-hint>
            </mat-form-field>
            <mat-form-field appearance="fill" hintLabel="Max {{maxFieldLength.description}} carácteres">
                <mat-label>Descripción</mat-label>
                <textarea matInput maxlength="{{maxFieldLength.description}}" name="description" [(ngModel)]="deviceStatusToUpdate.description" class="textarea" placeholder="Ex. El vehículo está siendo sometido a tareas de mantenimiento regular"></textarea>
                <mat-icon matSuffix [matTooltipClass]="'tooltip-multiline'" [matTooltip]="'Incluye una descripción clara y precisa sobre el estado'" fontIcon="question_mark">
                </mat-icon>
                <mat-hint align="end">{{deviceStatusToUpdate.description?.length || 0}}/{{maxFieldLength.description}}</mat-hint>
            </mat-form-field>
            <div class="detail-popover-actions">
                <button class="btn btn-primary"  [disabled]="loading" (click)="updateStatus()" mat-raised-button>{{this.deviceStatusSelected.id != null?loading?"Actualizando...":"Actualizar":loading?"Registrando...":"Registrar"}}</button>            
            </div>
        </form>
    </span>
</mat-menu>