<section class="form-box-container animate__animated animate__fadeIn" *ngIf="viewsAvailable['user_list'].show_view">
    <section class="form-box-container__header">
        <h2 class="form-box-container__header-title"><span class="icon"><i class="fa-solid fa-users"></i></span>Clientes</h2>
        <div  class="form-box-container__header-options">                     
            <button matTooltip="crear usuario" class="btn btn-primary" (click)="openCreateUserModal()"><i
                class="fa-solid fa-user-plus icon"></i><span class="description"> Agregar</span></button>
            <button matTooltip="Exportar datos" class="btn btn-outline-secondary" (click)="exportFile()"><i
                class="fa-solid fa-file-export icon"></i><span class="description"> Exportar</span></button>
            <div class="search">
                <input id="inputSearch" type="search" placeholder="Buscar" (input)="search()" name="searchText" [(ngModel)]="searchText">
                <label for="inputsearch"><i class="fa-solid fa-magnifying-glass"></i></label>
            </div>
        </div>
    </section>
    <div class="form-box animate__animated animate__fadeIn">
        <div class="form-box__content form-box__content-h-auto">
            <div class="w100 overflow-auto">
            <table mat-table matSort (matSortChange)="sortData($event)" [dataSource]="users">
                
                <ng-container matColumnDef="active">
                <th mat-header-cell *matHeaderCellDef  mat-sort-header sortActionDescription="Ordenar por estado de activación"> Activo </th>
                <td mat-cell *matCellDef="let client" class="text-center"> 
                    <mat-slide-toggle
                    [matTooltip]="toggleMessageActive(client.active)"
                        [checked]="isActive(client.active)"
                        color="primary"
                        class="z-index-last"
                        (change)="statusChange($event,client)">
                    </mat-slide-toggle>
                </td>
                </ng-container>
                <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Ordenar por correo"> Correo </th>
                <td mat-cell *matCellDef="let client"> {{client.email}} </td>
                </ng-container>
                <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef  mat-sort-header sortActionDescription="Ordenar por Contacto"> Contacto </th>
                <td mat-cell *matCellDef="let client"> {{client.name}} </td>
                </ng-container>
                <ng-container matColumnDef="document_number">
                <th mat-header-cell *matHeaderCellDef  mat-sort-header sortActionDescription="Ordenar por Documento"> Documento </th>
                <td mat-cell *matCellDef="let client"> {{client.document_number}} </td>
                </ng-container>
                <ng-container matColumnDef="phone_number">
                <th mat-header-cell *matHeaderCellDef  mat-sort-header sortActionDescription="Ordenar por Teléfono"> Teléfono </th>
                <td mat-cell *matCellDef="let client"> {{client.phone_number}} </td>
                </ng-container>
                <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef> Estado </th>
                <td mat-cell *matCellDef="let client" class="text-center"> 
                    <div class="status complete" *ngIf="isDataUserComplete(client)" matTooltip="datos completos"><i class="fa-solid fa-circle-check"></i></div>
                    <div class="status incomplete" *ngIf="!isDataUserComplete(client)" matTooltip="datos incompletos"><i class="fa-solid fa-circle-xmark"></i></div>    
                </td>
                </ng-container>
                <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef mat-sort-header disabled class="mat-header-center"> Acciones </th>
                <td mat-cell *matCellDef="let client" class="text-center"> 
                    <div class="actions-container">
                        <button matTooltip="Editar usuario" (click)="openUpdateUserModal(client)"><i class="fa-solid fa-pen-to-square fa-sm"></i></button>
                        <button matTooltip="Eliminar usuario" (click)="deleteUser(client)"><i class="fa-solid fa-trash-can fa-sm"></i></button>
                        <button matTooltip="mostrar dispositivos" (click)="getUserDevices(client)"><i class="fa-solid fa-list-ul  fa-sm"></i></button>
                    </div>
                </td>
                </ng-container>
        
                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell empty py-1" [attr.colspan]="displayedColumns.length">Sin datos</td>
                </tr>
            </table>
            </div>
            <mat-paginator [length]="pagination.length_page" [pageSize]="pagination.page_size" [pageIndex]="pagination.current_page"
                [pageSizeOptions]="pagination.page_size_options" (page)="paginator($event)"
                aria-label="Selecciona pagina de la lista de clientes">
            </mat-paginator>
        </div>
    </div>
    <div class="loader" *ngIf="loading">
        <img src="../../../../assets/loader.svg">
    </div>
</section>
<app-manager-devices-list [selectedUserId]="newUser.id" [isSelectedUser]="!utils.isNullOrEmpty(newUser.id)" (close)="utils.setSelectedView(this.viewsAvailable,this.viewsAvailable['user_list'].name);objectInitialization.initializeIUserGeneral(user.id,availableMaps,devicePlanType.PERSONALIZED,newUser.id)"  *ngIf="viewsAvailable['selected_user_devices'].show_view"></app-manager-devices-list>

<section class="form-box-container animate__animated animate__fadeIn" *ngIf="viewsAvailable['user_form'].show_view" >
    <div class="form-box animate__animated animate__fadeIn" >
        <form  class="form-box__content form-box__content-h-auto" id="registrationForm" (submit)="adminUser($event,isUpdate)">
            <mat-tab-group dynamicHeight (selectedTabChange)="validateComponentToShow($event)">
                <mat-tab label="Principal">
                    <div class="form-box__content-inputs mt-2">
                        <div class="form-box__content-group-fields">
                            <mat-form-field appearance="fill">
                                <mat-label>País</mat-label>
                                <mat-select  id="inputCountry" (selectionChange)="getIdentificationDocumentTypes();getCitiesList()" name="country_id" [(ngModel)]="newUser.country_id" >
                                    <ng-container   *ngFor="let item of countriesList">
                                        <mat-option [value]="item.id">{{item.name}}</mat-option>
                                    </ng-container>
                                </mat-select>
                            </mat-form-field>

                            <mat-form-field id="inputCity" appearance="fill">
                                <mat-label>Ciudad</mat-label>
                                <mat-select (closed)="clearSearchCity()" [(ngModel)]="newUser.city_id"name="city_id" placeholder="Buscar...">
                                    <mat-option>
                                    <ngx-mat-select-search [noEntriesFoundLabel]="'Sin resultados'" placeholderLabel="Buscar ciudad..." [(ngModel)]="cityToSearch" name="cityToSearch" (keyup)="searchCity()">
                                        <mat-icon (click)="clearSearchCity()" ngxMatSelectSearchClear>close</mat-icon>
                                    </ngx-mat-select-search>
                                    </mat-option>
                                    <mat-option value="" *ngIf="newUser.country_id == null">-- {{newUser.country_id == null ? 'Seleccione primero un país' : 'Seleccione una opción' }} -- </mat-option>
                                    <mat-option *ngFor="let city of citiesListFilter" [value]="city.id" color="primary">
                                        {{city.name}}                   
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field appearance="fill">
                                <mat-label>Dirección</mat-label>
                                <input matInput type="text" name="address" [(ngModel)]="newUser.address">
                            </mat-form-field>
                            <mat-form-field appearance="fill">
                                <mat-label>Tipo de documento de identificación</mat-label>
                                <mat-select  name="identification_document_type_id" (selectionChange)="showIdentificationDocumentType()" id="inputIdentificationDocumentType" [(ngModel)]="newUser.identification_document_type_id" >
                                    <mat-option value="">-- {{validationService.isNullOrEmpty(newUser.country_id)?'Seleccione primero un país':'Seleccione una opción' }} -- </mat-option>
                                    <ng-container   *ngFor="let item of identificationDocumentTypeList">
                                        <mat-option [value]="item.id">{{item.name +(validationService.isNullOrEmpty(item.acronym)?"":(" - "+item.acronym))}}</mat-option>
                                    </ng-container>
                                </mat-select>
                            </mat-form-field>
    
                            <mat-form-field appearance="fill">
                                <mat-label>{{newUserIdentificationDocumentType.id <= 0 ? "Número documento":(newUserIdentificationDocumentType.name.length > 40 ? newUserIdentificationDocumentType.acronym : newUserIdentificationDocumentType.name ) }}</mat-label>
                                <input matInput autocomplete="off" name="document_number" [(ngModel)]="newUser.document_number"
                                type="text" id="inputDocumentNumber">
                            </mat-form-field>
    
                            <mat-form-field appearance="fill">
                                <mat-label>Tipo de personalidad legal</mat-label>
                                <mat-select  name="legal_personality_type"   [(ngModel)]="newUser.legal_personality_type" id="inputlegalPersonalityType" >
                                    <ng-container   *ngFor="let item of dataService.legal_personality_types_list">
                                        <mat-option [value]="item.value">{{item.value}}</mat-option>
                                    </ng-container>
                                </mat-select>
                            </mat-form-field>
    
                            <mat-form-field appearance="fill">
                                <mat-label>{{newUser.legal_personality_type==dataService.legal_personality_types.legal_person || newUser.legal_personality_type==dataService.legal_personality_types.natural_person_trader?"Razón social":"Nombre"}}</mat-label>
                                <input matInput name="name"autocomplete="off" [(ngModel)]="newUser.name"
                                type="text" id="inputName">
                            </mat-form-field>
                            <mat-form-field appearance="fill" *ngIf="newUser.legal_personality_type==dataService.legal_personality_types.legal_person || newUser.legal_personality_type==dataService.legal_personality_types.natural_person_trader">
                                <mat-label>Nombre de contacto</mat-label>
                                <input matInput name="contact_name" type="text" [(ngModel)]="newUser.contact_name" id="inputContactName">
                            </mat-form-field>
    
                            <mat-form-field appearance="fill">
                                <mat-label>Tratamiento</mat-label>
                                <mat-select   name="courtesy_title" [(ngModel)]="newUser.courtesy_title" id="inputCourtesyTitle" >
                                    <ng-container   *ngFor="let item of dataService.courtesy_title">
                                        <mat-option [value]="item.value">{{item.value}}</mat-option>
                                    </ng-container>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field appearance="fill">
                                <mat-label>Prefijo Telefónico</mat-label>
                                <mat-select   name="phone_prefix"   [(ngModel)]="newUser.phone_prefix" id="inputPhonePrefix">
                                    <ng-container   *ngFor="let item of dataService.phone_prefix">
                                        <mat-option [value]="item.code">{{item.code +" - "+item.country}}</mat-option>
                                    </ng-container>
                                </mat-select>
                            </mat-form-field>
    
                            <mat-form-field appearance="fill">
                                <mat-label>Número de contacto</mat-label>
                                <input matInput matTooltip="ingrese solo numeros" autocomplete="off" pattern="^[0-9]{1,20}$" required type="text" name="phone_number" id="inputPhoneNumber"
                                [(ngModel)]="newUser.phone_number">
                            </mat-form-field>
    
                            <mat-form-field appearance="fill">
                                <mat-label>Email</mat-label>
                                <input matInput  required type="email" [pattern]="validationService.isValidEmailStr" name="email" autocomplete="off"id="inputEmail"
                                [(ngModel)]="newUser.email">
                                <mat-error>Email de contacto inválido. Ejemplo válido. {{'usuario@dominio.com'}}</mat-error>
                            </mat-form-field>
                            <mat-form-field appearance="fill" *ngIf="!isUpdate || (isUpdate && newUser.is_update_password)">
                                <mat-label>Contraseña</mat-label>
                                <input matInput   autocomplete="off" required type="password" name="password" id="inputPassword"  [(ngModel)]="newUser.password">
                            </mat-form-field>
                            <mat-form-field appearance="fill" *ngIf="!isUpdate || (isUpdate && newUser.is_update_password)">
                                <mat-label>Confirmar Contraseña</mat-label>
                                <input matInput required="" autocomplete="off"name="password_confirmation" [(ngModel)]="newUser.password_confirmation"
                                type="password" id="inputPasswordConfirmation">
                            </mat-form-field>
                            <mat-checkbox class="fill" color="primary" *ngIf="isUpdate" name="check_enable_password_update" [(ngModel)]="newUser.is_update_password">Actualizar Contraseña</mat-checkbox>
    
                        </div>
                        <fieldset class="form-box__content-fieldset mt-1">
                            <legend>Mapas disponibles:</legend>
                            
                            <div class="form-box__content-group-fields">
                                <input name="available_maps" type="hidden">
                            
                                <mat-checkbox color="primary" *ngIf="!isUpdate" checked="checked" (change)="modifyAvailableMaps($event,28)" id="mapsGoogleNormal" name="available_maps[]" value="28">Maps - Google Normal</mat-checkbox>
                                <mat-checkbox color="primary" *ngIf="isUpdate" [checked]="isMapSelected(newUser.available_maps,28)" (change)="modifyAvailableMaps($event,28)" id="mapsGoogleNormal" name="available_maps[]"value="28">Maps - Google Normal</mat-checkbox>
    
    
                                <mat-checkbox color="primary" *ngIf="!isUpdate" checked="checked"  (change)="modifyAvailableMaps($event,29)"  id="mapsGoogleSatelite" name="available_maps[]" value="29">Maps - Google Satelite</mat-checkbox>
                                <mat-checkbox color="primary" *ngIf="isUpdate" [checked]="isMapSelected(newUser.available_maps,29)"  (change)="modifyAvailableMaps($event,29)"  id="mapsGoogleSatelite"name="available_maps[]" value="29">Maps - Google Satelite</mat-checkbox>
    
                                <mat-checkbox color="primary" *ngIf="!isUpdate" checked="checked"  (change)="modifyAvailableMaps($event,30)" id="MapsGoogleHibrido" name="available_maps[]" value="30">Maps - Google Hibrido</mat-checkbox>
                                <mat-checkbox color="primary" *ngIf="isUpdate" [checked]="isMapSelected(newUser.available_maps,30)"  (change)="modifyAvailableMaps($event,30)" id="MapsGoogleHibrido" name="available_maps[]" value="30">Maps - Google Hibrido</mat-checkbox>
    
                                <mat-checkbox color="primary" *ngIf="!isUpdate" checked="checked" (change)="modifyAvailableMaps($event,31)" id="mapsGoogleTransitoHibrido"  name="available_maps[]" value="31">Maps - Google Transito Hibrido</mat-checkbox>
                                <mat-checkbox color="primary" *ngIf="isUpdate" [checked]="isMapSelected(newUser.available_maps,31)" (change)="modifyAvailableMaps($event,31)" id="mapsGoogleTransitoHibrido" name="available_maps[]" value="31">Maps - Google Transito Hibrido</mat-checkbox>
    
                                <mat-checkbox color="primary" *ngIf="!isUpdate" checked="checked"  (change)="modifyAvailableMaps($event,32)" id="mapsGoogleTransitoNormal" name="available_maps[]" value="32">Maps - Google Transito Normal</mat-checkbox>
                                <mat-checkbox color="primary"  *ngIf="isUpdate" [checked]="isMapSelected(newUser.available_maps,32)"  (change)="modifyAvailableMaps($event,32)" id="mapsGoogleTransitoNormal" name="available_maps[]" value="32">Maps - Google Transito Normal</mat-checkbox>
    
                                <mat-checkbox color="primary" *ngIf="!isUpdate" checked="checked"  (change)="modifyAvailableMaps($event,33)" id="mapsGoogleTerreno" name="available_maps[]" value="33">Maps - Google Terreno</mat-checkbox>
                                <mat-checkbox color="primary"  *ngIf="isUpdate" [checked]="isMapSelected(newUser.available_maps,33)"  (change)="modifyAvailableMaps($event,33)" id="mapsGoogleTerreno" name="available_maps[]" value="33">Maps - Google Terreno</mat-checkbox>
                            </div>
                        </fieldset>
                    </div>
    
                </mat-tab>
                <mat-tab label="Configuraciones">
                    <div class="form-box__content-inputs mt-2">
                        <mat-accordion>
                            <mat-expansion-panel [expanded]="!hasAccessToAutomation">
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        <i class="fa-solid fa-money-bill icon icon-xl text-primary"></i>Facturación
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                              <ng-container>
                                <section class="d-block alert alert-light-info">
                                    <p class="text-small">Esta sección permite definir el tiempo de vencimiento de las facturas que se le generen al cliente. los valores indicados solo tendrán funcionalidad si el cliente tiene algún dispositívo asignado como responsable de pago</p>
                                </section>
                                <div class="form-box__content-group-fields mb-2">
                                    <mat-form-field appearance="fill" floatLabel="always"  class="mat-number-spinner">
                                        <mat-label>tiempo de vencimiento de la factura</mat-label>
                                        <input matInput type="text" placeholder="0"  spellcheck="false" name="invoice_due_time_quantity" [(ngModel)]="newUser.user_setting!.invoice_due_time_quantity" readonly="">
                                        <span  matRipple matPrefix (mousedown)="intervalChangeExpirationTimeAmount(false)" (mouseup)="clearIntervalChangeExpirationTimeAmount()" (click)="$event.stopPropagation();changeExpirationTimeAmount(false)"  class="mat-number-spinner__button mat-number-spinner__button--decrement">-</span>
                                        <span  matRipple matSuffix (mousedown)="intervalChangeExpirationTimeAmount(true)" (mouseup)="clearIntervalChangeExpirationTimeAmount()" (click)="$event.stopPropagation();changeExpirationTimeAmount(true)" class="mat-number-spinner__button mat-number-spinner__button--increment">+</span>
                                        <mat-hint align="start">La cantidad de tiempo para las facturas generadas a su nombre</mat-hint>
                                    </mat-form-field>
                                    
                                    <mat-form-field appearance="fill">
                                        <mat-label>Unidad de tiempo</mat-label>
                                        <mat-select name="invoice_due_time_unit"  [(ngModel)]="newUser.user_setting!.invoice_due_time_unit">
                                          <mat-option *ngFor="let item of timeUnitArray" [value]="item.value">
                                            {{item.label |titlecase}}
                                          </mat-option>
                                        </mat-select>
                                        <mat-hint align="start">La unidad de tiempo para calcular la fecha de vencimiento de sus facturas</mat-hint>
                                    </mat-form-field>                    
                                </div>
                              </ng-container>
                              
                            </mat-expansion-panel>
                            <mat-expansion-panel *ngIf="hasAccessToAutomation && newUser.id > 0">
                              <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <i class="fa-solid fa-robot icon icon-xl text-primary"></i>Automatizaciones
                                </mat-panel-title>
                              </mat-expansion-panel-header>
                              <!-- Seccion para la configuracion de las automatizaciones en cuanto a gestion directa de habilitacion o deshabilitacion sobre el usuario -->
                              <ng-container>
                                <section class="d-block alert alert-light-info">
                                    <p class="text-small">Puedes configurar si el usuario estará habilitado para los procesos de las automatizaciones disponibles y configuradas actualmente</p>
                                </section>
                                <div class="form-box__content-group-fields mb-2">
                                    <mat-selection-list color="primary">
                                        <mat-list-option 
                                        *ngFor="let automation of automationsList" 
                                        [selected]="automation.active_user == 1"
                                        (click)="updateUserAssociationWithAutomation(automation)">
                                          {{automation.name }}
                                        </mat-list-option>
                                      </mat-selection-list>                                                    
                                </div>
                              </ng-container>
                            </mat-expansion-panel>
                          </mat-accordion>
                    </div>
                </mat-tab>
                <mat-tab label="Permisos">
                    <mat-tab-group dynamicHeight>
                        <mat-tab label="INTGPS">
                            <table class="table mt-2">
                                <thead>
                                    <tr>
                                        <th style="text-align: left">Permiso</th>
                                        <th style="text-align: center">Ver</th>
                                        <th style="text-align: center">Editar</th>
                                        <th style="text-align: center">Eliminar</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="group-main ">
                                        <td>
                                            Dispositivos
                                        </td>
                                        <td style="text-align: center">
                                            <div class="checkbox">
                                                <input form="registrationForm" class="perm_checkbox perm_view"[disabled]="isPermissionSelected(isUpdate,'devices',['edit','remove'],newUser.permissions ) ||isPermissionDisabled('devices','view')" (change)="modifySelection($event,'view')" 
                                                    name="perms[devices][view]" [(ngModel)]="newUser['permissions_to_update']['devices']['view']" type="checkbox" value="1">
                                                <label for=""></label>
                                            </div>
                                        </td>
                                        <td style="text-align: center">
                                            <div class="checkbox">
                                                <input form="registrationForm" class="perm_checkbox perm_edit" name="perms[devices][edit]" [(ngModel)]="newUser['permissions_to_update']['devices']['edit']"  [disabled]="isPermissionDisabled('devices','edit')" (change)="modifySelection($event,'edit')" 
                                                    type="checkbox" value="1">
                                                <label for=""></label>
                                            </div>
                                        </td>
                                        <td style="text-align: center">
                                            <div class="checkbox">
                                                <input form="registrationForm" class="perm_checkbox perm_remove" name="perms[devices][remove]" [(ngModel)]="newUser['permissions_to_update']['devices']['remove']" [disabled]="isPermissionDisabled('devices','remove')"(change)="modifySelection($event,'remove')"
                                                    type="checkbox" value="1">
                                                <label for=""></label>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr class="group-main ">
                                        <td>
                                            Alertas
                                        </td>
                                        <td style="text-align: center">
                                            <div class="checkbox disabled">
                                                <input form="registrationForm" class="perm_checkbox perm_view" [disabled]="isPermissionSelected(isUpdate,'alerts',['edit','remove'],newUser.permissions ) ||isPermissionDisabled('alerts','view')" (change)="modifySelection($event,'view')" 
                                                    name="perms[alerts][view]" [(ngModel)]="newUser['permissions_to_update']['alerts']['view']" type="checkbox" value="1" >
                                                <label for=""></label>
                                            </div>
                                        </td>
                                        <td style="text-align: center">
                                            <div class="checkbox">
                                                <input form="registrationForm" class="perm_checkbox perm_edit" (change)="modifySelection($event,'edit')"  [disabled]="isPermissionDisabled('alerts','edit')"
                                                    name="perms[alerts][edit]"[(ngModel)]="newUser['permissions_to_update']['alerts']['edit']" type="checkbox" value="1">
                                                <label for=""></label>
                                            </div>
                                        </td>
                                        <td style="text-align: center">
                                            <div class="checkbox">
                                                <input form="registrationForm" class="perm_checkbox perm_remove" (change)="modifySelection($event,'remove')"[disabled]="isPermissionDisabled('alerts','remove')"
                                                    name="perms[alerts][remove]" [(ngModel)]="newUser['permissions_to_update']['alerts']['remove']" type="checkbox" value="1">
                                                <label for=""></label>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr class="group-main ">
                                        <td>
                                            Geocercas
                                        </td>
                                        <td style="text-align: center">
                                            <div class="checkbox disabled">
                                                <input form="registrationForm" class="perm_checkbox perm_view"(change)="modifySelection($event,'view')"  [disabled]="isPermissionSelected(isUpdate,'geofences',['edit','remove'],newUser.permissions ) ||isPermissionDisabled('geofences','view')" 
                                                    name="perms[geofences][view]" [(ngModel)]="newUser['permissions_to_update']['geofences']['view']" type="checkbox" value="1" >
                                                <label for=""></label>
                                            </div>
                                        </td>
                                        <td style="text-align: center">
                                            <div class="checkbox">
                                                <input form="registrationForm" class="perm_checkbox perm_edit"(change)="modifySelection($event,'edit')" [disabled]="isPermissionDisabled('geofences','edit')"
                                                    name="perms[geofences][edit]" [(ngModel)]="newUser['permissions_to_update']['geofences']['edit']" type="checkbox" value="1">
                                                <label for=""></label>
                                            </div>
                                        </td>
                                        <td style="text-align: center">
                                            <div class="checkbox">
                                                <input form="registrationForm" class="perm_checkbox perm_remove"(change)="modifySelection($event,'remove')" [disabled]="isPermissionDisabled('geofences','remove')"
                                                    name="perms[geofences][remove]" [(ngModel)]="newUser['permissions_to_update']['geofences']['remove']" type="checkbox" value="1">
                                                <label for=""></label>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr class="group-main ">
                                        <td>
                                            Rutas
                                        </td>
                                        <td style="text-align: center">
                                            <div class="checkbox disabled">
                                                <input form="registrationForm" class="perm_checkbox perm_view"(change)="modifySelection($event,'view')" [disabled]="isPermissionSelected(isUpdate,'routes',['edit','remove'],newUser.permissions ) ||isPermissionDisabled('routes','view')" 
                                                    name="perms[routes][view]"[(ngModel)]="newUser['permissions_to_update']['routes']['view']" type="checkbox" value="1">
                                                <label for=""></label>
                                            </div>
                                        </td>
                                        <td style="text-align: center">
                                            <div class="checkbox">
                                                <input form="registrationForm" class="perm_checkbox perm_edit" (change)="modifySelection($event,'edit')"[disabled]="isPermissionDisabled('routes','edit')"
                                                    name="perms[routes][edit]" [(ngModel)]="newUser['permissions_to_update']['routes']['edit']" type="checkbox" value="1">
                                                <label for=""></label>
                                            </div>
                                        </td>
                                        <td style="text-align: center">
                                            <div class="checkbox">
                                                <input form="registrationForm" class="perm_checkbox perm_remove" (change)="modifySelection($event,'remove')" [disabled]="isPermissionDisabled('routes','remove')"
                                                    name="perms[routes][remove]" [(ngModel)]="newUser['permissions_to_update']['routes']['remove']" type="checkbox" value="1">
                                                <label for=""></label>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr class="group-main ">
                                        <td>
                                            POI
                                        </td>
                                        <td style="text-align: center">
                                            <div class="checkbox disabled">
                                                <input form="registrationForm" class="perm_checkbox perm_view"(change)="modifySelection($event,'view')"  [disabled]="isPermissionSelected(isUpdate,'poi',['edit','remove'],newUser.permissions )"
                                                name="perms[poi][view]"  [(ngModel)]="newUser['permissions_to_update']['poi']['view']"
                                                    type="checkbox" value="1">
                                                <label for=""></label>
                                            </div>
                                        </td>
                                        <td style="text-align: center">
                                            <div class="checkbox">
                                                <input form="registrationForm" class="perm_checkbox perm_edit" (change)="modifySelection($event,'edit')" 
                                                name="perms[poi][edit]"  [(ngModel)]="newUser['permissions_to_update']['poi']['edit']" [disabled]="isPermissionDisabled('poi','edit')"
                                                    type="checkbox" value="1">
                                                <label for=""></label>
                                            </div>
                                        </td>
                                        <td style="text-align: center">
                                            <div class="checkbox">
                                                <input form="registrationForm" class="perm_checkbox perm_remove"  (change)="modifySelection($event,'remove')" [disabled]="isPermissionDisabled('poi','remove')"
                                                    name="perms[poi][remove]" [(ngModel)]="newUser['permissions_to_update']['poi']['remove']" type="checkbox" value="1">
                                                <label for=""></label>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr class="group-main ">
                                        <td>
                                            Reportes
                                        </td>
                                        <td style="text-align: center">
                                            <div class="checkbox disabled">
                                                <input form="registrationForm" class="perm_checkbox perm_view"(change)="modifySelection($event,'view')" [disabled]="isPermissionSelected(isUpdate,'reports',['edit','remove'],newUser.permissions ) ||isPermissionDisabled('reports','view')" 
                                                    name="perms[reports][view]" [(ngModel)]="newUser['permissions_to_update']['reports']['view']" type="checkbox" value="1" >
                                                <label for=""></label>
                                            </div>
                                        </td>
                                        <td style="text-align: center">
                                            <div class="checkbox">
                                                <input form="registrationForm" class="perm_checkbox perm_edit"  (change)="modifySelection($event,'edit')" [disabled]="isPermissionDisabled('reports','edit')"
                                                    name="perms[reports][edit]"  [(ngModel)]="newUser['permissions_to_update']['reports']['edit']" type="checkbox" value="1">
                                                <label for=""></label>
                                            </div>
                                        </td>
                                        <td style="text-align: center">
                                            <div class="checkbox">
                                                <input form="registrationForm" class="perm_checkbox perm_remove"   (change)="modifySelection($event,'remove')" [disabled]="isPermissionDisabled('reports','remove')"
                                                    name="perms[reports][remove]"  [(ngModel)]="newUser['permissions_to_update']['reports']['remove']" type="checkbox" value="1">
                                                <label for=""></label>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr class="group-main ">
                                        <td>
                                            Conductores
                                        </td>
                                        <td style="text-align: center">
                                            <div class="checkbox disabled">
                                                <input form="registrationForm" class="perm_checkbox perm_view" (change)="modifySelection($event,'view')" [disabled]="isPermissionSelected(isUpdate,'drivers',['edit','remove'],newUser.permissions ) ||isPermissionDisabled('drivers','view')"
                                                    name="perms[drivers][view]"  [(ngModel)]="newUser['permissions_to_update']['drivers']['view']" type="checkbox" value="1" >
                                                <label for=""></label>
                                            </div>
                                        </td>
                                        <td style="text-align: center">
                                            <div class="checkbox">
                                                <input form="registrationForm" class="perm_checkbox perm_edit"   (change)="modifySelection($event,'edit')" [disabled]="isPermissionDisabled('drivers','edit')"
                                                    name="perms[drivers][edit]" [(ngModel)]="newUser['permissions_to_update']['drivers']['edit']" type="checkbox" value="1">
                                                <label for=""></label>
                                            </div>
                                        </td>
                                        <td style="text-align: center">
                                            <div class="checkbox">
                                                <input form="registrationForm" class="perm_checkbox perm_remove"   (change)="modifySelection($event,'remove')" [disabled]="isPermissionDisabled('drivers','remove')"
                                                    name="perms[drivers][remove]" [(ngModel)]="newUser['permissions_to_update']['drivers']['remove']" type="checkbox" value="1">
                                                <label for=""></label>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr class="group-main ">
                                        <td>
                                            Eventos personalizados
                                        </td>
                                        <td style="text-align: center">
                                            <div class="checkbox disabled">
                                                <input form="registrationForm" class="perm_checkbox perm_view" (change)="modifySelection($event,'view')" [disabled]="isPermissionSelected(isUpdate,'custom_events',['edit','remove'],newUser.permissions ) ||isPermissionDisabled('custom_events','view')"
                                                    name="perms[custom_events][view]" [(ngModel)]="newUser['permissions_to_update']['custom_events']['view']" type="checkbox" value="1">
                                                <label for=""></label>
                                            </div>
                                        </td>
                                        <td style="text-align: center">
                                            <div class="checkbox">
                                                <input form="registrationForm" class="perm_checkbox perm_edit" (change)="modifySelection($event,'edit')" [disabled]="isPermissionDisabled('custom_events','edit')"
                                                    name="perms[custom_events][edit]"[(ngModel)]="newUser['permissions_to_update']['custom_events']['edit']" type="checkbox" value="1">
                                                <label for=""></label>
                                            </div>
                                        </td>
                                        <td style="text-align: center">
                                            <div class="checkbox">
                                                <input form="registrationForm" class="perm_checkbox perm_remove"  (change)="modifySelection($event,'remove')" [disabled]="isPermissionDisabled('custom_events','remove')"
                                                    name="perms[custom_events][remove]" [(ngModel)]="newUser['permissions_to_update']['custom_events']['remove']" type="checkbox" value="1">
                                                <label for=""></label>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr class="group-main ">
                                        <td>
                                            Plantillas GPRS de usuario
                                        </td>
                                        <td style="text-align: center">
                                            <div class="checkbox disabled">
                                                <input form="registrationForm" class="perm_checkbox perm_view"(change)="modifySelection($event,'view')" [disabled]="isPermissionSelected(isUpdate,'user_gprs_templates',['edit','remove'],newUser.permissions ) ||isPermissionDisabled('user_gprs_templates','view')" 
                                                    name="perms[user_gprs_templates][view]" [(ngModel)]="newUser['permissions_to_update']['user_gprs_templates']['view']" type="checkbox" value="1">
                                                <label for=""></label>
                                            </div>
                                        </td>
                                        <td style="text-align: center">
                                            <div class="checkbox">
                                                <input form="registrationForm" class="perm_checkbox perm_edit"  (change)="modifySelection($event,'edit')" [disabled]="isPermissionDisabled('user_gprs_templates','edit')"
                                                    name="perms[user_gprs_templates][edit]"  [(ngModel)]="newUser['permissions_to_update']['user_gprs_templates']['edit']" type="checkbox" value="1">
                                                <label for=""></label>
                                            </div>
                                        </td>
                                        <td style="text-align: center">
                                            <div class="checkbox">
                                                <input form="registrationForm" class="perm_checkbox perm_remove"  (change)="modifySelection($event,'remove')" [disabled]="isPermissionDisabled('user_gprs_templates','remove')"
                                                    name="perms[user_gprs_templates][remove]" [(ngModel)]="newUser['permissions_to_update']['user_gprs_templates']['remove']" type="checkbox" value="1">
                                                <label for=""></label>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr class="group-main ">
                                        <td>
                                            Plantillas de SMS de usuario
                                        </td>
                                        <td style="text-align: center">
                                            <div class="checkbox disabled">
                                                <input form="registrationForm" class="perm_checkbox perm_view"(change)="modifySelection($event,'view')"[disabled]="isPermissionSelected(isUpdate,'user_sms_templates',['edit','remove'],newUser.permissions ) ||isPermissionDisabled('user_sms_templates','view')" 
                                                    name="perms[user_sms_templates][view]"  [(ngModel)]="newUser['permissions_to_update']['user_sms_templates']['view']" type="checkbox" value="1">
                                                <label for=""></label>
                                            </div>
                                        </td>
                                        <td style="text-align: center">
                                            <div class="checkbox">
                                                <input form="registrationForm" class="perm_checkbox perm_edit" (change)="modifySelection($event,'edit')" [disabled]="isPermissionDisabled('user_sms_templates','edit')"
                                                    name="perms[user_sms_templates][edit]"[(ngModel)]="newUser['permissions_to_update']['user_sms_templates']['edit']" type="checkbox" value="1">
                                                <label for=""></label>
                                            </div>
                                        </td>
                                        <td style="text-align: center">
                                            <div class="checkbox">
                                                <input form="registrationForm" class="perm_checkbox perm_remove" (change)="modifySelection($event,'remove')" [disabled]="isPermissionDisabled('user_sms_templates','remove')"
                                                    name="perms[user_sms_templates][remove]"[(ngModel)]="newUser['permissions_to_update']['user_sms_templates']['remove']" type="checkbox" value="1">
                                                <label for=""></label>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr class="group-main ">
                                        <td>
                                            Gateway SMS
                                        </td>
                                        <td style="text-align: center">
                                            <div class="checkbox">
                                                <input form="registrationForm" class="perm_checkbox perm_view"(change)="modifySelection($event,'view')"[disabled]="isPermissionSelected(isUpdate,'sms_gateway',['edit','remove'],newUser.permissions ) ||isPermissionDisabled('sms_gateway','view')"  
                                                    name="perms[sms_gateway][view]" [(ngModel)]="newUser['permissions_to_update']['sms_gateway']['view']" type="checkbox" value="1">
                                                <label for=""></label>
                                            </div>
                                        </td>
                                        <td style="text-align: center">
                                            <div class="checkbox">
                                                <input  form="registrationForm" 
                                                name="perms[sms_gateway][edit]" [(ngModel)]="newUser['permissions_to_update']['sms_gateway']['edit']" (change)="modifySelection($event,'edit')" [disabled]="isPermissionDisabled('sms_gateway','edit')" type="checkbox" value="1">
                                                <label for=""></label>
                                            </div>
                                        </td>
                                        <td style="text-align: center">
                                            <div class="checkbox">
                                                <input  form="registrationForm"  
                                                name="perms[sms_gateway][remove]"  [(ngModel)]="newUser['permissions_to_update']['sms_gateway']['remove']" (change)="modifySelection($event,'remove')" [disabled]="isPermissionDisabled('sms_gateway','remove')" type="checkbox" value="1">
                                                <label for=""></label>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr class="group-main ">
                                        <td>
                                            Enviar comando
                                        </td>
                                        <td style="text-align: center">
                                            <div class="checkbox">
                                                <input form="registrationForm" class="perm_checkbox perm_view" (change)="modifySelection($event,'view')"[disabled]="isPermissionSelected(isUpdate,'send_command',['edit','remove'],newUser.permissions )||isPermissionDisabled('send_command','view')"  
                                                    name="perms[send_command][view]" [(ngModel)]="newUser['permissions_to_update']['send_command']['view']" type="checkbox" value="1">
                                                <label for=""></label>
                                            </div>
                                        </td>
                                        <td style="text-align: center">
                                            <div class="checkbox">
                                                <input  form="registrationForm"
                                                name="perms[send_command][edit]" [(ngModel)]="newUser['permissions_to_update']['send_command']['edit']" (change)="modifySelection($event,'edit')"    [disabled]="isPermissionDisabled('send_command','edit')"  type="checkbox" value="1">
                                                <label for=""></label>
                                            </div>
                                        </td>
                                        <td style="text-align: center">
                                            <div class="checkbox">
                                                <input  form="registrationForm"
                                                name="perms[send_command][remove]" [(ngModel)]="newUser['permissions_to_update']['send_command']['remove']" (change)="modifySelection($event,'remove')"  [disabled]="isPermissionDisabled('send_command','remove')" type="checkbox" value="1">
                                                <label for=""></label>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr class="group-main ">
                                        <td>
                                            Historial
                                        </td>
                                        <td style="text-align: center">
                                            <div class="checkbox">
                                                <input form="registrationForm" class="perm_checkbox perm_view" (change)="modifySelection($event,'view')"[disabled]="isPermissionSelected(isUpdate,'history',['edit','remove'],newUser.permissions ) ||isPermissionDisabled('history','view')"  
                                                    name="perms[history][view]"  [(ngModel)]="newUser['permissions_to_update']['history']['view']" type="checkbox" value="1">
                                                <label for=""></label>
                                            </div>
                                        </td>
                                        <td style="text-align: center">
                                            <div class="checkbox">
                                                <input  form="registrationForm" 
                                                name="perms[history][edit]"  [(ngModel)]="newUser['permissions_to_update']['history']['edit']" (change)="modifySelection($event,'edit')" [disabled]="isPermissionDisabled('history','edit')" type="checkbox" value="1">
                                                <label for=""></label>
                                            </div>
                                        </td>
                                        <td style="text-align: center">
                                            <div class="checkbox">
                                                <input  form="registrationForm" 
                                                name="perms[history][remove]"  [(ngModel)]="newUser['permissions_to_update']['history']['remove']" (change)="modifySelection($event,'remove')" [disabled]="isPermissionDisabled('history','remove')" type="checkbox" value="1">
                                                <label for=""></label>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr class="group-main ">
                                        <td>
                                            Mantenimiento
                                        </td>
                                        <td style="text-align: center">
                                            <div class="checkbox">
                                                <input form="registrationForm" class="perm_checkbox perm_view" (change)="modifySelection($event,'view')"[disabled]="isPermissionSelected(isUpdate,'maintenance',['edit','remove'],newUser.permissions ) ||isPermissionDisabled('maintenance','view')"  
                                                    name="perms[maintenance][view]" [(ngModel)]="newUser['permissions_to_update']['maintenance']['view']" type="checkbox" value="1">
                                                <label for=""></label>
                                            </div>
                                        </td>
                                        <td style="text-align: center">
                                            <div class="checkbox">
                                                <input  form="registrationForm" name="perms[maintenance][edit]"  [(ngModel)]="newUser['permissions_to_update']['maintenance']['edit']" (change)="modifySelection($event,'edit')" [disabled]="isPermissionDisabled('maintenance','edit')"  type="checkbox" value="1">
                                                <label for=""></label>
                                            </div>
                                        </td>
                                        <td style="text-align: center">
                                            <div class="checkbox">
                                                <input  form="registrationForm"name="perms[maintenance][remove]" [(ngModel)]="newUser['permissions_to_update']['maintenance']['remove']" (change)="modifySelection($event,'remove')" [disabled]="isPermissionDisabled('maintenance','remove')"    type="checkbox" value="1">
                                                <label for=""></label>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr class="group-main ">
                                        <td>
                                            Cámara / Medios
                                        </td>
                                        <td style="text-align: center">
                                            <div class="checkbox disabled">
                                                <input form="registrationForm" class="perm_checkbox perm_view" (change)="modifySelection($event,'view')"[disabled]="isPermissionSelected(isUpdate,'camera',['edit','remove'],newUser.permissions )"   
                                                    name="perms[camera][view]" [(ngModel)]="newUser['permissions_to_update']['camera']['view']" type="checkbox" value="1">
                                                <label for=""></label>
                                            </div>
                                        </td>
                                        <td style="text-align: center">
                                            <div class="checkbox">
                                                <input form="registrationForm" class="perm_checkbox perm_edit"  (change)="modifySelection($event,'edit')" [disabled]="isPermissionDisabled('camera','edit')"
                                                    name="perms[camera][edit]" [(ngModel)]="newUser['permissions_to_update']['camera']['edit']" type="checkbox" value="1">
                                                <label for=""></label>
                                            </div>
                                        </td>
                                        <td style="text-align: center">
                                            <div class="checkbox">
                                                <input  form="registrationForm" name="perms[camera][remove]"[(ngModel)]="newUser['permissions_to_update']['camera']['remove']"(change)="modifySelection($event,'remove')" [disabled]="isPermissionDisabled('camera','remove')" type="checkbox" value="1">
                                                <label for=""></label>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr class="group-main ">
                                        <td>
                                            Cámara del dispositivo
                                        </td>
                                        <td style="text-align: center">
                                            <div class="checkbox disabled">
                                                <input form="registrationForm" class="perm_checkbox perm_view" (change)="modifySelection($event,'view')"[disabled]="isPermissionSelected(isUpdate,'device_camera',['edit','remove'],newUser.permissions )||isPermissionDisabled('device_camera','view')" 
                                                    name="perms[device_camera][view]" [(ngModel)]="newUser['permissions_to_update']['device_camera']['view']" type="checkbox" value="1" >
                                                <label for=""></label>
                                            </div>
                                        </td>
                                        <td style="text-align: center">
                                            <div class="checkbox">
                                                <input form="registrationForm" class="perm_checkbox perm_edit"  (change)="modifySelection($event,'edit')" [disabled]="isPermissionDisabled('device_camera','edit')"
                                                    name="perms[device_camera][edit]" [(ngModel)]="newUser['permissions_to_update']['device_camera']['edit']" type="checkbox" value="1">
                                                <label for=""></label>
                                            </div>
                                        </td>
                                        <td style="text-align: center">
                                            <div class="checkbox">
                                                <input  form="registrationForm" name="perms[device_camera][remove]"[(ngModel)]="newUser['permissions_to_update']['device_camera']['remove']" (change)="modifySelection($event,'remove')" [disabled]="isPermissionDisabled('device_camera','remove')"  type="checkbox" value="1">
                                                <label for=""></label>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr class="group-main ">
                                        <td>
                                            Tareas
                                        </td>
                                        <td style="text-align: center">
                                            <div class="checkbox disabled">
                                                <input form="registrationForm" class="perm_checkbox perm_view"(change)="modifySelection($event,'view')" [disabled]="isPermissionSelected(isUpdate,'tasks',['edit','remove'],newUser.permissions ) ||isPermissionDisabled('tasks','view')"  
                                                    name="perms[tasks][view]"[(ngModel)]="newUser['permissions_to_update']['tasks']['view']" type="checkbox" value="1">
                                                <label for=""></label>
                                            </div>
                                        </td>
                                        <td style="text-align: center">
                                            <div class="checkbox">
                                                <input form="registrationForm" class="perm_checkbox perm_edit" (change)="modifySelection($event,'edit')"  [disabled]="isPermissionDisabled('tasks','edit')"
                                                    name="perms[tasks][edit]" [(ngModel)]="newUser['permissions_to_update']['tasks']['edit']" type="checkbox" value="1">
                                                <label for=""></label>
                                            </div>
                                        </td>
                                        <td style="text-align: center">
                                            <div class="checkbox">
                                                <input  form="registrationForm" name="perms[tasks][remove]"[(ngModel)]="newUser['permissions_to_update']['tasks']['remove']" (change)="modifySelection($event,'remove')" [disabled]="isPermissionDisabled('tasks','remove')" type="checkbox" value="1">
                                                <label for=""></label>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr class="group-main ">
                                        <td>
                                            Chat
                                        </td>
                                        <td style="text-align: center">
                                            <div class="checkbox disabled">
                                                <input form="registrationForm" class="perm_checkbox perm_view" (change)="modifySelection($event,'view')" [disabled]="isPermissionSelected(isUpdate,'chat',['edit','remove'],newUser.permissions )||isPermissionDisabled('chat','view')" 
                                                    name="perms[chat][view]"[(ngModel)]="newUser['permissions_to_update']['chat']['view']" type="checkbox" value="1">
                                                <label for=""></label>
                                            </div>
                                        </td>
                                        <td style="text-align: center">
                                            <div class="checkbox">
                                                <input form="registrationForm" class="perm_checkbox perm_edit"  (change)="modifySelection($event,'edit')" [disabled]="isPermissionDisabled('chat','edit')"
                                                    name="perms[chat][edit]"[(ngModel)]="newUser['permissions_to_update']['chat']['edit']" type="checkbox" value="1">
                                                <label for=""></label>
                                            </div>
                                        </td>
                                        <td style="text-align: center">
                                            <div class="checkbox">
                                                <input  form="registrationForm" name="perms[chat][remove]"[(ngModel)]="newUser['permissions_to_update']['chat']['remove']" (change)="modifySelection($event,'remove')" [disabled]="isPermissionDisabled('chat','remove')" type="checkbox" value="1">
                                                <label for=""></label>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr class="group-main ">
                                        <td>
                                            Compartir
                                        </td>
                                        <td style="text-align: center">
                                            <div class="checkbox disabled">
                                                <input form="registrationForm" class="perm_checkbox perm_view"(change)="modifySelection($event,'view')"[disabled]="isPermissionSelected(isUpdate,'sharing',['edit','remove'],newUser.permissions )||isPermissionDisabled('sharing','view')"
                                                    name="perms[sharing][view]" [(ngModel)]="newUser['permissions_to_update']['sharing']['view']" type="checkbox" value="1">
                                                <label for=""></label>
                                            </div>
                                        </td>
                                        <td style="text-align: center">
                                            <div class="checkbox">
                                                <input form="registrationForm" class="perm_checkbox perm_edit" (change)="modifySelection($event,'edit')" [disabled]="isPermissionDisabled('sharing','edit')"
                                                    name="perms[sharing][edit]"[(ngModel)]="newUser['permissions_to_update']['sharing']['edit']" type="checkbox" value="1">
                                                <label for=""></label>
                                            </div>
                                        </td>
                                        <td style="text-align: center">
                                            <div class="checkbox">
                                                <input  form="registrationForm"name="perms[sharing][remove]"[(ngModel)]="newUser['permissions_to_update']['sharing']['remove']" (change)="modifySelection($event,'remove')" [disabled]="isPermissionDisabled('sharing','remove')" type="checkbox" value="1">
                                                <label for=""></label>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr class="group-main ">
                                        <td>
                                            Activación de dispositivo GPS
                                        </td>
                                        <td style="text-align: center">
                                            <div class="checkbox">
                                                <input form="registrationForm" class="perm_checkbox perm_view"(change)="modifySelection($event,'view')" [disabled]="isPermissionSelected(isUpdate,'device_configuration',['edit','remove'],newUser.permissions )||isPermissionDisabled('device_configuration','view')"
                                                    name="perms[device_configuration][view]" [(ngModel)]="newUser['permissions_to_update']['device_configuration']['view']" type="checkbox" value="1">
                                                <label for=""></label>
                                            </div>
                                        </td>
                                        <td style="text-align: center">
                                            <div class="checkbox">
                                                <input  form="registrationForm" name="perms[device_configuration][edit]"[(ngModel)]="newUser['permissions_to_update']['device_configuration']['edit']" (change)="modifySelection($event,'edit')" [disabled]="isPermissionDisabled('device_configuration','edit')"  type="checkbox" value="1">
                                                <label for=""></label>
                                            </div>
                                        </td>
                                        <td style="text-align: center">
                                            <div class="checkbox">
                                                <input  form="registrationForm"name="perms[device_configuration][remove]"[(ngModel)]="newUser['permissions_to_update']['device_configuration']['remove']" (change)="modifySelection($event,'remove')" [disabled]="isPermissionDisabled('device_configuration','rmeove')" type="checkbox" value="1">
                                                <label for=""></label>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr class="group-main ">
                                        <td>
                                            Tipo de ruta del dispositivo
                                        </td>
                                        <td style="text-align: center">
                                            <div class="checkbox">
                                                <input  form="registrationForm"class="perm_checkbox perm_view" 
                                                name="perms[device_route_types][view]"[(ngModel)]="newUser['permissions_to_update']['device_route_types']['view']" (change)="modifySelection($event,'view')" [disabled]="isPermissionSelected(isUpdate,'device_route_types',['edit','remove'],newUser.permissions )||isPermissionDisabled('device_route_types','view')" type="checkbox" value="1">
                                                <label for=""></label>
                                            </div>
                                        </td>
                                        <td style="text-align: center">
                                            <div class="checkbox">
                                                <input  form="registrationForm"  name="perms[device_route_types][edit]"[(ngModel)]="newUser['permissions_to_update']['device_route_types']['edit']"  class="perm_checkbox perm_edit" (change)="modifySelection($event,'edit')" [disabled]="isPermissionDisabled('device_route_types','edit')" type="checkbox" value="1">
                                                <label for=""></label>
                                            </div>
                                        </td>
                                        <td style="text-align: center">
                                            <div class="checkbox">
                                                <input  form="registrationForm"  name="perms[device_route_types][remove]"[(ngModel)]="newUser['permissions_to_update']['device_route_types']['remove']"  class="perm_checkbox perm_remove" (change)="modifySelection($event,'remove')"  [disabled]="isPermissionDisabled('device_route_types','remove')"  type="checkbox" value="1">
                                                <label for=""></label>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr class="group-main ">
                                        <td>
                                            Acciones de llamada
                                        </td>
                                        <td style="text-align: center">
                                            <div class="checkbox">
                                                <input  form="registrationForm" name="perms[call_actions][view]"[(ngModel)]="newUser['permissions_to_update']['call_actions']['view']"  (change)="modifySelection($event,'view')" [disabled]="isPermissionSelected(isUpdate,'call_actions',['edit','remove'],newUser.permissions )||isPermissionDisabled('call_actions','view')"  
                                                type="checkbox" value="1">
                                                <label for=""></label>
                                            </div>
                                        </td>
                                        <td style="text-align: center">
                                            <div class="checkbox">
                                                <input  form="registrationForm" name="perms[call_actions][edit]"[(ngModel)]="newUser['permissions_to_update']['call_actions']['edit']" (change)="modifySelection($event,'edit')" [disabled]="isPermissionDisabled('call_actions','edit')" type="checkbox" value="1">
                                                <label for=""></label>
                                            </div>
                                        </td>
                                        <td style="text-align: center">
                                            <div class="checkbox">
                                                <input  form="registrationForm" name="perms[call_actions][remove]"[(ngModel)]="newUser['permissions_to_update']['call_actions']['remove']" (change)="modifySelection($event,'remove')" [disabled]="isPermissionDisabled('call_actions','remove')" type="checkbox" value="1">
                                                <label for=""></label>
                                            </div>
                                        </td>
                                    </tr>
                
                                    <tr class="group-main ">
                                        <td>
                                            Usuarios
                                        </td>
                                        <td style="text-align: center">
                                            <div class="checkbox">
                                                <input  form="registrationForm" name="perms[users][view]" [(ngModel)]="newUser['permissions_to_update']['users']['view']" disabled=""  
                                                type="checkbox" value="1">
                                                <label for=""></label>
                                            </div>
                                        </td>
                                        <td style="text-align: center">
                                            <div class="checkbox">
                                                <input  form="registrationForm" name="perms[users][edit]" [(ngModel)]="newUser['permissions_to_update']['users']['edit']"  disabled=""   type="checkbox" value="1">
                                                <label for=""></label>
                                            </div>
                                        </td>
                                        <td style="text-align: center">
                                            <div class="checkbox">
                                                <input  form="registrationForm" name="perms[users][remove]" [(ngModel)]="newUser['permissions_to_update']['users']['remove']"  disabled="" type="checkbox" value="1">
                                                <label for=""></label>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                
                            <details class="device-permissions-container" open>
                                <summary class="list-style:none">Device</summary>
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th style="text-align: left">Permiso</th>
                                            <th style="text-align: center">Ver</th>
                                            <th style="text-align: center">Editar</th>
                                            <th style="text-align: center">Eliminar</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr class="group-device collapse">
                                            <td>
                                                IMEI
                                            </td>
                                            <td style="text-align: center">
                                                <div class="checkbox disabled">
                                                    <input form="registrationForm" class="perm_checkbox perm_view"(change)="modifySelection($event,'view')" [disabled]="isPermissionSelected(isUpdate,'device.imei',['edit','remove'],newUser.permissions ) ||isPermissionDisabled('device.imei','view')" 
                                                        name="perms[device.imei][view]" [(ngModel)]="newUser['permissions_to_update']['device.imei']['view']"  type="checkbox" value="1">
                                                    <label for=""></label>
                                                </div>
                                            </td>
                                            <td style="text-align: center">
                                                <div class="checkbox">
                                                    <input form="registrationForm" class="perm_checkbox perm_edit" (change)="modifySelection($event,'edit')" [disabled]="isPermissionDisabled('device.imei','edit')" 
                                                        name="perms[device.imei][edit]"[(ngModel)]="newUser['permissions_to_update']['device.imei']['edit']" type="checkbox" value="1">
                                                    <label for=""></label>
                                                </div>
                                            </td>
                                            <td style="text-align: center">
                                                <div class="checkbox">
                                                    <input  form="registrationForm"name="perms[device.imei][remove]" [(ngModel)]="newUser['permissions_to_update']['device.imei']['remove']" (change)="modifySelection($event,'remove')" [disabled]="isPermissionDisabled('device.imei','remove')"  type="checkbox" value="1">
                                                    <label for=""></label>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr class="group-device collapse">
                                            <td>
                                                Número SIM
                                            </td>
                                            <td style="text-align: center">
                                                <div class="checkbox disabled">
                                                    <input form="registrationForm" class="perm_checkbox perm_view"(change)="modifySelection($event,'view')"[disabled]="isPermissionSelected(isUpdate,'device.sim_number',['edit','remove'],newUser.permissions )||isPermissionDisabled('device.sim_number','view')"
                                                        name="perms[device.sim_number][view]"[(ngModel)]="newUser['permissions_to_update']['device.sim_number']['view']" type="checkbox" value="1">
                                                    <label for=""></label>
                                                </div>
                                            </td>
                                            <td style="text-align: center">
                                                <div class="checkbox">
                                                    <input form="registrationForm" class="perm_checkbox perm_edit"  (change)="modifySelection($event,'edit')" [disabled]="isPermissionDisabled('device.sim_number','edit')" 
                                                        name="perms[device.sim_number][edit]"[(ngModel)]="newUser['permissions_to_update']['device.sim_number']['edit']"  type="checkbox" value="1">
                                                    <label for=""></label>
                                                </div>
                                            </td>
                                            <td style="text-align: center">
                                                <div class="checkbox">
                                                    <input  form="registrationForm" name="perms[device.sim_number][remove]" [(ngModel)]="newUser['permissions_to_update']['device.sim_number']['remove']"  (change)="modifySelection($event,'remove')" [disabled]="isPermissionDisabled('device.sim_number','remove')"  type="checkbox" value="1">
                                                    <label for=""></label>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr class="group-device collapse">
                                            <td>
                                                Adelante
                                            </td>
                                            <td style="text-align: center">
                                                <div class="checkbox disabled">
                                                    <input form="registrationForm" class="perm_checkbox perm_view"(change)="modifySelection($event,'view')"[disabled]="isPermissionSelected(isUpdate,'device.forward',['edit','remove'],newUser.permissions ) ||isPermissionDisabled('device.forward','view')"
                                                        name="perms[device.forward][view]"[(ngModel)]="newUser['permissions_to_update']['device.forward']['view']"  type="checkbox" value="1">
                                                    <label for=""></label>
                                                </div>
                                            </td>
                                            <td style="text-align: center">
                                                <div class="checkbox">
                                                    <input form="registrationForm" name="perms[device.forward][edit]" class="perm_checkbox perm_edit" (change)="modifySelection($event,'edit')" [disabled]="isPermissionDisabled('device.forward','edit')"
                                                        name="perms[device.forward][edit]"[(ngModel)]="newUser['permissions_to_update']['device.forward']['edit']"  type="checkbox" value="1">
                                                    <label for=""></label>
                                                </div>
                                            </td>
                                            <td style="text-align: center">
                                                <div class="checkbox">
                                                    <input name="perms[device.forward][remove]"[(ngModel)]="newUser['permissions_to_update']['device.forward']['remove']"  form="registrationForm" (change)="modifySelection($event,'remove')" [disabled]="isPermissionDisabled('device.forward','remove')"  type="checkbox" value="1">
                                                    <label for=""></label>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr class="group-device collapse">
                                            <td>
                                                Protocolo
                                            </td>
                                            <td style="text-align: center">
                                                <div class="checkbox">
                                                    <input form="registrationForm" class="perm_checkbox perm_view"(change)="modifySelection($event,'view')"[disabled]="isPermissionSelected(isUpdate,'device.protocol',['edit','remove'],newUser.permissions ) ||isPermissionDisabled('device.protocol','view')" 
                                                        name="perms[device.protocol][view]"[(ngModel)]="newUser['permissions_to_update']['device.protocol']['view']" type="checkbox" value="1">
                                                    <label for=""></label>
                                                </div>
                                            </td>
                                            <td style="text-align: center">
                                                <div class="checkbox">
                                                    <input  form="registrationForm"name="perms[device.protocol][edit]"[(ngModel)]="newUser['permissions_to_update']['device.protocol']['edit']" (change)="modifySelection($event,'edit')" [disabled]="isPermissionDisabled('device.protocol','edit')"  type="checkbox" value="1">
                                                    <label for=""></label>
                                                </div>
                                            </td>
                                            <td style="text-align: center">
                                                <div class="checkbox">
                                                    <input  form="registrationForm" (change)="modifySelection($event,'remove')"name="perms[device.protocol][remove]" [(ngModel)]="newUser['permissions_to_update']['device.protocol']['remove']" [disabled]="isPermissionDisabled('device.protocol','remove')" type="checkbox" value="1">
                                                    <label for=""></label>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr class="group-device collapse">
                                            <td>
                                                Fecha de vencimiento
                                            </td>
                                            <td style="text-align: center">
                                                <div class="checkbox">
                                                    <input form="registrationForm" class="perm_checkbox perm_view"(change)="modifySelection($event,'view')"[disabled]="isPermissionSelected(isUpdate,'device.expiration_date',['edit','remove'],newUser.permissions ) ||isPermissionDisabled('device.expiration_date','view')"
                                                        name="perms[device.expiration_date][view]"[(ngModel)]="newUser['permissions_to_update']['device.expiration_date']['view']"  type="checkbox" value="1">
                                                    <label for=""></label>
                                                </div>
                                            </td>
                                            <td style="text-align: center">
                                                <div class="checkbox">
                                                    <input  form="registrationForm"name="perms[device.expiration_date][edit]"[(ngModel)]="newUser['permissions_to_update']['device.expiration_date']['edit']" (change)="modifySelection($event,'edit')" [disabled]="isPermissionDisabled('device.expiration_date','edit')"type="checkbox" value="1">
                                                    <label for=""></label>
                                                </div>
                                            </td>
                                            <td style="text-align: center">
                                                <div class="checkbox">
                                                    <input  form="registrationForm" name="perms[device.expiration_date][remove]" [(ngModel)]="newUser['permissions_to_update']['device.expiration_date']['remove']" (change)="modifySelection($event,'remove')" [disabled]="isPermissionDisabled('device.expiration_date','remove')" type="checkbox" value="1">
                                                    <label for=""></label>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr class="group-device collapse">
                                            <td>
                                                Fecha de instalación
                                            </td>
                                            <td style="text-align: center">
                                                <div class="checkbox disabled">
                                                    <input form="registrationForm" class="perm_checkbox perm_view" (change)="modifySelection($event,'view')" [disabled]="isPermissionSelected(isUpdate,'device.installation_date',['edit','remove'],newUser.permissions )||isPermissionDisabled('device.installation_date','view')"
                                                        name="perms[device.installation_date][view]"[(ngModel)]="newUser['permissions_to_update']['device.installation_date']['view']" type="checkbox" value="1">
                                                    <label for=""></label>
                                                </div>
                                            </td>
                                            <td style="text-align: center">
                                                <div class="checkbox">
                                                    <input form="registrationForm" class="perm_checkbox perm_edit" (change)="modifySelection($event,'edit')"  [disabled]="isPermissionDisabled('device.installation_date','edit')"
                                                        name="perms[device.installation_date][edit]"[(ngModel)]="newUser['permissions_to_update']['device.installation_date']['edit']"  type="checkbox" value="1">
                                                    <label for=""></label>
                                                </div>
                                            </td>
                                            <td style="text-align: center">
                                                <div class="checkbox">
                                                    <input  form="registrationForm"name="perms[device.installation_date][remove]"[(ngModel)]="newUser['permissions_to_update']['device.installation_date']['remove']"  (change)="modifySelection($event,'remove')"  [disabled]="isPermissionDisabled('device.installation_date','remove')" type="checkbox" value="1">
                                                    <label for=""></label>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr class="group-device collapse">
                                            <td>
                                                Fecha de activación de SIM
                                            </td>
                                            <td style="text-align: center">
                                                <div class="checkbox disabled">
                                                    <input form="registrationForm" class="perm_checkbox perm_view"(change)="modifySelection($event,'view')" [disabled]="isPermissionSelected(isUpdate,'device.sim_activation_date',['edit','remove'],newUser.permissions ) ||isPermissionDisabled('device.sim_activation_date','view')" 
                                                        name="perms[device.sim_activation_date][view]" [(ngModel)]="newUser['permissions_to_update']['device.sim_activation_date']['view']"  type="checkbox" value="1">
                                                    <label for=""></label>
                                                </div>
                                            </td>
                                            <td style="text-align: center">
                                                <div class="checkbox">
                                                    <input form="registrationForm" class="perm_checkbox perm_edit"  (change)="modifySelection($event,'edit')" [disabled]="isPermissionDisabled('device.sim_activation_date','edit')"
                                                        name="perms[device.sim_activation_date][edit]" [(ngModel)]="newUser['permissions_to_update']['device.sim_activation_date']['edit']" type="checkbox" value="1">
                                                    <label for=""></label>
                                                </div>
                                            </td>
                                            <td style="text-align: center">
                                                <div class="checkbox">
                                                    <input  form="registrationForm"name="perms[device.sim_activation_date][remove]"[(ngModel)]="newUser['permissions_to_update']['device.sim_activation_date']['remove']" (change)="modifySelection($event,'remove')" [disabled]="isPermissionDisabled('device.sim_activation_date','remove')"  type="checkbox" value="1">
                                                    <label for=""></label>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr class="group-device collapse">
                                            <td>
                                                Fecha de vencimiento de SIM
                                            </td>
                                            <td style="text-align: center">
                                                <div class="checkbox disabled">
                                                    <input form="registrationForm" class="perm_checkbox perm_view"(change)="modifySelection($event,'view')" [disabled]="isPermissionSelected(isUpdate,'device.sim_expiration_date',['edit','remove'],newUser.permissions ) ||isPermissionDisabled('device.sim_expiration_date','view')" 
                                                        name="perms[device.sim_expiration_date][view]"[(ngModel)]="newUser['permissions_to_update']['device.sim_expiration_date']['view']" type="checkbox" value="1">
                                                    <label for=""></label>
                                                </div>
                                            </td>
                                            <td style="text-align: center">
                                                <div class="checkbox">
                                                    <input form="registrationForm" class="perm_checkbox perm_edit"  (change)="modifySelection($event,'edit')" [disabled]="isPermissionDisabled('device.sim_expiration_date','edit')"
                                                        name="perms[device.sim_expiration_date][edit]"[(ngModel)]="newUser['permissions_to_update']['device.sim_expiration_date']['edit']" type="checkbox" value="1">
                                                    <label for=""></label>
                                                </div>
                                            </td>
                                            <td style="text-align: center">
                                                <div class="checkbox">
                                                    <input  form="registrationForm"name="perms[device.sim_expiration_date][remove]"[(ngModel)]="newUser['permissions_to_update']['device.sim_expiration_date']['remove']" (change)="modifySelection($event,'remove')" [disabled]="isPermissionDisabled('device.sim_expiration_date','remove')" type="checkbox" value="1">
                                                    <label for=""></label>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr class="group-device collapse">
                                            <td>
                                                MSISDN
                                            </td>
                                            <td style="text-align: center">
                                                <div class="checkbox disabled">
                                                    <input form="registrationForm" class="perm_checkbox perm_view" (change)="modifySelection($event,'view')"[disabled]="isPermissionSelected(isUpdate,'device.msisdn',['edit','remove'],newUser.permissions ) ||isPermissionDisabled('device.msisdn','view')"
                                                        name="perms[device.msisdn][view]"[(ngModel)]="newUser['permissions_to_update']['device.msisdn']['view']" type="checkbox" value="1">
                                                    <label for=""></label>
                                                </div>
                                            </td>
                                            <td style="text-align: center">
                                                <div class="checkbox">
                                                    <input form="registrationForm" class="perm_checkbox perm_edit"  (change)="modifySelection($event,'edit')"
                                                        name="perms[device.msisdn][edit]"[(ngModel)]="newUser['permissions_to_update']['device.msisdn']['edit']" type="checkbox" value="1">
                                                    <label for=""></label>
                                                </div>
                                            </td>
                                            <td style="text-align: center">
                                                <div class="checkbox">
                                                    <input  form="registrationForm"name="perms[device.msisdn][remove]" [(ngModel)]="newUser['permissions_to_update']['device.msisdn']['remove']" disabled="disabled" type="checkbox" value="1">
                                                    <label for=""></label>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </details>
                            <details class="device-permissions-container">
                                <summary class="list-style:none">User</summary>
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th style="text-align: left">Permiso</th>
                                            <th style="text-align: center">Ver</th>
                                            <th style="text-align: center">Editar</th>
                                            <th style="text-align: center">Eliminar</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr class="group-device collapse">
                                            <td>
                                                Identificación del cliente
                                            </td>
                                            <td style="text-align: center">
                                                <div class="checkbox disabled">
                                                    <input form="registrationForm" class="perm_checkbox perm_view"(change)="modifySelection($event,'view')" [disabled]="isPermissionSelected(isUpdate,'user.client_id',['edit','remove'],newUser.permissions ) ||isPermissionDisabled('user.client_id','view')"
                                                        name="perms[user.client_id][view]"[(ngModel)]="newUser['permissions_to_update']['user.client_id']['view']" type="checkbox" value="1">
                                                    <label for=""></label>
                                                </div>
                                            </td>
                                            <td style="text-align: center">
                                                <div class="checkbox">
                                                    <input form="registrationForm" class="perm_checkbox perm_edit" (change)="modifySelection($event,'edit')" [disabled]="isPermissionDisabled('user.client_id','edit')" 
                                                        name="perms[user.client_id][edit]"[(ngModel)]="newUser['permissions_to_update']['user.client_id']['edit']" type="checkbox" value="1">
                                                    <label for=""></label>
                                                </div>
                                            </td>
                                            <td style="text-align: center">
                                                <div class="checkbox">
                                                    <input  form="registrationForm"name="perms[user.client_id][remove]"[(ngModel)]="newUser['permissions_to_update']['user.client_id']['remove']"  (change)="modifySelection($event,'remove')" [disabled]="isPermissionDisabled('user.client_id','remove')"  type="checkbox" value="1">
                                                    <label for=""></label>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </details>
                        </mat-tab>

                        <mat-tab label="SGC" *ngIf="isUpdate">
                            <span class="form-text mt-2">Ten en cuenta que los permisos al usuario se ven afectados por el plan al cual usted se encuentre suscrito en el momento.</span>
                            <app-clients-sgc-permissions  [userId]="newUser.id"></app-clients-sgc-permissions>
                        </mat-tab>
                    </mat-tab-group>
                </mat-tab>
                <mat-tab label="Notas de usuario" *ngIf="isUpdate">
                    <!--Para la agregacion de notas al usuario-->
                </mat-tab>
            </mat-tab-group>
            <div class="form-box__content-actions">
                <button type="submit" *ngIf="isUpdate"
                    class="btn btn-primary ">Actualizar</button>
                <button type="submit"  *ngIf="!isUpdate"
                    class="btn btn-primary ">Registrar</button>
                <button class="btn btn-outline-secondary " (click)="closeModal()">cancelar</button>                        
            </div>
        </form>
    
    
    </div>
    <div class="loader" *ngIf="loading">
        <img src="../../../../assets/loader.svg">
    </div>
</section >

<app-user-notes *ngIf="viewsAvailable['user_notes'].show_view" [userId]="newUser.id" (close)="utils.setSelectedView(this.viewsAvailable,this.viewsAvailable['user_form'].name)"></app-user-notes>