<div class="app-container-adaptive animate__animated animate__fadeIn ">
    <header class="app-container-adaptive__header">
        <section class="app-container-adaptive__header-menu-content">
            <span class="app-container-adaptive__header-menu-content-logo">
                {{userTypeLogged == dataService.USER_TYPE.user_driver ? 'Tracking' : 'SGC'}}
            </span>
            <span class="version-tag">V.{{version}}</span>
        </section>
        <input type="checkbox" class="app-container-adaptive__header-menu-toggle" [(ngModel)]="isOpenSidebarMobile">
    </header>
    <div class="app-container">
        <nav class="app-container__sidebar animate__animated animate__fadeIn" [ngClass]="{'app-container__sidebar--closed': !isOpenSidebar,'app-container__sidebar--closed-mobile': !isOpenSidebarMobile}"
            *ngIf="!webMenuManagementService.isActiveWebMenu">
            <span class="app-container__sidebar-toggle">
                <input type="checkbox" [(ngModel)]="isOpenSidebar">
            </span>
            <span class="app-container__sidebar-logo app-container__sidebar-logo-wrapper">
                <span class="app-container__sidebar-logo-content">
                    {{userTypeLogged == dataService.USER_TYPE.user_driver ? 'Tracking' : 'SGC'}}
                </span>
                <span class="version-tag">V.{{version}}</span>
            </span>
            <span class="app-container__sidebar-user-avatar">
                <span class="app-container__sidebar-icon app-container__sidebar-user-avatar-icon" matTooltipPosition="right"
                    [matTooltip]="!isOpenSidebar? (userName | titlecase) : ''">
                    <i class="fa-solid fa-circle-user"></i>
                </span>
                <span class="app-container__sidebar-user-avatar-info">
                    <span class="app-container__sidebar-user-avatar-username">
                        {{userName | titlecase}}
                    </span>
                    <span class="app-container__sidebar-user-avatar-additional-info">
                        ({{!validationService.isNullOrEmpty(this.user.special_user_type??null) ? specialUser :
                        userRole|titlecase}})
                    </span>
                </span>
            </span>
            <div class="app-container__sidebar-content">
                <ng-container *ngFor="let menuItem of menu">
                    <div class="app-container__sidebar-content-item" matTooltipPosition="right"
                        [matTooltip]="!isOpenSidebar? (menuItem.name) : ''"
                        [ngClass]="{'app-container__sidebar-content-item--selected': menuItem.id == actualView}"
                        (click)="goTab(menuItem); $event.stopPropagation()" *ngIf="hasPermission(menuItem.id)">
                        <span class="app-container__sidebar-icon app-container__sidebar-content-item-icon">
                            <i class="fa-solid {{menuItem.icon}}"></i>
                        </span>
                        <div class="app-container__sidebar-content-item-name">{{menuItem.name}}</div>
                        <div class="app-container__sidebar-content-item-arrow" *ngIf="menuItem.children.length > 0"
                            (click)="goTab(menuItem,undefined,true);$event.stopPropagation();"><i
                                class="fa-solid fa-chevron-down"></i></div>
                    </div>
                    <div class="app-container__sidebar-content-subitems animate__animated animate__fadeIn"
                        [ngClass]="{'app-container__sidebar-content-subitems--selected': actualSubMenu == menuItem.id}">
                        <ng-container *ngFor="let subMenuItem of menuItem.children">
                            <div class="app-container__sidebar-content-item app-container__sidebar-content-subitems-item"
                                matTooltipPosition="right" [matTooltip]="!isOpenSidebar? (subMenuItem.name) : ''"
                                [ngClass]="{'app-container__sidebar-content-subitems-item--selected': subMenuItem.id == actualView}"
                                (click)="goTab(subMenuItem, true); $event.stopPropagation()"
                                *ngIf="hasPermission(subMenuItem.id)">
                                <span class="app-container__sidebar-icon app-container__sidebar-content-subitems-item-icon">
                                    <i class="fa-solid {{subMenuItem.icon}}"></i>
                                </span>
                                <div class="app-container__sidebar-content-subitems-item-name">{{subMenuItem.name}}</div>
                            </div>
                        </ng-container>
                    </div>
                </ng-container>
            </div>
            <div class="app-container__sidebar-content-bottom">
                <div class="app-container__sidebar-content-item app-container__sidebar-content-bottom-item"
                    matTooltipPosition="right" [matTooltip]="!isOpenSidebar? (logoutButtonTitle) : ''"
                    (click)="logout()">
                    <span class="app-container__sidebar-icon app-container__sidebar-content-item-icon text-danger">
                        <i class="fa-solid fa-right-from-bracket icon"></i>
                    </span>
                    <div class="app-container__sidebar-content-item-name">{{logoutButtonTitle}}</div>
                </div>
            </div>
        </nav>
        <app-web-template-edit-menu class="app-container__sidebar animate__animated animate__fadeIn" (actionOpenSidebar)="isOpenSidebar = !!$event" (actionOpenSidebarMobile)="isOpenSidebarMobile = !!$event "  [ngClass]="{'app-container__sidebar--closed': !isOpenSidebar,'app-container__sidebar--closed-mobile': !isOpenSidebarMobile}" *ngIf="webMenuManagementService.isActiveWebMenu"></app-web-template-edit-menu>
    
        <section class="app-container__content">
            <router-outlet></router-outlet>
        </section>
    </div>
</div>