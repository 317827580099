import { Component,Input,Output,OnInit,EventEmitter,Renderer2 } from '@angular/core';
import { DataService } from 'src/app/core/data/data.service';
import { UtilsService } from 'src/app/core/utils/utils.service';
import { ApiService } from 'src/app/core/api/api.service';
import { SgcPlatformPlanPermissions } from "../../../../core/models/sgc-platform-plan-permissions.model";

@Component({
  selector: 'app-clients-sgc-permissions',
  templateUrl: './clients-sgc-permissions.component.html',
  styleUrls: ['./clients-sgc-permissions.component.scss']
})
export class ClientsSgcPermissionsComponent  extends SgcPlatformPlanPermissions implements OnInit {
  user:any={};
  loading:boolean=false;
  @Input() userId!:number;
  @Output() closeUpdateModal = new EventEmitter<boolean>();
  constructor(
    private dataService:DataService,
    public  utils:UtilsService,
    private api:ApiService
  ){ super();
    this.user = this.dataService.getData("user");
  }
  ngOnInit(): void {
      this.getList();
  }
  getList(){
    this.api.getData("module","?action=getModules&include_manager_assigned_modules=true&user_api_hash="+this.user.hash,true).then((data: any) => {
      this.modules = data.status==1?data.data:[];
      this.filteredModules =  this.utils.copyObject(this.modules);
        this.api.getData("platformPlan", "?action=getPlatformPlan&user_api_hash="+this.user.hash+"&user_id="+this.userId,true).then((data: any) => {
          //el plan del usuario (es decir solo los permisos para el de rol usuario)
          this.newPlatformPlan = data.status==1?data.data:{};
            this.userHasAPlatformPlan = data.status==1 && typeof this.newPlatformPlan.modules !="undefined" && this.newPlatformPlan.modules.length>0;
            let modules:any =[];
            if(!this.userHasAPlatformPlan){
              this.newPlatformPlan.modules =[];
              for(let module of this.modules){
                module =  this.utils.copyObject(module);
                module.operations.forEach((operation:any)=>operation["action"]="add");
                let moduleToPlan:any = {module_id:module.id,module_name:module.name,permissions:[]};
                super.prepareModulePermissionsToPlatformPlan({...{action:"add"},...module},moduleToPlan.permissions,[],undefined,this.userId)
                modules.push(moduleToPlan);
              }
            }
          this.newPlatformPlan.modules = this.userHasAPlatformPlan?this.newPlatformPlan.modules:(modules);          
          this.newPlatformPlanCopy= this.utils.copyObject(this.newPlatformPlan);

          let selectedPlanRoles:any = [];
          if(this.userHasAPlatformPlan)
            this.newPlatformPlan.platform_plan_role.forEach((item:any)=>selectedPlanRoles.push(item.role_id));
          
          //Se filtra solo los modulos que son asignables a un usuario de rol usuario
          this.filteredModules =this.modules.filter((module:any)=>{
            if(this.newPlatformPlan.modules.every((moduleWithPermissions:any)=>moduleWithPermissions.module_id!=module.id)){
                let response = selectedPlanRoles.map((roleId:number)=>module.module_roles.includes(Number(roleId)) && module.module_roles.includes(this.dataService.USER_ROLES.USER) );
                return response.every((item:boolean)=>item);  
            }
            return false;
          });
        }).catch((error:any)=>{});
    }).catch((error:any)=>{});
  }
  addNewModuleWithPermissions(){
    let error:any=this.isValidmodulePermissionsToAddToPlatformPlan(this.selectedModule,this.modulePermissionsToAddToPlatformPlan);
    if(!error["status"]){
      this.utils.showMsg("",error["message"]);
      return;
    }
    let dataToSend={
      permissions:this.modulePermissionsToAddToPlatformPlan,
      user_api_hash:this.user.hash,
      action:"add"
    };
    this.loading=true;
    this.api.createData(dataToSend, "permission",true).then((data: any) => {
      if(data.status){
        this.utils.showResultRequest("success","Información",data.message);
        this.modulePermissionsToAddToPlatformPlan=[];
        this.selectedModule =[];
        this.selectedModulePermissions=[];
        this.getList();
      } else {
        this.utils.showResultRequest("error","Información",data.message);
      }
      this.utils.hideLoading(()=>this.loading=false);
    }).catch((err: any) => {
      this.utils.hideLoading(()=>this.loading=false);
      this.utils.showResultRequest("error","Permisos no registrados","Se ha presentado un error mientras se intentaba registrar los permisos");
    });
  }
  deleteModulePermissions(module:any){
    this.utils.showConfirm("Confirmar acción","Esta seguro de eliminar los permisos al usuario","Confirmar","Cancelar").then(()=>{
      let data={user_api_hash:this.user.hash,action:"delete", module:module};
      this.api.createData(data, "permission",true).then((data: any) => {
        this.utils.showResultRequest(data.status==1?"success":"error","Información",data.message);
        if(data.status==1)
          this.getList();
      }).catch((err: any) => {
        this.utils.showResultRequest("error","Información","Se ha presentado un error al eliminar los permisos indicados");
      });
    }).catch(()=>{});
  }
  override prepareModulePermissionsToPlatformPlan(){
    this.selectedModulePermissions = [];
    super.prepareModulePermissionsToPlatformPlan(this.selectedModule,this.selectedModulePermissions,this.modulePermissionsToAddToPlatformPlan,undefined,this.userId);
  }
  updatePermissions(){
    this.utils.showConfirm("Confirmar actualización de permisos del usuario","","Confirmar","Cancelar").then(()=>{
      this.prepareModulePlanToSend(this.newPlatformPlanCopy,this.newPlatformPlan,this.userId);
      this.newPlatformPlanCopy["action"]="update";
      this.newPlatformPlanCopy["user_api_hash"]=this.user.hash;
      if(!this.userHasAPlatformPlan)
        for(let module of this.newPlatformPlanCopy.modules){
         module.permissions.forEach((permission:any)=> typeof permission.action !="undefined" && permission.action =="delete"?delete permission.action:""); 
        }
        this.loading=true;
      this.api.createData(this.newPlatformPlanCopy, "permission",true).then((data: any) => {
        this.utils.showResultRequest(data.status==1?"success":"error","Información",data.message);
        (data.status==1)?this.getList():"";
        this.utils.hideLoading(()=>this.loading=false);
      }).catch((err: any) => {
        this.utils.hideLoading(()=>this.loading=false);
        this.utils.showResultRequest("error","Información",this.api.getDefaultMessage("permisos de usuario",true,false,"POST"));
      });
    }).catch((error:any)=>{});
  }
  close() {
    this.closeUpdateModal.emit(true);
  }
}