

<section class="form-box-container animate__animated animate__fadeIn" >
    <section class="form-box-container__header">
        <h2 class="form-box-container__header-title"><span class="icon"><i class="fa-solid fa-car"></i></span>Control de dispositivos </h2>
        <div  class="form-box-container__header-options">                     
            <button matTooltip="Exportar datos" class="btn btn-outline-secondary" (click)="exportFile()"><i
                class="fa-solid fa-file-export fa-sm"></i><span class="description"> Exportar</span></button>
            <div class="search">
                <label for="inputsearch" class="left"><mat-checkbox matTooltip="Buscar por campo dirección" color="primary" matTooltipPosition="left" [(ngModel)]="searchByAddress"></mat-checkbox></label>
                <input id="inputSearch" type="search" placeholder="Buscar" (input)="searchDevices()" [(ngModel)]="searchValue"><!--searchImei-->
                <label for="inputsearch"><i class="fa-solid fa-magnifying-glass fa-sm"></i></label>
            </div>
        </div>
    </section>
    <div class="form-box animate__animated animate__fadeIn">
  
        <div class="form-box__content form-box__content-h-auto">
          <div class="form-box__content-header form-box__content-header-horizontal p-0 form-box__content-header-align-end">
                <mat-checkbox 
                matTooltip="Incluir dispositivos disponibles" 
                color="primary"
                class="scale-75"
                name="availabilityStatus"
                [value]="availabilityStatus.available.toString()"
                [checked]="isDeviceAvailabilityStatus(availabilityStatus.available)"
                (change)="changeDeviceAvailabilityFilter($event)"
                >Disponibles <span class="success">({{availableTotal}})</span></mat-checkbox>
                <mat-checkbox 
                matTooltip="Incluir dispositivos ocupados" 
                color="primary"
                class="scale-75"
                name="availabilityStatus"
                [value]="availabilityStatus.not_available.toString()"
                [checked]="isDeviceAvailabilityStatus(availabilityStatus.not_available)"
                (change)="changeDeviceAvailabilityFilter($event)"
                >Ocupados <span class="warning">({{notAvailableTotal}})</span></mat-checkbox>
          </div>
          <div class="w-100 overflow-auto">
            <table mat-table matSort (matSortChange)="sortData($event)" [dataSource]="filteredDevicesData">

              <ng-container matColumnDef="available">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Ordenar por activación"> Disponible </th>
                <td mat-cell *matCellDef="let device" class="text-center">
                    <div class="status text-primary" *ngIf="device.available == 1" matTooltip="Disponible" matTooltipPosition="left"><i class="fa-solid fa-road-circle-check"></i></div>
                    <div class="status text-warning" *ngIf="device.available != 1" matTooltip="Ocupado" matTooltipPosition="left"><i class="fa-solid fa-road-circle-exclamation"></i></div>    
                </td>
              </ng-container>
              <ng-container matColumnDef="plate_number">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Ordenar por Número de placa"> Placa </th>
                <td mat-cell *matCellDef="let device">
                    <span class="white-space-nowrap d-flex align-items-center" [ngClass]="{'justify-content-between':!validationService.isNullOrEmpty(device.plate_number),'justify-content-end':validationService.isNullOrEmpty(device.plate_number)}" >{{device.plate_number}} 
                        <app-detail-popover [icon]="defineVehicleTypeICon(device.vehicle_type)" [title]="{label:'Número de placa',value:device.plate_number}" [dataSummary]="deviceDataSummary(device)"></app-detail-popover></span> 
                </td>
              </ng-container>
              <ng-container matColumnDef="payload_capacity_in_tons">
                <th mat-header-cell *matHeaderCellDef  mat-sort-header class="mat-header-cell-start" sortActionDescription="Ordenar por capacidad de carga" matTooltip="Capacidad de carga del vehículo (toneladas)"> Capacidad de carga <i class="fa-regular fa-circle-question icon"></i>  </th>
                <td mat-cell *matCellDef="let device"> 
                    {{( validationService.isNullOrEmpty(device.payload_capacity_in_tons)?'':(device.payload_capacity_in_tons+' toneladas')) }}
                </td>
              </ng-container>
              <ng-container matColumnDef="trailer_plate_number">
                <th mat-header-cell *matHeaderCellDef  mat-sort-header class="mat-header-cell-start" sortActionDescription="Ordenar por número de placa de remolque" matTooltip="Número de placa de remolque"> Remolque <i class="fa-regular fa-circle-question icon"></i>  </th>
                <td mat-cell *matCellDef="let device"> 
                    {{ device.trailer_plate_number }}
                </td>
              </ng-container>
              <ng-container matColumnDef="user_driver_email">
                <th mat-header-cell *matHeaderCellDef  mat-sort-header sortActionDescription="Ordenar por email de conductor"> Conductor </th>
                <td mat-cell *matCellDef="let device">
                    <span class="d-flex align-items-center" [ngClass]="{'justify-content-between':!validationService.isNullOrEmpty(device.current_driver),'justify-content-end':validationService.isNullOrEmpty(device.current_driver)}" >
                        {{!validationService.isNullOrEmpty(device.current_driver)?device.current_driver.name:''}}
                        <app-detail-popover *ngIf="!device.available" [icon]="{classes:'text-info',icon:'contacts',icon_type:'material_icon'}" [title]="{label:'Conductor',value:!validationService.isNullOrEmpty(device.current_driver)?device.current_driver.name:''}" [dataSummary]="driverDataSummary(device.current_driver)"></app-detail-popover>
                    </span>
                </td>
              </ng-container>
              <ng-container matColumnDef="phone">
                <th mat-header-cell *matHeaderCellDef  mat-sort-header sortActionDescription="Ordenar por telefono del conductor" matTooltip="Telefono del conductor"> Teléfono conductor <i class="fa-regular fa-circle-question icon"></i> </th>
                <td mat-cell *matCellDef="let device"> {{device.current_driver.phone}}</td>
              </ng-container>
              <ng-container matColumnDef="device_status_record">
                <th mat-header-cell *matHeaderCellDef > Estado </th>
                <td mat-cell *matCellDef="let device">
                    <span class="d-flex align-items-center justify-content-between" [ngClass]="{'text-primary': !validationService.isNullOrEmpty(device.device_status_record?.name),'text-warning':validationService.isNullOrEmpty(device.device_status_record?.name)}">
                        <span>
                            {{validationService.isNullOrEmpty(device.device_status_record?.name)?"Sin información":device.device_status_record!.name}} 
                        </span> 
                        <app-device-status-record-summary-popover [validateLastDeviceStatusRecord]="true" [deviceToConsultStatusRecord] = "device"  showTriggerType="icon"></app-device-status-record-summary-popover>
                    </span>
                </td>
              </ng-container>
              <ng-container matColumnDef="address">
                <th mat-header-cell *matHeaderCellDef > Dirección </th>
                <td mat-cell *matCellDef="let device" class="description-cell"> {{device.address}}</td>
              </ng-container>

                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef > Posición </th>
                    <td mat-cell *matCellDef="let device">
                        <div class="actions-container">
                            <button class="btn btn-outline-primary" matTooltip="Mostrar ubicación" *ngIf="!utils.isNullOrEmpty(device.latitude) && !utils.isNullOrEmpty(device.longitude)" role="link" (click)="redirectToMap(device.latitude,device.longitude)"><i class="fa-solid fa-location-dot fa-xl"></i></button>
                        </div>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell empty py-1" [attr.colspan]="displayedColumns.length">Sin datos</td>
                </tr>
            </table>
          </div>
            <mat-paginator [length]="lengthPage"
            [pageSize]="pageSize"
            [pageIndex]="currentPage"
            [pageSizeOptions]="pageSizeOptions"
            (page)="paginator($event)"
            matTooltipPosition="right"
            aria-label="Selecciona página">
            </mat-paginator>
        </div>
    </div>
    <div class="loader" *ngIf="loading">
        <img src="../../../../../assets/loader.svg">
    </div>
</section>


<mat-menu #informationMenu="matMenu" class="detail-popover">
    <span class="detail-popover__title">
        <h2 class="detail-popover__title-value">{{validationService.isNullOrEmpty(deviceToConsultStatusRecord.plate_number??null)? 'Sin placa definida' : deviceToConsultStatusRecord.plate_number}}</h2>
        <small class="detail-popover__title-label">{{'Últimos estados'}}</small>
    </span>
    <span mat-menu-item [disableRipple]="true" (click)="$event.stopPropagation()" class="detail-popover-content">
        <mat-list class="list detail-section">
            <ng-container *ngFor="let item of deviceStatusRecordSummary">
                <mat-list-item  class="item"><span class="item__label">{{item.name}} <mat-icon *ngIf="!validationService.isNullOrEmpty(item.description??null)"  class="text-info" [matTooltip]="item.description??''">info</mat-icon> </span><span class="item__value">{{item.created_at | date:'EEEE, MMMM d, y h:mm:ss a'}}</span> </mat-list-item>
                <mat-divider></mat-divider>
            </ng-container>
        </mat-list>
    </span>
</mat-menu>