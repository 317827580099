import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {MatNativeDateModule} from '@angular/material/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { MatSortModule } from '@angular/material/sort';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatSelectModule} from '@angular/material/select';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatDialogModule} from '@angular/material/dialog';
import {MatRadioModule} from '@angular/material/radio';
import {MatInputModule} from '@angular/material/input';
import {MatBadgeModule} from '@angular/material/badge';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import { MatListModule } from '@angular/material/list';
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatMenuModule} from '@angular/material/menu';
import {DragDropModule} from '@angular/cdk/drag-drop';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { MatOptionModule } from '@angular/material/core';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import {MatCardModule} from '@angular/material/card';
import {MatTabsModule} from '@angular/material/tabs';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatStepperModule} from '@angular/material/stepper';
import {MatRippleModule} from '@angular/material/core';

@NgModule({
  declarations: [],
  exports: [
    CommonModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatSortModule,
    MatTooltipModule,
    MatPaginatorModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatButtonToggleModule,
    MatCheckboxModule,
    MatDialogModule,
    MatRadioModule,
    MatInputModule,
    MatExpansionModule,
    MatSnackBarModule,
    MatListModule,
    MatButtonModule,
    MatTableModule,
    MatAutocompleteModule,
    MatMenuModule,
    DragDropModule,
    MatOptionModule,
    NgxMatSelectSearchModule,
    MatCardModule,
    MatTabsModule,
    MatBadgeModule,
    MatFormFieldModule,
    MatIconModule,
    MatStepperModule,
    MatRippleModule
  ],
  providers: [MatDatepickerModule, MatNativeDateModule,
  { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { disableClose: true } }
  ],

})
export class AngularMaterialModule { }
