import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiService } from '../api/api.service';
import { DataService } from '../data/data.service';
import { ValidationService } from '../validation/validation.service';
import { MessageBoxService } from '../message-box/message-box.service';
import { UtilsService } from '../utils/utils.service';
import { IUserPlanModule, IUserSessionData } from 'src/app/shared/models/interfaces/iuser-session-data.model';
import { Router } from '@angular/router';
import { IDashboardMenu } from 'src/app/shared/models/interfaces/idashboard-menu.model';
import { UserType } from 'src/app/shared/types/common-types.type';
import { ObjectInitializationService } from '../object-initialization/object-initialization.service';

export const ROUTES_TO_LOGIN: Record<UserType,string> = {user:"login",user_driver:"login/driver"};

export interface IAPIResponseLoginIntgps {
  status:1|0,
  user_api_hash?:string,
  permissions?:any,
  email?:string
}
export interface IAPIResponseLoginSgc {
  status:1|0,
  user?:IUserSessionData,
  message?:string
}
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  
  permissionsUrl:string="";
  user:any={};
  platformPlan:any={};
  constructor(
    private http: HttpClient,
    private api:ApiService,
    private route: Router,
    private dataService:DataService,
    private validationService:ValidationService,
    private messageBoxService: MessageBoxService,
    private utils: UtilsService,
    private objectInitializationService:ObjectInitializationService
  ) { 
    this.user= this.dataService.getData("user");
    this.platformPlan= this.dataService.getData("userSubscriptionPlan");
    this.permissionsUrl = this.api.apiRouteSGC+"platformPlan?action=getPlatformPlan&user_api_hash="+this.user.hash+"&id="+this.platformPlan.id; 
  }
  checkPermissions(): Observable<any> {
    return this.http.get<any>(this.permissionsUrl);
  }
  
  getUserPermissions(){

  }
  changeTypePasswordInput(typePassword:"password"|"text"){
    return typePassword=="password"? "text" : "password";
  }
  /** @description Realiza comprobacion de login en la plataforma */
  async logInIntgps(username:string,password:string):Promise<IAPIResponseLoginIntgps>{
    let response:IAPIResponseLoginIntgps = {status:0};

    if(!this.isEstablishedCredential(username,"",password,0,false))
      return response;
    const formData:any =  new FormData();
    formData.append("email",username);
    formData.append("password",password);
    try{
      let apiResponse:unknown = await this.api.getDataPost("api/login",formData);
      if(this.isAPIResponseLoginIntgps(apiResponse)){
        response.status = 1;
        response.email = username;
        response.user_api_hash = apiResponse.user_api_hash;
        this.dataService.setData("permissionsIntgps", apiResponse.permissions);   
      }
      return response;
    }catch(error:any){
      this.utils.showResultRequest("error","No se pudo iniciar sesión","email o contraseña incorrecta");
      return response;
    }
  }
  isAPIResponseLoginIntgps(obj:any):obj is IAPIResponseLoginIntgps{
    return (typeof obj.status =="boolean" || typeof obj.status == "number");
  }
  isAPIResponseLoginSgc(obj:any):obj is IAPIResponseLoginSgc{
    return (typeof obj.status == "number" || typeof obj.status == "boolean");
  }
  /**
   * @description Realiza peticion de inicio de sesion SGC
   * @param {string} username el nombre de usuario usado para login
   * @param {string} userApiHash el hash para autenticacion
   * @param {number} countryId usado cuando el tipo de usuario requiere de identificar el pais
   * @param {UserType} userType tipo de usuario a loguear. Por defecto "user"
   * @returns {Promise<IAPIResponseLoginSgc>}
   */
  async logInSgc(username:string,userApiHash:string,countryId:number |null,password:string,userType:UserType = "user"):Promise<IAPIResponseLoginSgc>{
  let response: IAPIResponseLoginSgc = {status:0};
  let loginEndpoint: Record <UserType,string> = {user_driver:"driver",user:"user"};
    try{
      if(!this.isEstablishedCredential(username,userApiHash,password,countryId,true,userType))
        return response;
      let requestedData:{action:"login",user_api_hash?:string,document_number?:string,password?:string,country_id?:number|null,email?:string,user_type?:string} = {action:"login"};
      if(userType == "user"){
        requestedData.email = username;
        requestedData.user_api_hash = userApiHash;  
      }
      else if(userType == this.dataService.SPECIAL_USER.user_driver){
        requestedData.country_id = countryId;
        requestedData.document_number = username;
        requestedData.password = password;
      }else{
        this.utils.showResultRequest("error","No se pudo iniciar sesión","No se ha podido determinar el tipo de usuario");
        return response;
      }
      let apiResponse:unknown = await this.api.getDataPost(loginEndpoint[userType],requestedData,true);      
      if(this.isAPIResponseLoginSgc(apiResponse)){
        if(apiResponse.status != 1){
          this.utils.showMsg("No se pudo iniciar sesión",apiResponse.message??this.api.errorWarning);
          return response;
        }
        this.dataService.setData("userSubscriptionPlan",apiResponse.user!.platform_plan);
        this.dataService.setData("user",apiResponse.user);
        if(typeof apiResponse.user !="undefined" && apiResponse.user != null && apiResponse.user!.platform_plan !=null && apiResponse.user!.platform_plan!.modules.length>0){
          if(apiResponse.user.platform_plan.modules.every((module:any)=>module.module_id!=this.dataService.MENU[0].id)){
            let moduleId: number = apiResponse.user.platform_plan.modules[0].module_id;
            let actualView = this.findDashboardMenuItemById(userType == this.dataService.SPECIAL_USER.user_driver ? this.dataService.USER_DRIVER_MENU : this.dataService.MENU,moduleId);
            this.dataService.setData("actualView",actualView);
            if(typeof actualView !="undefined" && actualView != null)
              this.route.navigate([actualView.tab]);
            
          }else{
            this.route.navigate(['/dashboard/home']);
          }
        }else{
          this.utils.showMsg("Solicitud rechazada","El plan al que se encuentra suscrito no tiene modulos disponibles");
        }
        response.status = 1;
      }else
        this.utils.showMsg("No se pudo iniciar sesión",null??this.api.errorWarning);
      return response;
    }catch(error:any){
      this.utils.showResultRequest("error","No se pudo iniciar sesión","email o contraseña incorrecta");
      return response;
    }
  }
  findDashboardMenuItemById(data:IDashboardMenu[], id:number):IDashboardMenu | null {
    for (let index = 0; index < data.length; index++) {
      if (data[index].id === Number(id))
            return data[index];
      if (typeof data[index].children != "undefined" && data[index].children.length > 0) {
        const recursiveResult:any = this.findDashboardMenuItemById(data[index].children, id);
        if (recursiveResult)
            return recursiveResult;
      }
    }
    return null;
  }
  /** @description Indica si las credenciales de acceso del login han sido diligenciadas por el usuario,
 * @returns {boolean} Indica si los datos de login estan establecidos  para enviar
 */
  isEstablishedCredential(username:string,userApiHash?:string,password?:string,countryId?:number| null,isSgcLogin:boolean=true,userType:UserType = "user"){
    
    let isUserNameEmpty = this.validationService.isNullOrEmpty(username);
    let isPasswordEmpty = this.validationService.isNullOrEmpty(password);
    if(!isSgcLogin){
      if( isUserNameEmpty || isPasswordEmpty){
        this.messageBoxService.openSnackBar("Por favor, indique "+(isPasswordEmpty && isUserNameEmpty ? ("email y contraseña"):isPasswordEmpty?"la contraseña":"el email"),"Aceptar");
        return false;
      }
    }else{
      let isCountryIdEmpty = this.validationService.isNullOrEmpty(countryId);
      let isUserApiHashEmpty = this.validationService.isNullOrEmpty(userApiHash);
      if(userType == this.dataService.SPECIAL_USER.user_driver){
        if( isUserNameEmpty || isCountryIdEmpty || isPasswordEmpty){
          this.messageBoxService.openSnackBar("Por favor, indique "+(isPasswordEmpty && isPasswordEmpty && isCountryIdEmpty? ("las credenciales de acceso"):isPasswordEmpty?"la contraseña":(isUserNameEmpty?"el número de documento":"el país")),"Aceptar");
          return false;
        }
      }
      else if( isUserNameEmpty ||isUserApiHashEmpty){
        this.messageBoxService.openSnackBar("Por favor, indique "+(isUserNameEmpty && isUserApiHashEmpty? ("las credenciales de acceso"):isUserApiHashEmpty?"la credencial":("el email")),"Aceptar");
        return false;
      }
    }
    return true;
  }
  logOut(){
    let intervalIdsList = this.dataService.getData(this.dataService.localData.intervals.idsList); 
    let user:IUserSessionData = this.dataService.getData("user");
    let routeToLogin:string = "login";
    
    if(typeof user.special_user_type !="undefined" && user.special_user_type == this.dataService.SPECIAL_USER.user_driver)
      routeToLogin = ROUTES_TO_LOGIN[user.special_user_type];
    
    if(!this.utils.isEmptyObject(intervalIdsList))
      intervalIdsList.forEach((id:any)=>clearInterval(id));
    this.dataService.clearAllData();
    this.route.navigate([routeToLogin]);
  }
  /**
   * @description Indica si el usuario tiene acceso a un determinado componente
   * @param moduleId El id del componente
   * @returns  Los datos del plan para el componente indicado y un status que indica si si posee permisos sobre dicho modulo
   */
  checkPermissionModule(moduleId: number):{status:boolean,data:IUserPlanModule}{
    let response:{status:boolean,data:IUserPlanModule} = {status: false,data: this.objectInitializationService.initializeIUserPlanModule()};
    let permissions =  this.dataService.getData("userSubscriptionPlan").modules.find((module:any)=>{
      if(module.module_id==moduleId)return module.permissions;});
    if(typeof permissions !="undefined" ){
      response.status = true;
      response.data = permissions;
    }
    return response;
  }
}