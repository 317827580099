<section class="form-box-container animate__animated animate__fadeIn" *ngIf="viewsAvailable['automations_list'].show_view">
    <section class="form-box-container__header">
        <h2 class="form-box-container__header-title"><span class="icon"><i class="fa-solid fa-robot"></i></span>Automatizaciones</h2>
        <div  class="form-box-container__header-options">                     
          <ng-container >
            <button matTooltip="crear automatización" class="btn btn-primary" (click)="openAutomationform()"><i class="fa-solid fa-plus icon-plus icon"></i><span class="description"> Crear</span></button>
          </ng-container>
        </div>
    </section>
    <div class="form-box animate__animated animate__fadeIn">
      <section class="form-box__content form-box__content-h-auto" *ngIf="automationsList.length > 0">
        <div class="w100 overflow-auto">
          <table mat-table matSort (matSortChange)="sortData($event)" [dataSource]="automationsList">
    
            <ng-container matColumnDef="select">
              <th mat-header-cell *matHeaderCellDef  mat-sort-header sortActionDescription="Ordenar por estado de activación"> Activo </th>
              <td mat-cell *matCellDef="let automation"> 
                  <mat-slide-toggle
                  [matTooltip]="(automation.active == 1 ? 'Desactivar' : 'Activar') + ' automatización' "
                      [checked]="automation.active == 1"
                      color="primary"
                      class="z-index-last"
                      (change)="updateStatus($event,automation)">
                  </mat-slide-toggle>    
              </td>
            </ng-container>
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Ordenar por nombre"> Nombre automatización</th>
              <td mat-cell *matCellDef="let automation"> {{automation.name}} </td>
            </ng-container>
            <ng-container matColumnDef="active">
              <th mat-header-cell *matHeaderCellDef  mat-sort-header sortActionDescription="Ordenar por estado de datos"  class="mat-header-center"> Estado </th>
              <td mat-cell *matCellDef="let automation" class="text-center"> 
                  <div class="status complete" *ngIf="automation.active == 1"><i class="fa-solid fa-circle-check"></i></div>
                  <div class="status incomplete" *ngIf="automation.active != 1"><i class="fa-solid fa-circle-xmark"></i></div>    
              </td>
            </ng-container>
            <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef mat-sort-header disabled class="mat-header-center"> Acciones </th>
              <td mat-cell *matCellDef="let automation" class="text-center"> 
                <div class="actions-container">
                  <button mat-button [matMenuTriggerFor]="actions"><i class="fa-solid fa-gear"></i></button>
                  <mat-menu #actions="matMenu">
                      <button class="text-primary" mat-menu-item matTooltip="Editar" matTooltipPosition="left" *ngIf="dataService.checkPermission(MODULE_OPERATIONS_SGC.EDIT, this.permissionsDataSgc.permissions)" (click)="openAutomationform(automation)"><i class="fa-solid fa-pen-to-square"></i> Editar</button>
                      <button class="text-danger" mat-menu-item matTooltip="Eliminar" *ngIf="dataService.checkPermission(MODULE_OPERATIONS_SGC.DELETE, this.permissionsDataSgc.permissions)"  matTooltipPosition="left" (click)="deleteAutomation(automation)" ><i class="fa-solid fa-trash-can"></i> Eliminar</button>
                  </mat-menu>
                </div>
              </td>
            </ng-container>
    
            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            <tr *matNoDataRow>
              <td class="mat-cell empty py-1" [attr.colspan]="displayedColumns.length">Sin datos</td>
          </tr>
          </table>
        </div>
        <mat-paginator  [length]="pagination.length_page" [pageSize]="pagination.page_size" [pageIndex]="pagination.current_page"
          [pageSizeOptions]="pagination.page_size_options" (page)="paginator($event)"
          aria-label="Selecciona página de la lista de automatizaciones">
        </mat-paginator>
      </section>
        <section  class="form-box__content form-box__content-h-auto justify-content-center"  *ngIf="automationsList.length == 0">
          <article class="ilustration animate__animated animate__bounceIn">
            <dotlottie-player autoplay loop mode="normal"
            src="../../../assets/img/robot.lottie"
            ></dotlottie-player>
            <span class="description">
              Automatiza procesos que incentiven a la fidelización de tus  <strong class="text-primary"> clientes</strong>. <br> En este módulo encontraras herramientas que proporcionan un valor agregado para el reconocimiento y crecimiento empresarial
            </span>
          </article>
        </section>
    </div>
    <div class="loader" *ngIf="loading">
        <img src="../../../../assets/loader.svg">
    </div>
  </section>

  <app-automation-form *ngIf="viewsAvailable['automation_form'].show_view" [selectedAutomation]="automationToEdit" (close)="closeAutomationForm($event)"></app-automation-form>