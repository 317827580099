import { Component } from '@angular/core';

@Component({
  selector: 'app-phone-line-registration-confirmation',
  templateUrl: './phone-line-registration-confirmation.component.html',
  styleUrls: ['./phone-line-registration-confirmation.component.scss']
})
export class PhoneLineRegistrationConfirmationComponent {

  readonly PRICE_PER_MONTH = { price: 12, monetary_unit_code: "USD"};  
}
