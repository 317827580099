import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { ApiService } from 'src/app/core/api/api.service';
import { DataService } from 'src/app/core/data/data.service';
import { ObjectInitializationService } from 'src/app/core/object-initialization/object-initialization.service';
import { UtilsService } from 'src/app/core/utils/utils.service';
import { ValidationService } from 'src/app/core/validation/validation.service';
import { RequestMethodHTTP } from 'src/app/shared/enums/common-enums.enum';
import { IPagination, ISort } from 'src/app/shared/models/interfaces/iapi-request-data.model';
import { IUserSessionData } from 'src/app/shared/models/interfaces/iuser-session-data.model';

interface ICaseronesOwlLogsWebService{
  id: number 
  user_id: number, 
  device_id: number, 
  device_plate_number: string, 
  device_imei: string,
  alert_name_convention: string, 
  created_at: string, 
  result: string, 
  device_time: string
}
@Component({
  selector: 'app-caserones-owl-logs-web-service',
  templateUrl: './caserones-owl-logs-web-service.component.html',
  styleUrls: ['./caserones-owl-logs-web-service.component.scss']
})
export class CaseronesOwlLogsWebServiceComponent {

  @Output() closeModal = new EventEmitter<boolean>();

  user:IUserSessionData;

  searchTimer!: NodeJS.Timeout;
  displayedColumns: string[] = ['device_imei', 'device_plate_number', 'alert_name_convention', 'created_at','device_time',"result"];
  /**el valor a buscar dentro de la lista de registros */
  searchValue: string = "";
  pagination: IPagination;
  loading:boolean = false;
  /**indica los datos para el ordenamiento de la tabla */
  sort: ISort;
  devices:ICaseronesOwlLogsWebService[] = [];
  constructor(
    private dataService:DataService,
    public utils:UtilsService,
    private api:ApiService,
    private objectInitializationService: ObjectInitializationService,
    private validationService: ValidationService
  ){
    this.user = this.dataService.getData("user") as IUserSessionData;
    this.pagination = this.objectInitializationService.initializeIPagination(this.dataService.PAGE_SIZE_OPTION);
    this.pagination.page_size = this.pagination.page_size_options[0];
    this.sort = this.objectInitializationService.initializeISort();
  }
  ngOnInit(): void {
    this.getLogs();
  }
  getLogs(){
    let data: string = this.api.structureParametersForConsult(RequestMethodHTTP.GET,"get",this.user.hash,this.searchValue,this.pagination.current_page,this.pagination.page_size,this.sort) as string
    console.log(data);
    this.api.getData("caseronesOwlLogWebService",data,true).then((data: unknown) => {
      if(this.validationService.isResponseApi(data)){
        this.devices = data.status ==1 ? data.data : [];
        this.pagination.length_page = data.status == 1 ? (data.total ?? 0) : 0; 
      }
    }).catch((error:any)=>{
      this.devices = [];
      this.pagination.length_page = 0;
      this.utils.showResultRequest("error", "", this.api.getDefaultMessage("la lista de registros", false, false, RequestMethodHTTP.GET));
    }).finally(() =>  this.utils.hideLoading((response:boolean)=>this.loading=response));
  }
  searchValueInData(){
    clearTimeout(this.searchTimer);
    this.searchTimer = setTimeout(() => {
      this.pagination.current_page = 0;
      this.getLogs();
    }, 800);
  }
  sortData(sort: Sort) {
    this.sort.direction=sort.direction;
    this.sort.by=sort.active;
    this.getLogs();
  }
  paginator(event:PageEvent){
    this.pagination.current_page = event.pageIndex;  
    this.pagination.page_size = event.pageSize;
    this.getLogs();
  }
  close() {
    this.closeModal.emit(true);
  }
}
