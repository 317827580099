import { Component, OnInit } from '@angular/core';
import { ValidationService } from 'src/app/core/validation/validation.service';
import { UtilsService } from 'src/app/core/utils/utils.service';
import { DataService } from 'src/app/core/data/data.service';
import { IDriverGeneral } from 'src/app/shared/models/interfaces/idriver.model';
import { Router } from '@angular/router';
import { ObjectInitializationService } from 'src/app/core/object-initialization/object-initialization.service';
import { IViewsAvailable } from 'src/app/shared/models/interfaces/views-available';
import { Sort } from '@angular/material/sort';
import { ApiService } from 'src/app/core/api/api.service';

@Component({
  selector: 'app-driver-management',
  templateUrl: './driver-management.component.html',
  styleUrls: ['./driver-management.component.scss']
})
export class DriverManagementComponent implements OnInit{
  
    /** Almacena la lista de vistas disponibles en el componente */
    viewsAvailable:IViewsAvailable={
      driver_management:{name:"driver_management",show_view:true},
      driver_form:{name:"driver_form",show_view:false}
    };
    loading:boolean=false;
    sort={
      by:"",
      direction:""
    };
    user:any={};
    moduleId:number=17;
    permissionsDataSgc:any=[];//permisos sobre el modulo

    /**Almacena el id del timer para consulta a traves del campo de busqueda */
    searchTimer:any;
    /** Valor a buscar en la tabla de conductores */
    searchValue:string ="";
    /**Los conductores del usuario */
    driverList:IDriverGeneral[]=[];
    /**
     * @type {IDriverGeneral} Datos del conductor seleccionado para editar */
    selectedDriver!:IDriverGeneral;

    //para paginacion
    lengthPage=0;
    pageSize!:number;
    pageSizeOptions:any=[];
    pageEvent:any;
    currentPage=0;
    /**Columnas a mostrar en la tabla */
    displayedColumns:any =["name","document_number","phone","rfid","email","actions"];
    constructor(
      public utils: UtilsService,
      public validationService:ValidationService,
      private dataService:DataService,
      private objectInitialize:ObjectInitializationService,
      private route:Router,
      private api: ApiService
    ){
      this.user = this.dataService.getData("user");
    }
    ngOnInit(): void {
      this.dataService.checkPermissionModule(this.moduleId).then((permissions: any) => {
        this.permissionsDataSgc=permissions;
        this.pageSizeOptions = this.dataService.PAGE_SIZE_OPTION;
        this.pageSize = this.pageSizeOptions[0];
        this.getList();
      }).catch(() => {
        this.utils.showMsg("Página no autorizada","No tiene permisos para ver esta página, contacte al administrador");
        this.route.navigate(['/']);
      });
    }
    sortData(sort: Sort) {
      this.sort.direction = sort.direction;
      this.sort.by = sort.active;
      this.getList();
    }
    getList(page = this.currentPage){
      this.loading = true;
      let data="?action=get&user_api_hash="+this.user.hash+"&page="+(page)+"&page_size="+this.pageSize;
      if(this.sort.by!="")
        data +="&sort_by="+this.sort.by+"&sort_direction="+this.sort.direction;
      if(this.searchValue !="")
        data +="&search_value="+this.searchValue;
      this.api.getData("driver",data,true).then((data: any)=>{
        if(data.status==1){
          this.lengthPage =data.total;
          this.driverList= data.data;
          this.currentPage = page;
        }else{
          this.lengthPage =0;
          this.driverList= [];
        }  
        console.log(data);
        this.utils.hideLoading(()=>this.loading=false);
      }).catch((error:any)=>{
        console.log(error);
        this.utils.hideLoading(()=>this.loading=false);
        this.driverList=[];
        this.utils.showResultRequest("error","Información",this.api.getDefaultMessage("la lista de conductores",false,false,"GET"));
      });
    }
    deleteDriver(driver:IDriverGeneral){
      this.utils.showConfirm("Confirmar acción","Esta seguro de eliminar al usuario <strong>"+(driver.name+ " "+(this.validationService.isNullOrEmpty(driver.document_number)?"":("("+driver.document_number+")")))+"</strong>","Eliminar","Cancelar").then(()=>{
        
        this.api.getData("api/destroy_user_driver","?user_api_hash="+this.user.hash+"&user_driver_id="+driver.id).then((result: any)=>{
          if(result.status != 1)
            return this.utils.showResultRequest("error","Información",this.api.getDefaultMessage("al conductor INTGPS",false,false,"DELETE"));  
          this.utils.hideLoading(()=>this.loading=false);
          let data={user_api_hash:this.user.hash,action:"delete",id:driver.id};
          
          this.api.createData(data, "driver",true).then((data: any) => {
            this.utils.showResultRequest(data.status==1?"success":"error","Información",data.status==1?(data.message??this.api.getDefaultMessage("",false,true,"DELETE")):(data.message??this.api.getDefaultMessage("al conductor",false,false,"DELETE")));
            data.status==1?this.getList():null;
            this.utils.hideLoading(()=>this.loading=false);
          }).catch((err: any) => {
            this.utils.showResultRequest("error","Información",this.api.getDefaultMessage("al conductor",false,false,"DELETE"));
          });
        }).catch((error:any)=>{
          this.utils.hideLoading(()=>this.loading=false);
          this.utils.showResultRequest("error","Información",this.api.getDefaultMessage("al conductor INTGPS",false,false,"DELETE"));
        });

      }).catch(()=>{});
    }
    searchValueInData(){
      clearTimeout(this.searchTimer);
      this.searchTimer = setTimeout(() => {
        this.currentPage=0;
        this.getList();
      }, 800);
    }
    paginator(event:any){
      this.pageSize = event.pageSize;
      this.currentPage = event.pageIndex;    
      this.getList();
    }
    openDriverForm(driver?:IDriverGeneral){
      const newDriverEmpty = this.objectInitialize.initializeIDriver(this.user.id,null);
      this.selectedDriver = typeof driver !="undefined"?Object.assign(newDriverEmpty,driver):newDriverEmpty;
      this.utils.setSelectedView(this.viewsAvailable,this.viewsAvailable["driver_form"].name);
    }
    closeDriverForm(event:any){
      !this.validationService.isNullOrEmpty(event.update_list) && event.update_list ? this.getList() : null;
      this.utils.setSelectedView(this.viewsAvailable,this.viewsAvailable['driver_management'].name)
    }
    exportFile(){
      this.utils.showConfirm("¿Está seguro de exportar los datos?", "Los datos pueden tomar un tiempo en procesarse", "Continuar", "Cancelar").then(() => {
        this.loading = true;
        setTimeout(() => {
          let headers = (this.displayedColumns.slice());
          headers.splice(headers.findIndex((item:string)=>item=="actions"),1);
          this.utils.downloadFileAsCSV(this.driverList,  "drivers_" + new Date().getTime().toString(), headers);
          this.loading = false;
          this.utils.showMsg("","Datos exportados con éxito");
        }, 1200);
      });
    }
}