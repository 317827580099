<form class="form-aside overflow-auto vh-70">
    <div class="form-main">
        <div class="container-table">
            <table class="angular-table" mat-table [dataSource]="newPlatformPlan.modules" class="mat-elevation-z8 demo-table">
                <ng-container matColumnDef="module_name" >
                    <th mat-header-cell *matHeaderCellDef class="border-right-primary"> Modulo </th>
                    <td mat-cell *matCellDef="let element" class="border-right-primary"> {{element.module_name}} </td>
                  </ng-container>
                  <ng-container matColumnDef="total_permissions">
                    <th mat-header-cell *matHeaderCellDef > Total permisos </th>
                    <td mat-cell *matCellDef="let element"> {{element.permissions.length}} </td>
                  </ng-container>
                  <ng-container matColumnDef="permissions">
                    <th mat-header-cell *matHeaderCellDef> Permisos </th>
                    <td mat-cell *matCellDef="let element;let i = index"> 
                        <div class="mat-form-field">
                            <mat-form-field appearance="fill"class="animate__animated animate__faster" >
                                <mat-select 
                                [disabled]="isAPlatformPlanModule(element)" 
                                [name]="'modulePermissions'+i" 
                                [multiple]="true" 
                                [(ngModel)]="element.permissions" 
                                [compareWith]="comparePermissionsSelected" 
                                [matTooltip]="!isAPlatformPlanModule(element)?'':'No se puede modificar los permisos porque este pertenece al plan'"
                                (selectionChange)="isNewPlatformPlanModified=true">
                                    <mat-option *ngFor="let operation of getModulePermissions(element,modules)" [value]="operation"  [selected]="isPermissionSelected(operation,element)">{{operation.name}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>    
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef class="mat-header-cell-center"> Acciones </th>
                    <td mat-cell *matCellDef="let element" >
                        <div class="actions-container" [matTooltip]="!isAPlatformPlanModule(element)?'Eliminar todos los permisos del módulo':'No se puede eliminar el módulo porque este pertenece al plan'"><!--element.module_id==1, home-->
                            <button class="text-primary" [disabled]="loading || isAPlatformPlanModule(element)" (click)="deleteModulePermissions(element)" ><i class="fa-solid fa-trash-can fa-sm clas"></i></button>
                        </div>
                    </td>
                  </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell empty py-1" [attr.colspan]="displayedColumns.length">Sin datos</td>
                </tr>
              </table>
        </div>
        <div class="actions actions--top border-bottom-primary">
            <button class="btn btn-primary" 
            (click)="updatePermissions()"
            matTooltip="Actualizar el conjunto de permisos" 
            [disabled]="!userHasAPlatformPlan" 
            mat-raised-button> Actualizar</button>
        </div>
    </div>
    <aside class="form-aside__sidebar overflow-auto vh-60 position-relative">
        <h3 class="position-sticky z-index-1 top-0 ">Asignar nuevo modulo</h3>
        <mat-form-field appearance="fill">
            <mat-label>Modulo</mat-label>
            <mat-select name="module"
            [disabled]="!userHasAPlatformPlan" 
            [(ngModel)]="selectedModule" (selectionChange)="prepareModulePermissionsToPlatformPlan()" >
                <mat-option *ngFor="let module of filteredModules" [value]="module" >{{module.name}}</mat-option>
                <mat-option [value]="" *ngIf="filteredModules.length==0"  [disabled]="filteredModules.length==0">No hay modulos disponibles</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill"class="animate__animated animate__faster" >
            <mat-label>Permisos</mat-label>
            <mat-select name="platformPlanPermissions" [disabled]="selectedModulePermissions.length==0 || !userHasAPlatformPlan" [multiple]="true" [(ngModel)]="modulePermissionsToAddToPlatformPlan">
                <mat-option *ngFor="let permission of selectedModulePermissions" [value]="permission">{{permission.name}}</mat-option>
            </mat-select>
        </mat-form-field>
        <div class="actions border-bottom-primary py-1">
            <button class="btn btn-primary" 
            (click)="addNewModuleToPlatformPlanWithPermissions()" 
            [disabled]="!isValidmodulePermissionsToAddToPlatformPlan(selectedModule,modulePermissionsToAddToPlatformPlan)['status']" 
            matTooltip="Agregar permisos seleccionados" 
            mat-raised-button><i class="fa-solid fa-plus"></i></button>
        </div>
    </aside>
  </form>