import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/core/api/api.service';
import { DataService } from 'src/app/core/data/data.service';
import { MessageBoxService } from 'src/app/core/message-box/message-box.service';
import { ObjectInitializationService } from 'src/app/core/object-initialization/object-initialization.service';
import { UtilsService } from 'src/app/core/utils/utils.service';
import { ValidationService } from 'src/app/core/validation/validation.service';
import { IApiRequestData } from 'src/app/shared/models/interfaces/iapi-request-data.model';
import { IChecklistAnswerOptionActionType } from 'src/app/shared/models/interfaces/ichecklist-answer-option-action-type.model';
import { IChecklistDataNameUtil, IChecklistQuestionGroupByCategory, IDeviceChecklist, IDeviceChecklistAnswerOption, IDeviceChecklistGridAnswerOption, IDeviceChecklistGridSubquestion, IDeviceChecklistQuestion, IDeviceChecklistQuestionCategory } from 'src/app/shared/models/interfaces/idevice-checklist.model';
import { IFormControlType } from 'src/app/shared/models/interfaces/iform-control-type.model';
import { IResponseApi } from 'src/app/shared/models/interfaces/iresponse-api.model';
import { IUserSessionData } from 'src/app/shared/models/interfaces/iuser-session-data.model';
import { DeviceChecklistQuestionCategoryComponent } from "../device-checklist-question-category/device-checklist-question-category.component";
import { MatDialog } from '@angular/material/dialog';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { DeviceChecklistDataService } from 'src/app/core/device-checklist-data/device-checklist-data.service';

type MaxFieldLengthDeviceChecklist = Pick <IDeviceChecklist,"name"|"description">;
type MaxFieldLengthQuestion = Pick <IDeviceChecklistQuestion,"label"|"description">;
type MaxFieldLengthAnswerOption = Pick <IDeviceChecklistAnswerOption,"value">;
type MaxFieldLengthGridSubquetion = Pick <IDeviceChecklistGridSubquestion,"value">;
type MaxFieldLengthGridAnswerOption = Pick <IDeviceChecklistGridAnswerOption,"value">;

@Component({
  selector: 'app-device-checklist-form',
  templateUrl: './device-checklist-form.component.html',
  styleUrls: ['./device-checklist-form.component.scss']
})
export class DeviceChecklistFormComponent implements OnInit{

  loading:boolean = false;
  loadingChecklistQuestions:boolean = false;

  user!:IUserSessionData;
  isUpdate:boolean = false;
  maxFieldLengthDeviceChecklist!: Record<keyof MaxFieldLengthDeviceChecklist,number>
  maxFieldLengthQuestion!: Record<keyof MaxFieldLengthQuestion,number>;
  maxFieldLengthAnswerOption!: Record<keyof MaxFieldLengthAnswerOption,number>;
  maxFieldLengthGridSubquetion!: Record<keyof MaxFieldLengthGridSubquetion,number>;
  maxFieldLengthGridAnswerOption!: Record<keyof MaxFieldLengthGridAnswerOption,number>;
  
  /**Almacena los tipos de controles de formularios disponibles, el nombre y id */
  readonly FORM_CONTROL_TYPES = this.dataService.FORM_CONTROL_TYPES;

  formControlTypes:IFormControlType[] = [];
  checklistAnswerOptionActionTypes:IChecklistAnswerOptionActionType[] = [];
  deviceChecklistQuestionCategories:IDeviceChecklistQuestionCategory[] = [];
  deviceChecklistQuestionCategoriesFiltered:IDeviceChecklistQuestionCategory[] = [];
  /**Lista de categorias ya usadas en el checklist */
  deviceChecklistQuestionUsedCategories:IDeviceChecklistQuestionCategory[] = [];
  /** @type {IDeviceChecklist} El checklist a editar o crear */
  @Input() checklist!:IDeviceChecklist;

  /**@type {IDeviceChecklist} Copia del checklist a editar, con el cual se trabaja el proceso de modificacion */
  checklistToEdit!:IDeviceChecklist;
  /**@type {IChecklistQuestionGroupByCategory} Las preguntas agrupadas por categoria del checklist que se esta editando */
  checklistQuestionsGroupedByCategories:IChecklistQuestionGroupByCategory[]= [];

  /**@type {Map} Almacena  el identificador de la pregunta como llave y el tipo de control de formulario seleccionado. Usado para validar los cambios de tipo de control de formulario en las preguntas */
  checklistQuestionsFormControlType = new Map<number,number>();


  /**Almacena los  id de preguntas y su respectivo Timer */
  checklistQuestionsUpdateTimers =  new Map<number,NodeJS.Timer>(); 
  /**Almacena los  id de los gridOptions y su respectivo Timer */
  gridOptionsUpdateTimers =  new Map<string,NodeJS.Timer>(); 
    
  /** @type {number|null} Almacena e id de la categoria nueva a agregar a preguntas des checklist */
  newCategoryToAdd:number|null = null;

  /**@type {NodeJS.Timeout} almacena el id del timer usado para actualizacion de datos basicos del checklist */
  checklistDataUpdateTimer!:NodeJS.Timeout;

  /** @description Indica si el checklist que se esta editando ha sido modificado */
  updatedChecklist:boolean =false;
  /** @type {number} El tiempo, en segundos, de espera para los temporizadores ejecutar la solicitud */
  timerWaitingTime:number = this.dataService.OneSecond*2;
  @Output() closeForm = new EventEmitter<{update_data:boolean}>();
  moduleId:number = 18;
  permissionsDataSgc:any=[];//permisos sobre el modulo
  constructor(
    private router:Router,
    private utils: UtilsService,
    public validationService:ValidationService,
    private api:ApiService,
    private dataService:DataService,
    private initializationService:ObjectInitializationService,
    private messageBox: MessageBoxService,
    private deviceChecklistDataService:DeviceChecklistDataService,
    public categoryDialog: MatDialog,
  ){
    this.user = this.dataService.getData("user") as IUserSessionData;
    this.checklistToEdit = this.initializationService.initializeIDeviceChecklist(this.user.id);
    this.maxFieldLengthDeviceChecklist = { name:150, description:300};
    this.maxFieldLengthQuestion = {label:200,description:200};
    this.maxFieldLengthAnswerOption = {value:180};
    this.maxFieldLengthGridSubquetion = {value:180};
    this.maxFieldLengthGridAnswerOption = {value:180};
  }
  ngOnInit(): void {
    this.dataService.checkPermissionModule(this.moduleId).then((permissions: any) => {
      this.checklistToEdit = this.utils.copyObject(this.checklist);
      this.isUpdate = !this.validationService.isNullOrEmpty(this.checklistToEdit.id);
      this.permissionsDataSgc = permissions;
      Object.values(this.deviceChecklistDataService.CHECKLIST_DATA_NAME_LIST).forEach((target:keyof IChecklistDataNameUtil)=>this.getUtilityData(target));
      this.getChecklistQuestions();
    }).catch(() => {
      this.utils.showMsg("Página no autorizada","No tiene permisos para ver esta página, contacte al administrador");
      this.router.navigate(['/dashboard/fleet-control']);
    }); 
  }
  addNewCategoryToChecklist(category:IDeviceChecklistQuestionCategory){
    this.utils.showConfirm("","Confirmar la agregacion de nueva categoria al checklist","Confirmar","Cancelar").then(()=>{
      this.checklistQuestionsGroupedByCategories.push({category_id:category.id,category_name:category.name,questions:[this.initializationService.initializeIDeviceChecklistQuestion(this.checklistToEdit.id,category.id,0)]});
      this.filterAvailableCategories();
    }).catch(()=>{});
  }
  getChecklistQuestions(){
    this.loadingChecklistQuestions =true;
    this.checklistQuestionsGroupedByCategories = [];
    setTimeout(async () => {
      const response:IResponseApi = await this.makeRequest({device_checklist_id:this.checklistToEdit.id,group_by_question_category:true},"deviceChecklistQuestion","get","las preguntas del checklist");
      this.checklistQuestionsGroupedByCategories = response.status && typeof response.data !="undefined"? response.data as IChecklistQuestionGroupByCategory[]:[];
      this.utils.hideLoading(()=>this.loadingChecklistQuestions =false);
      this.filterAvailableCategories();

      for(let category of this.checklistQuestionsGroupedByCategories){
        category.questions.forEach((question:IDeviceChecklistQuestion)=> this.checklistQuestionsFormControlType.set(question.id!,question.form_control_type_id));
      }
    }, this.dataService.OneSecond*1.5);
  }
  /** @description Obtiene los datos de utilidades segun los target disponibles*/
  async getUtilityData(target:keyof IChecklistDataNameUtil):Promise<void>{
    try{
      let response = await this.deviceChecklistDataService.getUtilityData(target);
      if(this.validationService.isResponseApi(response)){
        target == this.deviceChecklistDataService.CHECKLIST_DATA_NAME_LIST.FORM_CONTROL_TYPES ? this.formControlTypes = response.data as IFormControlType[]:(target==this.deviceChecklistDataService.CHECKLIST_DATA_NAME_LIST.CHECKLIST_ANSWER_OPTION_ACTION_TYPES ? this.checklistAnswerOptionActionTypes = response.data as IChecklistAnswerOptionActionType[]: this.deviceChecklistQuestionCategories = response.data as IDeviceChecklistQuestionCategory[]);
        if(this.deviceChecklistDataService.CHECKLIST_DATA_NAME_LIST.DEVICE_CHECKLIST_QUESTION_CATEGORIES == target)
          this.filterAvailableCategories();
      }
    }catch(error:any){}
  }
  /**Revisa las categorias que ya han sido usadas para crear preguntas y las excluye de la lista de categorias a agregar */
  filterAvailableCategories(){
    this.deviceChecklistQuestionCategoriesFiltered = this.deviceChecklistQuestionCategories.filter((category:IDeviceChecklistQuestionCategory)=>this.checklistQuestionsGroupedByCategories.every((questionCategory:IChecklistQuestionGroupByCategory)=>questionCategory.category_id != category.id)); 
    this.deviceChecklistQuestionUsedCategories = this.deviceChecklistQuestionCategories.filter((category:IDeviceChecklistQuestionCategory)=>this.checklistQuestionsGroupedByCategories.some((questionCategory:IChecklistQuestionGroupByCategory)=>questionCategory.category_id == category.id));
  }
  saveChecklistChangesTimer(deviceChecklist:IDeviceChecklist,isInput:boolean=false){
    if(this.isUpdate){
      clearTimeout(this.checklistDataUpdateTimer);
      this.checklistDataUpdateTimer = setTimeout(() => {
        this.saveChecklistChanges(deviceChecklist);
      }, this.timerWaitingTime);
    }else if(!isInput)
        this.saveChecklistChanges(deviceChecklist);
  }
  /** @description Registra cambios sobre el checklist */
  async saveChecklistChanges(deviceChecklist:IDeviceChecklist){
    this.loading = true;
    if(this.validationService.isNullOrEmpty(deviceChecklist.name.trim()))
      return this.utils.showResultRequest("error","Falta información","Por favor, indique un nombre para el formulario"); 
    let response:IResponseApi = await this.makeRequest(deviceChecklist,"deviceChecklist",this.isUpdate?"update":"add","el checklist");
    
    if(response.status == 1)
      this.updatedChecklist = true;
    if(response.status && !this.isUpdate){
      deviceChecklist.id = response?.data as number;
      this.isUpdate = true;
    }
      if(response.status)
        this.messageBox.openSnackBar(response.message??("Registro exitoso"),"Aceptar",{verticalPosition:"top",horizontalPosition:"end"});
      else
        this.utils.showResultRequest("error","",this.api.getDefaultMessage("checklist",false,false,"POST")); 
  }  
  //inicio de gestion de preguntas
  async saveQuestionModificationsTimer(question:IDeviceChecklistQuestion,isUpdate:boolean=false,inputType:"input"|"select"|"toggle"|""=""){
    let confirm:boolean = true;
    let cleanOptionsList:"answer_option"|"grid_option"|"" = "";
    if(question.id != null && this.checklistQuestionsFormControlType.get(question.id) != question.form_control_type_id){
      let oldFormControlType:number = this.checklistQuestionsFormControlType.get(question.id)??0;
      let newNameFormControlTypeSelected:IFormControlType|null = question.form_control_type_id != null ? (this.formControlTypes.find((type:IFormControlType)=>type.id==question.form_control_type_id)??null):null; 
      let oldnameFormControlType:IFormControlType|null = question.form_control_type_id != null ? (this.formControlTypes.find((type:IFormControlType)=>type.id==oldFormControlType)??null):null; 
      
      let formTypeRadioCheckbox:number[] = [this.FORM_CONTROL_TYPES.CHECKBOX,this.FORM_CONTROL_TYPES.RADIO];
      let formTypeGrid:number[] = [this.FORM_CONTROL_TYPES.CHECKBOX_GRID,this.FORM_CONTROL_TYPES.RADIO_GRID];    
      if(!(formTypeGrid.includes(oldFormControlType) && formTypeGrid.includes(parseInt(question.form_control_type_id.toString())) || formTypeRadioCheckbox.includes(oldFormControlType) && formTypeRadioCheckbox.includes(parseInt(question.form_control_type_id.toString())))){
        try{
          confirm = await this.utils.showConfirm(formTypeGrid.includes(oldFormControlType) ? ("¿Cambiar de una opcion de cuadricula a "+newNameFormControlTypeSelected?.label+"?") : ("¿Cambiar de <strong>"+oldnameFormControlType?.label+"</strong> a una opcion de cuadrícula?"), formTypeGrid.includes(oldFormControlType)?("Pasar de una opción de cuadricula a <strong>"+newNameFormControlTypeSelected?.label+"</strong> eliminará las opciones (filas, columnas) actualmente asignada"):("Pasar de una opción de "+oldnameFormControlType?.label+" a un opción de cuadrícula eliminará las opciones de respuestas actualmente asignada"),"Confirmar","Cancelar");
          cleanOptionsList = formTypeGrid.includes(parseInt(question.form_control_type_id.toString()))?"answer_option":"grid_option";
        }catch(error:any){
          confirm = !!error;
        }
      }
    }
    if(!!!confirm && question.id != null && this.checklistQuestionsFormControlType.get(question.id) != question.form_control_type_id){
      question.form_control_type_id = this.checklistQuestionsFormControlType.get(question.id)!;
      return;
    }
    if(isUpdate){
      clearTimeout(this.checklistQuestionsUpdateTimers.get(question.id!));
      this.checklistQuestionsUpdateTimers.set(question.id!,
        setTimeout(() => {
          this.saveQuestionModifications(question,isUpdate,false,cleanOptionsList);
        }, inputType == "select" || inputType == "toggle"?0:this.timerWaitingTime)) ;
    }else if(inputType !="input") 
        this.saveQuestionModifications(question,isUpdate,false,cleanOptionsList);
  }
  /** @description Registra o actualiza la pregunta en los datos del checklist */
  async saveQuestionModifications(question:IDeviceChecklistQuestion,isUpdate:boolean=false,updateList:boolean=false,cleanOptionList:"answer_option"|"grid_option"|""=""){
    if(this.validationService.isNullOrEmpty(question.label.trim()))
      return this.utils.showMsg("","Por favor, indique el texto de la pregunta");
    if(this.formControlTypes.every((item:IFormControlType)=>item.id != question.form_control_type_id))
      return this.utils.showMsg("","Por favor, indique un tipo de control de campo para la pregunta");
    let response:IResponseApi = await this.makeRequest(question,"deviceChecklistQuestion",isUpdate?"update":"add","la pregunta");
    if(response.status && !isUpdate)
      question.id = response.data;
    if(response.status && updateList)
      this.getChecklistQuestions();
    if(response.status && this.checklistQuestionsFormControlType.get(question.id!) != question.form_control_type_id){
      if(cleanOptionList=="answer_option")
        question.answer_option = [];
      else if(cleanOptionList =="grid_option"){
        question.grid_answer_option = [];
        question.grid_subquestion = [];
      }
      this.checklistQuestionsFormControlType.set(question.id!,question.form_control_type_id)
    }
    this.messageBox.openSnackBar(response.message??(response.status?(isUpdate?"Actualización exitosa":"Registro exitoso"):(isUpdate ?"La actualización ha fallado":"El registro ha fallado")),"Aceptar");
  }
  async showConfirmDeleteQuestion(question:IDeviceChecklistQuestion,questionCategory:IChecklistQuestionGroupByCategory){
    this.utils.showConfirm("¿Eliminar pregunta?","","Confirmar","Cancelar").then(async()=>{            
      let response:IResponseApi = await this.makeRequest({id: question.id},"deviceChecklistQuestion","delete","la pregunta");
      if(response.status)
        questionCategory.questions.splice(questionCategory.questions.findIndex((item:IDeviceChecklistQuestion)=>item.id==question.id),1);
      this.messageBox.openSnackBar(response.message??(response.status?"Eliminación exitosa":"La eliminación ha fallado"),"Aceptar");
    }).catch(()=>{ });
  }
  /** Cambia las posiciones de las preguntas */
  async dropQuestion(event: CdkDragDrop<string[]>,questions:IDeviceChecklistQuestion[]) {
    moveItemInArray(questions, event.previousIndex, event.currentIndex);
    if(event.currentIndex ==event.previousIndex)
      return;
    let questionsToSend: ({id:number,device_checklist_id:number,position:number})[] =[];
    questions.forEach((item:IDeviceChecklistQuestion,index:number)=>questionsToSend.push({id:item.id!,device_checklist_id:item.device_checklist_id,position:index+1}));
    let data: IApiRequestData = {questions: questionsToSend,device_checklist_id:this.checklistToEdit.id};
    let response:IResponseApi = await this.makeRequest(data,"deviceChecklistQuestion","update","posiciones de las preguntas");
    this.messageBox.openSnackBar(response.message??(response.status?"Actualización exitosa":"La actualización ha fallado"),"Aceptar");
  }
  /**@description Prepara nuevo objeto de pregunta para ser agregado a la lista de preguntas del checklist */
  prepareNewQuestion(questionCategory:IChecklistQuestionGroupByCategory){
    questionCategory.questions.unshift(this.initializationService.initializeIDeviceChecklistQuestion(this.checklistToEdit.id,questionCategory.category_id,0));
  }
  //fin de gestion de preguntas

  //inicio gestion de opciones de preguntas
  prepareNewQuestionAnswerOption(question:IDeviceChecklistQuestion){
    let position:number = question.answer_option!.length+1;
    let answer_option:IDeviceChecklistAnswerOption = this.initializationService.initializeIDeviceChecklistAnswerOption(position,question.id??undefined,null,"Opción "+position);  
    question.answer_option!.push(answer_option);
    this.saveQuestionAnswerOptionModifications(answer_option);
  }
  saveQuestionAnswerOptionModificationsTimer(answerOption:IDeviceChecklistAnswerOption,isUpdate:boolean=false,controlType:"input"|"select"|""=""){
    if(isUpdate){
      clearTimeout(this.checklistQuestionsUpdateTimers.get(answerOption.id!));
      this.checklistQuestionsUpdateTimers.set(answerOption.id!,
        setTimeout(() => {
          this.saveQuestionAnswerOptionModifications(answerOption,isUpdate);
        }, controlType =="select"?0:this.timerWaitingTime)) ;
    }else if(controlType !="input" && controlType !="select" )
      this.saveQuestionAnswerOptionModifications(answerOption,isUpdate);
  }
  /** @description Registra o actualiza la opcion de respuesta de la pregunta en los datos del checklist */
  async saveQuestionAnswerOptionModifications(answerOption:IDeviceChecklistAnswerOption,isUpdate:boolean=false){
    answerOption.action_type_id = this.validationService.isNullOrEmpty(answerOption.action_type_id)?null:answerOption.action_type_id; 
    if(this.validationService.isNullOrEmpty(answerOption.value.trim()))
      return this.utils.showResultRequest("error","Datos incompletos","Por favor, indique el texto de la pregunta");
    let response:IResponseApi = await this.makeRequest(answerOption,"deviceChecklistAnswerOption",isUpdate?"update":"add","la opción de respuesta");
    if(response.status && !isUpdate)
      answerOption.id = response.data;
    this.messageBox.openSnackBar(response.message??(response.status?(isUpdate?"Actualización exitosa":"Registro exitoso"):(isUpdate ?"La actualización ha fallado":"El registro ha fallado")),"Aceptar");
  }
  showConfirmDeleteAnswerOption(answerOptions:IDeviceChecklistAnswerOption[],option:IDeviceChecklistAnswerOption){
    this.utils.showConfirm("¿Eliminar opción de respuesta?","","Confirmar","Cancelar").then(()=>{      
      this.deleteAnswerOption(answerOptions,option);
    }).catch(()=>{ });
  }
  async deleteAnswerOption(answerOptions:IDeviceChecklistAnswerOption[],option:IDeviceChecklistAnswerOption){
    let response:IResponseApi = await this.makeRequest({id: option.id},"deviceChecklistAnswerOption","delete","la opción de respuesta");
    if(response.status)
      answerOptions.splice(answerOptions.findIndex((item:IDeviceChecklistAnswerOption)=>item.id==option.id),1);
    this.messageBox.openSnackBar(response.message??(response.status?"Eliminación exitosa":"La eliminación ha fallado"),"Aceptar");
  }
  /**Para el cambio de posicion de las opciones de respuestas*/
  async dropAnswerOption(event: CdkDragDrop<string[]>,answerOptions:IDeviceChecklistAnswerOption[],questionId:number) {
    moveItemInArray(answerOptions, event.previousIndex, event.currentIndex);
    if(event.currentIndex ==event.previousIndex)
      return;
    let answerOptionsToSend: ({id:number,device_checklist_question_id:number,position:number})[] =[];
    answerOptions.forEach((item:IDeviceChecklistAnswerOption,index:number)=>answerOptionsToSend.push({id:item.id!,device_checklist_question_id:item.device_checklist_question_id,position:index+1}));
    let data: IApiRequestData = {answer_options: answerOptionsToSend,device_checklist_question_id:questionId};
    let response:IResponseApi = await this.makeRequest(data,"deviceChecklistAnswerOption","update","posiciones de la lista de opciones de respuesta");
    this.messageBox.openSnackBar(response.message??(response.status?"Actualización exitosa":"La actualización ha fallado"),"Aceptar");
  }
  async toggleAnswerOptionsEnabledActions(question:IDeviceChecklistQuestion,event:MatSlideToggleChange){
    if(!event.checked){
      let answerOptionsToSend: ({id:number,device_checklist_question_id:number, action_type_id:number|null,destination_category_id:number|null})[] =[];
      question.answer_option!.forEach((item:IDeviceChecklistAnswerOption,index:number)=>answerOptionsToSend.push({id:item.id!,device_checklist_question_id:item.device_checklist_question_id,action_type_id:null,destination_category_id:null}));
      let data: IApiRequestData = {answer_options: answerOptionsToSend,device_checklist_question_id:question.id};
      let response:IResponseApi = await this.makeRequest(data,"deviceChecklistAnswerOption","update","la lista de opciones de respuesta");
      this.messageBox.openSnackBar(response.message??(response.status?"Actualización exitosa":"La actualización ha fallado"),"Aceptar");
    }
  }
  //final gestion de opciones de preguntas

  //inicio gestion de tipo de campo grid, cuadriculas
  prepareNewGridOption(question:IDeviceChecklistQuestion,gridType:"grid_subquestion"|"grid_answer_option"){
    let position:number = gridType=="grid_subquestion"?(this.validationService.isNullOrEmpty(question.grid_subquestion??"") ? (1) : ((question.grid_subquestion!.length??0)+1)):(this.validationService.isNullOrEmpty(question.answer_option?.length??0) ? (1) : ((question.answer_option!.length??0)+1) );
    let answerOption:IDeviceChecklistGridAnswerOption;
    let subquestion:IDeviceChecklistGridSubquestion;
    if(gridType=="grid_answer_option"){
      answerOption = this.initializationService.initializeIDeviceChecklistGridAnswerOption(position,null,question.id!, "Columna "+position);
      question.grid_answer_option?.push(answerOption);
    }
    else{
      subquestion = this.initializationService.initializeIDeviceChecklistGridSubquestion(position,question.id!, "Fila "+position);
      question.grid_subquestion?.push(subquestion);
    }
    this.saveGridOptionsModifications(gridType=="grid_answer_option"?answerOption!:subquestion!,false,gridType);
  }
  saveGridOptionsModificationsTimer(gridOption:IDeviceChecklistGridSubquestion|IDeviceChecklistGridAnswerOption,isUpdate:boolean=false,controlType:"input"|"select"|""="",gridType:"grid_subquestion"|"grid_answer_option"){
    if(isUpdate){
      clearTimeout(this.gridOptionsUpdateTimers.get((gridType=="grid_subquestion"?"subquestion_":"answer_option_")+ gridOption.id!));
      this.gridOptionsUpdateTimers.set((gridType=="grid_subquestion"?"subquestion_":"answer_option_")+gridOption.id!,
        setTimeout(() => {
          this.saveGridOptionsModifications(gridOption,isUpdate,gridType);
        }, controlType =="select"?0:this.timerWaitingTime)) ;
    }else if(controlType !="input" && controlType !="select" )
      this.saveGridOptionsModifications(gridOption,isUpdate,gridType);
  }
  /** @description Registra o actualiza la subpregunta */
  async saveGridOptionsModifications(gridOption:IDeviceChecklistGridSubquestion|IDeviceChecklistGridAnswerOption,isUpdate:boolean=false,gridType:"grid_subquestion"|"grid_answer_option"){
    if(this.validationService.isNullOrEmpty(gridOption.value.trim()))
      return this.messageBox.openSnackBar("Por favor, indique el texto  de la pregunta","Aceptar");
    let response:IResponseApi = await this.makeRequest(gridOption,gridType=="grid_subquestion" ? "deviceChecklistGridSubquestion":"deviceChecklistGridAnswerOption",isUpdate?"update":"add",gridType=="grid_answer_option"?"la opción de respuesta de la cuadricula":"la subpregunta");
    if(response.status && !isUpdate)
    gridOption.id = response.data;
    this.messageBox.openSnackBar(response.message??(response.status?(isUpdate?"Actualización exitosa":"Registro exitoso"):(isUpdate ?"La actualización ha fallado":"El registro ha fallado")),"Aceptar");
  }
  deleteGridOption(gridOptions:IDeviceChecklistGridSubquestion[]|IDeviceChecklistGridAnswerOption[],gridOption:IDeviceChecklistGridSubquestion|IDeviceChecklistGridAnswerOption,gridType:"grid_subquestion"|"grid_answer_option"){
    this.utils.showConfirm(gridType =="grid_answer_option" ? "¿Eliminar opción de respuesta?":"¿Eliminar subpregunta?","","Confirmar","Cancelar").then(async ()=>{      
      let response:IResponseApi = await this.makeRequest({id: gridOption.id},gridType=="grid_subquestion"?"deviceChecklistGridSubquestion":"deviceChecklistGridAnswerOption","delete",(gridType=="grid_subquestion"? "la subpregunta ":"la opción de respuesta")+" de cuadrícula");
      if(response.status)
        gridOptions.splice(gridOptions.findIndex((item:IDeviceChecklistGridSubquestion|IDeviceChecklistGridAnswerOption)=>item.id==gridOption.id),1);
      this.messageBox.openSnackBar(response.message??(response.status?"Eliminación exitosa":"La eliminación ha fallado"),"Aceptar");  
    }).catch(()=>{ });
  }
  async dropGridSubquestion(event: CdkDragDrop<string[]>,options:IDeviceChecklistGridSubquestion[]|IDeviceChecklistGridAnswerOption[],questionId:number,gridType:"grid_subquestion"|"grid_answer_option") {
    moveItemInArray(options, event.previousIndex, event.currentIndex);
    if(event.currentIndex ==event.previousIndex)
      return;
    let optionsToSend: ({id:number,device_checklist_question_id:number,position:number})[] =[];
    options.forEach((item:IDeviceChecklistGridSubquestion|IDeviceChecklistGridAnswerOption,index:number)=>optionsToSend.push({id:item.id!,device_checklist_question_id:item.device_checklist_question_id,position:index+1}));
    let data: IApiRequestData = {device_checklist_question_id:questionId};
    data[gridType=="grid_subquestion"?"subquestions":"answer_options"] = optionsToSend;
    let response:IResponseApi = await this.makeRequest(data,gridType=="grid_subquestion" ? "deviceChecklistGridSubquestion":"deviceChecklistGridAnswerOption","update",gridType =="grid_subquestion" ?"posiciones de la lista de subpreguntas":"posiciones de la lista de opciones de respuesta");
    this.messageBox.openSnackBar(response.message??(response.status?"Actualización exitosa":"La actualización ha fallado"),"Aceptar");
  }
  //fin gestion de preguntas de grid

  async makeRequest(dataToSend:IApiRequestData,target:"deviceChecklist"|"deviceChecklistQuestion"|"deviceChecklistAnswerOption"|"deviceChecklistGridSubquestion"|"deviceChecklistGridAnswerOption",action:"delete"|"update"|"add"|"get",targetName:string){
    this.loading = true;
    let data: IApiRequestData = { user_api_hash:this.user.hash, action:action,...dataToSend};
    try{
      let response:any = await this.api.createData(data, target,true);
      this.utils.hideLoading(()=>this.loading=false); 
      if(response.status == 1 && action != "get")
        this.updatedChecklist = true;
      return response;
    }catch(error:any){
      this.utils.hideLoading(()=>this.loading=false);
      this.utils.showResultRequest("error","Información",this.api.getDefaultMessage(  targetName,action=="update",false,action=="get"?"GET":(action=="update"?"POST":"DELETE")));
      return {"status":0};
    }
  }
  openCategoryManagement(){
    const dialogRef = this.categoryDialog.open(DeviceChecklistQuestionCategoryComponent,{data:this.deviceChecklistQuestionCategories});
    dialogRef.afterClosed().subscribe((updateCategoryList:Boolean) => {
      if(updateCategoryList){
        this.dataService.removeData(this.deviceChecklistDataService.UTILS_DATA_LIST.DEVICE_CHECKLIST_QUESTION_CATEGORIES);
        this.getUtilityData(this.deviceChecklistDataService.CHECKLIST_DATA_NAME_LIST.DEVICE_CHECKLIST_QUESTION_CATEGORIES);
      }
    }); 
  } 
  close():void{
    this.closeForm.emit({update_data:this.updatedChecklist});
  }
}