import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

export interface IMessageSnackBar{
  title:string,
  yes_text:string,
  no_text:string,
  onYes:()=>void,
  onNo:()=>void,
  [propName:string]:any
}
@Component({
  selector: 'app-confirmation-snackbar',
  templateUrl: './confirmation-snackbar.component.html',
  styleUrls: ['./confirmation-snackbar.component.scss']
})
export class ConfirmationSnackbarComponent {
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: IMessageSnackBar) { }

  onYesClick(): void {
    this.data.onYes();
  }

  onNoClick(): void {
    this.data.onNo();
  }
}
