import { Component } from '@angular/core';

@Component({
  selector: 'app-user-driver',
  templateUrl: './user-driver.component.html',
  styleUrls: ['./user-driver.component.scss']
})
export class UserDriverComponent {

}
