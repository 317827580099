import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/core/api/api.service';
import { DataService } from 'src/app/core/data/data.service';
import { UtilsService } from 'src/app/core/utils/utils.service';
import { Router } from '@angular/router';
import {FormGroup, FormControl} from '@angular/forms';
import { Sort } from '@angular/material/sort';
import {MatDialog} from '@angular/material/dialog';

import { PaymentOptionsComponent } from "./dialogs/payment-options/payment-options.component";
import { IUserSessionData } from 'src/app/shared/models/interfaces/iuser-session-data.model';
import { AlignDataTable, RequestMethodHTTP } from 'src/app/shared/enums/common-enums.enum';
import { IColumnStructureToDisplayInTable } from 'src/app/shared/models/interfaces/iutil.model';
import { IViewsAvailable } from 'src/app/shared/models/interfaces/views-available';

enum DeviceClassesByExpirationDate {expired="expired",soonToExpire="soonToExpire"};
@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.scss']
})
export class PaymentsComponent implements OnInit {
  viewsAvailable:IViewsAvailable = {
    payment_management_list:{name:"payment_management_list",show_view:true},
    payment_record_list:{name:"payment_record_list",show_view:false}
  };
  //para punto de exportacion
  headers=["plate_number","name","imei","expiration_date","created_at"];
  sourceToExport:any={
    soonToExpire:false,
    expired:false
  }
  alignDataTable: typeof AlignDataTable = AlignDataTable;
  displayedColumns:string[] = [];
  /** Las columnas a mostrar en la tabla */
  columnStructureToDisplay: IColumnStructureToDisplayInTable[]=
  [{name:this.dataService.NUMBERING_COLUMN_HEADER.NAME,label:this.dataService.NUMBERING_COLUMN_HEADER.LABEL,sorted:false, align_header: AlignDataTable.LEFT, align_data: AlignDataTable.LEFT, is_action_column: false},
   {name:"imei",label:"Imei",sorted:true, align_header: AlignDataTable.LEFT, align_data: AlignDataTable.LEFT, is_action_column: false},  
   {name:"name",label:"Nombre",sorted:true, align_header: AlignDataTable.LEFT, align_data: AlignDataTable.LEFT, is_action_column: false},
   {name:"plate_number",label:"Placa",sorted:true, align_header: AlignDataTable.LEFT, align_data: AlignDataTable.LEFT, is_action_column: false},
   {name:"expiration_date",label:"Fecha de expiración",sorted:true, align_header: AlignDataTable.LEFT, align_data: AlignDataTable.LEFT, is_action_column: false},
   {name:"action",label:"Pagar",sorted:false,align_header: AlignDataTable.CENTER, align_data: AlignDataTable.LEFT, is_action_column: true}];

  //usado para el calendario de Renovaciones
  expirationRange:any ={};

  user:IUserSessionData;//los datos del usuario logueado
  loading:boolean=false;//indicacuando se muestra el svg de cargando
  
  allUserDevices:any={
    devicesExpired:[],
    devicesSoonToExpire:[]
  };//almacena los dispositivos del usuario, los pronto a vencer y los expirados segun el rango de fecha
  allUserSoonToExpDevices:any=[];//almacena los dispositivos del usuario pronto a expirar segun el rango de fecha
  allUserExpiredDevices:any=[];//almacena los dispositivos del usuario expirados segun el rango de fecha
  
  devicesLoaded:any=[];//los dispositivos a cargar en la tabla: los vencidos o pronto a vencer;
  devicesLoadedInTableSorted:any=[];//los dispositivos a mostrar en la tabla ordenados: los vencidos o pronto a vencer. Son estos los que se cargan en la tabla

  searchValueInGeneralTable:string="";//valor a buscar en tabla, ya sea por imei o placa
  deviceExpTableDataSource:DeviceClassesByExpirationDate = DeviceClassesByExpirationDate.soonToExpire;
  
  setDevicesToPay:any =new Set();//lista de dispositivos seleccioandos, esto para realizar el pago
  moduleId = 6;
  permissionsDataSgc:any=[];
  constructor(
    public utils:UtilsService,
    private api:ApiService,
    public dataService:DataService,
    public matDialog: MatDialog,
    private router:Router
  ) { 
    this.columnStructureToDisplay.forEach((item:IColumnStructureToDisplayInTable)=> this.displayedColumns.push(item.name));
    this.user =this.dataService.getData("user") as IUserSessionData;
    const date =new Date();
    this.expirationRange= new FormGroup({
      start: new FormControl(new Date(date.getFullYear(),date.getMonth(),1)),
      end: new FormControl(new Date(date.getFullYear(),(date.getMonth()+1),0)),
    })
  }
  ngOnInit(): void {
    this.dataService.checkPermissionModule(this.moduleId).then((permissions: any) => {
      this.permissionsDataSgc = permissions;

      this.getDevicesByDateRange();
    }).catch(() => {
      this.utils.showMsg("Página no autorizada","No tiene permisos para ver esta página, contacte al administrador");
      this.router.navigate(['/']);
    });
  }
  filterDevicesByDateRange(start?:any,end?:any){
    console.log("filtro");
    this.getDevicesByDateRange(start,end);
  }

  toggleLoadExpirationTableDate(){
    let devicesExpired = this.allUserDevices.devicesExpired; 
    let devicesSoonToExpire = this.allUserDevices.devicesSoonToExpire;
    if( (typeof devicesSoonToExpire =="undefined"|| devicesSoonToExpire.length<=0) && this.deviceExpTableDataSource=="expired"){
      this.utils.showMsg("","No hay datos de dispositivos próximos a vencer");
      return;
    }
    if( (typeof devicesExpired =="undefined"|| devicesExpired.length<=0) && this.deviceExpTableDataSource== DeviceClassesByExpirationDate.soonToExpire){
      this.utils.showMsg("","No hay datos de dispositivos expirados");
      return;
    }
    this.loading = true;
    let data:any[] = this.allUserDevices.devicesSoonToExpire.slice();
    let source:DeviceClassesByExpirationDate = DeviceClassesByExpirationDate.soonToExpire;
    if(this.deviceExpTableDataSource== DeviceClassesByExpirationDate.soonToExpire){
      source= DeviceClassesByExpirationDate.expired;
      data = this.allUserDevices.devicesExpired.slice();
    }
    setTimeout(() => {
      this.devicesLoaded=data.slice();
      this.devicesLoadedInTableSorted=data.slice();
      this.deviceExpTableDataSource=source;
      this.utils.hideLoading(() => this.loading = false);
    }, 400);
  }
  searchInGeneralTable(){
    this.searchValueInGeneralTable = this.searchValueInGeneralTable.toLowerCase();
    if(this.searchValueInGeneralTable.length > 0){
      this.devicesLoadedInTableSorted = this.devicesLoaded.filter((device: any) => {
        return device.imei.toLowerCase().includes(this.searchValueInGeneralTable) || device.name.toLowerCase().includes(this.searchValueInGeneralTable) || device.plate_number.toLowerCase().includes(this.searchValueInGeneralTable);
      });
    } else {
      this.devicesLoadedInTableSorted = this.devicesLoaded;
    }
  }
  getDevicesByDateRange(startDate?:any,endDate?:any,page?:any){
    this.loading = true;
    
    if(((startDate ===null || startDate ===false) || (endDate ===false || endDate ===null))){
      this.utils.showMsg("","No ha seleccionado un rango de fechas válida");
      this.utils.hideLoading(() => this.loading = false);
      return;
    }
    let data= this.api.structureParametersForConsult(RequestMethodHTTP.GET,"getDevicesByExpirationDateRange",this.user.hash,undefined,page,undefined,undefined, (typeof startDate !="undefined" && typeof endDate !="undefined")&& ((startDate!==null && startDate !=false) && (endDate !=false && endDate !=null)) ? this.utils.getDateRange(startDate,endDate) : undefined);
    this.api.getData("device",data,true).then((data:any)=>{
      if(data.status==1){
        this.setDevicesToPay= new Set();
        this.allUserDevices =data.data;
        //los primeros a cargar son los proximos  a expirar si tiene datos
        if(data.data.devicesSoonToExpire.length==0){
          this.devicesLoaded=data.data.devicesExpired.slice();
          this.devicesLoadedInTableSorted=data.data.devicesExpired.slice();
          this.deviceExpTableDataSource = DeviceClassesByExpirationDate.expired;
        }else{
          this.devicesLoaded=data.data.devicesSoonToExpire.slice();
          this.devicesLoadedInTableSorted=data.data.devicesSoonToExpire.slice();
          this.deviceExpTableDataSource = DeviceClassesByExpirationDate.soonToExpire;
        }
      }else{
        this.setDevicesToPay= new Set();
        this.allUserDevices ={
          devicesExpired:[],
          devicesSoonToExpire:[]
        };
        this.devicesLoaded=[];
        this.devicesLoadedInTableSorted=[]; 
      }
      this.utils.hideLoading(() => this.loading = false);
    }).catch((error)=>{console.log(error);});
  }
  sortData(sort: Sort,source:DeviceClassesByExpirationDate) {
    const data = source==DeviceClassesByExpirationDate.soonToExpire?this.allUserDevices.devicesSoonToExpire.slice():this.allUserDevices.devicesExpired.slice();
    if (!sort.active || sort.direction === '') {
      this.devicesLoadedInTableSorted = data;
      return;
    }
    this.devicesLoadedInTableSorted = data.sort((a:any, b:any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'imei':
          return this.utils.compare(a.imei, b.imei, isAsc);
        case 'name':
          return this.utils.compare(a.name, b.name, isAsc);
        case 'plate_number':
          return this.utils.compare(a.plate_number, b.plate_number, isAsc);
        case 'expiration_date':
          return this.utils.compare(new Date(a.expiration_date).getTime(), new Date(b.expiration_date).getTime(), isAsc);
        default:
          return 0;
      }
    });
  }
  addToCart($event:any,device:any){
    ($event.checked) ? this.setDevicesToPay.add(device) : this.setDevicesToPay.delete(device);
  }
  emptyDevicesToPayList(){
    for(let item of this.devicesLoaded){
      if(typeof item.isDeviceSelected != "undefined" && item.isDeviceSelected){
        item.isDeviceSelected=false;
      }
    }
   this.setDevicesToPay.clear(); 
  }
  openDialogPaymentOptions():void{
    const dialogRef = this.matDialog.open(PaymentOptionsComponent,{data:{"devicesToPay":[...this.setDevicesToPay]}});
    dialogRef.afterClosed().subscribe(paidDevices => {
      if(paidDevices["status"]){
        this.getDevicesByDateRange();
      }
    });
  }
  exportFile(): void{
    let dataToExport:{name:string, data:any[]}[]=[];
    if(this.sourceToExport.expired)
      dataToExport.push({name: DeviceClassesByExpirationDate.expired,data: this.allUserDevices.devicesExpired});
    if(this.sourceToExport.soonToExpire)
      dataToExport.push({name: DeviceClassesByExpirationDate.soonToExpire,data: this.allUserDevices.devicesSoonToExpire});
    if(dataToExport.length==0)
      return this.utils.showMsg("","No hay opciones seleccionadas");
    this.utils.showConfirm("¿Está seguro de exportar los datos?", "Los datos pueden tomar un tiempo en procesarse", "Continuar", "Cancelar").then(() => {
      for(let source of dataToExport){
        this.processExport(source);
      }
    });
  }
  processExport(source:{name:string, data:any[]}):void{
    this.loading = true;
    let fileName=source.name;
      setTimeout(() => {
        this.utils.downloadFileAsCSV(source.data,  fileName + "_" + this.utils.getDateTimeNumericString(), this.headers);
        this.utils.showMsg("","Datos exportados con éxito");
        this.loading = this.sourceToExport.soonToExpire = this.sourceToExport.expired = false;
      }, 2000);
  }
}