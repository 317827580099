<section class="form-box-container animate__animated animate__fadeIn" *ngIf="viewsAvailable['device_checklist_management'].show_view">
    <section class="form-box-container__header">
        <!--<span class="form-box-container__header-back" (click)="back()"><span class="description">Volver</span> </span>-->
        <h2 class="form-box-container__header-title"><span class="icon"><i class="fa-solid fa-list-check"></i></span>Gestión de Checklist</h2>
        <h3 class="form-box-container__header-subtitle">{{!validationService.isNullOrEmpty(currentDrivenVehicle.current_driver_id) ? (!validationService.isNullOrEmpty(currentDrivenVehicle.plate_number) ? currentDrivenVehicle.plate_number : currentDrivenVehicle.name) : "Sin dispositivo" }} <mat-icon matTooltip="Dispositivo actual">help</mat-icon> </h3>
        <div class="form-box-container__header-options">
            <button 
            (click)="utils.setSelectedView(this.viewsAvailable,this.viewsAvailable['device_checklist_answered_history'].name)"
            class="btn btn-primary"  
            matTooltip="Ver Historial de respuestas"> <i class="fa-solid fa-folder-open"></i><span class="description"> Historial</span></button>
            <app-driver-device-selector (currentDeviceHasBeenChanged)="currentDrivenVehicle = $event"></app-driver-device-selector>
        </div>
    </section>
    <div class="form-box two-panel two-panel--automatic-orientation animate__animated animate__fadeIn">
        <div class="form-box__content" *ngIf="checklistList.length > 0">
            <mat-card *ngFor="let checklist of checklistList"  class="card">
                <mat-card-title-group>
                    <mat-card-title>{{checklist.name}}</mat-card-title>
                    <mat-card-subtitle> Checklist</mat-card-subtitle>
                    <img mat-card-sm-image src="../../../../../assets/img/checklist.svg" alt="imagen de checklist" >
                </mat-card-title-group>
                <mat-card-content>
                    {{checklist.description}}
                </mat-card-content>
                <mat-card-actions align="end" class="card__actions">
                    <button 
                    mat-icon-button 
                    mat-button
                    (click)="openChecklist(checklist.id)"
                    matTooltip="Responder checklist"
                    ><mat-icon class="text-primary">edit_document</mat-icon> </button>
                    </mat-card-actions>
            </mat-card>
        </div>
        <div *ngIf="checklistList.length > 0" class="d-none d-md-flex form-box__content form-box__content--vertical-centered ">
            <div class="ilustration" >
                <img src="../../../../assets/img/checklist.svg">
            </div>
        </div>
        <div *ngIf="checklistList.length == 0" class="form-box__content form-box__content--vertical-centered ">
            <div class="form-box__content-empty" >
                <img src="../../../../../assets/img/empty-checklist.svg">
                <span class="form-box__content-empty-label">No hay checklist disponibles. Comunicate con el gestor de la flota</span>
            </div>
        </div>
    </div>
    <div class="loader" *ngIf="loading">
        <img src="../../../../../assets/loader.svg">
    </div>
</section>
<app-device-checklist-answered-history 
[searchByField]="searchHistoryByUserDriver"
[fieldValue]="user.id"
(close)="utils.setSelectedView(this.viewsAvailable,this.viewsAvailable['device_checklist_management'].name)"
*ngIf="viewsAvailable['device_checklist_answered_history'].show_view"></app-device-checklist-answered-history>