<ng-container *ngIf="!loading">
    <div class="border-bottom-secondary d-flex gap-1 justify-content-between ">
      <h2 mat-dialog-title>Detalles</h2>
      <small>Fecha de revisión: {{utils.getDatetime() | date:"longDate"}}</small>
    </div>
    <mat-dialog-content class="mat-typography position-relative ">
        <div class="overflow-auto w-100 animate__animated animate__fadeInUp animate__faster">
            <table class="angular-table" mat-table [dataSource]="deviceList">
              <ng-container matColumnDef="imei">
                <th mat-header-cell *matHeaderCellDef> Imei </th>
                <td mat-cell *matCellDef="let element"> {{element.imei}} </td>
              </ng-container>
              <ng-container matColumnDef="plate_number">
                <th mat-header-cell *matHeaderCellDef> Placa </th>
                <td mat-cell *matCellDef="let element"> {{element.plate_number}} </td>
              </ng-container>
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef> Nombre </th>
                <td mat-cell *matCellDef="let element"> {{element.name}} </td>
            </ng-container>
            <ng-container matColumnDef="created_at">
                <th mat-header-cell *matHeaderCellDef matTooltip="Fecha en la cual se registró por primera vez el dispositivo"> Fecha de creación</th>
                <td mat-cell *matCellDef="let element"> {{element.created_at|date:"mediumDate"}} </td>
            </ng-container>
            <ng-container matColumnDef="expiration_date">
              <th mat-header-cell *matHeaderCellDef> Fecha de expiración</th>
              <td mat-cell *matCellDef="let element"> {{element.expiration_date|date:"mediumDate"}} </td>
            </ng-container>
            <ng-container matColumnDef="activation_date">
                <th mat-header-cell *matHeaderCellDef> Fecha de vinculación</th>
                <td mat-cell *matCellDef="let element"> {{element.activation_date|date:"mediumDate"}} </td>
            </ng-container>
            <ng-container matColumnDef="observation">
              <th mat-header-cell *matHeaderCellDef> </th>
              <td mat-cell *matCellDef="let element"> <div class="d-flex justify-content-center align-items-center"><span [ngClass]="{'success': element.observation==this.dataService.deviceActivationStates.new.name,'warning':element.observation==this.dataService.deviceActivationStates.renewed.name,'danger':element.observation==this.dataService.deviceActivationStates.expired.name}">{{dataService.deviceActivationStates[element.observation].label}}</span></div> </td>
            </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumnsForAInvoice"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumnsForAInvoice;">
                </tr>
            </table>   
          </div>
          <div class="loader" *ngIf="loadingExport">
            <img src="../../../assets/loader.svg">
        </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button mat-button [mat-dialog-close]="true"class="btn btn-outline-secondary" style="margin-right: 0.2rem;">Salir</button>
      <button mat-button (click)="exportFile()" class="btn btn-outline-primary" cdkFocusInitial>Exportar</button>
    </mat-dialog-actions>
</ng-container>

