<section class="form-box-container animate__animated animate__fadeIn">
    <section class="form-box-container__header">
        <span class="form-box-container__header-back" (click)="closeComponent()"><span class="description">Volver</span> </span>
        <h2 class="form-box-container__header-title"><span class="icon"><i class="fa-solid fa-list-check"></i></span>Gestión de Viajes</h2>
        <div class="form-box-container__header-options">
            <button [matMenuTriggerFor]="tripForm" [disabled]="lastTripInProcess.id != 0"  (click)="tripSelected = objectInitializationService.initializeITrip(currentDrivenVehicle.id,user.id,currentDrivenVehicle.name,currentDrivenVehicle.plate_number??'')" [matTooltip]="lastTripInProcess.id == 0 ? 'Establecer nuevo viaje': 'El dispositivo todavía tiene un viaje en proceso'" class="btn btn-primary"><i
                class="fa-solid fa-plus"></i><span class="description"> Nuevo</span></button>
        </div>
        
    </section>
    <div class="form-box animate__animated animate__fadeIn">
        <div class="form-box__content form-box__content-h-auto">
            <div class="form-box__content-inputs mt-2" *ngIf="driverTripList.length>0">
                <div class="form-box__content-group-fields">
                        <mat-card *ngFor="let driverTrip of driverTripList"  class="card">
                            <mat-card-title-group>
                                <mat-card-title >
                                    <div class="d-flex justify-content-start align-items-center g-1">
                                        <div class="status text-success" *ngIf="!validationService.isNullOrEmpty(driverTrip.end_datetime)" matTooltip="Completado"><i class="fa-solid fa-circle-check"></i></div>
                                        <div class="status text-warning" *ngIf="validationService.isNullOrEmpty(driverTrip.end_datetime)" matTooltip="En proceso"><i class="fa-solid fa-circle-exclamation"></i></div>
                                        <span>{{driverTrip.title}}</span>
                                    </div>
                                </mat-card-title>
                                <mat-card-subtitle> {{(validationService.isNullOrEmpty(driverTrip.updated_at)?("Registrado el "):("Actualizado el ")) + (validationService.isNullOrEmpty(driverTrip.updated_at)?(driverTrip.created_at):driverTrip.updated_at | date:"d/M/Y, h:mm a")}}</mat-card-subtitle>
                                <img mat-card-sm-image src="../../../../../assets/img/map-isometric.svg" alt="imagen de mapa" >
                            </mat-card-title-group>
                            <mat-card-content>
                                {{driverTrip.description}}
                            </mat-card-content>
                            <mat-card-actions align="end" class="card__actions">
                                <button mat-icon-button mat-button class="text-primary" (click)="deleteTrip(driverTrip)" matTooltip="Eliminar viaje"><mat-icon class="text-danger">delete</mat-icon></button>
                                <button mat-icon-button mat-button [matMenuTriggerFor]="tripForm" (click)="openTripEditor(driverTrip)" matTooltip="Editar datos"><mat-icon class="text-primary">edit_document</mat-icon> </button>
                            </mat-card-actions>
                        </mat-card>
                </div>
            </div>
            <div class="form-box__content-empty" *ngIf="driverTripList.length==0">
                <img src="../../../../../assets/img/search-engine.svg">
                <span class="form-box__empty-label">Sin datos</span>
            </div>
        </div>
    </div>
    <div class="loader" *ngIf="loading">
        <img src="../../../../../assets/loader.svg">
    </div>
</section>


<!-- Form para la actualizacion o creacion de viajes-->
<mat-menu #tripForm="matMenu" class="detail-popover">
    <span class="detail-popover__btn-close"></span>
    <span class="detail-popover__title">
        <h2 class="detail-popover__title-value">{{tripSelected.id != 0 ?tripSelected.title:"Registro de viaje"}}</h2>
        <small class="detail-popover__title-label" *ngIf="this.tripSelected.id != 0">{{"Actualización de viaje"}}</small>
    </span>
    <span mat-menu-item [disableRipple]="true" (click)="$event.stopPropagation()" class="detail-popover-content">
        <form class="detail-popover-content__form">

            <mat-form-field appearance="fill" hintLabel="Max {{MaxTripFieldLength.title }} carácteres">
                <mat-label>Título</mat-label>
                <input required="" (keydown)="$event.stopPropagation()" tabindex="0" matInput maxlength="{{MaxTripFieldLength.title}}" placeholder="Título para el viaje" name="title" [(ngModel)]="tripSelected.title">
                <mat-hint align="end">{{tripSelected.title?.length || 0}}/{{MaxTripFieldLength.title }}</mat-hint>
                <mat-error>Campo requerido</mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill" hintLabel="Max {{MaxTripFieldLength.description}} carácteres">
                <mat-label>Descripción</mat-label>
                <textarea  matInput maxlength="{{MaxTripFieldLength.description}}" name="description" [(ngModel)]="tripSelected.description" class="textarea" placeholder="Añade una breve descripción del viaje (opcional)"></textarea>
                <mat-hint align="end">{{tripSelected.description?.length || 0}}/{{MaxTripFieldLength.description}}</mat-hint>
            </mat-form-field>
            <mat-form-field id="device_id" appearance="fill">
                <mat-label>{{ deviceTripSelectedLoading ? 'Cargando dato de dispositivo' :'Dispositivo'}}</mat-label>
                <mat-select disabled="" name="device_id" [(ngModel)]="tripSelected.device_id">
                <mat-option  [value]="tripSelected.device_id"  color="primary">{{(!validationService.isNullOrEmpty(tripSelected.device?.name??'') ? tripSelected.device?.name : '' ) + (!validationService.isNullOrEmpty(tripSelected.device?.plate_number) ? (" ("+tripSelected.device?.plate_number+")") : "")}}</mat-option>                      
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="fill">
                <input required="" [min]="utils.getCurrentDate(false)" matInput name="start_datetime" [(ngModel)]="tripSelected.start_datetime" [disabled]="true" [ngxMatDatetimePicker]="picker" placeholder="Seleccione fecha de inicio" >
                <mat-datepicker-toggle matSuffix [for]="$any(picker)"></mat-datepicker-toggle>
                <ngx-mat-datetime-picker [disabled]="false" #picker [showSpinners]="true" [showSeconds]="false"
                                        [stepHour]="1" [stepMinute]="1" [stepSecond]="1"
                                        [touchUi]="false" [enableMeridian]="true"
                                        [disableMinute]="false" [hideTime]="false">
                </ngx-mat-datetime-picker>
                <mat-error>Campo requerido</mat-error>
            </mat-form-field>
            <div class="detail-popover-content__form-inputs-group">
                <mat-form-field appearance="fill">
                    <mat-label>Dirección de inicio</mat-label>
                    <input required="" matInput name="start_address" [(ngModel)]="tripSelected.start_address">
                    <mat-error>Debe de indicar una dirección</mat-error>
                </mat-form-field>
                <mat-form-field appearance="fill">
                    <mat-label>Dirección de destino</mat-label>
                    <input required="" matInput name="end_address" [(ngModel)]="tripSelected.end_address">
                    <mat-error>Debe de indicar una dirección</mat-error>
                </mat-form-field>
            </div>
            
            <div class="detail-popover-actions">
                <button class="btn btn-primary"  [disabled]="loading || updateTripLoading" (click)="updateTrip()" mat-raised-button>{{this.tripSelected.id != 0?loading?"Actualizando...":"Actualizar":loading?"Registrando...":"Registrar"}}</button>            
            </div>

            <div class="loader" *ngIf="updateTripLoading">
                <img src="../../../../../assets/loader.svg">
            </div>
        </form>
    </span>
</mat-menu>