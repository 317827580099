import { Component, EventEmitter, Input, OnInit, Output, Renderer2 } from '@angular/core';
import { IInvoice, IInvoiceDetail } from '../../models/interfaces/iinvoice.model';
import { PageEvent } from '@angular/material/paginator';
import { ObjectInitializationService } from 'src/app/core/object-initialization/object-initialization.service';
import { ApiService } from 'src/app/core/api/api.service';
import { UtilsService } from 'src/app/core/utils/utils.service';
import { ValidationService } from 'src/app/core/validation/validation.service';
import { DataService } from 'src/app/core/data/data.service';
import { Sort } from '@angular/material/sort';
import { IApiRequestData, IPagination, ISort } from '../../models/interfaces/iapi-request-data.model';
import { IColumnStructureToDisplayInTable } from '../../models/interfaces/iutil.model';
import { AlignDataTable, DataStatesTypes, InvoiceObservation, PipeTypes } from '../../enums/common-enums.enum';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { IUserSessionData } from '../../models/interfaces/iuser-session-data.model';
import { CommonConstant, IPaymentOptionItem, PaymentOptionKey } from 'src/app/core/constant/common-constant';
import { MatDatepicker } from '@angular/material/datepicker';
import { InvoiceCreationComponent } from 'src/app/views/my-portfolio/dialogs/invoice-creation/invoice-creation.component';
import { MatDialog } from '@angular/material/dialog';
import { FormControl, FormGroup } from '@angular/forms';
import { InvoiceRecordComponent } from 'src/app/views/my-portfolio/dialogs/invoice-record/invoice-record.component';
import { IResponseApi } from '../../models/interfaces/iresponse-api.model';
import Swal from 'sweetalert2';
import { FolderRoute } from 'src/app/core/constant/FolderRouter';
import { IPaymentAgreement } from '../../models/interfaces/ipayment.model';
import { PaymentAgreementComponent } from 'src/app/views/my-portfolio/dialogs/payment-agreement/payment-agreement.component';
import { InvoiceNotesComponent } from 'src/app/views/my-portfolio/dialogs/invoice-notes/invoice-notes.component';
import { INVOICE_GENERATION_MODE } from '../../enums/invoice.enum';

interface IGenerationModeDetailItems{
  abbreviation: string,
  description: string
}
type GenerationModeDetails = {
  [key in INVOICE_GENERATION_MODE]: IGenerationModeDetailItems
};
interface IPaymentItemSummary {
  monetary_unit_code: string,
  total: {
    outstanding_balance_overall: number,
    amount_paid_overall: number,
    price_overall: number,
  }
}
interface IPaymentSummary {
  [prop_name: string]: IPaymentItemSummary
}
interface IBillingListResponseApi extends IResponseApi {
  payment_summary: IPaymentSummary
}
type DetailsTableColumnName = Pick<IInvoiceDetail, "id" | "observation" | "device_name" | "payment_medium_name" | "device_imei" | "vehicle_type_name" | "amount_paid" | "outstanding_balance" | "price"> & { "action": boolean };
type TableColumnName = Pick<IInvoice, "id" | "client_name" | "reference_paypal" | "transaction_price" | "reference_refacil" | "observation" | "payment_date" | "payment_medium_name" | "created_at" | "days_past_due" | "expiration_date" | "total_amount_paid" | "total_outstanding_balance" | "total_price" | "payment_agreement_last_date" | "payment_agreement_last_comment"> & { "action": boolean };

interface ITargetItemforSummary {
  total: number,
  monetary_unit_code: string,
  icon?: string,
  title?: string,
  class_css: string,
  class_css_animate_delay: string
}
interface ITargetItemforSummaryTemplate {
  price: ITargetItemforSummary,
  amount_paid: ITargetItemforSummary,
  outstanding_balance: ITargetItemforSummary
}
@Component({
  selector: 'app-device-payment-records',
  templateUrl: './device-payment-records.component.html',
  styleUrls: ['./device-payment-records.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class DevicePaymentRecordsComponent implements OnInit {

  @Output() close = new EventEmitter<boolean>();

  loading: boolean = false;
  user: IUserSessionData;

  INVOICE_GENERATION_MODE: typeof INVOICE_GENERATION_MODE = INVOICE_GENERATION_MODE;

  generationModeDetails: GenerationModeDetails = {
    [INVOICE_GENERATION_MODE.MANUAL] : {abbreviation: "M", description: "Generada por el usuario"},
    [INVOICE_GENERATION_MODE.ADVANCE] : {abbreviation: "AD", description: "Generada de forma anticipada para un pago futuro o a solicitud del vendedor"},
    [INVOICE_GENERATION_MODE.AUTOMATIC] : {abbreviation: "A", description: "Generada automáticamente por el sistema"},
    [INVOICE_GENERATION_MODE.ONLINE_PAYMENT] : {abbreviation: "OP", description: "Pago online"}
  };

  paymentPrintingFilePath = this.api.apiRouteSGC + "fileGenerator/paymentPrinting";
  dataStatesTypes: typeof DataStatesTypes = DataStatesTypes;
  pipeTypes: typeof PipeTypes = PipeTypes;
  
  @Input() enableItems: { get_only_my_payments: boolean, button_back: boolean, payment_systems_filter: boolean, date_range_filter: boolean, generate_invoice: boolean, actions_on_details: boolean } = { get_only_my_payments: true, button_back: true, payment_systems_filter: true, date_range_filter: false, generate_invoice: false, actions_on_details: false };
  @Input() titleComponent: string = "Registro de pagos";
  
  currentDateForDatepicker: Date = new Date();
  hasClientNameColumn: boolean = false;

  /**
   * @type {string} Indica la fecha a filtrar como un string 
  */
  dateRangeToConsult!: FormGroup;
  /** @type {IPaymentOptionItem} Las ociones de pagos disponibles en la plataforma */
  readonly PAYMENT_OPTIONS: IPaymentOptionItem[] = Object.values(CommonConstant.PAYMENT_OPTIONS);
  /** @type {PaymentOptionKey[]} Indica los filtros de referencia de pago aplicar en consulta de datos */
  paymentOptionsSelected: IPaymentOptionItem[] = [CommonConstant.PAYMENT_OPTIONS.PAYPAL, CommonConstant.PAYMENT_OPTIONS.REFACIL];

  /** @type {InvoiceObservation[]} Para los filtros de observaciones realizadas en la consulta de datos*/
  readonly INVOICE_STATUS: InvoiceObservation[] = this.dataService.INVOICE_OBSERVATION;
  /** @type {InvoiceObservation[]} Indica los filtros de estadoa aplicar en consulta de datos */
  invoiceObservationSelected: InvoiceObservation[] = [InvoiceObservation.PENDING];
  /** @type {InvoiceObservation} Indica los tipos de observacion presentes en facturas */
  invoiceObservation: typeof InvoiceObservation = InvoiceObservation;

  alignDataTable: typeof AlignDataTable = AlignDataTable;
  /** @type {NodeJS.Timeout} Indica el id del timer usado para enviar solicitud de busqueda de un vaor determinado en los datos de la tabla */
  timerIdSearchValueInTable!: NodeJS.Timeout;
  /** @type {string} Almacena el valor a buscar en la tabla */
  searchValue: string = "";

  tableColumnInformation: Record<keyof TableColumnName, IColumnStructureToDisplayInTable> = {
    id: this.objectInitializationService.initializeIColumnStructureToDisplayInTable("id", "ID", undefined, undefined, undefined, true, AlignDataTable.LEFT, AlignDataTable.LEFT, false, true),
    observation: this.objectInitializationService.initializeIColumnStructureToDisplayInTable("observation", "Estado", undefined, undefined, undefined, true, AlignDataTable.LEFT, AlignDataTable.LEFT, false, true),
    client_name: this.objectInitializationService.initializeIColumnStructureToDisplayInTable("client_name", "Cliente", undefined, undefined, undefined, true, AlignDataTable.LEFT, AlignDataTable.LEFT, false, false),
    payment_date: this.objectInitializationService.initializeIColumnStructureToDisplayInTable("payment_date", "Fecha de pago", undefined, undefined, undefined, true, AlignDataTable.LEFT, AlignDataTable.LEFT, false, true),
    created_at: this.objectInitializationService.initializeIColumnStructureToDisplayInTable("created_at", "Fecha de generación", undefined, undefined, undefined, true, AlignDataTable.LEFT, AlignDataTable.LEFT, false, true),
    expiration_date: this.objectInitializationService.initializeIColumnStructureToDisplayInTable("expiration_date", "Vencimiento", PipeTypes.DATE, undefined, undefined, true, AlignDataTable.LEFT, AlignDataTable.LEFT, false, true, undefined, "yyyy-MM-dd"),
    days_past_due: this.objectInitializationService.initializeIColumnStructureToDisplayInTable("days_past_due", "Días de mora", undefined, undefined, undefined, false, AlignDataTable.LEFT, AlignDataTable.RIGHT, false, true, undefined),
    payment_agreement_last_date: this.objectInitializationService.initializeIColumnStructureToDisplayInTable("payment_agreement_last_date", "Compromiso de pago ", PipeTypes.DATE, "id", DataStatesTypes.INFO, true, AlignDataTable.LEFT, AlignDataTable.LEFT, false, false, "Ver acuerdos de pago de factura # ", "yyyy-MM-dd", (invoice: IInvoice,seeOnlyHistorical:boolean = true)=>{this.openPaymentAgreementDialog(invoice,seeOnlyHistorical)},"Última fecha de acuerdo de pago acordado"),
    payment_medium_name: this.objectInitializationService.initializeIColumnStructureToDisplayInTable("payment_medium_name", "Medio de pago", undefined, undefined, undefined, true, AlignDataTable.LEFT, AlignDataTable.LEFT, false, false),
    reference_paypal: this.objectInitializationService.initializeIColumnStructureToDisplayInTable("reference_paypal", "Referencia PayPal", undefined, undefined, undefined, true, AlignDataTable.LEFT, AlignDataTable.LEFT, false, false),
    reference_refacil: this.objectInitializationService.initializeIColumnStructureToDisplayInTable("reference_refacil", "Referencia Refacil", undefined, undefined, undefined, true, AlignDataTable.LEFT, AlignDataTable.LEFT, false, false),
    total_amount_paid: this.objectInitializationService.initializeIColumnStructureToDisplayInTable("total_amount_paid", "Abono", PipeTypes.CURRENCY, undefined, undefined, false, AlignDataTable.CENTER, AlignDataTable.RIGHT, false, false),
    total_outstanding_balance: this.objectInitializationService.initializeIColumnStructureToDisplayInTable("total_outstanding_balance", "Saldo pendiente", PipeTypes.CURRENCY, undefined, undefined, false, AlignDataTable.CENTER, AlignDataTable.RIGHT, false, false),
    total_price: this.objectInitializationService.initializeIColumnStructureToDisplayInTable("total_price", "Total", PipeTypes.CURRENCY, undefined, undefined, false, AlignDataTable.CENTER, AlignDataTable.RIGHT, false, true),
    payment_agreement_last_comment: this.objectInitializationService.initializeIColumnStructureToDisplayInTable("payment_agreement_last_comment", "Observación", undefined, undefined, undefined, false, AlignDataTable.LEFT, AlignDataTable.LEFT, false, false,undefined,undefined,undefined, "Observación de último acuerdo de pago"),
    transaction_price: this.objectInitializationService.initializeIColumnStructureToDisplayInTable("transaction_price", "Comisión", PipeTypes.CURRENCY, undefined, undefined, false, AlignDataTable.CENTER, AlignDataTable.RIGHT, false, false),
    action: this.objectInitializationService.initializeIColumnStructureToDisplayInTable("action", "Acciones", undefined, undefined, undefined, false, AlignDataTable.CENTER, AlignDataTable.CENTER, true, true)
  };
  /** Indica las columnas de tipo moneda mostradas en la tabla principal */
  paymentBalanceColumns: string[] = [];
  displayedColumns: string[] = [];
  columnStructureToDisplay: IColumnStructureToDisplayInTable[] = Object.values(this.tableColumnInformation);

  detailsTableColumnInformation: Record<keyof DetailsTableColumnName, IColumnStructureToDisplayInTable> = {
    id: this.objectInitializationService.initializeIColumnStructureToDisplayInTable("id", "ID", undefined, undefined, undefined, false, AlignDataTable.LEFT, AlignDataTable.LEFT, false, true),
    observation: this.objectInitializationService.initializeIColumnStructureToDisplayInTable("observation", "Observación", undefined, undefined, undefined, false, AlignDataTable.LEFT, AlignDataTable.LEFT, false, false),
    device_name: this.objectInitializationService.initializeIColumnStructureToDisplayInTable("device_name", "Nombre", undefined, "device_plate_number", DataStatesTypes.INFO, false, AlignDataTable.LEFT, AlignDataTable.LEFT, false, true, "Número de placa: "),
    device_imei: this.objectInitializationService.initializeIColumnStructureToDisplayInTable("device_imei", "Imei", undefined, undefined, undefined, false, AlignDataTable.LEFT, AlignDataTable.LEFT, false, true),
    vehicle_type_name: this.objectInitializationService.initializeIColumnStructureToDisplayInTable("vehicle_type_name", "Tipo de vehículo", undefined, undefined, undefined, false, AlignDataTable.LEFT, AlignDataTable.LEFT, false, true),
    payment_medium_name: this.objectInitializationService.initializeIColumnStructureToDisplayInTable("payment_medium_name", "Medio de pago", undefined, undefined, undefined, false, AlignDataTable.LEFT, AlignDataTable.LEFT, false, false),
    amount_paid: this.objectInitializationService.initializeIColumnStructureToDisplayInTable("amount_paid", "Abonado", PipeTypes.CURRENCY, undefined, undefined, false, AlignDataTable.LEFT, AlignDataTable.RIGHT, false, false),
    outstanding_balance: this.objectInitializationService.initializeIColumnStructureToDisplayInTable("outstanding_balance", "Saldo pendiente", PipeTypes.CURRENCY, undefined, undefined, false, AlignDataTable.LEFT, AlignDataTable.RIGHT, false, false),
    price: this.objectInitializationService.initializeIColumnStructureToDisplayInTable("price", "Precio", PipeTypes.CURRENCY, undefined, undefined, false, AlignDataTable.CENTER, AlignDataTable.RIGHT, false, true),
    action: this.objectInitializationService.initializeIColumnStructureToDisplayInTable("action", "Acciones", undefined, undefined, undefined, false, AlignDataTable.CENTER, AlignDataTable.CENTER, true, false)
  };
  /** Indica las columnas, de la tabla detalles, de tipo moneda mostradas en la tabla principal */
  paymentBalancedetailColumns: string[] = [];
  /** @type {string[]} Indica los nombres de columnas a mostrar en el detalle de la tabla */
  displayedDetailsColumns: string[] = [];
  detailsColumnStructureToDisplay: IColumnStructureToDisplayInTable[] = Object.values(this.detailsTableColumnInformation);
  sort: ISort;
  pagination:IPagination;

  targetPaymentSummaryItemsTemplate: ITargetItemforSummaryTemplate;
  paymentSummaryItemsGroup: ITargetItemforSummary[][] = [];
  /**
   * @type {IInvoice[]} Lista de registro de facturacion
   */
  billingList: IInvoice[] = [];
  constructor(
    public dataService: DataService,
    public validationService: ValidationService,
    public utils: UtilsService,
    private api: ApiService,
    private objectInitializationService: ObjectInitializationService,
    public matDialog: MatDialog,
    private renderer: Renderer2
  ) {
    this.user = this.dataService.getData("user") as IUserSessionData;
    if (this.user.role_id == this.dataService.USER_ROLES.ADMIN || !this.enableItems.get_only_my_payments) {
      this.tableColumnInformation.client_name.visible = true;
      this.hasClientNameColumn = true;
      this.invoiceObservationSelected.unshift(InvoiceObservation.PAID);
    }
    this.sort = this.objectInitializationService.initializeISort();

    this.pagination = this.objectInitializationService.initializeIPagination(this.dataService.PAGE_SIZE_OPTION.slice());
    this.pagination.page_size = this.dataService.PAGE_SIZE_OPTION[0];

    const date: Date = new Date();
    this.dateRangeToConsult = new FormGroup({
      start: new FormControl(new Date(date.getFullYear(), date.getMonth(), 1)),
      end: new FormControl(new Date(date.getFullYear(), (date.getMonth() + 1), 0)),
    });
    this.targetPaymentSummaryItemsTemplate = {
      price: { total: 0, monetary_unit_code: "", icon: "fa-solid fa-money-check-dollar", title: "Total", class_css: "count-panel-item__info", class_css_animate_delay: "custom-animate__delay-200ms" },
      outstanding_balance: { total: 0, monetary_unit_code: "", icon: "fa-solid fa-scale-unbalanced-flip", title: "Pendiente", class_css: "count-panel-item__danger", class_css_animate_delay: "custom-animate__delay-400ms" },
      amount_paid: { total: 0, monetary_unit_code: "", icon: "fa-solid fa-money-bill-trend-up", title: "Pagado", class_css: "count-panel-item__success", class_css_animate_delay: "custom-animate__delay-600ms" },
    };
  }
  ngOnInit(): void {
    if (this.user.role_id == this.dataService.USER_ROLES.MANAGER && !this.enableItems.get_only_my_payments) {
      this.detailsTableColumnInformation.observation.visible = this.tableColumnInformation.client_name.visible = true;
      this.hasClientNameColumn = true;
      this.detailsTableColumnInformation.payment_medium_name.visible = false;
      this.tableColumnInformation.payment_medium_name.visible = true;
    }
    if (!!this.enableItems.payment_systems_filter){
      this.tableColumnInformation.days_past_due.visible = this.tableColumnInformation.expiration_date.visible = false;
      this.tableColumnInformation.transaction_price.visible = this.tableColumnInformation.reference_refacil.visible = this.tableColumnInformation.reference_paypal.visible = true;
    }
    else {
      this.tableColumnInformation.payment_agreement_last_date.visible = this.tableColumnInformation.payment_agreement_last_comment.visible = true;
      this.tableColumnInformation.total_outstanding_balance.visible = this.tableColumnInformation.total_amount_paid.visible = true;
      this.detailsTableColumnInformation.outstanding_balance.visible = this.detailsTableColumnInformation.amount_paid.visible = false;
    }
    if (this.enableItems.actions_on_details) {
      this.detailsTableColumnInformation.action.visible = true;
      this.detailsTableColumnInformation.price.align_header = this.alignDataTable.CENTER;
    }
    this.detailsColumnStructureToDisplay.forEach((item: IColumnStructureToDisplayInTable) => typeof item.visible != "undefined" && item.visible ? this.displayedDetailsColumns.push(item.name) : null);
    this.columnStructureToDisplay.forEach((item: IColumnStructureToDisplayInTable) => typeof item.visible != "undefined" && item.visible ? this.displayedColumns.push(item.name) : null);

    this.paymentBalancedetailColumns.push(this.detailsTableColumnInformation.amount_paid.name, this.detailsTableColumnInformation.price.name, this.detailsTableColumnInformation.outstanding_balance.name);
    this.paymentBalanceColumns.push(this.tableColumnInformation.total_amount_paid.name, this.tableColumnInformation.total_outstanding_balance.name, this.tableColumnInformation.total_price.name);
    this.getBillingList();
  }
  searchValueInTable() {
    clearTimeout(this.timerIdSearchValueInTable);
    this.timerIdSearchValueInTable = setTimeout(() => {
      this.pagination.current_page = 0;
      this.getBillingList();
    }, this.dataService.DATA_TABLE_SEARCH_TIMEOUT);
  }
  getGenerationModeDetails(mode: INVOICE_GENERATION_MODE): IGenerationModeDetailItems{
    let item: IGenerationModeDetailItems = {abbreviation:"", description: ""};
    return this.generationModeDetails[mode]??item;
  }
  /**
   * 
   * @param date La fecha seleccionada
   * @param datepicker El elemento Datepicker, que se usará para cerrar despes de haber seleccionado un mes
   */
  filterByMonthAndYear(date: Date, datepicker: MatDatepicker<any>) {
    this.dateRangeToConsult.get("start")?.setValue(date);
    this.dateRangeToConsult.get("end")?.setValue(date);
    datepicker.close();
    this.searchValueInTable();
  }
  isIBillingListResponseApi(obj: any): obj is IBillingListResponseApi {
    return typeof obj.status != "undefined" && typeof obj.data != "undefined";
  }
  /**
   * Obtiene los dtos de criterios de busqueda aplicados para la obtencion de registros de facturacion
  * @param {boolean} addPagination Indica si en la solcitud se va a usar paginacion para la recuperacion de datos 
  */
  getBillingDataQueryCriteria(addPagination: boolean = true): IApiRequestData {
    let endDate: Date = this.dateRangeToConsult.value.end == null || this.dateRangeToConsult.value.end == false || !this.enableItems.date_range_filter ? this.dateRangeToConsult.value.start : this.dateRangeToConsult.value.end;
    if (!this.enableItems.date_range_filter)
      endDate = new Date(endDate.getFullYear(), endDate.getMonth() + 1, 0, 23, 59, 59);
    let data: IApiRequestData & { start_date?: string, end_date?: string, payment_options_filter?: PaymentOptionKey[], restriction_observation?: InvoiceObservation[], get_only_my_payments?: boolean } =
    {
      user_api_hash: this.user.hash,
      action: "get",
      start_date: this.utils.getDatetime(this.dateRangeToConsult.value.start).split(" ")[0] + " " + this.utils.getDatetime(this.dateRangeToConsult.value.start).split(" ")[1],
      end_date: this.utils.getDatetime(new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), 23, 59, 59)),
      get_only_my_payments: this.enableItems.get_only_my_payments,
      page: addPagination ? (this.pagination.apply_pagination ? this.pagination.current_page : undefined) : undefined,
      page_size: addPagination ? (this.pagination.apply_pagination ?  this.pagination.page_size : undefined) : undefined,
      search_value: this.validationService.isNullOrEmpty(this.searchValue) ? null : this.searchValue,
      order: { by: this.sort.by, direction: this.sort.direction }
    };
    if (this.paymentOptionsSelected.length > 0)
      data.payment_options_filter = this.paymentOptionsSelected.map((item: IPaymentOptionItem) => item.VALUE);
    if (this.invoiceObservationSelected.length > 0)
      data.restriction_observation = this.invoiceObservationSelected;

    return data;
  }
  async getBillingList() {
    this.loading = true;
    let resultData: IInvoice[] = [];
    let resultTotal: number = 0;
    try {
      let data = this.getBillingDataQueryCriteria();
      this.paymentSummaryItemsGroup = [];
      let response: unknown = await this.api.getDataPost("invoice", data, true);
      if (this.isIBillingListResponseApi(response)) {
        resultData = response.status == 1 ? response.data as IInvoice[] : [];
        resultTotal = response.status == 1 ? Number(response.total ?? 0) : 0;
        if (response.status == 1) {
          for (let value of Object.values(response.payment_summary ?? [])) {
            let templateCopy: ITargetItemforSummaryTemplate = this.utils.copyObject(this.targetPaymentSummaryItemsTemplate);
            templateCopy.price.total = value.total.price_overall;
            templateCopy.amount_paid.total = value.total.amount_paid_overall;
            templateCopy.outstanding_balance.total = value.total.outstanding_balance_overall;
            templateCopy.amount_paid.monetary_unit_code = templateCopy.price.monetary_unit_code = templateCopy.outstanding_balance.monetary_unit_code = value.monetary_unit_code;
            this.paymentSummaryItemsGroup.push(Object.values(templateCopy) as ITargetItemforSummary[]);
          }
        }
      }
    } catch (error:unknown) {
      this.utils.showResultRequest("error", "Información", this.api.getDefaultMessage("registro de pagos", false, false, "GET"));
    }
    finally {
      this.pagination.length_page = resultTotal;
      this.billingList = resultData;
      if(resultTotal > 0){
        this.pagination.page_size_options = this.dataService.PAGE_SIZE_OPTION.slice();
        if(this.pagination.page_size_options[this.pagination.page_size_options.length -1 ] < resultTotal)
          this.pagination.page_size_options.push(resultTotal);
        if(!this.pagination.page_size_options.includes(this.pagination.page_size))
          this.pagination.page_size =  resultTotal;
      }
      this.utils.hideLoading(() => this.loading = false);
    }
  }
  sortData(sort: Sort) {
    this.pagination.current_page = 0;
    this.sort.direction = sort.direction;
    this.sort.by = sort.active;
    this.getBillingList();
  }
  paginator(event: PageEvent) {
    this.pagination.page_size = event.pageSize;
    this.pagination.current_page = event.pageIndex;
    this.pagination.apply_pagination = this.pagination.page_size <= this.dataService.PAGE_SIZE_OPTION[this.dataService.PAGE_SIZE_OPTION.length -1];

    this.getBillingList();
  }
  exportFile(): void {
    this.utils.processFileDownload(this.user.name?.replace(" ", "_") + "_payment", async () => {

      let data: Partial<IInvoice>[] = [];
      let dataToRequest: IApiRequestData = this.getBillingDataQueryCriteria(false);
      try {
        let response: unknown = await this.api.getDataPost("invoice", dataToRequest, true);
        if (this.isIBillingListResponseApi(response)) 
          data = response.status == 1 ? (response.data as Partial<IInvoice>[]) : [];
      } catch (error: unknown) {
        this.loading = false;
        this.utils.showResultRequest("error", "Información", this.api.getDefaultMessage("los registros de facturación", false, false, "GET"));
      }
      let item: Partial<IInvoice> & { device_list?: string };
      let deviceListName: keyof IInvoice = "device_list";
      let billingList: Partial<IInvoice>[] = data;
      let headers: (keyof IInvoice)[] = [];

      let monetaryUnitCodeFieldName: keyof IInvoice = "monetary_unit_code";
      Object.values(this.tableColumnInformation).forEach((item: IColumnStructureToDisplayInTable) => item.visible && this.tableColumnInformation.action.name != item.name ?( this.tableColumnInformation.total_price.name == item.name ? headers.push(item.name as keyof IInvoice,monetaryUnitCodeFieldName) : headers.push(item.name as keyof IInvoice)) : null );
      headers.push(deviceListName);
      if(this.tableColumnInformation.client_name.visible)
        headers.splice(headers.findIndex((item:keyof IInvoice) => item =="client_name" ), 0,  "client_email", "client_phone_number");      
      for (item of billingList) {
        let deviceList: string = "";
        let count: number = 1;
        if (typeof item.details != "undefined") {
          for (let detail of item.details!) {
            deviceList += (count == 1 ? "" : " | ") + count + ". Device (Name: " + detail.device_name + " / Plate Number: " + detail.device_plate_number + " / Vehicle Type Name: " + detail.vehicle_type_name + " / imei: " + detail.device_imei + " / Price: " + detail.price + " " + detail.monetary_unit_code + ")";
            count++;
          }
          item[deviceListName] = deviceList;
        }
      }
      return { headers: headers, data: billingList };
    }, (response: boolean) => this.loading = response);
  }
  getSelectedPaymentOptionLabels() {
    let selected: string[] = [];
    this.paymentOptionsSelected.forEach((item: IPaymentOptionItem) => selected.push(item.LABEL));
    return selected.join(", ");
  }
  openInvoiceGenerator(invoice?: IInvoice, detail?: IInvoiceDetail) {
    const dataForDialog: { action: string, invoice?: IInvoice, detail?: IInvoiceDetail } = { action: typeof invoice != "undefined" ? "createInvoiceList" : "createInvoice", invoice: typeof invoice != "undefined" ? this.utils.copyObject(invoice) : undefined, detail: typeof detail != "undefined" ? detail : undefined };
    const dialogRef = this.matDialog.open(InvoiceCreationComponent, { data: dataForDialog,panelClass:'mat-dialog-width-responsive' });
    dialogRef.afterClosed().subscribe(invoiceCreated => {
      if (invoiceCreated)
        this.getBillingList();
    });
  }
  openPaymentDialog(invoice: IInvoice, detail?: IInvoiceDetail, action: "apply" | "edit" = "apply") {
    let dataForDialog: { action: "apply" | "edit", invoice: IInvoice, selected_invoice_detail?: IInvoiceDetail } = { action: action, invoice: invoice, selected_invoice_detail: detail };
    const dialogRef = this.matDialog.open(InvoiceRecordComponent, { data: dataForDialog });
    dialogRef.afterClosed().subscribe(paidDevices => {
      if (paidDevices)
        this.getBillingList();
    });
  }
  /**
   * 
   * @param {IInvoice} invoice Indica la factura a gestionar
   * @param {boolean} seeOnlyHistorical  Indica si se va a visualizar solo el historico de acuerdos de pagos de la factura
   */
  openPaymentAgreementDialog(invoice: IInvoice, seeOnlyHistorical: boolean  = false) {
    let dataForDialog: { invoice_id: number} = { invoice_id: invoice.id };
    const dialogRef = this.matDialog.open(PaymentAgreementComponent, { data: dataForDialog });
    dialogRef.afterClosed().subscribe((paymentAgreementCreated: boolean) => {
      if (paymentAgreementCreated)
        this.getBillingList();
    });
  }
  openNotesManagementDialog(invoice: IInvoice, seeOnlyHistorical: boolean  = false){
    let dataForDialog: { invoice_id: number} = { invoice_id: invoice.id };
    const dialogRef = this.matDialog.open(InvoiceNotesComponent, { data: dataForDialog });
    dialogRef.afterClosed().subscribe((invoiceNoteCreated: boolean) => {});
  }
  generatePaymentSupport(invoice: any, detail?: any) {
    window.open(this.api.apiRouteSGC + FolderRoute.FILE_GENERATOR_PAYMENT_PRINTING + "?token=" + invoice.token + "$" + this.user.id + (typeof detail != "undefined" ? ("&apply_to=invoiceDetail&detail_id=" + detail.id) : ("&apply_to=invoice")));
  }
  async deletePortfolioRecord(invoice: IInvoice, detail?: IInvoiceDetail, itemToDelete: "invoice" | "detail" = "invoice") {
    this.utils.showConfirm("Confirmar acción", "¿Esta seguro de " + (itemToDelete == "invoice" ? "anular" : "eliminar") + " este " + (itemToDelete == "invoice" ? "registro de facturación? <br><span class='text-small'>Recuerda que todos los detalles de ella también se afectarán</span>" : "detalle de factura? " + (invoice.details!.length < 2 ? "<br><span class='text-small'>Dado que es el único detalle de la factura, esta entrará a estado de anulado</span>" : "")), "Confirmar", "Cancelar").then(async () => {
      this.loading = true;
      let response: IResponseApi | null | unknown = null;
      try {
        if (itemToDelete == "invoice") {
          let data = { user_api_hash: this.user.hash, action: "update", observation: "anulada", id: invoice.id };
          response = await this.api.createData(data, "invoice", true);
        } else {
          let data = "?user_api_hash=" + this.user.hash + "&action=deleteDetail&invoice_id=" + invoice.id + (typeof detail != "undefined" ? ("&detail_id=" + detail.id) : "");
          response = await this.api.deleteData("invoice", data, true);
        }
      } catch (error: any) {
        this.utils.showResultRequest("error", "Información", ("Se presentó problemas al " + (itemToDelete == "invoice" ? "anular la factura" : "eliminar el detalle de la factura")));
      } finally {
        this.utils.hideLoading(() => this.loading = false);
        if (this.validationService.isResponseApi(response)) {
          this.utils.showResultRequest(response.status == 1 ? "success" : "error", "Información", response.status == 1 ? response.message : ("Se presentó problemas al " + (itemToDelete == "invoice" ? "anular la factura" : "eliminar el detalle de la factura")));
          if (response.status == 1)
            this.getBillingList();
        }
      }
    }).catch(() => { });
  }

  //REFACTORIZAR  ENVIOS NOTIFICACION
  notifyToClient(invoice: IInvoice, detail?: IInvoiceDetail) {
    this.loading = true;
    this.api.getData("user", "?action=getContactDetails&user_api_hash=" + this.user.hash + "&user_id=" + invoice.buyer_id, true).then((dataReceived: any) => {
      if (dataReceived.status == 1) {
        let contactDetails = dataReceived.data;
        let notificationOptions: string = "";
        for (let option of this.dataService.notificationOptions) {
          notificationOptions += `
          <div class='input-with-icon'><label class="icon"> ${(option.id == 1 ? '<i class="fa-brands fa-whatsapp icon-whatsapp fa-lg"></i>' : '<i class="fa-solid fa-envelope icon-gmail fa-lg"></i>')}</label><input type='${(option.id == 1 ? 'tel' : 'email')}'  id='${(option.id == 1 ? 'InputPhoneNumber' : 'inputEmail')}' name="notificationOption" value='${option.id == 1 ? ((contactDetails.phone_prefix != null ? contactDetails.phone_prefix : "") + contactDetails.phone_number) : contactDetails.email}'></div>
          ${(option.id == 1 && contactDetails.phone_prefix == null ? '<p class="text-small text-end pb-1">Indica el prefijo de telefono si deseas enviar por dicho medio</p>' : '')}
          `;
        }
        //notificationOptions += "<p class='text-small text-end'>Dejar el campo vacio si no desea enviar por dicho medio</p>";
        Swal.fire({
          title: 'Notificar al cliente',
          input: 'textarea',
          html: notificationOptions,
          inputPlaceholder: 'Mensaje, de máximo 400 carácteres, a enviar al cliente',
          inputAttributes: { maxLength: "400" },
          confirmButtonText: "Enviar",
          showConfirmButton: true,
          showCancelButton: true,
          cancelButtonText: "Cancelar"
        }).then((result) => {
          //let email: string = this.renderer.selectRootElement("#inputEmail", true).value;
          let phoneNumber: number = this.renderer.selectRootElement("#InputPhoneNumber", true).value;
          if (phoneNumber == 0)
            return this.utils.showMsg("Información", "Debe definir un número de teléfono de contacto");
          //if (email != "" && !this.utils.isValidEmail(email))
            //return this.utils.showMsg("Información", "Debe definir un email válido");
          if (phoneNumber != 0 && !this.utils.isOnlyNumbers(phoneNumber))
            return this.utils.showMsg("Información", "El campo teléfono debe de contener sólo números");
          if (result.isConfirmed) {
            if (result.value != "") {
              let dataToSend: any = {
                user_api_hash: this.user.hash,
                action: "notifyToClient",
                seller_id: this.user.id,
                message: result.value,
                buyer: {
                  //email: email,
                  phone_number: phoneNumber
                },
                invoice: {
                  support_path: this.paymentPrintingFilePath + "?token=" + invoice.token + "$" + this.user.id,
                  id: invoice.id,
                  observation: invoice.observation
                }
              };
              this.api.createData(dataToSend, "invoice", true).then((data: any) => {
                this.utils.showResultRequest(data.status == 1 ? "success" : "error", "Información", data.message);
                this.utils.hideLoading(() => this.loading = false)
              }).catch((error: any) => {
                this.utils.showResultRequest("error", "Información", "Ha surgido un error mientras se procesaba los envíos de notificaciones,<br><small>" + this.api.errorWarning + "</small>");
                this.utils.hideLoading(() => this.loading = false)
              });
            } else {
              this.utils.hideLoading(() => this.loading = false);
              this.utils.showResultRequest("info", "Información", "Ingrese un mensaje a enviar");
            }

          } else
            this.utils.hideLoading(() => this.loading = false)
        }).catch((error: any) => { this.utils.hideLoading(() => this.loading = false); });
      }
    }).catch(() => { this.utils.hideLoading(() => this.loading = false); });
  }
  closeComponent() {
    this.close.emit(true);
  }
}