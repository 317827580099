import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/core/api/api.service';
import { DataService } from 'src/app/core/data/data.service';
import { UtilsService } from 'src/app/core/utils/utils.service';
import { PlatformPlanFormComponent } from '../../shared/components/platform-plan-form/platform-plan-form.component';

@Component({
  selector: 'app-admin-plans',
  templateUrl: './admin-plans.component.html',
  styleUrls: ['./admin-plans.component.scss']
})
export class AdminPlansComponent implements OnInit {
  loading:boolean=false;//para mostrar SVG de carga
  user:any={};
  newPlatformPlan: any = {};
  permissionsForSelectedModule:any={//permisos del modulo nuevo a agregar al plan
    id:null,
    platform_plan_id:null,
    module_operation_id:null,
    module_id:null
  };
  isNewPlatformPlanModified:boolean=false;//indica cuando se haya realizado alguna modificacion a los datos del "plan de plataforma" cargado para modificar
  platformPlans:any=[];//alamcena la lista de planes creados
  modalEditPlatformPlan:boolean=false;
  isCreatePlatformPlan:boolean=false;

  moduleId:number=10;
  permissionsDataSgc:any=[];//permisos sobre el modulo
  constructor(
    private dataService:DataService,
    public utils:UtilsService,
    private api:ApiService,
    private router:Router
  ){  }
  ngOnInit(): void {
    this.dataService.checkPermissionModule(this.moduleId).then((permissions: any) => {
      this.permissionsDataSgc=permissions;
      this.user = this.dataService.getData("user");
      this.getList();
    }).catch(() => {
      this.utils.showMsg("Página no autorizada","No tiene permisos para ver esta página, contacte al administrador");
      this.router.navigate(['/']);
    });
  }
  getList(callback?:any){
    this.showLoading(true);
    this.api.getData("platformPlan", "?action=getPlatformPlans&user_api_hash="+this.user.hash,true).then((data: any) => {
      this.platformPlans = data.status==1?data.data:[];
      if(typeof callback !="undefined")
        callback(this.platformPlans);
      this.showLoading(false);
    }).catch((error:any)=>{this.showLoading(false);});
  }
  
  deletePlatformPlan(platformPlan:any){
    this.utils.showConfirm("Confirmar acción","Confirmar eliminación del plan","Confirmar","cancelar").then(()=>{
      let dataToSend={
        user_api_hash:this.user.hash,
        action:"delete",
        id:platformPlan.id
      };
      this.api.createData(dataToSend, "platformPlan",true).then((data: any) => {
        this.utils.showResultRequest(data.status?"success":"error","Información",data.message);
        data.status?this.getList():"";
        this.showLoading(false);
      }).catch((err: any) => {
        this.showLoading(false);
        this.utils.showResultRequest("error","Error de eliminación","Se ha presentado un error mientras se intentaba eliminar el plan<br><small>Intenta nuevamente. Si el error persiste comunicate con el administrador</small>");
      });
    }).catch(()=>{});
  }
  closeModal(event:any){
    this.isCreatePlatformPlan = this.isNewPlatformPlanModified = this.modalEditPlatformPlan=false;
    this.newPlatformPlan = {};
    if(!this.utils.isNullOrEmpty(event.changes_applied) && event.changes_applied && event.platform_plan_id!=-1){
      this.getList((plans:any)=>{
        let plan = plans.find((plan:any)=>event.platform_plan_id==plan.id);
        this.openPlanPermissionsManager(plan);
      });
    }else if(!this.utils.isNullOrEmpty(event.changes_applied) && event.changes_applied )
      this.getList();
  }
  openPlanPermissionsManager(object:any,isCreate:boolean=false){
    this.newPlatformPlan=this.utils.copyObject(object);
    this.modalEditPlatformPlan=true;
    this.isCreatePlatformPlan=isCreate;
  }
  openCreatePermissions(){
    this.openPlanPermissionsManager({},true);
  }
  showLoading(show: boolean = true){
    if(show) this.loading = true;
    else setTimeout(() => { this.loading = false; }, 500);
  }
}