<section class="form-box-container animate__animated animate__fadeIn">

    <div class="form-box transparent-scroll-vertical two-panel two-panel--automatic-orientation animate__animated animate__fadeIn">
        <div class="form-box__content  form-box__content-shadow form-box__content-fill  transparent-scroll-vertical">
            <h2 class="form-box__content-title sticky-top">Historial de actividades</h2>
            <app-device-checklist-answered-history [showTableTitle]="true" (dataRequestCompleted)="dataRequestCompleted($event)" [urlToReturn]="urlHome" [showOnlyTable]="true"></app-device-checklist-answered-history>
        </div>
        <div class="form-box__content mobile-no-padding form-box__content-auto transparent-scroll-vertical order-mobile-first">
            <section class="count-panel-container count-panel-container--automatic-orientation">
                <article class="count-panel-item count-panel-item__primary animate__animated animate__fadeInRight {{item.class_css_animate_delay}}" *ngFor="let item of totalsReport">
                    <span class="count-panel-item-content">
                        <span class="count-panel-item-content__title">{{item.title}} </span>
                        <strong class="count-panel-item-content__quantity">{{item.total}}</strong>
                    </span>
                    <span class="count-panel-item-icon"><i class="{{item.icon}}"></i></span>
                </article>
            </section>
        </div>
    </div>
    <div class="loader" *ngIf="loading">
        <img src="../../../../../assets/loader.svg">
    </div>
</section>