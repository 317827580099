export type PaymentOptionKey = "refacil" | "paypal";

export interface IPaymentOption{
    PAYPAL: IPaymentOptionItem,
    REFACIL: IPaymentOptionItem
}
export interface IPaymentOptionItem{
    VALUE:PaymentOptionKey,
    LABEL: string
}
export class CommonConstant{
    /** Indica las opciones de sistemas de pagos disponibles en la plataforma para la realizacion de pagos de dispositivos*/
    static readonly PAYMENT_OPTIONS:IPaymentOption = {PAYPAL:{VALUE:"paypal",LABEL:"PayPal"}, REFACIL: {VALUE:"refacil",LABEL:"Refacil"}};
    static readonly PAYMENT_OPTIONS_KEY: Record<PaymentOptionKey, PaymentOptionKey> = {refacil:"refacil", paypal:"paypal"}; 
}