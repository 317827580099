import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { ApiService } from 'src/app/core/api/api.service';
import { DataService } from 'src/app/core/data/data.service';
import { UtilsService } from 'src/app/core/utils/utils.service';

@Component({
  selector: 'app-logs-controlt',
  templateUrl: './logs-controlt.component.html',
  styleUrls: ['./logs-controlt.component.scss']
})
export class LogsControltComponent implements OnInit {
  @Output() closeModal = new EventEmitter<boolean>();

  user:any=[];

  searchTimer:any;
  displayedColumns: string[] = ['device_imei', 'device_plate_number', 'alert_name_convention', 'created_at','device_time'];
  /**el valor a buscar dentro de la lista de registros */
  searchValue="";
  //para paginacion
  lengthPage=0;
  pageSize=20;
  pageSizeOptions:any=[20];
  pageEvent:any;
  currentPage=0;
  loading:boolean=false;
  /**indica los datos para el ordenamiento de la tabla */
  sort={
    by:"",
    direction:""
  };

  devices:any = [];
  constructor(
    private dataService:DataService,
    public utils:UtilsService,
    private api:ApiService,
  ){}
  ngOnInit(): void {
    this.user= this.dataService.getData("user");
    this.getLogs();
  }
  getLogs(){
    let data="?action=getLogs&user_api_hash="+this.user.hash+"&page="+(this.currentPage);
    if(this.sort.by!="")
      data +="&sort_by="+this.sort.by+"&sort_direction="+this.sort.direction;
    if(this.searchValue !="")
      data +="&search_value="+this.searchValue;
    console.log(data);
    this.api.getData("controltLog",data,true).then((data: any) => {
      console.log(data);
      this.devices = data.status==1?data.data:[];
      console.log(this.devices);
      this.lengthPage =data.status==1?data.total:0;
    
    this.showLoading(false); 
    }).catch((error:any)=>{
      console.log(error);
      this.devices = [];
      this.lengthPage = 0;
      this.utils.showMsg("","No se han podido obtener la lista de registros");
      this.showLoading(false);
    });
  }
  searchValueInData(){
    clearTimeout(this.searchTimer);
    this.searchTimer = setTimeout(() => {
      this.currentPage=0;
      this.getLogs();
    }, 800);
  }
  sortData(sort: Sort) {
    console.log(sort);
    this.sort.direction=sort.direction;
    this.sort.by=sort.active;
    this.getLogs();
  }
  paginator($event:PageEvent){
    this.pageSize = $event.pageSize;
    this.currentPage = $event.pageIndex;    
    this.getLogs();
  }
  close() {
    this.closeModal.emit(true);
  }
  showLoading(show: boolean = true):void{
    if(show) this.loading = true;
    else setTimeout(() => { this.loading = false; }, 500);
  }
}