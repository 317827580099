<section class="form-box-container animate__animated animate__fadeIn">

    <section class="form-box-container__header">
        <h2 class="form-box-container__header-title"><span class="icon"><i class="fa-solid fa-sitemap"></i></span>Personalización de sitio web</h2>
        <div  class="form-box-container__header-options">                     
            <button class="btn btn-primary" [matMenuTriggerFor]="options"><i class="fa-solid fa-paint-roller"></i><span  class="description"> Opciones</span></button>
            <mat-menu #options="matMenu" class="actions">
                <button class="text-primary" mat-menu-item matTooltip="Editar datos básicos" matTooltipPosition="left" *ngIf="!this.viewsAvailable['basic_data'].show_view" (click)="utils.setSelectedView(this.viewsAvailable,this.viewsAvailable['basic_data'].name)"><i class="fa-solid fa-file-lines"></i> Datos básicos</button>
                <button class="text-primary" mat-menu-item matTooltip="Editar datos de diseño" matTooltipPosition="left" *ngIf="!this.viewsAvailable['design'].show_view" (click)="utils.setSelectedView(this.viewsAvailable,this.viewsAvailable['design'].name)"><i class="fa-solid fa-palette"></i>  Diseño</button>
                <button class="text-primary" mat-menu-item matTooltip="Editar plantilla web" matTooltipPosition="left" *ngIf="!this.viewsAvailable['website_template'].show_view " [disabled]="validationService.isNullOrEmpty(webTemplate.id)" (click)="utils.setSelectedView(this.viewsAvailable,this.viewsAvailable['website_template'].name)"><i class="fa-solid fa-layer-group"></i>  Plantilla web</button>
            </mat-menu>
        </div>
    </section>
    <app-basic-data 
    *ngIf="!loading && this.viewsAvailable['basic_data'].show_view" 
    [isUpdate]="!utils.isNullOrEmpty(webTemplate.id)" 
    [userWebAddress]="userWebAddress"
    [userWebAddressStr]="userWebAddressStr"
    [basicData]="{contact_us:webTemplate.contact_us,custom_css:webTemplate.custom_css,custom_js:webTemplate.custom_js,metadata:webTemplate.metadata,url_identifier:webTemplate.url_identifier }"
    (updateData)="updateBasicData($event)"></app-basic-data>
    
    <app-design 
    *ngIf="!loading && this.viewsAvailable['design'].show_view"
    [isUpdate]="!utils.isNullOrEmpty(webTemplate.id)" 
    [designData]="{color_palette:webTemplate.color_palette,typography:webTemplate.typography,home:webTemplate.home,about_us:webTemplate.about_us,our_services:webTemplate.our_services,contact_us:webTemplate.contact_us,global_image_filenames:webTemplate.global_image_filenames }"
    (updateData)="updateDesignData($event)"></app-design>
    
    <app-website-template 
    *ngIf="!loading && this.viewsAvailable['website_template'].show_view && !utils.isNullOrEmpty(webTemplate.id)"
    [domainWebsite]="domainWebsite"
    [userWebAddress]="userWebAddress"
    [userWebAddressStr]="userWebAddressStr"
    (updateData)="updateWebTemplatePageData($event)"
    [webWemplatePageDataEdition]="{home:webTemplate.home,about_us:webTemplate.about_us,our_services:webTemplate.our_services,contact_us:webTemplate.contact_us,design_versions:webTemplate.design_versions}"
    ></app-website-template>
    
    <div class="loader" *ngIf="loading">
        <img src="../../../assets/loader.svg">
    </div>
</section>