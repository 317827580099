<button 
class="btn btn-primary button-header" 
*ngIf="showTriggerType =='button'" 
(click)="showDeviceStatusRecordSummary(deviceToConsultStatusRecord)"
[matMenuTriggerFor]="informationMenu"
matTooltip="Ver historial de cambios de estados del dispositivo" 
[disabled]="validationService.isNullOrEmpty(deviceToConsultStatusRecord.current_driver_id??null)"
>
<i class="fa-solid fa-road"></i><span class="description"> Historial</span>
</button>
<mat-icon *ngIf="showTriggerType =='icon'" 
    role="button"
    matTooltip=" {{validationService.isNullOrEmpty(deviceToConsultStatusRecord.device_status_record??null) ? 'No tiene estados registrados': 'Ver historial de últimos estados'}}"
    [matMenuTriggerFor]="informationMenu"
    (click)="showDeviceStatusRecordSummary(deviceToConsultStatusRecord)"
    class="text-info cursor-pointer">
    info
</mat-icon>
<mat-menu #informationMenu="matMenu" class="detail-popover" [xPosition]="'before'" [yPosition]="'below'" >
    <span class="detail-popover__title">
        <h2 class="detail-popover__title-value">{{validationService.isNullOrEmpty(deviceToConsultStatusRecord.plate_number??null)? 'Sin placa definida' : deviceToConsultStatusRecord.plate_number}}</h2>
        <small class="detail-popover__title-label">{{'Últimos  estados'+ (deviceStatusRecordSummary.length==0 ? '' : ((deviceStatusRecordSummary.length == 1 ? (deviceStatusRecordSummary[0].created_at !='' ?(' ('+deviceStatusRecordSummary.length+")") : ''):(' ('+deviceStatusRecordSummary.length+")") )))}}</small>
    </span>
    <span mat-menu-item [disableRipple]="true" (click)="$event.stopPropagation()" class="detail-popover-content">
        <mat-list class="list detail-section">
            <ng-container *ngFor="let item of deviceStatusRecordSummary;index as i">
                <mat-list-item  class="item"><span class="item__label">{{(item.created_at == '' ? '' :i+1)+'. '+ item.name}} <mat-icon *ngIf="!validationService.isNullOrEmpty(item.description??null)"  class="text-info" [matTooltip]="item.description??''">info</mat-icon> </span><span class="item__value">{{item.created_at | date:'EEEE, MMMM d, y h:mm:ss a'}}</span> </mat-list-item>
                <mat-divider></mat-divider>
            </ng-container>
        </mat-list>
    </span>
</mat-menu>