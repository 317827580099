    <div class="form-box animate__animated animate__fadeIn">
        <form  class="form-box__content form-box__content-h-auto form-box__content-force-h-adaptive" id="registrationForm" enctype="multipart/form-data">
    
            <section class="form-box__content-section">
                <div class="form-box__content-inputs">
                    <div class="form-box__content-group-fields">
                        <mat-form-field appearance="fill" hintLabel="Max 70 carácteres">
                            <mat-label>Título</mat-label>
                            <input matInput maxlength="70" placeholder="Ex. Mi nombre empresarial" name="title" [(ngModel)]="basicData.metadata.title">
                            <mat-icon matSuffix [matTooltipClass]="'tooltip-multiline'" [matTooltip]="'Ingresa un título para tu sitio web que sea claro y cautivador'"><i class="fa-solid fa-circle-question pointer icon"></i>
                            </mat-icon>
                            <mat-hint align="end">{{basicData.metadata.title.length || 0}}/70</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="form-box__content-group-fields">
                        <mat-form-field appearance="fill" hintLabel="Max 160 carácteres">
                            <mat-label>Descripción</mat-label>
                            <textarea matInput maxlength="160" name="description" [(ngModel)]="basicData.metadata.description" class="textarea" placeholder="Ex. Somos una empresa especializada en proporcionar soluciones de seguimiento y localización en tiempo real altamente confiables para una amplia gama de dispositivos..."></textarea>
                            <mat-icon matSuffix [matTooltipClass]="'tooltip-multiline'" [matTooltip]="'Incluye una descripción convincente y persuasiva. \n\nEsta aparecerá, despues del titulo, en los resultados de búsqueda de los navegadores web'">
                                <i class="fa-solid fa-circle-question pointer icon"></i>
                            </mat-icon>
                            <mat-hint align="end">{{basicData.metadata.description.length || 0}}/160</mat-hint>
                        </mat-form-field>
                    </div>
        
                    <span class="form-text">Puedes copiar el siguiente enlace para acceder al sitio web: <a [href]="userWebAddress" target="_blank" id="clientUrl">{{userWebAddressStr}}</a><span (click)="copyClientUrl()" matTooltip="Copiar al portapapeles" *ngIf="validationService.isClipboardApiAvailable()"><i class="fa-solid fa-copy icon-copy"></i></span></span>
                    <div class="form-box__content-group-fields">
                        <mat-form-field hintLabel="Max 50 carácteres" appearance="fill">
                            <mat-label>Identificador URL</mat-label>
                            <input matInput placeholder="Ex. nombre-empresarial" maxlength="50" autocomplete="off" name="urlIdentifier" [(ngModel)]="basicData.url_identifier"pattern="^(?!.*--)(?!-)[a-z0-9-]{5,}(?<!-)$">
                            <mat-icon matSuffix [matTooltipClass]="'tooltip-multiline'" [matTooltip]="'Identificador en la ruta de la dirección web para apuntar a tu sitio personalizado. Solo puede contener:
                             
                             * Números
                             * Letras minusculas
                             * Guiones
                             
                             No debe de haber dos guiones seguidos ni terminar o iniciar con guión. Debe ser de mínimo 5 carácteres'">
                                <i class="fa-solid fa-circle-question pointer icon"></i>
                            </mat-icon>
                            <mat-hint align="end">{{basicData.url_identifier.length || 0}}/50</mat-hint>
                            <mat-error>Solo puede contener Números, letras minusculas y guiones. 
                                No debe de haber dos guiones seguidos ni terminar o iniciar con guión. Debe ser de mínimo 5 carácteres</mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <fieldset class="form-box__content-fieldset">
                    <legend>Metadatos <span class="optional-legend">(opcional)</span></legend>
                    <mat-tab-group>
                        <mat-tab label="Basicas">
                            <div class="form-box__content-group-fields mt-1">
                                <mat-form-field appearance="fill">
                                    <mat-label>¿Permitir que los buscadores indexen su sitio web?</mat-label>
                                    <mat-select name="metadataRobots"   [(ngModel)]="basicData.metadata.robots">
                                        <ng-container   *ngFor="let type of [{value:'robots,follow',label:'Sí'},{value:'noindex,nofollow',label:'No'}]">
                                            <mat-option [value]="type.value">{{type.label}}</mat-option>
                                        </ng-container>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field appearance="fill" hintLabel="Max 100 carácteres">
                                    <mat-label>Autor</mat-label>
                                    <input matInput type="text" maxlength="100" placeholder="Ex. Mi nombre empresarial" name="author" [value]="user.name" [(ngModel)]="basicData.metadata.author">
                                    <mat-hint align="end">{{basicData.metadata.author.length || 0}}/100</mat-hint>
                                </mat-form-field>
                            </div>
                            <div class="form-box__content-group-fields">
                                <mat-form-field appearance="fill" hintLabel="Max 100 carácteres">
                                    <mat-label>Copyright</mat-label>
                                    <input matInput type="text" maxlength="100" placeholder="Ex. Mi nombre empresarial" name="copyright" [(ngModel)]="basicData.metadata.copyright">
                                    <mat-hint align="end">{{basicData.metadata.copyright.length || 0}}/100</mat-hint>
                                </mat-form-field>
                            </div>
                        </mat-tab>
                        <mat-tab label="Social">
                            <div class="d-inline-block p-2">
                                <span class="icons-list"matTooltip="metadatos para compartir en medios sociales">
                                    <i class="fa-brands fa-facebook icon-2xl icon-facebook icon"></i> 
                                    <i class="fa-brands fa-instagram icon-2xl icon-instagram icon"></i>
                                    <i class="fa-brands fa-twitter icon-2xl icon-twitter icon" ></i> 
                                    <i class="fa-brands fa-linkedin icon-2xl icon-linkedin icon"></i>
                                </span>
                            </div>
                            <span class="form-text">Son metadatos importantes que mejoran la forma en que se muestra tu sitio web en las redes sociales como Facebook, Twitter y LinkedIn cuando alguien comparte un enlace</span>
                            <br>
                            <div class="form-box__content-group-fields">
                                <mat-form-field appearance="fill" hintLabel="Max 100 carácteres">
                                    <mat-label>Título</mat-label>
                                    <input matInput type="text" maxlength="100" placeholder="Ex. Nombre empresarial | Rastreo satelital" name="socialMediaTitle" [(ngModel)]="basicData.metadata.social_media.title">
                                    <mat-icon matSuffix [matTooltipClass]="'tooltip-multiline'" [matTooltip]="'Proporciona un título descriptivo. Este será el que se muestra como el título en las publicaciones compartidas'">
                                        <i class="fa-solid fa-circle-question pointer icon"></i>
                                    </mat-icon>
                                    <mat-hint align="end">{{basicData.metadata.social_media.title.length || 0}}/100</mat-hint>
                                </mat-form-field>
                            </div>
                            <div class="form-box__content-group-fields">
                                <mat-form-field appearance="fill" hintLabel="Max 100 carácteres">
                                    <mat-label>Nombre del sitio web</mat-label>
                                    <input matInput type="text" maxlength="100" placeholder="Ex. Tracking GPS" name="socialMediaSiteName" [(ngModel)]="basicData.metadata.social_media.site_name">
                                    <mat-icon matSuffix [matTooltipClass]="'tooltip-multiline'" [matTooltip]="'Su función principal es proporcionar información a las redes sociales sobre el nombre del sitio web para una identificación precisa'">
                                            <i class="fa-solid fa-circle-question pointer icon"></i>
                                    </mat-icon>
                                    <mat-hint align="end">{{basicData.metadata.social_media.site_name.length || 0}}/100</mat-hint>
                                </mat-form-field>
                            </div>
                            <div class="form-box__content-group-fields">
                                <mat-form-field appearance="fill" hintLabel="Max 160 carácteres">
                                    <mat-label>Descripción</mat-label>
                                    <textarea matInput maxlength="160" name="socialMediaDescription" [(ngModel)]="basicData.metadata.social_media.description" class="textarea" placeholder="Descubre cómo nuestra solución de rastreo GPS en tiempo real puede ayudarte a gestionar eficazmente tu flota de vehículos"></textarea>
                                    <mat-icon matSuffix [matTooltipClass]="'tooltip-multiline'" [matTooltip]="'Escribe una breve descripción que explique el contenido del sitio web. Esta descripción aparecerá en las vistas previas compartidas'">
                                        <i class="fa-solid fa-circle-question pointer icon"></i>
                                    </mat-icon>
                                    <mat-hint align="end">{{basicData.metadata.social_media.description.length || 0}}/160</mat-hint>
                                </mat-form-field>
                            </div>
                        </mat-tab>
                        <mat-tab label="Otras +">
                            <div class="form-box__content-group-fields mt-1">
                                <mat-form-field appearance="fill" hintLabel="Max 60 carácteres">
                                    <mat-label>Código de Google Search Console  </mat-label>
                                    <input matInput type="text" maxlength="60" placeholder="Ex. tu-codigo-de-verificacion" name="metaGoogleSearchConsole" [(ngModel)]="basicData.metadata.google_site_verification">
                                    <mat-icon matSuffix [matTooltipClass]="'tooltip-multiline'" [matTooltip]="'Si usas Google Search Console, indica el código de verificacion en caso de estar registrado con la opcion URl completa, con el metodo de verificación \'etiqueta HTML\''">
                                        <i class="fa-solid fa-circle-question pointer icon"></i>
                                    </mat-icon>
                                    <mat-hint align="end">{{basicData.metadata.google_site_verification.length || 0}}/60</mat-hint>
                                </mat-form-field>
                                <mat-form-field appearance="fill" hintLabel="Max 20 carácteres">
                                    <mat-label>Código de Google Analytics</mat-label>
                                    <input matInput type="text" maxlength="20" placeholder="Ex. UA-XXXXXXXXX-Y" name="gaMeasurementId" [(ngModel)]="basicData.metadata.ga_measurement_id">
                                    <mat-icon matSuffix [matTooltipClass]="'tooltip-multiline'" [matTooltip]="'Si usas Google Analytics, indica el identificador de seguimiento'">
                                        <i class="fa-solid fa-circle-question pointer icon"></i>
                                    </mat-icon>
                                    <mat-hint align="end">{{basicData.metadata.ga_measurement_id.length || 0}}/20</mat-hint>
                                </mat-form-field>
                            </div>
                            <div class="form-box__content-group-fields">
                                <mat-form-field appearance="fill" hintLabel="Max 200 carácteres">
                                    <mat-label>Palabras claves de su sitio web</mat-label>
                                    <input matInput type="text" maxlength="200" placeholder="Ex. Seguridad vehicular y dispositivos, rastreo satelital, Ubicación en tiempo real, ..." name="metaKeyword" [(ngModel)]="basicData.metadata.keywords">
                                    <mat-icon matSuffix [matTooltipClass]="'tooltip-multiline'" [matTooltip]="'Indique palabras claves relacionadas con su sitio web. Separalas por comas'">
                                        <i class="fa-solid fa-circle-question pointer icon"></i>
                                    </mat-icon>
                                    <mat-hint align="end">{{basicData.metadata.keywords.length || 0}}/200</mat-hint>
                                </mat-form-field>
                            </div>
                        </mat-tab>
                    </mat-tab-group>
                </fieldset>
                <fieldset class="form-box__content-fieldset">
                    <legend>Otra Información + <span class="optional-legend">(opcional)</span></legend>
                    <div class="form-box__content-group-fields">
                        <mat-form-field appearance="fill">
                            <mat-label>Insercion de mapa de ubicacion</mat-label>
                            <input matInput type="text" placeholder="Ex. &lt;iframe src=&quot;...&quot; ,,,&gt;&lt;/iframe&gt;" pattern="" name="locationMap" [(ngModel)]="basicData.contact_us.map_embed_html">                    
                        </mat-form-field>
                    </div>
                </fieldset>
                <fieldset class="form-box__content-fieldset">
                    <legend>opciones Avanzadas</legend>
                      <mat-accordion multi>
                        <mat-expansion-panel>
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <i class="fa-brands fa-js icon icon-xl icon-js" ></i> JavaScript
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <div
                                class="app-ace-editor"
                                #editorJs>
                            </div>
                        </mat-expansion-panel>
                        <mat-expansion-panel>
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <i class="fa-brands fa-css3-alt icon icon-xl icon-css"></i> CSS
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <div 
                                class="app-ace-editor"
                                #editorCss>
                            </div>
                        </mat-expansion-panel>
                      </mat-accordion>
                </fieldset>
            </section>
            <div class="form-box__content-actions">
                <button class="btn btn-primary " (click)="update()">{{isUpdate?'Actualizar':'Registrar'}}</button>
            </div>
        </form>
    </div>