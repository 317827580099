<section class="form-box-container animate__animated animate__fadeIn" >
  <section class="form-box-container__header">
      <span class="form-box-container__header-back" (click)="close()"><span class="description">Volver</span> </span>
      <h2 class="form-box-container__header-title"><span class="icon"><i class="fa-solid fa-satellite"></i></span> Registros - Pegaso </h2>
      <div  class="form-box-container__header-options">                     
        <div class="search">
          <input id="inputSearch" type="search" placeholder="Buscar" (input)="searchValueInData()"
            [(ngModel)]="searchValue">
          <label for="inputsearch"><i class="fa-solid fa-magnifying-glass fa-sm"></i></label>
        </div>
      </div>
  </section>
  <div class="form-box animate__animated animate__fadeIn">

      <div class="form-box__content form-box__content-h-auto">
        <div class="w100 overflow-auto">
          <table mat-table  [dataSource]="devices">
            <ng-container matColumnDef="device_imei">
              <th mat-header-cell *matHeaderCellDef> Imei </th>
              <td mat-cell *matCellDef="let element"> {{element.device_imei}} </td>
            </ng-container>  
            <ng-container matColumnDef="device_plate_number">
              <th mat-header-cell *matHeaderCellDef > Placa </th>
              <td mat-cell *matCellDef="let element"> {{element.device_plate_number}} </td>
            </ng-container>
  
            <ng-container matColumnDef="alert_name_convention">
              <th mat-header-cell *matHeaderCellDef > Evento </th>
              <td mat-cell *matCellDef="let element"> {{element.alert_name_convention}} </td>
            </ng-container>
            <ng-container matColumnDef="created_at">
              <th mat-header-cell *matHeaderCellDef  matTooltip="Fecha en que se registra la emisión del evento en la plataforma hacia el web service"> Fecha de registro  <i class="fa-regular fa-circle-question icon"></i> </th>
              <td mat-cell *matCellDef="let element"> {{element.created_at}} </td>
            </ng-container>
  
            <ng-container matColumnDef="device_time">
              <th mat-header-cell *matHeaderCellDef matTooltip="Fecha en la que el dispositivo emite el evento" > Fecha del dispositivo <i class="fa-regular fa-circle-question icon"></i></th>
              <td mat-cell *matCellDef="let element"> {{element.device_time}} </td>
            </ng-container>
            <ng-container matColumnDef="result">
              <th mat-header-cell *matHeaderCellDef matTooltip="Respuesta dada por Pegaso" > Respuesta <i class="fa-regular fa-circle-question icon"></i></th>
              <td mat-cell *matCellDef="let element"> {{element.result}} </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;">
            </tr>
            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell empty py-1" [attr.colspan]="displayedColumns.length">Sin datos</td>
          </tr>
          </table>
        </div>
        <mat-paginator class="position-sticky z-index-1 bottom-0 w-100"  [length]="lengthPage" [pageSize]="pageSize" [pageIndex]="currentPage"
          [pageSizeOptions]="pageSizeOptions" (page)="paginator($event)"
          aria-label="Selecciona pagina de la lista de dispositivos">
        </mat-paginator>
      </div>
  </div>
  <div class="loader" *ngIf="loading">
    <img src="../../../../../../assets/loader.svg">
  </div>
</section>