import { Component } from '@angular/core';
import { DataService } from './core/data/data.service';
import { ValidationService } from './core/validation/validation.service';
import { RouteConstant, IWebDomainElement, WebDomainKey } from './core/constant/RouteConstant';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'sgc';
  constructor(
    private dataservice: DataService,
    public validationService: ValidationService,
  ){
    let hostname:string = location.hostname;
    let webDomain: {item_name: string | null, value: IWebDomainElement | null}= RouteConstant.existsHostNameInAllowedWebDomain(hostname);  
    this.dataservice.accessHostName = webDomain.value;
    this.dataservice.accessHostNameKey = webDomain.item_name != null ? webDomain.item_name as WebDomainKey : null;
  }
}
