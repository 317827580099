<section class="form-box-container animate__animated animate__fadeIn">
    <section class="form-box-container__header">
        <span class="form-box-container__header-back" (click)="closeModal()"><span class="description">Volver</span> </span>
        <h2 class="form-box-container__header-title"><span class="icon"><i class="fa-solid fa-list-check"></i></span>Edición de plan</h2>
        <div  class="form-box-container__header-options"> </div>
    </section>
    <div class="form-box transparent-scroll-vertical flex-wrap two-panel two-panel--automatic-orientation animate__animated animate__fadeIn">
      <div class="form-box__content  form-box__content-shadow form-box__content-fill  transparent-scroll-vertical">
        <div class="form-box__content-group-fields">
            <mat-form-field appearance="fill">
                <mat-label>Nombre del plan <span class="required-field" >*</span></mat-label>
                <input matInput placeholder="Ex. Premium"(keyup)="isNewPlatformPlanModified=true" name="namePlatformPlan" [(ngModel)]="newPlatformPlan.name">
            </mat-form-field>
            <mat-form-field appearance="fill"class="animate__animated animate__faster" >
                <mat-label>Roles asignados al plan</mat-label>
                <mat-select name="'platformPlanRole" [multiple]="true"
                [(ngModel)]="selectedPlanRoles" 
                (selectionChange)="isNewPlatformPlanModified=true;modifyPlanRoles($event)" 
                [compareWith]="compareRolesSelected">
                    <mat-option *ngFor="let role of rolesList" [value]="role" [disabled]="roleAssociatedToPlan(role) || !isValidPlanForRole(role.id)">{{role.name}}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="fill" class="fill">
                <mat-label>Descripcion del plan</mat-label>
                <textarea maxlength="150" matInput name="descriptionplatformPlan" (keyup)="isNewPlatformPlanModified=true" [(ngModel)]="newPlatformPlan.description"></textarea>
            </mat-form-field>
        </div>
        <div class="form-box__content-actions">
            <button class="btn btn-primary" 
            (click)="updatePermissions()"
            [disabled]="isNewPlatformPlanModified==false"
            [matTooltip]="!isCreatePlatformPlan?'Actualiza el conjunto de permisos y su descripción':''"  
            mat-raised-button> {{isCreatePlatformPlan?'Crear':'Actualizar'}}</button>
            <button class="btn btn-secondary" (click)="closeModal()">Cancelar</button>
        </div>
        <div class="form-box__content-section" *ngIf="!isCreatePlatformPlan">
            <div class="w100 overflow-auto">
                <table mat-table [dataSource]="newPlatformPlan.modules" class="mat-elevation-z8 demo-table">
                    <ng-container matColumnDef="module_name" >
                        <th mat-header-cell *matHeaderCellDef class="border-right-primary"> Modulo </th>
                        <td mat-cell *matCellDef="let module" class="border-right-primary"> {{module.module_name}} </td>
                      </ng-container>
                      <ng-container matColumnDef="total_permissions">
                        <th mat-header-cell *matHeaderCellDef > Total permisos </th>
                        <td mat-cell *matCellDef="let module"> {{module.permissions.length}} </td>
                      </ng-container>
                      <ng-container matColumnDef="permissions">
                        <th mat-header-cell *matHeaderCellDef> Permisos </th>
                        <td mat-cell *matCellDef="let module;let i = index"> 
                            <div class="mat-form-field">
                                <mat-form-field appearance="fill"class="animate__animated animate__faster" >
                                    <mat-select [name]="'modulePermissions'+i" [multiple]="true" 
                                    [(ngModel)]="module.permissions" 
                                    [compareWith]="comparePermissionsSelected" 
                                    (selectionChange)="isNewPlatformPlanModified=true">
                                        <mat-option *ngFor="let operation of getModulePermissions(module)" [value]="operation"  [selected]="isPermissionSelected(operation,module)">{{operation.name}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>    
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="actions">
                        <th mat-header-cell *matHeaderCellDef class="mat-header-cell-center"> Acciones </th>
                        <td mat-cell *matCellDef="let element" >
                            <div class="actions-container">
                                <button class="text-primary" [disabled]="loading" matTooltip="Eliminar todos los permisos del modulo" matTooltipPosition="right" (click)="deleteModulePermissions(element)" ><i class="fa-solid fa-trash-can fa-sm clas"></i></button>
                            </div>
                        </td>
                      </ng-container>
        
                    <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell empty py-1" [attr.colspan]="displayedColumns.length">Sin datos</td>
                    </tr>
                  </table>
              </div>
        </div>
      </div>
      <div class="form-box__content form-box__content-auto form-box__content-flex-basis-medium transparent-scroll-vertical  order-mobile-first" *ngIf="!isCreatePlatformPlan" >
        <div class="form-box__content-header sticky-top">
          <h3 class="form-box__content-title">asignar nuevo módulo</h3>
        </div>
        <div class="form-box__content-section">
            <div class="form-box__content-group-fields">
                <mat-form-field appearance="fill">
                    <mat-label>Modulo</mat-label>
                    <mat-select name="module" [(ngModel)]="selectedModule" (selectionChange)="prepareModulePermissionsToPlatformPlan()" >
                        <mat-option *ngFor="let module of filteredModules" [value]="module" >{{module.name}}</mat-option>
                        <mat-option [value]="" *ngIf="filteredModules.length==0"  [disabled]="filteredModules.length==0">No hay modulos disponibles</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="fill"class="animate__animated animate__faster" >
                    <mat-label>Permisos</mat-label>
                    <mat-select name="platformPlanPermissions" [disabled]="selectedModulePermissions.length==0" [multiple]="true" [(ngModel)]="modulePermissionsToAddToPlatformPlan">
                        <mat-option *ngFor="let permission of selectedModulePermissions" [value]="permission">{{permission.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="form-box__content-actions">
                <button class="btn btn-primary" 
                (click)="addNewModuleToPlatformPlanWithPermissions()" 
                [disabled]="!isValidmodulePermissionsToAddToPlatformPlan()['status']" 
                matTooltip="Agregar permisos seleccionados" 
                mat-raised-button>
                <i class="fa-solid fa-plus"></i></button>
            </div>
        </div>
      </div>
    </div>
    <div class="loader" *ngIf="loading">
        <img src="../../../assets/loader.svg">
    </div>
  </section>