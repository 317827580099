import { Injectable } from '@angular/core';
import {MatSnackBar, MatSnackBarConfig} from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class MessageBoxService {

  constructor(
    private snackBar:MatSnackBar
  ) { }

  openSnackBar(message: string, action: string,configuration:MatSnackBarConfig={duration:4000,verticalPosition:"bottom",horizontalPosition:"center"}, callback:any=()=>{}) {
    const snackBar = this.snackBar.open(message, action,{duration:configuration.duration,verticalPosition:configuration.verticalPosition ?? "bottom",horizontalPosition:configuration.horizontalPosition ?? "center"});
      // Agrega un oyente de eventos al action
      snackBar.onAction().subscribe(() => {
      // Lógica personalizada
      callback();
    });
    snackBar.afterDismissed().subscribe(() => {
      // Esta función se ejecutará cuando el Snackbar se cierre, ya sea por clic en el boton o automáticamente.
      callback();
    });
  }
}
