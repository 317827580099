import { Injectable } from '@angular/core';
import { ValidationService } from '../validation/validation.service';
import { IVersion, IWebTemplateEditMenu } from 'src/app/views/website/models/interfaces/iweb-template-edit-menu.model';
import { IGlobalDesignVersions, IWebWemplatePageDataEdition } from 'src/app/views/website/models/interfaces/iweb-template.model';
import { WebTemplatePages } from 'src/app/views/website/website.types';
import { WebsiteInitializationService } from 'src/app/views/website/website-initialization/website-initialization.service';


@Injectable({
  providedIn: 'root'
})
export class WebMenuManagementService {

  isActiveWebMenu:boolean = false;
  webWemplatePageDataEdition!: IWebWemplatePageDataEdition;

  menuWebTemplate!:IWebTemplateEditMenu[];
  actualMenuWebTemplateId:string="1";
  actualMenuWebTemplateName:WebTemplatePages="home";
  actualMenuNameWebTemplateRemote:WebTemplatePages="home";//la pagina actual del sitio web que se edita
  actualSubMenu:string="";

  frameWebTemplate!:HTMLIFrameElement;
  userWebAddressStr!:string;

  callBackUpdateData!:any;
  /**Indica si el frame se encuentra en modo edicion */
  editFrameTemplate:boolean=false;
  constructor(
    private validation: ValidationService,
    private websiteInitialization:WebsiteInitializationService
  ){
    this.menuWebTemplate = this.websiteInitialization.initializeIWebTemplateEditMenu();
  }
  activeItemMenu(item: any, sub: boolean = false){
    if(item.sections.length > 0){
      if(this.actualSubMenu !=""){
        if(!sub) this.actualSubMenu ="";
      } else
        this.actualSubMenu = item.id;
    }
    this.actualMenuWebTemplateId = item.id;
    if(this.actualMenuWebTemplateName!=item.name && item.name !="global"){
      this.actualMenuWebTemplateName=item.name;
      if(this.editFrameTemplate)
        this.callBackUpdateData(this.webWemplatePageDataEdition);
      this.refreshIframeWebTemplate();
    }
  }

  isVersionSelected(pageName:WebTemplatePages,sectionName:keyof IGlobalDesignVersions|null|string,version:IVersion):boolean{
    const pageData = this.webWemplatePageDataEdition.design_versions[pageName];
      if ( typeof pageData!=="undefined" && pageData !== null && sectionName!==null) {
          const sectionData = pageData[sectionName];
          if (sectionData !== null && typeof sectionData !== "undefined") {
            return sectionData === version.value;
          }
      }     
    return false;
  }
  refreshIframeWebTemplate(){
    if(this.frameWebTemplate.contentWindow!=null){
      this.frameWebTemplate.src =this.userWebAddressStr+(this.actualMenuWebTemplateName.replace("_","-"));
    }
  }
  //para aplicar versiones disponibles para el sitio web
  applyVersion(pageName:WebTemplatePages,sectionName:keyof IGlobalDesignVersions|null|string,version:IVersion){
    let pageData = this.webWemplatePageDataEdition.design_versions[pageName];
    if (pageData !== null && pageData !== undefined && sectionName!==null && pageData[sectionName] !== null && pageData[sectionName] !== undefined){
      pageData[sectionName] = version.value;
      this.callBackUpdateData(this.webWemplatePageDataEdition,this.refreshIframeWebTemplate.bind(this));
    }
  }
   logout(){}
}
