import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/core/api/api.service';
import { DataService } from 'src/app/core/data/data.service';
import { UtilsService } from 'src/app/core/utils/utils.service';
import { BillingService } from '../../billing.service';
import { FormControl, FormGroup } from '@angular/forms';
import { IViewsAvailable } from 'src/app/shared/models/interfaces/views-available';
import { IUserSessionData } from 'src/app/shared/models/interfaces/iuser-session-data.model';

@Component({
  selector: 'app-device-linking-billing',
  templateUrl: './device-linking-billing.component.html',
  styleUrls: ['./device-linking-billing.component.scss']
})
export class DeviceLinkingBillingComponent implements OnInit {

  user: IUserSessionData;
  loading: boolean = false;
  loadingDeviceList: boolean = false;

  viewsAvailable:IViewsAvailable = {
    binding_list: {name:"binding_list", show_view:true},
    payment_record_list: {name:"payment_record_list", show_view:false}
  };
  @Output() openRenovationsModal: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() isUserDevicesSelected: boolean = false;

  isCompleteLoad:boolean=true;

  displayedColumnsInDataSummary: String[] = ["name", "devicesTotal", "actions"];
  /** Nombre de columna para el campo distribuidor */
  distributorNameColumnsInGeneralTable: string = "distributorName";
  displayedColumnsInGeneralTable: String[] = ["index", "observation", "distributorName", "imei", "deviceName", "plate_number", "server_time", "created_at", "activation_date", "expiration_date"];
  
  searchEmailByBinding:string="";//para input de busqueda en vista vinculaciones
  devicesBindHeader:any=["distributorEmail","distributorName","plate_number","name","imei","observation","server_time","created_at","expiration_date"];//define las columnas que se van a exportar de los datos de dispositivos vinculados
  devicesWithBindDate:any=[];
  devicesWithBindDateSorted:any=[];
  allDevicesWithBindDate:any=[];//alamcena todos los datos de dispositivos vinculados a la fecha
  distributorsByTotalGps:any=[];
  distributorsByTotalGpsSorted:any=[];
  deviceIndexList:any=[];
  UserEmailSelected:string="";//usado en el button de la plantilla para cuando se cargan los datos de un cliente especifico 
  
  
  range: FormGroup;
  constructor(
    public utils: UtilsService,
    private api: ApiService,
    public dataService: DataService,
    private router: Router,
    public userDevicesdialog: MatDialog,
    private billingService: BillingService
  ) {
    this.user = this.dataService.getData("user") as IUserSessionData;

    const date = new Date(); 
    this.range = new FormGroup({
      start: new FormControl(new Date(date.getFullYear(),date.getMonth(),1)),
      end: new FormControl(new Date(date.getFullYear(),(date.getMonth()+1),0)),
    });
  }

  ngOnInit(): void {
    this.loading =true;
    this.getDistributorsDataSummary();
  }

  sortData(sort: Sort, nameDataSource: string) {
    let data: any = [];
    //defino los datos para ordenarlos
    if (nameDataSource == "distributorsByTotalGps") {
      if (this.isUserDevicesSelected) {
        this.loadAllDevices();
      }
      data = this.distributorsByTotalGps.slice();
    } else if (nameDataSource == "devicesWithBindDate") {
      data = this.devicesWithBindDate.slice();
    }
    if (nameDataSource == "distributorsByTotalGps" && (!sort.active || sort.direction === '')) {
      this.distributorsByTotalGpsSorted = data;
      return;
    }
    else if (nameDataSource == "devicesWithBindDate" && (!sort.active || sort.direction === '')) {
      this.devicesWithBindDateSorted = data;
      return;
    }
    switch (nameDataSource) {
      case "distributorsByTotalGps":
        this.distributorsByTotalGpsSorted = this.billingService.getDataSorted(data, sort);
        return;
      case "devicesWithBindDate":
        this.devicesWithBindDateSorted = this.billingService.getDataSorted(data, sort);
        return;
      default:
        return;
    }
  }


  loadAllDevices(startDate?:any,endDate?:any,distributorId?:number){
    for(let index=0;index<this.deviceIndexList.length;index++){
        this.deviceIndexList[index]=false;
    }
    this.isUserDevicesSelected=false;
    this.UserEmailSelected="";

    this.loadingDeviceList = true;
    this.utils.hideLoading(()=> this.loadingDeviceList = false);
    this.devicesWithBindDate=this.allDevicesWithBindDate;
    this.devicesWithBindDateSorted=this.allDevicesWithBindDate.slice();

  }

  search(modal:string,dataSourceName:string){
    let dataToSearch: string = "";
    if(dataSourceName =="distributorsByTotalGps"){
      dataToSearch =this.searchEmailByBinding.toLowerCase();
    }
    if(dataToSearch.length >0){
        if(dataSourceName =="distributorsByTotalGps"){
          this.distributorsByTotalGpsSorted =this.distributorsByTotalGps.filter((user:any)=>{
            return user.email.toLowerCase().includes(dataToSearch) || user.name.toLowerCase().includes(dataToSearch);
          });
        }
    }
    else{
        if(dataSourceName =="distributorsByTotalGps"){
          this.distributorsByTotalGpsSorted =this.distributorsByTotalGps.slice();
        }
    }
  }
  exportFile(source:any,sourceHeaders:any,fileName:string="export_"){
    let data= source;
    let headers=sourceHeaders;
    if(this.isUserDevicesSelected){//variable usada en la vista vinculaciones
      headers=["plate_number","name","imei","expiration_date","created_at","server_time","observation"];//los campos a exportar cuando se selecciones los datos de un usuario
      //cuando se seleccione los dispositivos de un usuario este se nombrara con la parte del nombre que tenga asignado en el email
      fileName=this.UserEmailSelected;
    }
    fileName=fileName.split("@")[0];
    if(data.length==0){
      this.utils.showMsg("","No hay datos para exportar");
      return;
    }
    this.utils.showConfirm("¿Está seguro de exportar los datos?", "Los datos pueden tomar un tiempo en procesarse", "Continuar", "Cancelar").then(() => {
      this.loading = true;
      setTimeout(() => {
        const date=new Date(); 
        fileName=fileName+"_"+ date.getFullYear()+(this.utils.evaluateDateNumber(date.getMonth()+1))+(this.utils.evaluateDateNumber(date.getDate()))+"_"+date.getHours()+date.getMinutes()+date.getSeconds();
        this.utils.downloadFileAsCSV(data,  fileName, headers);
        this.loading = false;
        this.utils.showMsg("","Datos exportados con éxito");
      }, 2500);
    });
  }

  loadUserDevices(startDate:any,endDate:any,distributorId:number,fromInput:boolean,elementIndex:any,userEmail:string){
    this.isUserDevicesSelected=true;
    this.UserEmailSelected=userEmail;
    this.deviceIndexList[elementIndex]=true;
    for(let index=0;index<this.deviceIndexList.length;index++){
      if(index==elementIndex){
        this.deviceIndexList[index]=true;
      }else{
        this.deviceIndexList[index]=false;
      }
    }
    this.getDevicesByBindDateRange(startDate,endDate,distributorId,undefined,fromInput);
  }
  getDevicesByBindDateRange(startDate?:any,endDate?:any,userId?:number,page?:number,fromInput:boolean=false){
    this.loading = true;
    let data="?action=getDevicesByBindDateRange&user_api_hash="+this.user.hash;
    if(fromInput){
      this.utils.hideLoading(()=>this.loadingDeviceList = false);
    }
    if(fromInput!=false && ((startDate ===null || startDate ===false) || (endDate ===false || endDate ===null))){
      this.utils.showMsg("","No ha seleccionado un rango de fechas válida");
      this.utils.hideLoading(()=>this.loading = false);
      return;
    }
    if(((startDate!==null && startDate !=false) && (endDate !=false && endDate !=null))){
      let dateRange=this.billingService.getDateRange(startDate,endDate);
      startDate=dateRange.start;
      endDate=dateRange.end;
    }
    if(typeof userId !="undefined"){
      data += "&user_id="+userId;
    }
    if(typeof page !="undefined"){
      data += "&page="+page;
    }
    if((typeof startDate !="undefined" && typeof endDate !="undefined")){
      data += "&start_date="+startDate+"&end_date="+endDate;
    }
    this.api.getData("device",data,true).then((data:any)=>{
      if(data.status==1){
        if(typeof userId =="undefined"){
          this.allDevicesWithBindDate =data.data;
          this.devicesWithBindDate=data.data.slice();
          this.devicesWithBindDateSorted=data.data.slice(); 
        }else{
          this.devicesWithBindDate=data.data;
          this.devicesWithBindDateSorted=data.data.slice(); 
        }
      }else{
        this.allDevicesWithBindDate =[];
          this.devicesWithBindDate=[];
          this.devicesWithBindDateSorted=[]; 
      }
      if(fromInput){
        this.utils.hideLoading(()=>{this.loadingDeviceList = false;this.loading = false;});
      }
      this.utils.hideLoading(()=>this.loading = false);
    }).catch((error)=>{console.log(error);this.utils.hideLoading(()=>this.loading = false);});
  }

  filterDistributorsBydateRange(startDate:any,endDate:any,isFromInput:boolean=false){
    this.loading = true; 
    this.isUserDevicesSelected=false;
    this.UserEmailSelected="";
  
    this.getDistributorsDataSummary(startDate,endDate,undefined,isFromInput);    

  }
  getDistributorsDataSummary(startDate?:any,endDate?:any,page?:number,fromInput:boolean=false){
    let startDateBindDateRange=startDate;
    let endDateBindDateRange=endDate;
    let data="?action=getManagersDevicesQuantity&user_api_hash="+this.user.hash;
    if(fromInput!=false && ((startDate ===null || startDate ===false) || (endDate ===false || endDate ===null))){
      this.utils.showMsg("","No ha seleccionado un rango de fechas válida");
      this.utils.hideLoading(()=>this.loading = false);
      return;
    }
    if(typeof page !="undefined"){
      data += "&page="+page;
    }
    if((typeof startDate !="undefined" && typeof endDate !="undefined")&& ((startDate!==null && startDate !=false) && (endDate !=false && endDate !=null))){
      let dateRange=this.billingService.getDateRange(startDate,endDate);
      startDate=dateRange.start;
      endDate=dateRange.end;
      data += "&start_date="+startDate+"&end_date="+endDate;

    }
    this.api.getData("device",data,true).then((data:any)=>{
      console.log(data);
      if(data.status==1){
        this.distributorsByTotalGps=data.data;
        this.distributorsByTotalGpsSorted=data.data.slice();
        for(let index=0;index<data.data.length;index++){
          this.deviceIndexList[index]=false;
        }
      }else{
        this.distributorsByTotalGps=[];
        this.distributorsByTotalGpsSorted=[];
      }
      
      this.getDevicesByBindDateRange(startDateBindDateRange,endDateBindDateRange,undefined,undefined,fromInput);
      if(!this.isCompleteLoad)
      this.utils.hideLoading(()=>this.loading = false);    
    }).catch((error)=>{console.log(error);      this.utils.hideLoading(()=>this.loading = false);});
  }
  openRenovationModal(){
    this.openRenovationsModal.emit(true);
  }
}
