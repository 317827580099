<div class="chart"*ngIf="barLineChartConfigData.data.datasets[0].data.length > 0">
    <div class="chart__content">
        <div class="chart__content-header-title" *ngIf="barLineChartConfigData.title !=''">{{barLineChartConfigData.title}}</div>
        <div class="chart__content-canvas animate__animated animate__fadeIn" *ngIf="barLineChartConfigData.updated_chart">
            <canvas baseChart
                [data]="barLineChartConfigData.data"
                [options]="barChartOptions"
                [plugins]="barChartPlugins"
                [type]="barChartType">
            </canvas>
        </div>
    </div>
</div>