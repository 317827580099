
<section class="form-box-container animate__animated animate__fadeIn"  *ngIf="!taskModal">
    <section class="form-box-container__header">
        <h2 class="form-box-container__header-title"><span class="icon"><i class="fa-solid fa-list-check"></i></span>Administracion de tareas </h2>
        <div  class="form-box-container__header-options">                     
            <button matToolTip="crear tarea" class="btn btn-primary" (click)="openCreateTaskModal()"><i class="fa-regular fa-calendar-plus"></i> <span class="description"> Agregar</span></button>
            <button matToolTip="Exportar datos" class="btn btn-outline-secondary" (click)="exportFile()"><i class="fa-solid fa-file-export"></i> <span  class="description"> Exportar</span></button>
            <div class="search">
                <input id="inputSearchImei" type="search" placeholder="Buscar" name="searchImei" (input)="getTasks()" [(ngModel)]="searchImei">
                <label for="inputSearchImei"><i class="fa-solid fa-magnifying-glass"></i></label>
            </div>
        </div>
    </section>
    <div class="form-box animate__animated animate__fadeIn">
  
        <div class="form-box__content form-box__content-h-auto">
          <div class="form-box__content-header form-box__content-header-horizontal p-0 form-box__content-header-align-end">
            <mat-checkbox 
            matTooltip="Incluir tareas en proceso" 
            color="primary"
            class="scale-75"
            name="filterStatus"
            value="0"
            [checked]="isTaskStatusChecked(0)"
            (change)="changeCompleteTaskInclusion($event)"
            >En proceso</mat-checkbox>
            <mat-checkbox 
            matTooltip="Incluir tareas finalizadas" 
            color="primary"
            class="scale-75"
            name="filterStatus"
            value="1"
            [checked]="isTaskStatusChecked(1)"
            (change)="changeCompleteTaskInclusion($event)"
            >Finalizadas</mat-checkbox>
            <mat-checkbox 
            matTooltip="Incluir tareas vencidas" 
            color="primary"
            class="scale-75"
            name="filterStatus"
            value="-1"
            [checked]="isTaskStatusChecked(-1)"
            (change)="changeCompleteTaskInclusion($event)"
            >Vencidas</mat-checkbox>

            <button class="btn btn-outline-secondary icon-34x34" matTooltip="ver en vista de calendario"(click)="seeViewCalendar()" *ngIf="taskView =='table'" ><i class="fa-solid fa-calendar-days"></i></button>
            <button class="btn btn-outline-secondary icon-34x34" matTooltip="ver en vista de tabla"(click)="seeViewTable()"*ngIf="taskView =='calendar'"><i class="fa-solid fa-table"></i></button>
            
          </div>
          <ng-container *ngIf="taskView =='table'">
              <div class="w-100 overflow-auto  animate__animated animate__fadeIn">
                <table mat-table matSort (matSortChange)="sortData($event)" [dataSource]="tasksListSorted">
    
                <ng-container matColumnDef="plate_number">
                    <th mat-header-cell *matHeaderCellDef  mat-sort-header class="mat-header-cell-start" sortActionDescription="Ordenar por placa"> Placa  </th>
                    <td mat-cell *matCellDef="let task"> {{task.device_plate_number}}</td>
                </ng-container>
    
                <ng-container matColumnDef="imei">
                    <th mat-header-cell *matHeaderCellDef  mat-sort-header class="mat-header-cell-start" sortActionDescription="Ordenar por imei"> Imei  </th>
                    <td mat-cell *matCellDef="let task"> {{task.device_imei}}</td>
                </ng-container>
    
                <ng-container matColumnDef="employee">
                    <th mat-header-cell *matHeaderCellDef  mat-sort-header class="mat-header-cell-start" sortActionDescription="Ordenar por encargado"> Encargado  </th>
                    <td mat-cell *matCellDef="let task"> {{getEmployeeName(task.employee_id)}}</td>
                </ng-container>
    
                <ng-container matColumnDef="expiration_date">
                    <th mat-header-cell *matHeaderCellDef  mat-sort-header class="mat-header-cell-start" sortActionDescription="Ordenar por fecha de expiración"> Fecha de vencimiento  </th>
                    <td mat-cell *matCellDef="let task"> {{(task.expiration_date | date:"mediumDate")+', '+ (task.expiration_date | date:"h:mm a")}}</td>
                </ng-container>
    
                <ng-container matColumnDef="title">
                    <th mat-header-cell *matHeaderCellDef  mat-sort-header class="mat-header-cell-start" sortActionDescription="Ordenar por título"> Motivo  </th>
                    <td mat-cell *matCellDef="let task" class="description-cell"> {{task.title}}</td>
                </ng-container>
    
                <ng-container matColumnDef="completed">
                    <th mat-header-cell *matHeaderCellDef  mat-sort-header class="mat-header-cell-start" sortActionDescription="Ordenar por estado"> Estado  </th>
                    <td mat-cell *matCellDef="let task" class="text-center">
                        <select 
                        name="selectTaskStatus"
                        [(ngModel)]="task.completed"
                        (change)="changeTaskStatus($event,task)" 
                        [ngClass]="{'bg-secondary':task.completed==-1,'bg-primary':task.completed==1,'bg-danger':task.completed==0,'border-primary':task.completed==1,'border-danger':task.completed==0,'border-secondary':task.completed==-1}" >
                        <option  value="0" [selected]="!task.completed">En proceso</option>
                        <option  value="1" [selected]="task.completed">Finalizada</option>
                        <option  value="-1" [selected]="task.completed">Vencida</option>
                    </select>
                    </td>
                </ng-container>
    
                    <ng-container matColumnDef="actions">
                        <th mat-header-cell *matHeaderCellDef > Acciones </th>
                        <td mat-cell *matCellDef="let task">
                            <div class="actions-container">
    
                                <button  matTooltip="Agregar comentario" (click)="openAddComment(task)"><i class="fa-regular fa-comment"></i></button>
                                <button matTooltip="Editar tarea" (click)="openUpdateTaskModal(task)"><i
                                        class="fa-solid fa-pen-to-square"></i></button>
                                <button matTooltip="Eliminar tarea" (click)="deleteTask(task.id)"><i
                                        class="fa-solid fa-trash-can"></i></button>
                                <button matTooltip="ver tarea" (click)="openSeeTaskModal(task)"><i class="fa-solid fa-list-ul"></i></button>
                            </div>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell empty py-1" [attr.colspan]="displayedColumns.length">Sin datos</td>
                    </tr>
                </table>
              </div>
                <mat-paginator class=" animate__animated animate__fadeIn" [length]="lengthPage"
                [pageSize]="pageSize"
                [pageIndex]="currentPage"
                [pageSizeOptions]="pageSizeOptions"
                (page)="getTasks(currentPage+1)"
                matTooltipPosition="right"
                aria-label="Selecciona página">
                </mat-paginator>
          </ng-container>
          <div class="form-box__content-section animate__animated animate__fadeIn" *ngIf="taskView =='calendar'" >
            <div class="container-fullcalendar">
                <full-calendar class="animate__animated animate__fadeIn" [options]="calendarOptions" ></full-calendar>
            </div>
        </div>
        </div>
    </div>
    <div class="loader" *ngIf="loadings['modalView']">
        <img src="../../../assets/loader.svg">
    </div>
</section>

<section class="form-box-container animate__animated animate__fadeIn"  *ngIf="taskModal">
    <section class="form-box-container__header">
        <h2 class="form-box-container__header-title"><span class="icon"><i class="fa-solid fa-list-check"></i></span>{{isUpdateTask ? 'Actualizar tarea' : (!isUpdateTask && !isSeeTask ? 'Crear tarea' : (!isUpdateTask && isSeeTask ? 'Vista de tarea' : '') )}} </h2>
        <div  class="form-box-container__header-options">                     
            <div class="form-box-container-close animate__animated animate__fadeInDown" role="button"(click)="closeTaskModal()"></div>
        </div>
    </section>
    <div class="form-box two-panel two-panel--automatic-orientation flex-wrap animate__animated animate__fadeIn animate__animated animate__fadeIn">
  
        <div class="form-box__content form-box__content-fill form-box__content-flex-basis-medium transparent-scroll-vertical order-mobile-first">
            
            <div class="form-box__content-inputs">
                <div class="form-box__content-group-fields">
                    <mat-form-field id="deviceList" class="fill" appearance="fill"matTooltip="Selecciona el dispositivo">
                        <mat-label>Dispositivo</mat-label>
                        
                        <mat-select (closed)="clearSearchDeviceImei()" [(ngModel)]="newTask.device_id"name="device" placeholder="Buscar..." (selectionChange)="newTask.is_new_device_id=true">
                          <mat-option>
                            <ngx-mat-select-search [noEntriesFoundLabel]="'Sin resultados'" placeholderLabel="Buscar dispositivo..." [(ngModel)]="deviceImeiToSearchInSelect" name="deviceToSearch" (keyup)="searchDeviceImei()">
                                <mat-icon (click)="clearSearchDeviceImei()" ngxMatSelectSearchClear>close</mat-icon>
                            </ngx-mat-select-search>
                          </mat-option>
                          <mat-option *ngFor="let device of imeiListFilter" [value]="device.id" color="primary">
                            {{device.imei}} <small *ngIf="!utils.isNullOrEmpty(device.plate_number)">{{" - "+device.plate_number}}</small>                   
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                      <div class="fill d-flex justify-content-center align-items-center gutter-1">
                        <mat-form-field id="deviceList" class="fill" appearance="fill"matTooltip="Selecciona al encargado de realizar la tarea" matTooltipPosition="right">
                            <mat-label>Encargado</mat-label>
                            <mat-select (closed)="clearSearchEmployee()" [(ngModel)]="newTask.employee_id"name="responsible_user" placeholder="Buscar...">
                              <mat-option>
                                <ngx-mat-select-search [noEntriesFoundLabel]="'Sin resultados'" placeholderLabel="Buscar encargado..." [(ngModel)]="employeeToSearchInSelect" name="employeeToSearch" (keyup)="searchEmployee()">
                                    <mat-icon (click)="clearSearchEmployee()" ngxMatSelectSearchClear>close</mat-icon>
                                </ngx-mat-select-search>
                              </mat-option>
                              <mat-option *ngFor="let employee of employeesListFilter" [value]="employee.id" color="primary">
                                {{employee.name}}                   
                              </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <button class="btn btn-primary"matTooltip="Administrar lista de encargados"*ngIf="!isSeeTask" (click)="openEmployeesAdminModal()"><i class="fa-solid fa-plus"></i></button>
                      </div>

                      <div class="fill">
                        <mat-slide-toggle
                        name="taskAllDay"
                        color="primary"
                        [disabled]="isSeeTask"
                        [(ngModel)]="newTask.all_day">
                        Tarea sin rango de fecha</mat-slide-toggle>
                      </div>

                      <mat-form-field appearance="fill" class="fill" *ngIf="!newTask.all_day">
                        <mat-label>Fecha de inicio</mat-label>
                        <input matInput [disabled]="isSeeTask" name="start_date" type="datetime-local"[(ngModel)]="newTask.start_date">
                    </mat-form-field>
                    <mat-form-field appearance="fill" class="fill">
                        <mat-label>Fecha de vencimiento</mat-label>
                        <input matInput [value]="utils.getNowDate(newTask.expiration_date)<utils.getNowDate(newTask.start_date)?newTask.start_date:newTask.expiration_date" id="inputexpirationDate"[disabled]="isSeeTask" name="expiration_date" type="datetime-local"[(ngModel)]="newTask.expiration_date" >
                    </mat-form-field>
                    <div class="fill">
                        <mat-slide-toggle
                        color="primary"
                        name="notify"
                        [disabled]="isSeeTask"
                        [(ngModel)]="newTask.notify">
                        Notificar al encargado</mat-slide-toggle>
                    </div>
                    <div class="fill">
                        <mat-checkbox  name="reminder" color="primary" matTooltip="Enviar recordatorio antes del inicio de la tarea" matTooltipPosition="right" (change)="toggleinputReminder($event)" [(ngModel)]="newTask.reminder">Recordatorio</mat-checkbox>                    
                    </div>
                    <mat-form-field appearance="fill">
                        <mat-label>Cantidad de tiempo</mat-label>
                        <input matInput [(ngModel)]="newTask.reminder_time" matTooltip="indica la cantidad con incrementos de {{newTask.step_to_reminder_time}}" [disabled]="!newTask.reminder" min="0" [max]="newTask.max_reminder_time" [step]="newTask.step_to_reminder_time" name="reminderTime" type="number">
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                        <mat-label>Unidad de tiempo</mat-label>
                        <mat-select name="reminderTimeUnit"[disabled]="!newTask.reminder" [(ngModel)]="newTask.reminder_time_unit" (selectionChange)="setStepForReminderTime($event.value)">
                          <mat-option *ngFor="let unit of reminderTimeUnitList" [value]="unit.name">
                            {{unit.label + ' antes'}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                </div>
            </div>
        </div>
        <div class="pt-1 form-box__content form-box__content-shadow">
            <div class="form-box__content-inputs">
                <div class="form-box__content-group-fields">
                    <mat-form-field appearance="fill" class="fill">
                        <mat-label>Título</mat-label>
                        <input matInput [disabled]="isSeeTask" name="title" type="text"  [(ngModel)]="newTask.title" id="inputTaskTitle">
                    </mat-form-field>

                    <mat-form-field appearance="fill" class="fill">
                        <mat-label>Descripción</mat-label>
                        <textarea matInput  [disabled]="isSeeTask" name="description" [(ngModel)]="newTask.description" id="inputdescription"></textarea>
                    </mat-form-field>
                </div>
            </div>
            <div  class="form-box__content-inputs" *ngIf="isUpdateTask || isSeeTask">
        
                <div class="form-box__content-group-fields form-box__content-shadow py-1">
                    <mat-form-field appearance="fill">
                        <mat-label>Comentario</mat-label>
                        <textarea matInput name="comment" [(ngModel)]="newTask.comment"></textarea>
                    </mat-form-field>
                    <div class="d-flex">
                      <button type="submit" class="btn btn-outline-primary mb-1"(click)="addComment()" >Agregar</button>
                    </div>
                    <div class="comments p-1">
                      <fieldset class="p-1 comment-thread"> 
                          <legend>Hilo de comentarios</legend>
                          <div class="comments-list">
                              <div class="comment" *ngFor="let comment of newTask.comments">
                                  <span class="comment__head">
                                      <i class="fa-regular fa-message"></i>
                                      <time datetime="" class="badge text-bg-secondary badge--small">{{comment.created_at |date:"medium"}}</time>
                                  </span>
                                  <div class="comment__text">
                                      {{comment.description}}
                                  </div>
                              </div>
                          </div>
                      </fieldset>
                  </div>
                </div>
            </div>
            <div class="form-box__content-actions">
                <button type="submit" *ngIf="isUpdateTask" class="btn btn-primary"(click)="adminTask(true)" [disabled]="loading" >Actualizar</button>
                <button type="submit"  *ngIf="!isUpdateTask && !isSeeTask " class="btn btn-primary" (click)="adminTask(false)" [disabled]="loading">Registrar</button>
                <button type="submit"  *ngIf="!isUpdateTask && isSeeTask " class="btn btn-primary" (click)="isSeeTask=false;isUpdateTask=true">Editar</button>
                <button class="btn btn-secondary"  (click)="closeTaskModal()">{{isSeeTask?'Salir':'Cancelar'}}</button>
            </div>
        </div>
    </div>
    <div class="loader" *ngIf="loading">
        <img src="../../../assets/loader.svg">
    </div>
</section>