import { Injectable } from "@angular/core";
import { ApiService } from "src/app/core/api/api.service";
import { UtilsService } from "src/app/core/utils/utils.service";
import { ValidationService } from "src/app/core/validation/validation.service";
import { IDeviceBasicData } from "src/app/shared/models/interfaces/idevice.model";
import { IResponseApi } from "src/app/shared/models/interfaces/iresponse-api.model";

/**REUBICAR SERVICIO EN CORE PRINCIPAL */
@Injectable()
export class UserDriverDataService {

  constructor(
    public utils: UtilsService,
    private api: ApiService,
    public validationService: ValidationService
  ) { }

    /**
   * @description Obtiene el vehículo que se encuentra el conductor ocupando actualmente
   */
    async getDrivenVehicle(userHash:string):Promise<IResponseApi & {data?:IDeviceBasicData}>{
      try{
        let data:string = "?action=getDriverCurrentDevice&user_api_hash="+userHash;
        let response:unknown = await this.api.getData("device",data,true);
        return this.validationService.isResponseApi(response) ? response : {status:0};
      }catch(error:any){
        this.utils.showMsg("","Ha surgido un error mientras se intentaba obtener el dispositivo conducido por el usuario");
        return {status:0};
      }
    }
}