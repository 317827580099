<h2 mat-dialog-title>Comentarios</h2>
<mat-dialog-content class="mat-typography position-relative ">
    <div class="container position-relative container-comments animate__animated animate__fadeIn" >
        <div class="comments p-1" >
            <div class="option position-sticky top-0">
                <div class="input-group--textarea ">
                    <label class="form-label" for="inputComment" class="label">Comentario</label>
                    <textarea class="form-control" name="comment" id="inputComment" [(ngModel)]="newTask.comment"></textarea>
                </div>
                <button type="submit" class="btn btn-primary mb-1"(click)="addComment()" >Agregar</button>
            </div>
            <fieldset class="p-1 comment-thread "> 
                <legend>Hilo de comentarios</legend>
                <div class="comments-list overflow-auto">
                    <div class="comment" *ngFor="let comment of newTask.comments">
                        <span class="comment__head">
                            <i class="fa-regular fa-message"></i>
                            <time datetime="" class="badge text-bg-secondary badge--small">{{comment.created_at |date:"medium"}}</time>
                        </span>
                        <div class="comment__text">
                            {{comment.description}}
                        </div>
                    </div>
                </div>
            </fieldset>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]="updateTasks" class="btn btn-outline-secondary" style="margin-right: 0.2rem;">Salir</button>
</mat-dialog-actions>