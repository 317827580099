<section class="form-box-container animate__animated animate__fadeIn">
    <section class="form-box-container__header">
        <span class="form-box-container__header-back" (click)="closeComponent()"><span class="description">Volver</span> </span>
        <h2 class="form-box-container__header-title"><span class="icon"><i class="fa-solid fa-rss"></i></span>Operatividad de dispositivos</h2>
        <div  class="form-box-container__header-options">                     
            <button class="btn btn-primary" matTooltip="Filtra por cantidad de tiempo" matTooltipPosition="left"
            [matMenuTriggerFor]="timeFilterMenu"><i class="fa-regular fa-clock fa-sm"></i><span  class="description"> Opciones</span></button>
            <mat-menu #timeFilterMenu="matMenu" >
                <mat-form-field (click)="$event.stopPropagation()" class="example-full-width" appearance="fill" >
                    <mat-label><i class="fa-solid fa-power-off text-danger"></i> Última conexión desde hace</mat-label>
                    <input matInput (click)="$event.stopPropagation()" type="number" pattern="^[0-9]$" min="1" [(ngModel)]="timeSelectedAmount" (input)="!utils.isOnlyNumbers(timeSelectedAmount)?$event.preventDefault():''" maxlength="4">
                </mat-form-field>
                <mat-form-field (click)="$event.stopPropagation()" appearance="fill">
                    <mat-label><i class="fa-solid fa-timeline"></i> Unidad de tiempo</mat-label>
                    <mat-select (click)="$event.stopPropagation()" [(ngModel)]="selectedTimeUnit">
                        <mat-option *ngFor="let unit of timeUnits" [value]="unit.value">{{unit.label}}</mat-option>
                    </mat-select>
                </mat-form-field>   
                <div class="mat-menu-content__actions" (click)="$event.stopPropagation()"><button mat-button color="primary"  (click)="applyFilter('quantity_time')">Buscar</button></div>
            </mat-menu>
            <button class="btn btn-outline-primary border-primary overflow-hidden"  matTooltip="Filtrar por rango de fecha">
                <mat-datepicker-toggle style="display:inline-block; font-size: 0.5rem;opacity:0;position:absolute;top:0;left:0;right:0;bottom:0;" matIconSuffix [for]="expirationRangePicker"></mat-datepicker-toggle>
                <i class="fa-solid fa-calendar-week fa-sm"></i> <span  class="description"> Fecha</span>
                <mat-form-field class="animate__animated animate__fadeIn " style="width: 0;height: 0;position:absolute;opacity: 0;"  appearance="fill">
                    <mat-label>Ingrese un rango de fecha</mat-label>

                    <mat-date-range-input  class="d-none"  disabled [formGroup]="dateRangeToConsult" [rangePicker]="expirationRangePicker">
                    <input formControlName="start"   matStartDate placeholder="yyyy-mm-dd">
                    <input formControlName="end" matEndDate placeholder="yyyy-mm-dd">
                    </mat-date-range-input>
                    
                    <mat-date-range-picker class="d-none" #expirationRangePicker disabled="false">
                    <mat-date-range-picker-actions>
                        <button class="btn btn-outline-secondary" style="margin:0.1rem;" mat-button matDateRangePickerCancel>Cancelar</button>
                        <button class="btn btn-primary" mat-raised-button color="primary" (click)="applyFilter('date_range')"  matDateRangePickerApply>Aceptar</button>
                    </mat-date-range-picker-actions>
                    </mat-date-range-picker>
                </mat-form-field>
            </button>
            <button matTooltip="Exportar datos" class="btn btn-outline-secondary" (click)="exportFile()"><i
                class="fa-solid fa-file-export fa-sm"></i><span class="description"> Exportar</span></button>
            <div class="search">
                <input id="inputSearch" type="search" placeholder="Buscar" (input)="searchDevices()" [(ngModel)]="searchValue"><!--searchImei-->
                <label for="inputsearch"><i class="fa-solid fa-magnifying-glass fa-sm"></i></label>
            </div>
        </div>
    </section>
    <div class="form-box animate__animated animate__fadeIn">
      <div class="form-box__content form-box__content-h-auto">
        <div class="w100 overflow-auto">
          <table mat-table matSort (matSortChange)="sortData($event)" [dataSource]="sortedDevicesData">

            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Ordenar por número de placa"> Placa </th>
              <td mat-cell *matCellDef="let device"> {{device.name}} </td>
            </ng-container>
            <ng-container matColumnDef="imei">
              <th mat-header-cell *matHeaderCellDef  mat-sort-header sortActionDescription="Ordenar por Imei"> Imei </th>
              <td mat-cell *matCellDef="let device"> {{device.imei}} </td>
            </ng-container>
            <ng-container matColumnDef="sim_number">
              <th mat-header-cell *matHeaderCellDef  mat-sort-header sortActionDescription="Ordenar por Nro. SIM"> Nro. SIM </th>
              <td mat-cell *matCellDef="let device"> {{device.sim_number}} </td>
            </ng-container>
            <ng-container matColumnDef="server_time">
              <th mat-header-cell *matHeaderCellDef  mat-sort-header sortActionDescription="Ordenar por última conexión"> Última conexión </th>
              <td mat-cell *matCellDef="let device"> {{device.server_time}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            
            <tr mat-footer-row *matFooterRowDef="['footer'];sticky:true"></tr> 
            <ng-container matColumnDef="footer">
              <td mat-footer-cell *matFooterCellDef [colSpan]="displayedColumns.length"  class="text-small">
                Dispositivos con última conexión
                {{filterBy==filterOption.date_range?(
                    ' en el rango de fecha del '+footerData.start_date +' al '+footerData.end_date 
                ):(' desde hace ' + footerData.quantity + ' ' + footerData.time_unit)}}
              </td>
          </ng-container>
          </table>
        </div>
        <mat-paginator [length]="lengthPage"
        [pageSize]="pageSize"
        [pageIndex]="currentPage"
        [pageSizeOptions]="pageSizeOptions"
        (page)="paginator($event)"
        matTooltipPosition="right"
        aria-label="Selecciona página">
        </mat-paginator>
      </div>
    </div>
    <div class="loader" *ngIf="loading">
        <img src="../../../assets/loader.svg">
    </div>
  </section>
