
<h2 mat-dialog-title>{{paidDevices.status?"Estamos confirmando la transacción":"Dispositivos a pagar"}}</h2>
<mat-dialog-content class="mat-typography position-relative ">
    <ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#fff" type = "cube-transition" [fullScreen] = "true"><p style="color: white" > Realizando pago... </p></ngx-spinner>
    
<section class="form-box-container animate__animated animate__fadeIn">

    <div class="form-box two-panel two-panel--automatic-orientation flex-wrap animate__animated animate__fadeIn" *ngIf="!paidDevices.status">
      <div class="form-box__content form-box__content-shadow form-box__content--vertical-centered transparent-scroll-vertical">
        
        <div class="w100 overflow-auto">
            <table mat-table [dataSource]="devices">
      
              <ng-container matColumnDef="index">
                <th mat-header-cell *matHeaderCellDef > Item </th>
                <td mat-cell *matCellDef="let device;index as counter" > {{ counter + 1}} </td>
              </ng-container>
              <ng-container matColumnDef="imei">
                <th mat-header-cell *matHeaderCellDef > Imei </th>
                <td mat-cell *matCellDef="let device" > {{ device.imei}} </td>
              </ng-container>
              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef > Nombre </th>
                <td mat-cell *matCellDef="let device" > {{ device.name}} </td>
              </ng-container>
              <ng-container matColumnDef="plate_number">
                <th mat-header-cell *matHeaderCellDef > Placa </th>
                <td mat-cell *matCellDef="let device" > {{ device.plate_number}} </td>
              </ng-container>
              <ng-container matColumnDef="expiration_date">
                <th mat-header-cell *matHeaderCellDef > Placa </th>
                <td mat-cell *matCellDef="let device" > {{ device.expiration_date | date:"medium"}} </td>
              </ng-container>
              <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef > Acciones </th>
                <td mat-cell *matCellDef="let device" >
                    <div class="actions-container">
                        <button matTooltip="Descartar de pagos" class="btn btn-primary" (click)="removeFromTheListToPay(device)" matTooltipPosition="left" matTooltip="Eliminar de la lista de pagos"><i class="fa-solid fa-trash"></i></button>
                    </div>
                </td>
              </ng-container>
      
              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell empty py-1" [attr.colspan]="displayedColumnsInDataSummary.length">Sin datos</td>
            </tr>
              <tr mat-header-row *matHeaderRowDef="displayedColumnsInDataSummary; sticky: true"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumnsInDataSummary;"  ></tr>
            
            </table>
          </div>

      </div>
      <div class="form-box__content form-box__content-auto form-box__content-flex-basis-medium transparent-scroll-vertical">

        <div class="form-box__content-section">
            <mat-accordion>
                <mat-expansion-panel hideToggle expanded>
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      Detalles
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                    <div class="payment-details" *ngIf="cartSummaryExists()">                            
                        <ng-template #cartSummary [ngTemplateOutlet]="cartSummary"
                            let-cartSummary [ngTemplateOutletContext]="{$implicit:getCartSummary()}">
                            <span class="payment-details__item">Producto: {{cartSummary.name}}</span>
                            <span class="payment-details__item">Cantidad: {{cartSummary.quantity}}</span>
                            <span class="payment-details__item">Precio unitario: {{cartSummary.unit_amount.value | currency:cartSummary.unit_amount.currency_code}}</span>
                            <span class="payment-details__item" *ngIf="isCurrencyUSD() || isCurrencyCOP()">Comisión ({{(this.isCurrencyUSD()?this.paypalCommission:(this.isCurrencyCOP()?this.refacilCommission:0))|percent}}): {{ cartSummary.commission | currency:cartSummary.unit_amount.currency_code}}</span>
                            <span class="payment-details__item text-center important">Total: {{(getCartSummary().unit_amount.value*cartSummary.quantity)+cartSummary.commission| currency:cartSummary.unit_amount.currency_code}}</span>
                        </ng-template>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel (opened)="panelOpenState = true"
                                     (closed)="panelOpenState = false">
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      Opciones de pago
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                    <div class="form-box__content-section" >
                        <div  *ngIf="isCurrencyUSD()">
                            <ngx-paypal [config]="payPalConfig"></ngx-paypal>
                        </div>
                        <div class="d-flex justify-content-center align-items-center" *ngIf="isCurrencyCOP()">
                            <button class="btn  btn-primary btn-refacil"matTooltip="Opciones de pago nacionales (Colombia)" (click)="processNationalPayment()"> <i class="fa-solid fa-money-bill" ></i> Pagos nacionales</button>
                        </div>
                    </div>
                    
                    <div *ngIf="!isCurrencyUSD() && !isCurrencyCOP()" class="empty payment-options__item">No hay opciones disponibles</div>
                </mat-expansion-panel>
              </mat-accordion>
        </div>
      </div>
    </div>
    <div class="payment-details" *ngIf="cartSummaryExists() && paidDevices.status">
        <ng-template #cartSummaryItem [ngTemplateOutlet]="cartSummaryItem"
            let-cartSummaryItem [ngTemplateOutletContext]="{$implicit:getCartSummary()}">
            <span class="payment-details__item">Producto: {{cartSummaryItem.name}}</span>
            <span class="payment-details__item">Cantidad: {{cartSummaryItem.quantity}}</span>
            <span class="payment-details__item">Precio unitario: {{cartSummaryItem.unit_amount.value | currency:cartSummaryItem.unit_amount.currency_code}}</span>
            <span class="payment-details__item" *ngIf="isCurrencyUSD() || isCurrencyCOP()">Comisión ({{(this.isCurrencyUSD()?this.paypalCommission:(this.isCurrencyCOP()?this.refacilCommission:0))|percent}}): {{ cartSummaryItem.commission | currency:cartSummaryItem.unit_amount.currency_code}}</span>
            <span class="payment-details__item text-center important">Total: {{(getCartSummary().unit_amount.value*cartSummaryItem.quantity)+cartSummaryItem.commission| currency:cartSummaryItem.unit_amount.currency_code}}</span>
        </ng-template>
    </div>
    <div class="loader" *ngIf="loading">
        <img src="../../../assets/loader.svg">
    </div>
  </section>

</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]="paidDevices"  class="btn btn-outline-secondary" style="margin-right: 0.2rem;">{{paidDevices.status?"Cerrar":"Cancelar"}}</button>
</mat-dialog-actions>