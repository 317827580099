import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { ApiService } from 'src/app/core/api/api.service';
import { DataService } from 'src/app/core/data/data.service';
import { MessageBoxService } from 'src/app/core/message-box/message-box.service';
import { ObjectInitializationService } from 'src/app/core/object-initialization/object-initialization.service';
import { UtilsService } from 'src/app/core/utils/utils.service';
import { ValidationService } from 'src/app/core/validation/validation.service';
import { IApiRequestData, IPagination, ISort } from 'src/app/shared/models/interfaces/iapi-request-data.model';
import { IUserSessionData } from 'src/app/shared/models/interfaces/iuser-session-data.model';
import { IUserMessagingServiceApiPhoneLine, UserMessagingServicePhoneLineActivationStatus, UserMessagingServicePhoneLineConnectionValues } from '../../../../../shared/models/interfaces/imessaging-service-api.model';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ApiEndpointSgc } from 'src/app/shared/enums/api-endpoint-sgc.enum';
import { RequestMethodHTTP, swalIcon } from 'src/app/shared/enums/common-enums.enum';
import { IResponseApi } from 'src/app/shared/models/interfaces/iresponse-api.model';
import { window } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { PhoneLineQrCodeScannerComponent } from '../phone-line-qr-code-scanner/phone-line-qr-code-scanner.component';
import { MessagingServiceApiPhoneLineService } from '../services/messaging-service-api-phone-line/messaging-service-api-phone-line.service';
type TableColumnName = Pick<IUserMessagingServiceApiPhoneLine, "connected"|"name" | "available_message_count" |"phone_number" > & {"actions" :  boolean, "connect": boolean, "disconnect": boolean};
interface IConnectionStatus{
  [UserMessagingServicePhoneLineConnectionValues.CONNECTED]:{VALUE: UserMessagingServicePhoneLineConnectionValues.CONNECTED, LABEL: string},
  [UserMessagingServicePhoneLineConnectionValues.DISCONNECTED]:{VALUE: UserMessagingServicePhoneLineConnectionValues.DISCONNECTED, LABEL: string}
}
interface IActivationStatus{
  [UserMessagingServicePhoneLineActivationStatus.ACTIVE] :{VALUE:UserMessagingServicePhoneLineActivationStatus.ACTIVE, LABEL:string},
  [UserMessagingServicePhoneLineActivationStatus.INACTIVE] :{VALUE:UserMessagingServicePhoneLineActivationStatus.INACTIVE, LABEL:string},
  [UserMessagingServicePhoneLineActivationStatus.PENDING] :{VALUE:UserMessagingServicePhoneLineActivationStatus.PENDING, LABEL:string}
}
@Component({
  selector: 'app-messaging-service-phone-line-list',
  templateUrl: './messaging-service-phone-line-list.component.html',
  styleUrls: ['./messaging-service-phone-line-list.component.scss']
})
export class MessagingServicePhoneLineListComponent  implements OnInit{

  user:IUserSessionData;
  loading: boolean = false;

  phoneLineConnectionValues: typeof UserMessagingServicePhoneLineConnectionValues = UserMessagingServicePhoneLineConnectionValues;
  phoneLineActivationStatus: typeof UserMessagingServicePhoneLineActivationStatus = UserMessagingServicePhoneLineActivationStatus;
  readonly CONNECTION_STATUS:IConnectionStatus = {
    [UserMessagingServicePhoneLineConnectionValues.CONNECTED]: {VALUE:UserMessagingServicePhoneLineConnectionValues.CONNECTED, LABEL:"conectada"}, 
    [UserMessagingServicePhoneLineConnectionValues.DISCONNECTED]: {VALUE:UserMessagingServicePhoneLineConnectionValues.DISCONNECTED, LABEL:"desconectada"}
  };
  readonly ACTIVATION_STATUS: IActivationStatus = {
    [UserMessagingServicePhoneLineActivationStatus.ACTIVE] :{VALUE:UserMessagingServicePhoneLineActivationStatus.ACTIVE, LABEL:"Activo"},
    [UserMessagingServicePhoneLineActivationStatus.INACTIVE] :{VALUE:UserMessagingServicePhoneLineActivationStatus.INACTIVE, LABEL:"Inactivo"},
    [UserMessagingServicePhoneLineActivationStatus.PENDING] :{VALUE:UserMessagingServicePhoneLineActivationStatus.PENDING, LABEL:"Pendiente"}  
  };
  
  displayedColumns: (keyof TableColumnName)[]= ["name","phone_number","connected","connect","disconnect"];
  sort: ISort;
  pagination: IPagination;
  searchValue: string = "";
  /**
   * La lista de lineas moviles registradas por el cliente en servicio de mensajeria
   */
  phoneLineList:IUserMessagingServiceApiPhoneLine[] = [];
  @Output() close: EventEmitter<boolean> = new EventEmitter<boolean>();
  constructor(
    private api: ApiService,
    public dataService: DataService,
    public utils:UtilsService,
    public messageBox: MessageBoxService,
    private objectInitializationService: ObjectInitializationService,
    private validationService: ValidationService,
    public dialog: MatDialog,
    public messagingServiceApiPhoneLineService: MessagingServiceApiPhoneLineService
  ){
    this.pagination = this.objectInitializationService.initializeIPagination(this.dataService.PAGE_SIZE_OPTION);
    this.pagination.page_size = this.dataService.PAGE_SIZE_OPTION[0];
    this.sort = this.objectInitializationService.initializeISort();
    this.user = this.dataService.getData("user") as IUserSessionData;
  }
  ngOnInit(): void {
    this.getPhoneLines();
  }
  sortData(sort: Sort) {
    this.sort.direction = sort.direction;
    this.sort.by = sort.active;
    this.getPhoneLines();
  }
  paginator(event:PageEvent):void{
    this.pagination.current_page = event.pageIndex;    
    this.pagination.page_size = event.pageSize;
    this.getPhoneLines();
  }
  /**
   * @description Obtiene la lista de lineas moviles registradas por el usuario
   */
  getPhoneLines(){
    this.loading = true;
    this.phoneLineList = [];
    this.pagination.length_page = 0;
    let data:string =  this.api.getCommonQueryParameters("get",this.user.hash,this.searchValue,this.pagination.current_page,this.pagination.page_size,this.sort.by!,this.sort.direction!);
    this.api.getData(ApiEndpointSgc.USER_MESSAGING_SERVICE_API_PHONE_LINE,data,true).then((data: unknown)=>{
      if(this.validationService.isResponseApi(data) && data.status == 1){
        this.pagination.length_page = data.total ?? 0;
        this.phoneLineList = data.data as IUserMessagingServiceApiPhoneLine[]; 
        console.log(data);
      }
    }).catch((error:unknown)=>{
      this.utils.showResultRequest("error","Información",this.api.getDefaultMessage("la lista de automatizaciones",false,false,"GET"));
    }).finally(()=>this.utils.hideLoading(()=>this.loading = false));
  }
  requestQrCode(phoneLine: IUserMessagingServiceApiPhoneLine){
    console.log("realizar escaeno de QR");
    const dialogRef = this.dialog.open(PhoneLineQrCodeScannerComponent,{data:{userMessagingServicePhoneLine: phoneLine},panelClass:'mat-dialog-width-responsive'});
    dialogRef.afterClosed().subscribe((response:unknown) => {
      console.log(response);
      if(typeof response =="boolean" && response)
        this.getPhoneLines();
    }); 
  }
  disconnectPhoneLine(phoneLine: IUserMessagingServiceApiPhoneLine){
    if(phoneLine.user_messaging_service_api_credential_id == null || phoneLine.connected == this.phoneLineConnectionValues.DISCONNECTED)
      return;
    this.utils.showConfirm("¿Desea desconectar su cuenta de WhatsApp?", "Desconectar su cuenta de WhatsApp impedirá el envío de mensajes a través de nuestro servicio.", 
    "Desconectar", "Cancelar",swalIcon.INFO).then(async () => {
      this.loading = true;
      let response: IResponseApi | null | unknown = null;
      try {
        let dataToSend: IApiRequestData = { user_api_hash: this.user.hash, action: "logout", id: phoneLine.id};
        response = await this.api.createData(dataToSend, ApiEndpointSgc.USER_MESSAGING_SERVICE_API_PHONE_LINE, true);   
      } catch (error: unknown) {
        this.utils.showResultRequest("error","Información",this.api.getDefaultMessage("el estado de conexión de la cuenta",true, false, RequestMethodHTTP.POST));
      } finally {
        if (this.validationService.isResponseApi(response)) {
          this.messageBox.openSnackBar( response.message ?? "","Aceptar");
          if(response.status == 1)
            phoneLine.connected = this.phoneLineConnectionValues.DISCONNECTED;
        }
        this.utils.hideLoading(() => this.loading = false);
      }
    }).catch(() => { });
  }

  async updatePhoneLine(phoneLine:IUserMessagingServiceApiPhoneLine){
    let requestResponse: IResponseApi | null | unknown = null;
    phoneLine.name = phoneLine.name.trim();
    if(phoneLine.name == '')
      return;
    try {
        let dataToSend: IApiRequestData & Pick<IUserMessagingServiceApiPhoneLine,"name" | "id">= { user_api_hash: this.user.hash, action: "update", id:phoneLine.id,name: phoneLine.name};
        requestResponse = await this.api.createData(dataToSend, ApiEndpointSgc.USER_MESSAGING_SERVICE_API_PHONE_LINE, true);   
    } catch (error: unknown) {
      console.log(error);
      this.utils.showResultRequest("error","Información",this.api.getDefaultMessage("cuenta de linea movil de mensajería",true, false, RequestMethodHTTP.POST));
    } finally {
      console.log(requestResponse);
      if (this.validationService.isResponseApi(requestResponse))
        this.messageBox.openSnackBar( requestResponse.message ?? "","Aceptar");      
      this.utils.hideLoading(() => this.loading = false);
    }
  }
  closeComponent(){
    this.close.emit(true);
  }
}
