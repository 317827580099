
export class SgcPlatformPlanPermissions {

    constructor(){}
    newPlatformPlan: any = {
        id: 0,
        name: "",
        description: "",
        updated_at: '',
        modules: []
      };
      permissionsForSelectedModule:any={//permisos del modulo nuevo a agregar al plan
        id:null,
        platform_plan_id:null,
        module_operation_id:null,
        module_id:null
      };
      newPlatformPlanCopy:any={};
      newPlatformPlanCopyEmpty= JSON.parse(JSON.stringify(this.newPlatformPlan));
      isNewPlatformPlanModified:boolean=false;//indica cuando se haya realizado alguna modificacion a los datos del "plan de plataforma" cargado para modificar
      platformPlans:any=[];//alamcena la lista de planes creados
      modules: any = [];//modulos disponibles en la plataforma
      filteredModules:any=[];//almacena los modules filtrados exluyendo los que ya han sido agrgados al usuario
      modalEditPlatformPlan:boolean=false;
      isCreatePlatformPlan:boolean=false;
    
      selectedModule:any={};//almacena el modulo que ha sido seleccionado en el componente que carga los modulos
      modulePermissionsToAddToPlatformPlan:any=[];//almacena los permisos seleccionados del select de permisos ya listos para agregar al plan
      selectedModulePermissions:any=[];//almcena la estrcutura de los permisos del modulo que se seleccione en el elemento Select de "modulo"
      displayedColumns = ["module_name","total_permissions","permissions","actions"];

      /** Indica si el usuario tiene un plan asignado */
      userHasAPlatformPlan:boolean=false;
      isValidmodulePermissionsToAddToPlatformPlan(selectedModule:any,modulePermissionsToAddToPlatformPlan:any){
        if(Object.entries(selectedModule).length<1)
          return {status:false,message:"No ha seleccionado el modulo del cual establecerá permisos"};
        if(modulePermissionsToAddToPlatformPlan.length==0)
          return {status:false,message:"No ha indicado al menos un permiso sobre el modulo"};
        return {status:true};
      }
      prepareModulePermissionsToPlatformPlan(selectedModule:any,selectedModulePermissions:any,modulePermissionsToAddToPlatformPlan:any,newPlatformPlanId?:number,userId?:number){
        if(typeof selectedModule.operations !="undefined"){
          modulePermissionsToAddToPlatformPlan=[];
          selectedModule.operations.forEach((operation:any) => {
            let permissionToAdd:any = {
                id:null,
                name:operation.name,
                module_id:selectedModule.id,
                module_operation_id:operation.id
            };
            if(typeof operation.action!="undefined")
              permissionToAdd["action"] = operation.action;
            permissionToAdd[typeof userId!="undefined"?"user_id":"platform_plan_id"] = typeof userId!="undefined"?userId:newPlatformPlanId;
            selectedModulePermissions.push(permissionToAdd);
          });
        }
      }
      /**
       * Modifica el newPlatformPlan a modificar sus permisos
       * @param {Object} newPlatformPlan el plan a enviar a modificacion
       * @param {Number} userId  indica el id del usuario al que se enlaza el permiso, opcional. si el valor no se especifica entonces se enlaza al plan de la plataforma
       * @return {void} modifica al objeto newPlatformPlan 
       */
      prepareModulePlanToSend(newPlatformPlanCopy:any,newPlatformPlan:any,userId?:number){
        for(let module of newPlatformPlanCopy.modules){
            let moduleWithPermissionsModifed= newPlatformPlan.modules.find((moduleModified:any)=>moduleModified.module_id==module.module_id);
            for(let permission of module.permissions){
              if(moduleWithPermissionsModifed.permissions.length==0 || moduleWithPermissionsModifed.permissions.every((moduleOperation:any)=>(typeof moduleOperation.module_operation_id!="undefined"?moduleOperation.module_operation_id:moduleOperation.id)!=permission.module_operation_id))
                permission["action"]="delete";
            }
            for(let moduleOperation of moduleWithPermissionsModifed.permissions){
              if(module.permissions.length==0 || module.permissions.every((permission:any)=>permission.module_operation_id!=(typeof moduleOperation.module_operation_id!="undefined"?moduleOperation.module_operation_id:moduleOperation.id))){
                let permissionToAdd:any = {
                    action:"add",
                    module_id:moduleWithPermissionsModifed.module_id,
                    module_operation_id:moduleOperation.id,
                };
                permissionToAdd[typeof userId!="undefined"?"user_id":"platform_plan_id"] = typeof userId!="undefined"?userId:newPlatformPlan.id;
                module.permissions.push(permissionToAdd);
              }
            }
          } 
      }
        /**crea la estructura de permisos para ser agregados al objeto que almacena la edicion de los permisos otorgables por el distribuidoro directamente a permisos para usuarios*/
  structurePermissionsGranted(modules:any,permissionsGrantedToDistributor:any,modulesWithPermissionsGranted:any,distributorPlatoformPlanModules:any,newPlatformPlanCopy:any,newPlatformPlan:any){
    /** Los modulos que tienen permisos el gerente para otorgarlos a sus usuarios (aparte de los que hayan en el plan) */
    let modulesWithPermissionsGrantedArray:any = [];
    modulesWithPermissionsGrantedArray = permissionsGrantedToDistributor.length>0?permissionsGrantedToDistributor.map((item:any)=>item.module_id):[];
    modulesWithPermissionsGrantedArray = new Set(modulesWithPermissionsGrantedArray);
    modulesWithPermissionsGranted = [...modulesWithPermissionsGrantedArray];
    for(let moduleId of modulesWithPermissionsGranted){
      let module = modules.find((module:any)=>module.id == moduleId);
      if(typeof module !="undefined"){
        let newModule:any={module_id:moduleId,module_name:module.name,permissions:[]};
        for(let operation of module.operations){
          let operationIndex = permissionsGrantedToDistributor.findIndex((permissionsGranted:any)=>permissionsGranted.module_id==moduleId && permissionsGranted.module_operation_id==operation.id);
          if(operationIndex!=-1){
            /** Este objeto (id) es parte de manager_assignable_module_operation */
            let permissionToAdd:any = {
              id:permissionsGrantedToDistributor[operationIndex].id,
              module_operation_name:operation.name,
              module_id:moduleId,
              created_by:permissionsGrantedToDistributor[operationIndex].created_by,
              module_operation_id:operation.id,
              user_id:permissionsGrantedToDistributor[operationIndex].user_id
            };
            newModule.permissions.push(permissionToAdd);
          }
        }              
        newPlatformPlanCopy.modules.push(newModule);
        distributorPlatoformPlanModules.push(newModule);
      }
    }
    newPlatformPlan.modules= JSON.parse(JSON.stringify(newPlatformPlanCopy.modules));
  }
      /**
       * retorna si el modulo pertenece al plan de plataforma
       */
      isAPlatformPlanModule(module:any){
        return module.permissions.some((permission:any)=>typeof permission.platform_plan_id !="undefined" && permission.platform_plan_id!==null );
      }

      getModulePermissions(modulePermissions:any,modules:any){
        let response =modules.find((module:any)=>module.id==modulePermissions.module_id).operations??[];
        return response;
      }
      comparePermissionsSelected(operation: any, permission: any){
        return typeof permission.module_operation_id !="undefined" && typeof operation.id != "undefined" && operation.id == permission.module_operation_id;
      }
      isPermissionSelected(operationModule:any,modulePermission:any){
        if(typeof modulePermission.permissions =="undefined" || typeof operationModule.id =="undefined")
          return false;
        return modulePermission.permissions.some((permission:any)=>permission.module_operation_id==operationModule.id);
      }
}