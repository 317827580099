<section class="form-box-container animate__animated animate__fadeIn" *ngIf="viewsAvailable['device_list'].show_view" >
    <section class="form-box-container__header">
        <span class="form-box-container__header-back" (click)="close()"><span class="description">Volver</span> </span>
        <h2 class="form-box-container__header-title"><span class="icon"><i class="fa-solid fa-satellite"></i></span>Transmisión Wialon IPS </h2>
        <div  class="form-box-container__header-options">                     
          <button 
          (click)="updateDevicesSelect()" 
          class="btn btn-primary"><i class="fa-solid fa-file-pen fa-sm"></i> <span class="description"> Actualizar</span></button>

          <button 
          [matMenuTriggerFor]="wialonIpsconfigForm"
          class="btn btn-outline-secondary"> <i class="fa-solid fa-gear fa-sm"></i><span class="description"> Configuración</span></button>

          <div class="search">
            <input id="inputSearch" type="search" placeholder="Buscar" (input)="searchValueInData()"
              [(ngModel)]="searchDevice">
            <label for="inputsearch"><i class="fa-solid fa-magnifying-glass fa-sm"></i></label>
          </div>
        </div>
    </section>
    <div class="form-box animate__animated animate__fadeIn">
  
        <div class="form-box__content form-box__content-h-auto">
          <div class="form-box__content-header form-box__content-header-horizontal p-0 form-box__content-header-align-end">
            <mat-checkbox 
            matTooltip="Incluir dispositivos suscritos" 
            color="primary"
            class="scale-75"
            name="availabilityStatus"
            [value]="deviceSubscriptionStatus.subscribed+''"
            [checked]="isDeviceSubscriptionStatus(deviceSubscriptionStatus.subscribed)"
            (change)="changeDeviceSubscriptionFilter($event)"
            >Suscritos</mat-checkbox>
            <mat-checkbox 
            matTooltip="Incluir dispositivos por suscribir" 
            color="primary"
            class="scale-75"
            name="availabilityStatus"
            [value]="deviceSubscriptionStatus.not_subscribed+''"
            [checked]="isDeviceSubscriptionStatus(deviceSubscriptionStatus.not_subscribed)"
            (change)="changeDeviceSubscriptionFilter($event)"
            >Por suscribir</mat-checkbox>
          </div>
          <div class="w100 overflow-auto">
            <ng-container 
            *ngIf="!canViewGroupedDevices; then deviceTable; else deviceGroupTable"
            ></ng-container>
            


            <!-- Tabla de dispositivos agrupados-->

            <!-- fin Tabla de dispositivos agrupados-->
          </div>
          <mat-paginator [length]="pagination.length_page" [pageSize]="pagination.page_size" [pageIndex]="pagination.current_page"
            [pageSizeOptions]="pagination.page_size_options" (page)="paginator($event)"
            aria-label="Selecciona pagina de la lista de dispositivos">
          </mat-paginator>
              <!--mat-paginator aqui -->
        </div>
    </div>
    <div class="loader" *ngIf="loading">
        <img src="../../../../../assets/loader.svg">
    </div>
  </section>

  <ng-template #deviceGroupTable>
    <table 
    mat-table 
    multiTemplateDataRows 
    matSort 
    (matSortChange)="sortDataDeviceGroup($event)" 
    [dataSource]="deviceGroupList"
    >
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef class="mat-header-center"> 
        <span class="d-flex justify-content-center align-items-center" matTooltip="El indicador de grupo habilitado define que todos los dispositivos de dicho grupo estarán reportando al servicio web">
          <i class="fa-regular fa-circle-question icon"></i>
        </span>
          <!--<mat-slide-toggle
          color="primary"
          (change)="$event ? toggleAllRows() : null"
          [checked]="isAllSelected()"
          [aria-label]="checkboxLabel()">
          </mat-slide-toggle>  -->
      </th>
      <td mat-cell *matCellDef="let row" class="text-center"> 
        <mat-slide-toggle
          [checked]="row.activated == SUBSCRIPTION_STATUSES.SUBSCRIBED"
          color="primary"
          class="z-index-last"
          (change)="updateDeviceGroupStatus($event,row)"
          [aria-label]="(row.activated == SUBSCRIPTION_STATUSES.SUBSCRIBED ? 'Seleccionar' : 'Deseleccionar') + ' opción'">
        </mat-slide-toggle>
      </td>
    </ng-container>
    
    <ng-container matColumnDef="activated">
      <th mat-header-cell *matHeaderCellDef class="mat-header-center" mat-sort-header sortActionDescription="Ordenar por activación"> Activado </th>
      <td mat-cell *matCellDef="let deviceGroup" class="text-center">
        <div class="status complete" *ngIf="deviceGroup.activated == SUBSCRIPTION_STATUSES.SUBSCRIBED " matTooltip="Activo" matTooltipPosition="right"><i class="fa-solid fa-circle-check"></i></div>
        <div class="status incomplete" *ngIf="deviceGroup.activated != SUBSCRIPTION_STATUSES.SUBSCRIBED" matTooltip="Inactivo" matTooltipPosition="right"><i class="fa-solid fa-circle-xmark"></i></div>
                        
      </td>
    </ng-container>

      <ng-container matColumnDef="title">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Ordenar por titulo"> Nombre </th>
        <td mat-cell *matCellDef="let deviceGroup"> {{deviceGroup.title + '(' + (deviceGroup.device_total + ')')}}  </td>
      </ng-container>
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef mat-sort-header disabled class="mat-header-center"> Acciones </th>
        <td mat-cell *matCellDef="let deviceGroup" class="text-center"> 
          <div class="actions-container">
            <button mat-mini-fab (click)="(selectedDeviceGroup = selectedDeviceGroup === deviceGroup ? null : deviceGroup); $event.stopPropagation(); getDeviceList(deviceGroup.id, deviceGroup.is_expanded);" class="custom-action text-primary" aria-label="Ver dispositivos"><mat-icon>{{deviceGroup == selectedDeviceGroup ? 'expand_less' : 'expand_more'}}</mat-icon></button>

          </div>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumnsInDeviceGroupList; sticky: true"></tr> 
      <tr mat-row *matRowDef="let deviceGroup; columns:  displayedColumnsInDeviceGroupList;let i = index"></tr>                
      <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="no-hover"></tr>

      <ng-container matColumnDef="expandedDetail">
          <td mat-cell *matCellDef="let deviceGroup" [attr.colspan]="displayedColumnsInDeviceGroupList.length">
            <div [@detailExpand]="deviceGroup == selectedDeviceGroup ? 'expanded' : 'collapsed'" class="form-box__content form-box__content-h-auto transparent-scroll-vertical" [ngClass]="{'py-0': !deviceGroup.is_expanded,'py-1':deviceGroup.is_expanded}">
              <ng-container *ngTemplateOutlet="deviceTable"></ng-container>
              
            </div>
          </td>
      </ng-container>
      <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell empty py-1" [attr.colspan]="displayedColumns.length">Sin datos</td>
      </tr>
    </table>

  </ng-template>
  
  <ng-template #deviceTable>

    <table
    mat-table 
    matSort 
    (matSortChange)="sortData($event)" 
    [dataSource]="deviceList"
    >
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <ng-container *ngIf="canViewGroupedDevices;then headerSelectText; else headerSelectAction">
          </ng-container>
          <ng-template #headerSelectAction>
            <mat-slide-toggle
                color="primary"
                (change)="$event ? toggleAllRows() : null"
                [checked]="isAllSelected()"
                [aria-label]="checkboxLabel()">
            </mat-slide-toggle>
          </ng-template>
          <ng-template #headerSelectText></ng-template>          
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-slide-toggle
              [checked]="isSelectedDevice(row)"
              color="primary"
              class="z-index-last"
              (click)="$event.stopPropagation()"
              (change)="$event ? selection.toggle(row) : null;changeDeviceSelected($event,row)"
              [aria-label]="checkboxLabel(row)">
          </mat-slide-toggle>
        </td>
      </ng-container>
  
      <ng-container matColumnDef="activated">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Ordenar por activación"> Activado </th>
        <td mat-cell *matCellDef="let element" class="text-center">
          <div class="status complete" *ngIf="!utils.isNullOrEmpty(element.activated) && element.activated==1" matTooltip="Activo" matTooltipPosition="right"><i class="fa-solid fa-circle-check"></i></div>
          <div class="status incomplete" *ngIf="utils.isNullOrEmpty(element.activated) || element.activated!=1" matTooltip="Inactivo" matTooltipPosition="right"><i class="fa-solid fa-circle-xmark"></i></div>
                          
        </td>
      </ng-container>
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Ordenar por nombre"> Nombre </th>
        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
      </ng-container>
      <ng-container matColumnDef="imei">
        <th mat-header-cell *matHeaderCellDef  mat-sort-header sortActionDescription="Ordenar por imei"> Imei </th>
        <td mat-cell *matCellDef="let element"> {{element.imei}} </td>
      </ng-container>
      <ng-container matColumnDef="plate_number">
        <th mat-header-cell *matHeaderCellDef  mat-sort-header sortActionDescription="Ordenar por placa"> Placa </th>
        <td mat-cell *matCellDef="let element"> {{element.plate_number}} </td>
      </ng-container>
      <ng-container matColumnDef="device_model">
        <th mat-header-cell *matHeaderCellDef  mat-sort-header sortActionDescription="Ordenar por GPS"> GPS </th>
        <td mat-cell *matCellDef="let element"> {{element.device_model}} </td>
      </ng-container>
  
      <ng-container matColumnDef="expiration_date">
        <th mat-header-cell *matHeaderCellDef  mat-sort-header sortActionDescription="Ordenar por fecha de expiración"> Fecha de expiración </th>
        <td mat-cell *matCellDef="let element"> {{element.expiration_date}} </td>
      </ng-container>
  
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selection.toggle(row)">
      </tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell empty py-1" [attr.colspan]="displayedColumns.length">Sin datos</td>
    </tr>
    </table>
  </ng-template>

  <!-- Form para la actualizacion o creacion de estados-->
<mat-menu #wialonIpsconfigForm="matMenu" class="detail-popover">
    <span class="detail-popover__btn-close"></span>
    <span class="detail-popover__title" (click)="$event.stopPropagation()">
        <h2 class="detail-popover__title-value">Configuración</h2>
    </span>
    <span mat-menu-item [disableRipple]="true" (click)="$event.stopPropagation()" class="detail-popover-content">
        <form class="detail-popover-content__form">

            <mat-form-field appearance="fill">
                <mat-label>Servidor de destino</mat-label>
                <input matInput required="" name="name" [pattern]="validationService.isValidIpv4AddressAndPortStr" [(ngModel)]="wialonIPSTranmissionWebServiceConfiguration.destination_ip_address">
                <mat-hint align="start">Ejemplo. 1.1.1.1:2300</mat-hint>
                <mat-error>Debe de indicar una direccion IP valida seguida de un puerto</mat-error>
            </mat-form-field>
            <div class="detail-popover-actions">
                <button class="btn btn-primary"  [disabled]="loading" (click)="updateUserWebServiceConfiguration()" mat-raised-button>Guardar</button>            
            </div>
        </form>
    </span>
</mat-menu>