<h2 mat-dialog-title class="border-bottom">Detalles de la integración</h2>
<mat-dialog-content class="mat-typography informative-content informative-content--text-small  informative-content--section-background-alternator">
    <section class="informative-content__section mt-2">
        <h3 class="informative-content__subtitle informative-content__subtitle-6"> Acerca de esta funcionalidad</h3>
        <p>Nuestra solución ofrece una integración con WhatsApp Web mediante una API proporcionada por un proveedor externo. Esta integración permite conectar líneas de WhatsApp con nuestra plataforma SGC, utilizando un código QR para su vinculación.</p>
    </section>
    <section class="informative-content__section">   
        <h3  class="informative-content__subtitle informative-content__subtitle-6">Alcance</h3>
        <p>
        Nuestra integración está diseñada para optimizar la comunicación empresarial mediante la automatización de mensajes para los siguientes conceptos
        </p>
        <mat-list role="list" class="list informative-content__list">
            <mat-list-item role="listitem" class="list__item fs-small">Automatizaciones de cartera</mat-list-item>
            <mat-list-item role="listitem" class="list__item fs-small">Automatizaciones de Facturación</mat-list-item>
            <mat-list-item role="listitem" class="list__item fs-small">Automatizaciones de postventa</mat-list-item>
        </mat-list>
        <p>Con ello se permite enviar mensajes de forma automática, según la programación que usted realice, para los tipos de automatizaciones mencionados.</p>
        <p>La integración permite el envío de mensajes de texto,  enlaces web y documentos PDF (para automatizaciones de Cartera y facturación)</p>
        <p>La integración funciona en una sola dirección, permitiendo el envío de mensajes desde SGC hacia las líneas de WhatsApp de sus usuarios. La capacidad de recepción de mensajes desde WhatsApp hacia SGC no está habilitada</p>
    </section>
    <section class="informative-content__section">
        <h3  class="informative-content__subtitle informative-content__subtitle-6">Practicas no recomendadas</h3>
        <p>Para garantizar el cumplimiento de las políticas de uso de WhatsApp y evitar cualquier inconveniente, es importante tener en cuenta las siguientes prácticas no recomendadas para nuestra integración:</p>
        <mat-list role="list" class="list informative-content__list">
            <mat-list-item role="listitem" class="list__item fs-small">
                <div mat-line class="fw-bold">Prohibición de Mensajes Masivos</div>
                <div mat-line> Esta integración no permite el envío de mensajes masivos. Esta restricción está en línea con las políticas de uso de WhatsApp, que prohíben esta práctica.</div>
            </mat-list-item>
            <mat-list-item role="listitem" class="list__item fs-small">
                <div mat-line class="fw-bold">Envío de Mensajes No Esperados</div>
                <div mat-line>Es crucial no enviar mensajes que los clientes no esperen recibir, especialmente aquellos con contenido sensible. Enviar mensajes inesperados puede llevar a que su línea sea reportada, lo que podría ocasionar sanciones.</div>
            </mat-list-item>
            <mat-list-item role="listitem" class="list__item fs-small">
                <div mat-line class="fw-bold">Conexión de Nuevas Líneas de WhatsApp</div>
                <div mat-line>No se deben conectar nuevas líneas de WhatsApp a esta integración. Las líneas nuevas pueden ser reconocidas como SPAM y, en consecuencia, pueden ser baneadas. Es esencial utilizar únicamente líneas establecidas y autorizadas para evitar ser marcados como SPAM.</div>
            </mat-list-item>
        </mat-list>
    </section>
    <section class="informative-content__section">
        <h3  class="informative-content__subtitle informative-content__subtitle-6">Practicas recomendadas</h3>
        <p>Para garantizar una comunicación efectiva y satisfactoria con sus clientes a través de WhatsApp, es fundamental seguir estas prácticas recomendadas</p>
        <mat-list role="list" class="list informative-content__list">
          <mat-list-item role="listitem" class="list__item fs-small">
              <div mat-line class="fw-bold">Conocimiento de Términos de Uso</div>
              <div mat-line> Es fundamental que conozcas y respetes los términos de uso de WhatsApp para asegurar una comunicación adecuada y conforme a las políticas de la plataforma.</div>
          </mat-list-item>
          <mat-list-item role="listitem" class="list__item fs-small">
              <div mat-line class="fw-bold">Transparencia y Consentimiento</div>
              <div mat-line>Asegúrate de ser transparente con los usuarios sobre el propósito de tus mensajes y obtener su consentimiento antes de enviarles cualquier comunicación comercial.</div>
          </mat-list-item>
          <mat-list-item role="listitem" class="list__item fs-small">
              <div mat-line class="fw-bold">Agregar a Clientes como Contactos</div>
              <div mat-line>Agrega a tus clientes como contactos en WhatsApp y pídeles amablemente que hagan lo mismo contigo.</div>
          </mat-list-item>
          <mat-list-item role="listitem" class="list__item fs-small">
              <div mat-line class="fw-bold">Enviar Mensajes Esperados y Valiosos</div>
              <div mat-line>Envía mensajes que tus clientes esperen recibir, con un tono amable y contenido relevante.</div>
          </mat-list-item>
      </mat-list>
    </section>

</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close cdkFocusInitial class="btn btn-primary">Aceptar</button>
</mat-dialog-actions>
