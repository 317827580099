import { Component, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from 'src/app/core/data/data.service';
import { UtilsService } from 'src/app/core/utils/utils.service';
import { ValidationService } from 'src/app/core/validation/validation.service';
import { IUserSessionData } from '../../models/interfaces/iuser-session-data.model';
import { ObjectInitializationService } from 'src/app/core/object-initialization/object-initialization.service';
import { IRouteConstant, RouteConstant } from 'src/app/core/constant/RouteConstant';
import { IChecklistDataNameUtil, IChecklistQuestionGroupByCategory, IDeviceChecklist, IDeviceChecklistAnswerOption, IDeviceChecklistGridAnswerOption, IDeviceChecklistGridSubquestion, IDeviceChecklistQuestion, IDeviceChecklistQuestionCategory, IDeviceChecklistAnswerAnswered, IDeviceChecklistAnswered } from '../../models/interfaces/idevice-checklist.model';
import { IChecklistAnswerOptionActionType } from '../../models/interfaces/ichecklist-answer-option-action-type.model';
import { IFormControlType } from '../../models/interfaces/iform-control-type.model';
import { DeviceChecklistDataService } from 'src/app/core/device-checklist-data/device-checklist-data.service';
import { IDeviceBasicData } from '../../models/interfaces/idevice.model';
import { UserDriverDataService } from 'src/app/user-driver/core/user-driver-data/user-driver-data.service';
import { ApiService } from 'src/app/core/api/api.service';
import { FormControlTypes, RequestMethodHTTP } from '../../enums/common-enums.enum';
import { MatRadioChange } from '@angular/material/radio';
import { ISupportedFiles } from '../../models/interfaces/isupportedFiles.model';
import { FolderRoute } from '../../../core/constant/FolderRouter';
import { DeviceChecklistResponseHandlerComponent } from './device-checklist-response-handler/device-checklist-response-handler.component';
import { MatDialog } from '@angular/material/dialog';
import { NgxImageCompressService } from 'ngx-image-compress';
import { UserType } from '../../types/common-types.type';
import { IApiRequestData } from '../../models/interfaces/iapi-request-data.model';

import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { MessageBoxService } from 'src/app/core/message-box/message-box.service';

type MaxFieldLength = Pick <IDeviceChecklistAnswerAnswered,"observation">;

interface IRouteParam {
  /** Indica el identificador del checklist objetvo a visualizar en pantalla */
  id:number
  /** Indica el id de checklist resuelto, en caso de que el componente vaya a usarse para visuailzacion de respuestas */
  device_checklist_answered_id?:number
}
/** Indica los errores encontrados en la validacion del checklist */
interface IChecklistFieldError{
  field:string,
  message:string
}
/**
 * Contienela informacion validada del checklist respondido 
 */
interface IChecklistResponseValidated{
  /** Indica si es checklist esta correctamente llenado */
  status:boolean,
  /** Los datos respuestas del checklist a enviar */
  device_checklist_answer_answered?:IDeviceChecklistAnswerAnswered[],
  /** Los errores que se encontraron al realizar la validacion */
  errors?:IChecklistFieldError[];
}

interface IInitialStructurePropertiesKeys{
  question_key:string,
  /** El nombre de la propiedad que almacena el nombre de archivo que se encuentre registrado*/
  attached_file_name_initial_key:string,
  observation_initial_key:string,
  /** El nombre de la propiedad que almacena el archivo a ser subido */
  attached_file_initial_key:string,
  /** El nombre de la propiedad para la carga del archivo de forma temporal  */
  temp_attached_file_initial_key: string,
  /** Indica la parta inicial de la propiedad para acceder a la opcion de respuesta */
  answer_option_initial_key: string    
}
/** Prefijos usados para la interface ISelectedChecklistResponse */
enum SelectedChecklistResponsePrefixes {
  question ="question",
  answer_option="answer_option",
  grid_answer_option="grid_answer_option",
  grid_subquestion="grid_subquestion",
  action_type_id="action_type_id",

  /** Prefijo para agregar observacion a opcion de respuesta */
  observation="observation",

  /**
   * Prefijo para el nombre del archivo que haya almacenado el usuario para dicha respuesta indicada
   */
  atttached_file_name="atttached_file_name",
  /**
   * Prefijo para almacenar el archivo a enviar a almacenar 
   */
  atttached_file="atttached_file",
  /** Prefijo para la imagen  temporal, para cuando el usuario selecciona una
   * imagen del explorador de archivo y se requiere mostrar su selección 
   */
  temp_atttached_file_name="temp_atttached_file_name",
  /** El separador de los items que componen el nombre de propiedad que se construye */
  separator="$"
}
/**
 * almacena las opciones de respuestas con un valor numerico que indica el ID de dicha opcion
 */
interface IAnswerOptions {
  [SelectedChecklistResponsePrefixes.grid_answer_option] : number|null,
  [SelectedChecklistResponsePrefixes.grid_subquestion] : number|null,
  [SelectedChecklistResponsePrefixes.answer_option] : number |null,
  [SelectedChecklistResponsePrefixes.action_type_id] : number |null
}
interface ISelectedChecklistResponseItem{
  form_control_type_id: FormControlTypes,
      /** 
     * Usada para 
     *  El identificador de la respuesta 
     *  (referenciando con IChecklistQuestionGroupByCategory): 
     *     Para tipo grid esta formado por 
     *        la concatenacion de grid_subquestion.id y grid_answer_option.id  
     * 
     *     Para tipo radio o checkbox 
     *        está formado por el identificador de la opcion de respuesta (answer_option.id)
     *    Ambas opciones almacenan la respuesta como un valor booleano
     * 
     *  Tipo de accion asociado a la respuesta
     *  Formado por action_type_id_[answer_identifier]: almacena el id de ltipo de accion
     * 
     *  Archivos adjuntos a la respuesta
     *  formados asi(answer_identifier es el identificador formado con la explicacion anterior de la respuesta):
     *    atttached_file_name_[answer_identifier]: almacena el nombre del archivo que haya almacenado el usuario para dicha respuesta 
     *    atttached_file_[answer_identifier]: Almacena la imagen a enviar al servidor
     *    temp_atttached_file_name_[answer_identifier]: Almacena la imagen de forma temporal, para cuando el usuario selecciona una 
     *        imagen del explorador de archivo y se requiere mostrar su selección 
     * 
     * */
  [answer_identifier:string]:boolean | string|ArrayBuffer| null | File | FormControlTypes,

}
/**
 * @description Administra las respuestas del conductor durante la interaccion con el checklist
 */
interface ISelectedChecklistResponse{
  /** El id de la preguna */
  [question_id:string]:ISelectedChecklistResponseItem
}
@Component({
  selector: 'app-device-checklist-viewer',
  templateUrl: './device-checklist-viewer.component.html',
  styleUrls: ['./device-checklist-viewer.component.scss']
})
export class DeviceChecklistViewerComponent implements OnInit {

  /** @type {boolean} Indica la carga de datos general, como informacion de checklist*/
  loading:boolean = false;
  /**
   * @type {boolean} Indica cuando se esta generando el domumento PDF para la obtencion de las respuestas de checklist resuelto
   */
  generatingDocument:boolean = false;
  /**
   * @type {boolean} Cuando se envia el checklist a registrar, indica si el registro ha sido exitoso
   */
  successfulRegistration:boolean = false;
  /**
   * @type {boolean} Indica la carga de datos de preguntas del checklist
   */
  loadingChecklistQuestions:boolean = false;

  user!:IUserSessionData;
  /** @type {UserType} Almacena el tipo de usuario que esta logueado */
  userTypeLogged:UserType | null;
  /** @type {IDeviceBasicData} Vehiculo actualmente ocupado por el conductor */
  currentDrivenVehicle!:IDeviceBasicData;

  /** Almacena la fecha actual como un string */
  currentDatetime:string = "";
  formControlTypes:IFormControlType[] = [];
  checklistAnswerOptionActionTypes:IChecklistAnswerOptionActionType[] = [];

  /** Almacena los tipos de controles de formularios disponibles, el nombre y id */
  readonly FORM_CONTROL_TYPES = this.dataService.FORM_CONTROL_TYPES;

  /** @type {number} El id del checklist a visualizar */
  checklistId:number = 0;
  /**
   * @type {IDeviceChecklistAnswered} Almacena la respuesta de checklist, en caso de estar en modo edicion entonces se inicializa el objeto
   */
  checklistAnswered:IDeviceChecklistAnswered;
  /** @type {number|null} Indica el identificador de checklist resuelto, en caso de mostrar el componente como visualizador de resultado */
  checklistAnsweredId: number | null = null; 
  /** @type {boolean} Indica si se estará en modo de edición del formulario a responder o si estará solo como visualizador de respuesta. Su valor depende de la variable checklistAnsweredId*/
  editMode:boolean = true;

  /** @type {IDeviceChecklist} Checklist a responder */
  checklistSelected!:IDeviceChecklist;
  /**@type {IChecklistQuestionGroupByCategory} Las preguntas agrupadas por categoria del checklist que se esta respondiendo */
  checklistQuestionsGroupedByCategories:IChecklistQuestionGroupByCategory[]= [];
  /** @type {ISelectedChecklistResponse} Indica las respuestas dadas al checklist seleccionado */
  selectedChecklistResponse!:ISelectedChecklistResponse;

  maxFieldslength!: Record<keyof MaxFieldLength,number>;

  supportedFiles:ISupportedFiles;
  /**Prefijos usados para la interface ISelectedChecklistResponse */
  readonly selectedChecklistResponsePrefixes: typeof SelectedChecklistResponsePrefixes = SelectedChecklistResponsePrefixes;
  imageFolderRoute = FolderRoute;
  /**
   * @type {string} la url base para acceso a recursos
   */
  urlBaseSgc:string = "";
  moduleId:number = RouteConstant.DRIVER_DEVICE_CHECKLIST_MANAGEMENT.id!;
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public dataService: DataService,
    public validationService:ValidationService,
    public utils: UtilsService,
    private objectInitializationService: ObjectInitializationService,
    private deviceChecklistDataService:DeviceChecklistDataService,
    private userDriverDataService:UserDriverDataService,
    private api:ApiService,
    public matDialog: MatDialog,
    private imageCompress: NgxImageCompressService,
    private renderer:Renderer2,
    private messageBox: MessageBoxService
  ){
    this.maxFieldslength = {observation:255};
    this.supportedFiles = this.objectInitializationService.initializeISupportedFiles();
    this.urlBaseSgc = this.api.urlBaseSgc;
    this.currentDrivenVehicle = this.objectInitializationService.initializeIDeviceBasicData(); 
    this.user = this.dataService.getData("user") as IUserSessionData;
    this.checklistSelected = this.objectInitializationService.initializeIDeviceChecklist(this.user.id);
    this.userTypeLogged = this.validationService.isNullOrEmpty(this.user.special_user_type??null) ? this.dataService.USER_TYPE.user : (this.user.special_user_type == this.dataService.SPECIAL_USER.user_driver ? this.dataService.USER_TYPE.user_driver : null);
    this.checklistAnswered = this.objectInitializationService.initializeIDeviceChecklistAnswered();
  }
  ngOnInit(): void {
    const params: IRouteParam = this.activatedRoute.snapshot.params as IRouteParam; 
    this.checklistId = params.id;
    if(!this.validationService.isOnlyNumbers(params.id))
      this.back(false);
    if(typeof params.device_checklist_answered_id != "undefined" ){
      if(this.validationService.isOnlyNumbers(params.device_checklist_answered_id)){
        this.checklistAnsweredId = params.device_checklist_answered_id;
        this.editMode = false;
      }else
        this.back(false);
    }else if(this.userTypeLogged != this.dataService.USER_TYPE.user_driver)
      this.back(false);

    Object.values(this.deviceChecklistDataService.CHECKLIST_DATA_NAME_LIST).forEach((target:keyof IChecklistDataNameUtil)=>this.getUtilityData(target));
    this.currentDatetime = this.utils.getDatetime();
    if(this.userTypeLogged == this.dataService.USER_TYPE.user_driver)
      this.getDrivenVehicle();
    this.getChecklist();
  }

  async getDrivenVehicle(){
    try{
      this.currentDrivenVehicle = this.objectInitializationService.initializeIDeviceBasicData(); 
      let response = await this.userDriverDataService.getDrivenVehicle(this.user.hash);
      if(this.validationService.isResponseApi(response))
        this.currentDrivenVehicle = response.status == 1 ? response.data as IDeviceBasicData : this.objectInitializationService.initializeIDeviceBasicData(); 
      }catch(error){}
  }
  /**
   * @description Obtiene el checklist objetivo
   */
  async getChecklist():Promise<void>{
    this.loading = true;
    this.checklistSelected = this.objectInitializationService.initializeIDeviceChecklist(this.user.id);
    try {
      const response:unknown = await this.api.getDataPost("deviceChecklist",{id:this.checklistId,user_api_hash:this.user.hash,action:"get"},true);
      if(this.validationService.isResponseApi(response)){
        this.checklistSelected = response.status && typeof response.data !="undefined"? response.data as IDeviceChecklist: this.objectInitializationService.initializeIDeviceChecklist(this.user.id);
        response.status == 1 ? this.getChecklistQuestions() : null;
        if(response.status == 0)
          return this.utils.showResultRequest("error",response.message??"","");
      }
      this.utils.hideLoading(()=>this.loading =false);
    } catch (error:any) {
      this.utils.showResultRequest("error","Información",this.api.getDefaultMessage("el checklist",false,false,"GET"));
      this.objectInitializationService.initializeIDeviceChecklist(this.user.id)
      this.utils.hideLoading(()=>this.loading = false);
    }
  }
  /**
   * @description Obtiene los datos de checklist resuelto
   */
  getDeviceChecklistAnswered(){
    let data: IApiRequestData = {user_api_hash: this.user.hash,action:"get", id:this.checklistAnsweredId,include_answers: true};
    this.loading = true;
    this.api.getDataPost("deviceChecklistAnswered",data,true).then((response: unknown) => {
      if(this.validationService.isResponseApi(response)){
        this.checklistAnswered = response.status == 1 ? response.data as IDeviceChecklistAnswered :  this.objectInitializationService.initializeIDeviceChecklistAnswered();
        if(response.status != 1)
          this.utils.showResultRequest("error","Información", response.message??"Error al cargar el registro de respuesta de checklist");
      if(response.status == 1)
        this.initializeSelectedChecklistResponse(this.checklistQuestionsGroupedByCategories, this.checklistAnswered);        
      }
      this.utils.hideLoading(()=>this.loading =false);
    }).catch((error:any)=>{
      this.utils.hideLoading(()=>this.loading =false);
      this.utils.showResultRequest("error","Información",this.api.getDefaultMessage("el registro de respuesta de checklist",false,false,"GET"));
    });
  }
  /**
   * @description Obtiene los datos de preguntas del checklist agrupados por categoria 
   */
  async getChecklistQuestions(){
    this.loadingChecklistQuestions =true;
    this.checklistQuestionsGroupedByCategories = [];
    try {
      const response:unknown = await this.api.getDataPost("deviceChecklistQuestion",{device_checklist_id:this.checklistSelected.id,user_api_hash:this.user.hash,group_by_question_category:true,action:"get"},true);
      if(this.validationService.isResponseApi(response)){
        let data: IChecklistQuestionGroupByCategory[] = response.status && typeof response.data !="undefined"? response.data as IChecklistQuestionGroupByCategory[]:[];
        this.initializeSelectedChecklistResponse(data);
        this.checklistQuestionsGroupedByCategories = data;
        if(response.status == 0)
          this.utils.showResultRequest("error",response.message??"","");
        else{
          if(this.checklistAnsweredId != null)
            this.getDeviceChecklistAnswered();
          else if(this.userTypeLogged == this.dataService.USER_TYPE.user_driver)
            this.checklistAnswered = this.objectInitializationService.initializeIDeviceChecklistAnswered(this.user.id,this.user.name??"", this.currentDrivenVehicle.id,this.currentDrivenVehicle.name,this.currentDrivenVehicle.plate_number??"",this.checklistSelected.id,this.checklistSelected.name,this.utils.getDatetime() );
        }
      }
      this.utils.hideLoading(()=>this.loadingChecklistQuestions =false);
    } catch (error:any) {
      this.utils.showResultRequest("error","Información",this.api.getDefaultMessage("la lista de preguntas del checklist",false,false,"GET"));
      this.utils.hideLoading(()=>this.loadingChecklistQuestions =false);
    }
  }

  /** @description Obtiene los datos de utilidades de checklist como tipos de campo,categorias y tipos de acciones de respusta según los target disponibles*/
  async getUtilityData(target:keyof IChecklistDataNameUtil):Promise<void>{
    try{
      let response = await this.deviceChecklistDataService.getUtilityData(target);
      if(this.validationService.isResponseApi(response))
        target == this.deviceChecklistDataService.CHECKLIST_DATA_NAME_LIST.FORM_CONTROL_TYPES ? this.formControlTypes = response.data as IFormControlType[]:(target==this.deviceChecklistDataService.CHECKLIST_DATA_NAME_LIST.CHECKLIST_ANSWER_OPTION_ACTION_TYPES ? this.checklistAnswerOptionActionTypes = response.data as IChecklistAnswerOptionActionType[]: null);
    }catch(error:any){}
  }
  /**
   * @description Inicializa la variable encargada de gestionar las respuestas que en el formulario de checklist 
   * @param {deviceChecklistAnswered}  deviceChecklistAnswered Los datos de respuestas de checklist a cuzar con el formulario de checklist
  */
  initializeSelectedChecklistResponse(checklistQuestionsGroupedByCategories: IChecklistQuestionGroupByCategory[],deviceChecklistAnswered?: IDeviceChecklistAnswered):void{
    this.selectedChecklistResponse = {};
    for(let index = 0 ; index < checklistQuestionsGroupedByCategories.length; index++){
      let questions = checklistQuestionsGroupedByCategories[index].questions;
      for(let question of questions){
        let questionId:string = this.selectedChecklistResponsePrefixes.question + this.selectedChecklistResponsePrefixes.separator+ question.id!;
        Object.defineProperty(this.selectedChecklistResponse,this.selectedChecklistResponsePrefixes.question+ this.selectedChecklistResponsePrefixes.separator + question.id!,{value:{},writable:true,enumerable: true});
        if(this.validationService.isFormControlTypes(question.form_control_type_id))
          this.selectedChecklistResponse[this.selectedChecklistResponsePrefixes.question + this.selectedChecklistResponsePrefixes.separator + question.id!].form_control_type_id = question.form_control_type_id;
        if(question.form_control_type_id == this.FORM_CONTROL_TYPES.RADIO_GRID || question.form_control_type_id == this.FORM_CONTROL_TYPES.CHECKBOX_GRID){
          for(let answerOption of question.grid_answer_option??[]){
            question.grid_subquestion?.forEach((subquestion: IDeviceChecklistGridSubquestion) =>{
              let answer: IDeviceChecklistAnswerAnswered | null =  this.editMode ? null : this.findValueInDeviceChecklistAnswered(question.id!,question.form_control_type_id, subquestion.id, answerOption.id, null);
              let hasData: boolean = !!answer;
              let suffix:string = this.selectedChecklistResponsePrefixes.separator + subquestion.id+this.selectedChecklistResponsePrefixes.separator+answerOption.id!;
              Object.defineProperty(this.selectedChecklistResponse[questionId],(this.selectedChecklistResponsePrefixes.observation +suffix),{value: !hasData ? "" : answer?.observation,writable:true,enumerable: true});
              Object.defineProperty(this.selectedChecklistResponse[questionId],(this.selectedChecklistResponsePrefixes.atttached_file_name +suffix),{value:!hasData ? "" : answer?.attached_file_name,writable:true,enumerable: true});
              Object.defineProperty(this.selectedChecklistResponse[questionId],(this.selectedChecklistResponsePrefixes.action_type_id + suffix),{value:answerOption.action_type_id,writable:true,enumerable: true});
              Object.defineProperty(this.selectedChecklistResponse[questionId],(this.selectedChecklistResponsePrefixes.answer_option + suffix),{value:!!hasData,writable:true,enumerable: true});
            });
          }
        }
        else if(question.form_control_type_id == this.FORM_CONTROL_TYPES.RADIO || question.form_control_type_id == this.FORM_CONTROL_TYPES.CHECKBOX)
          question.answer_option?.forEach((answerOption:IDeviceChecklistAnswerOption) => {
            let answer: IDeviceChecklistAnswerAnswered | null =  this.editMode ? null : this.findValueInDeviceChecklistAnswered(question.id!,question.form_control_type_id, null, null, answerOption.id);
            let hasData: boolean = !!answer;
            Object.defineProperty(this.selectedChecklistResponse[questionId],(this.selectedChecklistResponsePrefixes.observation + this.selectedChecklistResponsePrefixes.separator + answerOption.id!),{value:!hasData ? "" : answer?.observation,writable:true,enumerable: true});
            Object.defineProperty(this.selectedChecklistResponse[questionId],(this.selectedChecklistResponsePrefixes.atttached_file_name + this.selectedChecklistResponsePrefixes.separator + answerOption.id!),{value:!hasData ? "" : answer?.attached_file_name,writable:true,enumerable: true});
            Object.defineProperty(this.selectedChecklistResponse[questionId],this.selectedChecklistResponsePrefixes.action_type_id + this.selectedChecklistResponsePrefixes.separator + answerOption.id!,{value:answerOption.action_type_id,writable:true,enumerable: true});
            Object.defineProperty(this.selectedChecklistResponse[questionId],this.selectedChecklistResponsePrefixes.answer_option + this.selectedChecklistResponsePrefixes.separator + answerOption.id!,{value:!!hasData,writable:true,enumerable: true});
          } );        
      }
    }
  }
  /**
   * Permite buscar un valor dentro de checklistAnswered
   * @param questionId el id de la pregunta a filtrar
   * @param formControlType tipo de control usado en la pregunta
   * @param subquestionId  id de la subpregunta (fila) en caso de ser una pregunta de tipo grid
   * @param gridAnswerOptionId id de la opcion de respuesta de cuadricula (columna) en caso de ser una pregunta de tipo grid
   * @param answerOptionId id de la opcion de respuesta en caso de ser una pregunta de tipo radio o checkbox 
   */
  findValueInDeviceChecklistAnswered(questionId: number,formControlType: FormControlTypes,subquestionId: number | null = null,gridAnswerOptionId: number | null = null,answerOptionId: number | null = null):IDeviceChecklistAnswerAnswered | null{
    return this.checklistAnswered.answers?.find((answer: IDeviceChecklistAnswerAnswered)=>{
     return answer.device_checklist_question_id == questionId && answer.device_checklist_grid_answer_option_id == gridAnswerOptionId && answer.device_checklist_grid_subquestion_id == subquestionId && answer.device_checklist_answer_option_id ==  answerOptionId;
    })??null;
  }
  /**
   * @description Realiza la solicitud de guardar la informacion de respuesta dada por el usuario 
   */
  async requestToSaveResponses(){
    if(this.successfulRegistration)
      return this.utils.showMsg("Ya se ha registrado las respuestas del checklist","");
    let checklistResponseValidated: IChecklistResponseValidated = this.validateQuestions();
    if(!checklistResponseValidated.status){
      let message:string = typeof checklistResponseValidated.errors != "undefined" ? checklistResponseValidated.errors.map((error:IChecklistFieldError,index:number)=> (index+1)+". "+error.field).join("<br><br>") : "" ;
      return this.utils.showMsg("Las siguientes preguntas deben ser respondidas:",message);
    }
    const dialogRef = this.matDialog.open(DeviceChecklistResponseHandlerComponent,{data:{device_checklist_answer_answered:checklistResponseValidated.device_checklist_answer_answered,device_checklist:this.checklistSelected, checklist_questions_grouped_by_categories:this.checklistQuestionsGroupedByCategories,current_device:this.currentDrivenVehicle}});
    dialogRef.afterClosed().subscribe((response:boolean) => {
      this.successfulRegistration = response;
      if(response)
        setTimeout(() => {this.back(false);}, 500);
    }); 
  }
  /**
   * @description Pobla un objeto IDeviceChecklistAnswerAnswered con las indicaciones de los valores indicados
   * @param questionId el id de la pregunta
   * @param formControlTypeId el tipo de control usado en la pregunta
   * @param answerOptionId Indica el id de la opcion de respuesta,para un contro de tipo checkbox o radio
   * @param gridSubquestionId Indica el id de la subpregunta en caso de ser de tipo grid
   * @param gridAswerOptionId Indica el id de la opcion de respuesta en caso de ser de tipo grid
   * @returns {IDeviceChecklistAnswerAnswered} El objeto de datos de respuesta de checklist con los valores agregados
   */
  populateIDeviceChecklistAnswerAnswered(questionId:number,formControlTypeId:FormControlTypes,answerOptionId?:number,gridSubquestionId?:number,gridAswerOptionId?:number):IDeviceChecklistAnswerAnswered{
    let initialKey:IInitialStructurePropertiesKeys = this.getStructurePropertiesInitialKeys(questionId);
    let isGridOption:boolean = ![this.FORM_CONTROL_TYPES.CHECKBOX,this.FORM_CONTROL_TYPES.RADIO].includes(formControlTypeId); 
    let answerOptionKeySuffix:string = isGridOption ? (gridSubquestionId+this.selectedChecklistResponsePrefixes.separator+gridAswerOptionId) : answerOptionId?.toString()!; 
    return this.objectInitializationService.initializeIDeviceChecklistAnswerAnswered(questionId,0,gridSubquestionId,gridAswerOptionId,answerOptionId, formControlTypeId,
    this.selectedChecklistResponse[initialKey.question_key][initialKey.observation_initial_key+answerOptionKeySuffix]?.toString(),
    this.selectedChecklistResponse[initialKey.question_key][initialKey.attached_file_name_initial_key+answerOptionKeySuffix]?.toString(),
    !this.validationService.isNullOrEmpty(this.selectedChecklistResponse[initialKey.question_key][initialKey.attached_file_initial_key+answerOptionKeySuffix]) ? (this.selectedChecklistResponse[initialKey.question_key][initialKey.attached_file_initial_key+answerOptionKeySuffix] as File) : null
    )
  }
  /**
   * @description Valida que las respuestas dadas por el usuario esten completas, en cuanto a que el conductor las haya respondido todas las obligatorias y si alguna requiere una accion adicional esta se haya completado tambien
   */
  validateQuestions():IChecklistResponseValidated{
    let response:IChecklistResponseValidated = {status:false,device_checklist_answer_answered:[],errors:[]};
    for(let index = 0 ; index < this.checklistQuestionsGroupedByCategories.length; index++){
      for(let question of this.checklistQuestionsGroupedByCategories[index].questions){
        let initialKey:IInitialStructurePropertiesKeys = this.getStructurePropertiesInitialKeys(question.id!);
        if(!!!question.is_required)//si no es requerido,entonces saltar la pregunta
          continue;
        let questionFormControlType: FormControlTypes =this.selectedChecklistResponse[initialKey.question_key].form_control_type_id;  
        if(questionFormControlType == this.FORM_CONTROL_TYPES.RADIO){
          let optionRadio:IDeviceChecklistAnswerOption | undefined = (question.answer_option??[]).find((option:IDeviceChecklistAnswerOption) => !!this.selectedChecklistResponse[initialKey.question_key][initialKey.answer_option_initial_key+option.id]);               
          if(typeof optionRadio != "undefined")
            response.device_checklist_answer_answered?.push(this.populateIDeviceChecklistAnswerAnswered(question.id!,question.form_control_type_id,optionRadio.id!));
          else
            response.errors?.push({field:question.label,message:""});
        }else if(questionFormControlType  == this.FORM_CONTROL_TYPES.CHECKBOX){
          if((question.answer_option??[]).length>0 && (question.answer_option??[]).some((option:IDeviceChecklistAnswerOption) => !!this.selectedChecklistResponse[initialKey.question_key][initialKey.answer_option_initial_key+option.id])){
            question.answer_option?.forEach((answerOption: IDeviceChecklistAnswerOption)=>{
              if(!!this.selectedChecklistResponse[initialKey.question_key][initialKey.answer_option_initial_key+answerOption.id])
                response.device_checklist_answer_answered?.push(this.populateIDeviceChecklistAnswerAnswered(question.id!,question.form_control_type_id,answerOption.id!));
            });
          }
          else
            response.errors?.push({field:question.label,message:""});
        }else if(question.form_control_type_id == this.FORM_CONTROL_TYPES.CHECKBOX_GRID || question.form_control_type_id == this.FORM_CONTROL_TYPES.RADIO_GRID){
          for(let subquestion of question.grid_subquestion??[]){
            let initialAnswerOptionKey:string = initialKey.answer_option_initial_key+subquestion.id+this.selectedChecklistResponsePrefixes.separator;
            if((question.grid_answer_option?.every((answerOption:IDeviceChecklistGridAnswerOption) => !!!this.selectedChecklistResponse[initialKey.question_key][initialAnswerOptionKey+answerOption.id]))){
              response.errors?.push({field:question.label,message:""});             
              break;
            }
            for(let gridAnswerOption of question.grid_answer_option??[]){                
              if(!!this.selectedChecklistResponse[initialKey.question_key][initialAnswerOptionKey+gridAnswerOption.id]){
                response.device_checklist_answer_answered?.push(this.populateIDeviceChecklistAnswerAnswered(question.id!,question.form_control_type_id,undefined,subquestion.id!,gridAnswerOption.id!));
                if(question.form_control_type_id == this.FORM_CONTROL_TYPES.RADIO_GRID)
                  break;
              }
            }
          }
        }
      }
    }
    response.status = (response.errors??[]).length==0;
    return response;
  }
  /**
   * Carga el archivo a la variable encargada de la gestion de respuesta
   * @param file El objeto del input file que permite acceder al archivo que el usuario esta cargando
   */
  async uploadRequestedFile(event:any,questionId:number,formControlType:FormControlTypes,gridSubquestionId:number = 0,questionAnswerOption:IDeviceChecklistAnswerOption[] = [], gridAnswerOption:IDeviceChecklistGridAnswerOption[] = []):Promise<void> {
      let reader = new FileReader();
      let file: File = event.target.files[0];
      let fileType:string[] = file.type.split("/");
      if(!this.supportedFiles.list_accept_file_type.includes(fileType[fileType.length >0 ? fileType.length -1 : 0])){
        return this.utils.showMsg("Debe de seleccionar un tipo de imagen con formato válido","Formatos aceptados: "+this.supportedFiles.accept_label);
      }
      let initialKey:IInitialStructurePropertiesKeys = this.getStructurePropertiesInitialKeys(questionId);
      if(formControlType == this.FORM_CONTROL_TYPES.RADIO){
        for(let option of questionAnswerOption){
          if(!!this.selectedChecklistResponse[initialKey.question_key][ initialKey.answer_option_initial_key +option.id]){            
            this.compressFile(file,initialKey.question_key, initialKey.attached_file_initial_key+option.id);
            reader.readAsDataURL(file);
            reader.onload = () => this.selectedChecklistResponse[initialKey.question_key][initialKey.temp_attached_file_initial_key+option.id] = reader.result;
          }
          else
            this.selectedChecklistResponse[initialKey.question_key][initialKey.attached_file_initial_key+option.id] = null;
        }  
      }else if(formControlType == this.FORM_CONTROL_TYPES.RADIO_GRID){
        for(let answerOption of gridAnswerOption){
            if(!!this.selectedChecklistResponse[initialKey.question_key][initialKey.answer_option_initial_key+gridSubquestionId+this.selectedChecklistResponsePrefixes.separator+answerOption.id]??false){              
              this.compressFile(file,initialKey.question_key, initialKey.attached_file_initial_key+gridSubquestionId+this.selectedChecklistResponsePrefixes.separator+answerOption.id);
              reader.readAsDataURL(file);
              reader.onload = () => this.selectedChecklistResponse[initialKey.question_key][initialKey.temp_attached_file_initial_key+gridSubquestionId+this.selectedChecklistResponsePrefixes.separator+answerOption.id] = reader.result;  
            }else
              this.selectedChecklistResponse[initialKey.question_key][initialKey.attached_file_initial_key+gridSubquestionId+this.selectedChecklistResponsePrefixes.separator+answerOption.id] = null;
        }
      }
  }
  /**
   * @description Comprime la imagen indicada y la agrega al objeto de respuestas de checklist
   * @param file Imagen a comprimir
   * @param questionKey el nombre completo de la propiedad que identifica a la pregunta
   * @param answerKey El nombre completo de la propiedad que identifica a la respuesta
   */
  async compressFile(file:File,questionKey:string,answerKey:string):Promise<void> {
    let imageAsDataUrl = await this.utils.fileToDataUrl(file); 
    this.imageCompress
    .compressFile(imageAsDataUrl, this.imageCompress.DOC_ORIENTATION.NotDefined, 50, 50) // 50% ratio, 50% quality
    .then(compressedImage => {
      const blobFile = this.utils.dataURLtoBlob(compressedImage) as Blob;
      let typeArray:string[] = blobFile.type.split("/");
      this.selectedChecklistResponse[questionKey][answerKey] = new File([blobFile], this.selectedChecklistResponsePrefixes.atttached_file+"."+typeArray[typeArray.length >0 ? (typeArray.length-1) : 0],{type:blobFile.type});
    });
  }
  /**
   * @description Carga las imagenes en la interfaz grafica 
   * @returns 
   */
  loadImageUrl(questionId:number,formControlType:FormControlTypes,gridSubquestionId:number = 0,questionAnswerOption:IDeviceChecklistAnswerOption[] = [], gridAnswerOption:IDeviceChecklistGridAnswerOption[] = []):string {    
    let initialKey:IInitialStructurePropertiesKeys = this.getStructurePropertiesInitialKeys(questionId);
    if(formControlType == this.FORM_CONTROL_TYPES.RADIO || formControlType == this.FORM_CONTROL_TYPES.RADIO_GRID){
      let items:IDeviceChecklistGridAnswerOption[]|IDeviceChecklistAnswerOption[] = formControlType == this.FORM_CONTROL_TYPES.RADIO ? questionAnswerOption : gridAnswerOption;
      for(let option of items){
        if(!!this.selectedChecklistResponse[initialKey.question_key][initialKey.answer_option_initial_key + (formControlType == this.FORM_CONTROL_TYPES.RADIO ? (option.id): (gridSubquestionId+this.selectedChecklistResponsePrefixes.separator+option.id))]){
          if(!this.validationService.isNullOrEmpty(this.selectedChecklistResponse[initialKey.question_key][initialKey.attached_file_name_initial_key + (formControlType == this.FORM_CONTROL_TYPES.RADIO ? (option.id): (gridSubquestionId+this.selectedChecklistResponsePrefixes.separator+option.id))]))
            return this.urlBaseSgc + this.imageFolderRoute.CHECKLIST_ANSWERS + this.selectedChecklistResponse[initialKey.question_key][initialKey.attached_file_name_initial_key + (formControlType == this.FORM_CONTROL_TYPES.RADIO ? (option.id): (gridSubquestionId+this.selectedChecklistResponsePrefixes.separator+option.id))]?.toString()!;
          else
            return this.selectedChecklistResponse[initialKey.question_key][initialKey.temp_attached_file_initial_key + (formControlType == this.FORM_CONTROL_TYPES.RADIO ? (option.id): (gridSubquestionId+this.selectedChecklistResponsePrefixes.separator+option.id))]?.toString()!;
        }
      }  
    }
    return "";
  }
  /**
   * @description Elimina la imagen que el usuario ha cargado para la opcion de respuesta
   */
  deleteUploadedImage(questionId:number,formControlType:FormControlTypes,gridSubquestionId:number = 0,questionAnswerOption:IDeviceChecklistAnswerOption[] = [], gridAnswerOption:IDeviceChecklistGridAnswerOption[] = []){
    let initialKey:IInitialStructurePropertiesKeys = this.getStructurePropertiesInitialKeys(questionId);
    if(formControlType == this.FORM_CONTROL_TYPES.RADIO || formControlType == this.FORM_CONTROL_TYPES.RADIO_GRID){
      let items:IDeviceChecklistGridAnswerOption[]|IDeviceChecklistAnswerOption[] = formControlType == this.FORM_CONTROL_TYPES.RADIO ? questionAnswerOption : gridAnswerOption;
      for(let option of items){
        this.selectedChecklistResponse[initialKey.question_key][initialKey.temp_attached_file_initial_key+(formControlType == this.FORM_CONTROL_TYPES.RADIO ? (option.id): (gridSubquestionId+this.selectedChecklistResponsePrefixes.separator+option.id))] = null;
        this.selectedChecklistResponse[initialKey.question_key][initialKey.attached_file_initial_key+(formControlType == this.FORM_CONTROL_TYPES.RADIO ? (option.id): (gridSubquestionId+this.selectedChecklistResponsePrefixes.separator+option.id))] = null;
      }  
    }
  }
  /**
   * Obtiene los nombres de propiedades estrcuturadas para la consulta de los vaores de respuestas de checklist
   * @param questionId id de la pregunta
   * @returns 
   */
  getStructurePropertiesInitialKeys(questionId:number):IInitialStructurePropertiesKeys{
    let questionKey:string = this.selectedChecklistResponsePrefixes.question + this.selectedChecklistResponsePrefixes.separator + questionId;
    /** El nombre de la propiedad que almacena el nombre de archivo que se encuentre registrado*/
    let attachedFileNameKey: string = this.selectedChecklistResponsePrefixes.atttached_file_name + this.selectedChecklistResponsePrefixes.separator;
    let atttachedFileKey: string = this.selectedChecklistResponsePrefixes.atttached_file + this.selectedChecklistResponsePrefixes.separator;
    let observationKey: string = this.selectedChecklistResponsePrefixes.observation + this.selectedChecklistResponsePrefixes.separator;
  
    /** El nombre de la propiedad para la carga del archivo de forma temporal  */
    let tempAttachedFileKey: string = this.selectedChecklistResponsePrefixes.temp_atttached_file_name + this.selectedChecklistResponsePrefixes.separator;
    /** Indica la parta inicial de la propiedad para acceder a la opcion de respuesta */
    let answerOptionInitialKey: string = this.selectedChecklistResponsePrefixes.answer_option+this.selectedChecklistResponsePrefixes.separator;    
    return {question_key:questionKey, observation_initial_key: observationKey , attached_file_initial_key: atttachedFileKey,temp_attached_file_initial_key:tempAttachedFileKey,answer_option_initial_key:answerOptionInitialKey, attached_file_name_initial_key:attachedFileNameKey};
  }
  /**
   * Valida la opcion de radio seleccionada en la pregunta y la asigna a la lista de rspuesta
   * @param {FormControlTypes} SelectedFormControlType  El tipo de campo de control de formulario a que pertenece la pregunta 
   * @param {number} questionId El id de la pregunta
   * @param {number} subquestionId El id de la opcion de respuesta,para las preguntas de tipo grid
   * @param {number} gridAnswerOptionId El id de la opcion de respuesta,para las preguntas de tipo grid 
   * @param {number} answerOptionId El id de la opcion de respuesta,para las preguntas de tipo radio
   * @param {MatRadioChange} event El evento de cambio de seleccion de item 
   */
   isAnswerOptionSelected(questionId:number,subquestionId:number,gridAnswerOptionId:number,answerOptionId:number,event:MatRadioChange){
    let formControlType: FormControlTypes = this.selectedChecklistResponse[this.selectedChecklistResponsePrefixes.question+this.selectedChecklistResponsePrefixes.separator + questionId].form_control_type_id;
      if(formControlType === this.FORM_CONTROL_TYPES.RADIO_GRID || formControlType == this.FORM_CONTROL_TYPES.RADIO){
        let items = this.selectedChecklistResponse[this.selectedChecklistResponsePrefixes.question+ this.selectedChecklistResponsePrefixes.separator + questionId];       
        for(let [key,value] of Object.entries(items)){
          let keyAnswerIds: IAnswerOptions = this.getSelectedAnswerIds(key,formControlType);
          if(typeof value =="boolean" &&  formControlType == this.FORM_CONTROL_TYPES.RADIO ? true : (keyAnswerIds[SelectedChecklistResponsePrefixes.grid_subquestion] == subquestionId) )
            this.selectedChecklistResponse[this.selectedChecklistResponsePrefixes.question+ this.selectedChecklistResponsePrefixes.separator + questionId][key] =  formControlType == this.FORM_CONTROL_TYPES.RADIO ? (answerOptionId == keyAnswerIds[SelectedChecklistResponsePrefixes.answer_option]) : (gridAnswerOptionId == keyAnswerIds[SelectedChecklistResponsePrefixes.grid_answer_option]);
        }
      } 
  }
  /**
   * @description Retorna los ids que componen a la key indicada
   */
  getSelectedAnswerIds(key:string,formControlType: FormControlTypes):IAnswerOptions{
    let response: IAnswerOptions = {grid_answer_option:null,grid_subquestion:null,answer_option:null,action_type_id:null};
    let items:string[] = key.split(this.selectedChecklistResponsePrefixes.separator);
    if(items[0] == this.selectedChecklistResponsePrefixes.answer_option && formControlType === this.FORM_CONTROL_TYPES.RADIO_GRID){
      /** por convención el primer numero almacenado en el key es el subquestion y el segundo es la grid_answer_option */
      response[SelectedChecklistResponsePrefixes.grid_subquestion] = !isNaN(Number(items[1])) ? Number(items[1]) : null; 
      response[SelectedChecklistResponsePrefixes.grid_answer_option] = !isNaN(Number(items[2])) ? Number(items[2]) : null; 
    }else if(items[0] == this.selectedChecklistResponsePrefixes.answer_option && formControlType == this.FORM_CONTROL_TYPES.RADIO) // por convencion el primer valor numerico es el id del answer_option
      response[SelectedChecklistResponsePrefixes.answer_option] = !isNaN(Number(items[1])) ? Number(items[1]) : null; 
    else
      return response;
    return response;
  }
  /**
   * Determina si la opcion de respuesta seleccionada tiene un tipo de accion asociada
   * @param {number} questionId el id de la pregunta
   * @param {FormControlTypes} formControlType El tipo de control de formulaio usado para la pregunta
   * @param {number} gridSubquestionId el id de la subpregunta, en el caso de que sea una pregunta de tipo grid  
   * @param {IDeviceChecklistAnswerOption[]} questionAnswerOption La lista de opciones de respuesta de la pregunta
   * @param {gridAnswerOption[]} gridAnswerOption Las opciones de respuestas  que componen a una pregunta de tipo cuadricula
   * @returns {number} El id del tipo de accion, 0 en caso de no obtener ningun tipo de accion  
   */
  currentResponseActionType(questionId:number,formControlType:FormControlTypes,gridSubquestionId:number = 0,questionAnswerOption:IDeviceChecklistAnswerOption[] = [], gridAnswerOption:IDeviceChecklistGridAnswerOption[] = []):number{
    let initialKey:IInitialStructurePropertiesKeys = this.getStructurePropertiesInitialKeys(questionId);
    if(formControlType == this.FORM_CONTROL_TYPES.RADIO){
      for(let option of questionAnswerOption){
        if(!!this.selectedChecklistResponse[initialKey.question_key][initialKey.answer_option_initial_key+option.id])
          return option.action_type_id??0;
      }  
    }else if(formControlType === this.FORM_CONTROL_TYPES.RADIO_GRID){
      for(let answerOption of gridAnswerOption){
          if(!!this.selectedChecklistResponse[initialKey.question_key][initialKey.answer_option_initial_key+gridSubquestionId+this.selectedChecklistResponsePrefixes.separator+answerOption.id]??false)
            return answerOption.action_type_id!;
      }
    }
      return 0;
  }
  /**
   * @description Indica si la pregunta tiene una opcion seleccionada, para las preguntas de tipo cuadricula evalua si un subpregunta tiene opcion seleccionada */
  hasAnswerOptionSelected(questionId:number,formControlType:FormControlTypes,gridSubquestionId:number = 0,questionAnswerOption:IDeviceChecklistAnswerOption[] = [], gridAnswerOption:IDeviceChecklistGridAnswerOption[] = []){
    let initialKey:IInitialStructurePropertiesKeys = this.getStructurePropertiesInitialKeys(questionId);    
    if(formControlType == this.FORM_CONTROL_TYPES.RADIO || formControlType == this.FORM_CONTROL_TYPES.CHECKBOX){
      return questionAnswerOption.some((option:IDeviceChecklistAnswerOption)=> !!this.selectedChecklistResponse[initialKey.question_key][initialKey.answer_option_initial_key+option.id] );
    }else if(formControlType == this.FORM_CONTROL_TYPES.RADIO_GRID || formControlType == this.FORM_CONTROL_TYPES.CHECKBOX_GRID){
      return gridAnswerOption.some((answerOption: IDeviceChecklistGridAnswerOption) => !!this.selectedChecklistResponse[initialKey.question_key][initialKey.answer_option_initial_key+gridSubquestionId+this.selectedChecklistResponsePrefixes.separator+answerOption.id]??false);
    }
    return false;
  }
  /**
   * @description Establece el valor asignado como observacion a una opcion de respuesta. Para las preguntas de tipo cuadricula evalua dichas opciones por subpregunta
   */
  assignAnswerOptionObservation(questionId:number,formControlType:FormControlTypes,gridSubquestionId:number = 0,questionAnswerOption:IDeviceChecklistAnswerOption[] = [], gridAnswerOption:IDeviceChecklistGridAnswerOption[] = [],event:any|null = null):string{
    let initialKey:IInitialStructurePropertiesKeys = this.getStructurePropertiesInitialKeys(questionId);    
    let response:string|null = event != null ? (event.target.value??null) : null;
    let items:IDeviceChecklistGridAnswerOption[]|IDeviceChecklistAnswerOption[] = [this.FORM_CONTROL_TYPES.RADIO,this.FORM_CONTROL_TYPES.CHECKBOX].includes(formControlType ) ? questionAnswerOption : gridAnswerOption;
    for(let option of items){
      let answerOptionKey:string = initialKey.answer_option_initial_key + ([this.FORM_CONTROL_TYPES.RADIO,this.FORM_CONTROL_TYPES.CHECKBOX].includes(formControlType) ? (option.id): (gridSubquestionId+this.selectedChecklistResponsePrefixes.separator+option.id));
      let observationKey:string = initialKey.observation_initial_key + ([this.FORM_CONTROL_TYPES.RADIO,this.FORM_CONTROL_TYPES.CHECKBOX].includes(formControlType) ? (option.id): (gridSubquestionId+this.selectedChecklistResponsePrefixes.separator+option.id));
        if(response != null)
          this.selectedChecklistResponse[initialKey.question_key][observationKey] = !!this.selectedChecklistResponse[initialKey.question_key][answerOptionKey] ? (response??"") : "";
        else if(!!this.selectedChecklistResponse[initialKey.question_key][answerOptionKey]){
          response = this.selectedChecklistResponse[initialKey.question_key][observationKey]?.toString()!;
          break;
        }
    }  
    return response??"";
  }
  async resetChecklist(){
    try {
      let confirm:unknown = await this.utils.showConfirm("¿Quieres borrar las respuestas?", "Esta acción eliminará todas las respuestas actuales seleccionadas en este checklist","Confirmar","Cancelar");
      if(typeof confirm == "boolean" || confirm){
        this.loadingChecklistQuestions = true;
        setTimeout(() => {
          this.initializeSelectedChecklistResponse(this.checklistQuestionsGroupedByCategories);
          this.loadingChecklistQuestions= false;
        }, 500);
      }
    } catch (error:any) {}
  }
  /** 
   * @description Retorna a la vista de checklist disponibles 
   * @param {boolean} confirm indica si el usuario tiene que confirmar primero el retroceso
  */
  async back(confirm:boolean = true):Promise<void>{
    try {
      if(this.userTypeLogged == this.dataService.USER_TYPE.user){
        if(this.deviceChecklistDataService.getUrlToReturn() != null){
          let route: IRouteConstant  = this.deviceChecklistDataService.getUrlToReturn() as IRouteConstant;
          this.router.navigateByUrl(route.url);
          this.deviceChecklistDataService.setUrlToReturn(null);
        }else
          this.router.navigateByUrl(RouteConstant.DASHBOARD.url+"/"+RouteConstant.DEVICE_CHECKLIST_MANAGEMENT.url);
      }
      else{
        let response:unknown = !confirm || this.checklistAnsweredId != null ? true : (this.checklistQuestionsGroupedByCategories.length>0 ? await this.utils.showConfirm("¿Confirmar regreso a gestión de checklist?", "Esto eliminará las respuestas dadas en este checklist en el momento","Confirmar","Cancelar"): true);
        if(typeof response == "boolean" || response)
          this.router.navigate([(RouteConstant.DASHBOARD.url+"/"+RouteConstant.DRIVER_DEVICE_CHECKLIST_MANAGEMENT.url)]);
      }
    } catch (error:any) {}
  }
 async generatePdfDocument():Promise<void>{    
    const formdata =  new FormData();
    formdata.append("user_api_hash", this.user.hash);
    formdata.append("action", "requestPDF");
    formdata.append("device_checklist_answered_id", this.checklistAnswered.id.toString());
    this.generatingDocument = true;
    try {
      let response:any = await this.api.requestBlobData(formdata,FolderRoute.FILE_GENERATOR_DEVICE_CHECKLIST_ANSWERED);
      if(this.validationService.isBlob(response))
        this.utils.downloadFileAsPDF(response,"checklist_"+this.checklistAnswered.driver_name+"_"+(this.utils.getDateTimeNumericString()));
      else
        this.messageBox.openSnackBar("Error al generar archivo","Aceptar");
    } catch (error) {
      this.messageBox.openSnackBar("Error al generar archivo","Aceptar");
    }finally{
      this.generatingDocument = false;
    }
  }
  
}