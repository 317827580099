<section class="form-box-container animate__animated animate__fadeIn" *ngIf="viewsAvailable['automation_form'].show_view">
    
    <section class="form-box-container__header">
        <span class="form-box-container__header-back" (click)="closeComponent()"><span class="description">Volver</span> </span>
        <h2 class="form-box-container__header-title"><span class="icon"><i class="fa-solid fa-robot"></i></span>{{selectedAutomation.id > 0 ? "Actualizacion" : "Registro de"}} de atomatización </h2>
    </section>
    <div class="form-box animate__animated animate__fadeInRight">
        <div  class="form-box__content form-box__content-h-auto">
            <!-- Inicio de formulario automatizacion-->
            <mat-tab-group (selectedTabChange)="validateComponentToShow($event)">
              <mat-tab>
                <ng-template mat-tab-label>
                  <mat-icon class="text-success">smart_toy</mat-icon>
                  Configuracion automatización
                </ng-template>
                <div class="form-box__content-section form-box__content-section-shadow">
                  <mat-stepper class="mat-horizontal-stepper-header--compact"  linear #stepper [linear]="selectedAutomation.id <= 0">
                    <mat-step [stepControl]="automationTypeForm" >
                      <form class="position-relative" [formGroup]="automationTypeForm">
                        <ng-template matStepLabel>Tipo de automatización</ng-template>
                        <div class="form-box__content-inputs mt-2" *ngIf="automationTypeList.length>0">
                          <div class="form-box__content-group-fields "><!--flex-grow-0-->
                                         
                        <mat-form-field appearance="fill" class="fill"  hintLabel="Max {{maxFieldslength.name}} carácteres">
                          <mat-label>Nombre</mat-label>
                          <input matInput formControlName="name" [maxLength]="maxFieldslength.name" placeholder="Nombre identificativo de automatización">
                          <mat-hint align="end">{{automationTypeForm.value['name']?.length || 0}}/{{maxFieldslength.name}}</mat-hint>
                          <mat-error>El nombre es requerido</mat-error>
                        </mat-form-field>
                          <ng-container *ngFor="let automation of automationTypeList">
                            <mat-card  class="card" *ngIf="selectedAutomation.id == 0 || selectedAutomation.id > 0 && selectedAutomation.automation_type_id == automation.id" [ngClass]="{'card__radio--checked': automationTypeForm.value['selectedAutomationType'] == automation.id.toString()}" >
                              <input type="radio" class="card__radio" formControlName="selectedAutomationType" [id]="'au'+automation.id" name="selectedAutomationType" [value]="automation.id">
                              <mat-card-title-group>
                                  <mat-card-title>
                                <div class="d-flex justify-content-start align-items-center g-1">
                                  <div [ngClass]="{'text-gray': automationTypeForm.value['selectedAutomationType'] != automation.id.toString(), 'text-success': automationTypeForm.value['selectedAutomationType'] == automation.id.toString()}" ><i class="fa-solid fa-check-to-slot"></i></div>
                                  <span>{{automation.name}}</span>
                                </div>
                                  </mat-card-title>
                                  <img mat-card-sm-image src="../../../../../assets/img/{{automation.illustrative_image_name }}" alt="imagen de tipo de automatización de {{automation.illustrative_image_name }}" >
                              </mat-card-title-group>
                              <mat-card-content>
                                  {{automation.description}}
                              </mat-card-content>
                            </mat-card>
                          </ng-container>
                          
                          </div>
                        </div>
                        <div class="form-box__content-actions form-box__content-actions--align-end">
                          <button mat-button matStepperNext class="btn btn-primary">Siguiente</button>
                        </div>
                      </form>
                    </mat-step>
                    <mat-step class="animate__animated animate__fadeIn" [stepControl]="timeSettingForm" *ngIf="automationTypeForm.value['selectedAutomationType'] != AUTOMATION_TYPE_LIST.BILLING.toString() && automationTypeForm.value['selectedAutomationType'] != ''" [optional]="automationTypeForm.value['selectedAutomationType'] == AUTOMATION_TYPE_LIST.BILLING.toString()" >
                      <!--<article class="ilustration ilustration__background ilustration__background--left-bottom">
                        <dotlottie-player autoplay loop mode="normal"
                        src="../../../..//../assets/img/user-clock.lottie"
                        ></dotlottie-player>
                    </article>-->
                      <form [formGroup]="timeSettingForm">
                          <ng-template matStepLabel>Configuración de tiempo</ng-template>
      
                            <div
                            *ngIf="automationTypeForm.value['selectedAutomationType'] == AUTOMATION_TYPE_LIST.AFTER_SALE.toString() "
                            class="form-box__content-inputs mt-2" >
                              <div class="form-box__content-group-fields ">
      
                                <mat-slide-toggle color="primary" class="fill" formControlName="periodicExecution" name="periodicExecution">
                                  Enviar de forma frecuente
                                </mat-slide-toggle>
                                <div 
                                class="form-box__content-fields-flex-row fill">
                                  <span>
                                    Enviar notificación de postventa {{ timeSettingForm.value['periodicExecution'] == PERIODIC_EXECUTION_VALUES.PERIODIC ? 'cada' : 'al cumplir'}}
                                  </span>        
                                    <mat-form-field appearance="fill">
                                      <input matInput formControlName="timeQuantity" value=""type="number" [max]="maxFieldslength.time_quantity">
                                    </mat-form-field>
                                    <mat-form-field appearance="fill">
                                      <mat-select name="timeUnit"  formControlName="timeUnit">
                                        <mat-option *ngFor="let item of timeUnitArray"  [value]="item.value">
                                          {{item.label |titlecase}}
                                        </mat-option>
                                      </mat-select>
                                  </mat-form-field>
                                  <span >{{ timeSettingForm.value['periodicExecution'] == PERIODIC_EXECUTION_VALUES.PERIODIC ? 'posterior a' : 'de'}} su registro </span>
                                </div>
                              </div>
                            </div>
                            <div 
                              *ngIf="automationTypeForm.value['selectedAutomationType'] == AUTOMATION_TYPE_LIST.PORTFOLIO.toString() "
                              class="form-box__content-fields-inline form-box__content-fields-inline--compact">
                              <span>Enviar notificación al cumplir</span>        
                                <mat-form-field class="">
                                  <input matInput formControlName="timeQuantity" value="" type="number" min="1" >
                                </mat-form-field>
                                <mat-form-field appearance="fill">
                                  <mat-select name="timeUnit"  formControlName="timeUnit">
                                    <mat-option *ngFor="let item of timeUnitArray"  [value]="item.value">
                                      {{item.label |titlecase}}
                                    </mat-option>
                                  </mat-select>
                              </mat-form-field>
                              <span >de mora </span>
                            </div>
                            <div class="form-box__content-actions form-box__content-actions--align-end">
                              <button mat-button matStepperPrevious>Volver</button>
                              <button mat-button matStepperNext class="btn btn-primary">Siguiente</button>                        
                            </div>
                        </form>
                      </mat-step>
                    <mat-step [stepControl]="messagetTemplateForm" *ngIf="automationTypeForm.value['selectedAutomationType'] != ''">
                      <form [formGroup]="messagetTemplateForm">
                        <ng-template matStepLabel>Notificación</ng-template>
      
                        <section class="message-template-editor">
                          <section  class="message-template-editor">
                            <article class="message-template-editor__toolbar">
      
                            </article>
                            <article class="message-template-editor__content">
                              <mat-form-field appearance="fill" class="fill">
                                <mat-label>Plantilla de mensaje</mat-label>
                                <textarea
                                [maxLength]="maxFieldslength.sms_template" 
                                matInput 
                                formControlName="messageTemplate" 
                                autocomplete="off" 
                                rows="10" 
                                required=""
                                placeholder="Mensaje de ejemplo: ¡Hola [user.name]!, ..."></textarea>
                              </mat-form-field>
                            </article>
                          </section>
                          <section class="message-template-editor__note alert alert-light-info">
                            <p>Puedes usar los siguientes marcadores de posiciones para personalizar los mensajes</p>
                            <mat-list role="list">
                              <mat-list-item role="listitem" *ngFor="let placeholder of messageTemplateUserPlaceholder">
                                <span matTooltipPosition="left" class="" matTooltip="Copiar al portapapeles" *ngIf="validationService.isClipboardApiAvailable()" (click)="copyMessageTemplatePlaceholder(placeholder)"><i class="fa-solid fa-copy icon-copy"></i></span>
                                {{placeholder}}</mat-list-item>
                            </mat-list>
                          </section>
                        </section>
                        <div class="form-box__content-actions form-box__content-actions--align-end">
                          <button mat-button matStepperPrevious>Volver</button>
                          <!--<button mat-button matStepperNext>Siguiente</button>-->
                          <button mat-button class="btn btn-primary" (click)="confirmAutomationEdit()">{{selectedAutomation.id > 0 ? 'Actualizar' : 'Registrar'}}</button>
                        </div>
                      </form>
                    </mat-step>
                  </mat-stepper>
                </div>
                
              

              </mat-tab>
            
              <mat-tab *ngIf="automationTypeForm.value['selectedAutomationType'] != '' && selectedAutomation.id > 0 ">
                <ng-template mat-tab-label>
                  <mat-icon class="text-info">group_add</mat-icon>
                  Destinatarios
                </ng-template>

              </mat-tab>
            </mat-tab-group>
            <!-- fin de formulario automatizacion-->

        </div>
    </div>
    <div class="loader" *ngIf="loading">
        <img src="../../../../assets/loader.svg">
    </div>
</section>
<app-automation-target-user [selectedAutomationId]="selectedAutomation.id"  [selectedAutomationType]="selectedAutomation.automation_type_id" *ngIf="viewsAvailable['automation_target_user'].show_view" (close)="utils.setSelectedView(this.viewsAvailable,this.viewsAvailable['automation_form'].name)">

</app-automation-target-user>