import { Component, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from 'src/app/core/data/data.service';
import { UtilsService } from 'src/app/core/utils/utils.service';
import { ApiService } from '../../core/api/api.service';
import { Sort } from '@angular/material/sort';
import Swal from 'sweetalert2';
import { ValidationService } from 'src/app/core/validation/validation.service';
import { IPermissionsIntgps } from 'src/app/shared/models/interfaces/ipermissions-intgps.model';
import { ObjectInitializationService } from 'src/app/core/object-initialization/object-initialization.service';
import { DevicePlanType, RequestMethodHTTP, USER_ROLES_ID } from 'src/app/shared/enums/common-enums.enum';
import { ICity } from 'src/app/shared/models/interfaces/iutils-data.model';
import { IApiRequestData } from 'src/app/shared/models/interfaces/iapi-request-data.model';
import { HttpErrorResponse } from '@angular/common/http';
//REFACTORIZAR  EN CONJUNTO CON view/distributors y clients
@Component({
  selector: 'app-distributors',
  templateUrl: './distributors.component.html',
  styleUrls: ['./distributors.component.scss']
})
export class DistributorsComponent implements OnInit {
  //REFACTORIZAR EN CONJUNTO CON COMPONENTE CLIENTS
  //para paginacion
  lengthPage = 0;
  pageSize = 20;
  pageSizeOptions: any = [20];
  pageEvent: any;
  pageIndex: number = 0;
  currentPage: number = 0;

  displayedColumnsInUserNote: string[] = ["creation_date", "description", "actions"];

  displayedColumns: string[] = ["active", "email", "name", "country", "document_number", "contact_name", "phone_number", "complete_data", "actions"];
  searchText: string = "";//variable para realizar la busqueda, usado en el input search
  headers: any = ["email", "name", "country", "document_number", "contact_name", "phone_number"];//propiedades a exportar de los distribuidores
  sortedDistributorsData: any = [];//para ordenar datos en tabla
  managerId: number;
  user: any = {};//datos de validacion del usuario
  isUpdate: boolean = false;//para indicar si es actualizacion en el modal de registro o actualizacion del distribuidor

  loading: boolean = false;//para loading

  //variables para la agregacion de notas al usuario a actualizar
  userNotesModal: boolean = false;
  userNotes: any = [];//alamcena las notas del dispositivos, notas son aquellas novedades que se registran acerca de lo que se le ha hecho al dispositivo
  userIdToAddNote: number = 0;//almacena el id del dispositivo a consultar las notas, 
  datetimeNote: string = "";//fecha de realizacion de la nota
  loadingUserNotes: boolean = false;
  modal: boolean = false;//para ventana modal
  distributorToUpdate: any = [];//almacena los datos del usuario al que se va a realizar la actualizacion de datos

  billingPlanType: any = 'standardized';//indica el tipo de plan de facturacion, si es personalizado o es un plan estandarizado
  formModal: boolean = true;//para modal de registros, 
  permissionsListModal: boolean = false;//opciones de permisos para el formulario de registro
  distributorsToShowInTable: any = [];//almancena la lista de distribuidores a mostrar en la tabla
  newDistributor: any = {
    contact_name: "",
    country_id: null,
    business_name: "",//es el name
    document_number: "",//es el document_number
    phone_number: "",
    email: "",
    password: "",
    password_confirmation: "",
    personalized_plan_id: null,
    phone_prefix: null,
    legal_personality_type: null,
    courtesy_title: null,
    identification_document_type_id: null,
    personalized_plan: {
      id: null,
      monetary_unit_id: "",
      billing_frequency_id: "",
      payday: null,
      unit_price: null
    },
    available_maps: [28, 29, 30, 31, 32, 33],
    is_update_password: false
  };//el  usuario a registrar o actualizar;
  identificationDocumentTypeList: any = [];/**Almacena la lista de tipos de documentos disponibles para asignar al usuario segun el pais seleccionado */
  newUserIdentificationDocumentType: any = {};//almacena el tipo de documento seleccionado para el usuario editado

  newDistributorEmptyCopy = this.utils.copyObject(this.newDistributor);
  billingFrequenciesList: any = [];
  monetaryUnitsList: any = [];
  platformList: any = [];//almacena la lista de planes disponibles para los distribuidores
  countriesList: any = [];//alamcena la lista de paises;
  distributors: any = [];//alamcena la lista de  distribuidores
  
  /**
   * @type {string} El valor de ciduad a buscar dentro de la lista de ciudades
   */
  cityToSearch: string = "";
  citiesListFilter: ICity[] = [];//almacena la lista de ciudades;
  citiesList: ICity[] = [];//almacena la lista de ciudades;
  
  permissionsIntgps: any = [];//los permisos del usuario logueado
  permissionsDataSgc: any = [];
  moduleId = 4;
  constructor(
    private api: ApiService,
    public dataService: DataService,
    public utils: UtilsService,
    private router: Router,
    private renderer: Renderer2,
    public validationService: ValidationService,
    public objectInitialization: ObjectInitializationService
  ) {
    this.user = this.dataService.getData("user");
    this.managerId = this.user.id;
    this.newDistributor = this.objectInitialization.initializeIUserGeneral(this.user.id,this.dataService.availableMaps,DevicePlanType.STANDARDIZED);
  }

  ngOnInit(): void {
    this.dataService.checkPermissionModule(this.moduleId).then((permissions: any) => {
      this.permissionsDataSgc = permissions;
      this.permissionsIntgps = this.dataService.getData("permissionsIntgps");
      this.sortedDistributorsData = this.distributors.slice();
      this.getList();
      this.getIdentificationDocumentTypes();
    }).catch(() => {
      this.utils.showMsg("Página no autorizada", "No tiene permisos para ver esta página, contacte al administrador");
      this.router.navigate(['/']);
    });
  }
  paginator($event: any) {
    //this.getList($event.pageIndex+1);
    this.showLoading(true);
    setTimeout(() => {
      this.currentPage = $event.pageIndex + 1;
      let from = (this.currentPage - 1) * this.pageSize;
      this.distributorsToShowInTable = this.sortedDistributorsData.slice();
      this.distributorsToShowInTable = this.distributorsToShowInTable.slice(from, (from + this.pageSize));
      this.showLoading(false);
    }, 400);
  }
  sortData(sort: Sort) {
    const data = this.distributors.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedDistributorsData = data;
      return;
    }

    this.sortedDistributorsData = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'active':
          return this.compare(a.active, b.active, isAsc);
        case 'email':
          return this.compare(a.email, b.email, isAsc);
        case 'name':
          return this.compare(a.name, b.name, isAsc);
        case 'document_number':
          return this.compare(a.document_number, b.document_number, isAsc);
        case 'plate_number':
          return this.compare(a.plate_number, b.plate_number, isAsc);
        case 'contact_name':
          return this.compare(a.contact_name, b.contact_name, isAsc);
        case 'phone_number':
          return this.compare(a.phone_number, b.phone_number, isAsc);
        case 'complete_data':
          return this.compare(a.complete_data, b.complete_data, isAsc);
        case 'country':
          return this.compare(this.getCountryName(a.country_id), this.getCountryName(b.country_id), isAsc);
        default:
          return 0;
      }
    });
    let quantity = this.pageSize;
    let from = (this.currentPage - 1) * quantity;
    this.distributorsToShowInTable = this.sortedDistributorsData.slice();
    this.distributorsToShowInTable = this.distributorsToShowInTable.slice(from, (from + quantity));
  }
  //metodo usado para realizar las comparaiciones en la ordenacion de datos para la tabla
  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
  toggleMessageActive(activeUser: number) {
    return activeUser == 1 ? "Desactivar distribuidor" : "Activar distribuidor";
  }
  //metodo para la tabla de distribuidores, que indica si el usuario esta activo o inactivo
  isActive(activeUser: number) {
    return activeUser == 1 ? true : false;
  }
  statusChange(event: any, user: any) {
    let userData = {
      user_api_hash: this.user.hash,//del manager
      id: user.id,
      active: event.checked ? 1 : 0,
      action: "changeStatus"
    };
    this.api.createData(userData, "user", true).then((data: any) => {
      this.showLoading(false);
      if (data.status == 1) {
        this.utils.showMsg("", "Estado de usuario actualizado");
      } else {
        this.utils.showMsg("", "No se pudo cambiar el estado del usuario");
      }
      this.getList(this.currentPage);
    }).catch((err: any) => {
      this.showLoading(false);
      this.utils.showMsg("", "Se ha presentado problemas al actualizar el estado");
    });
  }
  search() {
    this.searchText = this.searchText.toLowerCase();
    if (this.searchText.length > 0) {
      this.distributorsToShowInTable = this.sortedDistributorsData.filter((user: any) => {
        return user.email.toLowerCase().includes(this.searchText) || user.name.toLowerCase().includes(this.searchText);
      });
    } else {
      this.distributorsToShowInTable = this.sortedDistributorsData;
    }
    let from = (0) * this.pageSize;
    this.distributorsToShowInTable = this.distributorsToShowInTable.slice(from, (from + this.pageSize));
  }
  clearSearchCity(){
    this.cityToSearch = "";
    this.searchCity();  
  }
  searchCity() {
    this.cityToSearch = this.cityToSearch.toLowerCase() ?? "";
    this.citiesListFilter = this.cityToSearch.length > 0 ? this.citiesList.filter((user: ICity) => user.name.toLowerCase().includes(this.cityToSearch)) : this.citiesList;
  }
  getIdentificationDocumentTypes(): void {
    if (this.validationService.isNullOrEmpty(this.newDistributor.country_id))
      return;
    this.identificationDocumentTypeList = [];
    this.api.getData("identificationDocumentType", "?action=getDocumentTypes&user_api_hash=" + this.user.hash + "&country_id=" + this.newDistributor.country_id, true).then((data: any) => {
      this.identificationDocumentTypeList = data.status ? data.data : [];
      this.showIdentificationDocumentType();
    }).catch((error: any) => { this.identificationDocumentTypeList = [] });
  }
  showIdentificationDocumentType() {
    this.newUserIdentificationDocumentType = this.identificationDocumentTypeList.find((type: any) => type.id == this.newDistributor.identification_document_type_id);
  }
  /**
   * 
   * @param {boolean} resetSelectedCity Indica si se va a reiniciar el valor de la ciudad actualmente asignada a lusuario
   * @returns 
   */
  async getCitiesList(resetSelectedCity:boolean = true): Promise<void> {
    if(resetSelectedCity)
      this.newDistributor.city_id = null;
    if (this.validationService.isNullOrEmpty(this.newDistributor.country_id))
      return;
    try {
      this.citiesList = [];
      let data: IApiRequestData = {user_api_hash:this.user.hash, action:"get", country_id: this.newDistributor.country_id};
      let response: unknown = await this.api.getDataPost("city", data, true);      
      if(this.validationService.isResponseApi(response))
        this.citiesList = response.status ? ( response.data as ICity[] ) : [];
      this.citiesListFilter = this.utils.copyObject(this.citiesList);
    } catch (error:unknown) { this.citiesList = [];}
  }
  getList(page: number = 1) {
    this.currentPage = page;
    this.showLoading(true);
    let data = {
      user_api_hash: this.user.hash,
      action: "getUsersList"
      //page:page
    };
    this.api.getDataPost("user", data, true).then((data: any) => {
      if (data.status == 1) {
        this.lengthPage = data.totalIntgps;
        let distributorsData: any = [];//alamcena la lista de usuarios con propiedades agregadas que se usan en sgc
        for (let distributorIntgps of data.data) {
          let isCompleteData = this.isDataDistributorComplete(distributorIntgps);
          if (isCompleteData) {
            distributorIntgps["complete_data"] = 1;
          } else {
            distributorIntgps["complete_data"] = 0;
          }
          distributorsData.push(distributorIntgps);
        }
        this.distributors = distributorsData;
        this.sortedDistributorsData = this.distributors;

        this.sortedDistributorsData = this.distributors.slice();
        let quantity = this.pageSize;
        let from = (page - 1) * quantity;
        this.distributorsToShowInTable = this.distributors.slice();
        this.distributorsToShowInTable = this.distributorsToShowInTable.slice(from, (from + quantity));

        this.api.getData("country", "?action=getCountries&user_api_hash=" + this.user.hash, true).then((countriesData: any) => {
          if (countriesData.status == 1) {
            this.countriesList = countriesData.data;
            this.api.getData("plan", "?action=getMonetaryUnits&user_api_hash=" + this.user.hash, true).then((monetaryUnitsData: any) => {
              if (monetaryUnitsData.status == 1) {
                this.monetaryUnitsList = monetaryUnitsData.data;
                this.api.getData("plan", "?action=getBillingFrequencies&user_api_hash=" + this.user.hash, true).then((billingFrequenciesData: any) => {
                  if (billingFrequenciesData.status == 1) {
                    this.billingFrequenciesList = billingFrequenciesData.data;
                  }
                  this.showLoading(false);
                }).catch((error) => { });

                this.api.getData("platformPlan", "?action=getPlatformPlansBasicData&for_role_id=3&user_api_hash=" + this.user.hash, true).then((platformPlansData: any) => {
                  if (platformPlansData.status == 1)
                    this.platformList = platformPlansData.data;
                  this.showLoading(false);
                }).catch((error) => { });
              }
            }).catch((error) => { });
          }
        }).catch((error) => { });
      }
    }).catch((error) => {
      this.distributors = [];
      this.utils.showMsg("", "Error al cargar datos");
      this.showLoading(false);
    });

  }
  //comprueba si aquellos datos del usuario que hacen parte  de la tabla donde se muestran los usuarios estan completos
  isDataDistributorComplete(distributor: any): boolean {
    return (!this.utils.isNullOrEmpty(distributor.phone_number) && !this.utils.isNullOrEmpty(distributor.name) && !this.utils.isNullOrEmpty(distributor.document_number) && !this.utils.isNullOrEmpty(distributor.contact_name) && !this.utils.isNullOrEmpty(distributor.country_id) && !this.utils.isNullOrEmpty(distributor.logo_url) && !this.utils.isNullOrEmpty(distributor.sign_url));
  }
  openUpdateDistributorModal(distributor: any): void {
    this.userIdToAddNote = distributor.id;
    this.billingPlanType = (distributor.personalized_plan_id != null && distributor.personalized_plan_id != "" && distributor.personalized_plan_id > 0) ? 'personalized' : 'standardized';
    this.newDistributor = JSON.parse(JSON.stringify(distributor));
    this.newDistributor["permissions"] = [];
    this.structureNewuserPermissionsToUpdate();
    this.getIdentificationDocumentTypes();
    this.getCitiesList(false);
    this.modal = true;
    this.isUpdate = true;
    this.showLoading(true);

    this.api.getData("user", "?action=getPermissionsList&user_api_hash=" + this.user.hash + "&user_id=" + distributor.id, true).then((permissionsData: any) => {
      if (this.validationService.isResponseApi(permissionsData)){
        if(permissionsData.status == 1)
          this.structureNewuserPermissionsToUpdate(permissionsData.data);
        else 
          this.utils.showResultRequest("error","Información", "No se han obtenido los permisos actuales del usuario de forma correcta");
      }
    }).catch(() => { 
      this.utils.showResultRequest("error","Información",this.api.getDefaultMessage("los permisos del usuario",false,false,RequestMethodHTTP.GET));
    }).finally(() => this.showLoading(false));
  }
  openCreateUserModal(): void {
    this.citiesList = this.citiesListFilter = [];
    this.billingPlanType = 'standardized';//por defecto es el plan estandarizado
    this.newDistributor = this.newDistributorEmptyCopy;
    this.structureNewuserPermissionsToUpdate();
    this.predefinePermissionsForNewUser();
    this.modal = true;
    this.isUpdate = false;
  }

  deleteDistributor(userToDelete: any) {
    this.utils.showConfirm("Confirmar acción", "Esta seguro de eliminar al usuario '" + userToDelete.email + "'", "Eliminar", "Cancelar").then(() => {
      let data = "?action=delete&user_api_hash=" + this.user.hash + "&id=" + userToDelete.id + "&email=" + userToDelete.email;
      this.api.deleteData("user", data, true).then((data: any) => {
        if (data.status == 1) {
          this.utils.showMsg("Acción completada", "Se ha eliminado al usuario");
          this.getList(this.currentPage);
        } else {
          this.utils.showMsg("Información", data.message);
        }
        this.showLoading(false);
      }).catch((err: any) => {
        this.showLoading(false);
        this.utils.showMsg("Acción no completada", "Se presento problemas al eliminar el usuario");
      });
    }).catch(() => {
      return;
    });
  }
  showDistributorLoading(update: boolean = false) {
    Swal.fire({
      title: !update ? 'Creando distribuidor' : 'Actualizando distribuidor...',
      html: '<i class="fa-solid fa-user-plus fa-2x fa-beat-fade"></i>',
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      showCancelButton: false,
      showConfirmButton: false,
    });
  }
  selectFile(file: any, type: string) {
    return (typeof file == "undefined" || file == "") ? "sample.png" : file;
  }
  loadFileIntoImgElement($event: any, type: string) {
    if (typeof $event.target.files != "undefined" && $event.target.files[0]) { //Revisamos que el input tenga contenido
      let reader = new FileReader(); //Leemos el contenido

      let input = this.renderer.selectRootElement(`.${type}`, true);
      reader.readAsDataURL($event.target.files[0]);
      reader.onloadend = function () {
        input.src = reader.result;
      }
    }
  }
  showMessageAboutFile(fileName: string, name: string) {
    return ((typeof fileName != "undefined" && fileName != "") ? ("Cambiar ") : ("Subir")) + name;
  }
  adminDistributor($event: any, isUpdate: boolean) {
    $event.preventDefault();
    let validatePassword: boolean = !isUpdate || (isUpdate && typeof this.newDistributor.is_update_password != "undefined" && this.newDistributor.is_update_password);
    let error = "";
    if (this.newDistributor.email.trim() == "") {
      error = "Falta ingresar el email";
    } else if (validatePassword && this.newDistributor.password.trim() == "") {
      error = "Falta ingresar contraseña";
    } else if (validatePassword && this.newDistributor.password_confirmation.trim() == "") {
      error = "Falta que confirme la contraseña";
    } else if (validatePassword && this.newDistributor.password_confirmation.trim() != this.newDistributor.password.trim()) {
      error = "Las contraseñas no coinciden";
    }
    this.utils.showConfirm("Confirmar acción", "Esta seguro de " + (isUpdate ? "actualizar" : "crear") + " a este usuario", "Sí, " + (isUpdate ? "actualizar" : "crear"), "Cancelar").then((data: any) => {
      this.showDistributorLoading(isUpdate);
      let formData: any = new FormData($event.target);//datos para INTGPS
      let formDataSGC: any = new FormData($event.target);//datos para SGC
      if (this.billingPlanType == "personalized") {//si es igual a un plan personalizado
        if (formDataSGC.get("personalized_plan[unit_price]") == "" && formDataSGC.get("personalized_plan[monetary_unit_id]") == "" && formDataSGC.get("personalized_plan[billing_frequency_id]") == "" && formDataSGC.get("personalized_plan[payday]") == "") {
          error = "Faltan datos importantes para la creación del plan de facturación";
        }
        formDataSGC.set("personalized_plan[monetary_unit_id]",this.newDistributor.personalized_plan.monetary_unit_id)
        formDataSGC.set("personalized_plan[billing_frequency_id]",this.newDistributor.personalized_plan.billing_frequency_id)
      }
      if (error != "") {
        this.utils.showMsg("", error, false);
        this.showLoading(false);
        return;
      }
      if (validatePassword) {
        formData.set("password", this.newDistributor.password);
        formData.set("password_confirmation", this.newDistributor.password_confirmation);
      } else {
        formData.delete("password");
        formData.delete("password_confirmation");
      }
      formData.set("user_api_hash", this.user.hash);
      formData.set("group_id", isUpdate ? this.newDistributor.group_id : 3);
      formData.set("available_maps", "");

      let names: string[] = [];
      for (let key of formData.keys()) {
        names.push(key);
      }
      for (let name of names) {
        if (name == "contact_name" || name == "country_id" ||
          name == "address" || name == "business_name" || name == "document_number" ||
          name == "file_logo" || name == "file_sign" || name == "platform_plan_id") {
          formData.delete(name);
        }
      }
      names = [];
      for (let key of formDataSGC.keys()) {
        names.push(key);
      }
      for (let name of names) {
        if (name != "contact_name" && name != "country_id" && name != "address" &&
          name != "business_name" && name != "document_number" && name != "email" &&
          name != "file_logo" && name != "file_sign" && name != "personalized_plan[unit_price]" &&
          name != "personalized_plan[monetary_unit_id]" && name != "personalized_plan[billing_frequency_id]" &&
          name != "personalized_plan[payday]" && name != "platform_plan_id") {
          formDataSGC.delete(name);
        }
      }
      formDataSGC.set("role_id", formData.get("group_id"));
      formDataSGC.set("user_api_hash", formData.get("user_api_hash"));
      formDataSGC.set("plan_type", this.billingPlanType);
      formDataSGC.set("phone_prefix", this.newDistributor.phone_prefix);
      formDataSGC.set("identification_document_type_id", this.newDistributor.identification_document_type_id);
      formDataSGC.set("legal_personality_type", this.newDistributor.legal_personality_type);
      formDataSGC.set("courtesy_title", this.newDistributor.courtesy_title);
      if(this.newDistributor.city_id != null)
        formDataSGC.set("city_id", this.newDistributor.city_id);
      if(this.newDistributor.country_id != null)
        formDataSGC.set("country_id", this.newDistributor.country_id);
      if(this.newDistributor.platform_plan_id != null)
        formDataSGC.set("platform_plan_id", this.newDistributor.platform_plan_id);
      let mapsToReview:number[] = [...(this.newDistributor.available_maps??[]), ...(this.newDistributorEmptyCopy.available_maps ?? []) ];
      for (let map of (isUpdate ? this.newDistributor.available_maps : (this.newDistributorEmptyCopy.available_maps ?? []))) {
        if (mapsToReview.some((mapId: number) => Number(map) == Number(mapId)))
          formData.append("available_maps[]", map);
      }
      /** Los campos se sobreescriben de acuerdo a los valores presentes en el name de los campos de formulario */
      //this.utils.fillPermissionsFormData(formData,this.newDistributor.permissions);

      let namePermission: any, valuesPermission: any;
      for ([namePermission, valuesPermission] of Object.entries(this.newDistributor.permissions_to_update)) {
        formData.set("perms[" + namePermission + "]" + "[view]", valuesPermission.view ? 1 : 0);
        formData.set("perms[" + namePermission + "]" + "[edit]", valuesPermission.edit ? 1 : 0);
        formData.set("perms[" + namePermission + "]" + "[remove]", valuesPermission.remove ? 1 : 0);
      }

      if (isUpdate) {
        formData.set("id", this.newDistributor.id);
        formData.set("_method", "PUT");
        formData.set("active", this.newDistributor.active);
        formData.set("billing_plan_id", this.newDistributor.billing_plan_id);
        formData.set("email", this.newDistributor.email);
        formData.set("map_id", this.newDistributor.map_id);
        if (this.newDistributor.subscription_expiration != "0000-00-00 00:00:00") {
          formData.set("enable_expiration_date", 1);
          formData.set("expiration_date", this.newDistributor.subscription_expiration);
        }
        formData.set("week_start_day", this.newDistributor.week_start_day);
        formData.set("top_toolbar_open", this.newDistributor.top_toolbar_open);
        formData.set("unit_of_altitude", this.newDistributor.unit_of_altitude);
        formData.set("lang", this.newDistributor.lang);
        formData.set("unit_of_distance", this.newDistributor.unit_of_distance);
        formData.set("unit_of_capacity", this.newDistributor.unit_of_capacity);
        formData.set("timezone_id", this.newDistributor.timezone_id);
        formData.set("sms_gateway", this.newDistributor.sms_gateway);
        formData.set("sms_gateway_url", this.newDistributor.sms_gateway_url);

        if (this.newDistributor.devices_limit == null) {
          formData.set("devices_limit", 0);
        } else {
          formData.set("enable_devices_limit", 1);
          formData.set("devices_limit", this.newDistributor.devices_limit);
        }
        this.api.createData(formData, "api/admin/client", false).then((data: any) => {

          if (data.status == 1) {
            formDataSGC.set("id", this.newDistributor.id);
            formDataSGC.set("action", "update");
            formDataSGC.set("personalized_plan_id", this.newDistributor.personalized_plan_id);
            formDataSGC.set("personalized_plan[id]", this.newDistributor.personalized_plan_id);
            formDataSGC.set("manager_id", this.newDistributor.manager_id);
            formDataSGC.set("logo_url", this.newDistributor.logo_url);
            formDataSGC.set("sign_url", this.newDistributor.sign_url);
            formDataSGC.set("email", this.newDistributor.email);

            this.api.createData(formDataSGC, "user", true, "multipart/form-data").then((data: any) => {
              if (data.status == 1) {
                this.modal = false;
                let message = data.message + "<br><span class='text-small'>";
                if (typeof data.infoLogo != "undefined" && data.infoLogo.status != 1 && typeof data.infoSign != "undefined" && data.infoSign.status != 1) {
                  message += "logo: " + data.infoLogo.message + " | firma: " + data.infoLogo.message;
                }
                else if (typeof data.infoSign != "undefined" && data.infoSign.status != 1) {
                  message += "La firma no pudo ser almacenada (" + data.infoLogo.message + ")";
                }
                else if (typeof data.infoLogo != "undefined" && data.infoLogo.status != 1) {
                  message += "El logo no pudo ser almacenado (" + data.infoLogo.message + ")";
                }
                this.getList(this.currentPage);
                this.utils.showMsg("", message + "</span>");
              } else {
                this.showLoading(false);
                this.utils.showMsg("", data.message);
              }
            }).catch((err: any) => {
              this.showLoading(false);
              this.utils.showMsg("", "Se presentó un error al actualizar al distribuidor en SGC");
            });

          }
        }).catch((error: unknown) => {
          this.showLoading(false);
          let message = "";
          if (error instanceof HttpErrorResponse)
            message = error.error.message ?? "";
          this.utils.showMsg("", message ?? "No se pudo realizar la actualización en plataforma GPS");
        });

      } else {
        this.api.createData(formData, "api/admin/client", false).then((registeredDistributor: any) => {

          if (registeredDistributor.status > 0) {
            formDataSGC.set("id", registeredDistributor.item.id);//id del usuario registrado             
            formDataSGC.set("action", "add");
            this.api.createData(formDataSGC, "user", true, this.api.CONTENT_TYPE.FORM_DATA).then((data: any) => {

              this.showLoading(false);
              if (data.status == 1) {
                let message = data.message + "<br>";
                if (typeof data.infoLogo != "undefined" && data.infoLogo.status != 1 && typeof data.infoSign != "undefined" && data.infoSign.status != 1) {
                  message += "No se almacenó la firma y el logo";
                }
                else if (typeof data.infoSign != "undefined" && data.infoSign.status != 1) {
                  message += "La firma no pudo ser almacenada";
                }
                else if (typeof data.infoLogo != "undefined" && data.infoLogo.status != 1) {
                  message += "El logo no pudo ser almacenado";
                }
                this.modal = false;
                this.utils.showMsg("", message);
                this.getList(this.currentPage);
                this.newDistributor = this.newDistributorEmptyCopy;
              } else {
                this.utils.showMsg("", data.message);
              }
            }).catch((err: any) => {
              this.showLoading(false);
              this.utils.showMsg("", err.error.message ?? this.api.getDefaultMessage("al distribuidor", isUpdate, false, "POST"));
            });
          }
        }).catch((err: any) => {
          this.showLoading(false);
          let message = "error al crear distribuidor en INTGPS"
          if (typeof err.error.message != "undefined") {
            message = err.error.message;
          }
          this.utils.showMsg("", message);
        });
      }
    }).catch((error: any) => {
      this.showLoading(false);
      return;
    });
  }
  structureNewuserPermissionsToUpdate(userPermissions: IPermissionsIntgps[] = []) {
    let userPerms: IPermissionsIntgps[] = userPermissions;
    let newPerms: IPermissionsIntgps[] = [];
    this.newDistributor.permissions_to_update = [];
    for (let permission of this.objectInitialization.initializeIPermissionsIntgps(this.newDistributor.id,USER_ROLES_ID.MANAGER)) {
      let permissionIndex = userPerms.findIndex((perm: IPermissionsIntgps) => perm.name == permission.name);
      let newPermission = (permissionIndex !== -1) ? userPerms[permissionIndex] : permission;
      newPerms.push(newPermission);
      this.newDistributor.permissions_to_update[newPermission.name] = {};
      Object.defineProperties(this.newDistributor.permissions_to_update[newPermission.name], {
        view: { value: parseInt(newPermission.view.toString()), writable: true },
        edit: { value: parseInt(newPermission.edit.toString()), writable: true },
        remove: { value: parseInt(newPermission.remove.toString()), writable: true },
      });
    }
    this.newDistributor["permissions"] = newPerms;
  }
  predefinePermissionsForNewUser(){
    for (let permission of this.newDistributor.permissions!) {
      if (permission.name == "devices") {
        this.newDistributor.permissions_to_update[permission.name]["view"] = 1;
        this.newDistributor.permissions_to_update[permission.name]["edit"] = this.newDistributor.permissions_to_update[permission.name]["remove"] = 0;
      } else if (permission.name == "tasks") {
        this.newDistributor.permissions_to_update[permission.name]["edit"] = this.newDistributor.permissions_to_update[permission.name]["view"] = 1;
        this.newDistributor.permissions_to_update[permission.name]["remove"] = 0;
      } else if (permission.name == "call_actions" || permission.name == "device_route_types") {
        this.newDistributor.permissions_to_update[permission.name]["remove"] = this.newDistributor.permissions_to_update[permission.name]["edit"] = this.newDistributor.permissions_to_update[permission.name]["view"] = 0;
      }else if(permission.name == "chat" || permission.name == "camera" || permission.name == "device_camera"){
        this.newDistributor.permissions_to_update[permission.name]["view"]= this.newDistributor.permissions_to_update[permission.name]["edit"]= this.newDistributor.permissions_to_update[permission.name]["remove"] = 0;   
      }else if(permission.name == "users"){
        this.newDistributor.permissions_to_update[permission.name]["view"]= this.newDistributor.permissions_to_update[permission.name]["edit"]= 1;
        this.newDistributor.permissions_to_update[permission.name]["remove"] = 0;  
      }else if(permission.name == "device.expiration_date"){
        this.newDistributor.permissions_to_update[permission.name]["remove"]= this.newDistributor.permissions_to_update[permission.name]["edit"]= 0;
        this.newDistributor.permissions_to_update[permission.name]["view"] = 1;  
      }
      else {
        let managerperms = this.permissionsIntgps[permission.name];
        this.newDistributor.permissions_to_update[permission.name].view = managerperms.view;
        this.newDistributor.permissions_to_update[permission.name].edit = managerperms.edit;
        this.newDistributor.permissions_to_update[permission.name].remove = managerperms.remove;
      }
    }
  }
  //para saber si un permiso esta deshabilitado, se busca entre los permisos del admin
  isPermissionDisabled(permissionName: string, permissionType: string) {
    if ((permissionName == "call_actions" || permissionName == "device_route_types") || (permissionName == "users" && permissionType == "remove"))
      return false;
    return !this.permissionsIntgps[permissionName][permissionType];
  }
  //permite saber si un permiso ha sido seleccionado por el usuario
  isPermissionSelected(isUpdate: boolean, permissionName: string, permissionType: any, permissions?: any): boolean {
    let response = false;
    //primero los permisos del administrador para habilitar dichas opciones
    if (isUpdate) {
      let permission: any = [];
      //en services esta almacenado los servicios que han sido seleccionados por el usuario
      if (permissions.length > 0) {
        permission = permissions.find((permission: any) => permission.name == permissionName);
        if (typeof permission != "undefined") {
          if (typeof permissionType != "undefined") {
            if (typeof permissionType != "object") {
              response = permission[permissionType] == 0 ? false : true;
            } else {
              for (let type of permissionType) {
                response = permission[type] == 0 ? false : true;
                if (response == true) {
                  break;
                }
              }
            }
          }
        }
      }
    } else {
      if (typeof permissionType != "object") {
        if (permissionName == "devices" && (permissionType == "edit" || permissionType == "remove"))
          response = false;
        else if (permissionName == "tasks" && permissionType == "remove")
          response = false;
        else if (permissionName == "history" && permissionType == "remove")
          response = false;
        else if (permissionName == "call_actions" || permissionName == "device_route_types")
          response = false;
        else if (permissionName == "device.expiration_date" && permissionType == "edit")
          response = false;
        else {
          response = this.permissionsIntgps[permissionName][permissionType];
        }
      } else {
        for (let type of permissionType) {//edit y remove
          response = this.permissionsIntgps[permissionName][type];
          if (response == true) {
            break;
          }
        }

        if (permissionName == "devices" || permissionName == "device_route_types" || permissionName == "call_actions") {
          response = false;
        }

      }
    }
    return response;
  }

  //modifica seleccion de permisos
  modifySelection($event: any, option?: string) {
    let row = this.renderer.parentNode(this.renderer.parentNode(this.renderer.parentNode($event.target)));
    let viewInput = row.children[1].querySelector("input");//el uno es el input view
    if ($event.target.checked && (option == "edit" || option == "remove")) {
      viewInput.checked = true;
      viewInput.disabled = true;
    } else if ($event.target.checked == false && (option == "edit" || option == "remove")) {
      let editInput = row.children[2].querySelector("input");//el uno es el input edit
      let removeInput = row.children[3].querySelector("input");//el uno es el input remove
      if (editInput.checked == false && removeInput.checked == false) {
        viewInput.disabled = false;
      }
    }
  }
  changeBillingPlanType($event: any) {
    if (this.isUpdate) {
      this.utils.showConfirm("Confirmar cambio", "Si cambia el tipo de plan seleccionado entonces cualquier asociación al plan actual será eliminada de forma definitiva una vez de en actualizar", "Confirmar", "Cancelar").then(() => {
        this.billingPlanType = $event.value;
      }).catch(() => {
        this.billingPlanType = $event.value = $event.value == "personalized" ? "standardized" : "personalized";
      });
    } else {
      this.billingPlanType = $event.value;
    }

  }
  //para exportar los datos del formulario
  exportFile() {
    this.utils.showConfirm("¿Está seguro de exportar los datos?", "Los datos pueden tomar un tiempo en procesarse", "Continuar", "Cancelar").then(() => {
      this.loading = true;
      let dataToExport: any = [];
      for (let distributor of this.distributors) {
        dataToExport = distributor;
        dataToExport["country"] = this.getCountryName(distributor["country_id"]);
      }

      setTimeout(() => {
        this.utils.downloadFileAsCSV(this.distributors, "export_" + new Date().getTime().toString(), this.headers);
        this.loading = false;
        this.utils.showMsg("", "Datos exportados con éxito");
      }, 2500);
    });
  }

  getCountryName(id: number) {
    let name = this.countriesList.find((item: any) => item.id == id);
    if (typeof name == "undefined") {
      return "";
    }
    return name["name"];
  }
  showLoading(show: boolean = true): void {
    if (show) this.loading = true;
    else setTimeout(() => { this.loading = false; }, 500);
  }
  //TODO Cerrar Modal
  closeModal() { this.modal = false; this.isUpdate = false; this.formModal = true; this.userNotesModal = false;; this.permissionsListModal = false; this.userNotes = []; this.userIdToAddNote = 0; this.identificationDocumentTypeList = []; }
  //para la agregacion de notas al usuario
  closeNotesView() {
    this.permissionsListModal = false;
    this.formModal = true;
    this.userNotesModal = false;
  }
}