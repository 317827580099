<section class="form-box-container animate__animated animate__fadeIn" *ngIf="viewsAvailable['payment_management_list'].show_view">
    <section class="form-box-container__header">
        <h2 class="form-box-container__header-title"><span class="icon"><i class="fa-solid fa-money-check-dollar"></i></span>Administracion de pagos</h2>
        <div class="form-box-container__header-options">
            <button class="btn btn-primary border-primary overflow-hidden" matTooltip="Filtrar por rango de fecha">
                <mat-datepicker-toggle style="display:inline-block; font-size: 0.5rem;opacity:0;position:absolute;inset:0" matIconSuffix [for]="expirationRangePicker"></mat-datepicker-toggle>
                <i class="fa-solid fa-calendar-week fa-sm"></i> <span  class="description"> Filtro</span>
                <mat-form-field class="animate__animated animate__fadeIn " style="width: 0;height: 0;position:absolute;opacity: 0;"  appearance="fill">
                    <mat-label>Ingrese un rango de fecha</mat-label>

                    <mat-date-range-input  class="d-none"  disabled [formGroup]="expirationRange" [rangePicker]="expirationRangePicker">
                    <input formControlName="start"   matStartDate placeholder="yyyy-mm-dd">
                    <input formControlName="end" matEndDate placeholder="yyyy-mm-dd">
                    </mat-date-range-input>
                    
                    <mat-date-range-picker class="d-none" #expirationRangePicker disabled="false">
                    <mat-date-range-picker-actions>
                        <button class="btn btn-outline-secondary" style="margin:0.1rem;" mat-button matDateRangePickerCancel>Cancelar</button>
                        <button class="btn btn-primary" mat-raised-button color="primary" (click)="filterDevicesByDateRange(expirationRange.value.start,expirationRange.value.end)"  matDateRangePickerApply>Aceptar</button>
                    </mat-date-range-picker-actions>
                    </mat-date-range-picker>
                </mat-form-field>
            </button>
            <button mat-icon-button [matMenuTriggerFor]="headerOptions" matTooltip="Ver más opciones" matTooltipPosition="above" aria-label="Opciones"><mat-icon>more_vert</mat-icon></button>
            <mat-menu #headerOptions="matMenu" class="actions">
                <button mat-menu-item matTooltipPosition="left"  [ngClass]="{'text-primary': deviceExpTableDataSource=='soonToExpire'?allUserDevices.devicesExpired.length !=0 :allUserDevices.devicesSoonToExpire.length != 0}" [disabled]="deviceExpTableDataSource=='soonToExpire'?allUserDevices.devicesExpired.length==0:allUserDevices.devicesSoonToExpire.length==0"  matTooltip="{{(deviceExpTableDataSource=='soonToExpire' && allUserDevices.devicesExpired.length == 0 || deviceExpTableDataSource=='expired' && allUserDevices.devicesSoonToExpire.length == 0 ? 'Sin datos para el rango de fecha indicada' :  ('Cargar dispositivos ' + (deviceExpTableDataSource=='soonToExpire'?'expirados':'próximos a vencer') ))}}"(click)="toggleLoadExpirationTableDate()" ><i class="fa-solid fa-arrow-right-arrow-left"></i> Ver {{deviceExpTableDataSource=='soonToExpire'?'expirados':'próximos a vencer'}}</button>

                <button mat-menu-item matTooltipPosition="left" class="text-primary" matTooltip="Ver registro de pagos" (click)="utils.setSelectedView(this.viewsAvailable,this.viewsAvailable['payment_record_list'].name)"><i class="fa-solid fa-file-invoice"></i> Registro de pagos</button>

                <button mat-menu-item class="text-primary" name="exportMenu"
                [matMenuTriggerFor]="exportMenu"><i class="fa-solid fa-download"></i> Exportar</button>
            </mat-menu>
            <mat-menu #exportMenu="matMenu" > 
                <div class="inputs-list">
                    <mat-checkbox 
                    name="expired"
                    class="text-primary"
                    color="primary" id="expired" (click)="$event.stopPropagation()"
                    [matTooltip]="allUserDevices.devicesExpired.length==0?'No hay datos disponibles':'Exportar dispositivos expirados'" matTooltipPosition="left" 
                    [(ngModel)]="sourceToExport.expired" [disabled]="allUserDevices.devicesExpired.length==0"
                    >Expirados</mat-checkbox>
                    <mat-checkbox 
                    class="text-primary"
                    color="primary" name="soonToExpire" id="soonToExpire" (click)="$event.stopPropagation()"
                    [matTooltip]="allUserDevices.devicesSoonToExpire.length==0?'No hay datos disponibles':'Exportar dispositivos pronto a expirar'" matTooltipPosition="left"
                    [(ngModel)]="sourceToExport.soonToExpire" [disabled]="allUserDevices.devicesSoonToExpire.length==0"  
                    >pronto a expirar</mat-checkbox>
                </div>
                <mat-divider></mat-divider>
                <div class="mat-menu-content__actions"><button mat-button class="btn btn-primary"  [disabled]="(!sourceToExport.expired && !sourceToExport.soonToExpire) ||(allUserDevices.devicesSoonToExpire.length==0 && allUserDevices.devicesExpired.length==0)"  (click)="exportFile()">Aceptar</button></div>
            </mat-menu>
            <div class="search">
                <input id="inputSearch" type="search" name="inputSearch" placeholder="Buscar" (input)="searchInGeneralTable()" [(ngModel)]="searchValueInGeneralTable">
                <label for="inputsearch"><i class="fa-solid fa-magnifying-glass fa-sm"></i></label>
            </div>
        </div>
    </section>
    <div class="form-box animate__animated animate__fadeIn">
        <div class="form-box__content form-box__content-h-auto">
        <div class="w100 overflow-auto">
            <table  mat-table matSort (matSortChange)="sortData($event,deviceExpTableDataSource)" [dataSource]="devicesLoadedInTableSorted">
                <ng-container *ngFor="let field of columnStructureToDisplay">
                    <ng-container  matColumnDef="{{field.name}}">
                        <ng-container *ngIf="!field.sorted">
                          <th mat-header-cell *matHeaderCellDef [ngClass]="{'mat-header-cell-center': field.align_header == alignDataTable.CENTER, 'mat-header-cell-end': field.align_header == alignDataTable.RIGHT}" matTooltip="{{field.label.length>dataService.maxColumLabelLength?field.label:''}}"> {{field.label.length>dataService.maxColumLabelLength ? field.label.substring(0,dataService.maxColumLabelLength):field.label}}</th>
                        </ng-container>
                        <ng-container *ngIf="field.sorted">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header [ngClass]="{'mat-header-cell-center': field.align_header == alignDataTable.CENTER, 'mat-header-cell-end': field.align_header == alignDataTable.RIGHT}" sortActionDescription="Ordenar por {{field.label}}" matTooltip="{{field.label.length>dataService.maxColumLabelLength?field.label:''}}">{{field.label.length>dataService.maxColumLabelLength ? field.label.substring(0,dataService.maxColumLabelLength)+"...":field.label}}</th>
                        </ng-container>
                        <ng-container *ngIf="field.name == dataService.NUMBERING_COLUMN_HEADER.NAME">
                            <td mat-cell *matCellDef="let device;let i = index" > {{ i + 1}} </td>
                        </ng-container>
                        <ng-container *ngIf="field.name != dataService.NUMBERING_COLUMN_HEADER.NAME && field.is_action_column;else elseDataColumn">
                            <td mat-cell *matCellDef="let device;let i = index" > 
                                <div class="actions-container" [matTooltip]="'Agregar'" matTooltipPosition="left">
                                    <mat-checkbox (change)="addToCart($event,device)"[(ngModel)]="device.isDeviceSelected" color="primary"></mat-checkbox>
                                </div>
                            </td>
                        </ng-container>
                        <ng-template #elseDataColumn>
                            <td mat-cell *matCellDef="let device;let i = index" > {{ device[field.name]}} </td>
                        </ng-template>
                      </ng-container>
                </ng-container>
                <ng-container matColumnDef="footer">
                    <td mat-footer-cell *matFooterCellDef [colSpan]="displayedColumns.length"  class="text-small">
                        {{deviceExpTableDataSource=='soonToExpire'?'Lista de dispositivos pronto a expirar':'Lista de dispositivos expirados'}} ({{expirationRange.value.start |date:'mediumDate'}} - {{expirationRange.value.end |date:'mediumDate'}})
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;let i = index"></tr>
                <tr mat-footer-row *matFooterRowDef="['footer'];sticky:true"></tr>
                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell empty py-1" [attr.colspan]="displayedColumns.length">Sin datos</td>
                </tr>
            </table>
        </div>
        </div>
    </div>
    <button  mat-mini-fab
        class="floating-button animate__animated" color="primary" 
        [ngClass]="{'animate__backInUp':setDevicesToPay.size >0,'animate__backOutRight':setDevicesToPay.size ==0}"   
        [matBadge]="setDevicesToPay.size" 
        matBadgePosition="before" 
        matBadgeColor="warn"
        [matMenuTriggerFor]="menuPayment"
        aria-label="Realizar pagos de dispositivos" >
        <mat-icon class="text-white">shopping_cart</mat-icon>
    </button>
    <mat-menu #menuPayment="matMenu">
        <button mat-menu-item class="btn-descriptive text-primary" (click)="openDialogPaymentOptions()" matTooltipPosition="left"matTooltip="Realizar pago"><i class="fa-solid fa-money-bill-wave icon"></i><span class="description">Pagar</span></button>
        <button mat-menu-item class="btn-descriptive text-secondary" (click)="emptyDevicesToPayList()" matTooltipPosition="left" matTooltip="Vaciar lista seleccionada"><i class="fa-solid fa-arrow-rotate-left"></i><span class="description">Cancelar</span></button>
    </mat-menu>

    <div class="loader" *ngIf="loading">
        <img src="../../../assets/loader.svg">
    </div>
</section>
<app-device-payment-records
(close)="utils.setSelectedView(this.viewsAvailable,this.viewsAvailable['payment_management_list'].name)"
*ngIf="viewsAvailable['payment_record_list'].show_view"
></app-device-payment-records>