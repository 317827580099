import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/core/api/api.service';
import { DataService } from 'src/app/core/data/data.service';
import { UtilsService } from 'src/app/core/utils/utils.service';
import { ValidationService } from 'src/app/core/validation/validation.service';
import { IDeviceBasicData } from 'src/app/shared/models/interfaces/idevice.model';
import { IUserSessionData } from 'src/app/shared/models/interfaces/iuser-session-data.model';
import { IApiRequestDataForReport, PvsReportsService } from '../../pvs-reports.service';
import { ISort } from 'src/app/shared/models/interfaces/iapi-request-data.model';
import { ObjectInitializationService } from '../../../../core/object-initialization/object-initialization.service';

interface IDriverBasicData{
  id:number,
  name:string
}
interface IColumnStructureToDisplay{
  name:string,
  label:string,
  sorted:boolean,
  additional_field_data:boolean
}
interface IDeviceList{
  time:string,
  plate_number:string,
  device_id:number,
  current_driver_name:number,
  additional?:{driver_id?:number,driver_name?:string}
  [propName:string]:any
}
@Component({
  selector: 'app-driver-behavior',
  templateUrl: './driver-behavior.component.html',
  styleUrls: ['./driver-behavior.component.scss']
})
export class DriverBehaviorComponent implements OnInit {

  loading:boolean=false;
  user!:IUserSessionData;
  
  /**@type {any} La lista de datos a mostrar de los dispositivos.*/
  deviceList:IDeviceList[]=[];
  sort: ISort;
  /** Indica las fechas de filtro usadas */
  footerData!:{start_date:string,end_date:string};
  
  /** @type {boolean} Indica si la fuente de datos del SELECT de filtro son los datos de conductores, en caso contrario entonces son datos de dispositivos */
  isDriverDataSelected:boolean = false;
  /** @type {number} el id del conductor seleccionado. Usado para cuando se va a usar el select para filtrar datos por conductor*/
  selectedDriver!:number;
  /**@type {any} La lista de datos a mostrar de los conductores.*/
  availableDriverList:IDriverBasicData[]=[];
  /** @type { IDriverBasicData[] } Lista de conductores disponibles para el usuario, filtrados */
  availableDriverListFiltered: IDriverBasicData[] = [];
  
  /** @type { IDeviceBasicData[] } Lista de dispositivos disponibles para el usuario */
  availableDeviceList: IDeviceBasicData[] = [];
  
  /** @type { IDeviceBasicData[] } Lista de dispositivos disponibles para el usuario, filtrados */
  availableDeviceListFiltered: IDeviceBasicData[] = [];
  /** @type {string} el valor a buscar dentro de la lista de dispositivos o conductores seleccionables */
  dataToSearch:string = "";
  /** @type {number[]} Los dispositivos seleccionados para consultar */
  devicesSelected:number[] = [];
  /** @type {boolean} Indica si todos los dispositivoss de la lista han sido seleccionados */
  allSelectedDevices:boolean = false;

  optionSelectAllDevices:IDeviceBasicData = {id:0, name:"Seleccionar todos", plate_number:"", imei:""};
  /** @type {number} Cantidad maxima de caracteres de una etiqueta de columna */
  maxColumLabelLength:number = 30;
  //para paginacion
  lengthPage:number=0;
  pageSize!:number;
  pageSizeOptions:number[]=[];
  pageEvent!:PageEvent;
  currentPage=0;

  /** @type {FormGroup} Indica el rango de fecha para filtrar datos por fecha, cuando la seleccion es un solo dispositivo */
  dateRangeToConsult!:FormGroup;
  /** @type {Date} Indica la fecha para filtrar datos por fecha, cuando la seleccion es de varios dispositivos */
  dateToConsult:Date = new Date();  
  displayedColumns:string[] =[];
  displayedColumnsInitial:string[] = []; 
  /** @type {string} Nombre de la columna de "Nombre de conductor" que es reportada cuando sucede un evento. Es para ser nombrado en la tabla*/
  columnForAdditionalDriverName:string = "driver_name";
  /** @type {string[]} Columnas que son exluidas de la lista que llega de la consulta de datos de resumen de eventos */
  excludedColumns:string[] = ["time","device_plate_number","current_driver_name","device_id","current_driver_id","user_id","device_name","additional"]; 
  columnStructureToDisplay: IColumnStructureToDisplay[]=
  [{name:this.pvsReportsService.NUMBERING_COLUMN_HEADER.NAME,label:this.pvsReportsService.NUMBERING_COLUMN_HEADER.LABEL,sorted:false,additional_field_data:false},
   {name:"time",label:"Fecha",sorted:true,additional_field_data:false},  
   {name:"device_plate_number",label:"Placa",sorted:false,additional_field_data:false},
   {name:"current_driver_name",label:"Conductor actual",sorted:false,additional_field_data:false}];
  /** @type {IColumnStructureToDisplay} Las columnas iniciales con que se presenta la tabla */
  columnStructureToDisplayInitial:IColumnStructureToDisplay[] = this.columnStructureToDisplay; 
  moduleId=22;
  permissionsDataSgc:any=[];//permisos sobre el modulo
  constructor(
    private router:Router,
    private utils: UtilsService,
    public validationService:ValidationService,
    private api:ApiService,
    private dataService:DataService,
    public pvsReportsService:PvsReportsService,
    private objectInitializationService:ObjectInitializationService
  ){
    this.sort = this.objectInitializationService.initializeISort();
    this.user = this.dataService.getData("user") as IUserSessionData;    
    this.columnStructureToDisplay.forEach((item:IColumnStructureToDisplay)=> this.displayedColumnsInitial.push(item.name));
    this.displayedColumns = this.displayedColumnsInitial.slice();
    const date:Date = new Date();
    this.dateRangeToConsult = new FormGroup({
      start: new FormControl(new Date(date.getFullYear(),date.getMonth()-1,1)),
      end: new FormControl(new Date(date.getFullYear(),(date.getMonth()),0)),
    });
    this.footerData = {start_date:"",end_date:""};
  }
  ngOnInit(): void {
    this.dataService.checkPermissionModule(this.moduleId).then((permissions: any) => {
      this.permissionsDataSgc = permissions;
      this.pageSizeOptions = this.dataService.PAGE_SIZE_OPTION;
      this.pageSize = this.pageSizeOptions[0];
      this.getDeviceList();
      this.getDriverList();
    }).catch(() => {
      this.utils.showMsg("Página no autorizada","No tiene permisos para ver esta página, contacte al administrador");
      this.router.navigate(['/']);
    });
  }
  toggleSelectAllDevicesToAssociate(){
    this.devicesSelected = this.allSelectedDevices?[]:this.availableDeviceList.map((device:IDeviceBasicData)=>device.id);
    if(!this.allSelectedDevices)
      this.devicesSelected.unshift(this.optionSelectAllDevices.id);
    this.allSelectedDevices = !this.allSelectedDevices;
  }
  getDriverList(){
      let data="?action=get&user_api_hash="+this.user.hash+"&linked_to_sgc=false&columns_to_get=id,name";
      this.api.getData("driver",data,true).then((data: any)=>{
        this.availableDriverList = data.status ?  data.data as IDriverBasicData[] : [];
        this.availableDriverListFiltered = this.utils.copyObject(this.availableDriverList);
      }).catch((error:any)=>{
        this.availableDriverList = [];
        this.availableDriverListFiltered = [];
        this.utils.showResultRequest("error","Información",this.api.getDefaultMessage("la lista de conductores",false,false,"GET"));
      });
  }
  clearSearchDevice(){
    this.dataToSearch = "";
    this.searchDevice();  
  }
  searchDevice(){
    this.dataToSearch = this.dataToSearch.toLowerCase();
    if(!this.isDriverDataSelected){
      let FilteredList:IDeviceBasicData[] = !this.validationService.isNullOrEmpty(this.dataToSearch) && this.dataToSearch.length > 0? this.availableDeviceList.filter(( device: IDeviceBasicData ) => (device.plate_number??"").includes(this.dataToSearch) || device.name.toLowerCase().includes(this.dataToSearch)):this.availableDeviceList;
      this.availableDeviceListFiltered = this.utils.copyObject(FilteredList) as IDeviceBasicData[];
    }else{
      let FilteredList:IDriverBasicData[] = !this.validationService.isNullOrEmpty(this.dataToSearch) && this.dataToSearch.length > 0? this.availableDriverList.filter(( driver: IDriverBasicData ) =>  driver.name.toLowerCase().includes(this.dataToSearch)):this.availableDriverList;
      this.availableDriverListFiltered = this.utils.copyObject(FilteredList) as IDriverBasicData[];      
    }
  }
  isAllDevicesSelected(){  
    const {allSelectedDevices, devicesSelected} = this.pvsReportsService.isAllDevicesSelected(this.devicesSelected,this.availableDeviceList,this.allSelectedDevices,this.optionSelectAllDevices.id); 
    this.allSelectedDevices = allSelectedDevices;
    this.devicesSelected = devicesSelected;
  }
  paginator(event:PageEvent){
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;    
    this.getDeviceList();
  }
  sortData(sort:Sort){
    this.sort.direction = sort.direction;
    this.sort.by = sort.active;
    this.getDeviceList()
  }
  async getDeviceList(){
    this.loading = true;
    let reponse:{status:boolean,available_device_list?:IDeviceBasicData[],request_data?:IApiRequestDataForReport,devices_selected?:number[]} = await this.pvsReportsService.getDataForDeviceSummaryQuery("getEventsSummary",this.sort,this.availableDeviceList,this.optionSelectAllDevices.id,this.devicesSelected,this.dateRangeToConsult,this.dateToConsult,this.pageSize,this.currentPage);
    if(!reponse.status)
      return this.utils.hideLoading(()=>this.loading = false);
    let dataTosend = reponse.request_data;
    if(this.isDriverDataSelected){
       dataTosend!.driver_id = this.selectedDriver;
       dataTosend!.device_id_list = [];
      if( ((this.dateRangeToConsult.value.start === null || this.dateRangeToConsult.value.start ===false) || (this.dateRangeToConsult.value.end ===false || this.dateRangeToConsult.value.end === null)) || this.dateRangeToConsult.value.start > this.dateRangeToConsult.value.end){
        return this.utils.showMsg("","No ha seleccionado un rango de fechas válida");
      }
      let dateRange = this.utils.getDateRange(this.dateRangeToConsult.value.start,this.dateRangeToConsult.value.end);
      dataTosend!.start_date = dateRange.start+ " 00:00:00";
      dataTosend!.end_date = dateRange.end+ " 23:59:59"; 
    }
    this.availableDeviceList = reponse.available_device_list!;
    this.availableDeviceListFiltered = this.utils.copyObject(this.availableDeviceList);
    this.devicesSelected = reponse.devices_selected!;
    this.footerData = {start_date:dataTosend!.start_date,end_date:dataTosend!.end_date};
    this.api.getDataPost("report",dataTosend,true).then((data: any) => {
      if((this.devicesSelected.length - (this.devicesSelected.some((item:number)=>item==this.optionSelectAllDevices.id)?1:0)) == dataTosend!.device_id_list?.length){
        this.allSelectedDevices = true;
      }
      this.deviceList = data.status == 1 ? data.data:[];
      this.lengthPage = data.status == 1 ? (this.isDriverDataSelected?data.total:this.availableDeviceList.length):0;
      this.columnStructureToDisplay = this.utils.copyObject(this.columnStructureToDisplayInitial);
      this.displayedColumns = this.displayedColumnsInitial.slice();
      if(data.status){
        let columnFiltered:string[] = Object.keys(this.deviceList[0]).filter((item:string)=> !this.displayedColumnsInitial.includes(item.trim()) && !this.excludedColumns.includes(item.trim()));  
        columnFiltered.forEach((item:string)=>this.columnStructureToDisplay.push({name:item,label:item=="overspeed"?"Exceso de velocidad":item.replace(/_/g," ").toLowerCase(),sorted:true,additional_field_data:false}));
        this.displayedColumns = [...this.displayedColumns,...columnFiltered];
        /** Agrega dato de conductor registrado en la fecha de consulta, en caso de existir. Dicho dato es del primer registro encontrado para dicha fecha */
        if( Object.values(this.deviceList).some((device:IDeviceList)=>!this.validationService.isNullOrEmpty(device.additional?.driver_name??null))){
          let newField:IColumnStructureToDisplay = {name:this.columnForAdditionalDriverName,label:"Conductor fecha reporte",sorted:false,additional_field_data:true};
          let currentDriverIndex:number = Object.values(this.columnStructureToDisplay).findIndex((item: IColumnStructureToDisplay)=>item.name=="current_driver_name");
          this.columnStructureToDisplay.push(newField);
          this.displayedColumns.splice(currentDriverIndex != -1 ?currentDriverIndex:(Object.values(this.columnStructureToDisplay).length-1) ,0,newField.name);
        }
      }else if(this.isDriverDataSelected)
        this.utils.showResultRequest("error","Información",data.message??"No se ha encontrado datos"); 
      this.utils.hideLoading(()=>this.loading=false);
    }).catch((error)=>{
      console.log(error);
      this.deviceList = [];
      this.utils.showResultRequest("error","Información",this.api.getDefaultMessage("la lista de estados de dispositivos",false,false,"GET"));
      this.utils.hideLoading(()=>this.loading=false);
    });
  }
  exportFile(fileType:"PDF"|"CSV"){
    if(fileType=="CSV"){
      this.utils.processFileDownload("device_report_",
      async()=>{
        let data: IDeviceList[] = this.deviceList;
        let headers: string[] = this.displayedColumns.slice();
        headers.splice(this.displayedColumns.findIndex((item:string)=>item==this.pvsReportsService.NUMBERING_COLUMN_HEADER.NAME),1);
        if( Object.values(this.deviceList).some((device:IDeviceList)=>!this.validationService.isNullOrEmpty(device.additional?.driver_name??null))){
          /**@type {string} Nombre de la columna del objeto additional.driver_name para poder incluirlo en los datos a exportar */
          let driverNameDateReportField = "driver_name_date_report";
          headers.splice(headers.findIndex((item:string)=>item==this.columnForAdditionalDriverName),1);
          data = this.utils.copyObject(data);
          headers.splice(1,0,driverNameDateReportField);
          data = data.map((device:IDeviceList)=>{let item = device; item[driverNameDateReportField] = item.additional?.driver_name??""; return item;});
        }
        return {data:data,headers:headers}      
      },(response:boolean)=>this.loading = response);
    }
  }
}