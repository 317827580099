<div class="border-bottom-secondary d-flex justify-content-between ">
    <h2 > {{viewsAvailable['registration_form'].show_view ? "": "Historial de "}} notas de factura # {{invoiceId}} </h2>
  </div>
  <mat-dialog-content class="mat-typography form-box-container">
  <ng-container>
    <section class="form-box animate__animated animate__fadeIn">
      <div class="form-box__content form-box__content-h-auto g-0">
        <section class="d-block alert alert-light-danger" *ngIf="invoiceNotesList.length >= MAX_RECORDS_PER_INVOICE">
          <p class="text-small m-0"> 
            <i class="fa-solid fa-circle-exclamation alert-icon"></i> Has alcanzado la cantidad máxima ({{MAX_RECORDS_PER_INVOICE}}) de registro de notas posibles para la factura
          </p>
      </section>
        <div  class="form-box__content-inputs mb-1" *ngIf="viewsAvailable['registration_form'].show_view && consultedInvoiceNotes && invoiceNotesList.length < MAX_RECORDS_PER_INVOICE">
            <div class="form-box__content-group-fields form-box__content-group-fields--min-width-x-small">
                <mat-form-field appearance="fill" hintLabel="Max {{MAX_INVOICE_NOTE_FIELD_LENGTH.note}} carácteres">
                    <mat-label>Agregar nota</mat-label>
                    <textarea rows="4" required="" matInput maxlength="{{MAX_INVOICE_NOTE_FIELD_LENGTH.note}}" name="invoiceNote" [(ngModel)]="invoiceNote.note"></textarea>
                    <mat-error>Debe de indicar un valor</mat-error>
                    <mat-hint align="end">{{invoiceNote.note.length || 0}}/{{MAX_INVOICE_NOTE_FIELD_LENGTH.note}}</mat-hint>
                </mat-form-field>
            </div>
        </div>
        
        <section class="form-box__content-section">
          <div class="overflow-auto w-100">
            <table mat-table [dataSource]="invoiceNotesList">
    
              <ng-container *ngFor="let field of columnStructureToDisplay">
                <ng-container *ngIf="field.visible"  matColumnDef="{{field.name}}">
                    <ng-container *ngIf="!field.sorted">
                      <th mat-header-cell *matHeaderCellDef> {{field.label.length>dataService.maxColumLabelLength ? field.label.substring(0,dataService.maxColumLabelLength):field.label}}</th>
                    </ng-container>
                    <ng-container *ngIf="field.sorted">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Ordenar por {{field.label}}" matTooltip="{{field.label.length>dataService.maxColumLabelLength?field.label:''}}">{{field.label.length>dataService.maxColumLabelLength ? field.label.substring(0,dataService.maxColumLabelLength)+"...":field.label}}</th>
                    </ng-container>
                    <ng-container *ngIf="field.is_action_column;else elseDataColumn">
                        <td mat-cell *matCellDef="let invoiceNote;let i = index"> 
                            <div class="actions-container">
                              <button class="text-danger" (click)="deleteNote(invoiceNote)" ><i class="fa-solid fa-trash-can"></i></button>
                            </div>
                        </td>
                    </ng-container>
                    <ng-template #elseDataColumn>
                        <td mat-cell *matCellDef="let invoiceNote;let i = index" [ngClass]="{'description-cell': field.name == 'note'}"> 
                          <span [ngClass]="{'white-space-pre-wrap': field.name == 'note'}">{{ field.pipe_type == pipeTypes.DATE ? (invoiceNote[field.name] | date: field.pipe_option) :  invoiceNote[field.name] }}</span>
                        </td>
                    </ng-template>
                </ng-container>

            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumnsInTable"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsInTable;"></tr>
            <tr *matNoDataRow>
              <td class="mat-cell empty py-1" [attr.colspan]="displayedColumnsInTable.length">{{!consultedInvoiceNotes ? 'Consultando datos...' : 'Sin datos'}}</td>
            </tr>
            </table>   
          </div>
        </section>
      </div>
    </section>
      <div class="loader" *ngIf="loading">
          <img src="../../../../../assets/loader.svg">
      </div>
  </ng-container>

  </mat-dialog-content>
  <mat-dialog-actions align="end" >
      <button  class="btn btn-primary me-1" (click)="addInvoiceNote()" *ngIf="consultedInvoiceNotes && viewsAvailable['registration_form'].show_view && invoiceNotesList.length < MAX_RECORDS_PER_INVOICE" [disabled]="loading" >Registrar</button>  
      <button mat-button [mat-dialog-close]="invoiceNoteCreated" class="btn btn-outline-secondary">Cerrar</button>  
  </mat-dialog-actions>
  
  