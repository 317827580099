<div class="form-box animate__animated animate__fadeIn">
    <form class="form-box__content form-box__content-h-auto form-box__content-force-h-adaptive form-box__content-force-min-h-adaptive">
        <div class="options">
            <mat-form-field appearance="fill" class="position-sticky left-0" >
                <mat-label>Modo de vista</mat-label>
                <mat-select name="templateViewSize" [(ngModel)]="templateViewSize" (selectionChange)="templateOptionSize()">
                    <ng-container   *ngFor="let item of templateViewSizeOptions">
                        <mat-option [value]="item.value"><span class="d-flex justify-content-between align-items-center"><span>{{item.label}}</span> <span class="text-primary"><i class="fa-solid {{item.icon}} fa-lg "></i></span> </span></mat-option>
                    </ng-container>
                </mat-select>
                
            </mat-form-field>
            <mat-slide-toggle color="primary" class="animate__animated" [ngClass]="{'animate__fadeInDown': frameLoaded && !validation.isNullOrEmpty(userWebAddress)}" name="editWebsite" (change)="editFrame($event,undefined)" [(ngModel)]="editFrameTemplate" matTooltip="Activa/desactiva la edición del contenido del sitio web">Edición de contenido</mat-slide-toggle>
        </div>
        <div class="iframe-container" *ngIf="!validation.isNullOrEmpty(userWebAddress)">
            
            <iframe  #frameWebTemplate id="frameWebTemplate"(load)="frameLoaded=true;setFrameWebTemplate();editFrame(undefined,editFrameTemplate)"  class="iframe-content animate__animated animate__fadeIn" [src]="userWebAddress"  [ngStyle]="{'width':templateViewSize}">
            </iframe>
        </div>
    </form>


</div>