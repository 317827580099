

<section class="form-box-container animate__animated animate__fadeIn">
    <section class="form-box-container__header">
      <span class="form-box-container__header-back" (click)="closeModal(false)"><span class="description">Volver</span> </span>
        <h2 class="form-box-container__header-title"><span class="icon"><i class="fa-solid fa-id-card"></i></span>{{isUpdate ? 'Actualizar datos de conductor' : 'Nuevo conductor'}} </h2>
        <div  class="form-box-container__header-options"></div>
    </section>
    <div class="form-box animate__animated animate__fadeIn">
  
        <div class="form-box__content form-box__content-h-auto">
            <form  class="form-box__content form-box__content-h-auto" id="registrationForm" enctype="multipart/form-data">
                <div class="form-box__content-inputs">
                    <div class="form-box__content-group-fields">
                        <mat-form-field appearance="fill" hintLabel="Max {{maxFieldslength.name}} carácteres">
                            <mat-label>Nombre</mat-label>
                            <input matInput type="text" maxlength="{{maxFieldslength.name}}" name="name" [(ngModel)]="newDriver.name">
                            <mat-hint align="end">{{newDriver.name.length || 0}}/{{maxFieldslength.name}}</mat-hint>
                            <mat-error>El nombre es requerido</mat-error>
                        </mat-form-field>
                        <mat-form-field appearance="fill">
                            <mat-label>País</mat-label>
                            <mat-select  id="inputCountry" name="country_id" [(ngModel)]="newDriver.country_id" >
                                <ng-container   *ngFor="let item of countriesList">
                                    <mat-option [value]="item.id">{{item.name}}</mat-option>
                                </ng-container>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field appearance="fill" hintLabel="Max {{maxFieldslength.document_number}} carácteres">
                            <mat-label>Número de documento</mat-label>
                            <input matInput type="text" autocomplete="username" inputmode="numeric" [maxlength]="maxFieldslength.document_number" (keyup)="newDriver.document_number = validationService.onlyNumbers(newDriver.document_number)" [pattern]="validationService.isOnlyNumbersStr" name="document_number" [(ngModel)]="newDriver.document_number">
                            <mat-error>Solo ingrese números</mat-error>
                            <mat-hint align="end">{{newDriver.document_number !=null ? newDriver.document_number.length : 0}}/{{maxFieldslength.document_number}}</mat-hint>
                        </mat-form-field>
                        <mat-form-field appearance="fill" class="fill" hintLabel="Max {{maxFieldslength.email}} carácteres">
                            <mat-label>Email</mat-label>
                            <input matInput maxlength="{{maxFieldslength.email}}" [pattern]="validationService.isValidEmailStr" type="email" name="email" [(ngModel)]="newDriver.email">
                            <mat-error>Ingrese un formato válido Ex.  usuario@dominio.com</mat-error>
                            <mat-hint align="end">{{  newDriver.email !=null ? newDriver.email.length:0}}/{{maxFieldslength.email}}</mat-hint>
                        </mat-form-field>
                        <mat-form-field appearance="fill" hintLabel="Max {{maxFieldslength.phone}} carácteres">
                            <mat-label>Número de teléfono</mat-label>
                            <input matInput maxlength="{{maxFieldslength.phone}}" type="tel" (keyup)="newDriver.phone = validationService.onlyNumbers(newDriver.phone)" [pattern]="validationService.isOnlyNumbersStr" name="phone" [(ngModel)]="newDriver.phone">
                            <mat-hint align="end">{{  newDriver.phone !=null ? newDriver.phone.length:0}}/{{maxFieldslength.phone}}</mat-hint>
                            <mat-error>Solo ingrese numeros</mat-error>
                        </mat-form-field>
                        <mat-form-field appearance="fill" hintLabel="Max {{maxFieldslength.emergency_phone_number}} carácteres">
                            <mat-label>teléfono de emergencia</mat-label>
                            <input matInput maxlength="{{maxFieldslength.emergency_phone_number}}" type="tel" (keyup)="newDriver.emergency_phone_number = validationService.onlyNumbers(newDriver.emergency_phone_number)" [pattern]="validationService.isOnlyNumbersStr" name="emergency_phone_number" [(ngModel)]="newDriver.emergency_phone_number">
                            <mat-hint align="end">{{  newDriver.emergency_phone_number !=null ? newDriver.emergency_phone_number.length:0}}/{{maxFieldslength.emergency_phone_number}}</mat-hint>
                            <mat-error>Solo ingrese numeros</mat-error>
                        </mat-form-field>
                        <mat-form-field appearance="fill">
                            <mat-label>Tipo de Sangre</mat-label>
                            <mat-select  id="inputbooldTypes" name="boold_type" [(ngModel)]="newDriver.boold_type" >
                                <ng-container   *ngFor="let item of dataService.booldTypes">
                                    <mat-option [value]="item['value']">{{item['label'] +" ("+item['value']+")"}}</mat-option>
                                </ng-container>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field appearance="fill" class="fill" hintLabel="Max {{maxFieldslength.rfid}} carácteres">
                            <mat-label>RFID</mat-label>
                            <input matInput maxlength="{{maxFieldslength.rfid}}" type="text" [pattern]="validationService.onlyCharactersStr"  name="rfid" [(ngModel)]="newDriver.rfid">
                            <mat-hint align="end">{{  newDriver.rfid !=null ? newDriver.rfid.length:0}}/{{maxFieldslength.rfid}}</mat-hint>
                            <mat-error>Solo puede contener letras y números</mat-error>
                        </mat-form-field>
                        <mat-form-field id="inputdeviceUsers" class="fill" appearance="fill"class="animate__animated animate__faster" >
                            <mat-label>Dispositivos</mat-label>
                            <mat-select (closed)="clearSearchDevice('associatedDevices')" [multiple]="true" name="deviceUsers" [(ngModel)]="newDriver.devices">
                                <mat-option>
                                    <ngx-mat-select-search [noEntriesFoundLabel]="'Sin resultados'" placeholderLabel="Buscar dispositivo..." [(ngModel)]="deviceToSearch" name="searchdevice" (keyup)="searchDevice('associatedDevices')">
                                        <mat-icon (click)="clearSearchDevice('associatedDevices')" ngxMatSelectSearchClear>close</mat-icon>
                                    </ngx-mat-select-search>
                                </mat-option>
                                <ng-container *ngFor="let device of FilteredDeviceList">
                                    <mat-option *ngIf="device.id == 0" (click)="toggleSelectAllDevicesToAssociate()" [value]="device.id">Seleccionar todos</mat-option>
                                    <mat-option *ngIf="device.id != 0" [value]="device.id"  >{{device.name + ' ('+device.imei+")" }}</mat-option>
                                </ng-container>
                            </mat-select>
                        </mat-form-field>
    
                        <mat-checkbox class="fill" color="primary" name="set_current_device" [(ngModel)]="newDriver.current">Establecer como dispositivo actual</mat-checkbox>
                        <mat-form-field id="currentDevice" appearance="fill"matTooltip="Selecciona el dispositivo">
                            <mat-label>Actual</mat-label>
                            <mat-select (closed)="clearSearchDevice('currentDevice')" [disabled]="!newDriver.current"  [(ngModel)]="newDriver.device_id" name="current_device" placeholder="Buscar dispositivo..." >
                              <mat-option>
                                <ngx-mat-select-search [noEntriesFoundLabel]="'Sin resultados'" placeholderLabel="Buscar dispositivo..." [(ngModel)]="deviceToSearchInCurrent" name="currentDeviceToSearch" (keyup)="searchDevice('currentDevice')">
                                    <mat-icon (click)="clearSearchDevice('currentDevice')" ngxMatSelectSearchClear>close</mat-icon>
                                </ngx-mat-select-search>
                              </mat-option>
                              <mat-option value=""  >Ninguno</mat-option>
                              <mat-option *ngFor="let device of FilteredCurrentDeviceList" [value]="device.id"  >{{device.name + ' ('+device.imei+")" }}</mat-option>
                            </mat-select>
                          </mat-form-field>
    
                          <mat-form-field appearance="fill" class="fill" hintLabel="Max {{maxFieldslength.address}} carácteres">
                            <mat-label>Dirección</mat-label>
                            <input matInput type="text" maxlength="{{maxFieldslength.address}}" name="address" [(ngModel)]="newDriver.address">
                            <mat-hint align="end">{{  newDriver.address !=null ? newDriver.address.length:0}}/{{maxFieldslength.address}}</mat-hint>                        
                        </mat-form-field>
                        <mat-form-field appearance="fill" class="fill" hintLabel="Max {{maxFieldslength.description}} carácteres">
                            <mat-label>Descripción</mat-label>
                            <input matInput type="text" maxlength="{{maxFieldslength.description }}" name="description" [(ngModel)]="newDriver.description">
                            <mat-hint align="end">{{  newDriver.description !=null ? newDriver.description.length:0}}/{{maxFieldslength.description }}</mat-hint>                        
                        </mat-form-field>                    
                        <mat-form-field appearance="fill" *ngIf="!isUpdate || isUpdatePassword" >
                            <mat-label>Contraseña</mat-label>
                            <input matInput type="password" minlength="{{minPasswordLength}}" [pattern]="validationService.onlyCharactersStrWithMinimum(minPasswordLength)" [autocomplete]="isUpdate?'off':'new-password'" name="password" [(ngModel)]="newDriver.password">
                            <mat-icon matSuffix [matTooltipClass]="'tooltip-multiline'"  [matTooltip]="'La contraseña debe cumplir con los siguientes requisitos:                     
                            
                            * minimo '+minPasswordLength+' carácteres
                            * No debe contener espacios en blanco'">
                               <i class="fa-solid fa-circle-question pointer icon"></i>
                           </mat-icon>
                           <mat-error>{{showPasswordValidityMessage()}}</mat-error>
                        </mat-form-field>
                        <mat-form-field appearance="fill" *ngIf="!isUpdate || isUpdatePassword">
                            <mat-label>Confirmar contraseña</mat-label>
                            <input matInput type="password" minlength="{{minPasswordLength}}" [pattern]="newDriver.password??''" name="confirm-password" [(ngModel)]="newDriver.password_confirmation">
                           <mat-error>Las contraseñas no coinciden</mat-error>
                        </mat-form-field>
                        <mat-checkbox class="fill" color="primary" *ngIf="isUpdate" name="check_enable_password_update" [(ngModel)]="isUpdatePassword" (change)="enablePasswordUpdate($event)">Actualizar Contraseña</mat-checkbox>
                    </div>         
                </div>
                <div class="form-box__content-actions">
                    <button class="btn btn-primary " (click)="adminDriver()" [disabled]="loading">{{(loading?'Actualizando...':isUpdate?'Actualizar':'Registrar')}}</button>
                </div>
            </form>
        </div>
    </div>
    <div class="loader" *ngIf="loading">
      <img src="../../../assets/loader.svg">
    </div>
  </section>