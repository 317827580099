import { Injectable } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { UtilsService } from 'src/app/core/utils/utils.service';

@Injectable({
  providedIn: 'root'
})
export class BillingService {

  constructor(
    private utils: UtilsService
  ) { }


  getDataSorted(data:any,sort:Sort){
    data.sort((a:any, b:any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'name'://nombre del distribuidor en la tabla de resumen de distribuidores
          if(a.name =="" && b.name ==""){
            return this.utils.compare(a.email.toLowerCase().trim(), b.email.toLowerCase().trim(), isAsc);
          }else if(a.name =="" && b.name !=""){
            return this.utils.compare(a.email.toLowerCase().trim(), b.name.toLowerCase().trim(), isAsc);
          }else if(a.name !="" && b.name ==""){
            return this.utils.compare(a.name.toLowerCase().trim(), b.email.toLowerCase().trim(), isAsc);
          }else{
            return this.utils.compare(a.name.toLowerCase().trim(), b.name.toLowerCase().trim(), isAsc);
          }
        case 'distributorName'://nombre del distribuidor en talba de dispositivos
          if(a.distributorName =="" && b.distributorName ==""){
            return this.utils.compare(a.distributorEmail.toLowerCase().trim(), b.distributorEmail.toLowerCase().trim(), isAsc);
          }else if(a.distributorName =="" && b.distributorName !=""){
            return this.utils.compare(a.distributorEmail.toLowerCase().trim(), b.distributorName.toLowerCase().trim(), isAsc);
          }else if(a.distributorName !="" && b.distributorName ==""){
            return this.utils.compare(a.distributorName.toLowerCase().trim(), b.distributorEmail.toLowerCase().trim(), isAsc);
          }else{
            return this.utils.compare(a.distributorName.toLowerCase().trim(), b.distributorName.toLowerCase().trim(), isAsc);
          }
        case 'devicesTotal':
          return this.utils.compare(a.devicesTotal, b.devicesTotal, isAsc);
        case 'deviceName':
          return this.utils.compare(a.name, b.name, isAsc);
        case 'observation':
          return this.utils.compare(a.observation, b.observation, isAsc);
        case 'imei':
          return this.utils.compare(a.imei, b.imei, isAsc);
        case 'plate_number':
          return this.utils.compare(a.plate_number.toLowerCase().trim(), b.plate_number.toLowerCase().trim(), isAsc);
        case 'created_at':
          return this.utils.compare(new Date(a.created_at).getTime(), new Date(b.created_at).getTime(), isAsc);
        case 'soonToDevicesExpireTotal':
          return this.utils.compare(a.soonToDevicesExpireTotal, b.soonToDevicesExpireTotal, isAsc);
        case 'devicesExpiredTotal':
          return this.utils.compare(a.devicesExpiredTotal, b.devicesExpiredTotal, isAsc);
        case 'expiration_date':
          return this.utils.compare(new Date(a.expiration_date).getTime(), new Date(b.expiration_date).getTime(), isAsc);
        case 'last_connection':
          return this.utils.compare(new Date(a.last_connection).getTime(), new Date(b.last_connection).getTime(), isAsc);
        case 'activation_date':
          return this.utils.compare(new Date(a.server_time).getTime(), new Date(b.server_time).getTime(), isAsc);
        default:
          return 0;
      }
    });
    return data;
  }

  getDateRange(startDate:any,endDate:any){
    let start= new Date(startDate);
    let end= new Date(endDate);
    let newStart= start.getFullYear()+"-"+this.utils.evaluateDateNumber(start.getMonth()+1)+"-"+this.utils.evaluateDateNumber(start.getDate());
    let newEnd= end.getFullYear()+"-"+this.utils.evaluateDateNumber(end.getMonth()+1)+"-"+this.utils.evaluateDateNumber(end.getDate());
    startDate =newStart;
    endDate=newEnd;
    return {
      start:startDate,
      end:endDate
    };
}
}
