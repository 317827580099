import { Component } from '@angular/core';

@Component({
  selector: 'app-my-account-basic-data',
  templateUrl: './my-account-basic-data.component.html',
  styleUrls: ['./my-account-basic-data.component.scss']
})
export class MyAccountBasicDataComponent {

}
