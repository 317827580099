import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApiService } from 'src/app/core/api/api.service';
import { DataService } from 'src/app/core/data/data.service';
import { ObjectInitializationService } from 'src/app/core/object-initialization/object-initialization.service';
import { UtilsService } from 'src/app/core/utils/utils.service';
import { ValidationService } from 'src/app/core/validation/validation.service';
import { ApiEndpointSgc } from 'src/app/shared/enums/api-endpoint-sgc.enum';
import { AlignDataTable, PipeTypes, RequestMethodHTTP } from 'src/app/shared/enums/common-enums.enum';
import { IApiRequestData } from 'src/app/shared/models/interfaces/iapi-request-data.model';
import { IInvoiceNote } from 'src/app/shared/models/interfaces/iinvoice.model';
import { IUserSessionData } from 'src/app/shared/models/interfaces/iuser-session-data.model';
import { IColumnStructureToDisplayInTable } from 'src/app/shared/models/interfaces/iutil.model';
import { IViewsAvailable } from 'src/app/shared/models/interfaces/views-available';

type TableColumnName = Pick<IInvoiceNote, "created_at" | "note"> & { action: boolean };

type MaxInvoiceNoteFieldLength = Pick<IInvoiceNote, "note">;

@Component({
  selector: 'app-invoice-notes',
  templateUrl: './invoice-notes.component.html',
  styleUrls: ['./invoice-notes.component.scss']
})
export class InvoiceNotesComponent implements OnInit {

  currentDate: Date = new Date();

  user: IUserSessionData;
  loading: boolean = false;
  /**
   * Indica cuando ya se ha consultado los registros de notas 
   */
  consultedInvoiceNotes: boolean = false;
  /** @type {boolean} Indica si se registró una nota  */
  invoiceNoteCreated: boolean = false;
  /**
   * @type {IInvoiceNote} Indica los datos de la nota a registrar
   */
  invoiceNote: IInvoiceNote;
  readonly MAX_RECORDS_PER_INVOICE: number = 20;
  readonly MAX_INVOICE_NOTE_FIELD_LENGTH: Record<keyof MaxInvoiceNoteFieldLength, number> = { note: 700 };

  /** Almacena la lista de vistas disponibles en el componente */
  viewsAvailable: IViewsAvailable = {
    history: { name: "history", show_view: false },
    registration_form: { name: "registration_form", show_view: true }
  };

  pipeTypes: typeof PipeTypes = PipeTypes;
  /**
   * @type {number} Indica la factura a la que se toma como referencia para la consulta de datos y registro de notas
   */
  invoiceId: number | null = null;

  see_only_historical: boolean = false;
  alignDataTable: typeof AlignDataTable = AlignDataTable;
  /** La lista de registro de notas*/
  invoiceNotesList: IInvoiceNote[] = [];
  displayedColumnsInTable: (keyof TableColumnName)[] = ["created_at", "note", "action"];

  tableColumnInformation: Record<keyof TableColumnName, IColumnStructureToDisplayInTable> = {
    created_at: this.objectInitializationService.initializeIColumnStructureToDisplayInTable("created_at", "Fecha de registro", undefined, undefined, undefined, false, AlignDataTable.LEFT, AlignDataTable.LEFT, false, true),
    note: this.objectInitializationService.initializeIColumnStructureToDisplayInTable("note", "Nota", undefined, undefined, undefined, false, AlignDataTable.LEFT, AlignDataTable.LEFT, false, true),
    action: this.objectInitializationService.initializeIColumnStructureToDisplayInTable("action", "Acciones", undefined, undefined, undefined, false, AlignDataTable.CENTER, AlignDataTable.CENTER, true, true)
  };
  columnStructureToDisplay: IColumnStructureToDisplayInTable[] = Object.values(this.tableColumnInformation);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { invoice_id: number, see_only_historical: boolean },
    private dialogRef: MatDialogRef<InvoiceNotesComponent>,
    private api: ApiService,
    public dataService: DataService,
    public validationService: ValidationService,
    public utils: UtilsService,
    private objectInitializationService: ObjectInitializationService
  ) {
    this.invoiceId = this.data.invoice_id ?? null;
    this.user = this.dataService.getData("user") as IUserSessionData;

    this.utils.setSelectedView(this.viewsAvailable, this.viewsAvailable['registration_form'].name);
    this.invoiceNote = this.objectInitializationService.initializeIINvoiceNote(this.invoiceId);
  }
  ngOnInit(): void {
    if (this.invoiceId != null)
      this.getInvoiceNotes(this.invoiceId);
  }
  getInvoiceNotes(invoiceId: number) {
    this.invoiceNotesList = [];
    let dataToSend: IApiRequestData = { user_api_hash: this.user.hash, action: "get", invoice_id: invoiceId };
    this.api.getDataPost(ApiEndpointSgc.INVOICE_NOTE, dataToSend, true).then((data: unknown) => {
      if (this.validationService.isResponseApi(data) && data.status == 1) {
        this.invoiceNotesList = data.data as IInvoiceNote[];
      }
      this.consultedInvoiceNotes = true;
    }).catch((err: unknown) => {
      this.utils.showResultRequest("error", "Información", this.api.getDefaultMessage("las notas de factura", false, false, RequestMethodHTTP.GET));
    }).finally(() => this.utils.hideLoading(() => this.loading = false));
  }
  addInvoiceNote() {
    this.utils.showConfirm("Confirmar acción", "¿Esta seguro de registrar la nota?", "Confirmar", "Cancelar").then(() => {
      this.loading = true;
      let errorMessage: string = "";
      let dataToSend: IApiRequestData = { user_api_hash: this.user.hash, action: "add" };

      if (this.validationService.isNullOrEmpty(this.invoiceNote.invoice_id))
        errorMessage = "La nota debe de estar asociada a una factura";
      else if (this.validationService.isNullOrEmpty(this.invoiceNote.note))
        errorMessage = "Debe de indicar la descripción de la nota";
      else if (this.invoiceNote.note.length > this.MAX_INVOICE_NOTE_FIELD_LENGTH.note)
        errorMessage = "No puede exceder el límite de " + this.MAX_INVOICE_NOTE_FIELD_LENGTH.note + " carácteres";

      if (errorMessage != "") {
        this.utils.hideLoading(() => this.loading = false);
        return this.utils.showResultRequest("error", "Información", errorMessage);
      }
      let invoiceNote = this.utils.copyObject(this.invoiceNote) as IInvoiceNote;
      dataToSend = { ...dataToSend, ...invoiceNote };
      this.api.createData(dataToSend, ApiEndpointSgc.INVOICE_NOTE, true).then((data: unknown) => {
        if (this.validationService.isResponseApi(data)) {
          this.utils.showResultRequest(data.status == 1 ? "success" : "error", "Información", data.message);
          if (data.status == 1) {
            this.getInvoiceNotes(this.invoiceId!);
            //this.dialogRef.close(true);
            this.invoiceNote.note = "";
          }
        }
      }).catch((err: unknown) => {
        this.utils.showResultRequest("error", "Información", this.api.getDefaultMessage("la nota", false, false, RequestMethodHTTP.POST));
      }).finally(() => this.utils.hideLoading(() => this.loading = false));
    });
  }
  deleteNote(invoiceNote: IInvoiceNote) {
    this.utils.showConfirm("Confirmar acción", "Esta seguro de eliminar la nota de factura", "Confirmar", "Cancelar").then(() => {
      this.loading = true;
      let data = { user_api_hash: this.user.hash, action: "delete", id: invoiceNote.id };
      this.api.createData(data, ApiEndpointSgc.INVOICE_NOTE, true).then((data: unknown) => {
        if(this.validationService.isResponseApi(data)){
          this.utils.showResultRequest(data.status == 1 ? "success" : "error", "Información", data.message ?? '');
          if (data.status == 1)
            this.getInvoiceNotes(this.invoiceId!);
        }
      }).catch((err: any) => {
        this.utils.showResultRequest("error", "Información", this.api.getDefaultMessage("la nota", false, false, RequestMethodHTTP.DELETE));
      }).finally(() => this.utils.hideLoading(() => this.loading = false));
    }).catch(() => { });
  }
}
