export class FolderRoute {
    static readonly UPLOADS = "uploads/";
    static readonly UPLOADS_IMAGES= FolderRoute.UPLOADS+"images/";
    static readonly CHECKLIST = FolderRoute.UPLOADS_IMAGES+"checklist/";
    static readonly CHECKLIST_SIGNATURES = FolderRoute.CHECKLIST+"signatures/";
    static readonly CHECKLIST_ANSWERS = FolderRoute.CHECKLIST+"answers/";
    /**Indica el caracter que se usa como separador en la formacion del nombre del archivo */
    static readonly CHECKLIST_ANSWERS_IMAGE_SEPARATOR = "_";

    static readonly FILE_GENERATOR = "fileGenerator/";
    static readonly FILE_GENERATOR_DEVICE_CERTIFICATE = FolderRoute.FILE_GENERATOR + "deviceCertificate";
    static readonly FILE_GENERATOR_PAYMENT_PRINTING = FolderRoute.FILE_GENERATOR + "paymentPrinting";
    static readonly FILE_GENERATOR_DEVICE_CHECKLIST_ANSWERED = FolderRoute.FILE_GENERATOR + "deviceChecklistAnswered";
    
    static readonly REPORTS = FolderRoute.FILE_GENERATOR + "reports/";
  
    static readonly USER = FolderRoute.UPLOADS_IMAGES+"user/";
    static readonly USER_BANK_QR_CODE = FolderRoute.USER+"bank-qr-code/";
  };