import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ApiService } from 'src/app/core/api/api.service';
import { DataService } from 'src/app/core/data/data.service';
import { UtilsService } from 'src/app/core/utils/utils.service';
import { IDistributorPaymentOption } from 'src/app/shared/models/interfaces/iutils-data.model';
import { ObjectInitializationService } from '../../../../core/object-initialization/object-initialization.service';
import { IUserSessionData } from 'src/app/shared/models/interfaces/iuser-session-data.model';
import { ValidationService } from '../../../../core/validation/validation.service';
import { IApiRequestData } from 'src/app/shared/models/interfaces/iapi-request-data.model';
import { RequestMethodHTTP } from 'src/app/shared/enums/common-enums.enum';
import { IUserSetting, IUserSgc, IUserSgcFileToUpload, UserSettingDisplayPaymentOptionInBilling } from 'src/app/shared/models/interfaces/user-data.model';
import { ISupportedFiles } from 'src/app/shared/models/interfaces/isupportedFiles.model';
import { FolderRoute } from 'src/app/core/constant/FolderRouter';
import { IViewsAvailable } from 'src/app/shared/models/interfaces/views-available';
import { ObjectValidatorService } from 'src/app/core/object-validator/object-validator.service';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { MessageBoxService } from 'src/app/core/message-box/message-box.service';


@Component({
  selector: 'app-payment-options-basic-data',
  templateUrl: './payment-options-basic-data.component.html',
  styleUrls: ['./payment-options-basic-data.component.scss']
})
export class PaymentOptionsBasicDataComponent implements OnInit {

  loading: boolean = false;
  user: IUserSessionData;

  tabsAvailable: IViewsAvailable = {
    payment_option_list:{name:"payment_option_list",show_view:true,index_in_tab: 0 },
    user_bank_qr_code:{name:"user_bank_qr_code",show_view:false, index_in_tab: 1}
  };
  /**
   * @type {string} la url base para acceso a recursos
   */
  urlBaseSgc:string = "";
  imageFolderRoute = FolderRoute;

  supportedFiles: ISupportedFiles;
  maxQRImageSizeInKB: number = 100;
  /**
   * Indica si la vista solo es para agregar el nombre de la opcion de pago
   */
  @Input() addOnlyPaymentOptionName: boolean = false;
  paymentOption: IDistributorPaymentOption;

  /**
   * @type {IUserSgcFileToUpload} Datos de usuario para la gestion de archivo de Codigo QR
   */
  userSgcFileToUpload: IUserSgcFileToUpload;
  /**
   * @type {IUserSetting} Indica los datos de configuracion del usuario sgc
   */
  userSetting: IUserSetting;
  userSettingDisplayPaymentOptionInBilling: typeof UserSettingDisplayPaymentOptionInBilling = UserSettingDisplayPaymentOptionInBilling;
  bankAccountTypesList: string[] = [];
  paymentOptionsList: IDistributorPaymentOption[] = [];

  @Output() close: EventEmitter<boolean> = new EventEmitter<boolean>();
  constructor(
    private dataService: DataService,
    public utils: UtilsService,
    private api: ApiService,
    private objectInitializationService: ObjectInitializationService,
    private validationService: ValidationService,
    private objectValidator: ObjectValidatorService,
    private messageBox: MessageBoxService
  ) {
    this.user = this.dataService.getData("user");

    this.urlBaseSgc = this.api.urlBaseSgc;
    this.userSetting = this.objectInitializationService.initializeIUserSetting(this.user.id);
    this.supportedFiles = this.objectInitializationService.initializeISupportedFiles(undefined,{jpeg: true,jpg: true,png: true,gif: false,ico: false,webp: false});

    this.userSgcFileToUpload = this.objectInitializationService.initializeIUserSgcFileToUpload();
    this.paymentOption = this.objectInitializationService.initializeIDistributorPaymentOption(this.user.id);
  }
  ngOnInit(): void {
    this.bankAccountTypesList = this.dataService.bankAccountTypesList;
    this.getPaymentOption();
    this.getUserData();
  }
  closeComponent(){
    this.close.emit(true);
  }
  adminPaymentOption() {
    let dataTosSend: IApiRequestData & Partial<IDistributorPaymentOption> & {user_setting?: Partial <IUserSetting>} = {
      user_api_hash: this.user.hash,
      action: "add",
      distributor_id: this.user.id
    };
    if (this.paymentOption.id != null && this.paymentOption.id > 0) {
      dataTosSend["action"] = "update";
    }
    if(this.userSetting.display_payment_option_in_billing != null && [this.userSettingDisplayPaymentOptionInBilling.BANK_QR_CODE,this.userSettingDisplayPaymentOptionInBilling.PAYMENT_OPTIONS_LIST].includes(this.userSetting.display_payment_option_in_billing))
      dataTosSend.user_setting = {display_payment_option_in_billing: this.userSetting.display_payment_option_in_billing};
    dataTosSend = Object.assign(dataTosSend, this.paymentOption);
    let error = this.validateFields(dataTosSend);
    if (error != "") {
      this.utils.showResultRequest("info", error, "");
      return;
    }
    this.loading = true;
    this.api.createData(dataTosSend, "distributorPaymentOption", true).then((data: any) => {
      if (this.validationService.isResponseApi(data)) {
        this.utils.showResultRequest(data.status == 1 ? "success" : "error", (data.message ?? ""), "");
        data.status == 1 ? this.clearFields() : null;
        data.status == 1 && this.addOnlyPaymentOptionName ? this.closeComponent() : null;
      }
    }).catch((err: any) => {
      this.utils.showResultRequest("error", "", this.api.getDefaultMessage("la opción de pago", dataTosSend.action == "update", false, "POST"));
    }).finally(() => this.utils.hideLoading((response: boolean) => this.loading = response));
  }
  deletePaymentOption(option: IDistributorPaymentOption) {
    this.utils.showConfirm("Confirmar acción", "Esta seguro de eliminar esta opción de pago", "Eliminar", "Cancelar").then(() => {
      let data = "?action=delete&user_api_hash=" + this.user.hash + "&id=" + option.id;
      this.loading = true;
      this.api.deleteData("distributorPaymentOption", data, true).then((data: unknown) => {
        if (this.validationService.isResponseApi(data)) {
          if (data.status == 1)
            this.getPaymentOption();
          this.utils.showResultRequest(data.status == 1 ? "success" : "error", data.message ?? "", "");
        }
      }).catch((err: any) => {
        this.utils.showResultRequest("error", "", this.api.getDefaultMessage("la opción de pago", false, false, RequestMethodHTTP.DELETE));
      }).finally(() => this.utils.hideLoading((response: boolean) => this.loading = response));
    }).catch(() => { }).finally(() => this.utils.hideLoading((response: boolean) => this.loading = response));
  }
  getPaymentOption() {
    this.paymentOption = this.objectInitializationService.initializeIDistributorPaymentOption(this.user.id);
    this.loading = true;
    this.api.getData("distributorPaymentOption", "?action=getPaymentOptions&user_api_hash=" + this.user.hash, true).then((data: unknown) => {
      if (this.validationService.isResponseApi(data))
        this.paymentOptionsList = (data.status == 1 ? data.data : []);
    }).catch((error: any) => {
      this.utils.showResultRequest("error", "", this.api.getDefaultMessage("las opciones de pago", false, false, RequestMethodHTTP.GET));
    }).finally(() => this.utils.hideLoading((response: boolean) => this.loading = response));
  }
  optionSelected(option: any) {//PAYMENT
    this.paymentOption = JSON.parse(JSON.stringify(option));
    this.paymentOption.payment_proccess = this.paymentOption.payment_proccess;
  }
  validateFields(data: any) {//PAYMENT
    if (data.name == "")
      return "Asigne un nombre a la opción de pago";
    if (data.payment_proccess.length < 1 && !this.addOnlyPaymentOptionName)
      return "Asigne al menos un paso en el proceso de pago";
    return "";
  }
  clearFields() {
    this.getPaymentOption();
    this.paymentOption = this.objectInitializationService.initializeIDistributorPaymentOption(this.user.id);
  }
  addStep() {
    if (this.paymentOption.step_actual != "") {
      this.paymentOption.payment_proccess!.push(this.paymentOption.step_actual!);
      this.paymentOption.step_actual = "";
    }
  }
  deleteStep(index: number) {
    this.paymentOption.payment_proccess!.splice(index, 1);
  }
  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.paymentOption.payment_proccess!, event.previousIndex, event.currentIndex);
  }

  changeDisplayPaymentOptionInBilling(displayPaymentOptionInBilling: UserSettingDisplayPaymentOptionInBilling,event: MatCheckboxChange,sendRequest: boolean = false){
    this.userSetting.display_payment_option_in_billing = event.checked ? displayPaymentOptionInBilling : null;

    if(!sendRequest)
      return;
    let dataToSend: IApiRequestData & Partial<IUserSgcFileToUpload>= { user_api_hash: this.user.hash, action:"update_or_create"};
    dataToSend = Object.assign(dataToSend,this.userSetting);
    this.loading = true;
    this.api.createData(dataToSend, "userSetting", true).then((data: unknown) => {
      if (this.validationService.isResponseApi(data)) {
        this.messageBox.openSnackBar(data.message ?? "","Aceptar");
      }
    }).catch((err: unknown) => {
      this.utils.showResultRequest("error", "", this.api.getDefaultMessage("la selección de tipo de dato a mostrar en documentos de facturación", true , false, "POST"));
    }).finally(() => this.utils.hideLoading((response: boolean) => this.loading = response));
  
  }
  loadQRCode(event:any){
    let file: File = event.target.files[0];
    this.userSgcFileToUpload.bank_qr_code_file = file;
    this.userSgcFileToUpload.delete_bank_qr_code_file = false;
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => this.userSgcFileToUpload.temp_bank_qr_code_file = reader.result;
  }
  getUserData(){
    let dataSgc:IApiRequestData = this.api.structureParametersForConsult(RequestMethodHTTP.POST,"get",this.user.hash) as IApiRequestData;
    dataSgc = {...dataSgc,...{get_my_data: true,columns_to_get:["bank_qr_code_filename","id"], include_user_setting: true }}
    this.api.getDataPost("user",dataSgc,true).then((data: unknown) => {
      let userData: IUserSgc = this.objectInitializationService.initializeIUserSgc();
      if(this.validationService.isResponseApi(data) && data.status == 1){
        userData = data.data as IUserSgc;
        if(typeof userData.user_setting != "undefined" && this.objectValidator.isIUserSetting(userData.user_setting) )
          this.userSetting = userData.user_setting!;
      }
      this.userSgcFileToUpload.bank_qr_code_filename = typeof userData.bank_qr_code_filename != "undefined" ? userData.bank_qr_code_filename : "";
    }).catch((error:unknown)=>{
      this.utils.showResultRequest("error","Información",this.api.getDefaultMessage("los datos del usuario",true, false,RequestMethodHTTP.GET));
    }).finally(() => this.utils.hideLoading((response:boolean)=>this.loading=response));
  }
  async adminUserQrCode(){
    let actionType = {
      delete_bank_qr_code: "delete_bank_qr_code",
      update_bank_qr_code: "update_bank_qr_code"
    };
    let dataToSend: IApiRequestData & Partial<IUserSgcFileToUpload>= { user_api_hash: this.user.hash};
    let error: string = "";
    if(this.userSgcFileToUpload.delete_bank_qr_code_file)
      dataToSend = {...dataToSend,...{action:"delete_bank_qr_code"}};
    else
      dataToSend = {...dataToSend,...{bank_qr_code_file:this.userSgcFileToUpload.bank_qr_code_file, action: "update_bank_qr_code" } };
    let formData: any = new FormData();
    for(let [key,value] of Object.entries(dataToSend)){
      formData.append(key,value);
    }
    if(this.userSetting.display_payment_option_in_billing != null && [this.userSettingDisplayPaymentOptionInBilling.BANK_QR_CODE,this.userSettingDisplayPaymentOptionInBilling.PAYMENT_OPTIONS_LIST].includes(this.userSetting.display_payment_option_in_billing))
      formData.append("user_setting[display_payment_option_in_billing]", this.userSetting.display_payment_option_in_billing )
    if(!this.userSgcFileToUpload.delete_bank_qr_code_file && (!(this.userSgcFileToUpload.bank_qr_code_file instanceof File) && this.userSgcFileToUpload.bank_qr_code_filename == "" ))
      error = "Debe de asignar el Código QR a almacenar";
    if (error != "") {
      this.utils.showResultRequest("info", error, "");
      return;
    }
    if(this.userSgcFileToUpload.delete_bank_qr_code_file){
      let response = await this.utils.showConfirm("Confirmar acción", "¿Esta seguro de eliminar el código QR?", "Eliminar", "Cancelar");
      if(typeof response != "boolean" || !response)
        return;
    }
    this.loading = true;
    this.api.createData(formData, "user", true, this.api.CONTENT_TYPE.FORM_DATA).then((data: unknown) => {
      if (this.validationService.isResponseApi(data)) {
        this.utils.showResultRequest(data.status == 1 ? "success" : "error", (data.message ?? ""), "");
      }
    }).catch((err: unknown) => {
      this.utils.showResultRequest("error", "", this.api.getDefaultMessage("el código QR", dataToSend.action == actionType.update_bank_qr_code , false, dataToSend.action == actionType.delete_bank_qr_code ? "DELETE" : "POST"));
    }).finally(() => this.utils.hideLoading((response: boolean) => this.loading = response));
  
  }
  deleteImgQR(){
    this.userSgcFileToUpload.delete_bank_qr_code_file = true;
  }
  changeTab(event: MatTabChangeEvent){
    let item: any = Object.values(this.tabsAvailable).find((item:any, index:number) => index == parseInt(event.index.toString()));
    if(typeof item != "undefined")
      this.utils.setSelectedView(this.tabsAvailable,item.name); 
  }
}