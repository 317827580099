<section class="form-box-container animate__animated animate__fadeIn" >
    
    <section class="form-box-container__header">
        <span class="form-box-container__header-back" (click)="closeComponent()"><span class="description">Volver</span> </span>
        <h2 class="form-box-container__header-title"><span class="icon"><i class="fa-solid fa-users-rays"></i></span> Usuarios destinatarios </h2>
        <div  class="form-box-container__header-options">                     
            <div class="search">
                <input id="inputSearch" type="search" placeholder="Buscar" (input)="search()" name="searchValue" [(ngModel)]="searchValue">
                <label for="inputsearch"><i class="fa-solid fa-magnifying-glass"></i></label>
            </div>
        </div>
    </section>
    <div class="form-box animate__animated animate__fadeInRight">
        <div class="form-box__content form-box__content-h-auto">
            <div class="form-box__content-header form-box__content-header-horizontal p-0 form-box__content-header-align-end">
                <mat-checkbox 
                matTooltip="Incluir usuarios suscritos" 
                color="primary"
                class="scale-75"
                name="availabilityStatus"
                [value]="SUBSCRIPTION_STATUSES.SUBSCRIBED + ''"
                [checked]="subscriptionStatusFilter[SUBSCRIPTION_STATUSES.SUBSCRIBED]"
                (change)="changeUsersSubscriptionFilter(SUBSCRIPTION_STATUSES.SUBSCRIBED, $event)"
                >Suscritos</mat-checkbox>
                <mat-checkbox 
                matTooltip="Incluir usuarios por suscribir" 
                color="primary"
                class="scale-75"
                name="availabilityStatus"
                [value]="SUBSCRIPTION_STATUSES.NOT_SUBSCRIBED + ''"
                [checked]="subscriptionStatusFilter[SUBSCRIPTION_STATUSES.NOT_SUBSCRIBED]"
                (change)="changeUsersSubscriptionFilter(SUBSCRIPTION_STATUSES.NOT_SUBSCRIBED,$event)"
                >Por suscribir</mat-checkbox>
            </div>
            <div class="w100 overflow-auto">
            <table mat-table matSort (matSortChange)="sortData($event)" [dataSource]="usersList">
                <ng-container matColumnDef="select">
                <th mat-header-cell *matHeaderCellDef>
                    <mat-slide-toggle
                        color="primary"
                        (change)="$event ? toggleAllRows() : null; requestDataUpdate();"
                        [checked]="isAllSelected()"
                        [disabled]="loading"
                        [aria-label]="checkboxLabel()">
                    </mat-slide-toggle>
                </th>
                <td mat-cell *matCellDef="let row">
                    <mat-slide-toggle
                        [checked]="isSelectedUser(row)"
                        color="primary"
                        class="z-index-last"
                        [disabled]="loading"
                        (change)="toggleSelection(row, $event);requestDataUpdate(row);"
                        [aria-label]="checkboxLabel(row)">
                    </mat-slide-toggle>
                </td>
                </ng-container>
                <ng-container matColumnDef="active">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="mat-header-center" sortActionDescription="Ordenar por estado de asociacion"> Enviar </th>
                    <td mat-cell *matCellDef="let automationTargetUser"  class="text-center"> 
                        <div class="status complete" *ngIf="automationTargetUser.active == SUBSCRIPTION_STATUSES.SUBSCRIBED"><i class="fa-solid fa-circle-check"></i></div>
                        <div class="status incomplete" *ngIf="automationTargetUser.active != SUBSCRIPTION_STATUSES.SUBSCRIBED"><i class="fa-solid fa-circle-xmark"></i></div>          
                    </td>
                    </ng-container>
                <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Ordenar por email"> Email </th>
                <td mat-cell *matCellDef="let automationTargetUser"> {{automationTargetUser.email}} </td>
                </ng-container>
                <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef  mat-sort-header sortActionDescription="Ordenar por nombre"> Nombre </th>
                <td mat-cell *matCellDef="let automationTargetUser"> {{automationTargetUser.name}} </td>
                </ng-container>
                <ng-container matColumnDef="phone_number">
                <th mat-header-cell *matHeaderCellDef  mat-sort-header sortActionDescription="Ordenar por numero de teléfono"> Teléfono </th>
                <td mat-cell *matCellDef="let automationTargetUser"> {{automationTargetUser.phone_number}} </td>
                </ng-container>
        
                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                </tr>
                <ng-container *ngIf="isSelectedAutomationTypeInBillingAutomation">
                    <tr mat-footer-row *matFooterRowDef="['footer'];sticky:true"></tr>  
                    <ng-container matColumnDef="footer">
                      <td mat-footer-cell *matFooterCellDef [colSpan]="displayedColumns.length"  class="text-small">
                          Para automatizaciones de tipo <strong>{{AUTOMATION_TYPE_NAME_LIST[selectedAutomationType!]}} </strong> sólo aparecen aquellos clientes que tienen dispositivos asignados cómo responsables
                      </td>
                    </ng-container>
                </ng-container>
                
                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell empty py-1" [attr.colspan]="displayedColumns.length">Sin datos</td>
                </tr>
            </table>
            </div><!--[selectConfig]="{ panelClass:'paginator-with-select-all-option'}"-->
            <mat-paginator [length]="pagination.length_page"  [pageSize]="pagination.page_size" [pageIndex]="pagination.current_page"
            [pageSizeOptions]="pagination.page_size_options" (page)="paginator($event)"
            aria-label="Selecciona pagina de la lista de dispositivos">
            </mat-paginator>
        </div>
    </div>
</section>
<div class="loader" *ngIf="loading">
    <img src="../../../../assets/loader.svg">
</div>
