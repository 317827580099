<section class="form-box-container animate__animated animate__fadeIn" *ngIf="viewsAvailable['basic_data'].show_view">
    <section class="form-box-container__header">
        <h2 class="form-box-container__header-title"><span class="icon"><i class="fa-solid fa-person-circle-exclamation"></i></span>Mi cuenta</h2>
    <div class="form-box-container__header-options">
    </div>
    </section>
    <div class="form-box  animate__animated animate__fadeInRight">

    <form enctype="multipart/form-data" class="form-box__content form-box__content-h-auto" *ngIf="viewsAvailable['basic_data'].show_view">
        <mat-tab-group dynamicHeight (selectedTabChange)="changeTab($event)">
            <mat-tab>
                <ng-template mat-tab-label>
                    <i class="fa-solid fa-photo-film icon text-primary"></i>
                    Multimedia
                </ng-template>
                <mat-accordion multi>
                    <mat-expansion-panel expanded>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <i class="fa-solid fa-building icon icon-xl"></i>Logo
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="images-list">
                            <label class="wrapper-img" for="inputFileLogo">
                                <div class="img">
                                    <img class="logo-img animate__animated animate__bounceIn"[matTooltip]="showMessageAboutFile(newDistributor.logo_url,'logo')" src="https://sgc.internationalgpsonline.com/logo/{{selectFile(newDistributor.logo_url,'logo')}}">
                                </div>
                                <input  hidden form="registrationForm"(change)="loadFileIntoImgElement($event,'logo-img','file_logo')"  class="form-control" accept="image/png,image/jpg,image/svg,image/jpeg" type="file" id="inputFileLogo" name="file_logo">
                            </label>  
                        </div>
                    </mat-expansion-panel>

                    <mat-expansion-panel expanded>
                        <mat-expansion-panel-header >
                            <mat-panel-title>
                                <i class="fa-solid fa-signature icon icon-xl"></i>Firma
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="images-list">
                            <label class="wrapper-img" for="inputFileSign">
                                <div class="img">
                                    <img  [matTooltip]="showMessageAboutFile(newDistributor.sign_url,'firma')" class="sign-img animate__animated animate__bounceIn" src="https://sgc.internationalgpsonline.com/sign/{{selectFile(newDistributor.sign_url,'sign')}}">
                                </div>
                                <input hidden form="registrationForm"(change)="loadFileIntoImgElement($event,'sign-img','file_sign')"  class="form-control" accept="image/png,image/jpg,image/svg,image/jpeg" type="file" id="inputFileSign" name="file_sign">
                            </label>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>
                    <i class="fa-solid fa-users-viewfinder icon text-primary"></i>
                    Informacion comercial
                </ng-template>

                <fieldset class="form-box__content-fieldset mt-1">
                    <legend>Redes sociales <span class="optional-legend">(opcional)</span></legend>
                    <div class="form-box__content-group-fields">
                        <mat-form-field appearance="fill">
                            <mat-label>Facebook</mat-label>
                            <input matInput [pattern]="validationService.isWebAddressValidStr" type="url" name="socialMediaFacebook" [(ngModel)]="newDistributor.business_contact_information.social_media.facebook" placeholder="https://facebook.com/mypage/">
                            <mat-icon matSuffix>
                                <i class="fa-brands fa-facebook"></i>
                            </mat-icon>
                            <mat-error>Formato inválido. Ejemplo válido. https://facebook.com/mypage/</mat-error>
                        </mat-form-field>
                        <mat-form-field appearance="fill">
                            <mat-label>Instagram</mat-label>
                            <input matInput type="url" [pattern]="validationService.isWebAddressValidStr" name="socialMediaInstagram" [(ngModel)]="newDistributor.business_contact_information.social_media.instagram" placeholder="https://instagram.com/MyAccount/">
                            <mat-icon matSuffix>
                                <i class="fa-brands fa-instagram"></i>
                            </mat-icon>
                            <mat-error>Formato inválido. Ejemplo válido. https://instagram.com/MyAccount/</mat-error>
                        </mat-form-field>
                        <mat-form-field appearance="fill">
                            <mat-label>Twitter</mat-label>
                            <input matInput type="url" [pattern]="validationService.isWebAddressValidStr" name="socialMediaTwitter" [(ngModel)]="newDistributor.business_contact_information.social_media.twitter" placeholder="https://twitter.com/myAccount/">
                            <mat-icon matSuffix>
                                <i class="fa-brands fa-twitter"></i>
                            </mat-icon>
                            <mat-error>Formato inválido. Ejemplo válido. https://twitter.com/myAccount/</mat-error>

                        </mat-form-field>
                        <mat-form-field appearance="fill">
                            <mat-label>Youtube</mat-label>
                            <input matInput type="url" [pattern]="validationService.isWebAddressValidStr" name="socialMediaYoutube" [(ngModel)]="newDistributor.business_contact_information.social_media.youtube"  placeholder="https://youtube.com/@myChannel/">
                            <mat-icon matSuffix>
                                <i class="fa-brands fa-youtube"></i>
                            </mat-icon>
                            <mat-error>Formato inválido. Ejemplo válido. https://youtube.com/{{'@myChannel'}}/</mat-error>
                        </mat-form-field>
                        <mat-form-field appearance="fill">
                            <mat-label>LinkedIn</mat-label>
                            <input matInput type="url" [pattern]="validationService.isWebAddressValidStr" name="socialMediaLinkedin" [(ngModel)]="newDistributor.business_contact_information.social_media.linkedin"   placeholder="https://linkedin.com/company/myAccount/">
                            <mat-icon matSuffix>
                                <i class="fa-brands fa-linkedin"></i>
                            </mat-icon>
                            <mat-error>Formato inválido. Ejemplo válido. https://linkedin.com/company/myAccount/</mat-error>
                        </mat-form-field>
                    </div>
                </fieldset>
                <fieldset class="form-box__content-fieldset mt-1">
                    <legend>Datos generales <span class="optional-legend">(opcional)</span></legend>
                    <div class="form-box__content-group-fields">
                        <mat-form-field appearance="fill">
                            <mat-label>Prefijo Telefónico</mat-label>
                            <mat-select name="whatsappPrefix" [(ngModel)]="newDistributor.business_contact_information.whatsapp.country_code">
                                <mat-option *ngFor="let prefix of dataService.phone_prefix" [value]="prefix.code">{{'(+'+prefix.code+')'+prefix.country}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                            
                        <mat-form-field appearance="fill">
                            <mat-label>Número de Whatsapp</mat-label>
                            <input matInput  type="tel" [pattern]="validationService.isValidPhoneStr" autocomplete="off" name="whatsappNumber" [(ngModel)]="newDistributor.business_contact_information.whatsapp.number">
                            <mat-icon matSuffix>
                                <i class="fa-brands fa-whatsapp"></i>
                            </mat-icon>  
                            <mat-error>Solo incluya números. Longitud mínima de 9 dígitos</mat-error>
                        </mat-form-field>
                        <mat-form-field appearance="fill">
                            <mat-label>Email de contacto comercial</mat-label>
                            <input matInput type="text" name="commercialContactEmail" [pattern]="validationService.isValidEmailStr" [(ngModel)]="newDistributor.business_contact_information.commercial_contact_email" placeholder="Ex. ventas@dominio.com">
                            <mat-icon matSuffix>
                                <i class="fa-solid fa-envelope"></i>
                            </mat-icon>
                            <mat-error>Email de contacto inválido. Ejemplo válido. ventas{{'@dominio.com'}}</mat-error>
                        </mat-form-field>
                        <mat-form-field class="fill" appearance="fill">
                            <mat-label>Dirección web de plataforma de seguimiento</mat-label>
                            <input matInput type="url" name="websiteUrl" [pattern]="validationService.isWebAddressValidStr" [(ngModel)]="newDistributor.business_contact_information.tracking_website_url" placeholder="Ex https://tracking.dominio.com, https://ingreso.midominio.com">
                            <mat-icon matSuffix [matTooltipClass]="'tooltip-multiline'" [matTooltip]="'Si tienes un dominio para acceder a la plataforma web de rastreo satelital, indicalo en este campo'">
                                <i class="fa-solid fa-network-wired"></i>
                            </mat-icon>
                            <mat-hint align="end">Si tienes un dominio para acceder a la plataforma web de rastreo satelital, indicalo en este campo.</mat-hint>
                            <mat-error>Indica una dirección web válida. Ex. https://tracking.midominio.com, https://ingreso.midominio.com. Recuerda que solo debes de indicar este valor si cuentas con un dominio personalizado</mat-error>
                        </mat-form-field>
                        <mat-form-field class="fill" appearance="fill">
                            <mat-label>Dirección física</mat-label>
                            <input matInput type="text" name="companyAddress" [(ngModel)]="newDistributor.address" placeholder="Ingrese la dirección de la empresa">
                            <mat-icon matSuffix>
                                <i class="fa-solid fa-map-location-dot"></i>
                            </mat-icon>
                        </mat-form-field>
                    </div>
                </fieldset>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>
                    <i class="fa-solid fa-money-check-dollar icon text-primary"></i>
                    Opciones de pago
                </ng-template>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>
                    <i class="fa-brands fa-whatsapp icon text-primary"></i>
                    Configuración WhatsApp
                </ng-template>

            </mat-tab>
        </mat-tab-group>
        <div class="form-box__content-actions">
            <!--<button class="btn btn-primary"
            *ngIf="availableTabs['multimedia'].show_view"
            (click)="updateBasicData()"
            >Registrar</button>-->

            <button class="btn btn-primary"
            *ngIf="availableTabs['multimedia'].show_view || availableTabs['business_contact_information'].show_view"
            (click)="updateBasicData()"
            >Registrar</button>
        </div>
    </form>
    </div>
    <div class="loader" *ngIf="loading">
        <img src="../../../assets/loader.svg">
    </div>
</section>

<app-payment-options-basic-data 
(close)="showTabMultimedia()"
*ngIf="viewsAvailable['payment_option'].show_view"></app-payment-options-basic-data>

<app-messaging-service-account-setting
(close)="showTabMultimedia()"
*ngIf="viewsAvailable['messaging_service_account_setting'].show_view"
></app-messaging-service-account-setting>
