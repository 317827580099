<h2 mat-dialog-title class="border-bottom"><i class="fa-brands fa-whatsapp text-primary"></i>  Escanear código QR</h2>
<mat-dialog-content class="mat-typography form-box-container">
    <div class="form-box  transparent-scroll-vertical two-panel two-panel--automatic-orientation animate__animated animate__fadeIn">
        <div class="form-box__content">
            <section class="informative-content">
                <section class="informative-content__section mt-2">
                    <mat-list role="list" class="list informative-content__list">
                        <mat-list-item role="listitem" class="list__item" *ngFor="let step of stepsToScanQrCode; index as i" [innerHTML]="(i + 1 ) + '. ' + step"></mat-list-item>
                    </mat-list>
                </section>
            </section>
        </div>  
        <div class="order-mobile-first form-box__content bg-transparent text-bg-light-info py-1" *ngIf="userMessagingServicePhoneLine.activation_status ==  phoneLineActivationStatus.PENDING ">
            <div class="form-box__content-header bg-transparent form-box__content-header-horizontal form-box__content-header-align-center">
                <strong class="form-box__content-title bg-transparent" [innerHTML]="messagingServiceApiPhoneLineService.ACCOUNT_PENDING_ACTIVATION_MESSAGE.title"> </strong>
            </div>
            <section class="informative-content__section mt-1">
                <p [innerHTML]="messagingServiceApiPhoneLineService.ACCOUNT_PENDING_ACTIVATION_MESSAGE.message">Nuestra solución ofrece una integración con WhatsApp Web mediante una API proporcionada por un proveedor externo. Esta integración permite conectar líneas de WhatsApp con nuestra plataforma SGC, utilizando un código QR para su vinculación.</p>
            </section>
        </div>  
        <div class="order-mobile-first form-box__content bg-transparent text-bg-light-info py-1" *ngIf="userMessagingServicePhoneLine.activation_status ==  phoneLineActivationStatus.ACTIVE ">
            <figure class="form-box__content-img-wrapper animate__animated animate__fadeIn" *ngIf="phoneLineQrCode.qr_url !=''">
                <img alt="Código QR para vinculacin a servicio de mensajería" class="animate__animated animate__fadeIn form-box__content-img" [src]="phoneLineQrCode.qr_url">                
                <figcaption class="form-box__content-img-caption text-primary" *ngIf="countQrCodeRequestsInInterval > maxQrCodeRequestsInInterval">
                    Solicita un nuevo código QR 
                    <button mat-icon-button color="primary" matTooltip="Solicitar código QR" (click)="requestQrCode()" aria-label="Soliitar código QR">
                        <mat-icon>refresh</mat-icon>
                    </button>
                </figcaption>
                <figcaption class="form-box__content-img-caption text-primary" *ngIf="countQrCodeRequestsInInterval <= maxQrCodeRequestsInInterval">cantidad de segundos restantes: {{phoneLineQrCode.qr_duration}} </figcaption>
            </figure>
            <article class="ilustration animate__animated animate__fadeIn" *ngIf="phoneLineQrCode.qr_url ==''">
                <dotlottie-player autoplay count="1" mode="normal"
                src="../../../../../../assets/img/mobile-scanner-qr.lottie"
                ></dotlottie-player>
                <span class="description">Obteniendo código QR...</span>    
              </article>       
        </div>   
    </div>
    
    <div class="loader" *ngIf="loading">
        <img src="../../../../assets/loader.svg">
    </div>

</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]="successfulScan" cdkFocusInitial class="btn btn-primary">Cerrar</button>
</mat-dialog-actions>
