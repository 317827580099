import { Component, OnInit, Inject } from '@angular/core';
import { ApiService } from 'src/app/core/api/api.service';
import { DataService } from 'src/app/core/data/data.service';
import { UtilsService } from 'src/app/core/utils/utils.service';
import { Router } from '@angular/router';

import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RequestMethodHTTP } from '../../enums/common-enums.enum';

@Component({
  selector: 'app-upcoming-device-invoices',
  templateUrl: './upcoming-device-invoices.component.html',
  styleUrls: ['./upcoming-device-invoices.component.scss']
})
export class UpcomingDeviceInvoicesComponent implements OnInit {
  user:any={};
  loading:boolean=false;

  device:any={};//dispoisitivo a consultar las proximas facturas. almacena los datos del dipositivo en SGC
  upcomingInvoicesList:any=[];//lista de facturas proximas a emitir
  
  monetaryUnitCode:string="";
  deviceUnitPrice:number=0;
  totalToProject:number=1;
  totalProjectionPrice:string="";
  
  moduleId:number=5;
  permissionsDataSgc:any=[];//permisos sobre el modulo;
  constructor(
    private dialogRef: MatDialogRef<UpcomingDeviceInvoicesComponent>,
    private api: ApiService,
    public utils: UtilsService,
    public dataService: DataService,
    private router:Router,
    @Inject(MAT_DIALOG_DATA) public data: any
  ){}
  ngOnInit(): void {
    this.dataService.checkPermissionModule(this.moduleId).then((permissions: any) => {
      this.user = this.dataService.getData("user");
      this.device = this.data.device;
      this.getUpcomingInvoicesPreview();
    }).catch(() => {
      this.utils.showMsg("Página no autorizada","No tiene permisos para ver esta página, contacte al administrador");
      this.router.navigate(['/']);
    });

  }
  getUpcomingInvoicesPreview(){
    this.loading = true;
    this.api.getData("invoice","?action=getUpcomingInvoicesPreview&device_id="+this.device.id+"&user_api_hash="+this.user.hash,true).then((data: any) => {
      if(data.status==1){
        this.upcomingInvoicesList= data.data;
        this.deviceUnitPrice = this.upcomingInvoicesList[0].details.price;
        this.monetaryUnitCode = this.upcomingInvoicesList[0].details.monetary_unit_code;
        this.totalToProject = this.upcomingInvoicesList.length;
        this.calculateProjectionPrice();
      }else{
        this.upcomingInvoicesList=[];
        this.utils.showResultRequest("error","Información",data.message);
      }
    }).catch((error:any)=>{
      this.utils.showResultRequest("error","Información",this.api.getDefaultMessage("las próximas facturas",false,false,RequestMethodHTTP.GET));
    }).finally(()=>this.utils.hideLoading((response:boolean)=>this.loading = false));
  }
  calculateProjectionPrice(){
    if(this.upcomingInvoicesList.length>0){
       this.totalProjectionPrice= "$ "+(this.totalToProject*this.deviceUnitPrice).toLocaleString()+" " +this.monetaryUnitCode;
       return;
    }
    this.totalProjectionPrice= "$ 0.00";
  }
  createInvoice(upcomingInvoice:any){
    let indexInvoice =this.upcomingInvoicesList.findIndex((invoice:any)=>invoice.created_at==upcomingInvoice.created_at);
    indexInvoice = indexInvoice==-1 || indexInvoice==0?false:indexInvoice;
    this.utils.showConfirm("Confirmar registro",indexInvoice !=false?((indexInvoice>1?("Los "+(indexInvoice)+" registros anteriores"):("El registro anterior"))+" a la seleccionada también "+(indexInvoice>1?("serán creados"):("será creado"))):"","Confirmar","Cancelar").then(()=>{
      this.loading = true;
      let invoicesToCreate= this.upcomingInvoicesList.slice(0,indexInvoice+1); 
      this.api.createData({action:"confirmEarlyInvoiceIssuance",user_api_hash:this.user.hash,device_id:this.device.id,invoices:invoicesToCreate}, "invoice",true).then((data: any) => {
        if(data.status==1){
          this.utils.showResultRequest("success","Información","Registro exitoso");
          this.dialogRef.close(true);
        } else {
          this.utils.showResultRequest("error","Información", data.message ?? "Error al generar las facturas");
        }
      }).catch((err: any) => {
        this.utils.showResultRequest("error","Información",this.api.getDefaultMessage("las facturas",false,false,RequestMethodHTTP.POST));
      }).finally(()=>this.utils.hideLoading((response:boolean)=>this.loading = false));

    }).catch();
  }
}