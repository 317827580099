<h2 mat-dialog-title>Gestión de categorías de preguntas</h2>
<mat-dialog-content class="mat-typography position-relative ">

  <div class="row overflow-auto position-relative table-container">
    <div class="col">
        <table class="w-100 h-100 data-table ">
            <thead class="thead position-sticky z-index-1 top-0 bg-white ">
                <tr class="tr">
                    <th class="th">N°</th>
                    <th class="th">Nombre</th>
                </tr>
            </thead>
            <tbody>
                <tr class="tr" *ngFor="let category of categoryList;index as counter">
                    <td class="td text-start"> 
                        <span class="d-flex flex-direction-row align-items-center justify-content-between">{{counter+1}}
                            <mat-icon class="text-info" *ngIf="validationService.isNullOrEmpty(category.user_id)"  [matTooltip]="validationService.isNullOrEmpty(category.user_id)?'Esta es una categoría predeterminada':''">feedback</mat-icon> 
                        </span>
                    </td>
                    <td class="td text-start">
                        <mat-form-field appearance="outline">
                            <mat-label>Nombre</mat-label>
                            <input matInput [disabled]="validationService.isNullOrEmpty(category.user_id)" [maxLength]="maxNameLength" required="" type="text" placeholder="Ejemplo: Exterior del vehículo" name="categoryName" [(ngModel)]="category.name">
                            <mat-error>El campo es requerido</mat-error>
                            <mat-hint align="end">{{category.name.length || 0}}/{{maxNameLength }}</mat-hint>
                        </mat-form-field>
                        <button mat-icon-button mat-button class="text-primary" matTooltip="Guardar edición" (click)="saveCategoryChanges(category,true)" *ngIf="!validationService.isNullOrEmpty(category.user_id)" [disabled]="loading"><mat-icon class="text-primary">edit</mat-icon></button>
                        <button mat-icon-button mat-button class="text-danger" matTooltip="Eliminar categoría" *ngIf="!validationService.isNullOrEmpty(category.user_id)" [disabled]="loading" (click)="deleteCategory(category)"><mat-icon class="text-danger">delete</mat-icon></button>
                    </td>
                </tr>
                <tr class="tr" >
                    <td class="td text-start"></td>
                    <td class="td text-start">
                        <mat-form-field appearance="outline" class="form-box__content-section-header__title">
                            <mat-label>Nueva categoría</mat-label>
                            <input matInput [maxLength]="maxNameLength" type="text" placeholder="Ejemplo: Exterior del vehículo" name="categoryName" [(ngModel)]="newCategoryName">
                            <mat-hint align="end">{{newCategoryName.length || 0}}/{{maxNameLength }}</mat-hint>
                        </mat-form-field>
                        <button mat-icon-button mat-button class="text-primary" matTooltip="Registrar" [disabled]="loading" (click)="saveCategoryChanges(undefined,false)"><mat-icon class="text-primary">add</mat-icon></button>                        
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]="updatedList" class="btn btn-primary" cdkFocusInitial>Cerrar</button>
</mat-dialog-actions>