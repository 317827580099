<section class="layout-container">
    <section class="form-box-container animate__animated animate__fadeIn">
        <div id="buttonBack" class="form-box-container-close form-box-container-close-dark" role="button" aria-label="Cerrar visualización de checklist" (click)="back()" matTooltip="Cerrar"></div>
        <div class="form-box bg-transparent animate__animated animate__fadeIn">
            <form class="form-box__content form-box__content-h-auto">
                <mat-accordion class="mat-expansion-panel-header-justify-end" multi>
                    <mat-expansion-panel [expanded]="true">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <mat-icon>checklist_rtl</mat-icon>
                                 Información
                            </mat-panel-title>
                            <mat-panel-description *ngIf="!editMode">
                                <button 
                                    mat-icon-button matSuffix
                                    matTooltip="Generar documento PDF"
                                    (click)="$event.stopPropagation();generatePdfDocument()"
                                    [attr.aria-label]="'Generar documento PDF del checklist'">
                                    <mat-icon class="custom-color icon-pdf icon-2xl">picture_as_pdf</mat-icon>
                                  </button>
                              </mat-panel-description>
                          </mat-expansion-panel-header>
                          
                        <article class="form-box__content-section">
                            <div class="form-box__content-section-header g-0">
                                <div class="form-box__content-section-header__title">{{ checklistSelected.name }}</div>
                                <div class="form-box__content-section-header__subtitle">{{checklistSelected.description}} </div>
                                <mat-form-field appearance="standard">
                                    <mat-label><strong>Fecha</strong></mat-label>
                                    <input matInput readonly="" type="datetime-local" name="ChecklistDate" [value]="checklistAnswered.created_at">
                                    <mat-hint *ngIf="editMode">La fecha definitiva se asigna una vez se registre las respuestas </mat-hint>
                                </mat-form-field>
                                <mat-form-field appearance="standard">
                                    <mat-label><strong>Dispositivo</strong></mat-label>
                                    <input matInput readonly="" type="text" name="currentDevice" [value]="validationService.isNullOrEmpty(checklistAnswered.device_plate_number) ? checklistAnswered.device_name : checklistAnswered.device_plate_number">
                                </mat-form-field>
                                <mat-form-field appearance="standard">
                                    <mat-label><strong>Conductor</strong></mat-label>
                                    <input matInput readonly="" type="text"v name="DriverName" [value]="checklistAnswered.driver_name">
                                </mat-form-field>
                            </div>
                        </article>
                    </mat-expansion-panel>

                    <ng-container *ngIf="!loadingChecklistQuestions">
                        <mat-expansion-panel *ngFor="let questionCategory of checklistQuestionsGroupedByCategories">
                          <mat-expansion-panel-header>
                            <mat-panel-title>
                              <mat-icon>touch_app</mat-icon>
                              {{questionCategory.category_name}}
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                            <div >
                                <ng-container *ngFor="let question of questionCategory.questions">
                                    <ng-container *ngIf="question.enabled">
                                        <div class="card-question"  *ngIf="question.id !=null">
                                            <div class="card-question__header">
                                                <strong class="card-question-name" id="questionId{{question.id}}">{{question.label}}</strong>
                                            </div>
                                            <p class="card-question-description">
                                                {{question.description}}
                                            </p>
                                            <ng-container *ngIf="question.form_control_type_id == FORM_CONTROL_TYPES.CHECKBOX || question.form_control_type_id==FORM_CONTROL_TYPES.RADIO">
                                                <div class="card-question__options"  >
                                                    <div  class="card-question__options-item">
                                                        <div class="card-question__options-item-left overflow-visible">
                                                            <ng-container *ngIf="question.form_control_type_id == FORM_CONTROL_TYPES.RADIO">
                                                                <div class="card-question__options-radio-group">
                                                                    <ng-container
                                                                    *ngFor="let option of question.answer_option;index as index">
                                                                        <mat-radio-group
                                                                        (change)="isAnswerOptionSelected(question.id,0,0,option.id!,$event)"
                                                                        name="ChecklistQuestionOptionRadio{{question.id}}">
                                                                            <mat-radio-button 
                                                                            color="primary"
                                                                            class="no-disabled-color"
                                                                            [disabled]="!editMode"
                                                                            [checked]="!!selectedChecklistResponse[selectedChecklistResponsePrefixes.question +selectedChecklistResponsePrefixes.separator + question.id][selectedChecklistResponsePrefixes.answer_option + selectedChecklistResponsePrefixes.separator + option.id!]"
                                                                            [value]="true">
                                                                            {{option.value }}
                                                                            </mat-radio-button>
                                                                        </mat-radio-group>
                                                                    </ng-container>
                                                                    
                                                                </div>
                                                            </ng-container>
                                                            
                                                            <ng-container *ngIf="question.form_control_type_id==FORM_CONTROL_TYPES.CHECKBOX">
                                                                <p
                                                                class="card-question__options-checkbox-group">
                                                                <ng-container *ngIf="editMode">
                                                                    <mat-checkbox  
                                                                        color="primary"
                                                                        [(ngModel)]="selectedChecklistResponse[selectedChecklistResponsePrefixes.question +selectedChecklistResponsePrefixes.separator + question.id][selectedChecklistResponsePrefixes.answer_option + selectedChecklistResponsePrefixes.separator + option.id!]"
                                                                        name="ChecklistQuestionOptionCheckbox{{question.id}}"
                                                                        *ngFor="let option of question.answer_option">{{option.value}} 
                                                                    </mat-checkbox>
                                                                </ng-container>
                                                                <ng-container *ngIf="!editMode">
                                                                    <mat-checkbox  
                                                                        class="no-disabled-color"
                                                                        color="primary"
                                                                        [checked]="!!selectedChecklistResponse[selectedChecklistResponsePrefixes.question +selectedChecklistResponsePrefixes.separator + question.id][selectedChecklistResponsePrefixes.answer_option + selectedChecklistResponsePrefixes.separator + option.id!]"
                                                                        [disabled]="true"
                                                                        name="ChecklistQuestionOptionCheckbox{{question.id}}"
                                                                        *ngFor="let option of question.answer_option">{{option.value}}
                                                                    </mat-checkbox>
                                                                </ng-container>
                                                                </p>
                                                            </ng-container>
                                                            
                                                        </div>
                                                        <div class="card-question__options-item-right" *ngIf="question.form_control_type_id == FORM_CONTROL_TYPES.RADIO && currentResponseActionType(question.id,question.form_control_type_id ,0,question.answer_option!) == dataService.CHECKLIST_ANSWER_OPTION_TYPE.REQUEST_IMAGE">
                                                            <div class="container-inputs-file">
                                                                <ng-container>
                                                                    <div class="img-form" 
                                                                    *ngIf="loadImageUrl(question.id!,question.form_control_type_id ,0,question.answer_option!); else imgRadioOptionElseBlock"
                                                                    matTooltip="imagen solicitada por la respuesta" matTooltipPosition="below">
                                                                        <img [src]="loadImageUrl(question.id!,question.form_control_type_id ,0,question.answer_option!)" class="animate__animated animate__bounceIn" alt="imagen solicitada por la opcion de respuesta">
                                                                        <div class="trash p-1" *ngIf="editMode" (click)="deleteUploadedImage(question.id!,question.form_control_type_id ,0,question.answer_option!)" matTooltip="Descartar imagen"><i class="fa-regular fa-trash-can small"></i></div>
                                                                    </div>
                                                                <ng-template #imgRadioOptionElseBlock>
                                                                    <div class="img-form" *ngIf="editMode">
                                                                        <i class="fa-regular fa-image icon"></i>
                                                                        <div class="txt">Selecciona o arrastra la imagen <br>{{'('+supportedFiles.accept_label+')'}}<br><span class="d-inline-flex align-items-center"></span></div>
                                                                        <input type="file"  [readonly]="!editMode" name="imageChecklistQuestion{{question.id}}" [accept]="supportedFiles.accept_value" (change)="uploadRequestedFile($event,question.id,question.form_control_type_id ,0,question.answer_option!)">
                                                                    </div>
                                                                </ng-template>
                                                                </ng-container>
                                                            </div>
                                                        </div>
                                                    </div> 
                                                </div>
                                                <div *ngIf="hasAnswerOptionSelected(question.id,question.form_control_type_id,0,question.answer_option) && editMode || (!editMode && !!assignAnswerOptionObservation(question.id,question.form_control_type_id ,0,question.answer_option!))" class="card-question__options-item">
                                                    <div class="observation">
                                                        <span class="observation__label">Observación</span>
                                                        <span class="observation__field" attr.colspan="{{(question.grid_answer_option?.length??0)+1}}">
                                                            <input [readonly]="!editMode" [maxLength]="maxFieldslength.observation" matInput placeholder="Opcional. Máximo {{maxFieldslength.observation}} carácteres" (input)="assignAnswerOptionObservation(question.id,question.form_control_type_id ,0,question.answer_option!,[],$event)" [value]="assignAnswerOptionObservation(question.id,question.form_control_type_id ,0,question.answer_option!)">
                                                        </span>
                                                    </div>
                                                </div>
                                            </ng-container>    
                                            <div class="card-question__options-grid overflow-x-auto" *ngIf="question.form_control_type_id==FORM_CONTROL_TYPES.RADIO_GRID || question.form_control_type_id==FORM_CONTROL_TYPES.CHECKBOX_GRID">
                                                <div class="card-question__options-grid-table-container">
                                                    <table class="card-question__options-grid-table">
                                                        <thead class="card-question__options-grid-table">
                                                            <tr>
                                                                <th> </th>
                                                                <th *ngFor="let option of question.grid_answer_option">{{option.value}}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <ng-container *ngFor="let subquestion of question.grid_subquestion;index as subquestionIndex">
                                                                <tr>
                                                                    <td>{{subquestion.value}}</td>
                                                                    <td *ngFor="let option of question.grid_answer_option;index as answerOptionIndex">
                                                                        <mat-checkbox 
                                                                        *ngIf="editMode && question.form_control_type_id==FORM_CONTROL_TYPES.CHECKBOX_GRID"
                                                                        color="primary"
                                                                        [(ngModel)]="selectedChecklistResponse[selectedChecklistResponsePrefixes.question +selectedChecklistResponsePrefixes.separator + question.id][selectedChecklistResponsePrefixes.answer_option +selectedChecklistResponsePrefixes.separator + subquestion.id + selectedChecklistResponsePrefixes.separator + option.id]"
                                                                        name="ChecklistQuestionOptionGridCheckbox{{question.id+''+subquestion.id}}"
                                                                        > </mat-checkbox>

                                                                        <mat-checkbox 
                                                                        *ngIf=" !editMode && question.form_control_type_id==FORM_CONTROL_TYPES.CHECKBOX_GRID"
                                                                        color="primary"
                                                                        class="no-disabled-color"
                                                                        [disabled]="true"
                                                                        [checked]="!!selectedChecklistResponse[selectedChecklistResponsePrefixes.question +selectedChecklistResponsePrefixes.separator + question.id][selectedChecklistResponsePrefixes.answer_option +selectedChecklistResponsePrefixes.separator + subquestion.id + selectedChecklistResponsePrefixes.separator + option.id]"
                                                                        name="ChecklistQuestionOptionGridCheckbox{{question.id+''+subquestion.id}}"
                                                                        ></mat-checkbox>
                                                                
                                                                        <mat-radio-group 
                                                                        (change)="isAnswerOptionSelected(question.id,subquestion.id!,option.id!,0,$event)"
                                                                        *ngIf="question.form_control_type_id == FORM_CONTROL_TYPES.RADIO_GRID"
                                                                        name="ChecklistQuestionOptionGridRadio{{question.id+''+subquestion.id}}">
                                                                        <mat-radio-button 
                                                                        class="no-disabled-color"
                                                                        [disabled]="!editMode"
                                                                        [value]="true"
                                                                        [checked]="!!selectedChecklistResponse[selectedChecklistResponsePrefixes.question + selectedChecklistResponsePrefixes.separator + question.id][selectedChecklistResponsePrefixes.answer_option + selectedChecklistResponsePrefixes.separator + subquestion.id+ selectedChecklistResponsePrefixes.separator +option.id]"
                                                                        color="primary">
                                                                        </mat-radio-button>
                                                                        </mat-radio-group>
                                                                    </td>
                                                                </tr>   
                                                                <tr *ngIf="hasAnswerOptionSelected(question.id,question.form_control_type_id,subquestion.id!,[],question.grid_answer_option) && editMode || (!editMode && !!assignAnswerOptionObservation(question.id,question.form_control_type_id,subquestion.id!,[],question.grid_answer_option))"> 
                                                                    <td class="observation__label">Observación</td>
                                                                    <td class="observation__field" attr.colspan="{{(question.grid_answer_option?.length??0)+1}}">
                                                                        <input [readonly]="!editMode" [maxLength]="maxFieldslength.observation" matInput placeholder="Opcional. Máximo {{maxFieldslength.observation}} carácteres" (input)="assignAnswerOptionObservation(question.id,question.form_control_type_id,subquestion.id!,[],question.grid_answer_option, $event)" value="{{assignAnswerOptionObservation(question.id,question.form_control_type_id,subquestion.id!,[],question.grid_answer_option)}}">
                                                                    </td>
                                                                </tr>                                                 
                                                                <tr *ngIf="question.form_control_type_id == FORM_CONTROL_TYPES.RADIO_GRID && currentResponseActionType(question.id,question.form_control_type_id,subquestion.id!,[],question.grid_answer_option) == dataService.CHECKLIST_ANSWER_OPTION_TYPE.REQUEST_IMAGE">
                                                                    <td class="all-col" attr.colspan="{{(question.grid_answer_option?.length??0)+1}}">
                                                                        <div class="container-inputs-file">
                                                                            <ng-container>
                                                                                <div class="img-form" 
                                                                                *ngIf="loadImageUrl(question.id!,question.form_control_type_id,subquestion.id!,[],question.grid_answer_option); else imgGridRadioOptionElseBlock"
                                                                                matTooltip="imagen solicitada por la respuesta" matTooltipPosition="below">
                                                                                    <img [src]="loadImageUrl(question.id!,question.form_control_type_id,subquestion.id!,[],question.grid_answer_option)" class="animate__animated animate__bounceIn" alt="imagen solicitada por la opcion de respuesta">
                                                                                    <div class="trash p-1" *ngIf="editMode" (click)="deleteUploadedImage(question.id!,question.form_control_type_id,subquestion.id!,[],question.grid_answer_option)"><i class="fa-regular fa-trash-can small"></i></div>
                                                                                </div>
                                                                            <ng-template #imgGridRadioOptionElseBlock>
                                                                                <div class="img-form">
                                                                                    <i class="fa-regular fa-image icon"></i>
                                                                                    <div class="txt">Selecciona o arrastra la imagen <br>{{'('+supportedFiles.accept_label+')'}}<br><span class="d-inline-flex align-items-center"></span></div>
                                                                                    <input type="file" [readOnly]="!editMode"  name="imageChecklistQuestionOptionGrid{{question.id+''+subquestion.id}}" [accept]="supportedFiles.accept_value" (change)="uploadRequestedFile($event,question.id,question.form_control_type_id,subquestion.id!,[],question.grid_answer_option)">
                                                                                </div>
                                                                            </ng-template>
                                                                            </ng-container>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </ng-container>
                                                            
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <mat-divider></mat-divider><mat-divider></mat-divider>
                                    </ng-container>
                                </ng-container>
                            </div>                        
                        </mat-expansion-panel>
                    </ng-container>
                    <mat-expansion-panel [expanded]="!loadingChecklistQuestions" *ngIf="!editMode">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <mat-icon>draw</mat-icon>
                                 Firma
                            </mat-panel-title>
                          </mat-expansion-panel-header>
                          <article class="form-box__content-section">
                            <div class="container-inputs-file">
                                <div class="img-form" 
                                *ngIf="!!checklistAnswered.signature_file_name;else imgSignElseBlock"
                                matTooltip="Firma del conductor" matTooltipPosition="below">
                                    <img [src]="this.urlBaseSgc + this.imageFolderRoute.CHECKLIST_SIGNATURES + checklistAnswered.signature_file_name" class="animate__animated animate__bounceIn" alt="Firma del conductor que ha respondido el checklist">
                                </div>
                                <ng-template #imgSignElseBlock>
                                    <div class="img-form" *ngIf="!editMode">
                                        <i class="fa-regular fa-image icon"></i>
                                        <div class="txt">Sin firma disponible</div>
                                    </div>
                                </ng-template>
                            </div>
                          </article>
                    </mat-expansion-panel>
                    <mat-expansion-panel [expanded]="loadingChecklistQuestions" *ngIf="loadingChecklistQuestions">
                        <article class="ilustration">
                            <dotlottie-player autoplay loop mode="normal"
                            src="../../../../assets/img/loading-checklist-questions.lottie"
                            ></dotlottie-player>
                            <span class="description">Cargando datos</span>
                        </article>
                    </mat-expansion-panel>
                </mat-accordion>
                <ng-container *ngIf="editMode">
                    <div class="form-box__content-actions" *ngIf="!successfulRegistration">
                        <button class="btn btn-outline-secondary" (click)="back()">Cancelar</button>
                        <ng-container *ngIf="checklistQuestionsGroupedByCategories.length>0">
                            <button class="btn btn-primary "  (click)="requestToSaveResponses()">Registrar</button>
                            <a role="button" class="cursor-pointer ms-auto" (click)="$event.preventDefault();resetChecklist()">Descartar respuestas</a>
                        </ng-container>
                        
                    </div>
                </ng-container>
                
            </form>
        </div>
    </section>
</section>
<div class="loader" *ngIf="loading">
    <img src="../../../../assets/loader.svg">
</div>

<div class="loader position-fixed" *ngIf="generatingDocument">
    <article class="ilustration">
        <dotlottie-player autoplay loop mode="normal"
        src="../../../../assets/img/scan_document.lottie"
        ></dotlottie-player>
        <span class="description">Generando PDF</span>
    </article>
</div>
