<span class="app-container__sidebar-toggle">
    <input type="checkbox"  (click)="toggleActionOpenSidebar()">
</span>

<div class="app-container__sidebar-content">
    <ng-container *ngFor="let menuItem of menuWebTemplate">
        <div class="app-container__sidebar-content-item" matTooltipPosition="right"
            [matTooltip]="!isOpenSidebar? (menuItem.label) : ''"
            [ngClass]="{'app-container__sidebar-content-item--selected': menuItem.id == webMenuManagementService.actualMenuWebTemplateId}"
            (click)="activeItemMenu(menuItem); $event.stopPropagation()">
            <span class="app-container__sidebar-icon app-container__sidebar-content-item-icon">
                <i class="fa-solid {{menuItem.icon}}"></i>
            </span>
            <div class="app-container__sidebar-content-item-name">{{menuItem.label}}</div>
            <div class="app-container__sidebar-content-item-arrow" *ngIf="menuItem.sections.length>0"
                (click)="activeItemMenu(menuItem); $event.stopPropagation()"><i
                    class="fa-solid fa-chevron-down"></i></div>
        </div>
        <div class="app-container__sidebar-content-subitems animate__animated animate__fadeIn"
            [ngClass]="{'app-container__sidebar-content-subitems--selected': webMenuManagementService.actualSubMenu == menuItem.id}">
            <ng-container *ngFor="let section of menuItem.sections">
                <div class="app-container__sidebar-content-item app-container__sidebar-content-subitems-item"
                    matTooltipPosition="right" 
                    [matTooltip]="!isOpenSidebar? (section.label) : ''"
                    [matMenuTriggerFor]="menuVersions" (click)="$event.stopPropagation()"
                    [ngClass]="{'app-container__sidebar-content-subitems-item--selected': section.id == webMenuManagementService.actualMenuWebTemplateId}"
                    (click)="activeItemMenu(section, true); $event.stopPropagation()">
                    <span class="app-container__sidebar-icon app-container__sidebar-content-subitems-item-icon">
                    </span>
                    <div class="app-container__sidebar-content-subitems-item-name">
                        
                        {{section.label}}
                        <mat-menu class="mat-menu" #menuVersions="matMenu">
                            <button  *ngFor="let version of section.versions;index as count" (click)="applyVersion(menuItem.name,section.name,version);$event.stopPropagation()" mat-menu-item
                            >{{version.label}}<small *ngIf="isVersionSelected(menuItem.name,section.name,version)" class="text-small"> (actual)</small></button>
                        </mat-menu>
                    </div>
                </div>
            </ng-container>
        </div>
    </ng-container>
    <hr class="app-container__sidebar-content-divider">
    <div class="app-container__sidebar-content-item app-container__sidebar-content-item--selected app-container__sidebar-content-item--selected-bordered" matTooltipPosition="right"
        [matTooltip]="!isOpenSidebar? ('Guardar cambios') : ''"
        (click)="update(); $event.stopPropagation()">
        <span class="app-container__sidebar-icon app-container__sidebar-content-item-icon">
            <i class="fa-solid fa-floppy-disk"></i>
        </span>
        <div class="app-container__sidebar-content-item-name">Guardar cambios</div>
    </div>
</div>
<div class="app-container__sidebar-content-bottom">
    <div class="app-container__sidebar-content-item app-container__sidebar-content-bottom-item"
        matTooltipPosition="right"
        [matTooltip]="!isOpenSidebar? (logoutButtonTitle) : ''"
        (click)="logout()">
        <span class="app-container__sidebar-icon app-container__sidebar-content-item-icon text-danger">
            <i class="fa-solid fa-right-from-bracket icon"></i>
        </span>
        <div class="app-container__sidebar-content-item-name">{{logoutButtonTitle}}</div>
    </div>
</div>