import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/core/api/api.service';
import { DataService, IVehicleTypeIcon, IVehicleTypeIconItem } from 'src/app/core/data/data.service';
import { UtilsService } from 'src/app/core/utils/utils.service';
import {Sort} from '@angular/material/sort';
import { Router } from '@angular/router';
import { ValidationService } from '../../../core/validation/validation.service';
import { IDataSummary, IIcon } from '../detail-popover/detail-popover.component';
import { IDriverGeneral } from '../../models/interfaces/idriver.model';
import { IViewsAvailable } from '../../models/interfaces/views-available';
import { IVehicleType } from '../../models/interfaces/ivehicle-type.model';
import { MatMenuTrigger } from '@angular/material/menu';
import { DeviceStatusRecordSummaryPopoverService } from '../device-status-record-summary-popover/device-status-record-summary-popover.service';
import { IDeviceBasicData } from '../../models/interfaces/idevice.model';
import { IDeviceStatusRecord } from '../../models/interfaces/idevice-status-option.model';
import { IApiRequestData } from '../../models/interfaces/iapi-request-data.model';
import { ObjectInitializationService } from '../../../core/object-initialization/object-initialization.service';
import { PageEvent } from '@angular/material/paginator';
//TRASLADAR COMPONENTE  FLEET-CONTROL
const localData:Record<"updateFleetManagementList",string> ={
  updateFleetManagementList:"updateFleetManagementList",
};
@Component({
  selector: 'app-user-devices-list',
  templateUrl: './user-devices-list.component.html',
  styleUrls: ['./user-devices-list.component.scss']
})
//para usuarios de rol "usuario final" funcionalidad del componente, de cargar los dispositivos y darle la opcion de cambiar el estado de disponibilidad de los dispositivos 
export class UserDevicesListComponent implements OnInit,OnDestroy {
  loading: boolean = false;//para svg de loading

  viewsAvailable:IViewsAvailable={
    device_checklist_management:{name:"device_checklist_management",show_view:true},
    device_state_management:{name:"driver_form",show_view:false}
  };

  moduleId=16;
  headers =  ["available","plate_number","imei","user_driver_name","user_driver_email","address"];//datos a exportar
  //para paginacion
  lengthPage=0;
  pageSize=50;
  pageSizeOptions:any=[this.pageSize];
  pageEvent:any;
  currentPage=0;

  searchValue:string ="";
  searchByAddress:boolean =false;
  sort={
    by:"",
    direction:""
  };
  /**
   * @type {string[]} Columnas a mostrar en la tabla
   */
  displayedColumns: string[] = ["available","plate_number","payload_capacity_in_tons","trailer_plate_number","user_driver_email","phone","device_status_record","address","actions"];
  filteredDevicesData:any =[];//los datos de dispositivos filtrados, 
  sortedDevicesData: any=[];//almacena la lista ordenada de datos. se usa con angular material para ordenar la tabla
  /** @type {Array} Lista de opciones de filtro en cuanto a si mostrar dispositivos disponibles(1) y ocupados(0) */
  availabilityStatus:{available:1|0,not_available:1|0}={
    available:1,
    not_available:0
  };
  timerIdSearchDevice!:NodeJS.Timeout;
  availabilityFilter:any=[this.availabilityStatus.available];
  availableTotal=0;
  notAvailableTotal=0;
  user:any=[];
  currentDrivenVehicle:any={};//el vehiculo que actualmente conduce el usuario

  updateFleetManagementList:boolean=true;

  /**
   *@type {IDeviceBasicData} indica Los datos del dispositivo a consultar registros de estados
   */
  deviceToConsultStatusRecord:IDeviceBasicData;
  /** @type {IDeviceStatusRecord[]} Los registros de estados del dispositivo seleccionado para dicha consulta */
  deviceStatusRecordSummary:IDeviceStatusRecord[] = [];
  /**@type {string} El nombre de la variable localStorage que almacena el id del intervalo de actualizacion de datos de dispositivos */
  intervalIdUserDeviceList:string="intervalIdUserDeviceList";
  deviceListUpdateTime:number=this.dataService.OneMinute*1.5;
  constructor(
    private api: ApiService,
    public dataService: DataService,
    public utils:UtilsService,
    private route:Router,
    public validationService: ValidationService,
    private objectInitializationService: ObjectInitializationService,
    private deviceStatusRecordSummaryPopoverService:DeviceStatusRecordSummaryPopoverService
  ){
    this.deviceToConsultStatusRecord = this.objectInitializationService.initializeIDeviceBasicData();
  }
  ngOnInit(): void {
    this.dataService.checkPermissionModule(this.moduleId).then((permissions: any) => {
      this.user = this.dataService.getData("user");
      this.getDeviceList(this.currentPage,true); 
      if(this.utils.isEmptyObject(this.dataService.getData(this.intervalIdUserDeviceList))){
        let intervalId = setInterval(()=>{
          let actualView = this.dataService.getData("actualView");
          if(!this.updateFleetManagementList && (!this.validationService.isNullOrEmpty(actualView) && !isNaN(actualView) && actualView != this.moduleId) || this.utils.isEmptyObject(this.dataService.getData("user"))){
            clearInterval(intervalId);
            return;
          }
          this.getDeviceList();
        },this.deviceListUpdateTime);
  
        this.dataService.setData(this.intervalIdUserDeviceList,intervalId);
        this.dataService.updateIntervalIdsList(intervalId);
      }
    }).catch(() => {
      this.utils.showMsg("Página no autorizada","No tiene permisos para ver esta página, contacte al administrador");
      this.route.navigate(['/']);
    });
  }
  async showDeviceStatusRecordSummary(device:any):Promise<void>{
    this.deviceToConsultStatusRecord = device as IDeviceBasicData;
    this.deviceStatusRecordSummary = [this.objectInitializationService.initializeIDeviceStatusRecord(this.user.id,0,null)]
    this.deviceStatusRecordSummary[0].name = "Buscando datos...";
    if(device.device_status_record.length== 0){
      this.deviceStatusRecordSummary[0].name = "Sin datos";
      return;
    }
    try{
      let response = await this.deviceStatusRecordSummaryPopoverService.getStateChangeHistory(device.id);
      if(this.validationService.isResponseApi(response))
        this.deviceStatusRecordSummary = response.status == 1 ? response.data as IDeviceStatusRecord[] : [];
      if(this.deviceStatusRecordSummary.length == 0){
        this.deviceStatusRecordSummary = [this.objectInitializationService.initializeIDeviceStatusRecord(this.user.id,0,null)]
        this.deviceStatusRecordSummary[0].name = "No se han encontrado datos";
      }
    }catch(error:any){
      this.deviceStatusRecordSummary = [this.objectInitializationService.initializeIDeviceStatusRecord(this.user.id,0,null)]
      this.deviceStatusRecordSummary[0].name = "Sin datos";
    }
  }
  searchDevices(){
    clearTimeout(this.timerIdSearchDevice);
    this.timerIdSearchDevice = setTimeout(() => {
      if(this.searchByAddress){
        let searchValue =  this.searchValue.trim().toLowerCase();
        this.filteredDevicesData = !this.validationService.isNullOrEmpty(searchValue)? this.utils.copyObject(this.sortedDevicesData.filter((device:any)=> (device.address??"").toLowerCase().includes(searchValue))):this.utils.copyObject(this.sortedDevicesData);
        return;
      }
      this.getDeviceList();
    }, 700);
  }
  exportFile(){
    this.utils.showConfirm("¿Está seguro de exportar los datos?", "Los datos pueden tomar un tiempo en procesarse", "Continuar", "Cancelar").then(() => {
      let data=this.getparametersForGetDeviceList(-1,-1);
      this.showLoading(true);
      this.api.getData("device",data,true).then((data: any)=>{
        if(data.status==1){
          setTimeout(() => {
            this.utils.downloadFileAsCSV(data.data,  "user_device_list_" + new Date().getTime().toString(), this.headers);
            this.utils.showMsg("","Datos exportados con éxito");
          }, 1500);
        }else
          this.utils.showMsg("","No se han encontrado dispositivos asociados");
        this.showLoading(false);
      }).catch((error:any)=>{
        this.showLoading(false);
        this.utils.showMsg("","No se han podido obtener la lista de dispositivos");
      });
    });
  }
  getparametersForGetDeviceList(page:number=this.currentPage,pageSize:number=this.pageSize){
    let data = this.api.getCommonQueryParameters("getUserDevices",this.user.hash,this.searchValue,page==-1?undefined:page,pageSize==-1?undefined:pageSize,this.sort.by,this.sort.direction);
    data +="&available="+this.availabilityFilter.join(",");
    return data;
  }
  getDeviceList(page:number=this.currentPage,loadDrivenVehicle:boolean=false){
    this.showLoading(true);
    let data=this.getparametersForGetDeviceList();
    this.api.getData("device",data,true).then((data: any)=>{
      console.log(data);
      (data.status==1)?this.currentPage=page:this.currentPage = 0;
      this.sortedDevicesData = data.data??[];
      this.filteredDevicesData = data.data??[];
      this.lengthPage =data.total??0;
      this.notAvailableTotal = data.not_available_total??0;
      this.availableTotal = data.available_total??0;
      (loadDrivenVehicle)?this.getDrivenVehicle(true):this.addDrivenVehicleToDeviceList();       
      this.showLoading(false);
    }).catch((error:any)=>{
      this.showLoading(false);
      this.currentPage = 0;
      this.sortedDevicesData=[];
      this.utils.showMsg("","No se han podido obtener la lista de dispositivos");
    });
  }
  isDeviceAvailabilityStatus(status:number){
    return this.availabilityFilter.some((item:any)=>item==status);
  }
  changeDeviceAvailabilityFilter(event:any){
    (event.checked)?this.availabilityFilter.push(parseInt(event.source.value)):this.availabilityFilter.splice(this.availabilityFilter.findIndex((item:number)=>item==parseInt(event.source.value)),1);
    this.getDeviceList();
  }
  /**
   * @description Obtiene el vehiculo que se encuentra el usuario ocupando actualmente
   */
  getDrivenVehicle(isAddToDeviceList:boolean=false){
    this.showLoading(true);
    this.api.getData("device","?action=getDrivenVehicle&user_api_hash="+this.user.hash,true).then((data: any)=>{
      this.currentDrivenVehicle =data.status==1?data.data:{};
      if(data.status==1 && isAddToDeviceList)        
          this.addDrivenVehicleToDeviceList();
      this.showLoading(false);
    }).catch((error:any)=>{
      this.showLoading(false);
      this.utils.showMsg("","Ha surgido un error mientras se intentaba obtener el dispositivo conducido por el usuario");
    });
  }
  addDrivenVehicleToDeviceList(){
    let deviceIndex = this.sortedDevicesData.findIndex((device:any)=>device.id==this.currentDrivenVehicle.id);
    if(!this.utils.isEmptyObject(this.currentDrivenVehicle) && (deviceIndex ==-1 || deviceIndex !=0)){
      if(deviceIndex !=-1 && deviceIndex !=0)
        this.sortedDevicesData.splice(deviceIndex,1);
      this.sortedDevicesData.unshift(this.currentDrivenVehicle);
    }
  }
  //para cambiar el estado de activo/inactivo del dispositivo
  changeAvailability(event:any,device:any){
    this.utils.showConfirm(this.utils.isEmptyObject(this.currentDrivenVehicle)?"¿Ocupar dispositivo?":"Liberar vehiculo?", "", "Confirmar", "Cancelar").then(() => {
      this.showLoading(true);

      let deviceData={
        user_api_hash:this.user.hash,//del manager
        id:device.id,
        available:event.checked?1:0,
        action:"changeAvailability"
      };
      this.api.createData(deviceData, "device",true).then((data: any) => {
        this.utils.showResultRequest(data.status==1?"success":"error","Información",data.message);
        if(data.status==1){
          this.getDeviceList(this.currentPage,true);
        }else
          event.source.checked=!event.checked;
        this.showLoading(false);
      }).catch((err: any) => {
        event.source.checked=!event.checked;
        this.showLoading(false);
        this.utils.showResultRequest("error","Error","Se ha presentado un error al actualizar el estado");
      });
    }).catch(()=>{
      event.source.checked=!event.checked;
    });
  }
  /**indica si el dispositivo puede ser seleccionado por el usuario  para realizar alguna modificacion de estado en él*/
  isDeviceSelectable(device:any){
    device.available = Number(device.available); 
    let deviceNotAvailableMessage =!!!device.available?("Dispositivo ocupado por el usuario "+device.user_driver_email+(device.user_driver_name!=""?(" ("+device.user_driver_name+")"):"")):"";
    let isEmptyCurrentDrivenVehicle:boolean= Object.entries(this.currentDrivenVehicle).length<1;
    if(isEmptyCurrentDrivenVehicle){
      return { message:!!device.available?"Usar dispositivo":deviceNotAvailableMessage,status:!!device.available};
    }
    else{
      let isDrivenDevice = device.id == this.currentDrivenVehicle.id;
      return { message:isDrivenDevice?"Modificar disponibilidad":(device.available?"Para hacer uso de este dispositivo primero modifica la disponibilidad del vehículo actualmente usado":deviceNotAvailableMessage),status:isDrivenDevice};
    }
  }
  deviceDataSummary(device:any):IDataSummary{
    return {
      imei:{label:"Imei",value:device.imei},
      cylinder_capacity:{label:"Cilindraje",value:this.validationService.isNullOrEmpty(device.cylinder_capacity)?"--":device.cylinder_capacity},
      vehicle_type:{label:"Tipo de vehículo", value:device.vehicle_type.name},
      vehicle_color:{label:"Color",value:this.validationService.isNullOrEmpty(device.vehicle_color)?"--":device.vehicle_color},
      vehicle_model:{label:"Modelo",value:this.validationService.isNullOrEmpty(device.vehicle_model)?"--":device.vehicle_model},
      payload_capacity_in_tons:{label:"Capacidad de carga útil (toneladas)",value:this.validationService.isNullOrEmpty(device.payload_capacity_in_tons)?"--":device.payload_capacity_in_tons}
    };
  }
  driverDataSummary(currentDriver:IDriverGeneral):IDataSummary{
    if(this.validationService.isNullOrEmpty(currentDriver))
      return {};
    return {
      document_number:{label:"Número de documento",value:currentDriver.document_number},
      boold_type:{label:"Tipo de sangre",value:currentDriver.boold_type},
      phone:{label:"Teléfono",value:currentDriver.phone},
      emergency_phone_number:{label:"Teléfono de emergencia", value:currentDriver.emergency_phone_number},
      email:{label:"Email",value:currentDriver.email},
    };
  }
  defineVehicleTypeICon(vehicle_type:IVehicleType):IIcon{
    let icon:IVehicleTypeIconItem |null  = this.validationService.isNullOrEmpty(vehicle_type.value)?null:this.dataService.VEHICLE_TYPES_ICONS[vehicle_type.value as keyof IVehicleTypeIcon];
    return {
      classes:"text-info",
      icon: this.validationService.isNullOrEmpty(icon?.icon_type) ?"directions_car":icon!.icon,
      icon_type:!this.validationService.isNullOrEmpty(icon?.icon_type) && icon?.icon_type=="font_awesome"?"font_awesome":"material_icon"
    };
  }
  sortData(sort: Sort) {
    this.sort.direction=sort.direction;
    this.sort.by=sort.active;
    this.getDeviceList();
  }
  paginator($event:PageEvent){
    this.pageSize = $event.pageSize;
    this.currentPage = $event.pageIndex;    
    this.getDeviceList();
  }
  goToView(view:"device_state_management"|"device_checklist_management"){
    this.updateFleetManagementList =false;
    this.route.navigate(["/dashboard/fleet-control/"+view.replace(/_/g,"-")]);
  }
  showLoading(show: boolean = true):void{
    if(show) this.loading = true;
    else setTimeout(() => { this.loading = false; }, 2000);
  }
  redirectToMap(latitude:number,longitude:number){
    window.open("https://www.google.com/maps?q="+latitude+","+longitude+"&z=17");
  }

  ngOnDestroy(): void {
    if(typeof this.intervalIdUserDeviceList == "number")
      clearInterval(this.intervalIdUserDeviceList);
  }
}