import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ValidationService } from 'src/app/core/validation/validation.service';
import { UtilsService } from 'src/app/core/utils/utils.service';
import { DataService } from 'src/app/core/data/data.service';
import { IDriverGeneral, IDriverIntgps, IDriverSgc } from 'src/app/shared/models/interfaces/idriver.model';
import { Router } from '@angular/router';
import { ObjectInitializationService } from 'src/app/core/object-initialization/object-initialization.service';
import { ApiService } from 'src/app/core/api/api.service';
import { IApiRequestData } from 'src/app/shared/models/interfaces/iapi-request-data.model';

type MaxFieldLength = Pick <IDriverGeneral,"emergency_phone_number"|"document_number"|"name"|"email"|"phone"|"rfid"|"description"|"address">;
@Component({
  selector: 'app-driver-form',
  templateUrl: './driver-form.component.html',
  styleUrls: ['./driver-form.component.scss']
})
export class DriverFormComponent  implements OnInit{
  
  loading:boolean =false;
  /**Indica si es actualizacion de datos de conductor */
  @Input() isUpdate:boolean =false;
  /** Indica si se va a actualizar la contraseña del usuario */
  isUpdatePassword:boolean =false;
  user:any={};
  moduleId:number=17;
  permissionsDataSgc:any=[];//permisos sobre el modulo

  /**El valor a buscar en la lista de dispositivos */
  deviceToSearch:string="";
  /**Almacena la lista de dispositivos, objetos con datos basicos sobre el dispositivo */
  deviceList:any =[];
  optionSelectAllDevices = {id:0,name:"Seleccionar todos",imei:""};
  FilteredDeviceList:any =[];
  /**Indica si todos los dispositivos  asociar al conductor estan seleccionados */
  allSelectedDevices:boolean =false;
  /**El valor a buscar en la lista de dispositivos para seleccion de dispositivo actual */
  deviceToSearchInCurrent:string="";
  /** Almacena la lista de dispositivos para el select de dispositivo actual */
  FilteredCurrentDeviceList:any =[];
  
  @Output() close = new  EventEmitter<{close:boolean,update_list:boolean}>();

  minPasswordLength:number = 10;
  maxFieldslength!: Record<keyof MaxFieldLength,number>;
  /**Usuario  actualizar o crear enviado desde la vista de conductores */
  @Input() inputNewDriver!:IDriverGeneral;

  /**Usuario  actualizar o crear */
  newDriver!:IDriverGeneral;
  
  countriesList:any = [];
  constructor(
    public utils: UtilsService,
    public validationService:ValidationService,
    public dataService:DataService,
    private objectInitialize:ObjectInitializationService,
    private route:Router,
    private api: ApiService
  ){
    this.maxFieldslength = {
      emergency_phone_number:25,
      document_number:25,
      name:50,
      email:100,
      phone:25,
      rfid:150,
      description:100,
      address:150
    };
    this.user = this.dataService.getData("user");
    this.newDriver = this.objectInitialize.initializeIDriver(this.user.id,this.user.country_id);
  }
  ngOnInit(): void {
    this.dataService.checkPermissionModule(this.moduleId).then((permissions: any) => {
      this.newDriver =  this.utils.copyObject(this.inputNewDriver);
      this.permissionsDataSgc=permissions;
      this.getCountries();
      this.getDeviceList();
    }).catch(() => {
      this.utils.showMsg("Página no autorizada","No tiene permisos para ver esta página, contacte al administrador");
      this.route.navigate(['/']);
    });
  }

  getDeviceList(){
    this.api.getData("device","?action=getUserImeiDevices&user_api_hash="+this.user.hash,true).then((devicesData:any)=>{
      this.deviceList=devicesData.status==1?[this.utils.copyObject(this.optionSelectAllDevices),...devicesData.data]:[this.utils.copyObject(this.optionSelectAllDevices)];
      this.FilteredDeviceList = this.utils.copyObject(this.deviceList);
      this.FilteredCurrentDeviceList = this.utils.copyObject(this.deviceList);
      if(typeof this.newDriver.devices !="undefined" && this.newDriver.devices.length==this.deviceList.length-1){
        this.newDriver.devices.unshift(this.optionSelectAllDevices.id);
        this.allSelectedDevices = true;
      }
    }).catch((error:any)=>{console.log("error devices, ",error);});
  }
  getCountries(){
    let countries = this.dataService.getData(this.dataService.UTILS_DATA_LIST.COUNTRIES);
    if(Object.entries(countries).length>0)
      return this.countriesList = countries;
    this.api.getData("country","?action=getCountries&user_api_hash="+this.user.hash,true).then((countriesData:any)=>{
    this.countriesList = countriesData.status?countriesData.data:[];
    if(countriesData.status)
      this.dataService.setData(this.dataService.UTILS_DATA_LIST.COUNTRIES, countriesData.data);
    }).catch(()=>this.countriesList = []);
  }
  enablePasswordUpdate(event:any){
    this.isUpdatePassword = event.checked;
  }
  adminDriver(){
    let error = this.dataIsValid();
    if(error !="")
      return this.utils.showMsg("", error, false);
    let passwordValidation = this.validPasswordCreationProcess();
    if(!passwordValidation.status)
      return this.utils.showResultRequest("error","Información",passwordValidation.message);  
    this.loading =true;
    const formDataIntgps:any = new FormData();
    const newDriverIntgps: Pick<IDriverIntgps,"id"|"user_id"|"device_id"|"device_port"|"name"|"rfid"|"phone"|"email"|"description"|"current"> & IApiRequestData = {id:this.newDriver.id, user_id:this.newDriver.user_id, device_id:this.newDriver.device_id,
      device_port:this.newDriver.device_port,name:this.newDriver.name, rfid:this.newDriver.rfid, phone:this.newDriver.phone,
      email:this.newDriver.email, description:this.newDriver.description,current:this.newDriver.current,user_api_hash:this.user.hash};
    this.utils.fillFormData(formDataIntgps,newDriverIntgps,true);
    for(let deviceId of this.newDriver.devices??[]){
      if(this.optionSelectAllDevices.id !== deviceId)
        formDataIntgps.append("devices[]",deviceId);
    }
    formDataIntgps.set("current",this.newDriver.current ? 1 : 0)
    if(this.isUpdate) 
      formDataIntgps.set("_method","PUT");
    for(let obj of (formDataIntgps)){
      console.log(obj);
    }  
    this.api.createData(formDataIntgps, "api/"+(this.isUpdate?"edit_user_driver":"add_user_driver"),false,this.api.CONTENT_TYPE.FORM_DATA).then((result: any) => {
      if(!result.status){
        this.utils.showResultRequest("error","Información", this.api.getDefaultMessage("los datos del conductor en INTGPS",this.isUpdate,false,"POST"));
        this.utils.hideLoading(()=>this.loading=false);
        return
      }
      const newDriverSgc:Pick<IDriverSgc,"id"|"user_id"|"document_number"|"country_id"|"password"|"password_confirmation"|"emergency_phone_number"|"boold_type"|"address"> & IApiRequestData = {id:this.validationService.isNullOrEmpty(this.newDriver.id)?result.item.id:this.newDriver.id, user_id:this.newDriver.user_id,
        document_number:this.newDriver.document_number,country_id:this.newDriver.country_id,password:this.newDriver.password,password_confirmation:this.newDriver.password_confirmation, user_api_hash:this.user.hash,
        emergency_phone_number:this.newDriver.emergency_phone_number,boold_type:this.newDriver.boold_type,address:this.newDriver.address,action:this.isUpdate?"update":"add"};
      console.log(newDriverSgc);
      this.api.createData(newDriverSgc, "driver",true).then((data: any) => {
        this.utils.showResultRequest(data.status==1?"success":"error","Información",data.status==1?(data.message):(data.message??this.api.getDefaultMessage("los datos del conductor",this.isUpdate,false,"POST")));
        console.log(data);
        if(data.status)
          this.closeModal(true);
        this.utils.hideLoading(()=>this.loading=false);
      }).catch((error)=>{
        console.log(error);
        this.utils.showResultRequest("error","Información",error.error.message??this.api.getDefaultMessage("los datos del conductor",this.isUpdate,false,"POST"));
        this.utils.hideLoading(()=>this.loading=false);
      });
    })
    .catch((error:any)=>{
      console.log(error);
      this.utils.showResultRequest("error","Información",error.error.message??this.api.getDefaultMessage("los datos del conductor en INTGPS",this.isUpdate,false,"POST"));
      this.utils.hideLoading(()=>this.loading=false);
    });
  }
  /** Valida si los datos son validos para ser registrados, en caso de exito retorna true */
  dataIsValid():string{
    if( !this.validationService.isNullOrEmpty(this.newDriver.document_number) && this.validationService.isNullOrEmpty(this.newDriver.country_id))
      return "Si indica un numero de documento entonces debe de indicar el pais";
    if(!this.validationService.isNullOrEmpty(this.newDriver.country_id) && this.validationService.isNullOrEmpty(this.newDriver.document_number))
      return "Si indica un país entonces debe de indicar un número de documento";
    return "";
    /*return !(this.validationService.isNullOrEmpty(this.newDriver.document_number) || 
       this.validationService.isNullOrEmpty(this.newDriver.name) ||
       this.validationService.isNullOrEmpty(this.newDriver.country_id));*/
  }
  /** Realiza proceso de validacion de contraseña */
  validPasswordCreationProcess(){
    let response:any = {status:false,message:""};
    if(!this.validationService.isNullOrEmpty(this.newDriver.password) || !this.validationService.isNullOrEmpty(this.newDriver.password_confirmation)){
      if(this.validationService.isNullOrEmpty(this.newDriver.password))
        response.message = "Debe de indicar una contraseña";
      else if((response.message = this.showPasswordValidityMessage())!="")
        response.message = "La contraseña "+response.message.toLowerCase();
      else if(this.newDriver.password !=this.newDriver.password_confirmation)
        response.message = "Las contraseñas no coinciden";
    }
    response.status = response.message == "";
    return response;
  }
  showPasswordValidityMessage():string{
    if(this.validationService.hasWhitespace(this.newDriver.password??""))
      return "No puede contener espacios en blanco";
    if(typeof this.newDriver.password !="undefined" && this.newDriver.password!=null && this.newDriver.password.length<this.minPasswordLength)
      return "Debe contener minimo "+this.minPasswordLength+" carácteres"; 
    return "";
  }
  toggleSelectAllDevicesToAssociate(){
    this.newDriver.devices = this.allSelectedDevices?[]:this.deviceList.map((device:any)=>device.id);
    this.allSelectedDevices = !this.allSelectedDevices;
  }
  clearSearchDevice(filterListType:"currentDevice"|"associatedDevices"){
    this.deviceToSearch = "";
    this.searchDevice(filterListType);
  }
  /**Busca un dispositivo dentro de la lista disponible a ser elegidos */
  searchDevice(filterListType:"currentDevice"|"associatedDevices"){
    this.deviceToSearch = this.deviceToSearch.toLowerCase();
    let FilteredDeviceList = !this.validationService.isNullOrEmpty(this.deviceToSearch) && this.deviceToSearch.length > 0? this.deviceList.filter((device: any) => device.imei.includes(this.deviceToSearch) || device.plate_number.toLowerCase().includes(this.deviceToSearch)):this.deviceList;
    if(filterListType =="currentDevice")
      this.FilteredCurrentDeviceList = this.utils.copyObject(FilteredDeviceList);
    else
      this.FilteredDeviceList = this.utils.copyObject(FilteredDeviceList);
  }
  closeModal(updateList:boolean){
    this.close.emit({close:true,update_list:updateList});
  }
}