import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AngularSignaturePadModule } from '@almothafar/angular-signature-pad';

import { DeviceStatusRecordSummaryPopoverComponent } from './components/device-status-record-summary-popover/device-status-record-summary-popover.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularMaterialModule } from '../angular-material.module';
import { DeviceChecklistViewerComponent } from "./components/device-checklist-viewer/device-checklist-viewer.component";
import { DeviceChecklistResponseHandlerComponent } from './components/device-checklist-viewer/device-checklist-response-handler/device-checklist-response-handler.component';
import { DeviceChecklistAnsweredHistoryComponent } from './components/device-checklist-answered-history/device-checklist-answered-history.component';

@NgModule({
  declarations: [
    DeviceStatusRecordSummaryPopoverComponent,
    DeviceChecklistViewerComponent,
    DeviceChecklistResponseHandlerComponent,
    DeviceChecklistAnsweredHistoryComponent,
    
  ],
  imports: [
    CommonModule,
    AngularMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    AngularSignaturePadModule
  ],
  exports:[
    DeviceStatusRecordSummaryPopoverComponent,
    DeviceChecklistViewerComponent,
    DeviceChecklistAnsweredHistoryComponent,
  ],
  schemas:[CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule { }
