<div class="form-box animate__animated animate__fadeIn">
    <form  class="form-box__content form-box__content-h-auto form-box__content-force-h-adaptive" id="registrationForm">
        <mat-accordion multi>
            <mat-expansion-panel expanded>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <i class="fa-solid fa-brush icon icon-xl"></i> Colores base
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="form-box__content-fields-inline">
                    <mat-form-field appearance="fill">
                        <mat-label>Color primario</mat-label>
                        <input matInput type="color" [(ngModel)]="designData.color_palette.primary_color" name="primaryColor">
                        <mat-icon matSuffix [matTooltip]="'Color que se usa como color predominante en el sitio web'">
                            <i class="fa-solid fa-circle-question pointer icon"></i>
                        </mat-icon>
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                        <mat-label>Color secundario</mat-label>
                        <input matInput type="color" [(ngModel)]="designData.color_palette.secondary_color" name="secondaryColor">
                        <mat-icon matSuffix  [matTooltip]="'Color complementario que se usa para fondos de determinados apartados del sitio web'">
                            <i class="fa-solid fa-circle-question pointer icon"></i>
                        </mat-icon>
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                        <mat-label>Color de acentuación</mat-label>
                        <input matInput type="color" [(ngModel)]="designData.color_palette.accent_color" name="accenColor">
                        <mat-icon matSuffix [matTooltip]="'El color de acentuación es utilizado para enfatizar información y elementos relevantes'">
                            <i class="fa-solid fa-circle-question pointer icon"></i>
                        </mat-icon>
                    </mat-form-field> 
                </div>
            </mat-expansion-panel>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <i class="fa-solid fa-paintbrush icon icon-xl"></i> Texto
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="form-box__content-fields-inline">
                    <mat-form-field  appearance="fill">
                        <mat-label>Color de títulos</mat-label>
                        <input matInput type="color" [(ngModel)]="designData.color_palette.title_color" name="titleColor">
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                        <mat-label>Color de texto</mat-label>
                        <input matInput type="color"  [(ngModel)]="designData.color_palette.text_color" name="textColor">
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                        <mat-label>Tipografía del titulo</mat-label>
                        <mat-select name="typographyTitle" [(ngModel)]="designData.typography.title" >
                            <ng-container   *ngFor="let type of typefaces">
                                <mat-option [value]="type" [ngStyle]="{'font-family':type}">{{type}}</mat-option>
                            </ng-container>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                        <mat-label>Tipografía del texto</mat-label>
                        <mat-select name="typographyText" [(ngModel)]="designData.typography.paragraph">
                            <ng-container   *ngFor="let type of typefaces">
                                <mat-option [value]="type" [ngStyle]="{'font-family':type}">{{type}}</mat-option>
                            </ng-container>
                        </mat-select>
                    </mat-form-field>  
                </div>
            </mat-expansion-panel>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <i class="fa-solid fa-panorama icon icon-xl"></i> Imagenes
                    </mat-panel-title>
                </mat-expansion-panel-header>
               <span class="form-text">Imagenes que serán usadas en cada página del sitio web. El <strong>tamaño máximo</strong> para el <strong>Favicon es de {{supported_files.max_favicon_size}}</strong> y para las demás <strong>imagenes es de {{supported_files.max_image_size}}.</strong><br>
                Optimiza los tamaños de cada imagen para mejorar la experiencia de carga del sitio web. Puedes usar herramientas de compresión de imagenes como <a href="https://tinypng.com/" target="_blank">TinyPNG</a>, <a href="https://www.iloveimg.com/es/comprimir-imagen" target="_blank">iLoveIMG</a> u otros.<br>
                Para editar imagenes puedes usar editores en linea como <a href="https://www.befunky.com/es/crear/" target="_blank">BeFunky</a>, <a href="https://pixlr.com/es/e/" target="_blank">pixlr</a>, u otro de su preferencia
                </span>
                <mat-tab-group dynamicHeight>
                    <mat-tab label="General">
                        <div class="container-inputs-file">
                            <ng-container>
                                <div 
                                *ngIf="(!validationService.isNullOrEmpty(designData.global_image_filenames.favicon) || !validationService.isNullOrEmpty(imageManager.global.temp_favicon)) && !imageManager.global.delete_favicon; else faviconElseBlock"
                                class="img-form"  matTooltip="Cuenta con Favicon" matTooltipPosition="below">
                                    <!--<i class="fa-solid fa-check-double icon text-success animate__animated animate__bounceIn"></i>-->
                                    <img [src]="!validationService.isNullOrEmpty(imageManager.global.temp_favicon)? imageManager.global.temp_favicon : urlAssets.base+urlAssets.favicon+designData.global_image_filenames.favicon" class="animate__animated animate__bounceIn">                                    
                                    <div class="trash p-1" (click)="deleteGlobalFile('favicon','temp_favicon')"><i class="fa-regular fa-trash-can small"></i></div>
                                </div>
                                <ng-template #faviconElseBlock>
                                    <div class="img-form">
                                        <i class="fa-regular fa-image icon"></i>
                                        <div class="txt">Selecciona o arrastra el icono para el 'Favicon' <br>(.ico)</div>
                                        <input type="file"  name="faviconTopSectionBg" accept="image/x-icon" (change)="uploadGlobalFile($event,'favicon','temp_favicon')">
                                    </div>
                                </ng-template>
                            </ng-container>
                            <ng-container>
                                <div class="img-form" 
                                *ngIf="(!validationService.isNullOrEmpty(designData.global_image_filenames.logo) || !validationService.isNullOrEmpty(imageManager.global.temp_logo)) && !imageManager.global.delete_logo; else logoElseBlock"
                                matTooltip="logo" matTooltipPosition="below">
                                    <img [src]="!validationService.isNullOrEmpty(imageManager.global.temp_logo)? imageManager.global.temp_logo : urlAssets.base+urlAssets.logos+designData.global_image_filenames.logo" class="animate__animated animate__bounceIn">
                                    <div class="trash p-1" (click)="deleteGlobalFile('logo','temp_logo')"><i class="fa-regular fa-trash-can small"></i></div>
                                </div>
                            <ng-template #logoElseBlock>
                                <div class="img-form">
                                    <i class="fa-regular fa-image icon"></i>
                                    <div class="txt">Selecciona o arrastra la imagen del logo <br>{{supported_files.accept_label}}<br><span class="d-inline-flex align-items-center">Proporcion recomendada: {{supported_files.crop_aspect_ratio.r1_1.label}}<mat-icon>{{supported_files.crop_aspect_ratio.r1_1.mat_icon}}</mat-icon></span></div>
                                    <input type="file"  name="logo" [accept]="supported_files.accept_value" (change)="uploadGlobalFile($event,'logo','temp_logo')">
                                </div>
                            </ng-template>
                            </ng-container>
                            
                        </div>
                    </mat-tab>
                    <mat-tab label="Inicio">
                        <div class="container-inputs-file">
                            <ng-container>
                                <div 
                                *ngIf="(!validationService.isNullOrEmpty(designData.home.image_filename.top_section_bg_image) || !validationService.isNullOrEmpty(imageManager.pages.home.temp_top_section_bg_image)) && !imageManager.pages.home.delete_top_section_bg_image; else homeElseBlock"
                                class="img-form" matTooltip="Fondo de sección principal de Inicio" matTooltipPosition="below">
                                    <img [src]="validationService.isNullOrEmpty(imageManager.pages.home.temp_top_section_bg_image) ? urlAssets.base+urlAssets.background+designData.home.image_filename.top_section_bg_image : imageManager.pages.home.temp_top_section_bg_image" class="animate__animated animate__bounceIn">
                                    <div class="trash p-1" (click)="deleteImg('home','top_section_bg_image','temp_top_section_bg_image')"><i class="fa-regular fa-trash-can small"></i></div>
                                </div>
                                <ng-template #homeElseBlock>                                
                                    <div class="img-form">
                                        <i class="fa-regular fa-image icon"></i>
                                        <div class="txt">Selecciona o arrastra la imagen para fondo de seccion principal de 'Inicio' <br>{{supported_files.accept_label}}<br><span class="d-inline-flex align-items-center">Proporcion recomendada: {{supported_files.crop_orientation.landscape + " - "+supported_files.crop_aspect_ratio.r3_2.label}}<mat-icon>{{supported_files.crop_aspect_ratio.r3_2.mat_icon}}</mat-icon></span></div>
                                        <input type="file"  name="homeTopSectionBg" [accept]="supported_files.accept_value" (change)="uploadImage($event,'home','top_section_bg_image','temp_top_section_bg_image')">
                                    </div>
                                </ng-template>  
                            </ng-container>
                            <ng-container> 
                                <div 
                                *ngIf="(!validationService.isNullOrEmpty(designData.home.image_filename.top_section_presentation_image) || !validationService.isNullOrEmpty(imageManager.pages.home.temp_top_section_presentation_image)) && !imageManager.pages.home.delete_top_section_presentation_image; else homeTopSectionPresentationImage"
                                class="img-form" matTooltip="Imagen usada en la sección principal de la página de 'Inicio'" matTooltipPosition="below">
                                    <img [src]="validationService.isNullOrEmpty(imageManager.pages.home.temp_top_section_presentation_image) ? urlAssets.base+urlAssets.gallery+designData.home.image_filename.top_section_presentation_image:imageManager.pages.home.temp_top_section_presentation_image" class="animate__animated animate__bounceIn">
                                    <div class="trash p-1" (click)="deleteHomeImg('top_section_presentation_image','temp_top_section_presentation_image','delete_top_section_presentation_image')"><i class="fa-regular fa-trash-can small"></i></div>    
                                </div>
                                <ng-template #homeTopSectionPresentationImage>
                                    <div class="img-form" >
                                        <i class="fa-regular fa-image icon"></i>
                                        <div class="txt">Selecciona o arrastra la imagen usada para la sección principal de la página de 'Inicio' <br>{{supported_files.accept_label}} <br><span class="d-inline-flex align-items-center">Proporcion recomendada: {{supported_files.crop_orientation.landscape + " - "+supported_files.crop_aspect_ratio.r3_2.label}}<mat-icon>{{supported_files.crop_aspect_ratio.r3_2.mat_icon}}</mat-icon></span></div>
                                        <input type="file"  name="homeTopSectionPresentationImage" [accept]="supported_files.accept_value" (change)="uploadHomeImg($event,'top_section_presentation_image','temp_top_section_presentation_image','delete_top_section_presentation_image')">
                                    </div>
                                </ng-template> 
                            </ng-container>
                        </div>
                    </mat-tab>
                    <mat-tab label="¿Quiénes somos?">
                        <!--Inicio para imagenes de identidad corporativa-->
                        <mat-tab-group dynamicHeight>
                            <mat-tab >
                                <ng-template mat-tab-label>
                                    Seccion Superior
                                    <mat-icon matTooltip="Indica la imagen de la sección superior de la página 'Acerca de nosotros'">help</mat-icon>
                                  </ng-template>
                                <div class="container-inputs-file">
                                    <div 
                                    *ngIf="(!validationService.isNullOrEmpty(designData.about_us.image_filename.top_section_bg_image) || !validationService.isNullOrEmpty(imageManager.pages.about_us.temp_top_section_bg_image)) && !imageManager.pages.about_us.delete_top_section_bg_image; else aboutUsElseBlock"
                                    class="img-form" matTooltip="Fondo de sección principal de Acerca de nosotros" matTooltipPosition="below">
                                        <img [src]="validationService.isNullOrEmpty(imageManager.pages.about_us.temp_top_section_bg_image) ? urlAssets.base+urlAssets.background+designData.about_us.image_filename.top_section_bg_image:imageManager.pages.about_us.temp_top_section_bg_image" class="animate__animated animate__bounceIn">
                                        <div class="trash p-1" (click)="deleteImg('about_us','top_section_bg_image','temp_top_section_bg_image')"><i class="fa-regular fa-trash-can small"></i></div>    
                                    </div>
                                    <ng-template #aboutUsElseBlock>
                                        <div class="img-form" >
                                            <i class="fa-regular fa-image icon"></i>
                                            <div class="txt">Selecciona o arrastra la imagen para fondo de seccion principal de 'acerca de nosotros' <br>{{supported_files.accept_label}}<br><span class="d-inline-flex align-items-center">Proporcion recomendada: {{supported_files.crop_orientation.landscape + " - "+supported_files.crop_aspect_ratio.r3_2.label}}<mat-icon>{{supported_files.crop_aspect_ratio.r3_2.mat_icon}}</mat-icon></span></div>
                                            <input type="file"  name="aboutUsTopSectionBg" [accept]="supported_files.accept_value" (change)="uploadImage($event,'about_us','top_section_bg_image','temp_top_section_bg_image')">
                                        </div>
                                    </ng-template>
                                </div>
                            </mat-tab>
                            <mat-tab>
                                <ng-template mat-tab-label>
                                    Identidad corporativa
                                    <mat-icon matTooltip="Indica las imágenes de identidad corporativa - Misión, visión y valores">help</mat-icon>
                                </ng-template>
                                <div class="container-inputs-file">
                                    
                                    <div 
                                    *ngIf="(!validationService.isNullOrEmpty(designData.about_us.image_filename.corporate_identity_image_mission) || !validationService.isNullOrEmpty(imageManager.pages.about_us.temp_corporate_identity_image_mission)) && !imageManager.pages.about_us.delete_corporate_identity_image_mission; else aboutUsCorporateIdentityMission"
                                    class="img-form" matTooltip="Imagen usada en la seccion 'Mision'" matTooltipPosition="below">
                                        <img [src]="validationService.isNullOrEmpty(imageManager.pages.about_us.temp_corporate_identity_image_mission) ? urlAssets.base+urlAssets.corporate_identity+designData.about_us.image_filename.corporate_identity_image_mission:imageManager.pages.about_us.temp_corporate_identity_image_mission" class="animate__animated animate__bounceIn">
                                        <div class="trash p-1" (click)="deleteAboutUsCorporateIdentityImg('corporate_identity_image_mission','temp_corporate_identity_image_mission','delete_corporate_identity_image_mission')"><i class="fa-regular fa-trash-can small"></i></div>    
                                    </div>
                                    <ng-template #aboutUsCorporateIdentityMission>
                                        <div class="img-form" >
                                            <i class="fa-regular fa-image icon"></i>
                                            <div class="txt">Selecciona o arrastra la imagen usada para la misión <br>{{supported_files.accept_label}}<br><span class="d-inline-flex align-items-center">Proporcion recomendada: {{supported_files.crop_orientation.landscape + " - "+supported_files.crop_aspect_ratio.r5_4.label}}<mat-icon>{{supported_files.crop_aspect_ratio.r5_4.mat_icon}}</mat-icon></span></div>
                                            <input type="file"  name="aboutUsCorporateIdentityMission" [accept]="supported_files.accept_value" (change)="uploadAboutUsCorporateIdentityImg($event,'corporate_identity_image_mission','temp_corporate_identity_image_mission','delete_corporate_identity_image_mission')">
                                        </div>
                                    </ng-template> 
                                    
                                </div>
                                <div class="container-inputs-file">
                                    <ng-container>
                                        <div 
                                        *ngIf="(!validationService.isNullOrEmpty(designData.about_us.image_filename.corporate_identity_image_vision) || !validationService.isNullOrEmpty(imageManager.pages.about_us.temp_corporate_identity_image_vision)) && !imageManager.pages.about_us.delete_corporate_identity_image_vision; else aboutUsCorporateIdentityVision"
                                        class="img-form" matTooltip="Imagen usada en la seccion 'Visión'" matTooltipPosition="below">
                                            <img [src]="validationService.isNullOrEmpty(imageManager.pages.about_us.temp_corporate_identity_image_vision) ? urlAssets.base+urlAssets.corporate_identity+designData.about_us.image_filename.corporate_identity_image_vision:imageManager.pages.about_us.temp_corporate_identity_image_vision" class="animate__animated animate__bounceIn">
                                            <div class="trash p-1" (click)="deleteAboutUsCorporateIdentityImg('corporate_identity_image_vision','temp_corporate_identity_image_vision','delete_corporate_identity_image_vision')"><i class="fa-regular fa-trash-can small"></i></div>    
                                        </div>
                                        <ng-template #aboutUsCorporateIdentityVision>
                                            <div class="img-form" >
                                                <i class="fa-regular fa-image icon"></i>
                                                <div class="txt">Selecciona o arrastra la imagen usada para la visión <br>{{supported_files.accept_label}}<br><span class="d-inline-flex align-items-center">Proporcion recomendada: {{supported_files.crop_orientation.landscape + " - "+supported_files.crop_aspect_ratio.r5_4.label}}<mat-icon>{{supported_files.crop_aspect_ratio.r5_4.mat_icon}}</mat-icon></span></div>
                                                <input type="file"  name="aboutUsCorporateIdentityVision" [accept]="supported_files.accept_value" (change)="uploadAboutUsCorporateIdentityImg($event,'corporate_identity_image_vision','temp_corporate_identity_image_vision','delete_corporate_identity_image_vision')">
                                            </div>
                                        </ng-template> 
                                    </ng-container>
                                    <ng-container>
                                        <div 
                                        *ngIf="(!validationService.isNullOrEmpty(designData.about_us.image_filename.corporate_identity_image_values) || !validationService.isNullOrEmpty(imageManager.pages.about_us.temp_corporate_identity_image_values)) && !imageManager.pages.about_us.delete_corporate_identity_image_values; else aboutUsCorporateIdentityValues"
                                        class="img-form" matTooltip="Imagen usada en la seccion 'Valores corporativos'" matTooltipPosition="below">
                                            <img [src]="validationService.isNullOrEmpty(imageManager.pages.about_us.temp_corporate_identity_image_values) ? urlAssets.base+urlAssets.corporate_identity+designData.about_us.image_filename.corporate_identity_image_values:imageManager.pages.about_us.temp_corporate_identity_image_values" class="animate__animated animate__bounceIn">
                                            <div class="trash p-1" (click)="deleteAboutUsCorporateIdentityImg('corporate_identity_image_values','temp_corporate_identity_image_values','delete_corporate_identity_image_values')"><i class="fa-regular fa-trash-can small"></i></div>    
                                        </div>
                                        <ng-template #aboutUsCorporateIdentityValues>
                                            <div class="img-form" >
                                                <i class="fa-regular fa-image icon"></i>
                                                <div class="txt">Selecciona o arrastra la imagen usada para los Valores corporativos <br>{{supported_files.accept_label}}<br><span class="d-inline-flex align-items-center">Proporcion recomendada: {{supported_files.crop_orientation.landscape + " - "+supported_files.crop_aspect_ratio.r5_4.label}}<mat-icon>{{supported_files.crop_aspect_ratio.r5_4.mat_icon}}</mat-icon></span></div>
                                                <input type="file"  name="aboutUsCorporateIdentityValues" [accept]="supported_files.accept_value" (change)="uploadAboutUsCorporateIdentityImg($event,'corporate_identity_image_values','temp_corporate_identity_image_values','delete_corporate_identity_image_values')">
                                            </div>
                                        </ng-template> 
                                     </ng-container>
                                </div>
                            </mat-tab>
                        </mat-tab-group>
                        <!--Fin para imagenes de identidad corporativa-->

                    </mat-tab>
                    <mat-tab label="Nuestros servicios">
                        <div class="container-inputs-file">
                            <div 
                            *ngIf="(!validationService.isNullOrEmpty(designData.our_services.image_filename.top_section_bg_image) || !validationService.isNullOrEmpty(imageManager.pages.our_services.temp_top_section_bg_image)) && !imageManager.pages.our_services.delete_top_section_bg_image; else ourServicesElseBlock"
                            class="img-form" matTooltip="Fondo de sección principal de Nuetros servicios" matTooltipPosition="below">
                                <img [src]="validationService.isNullOrEmpty(imageManager.pages.our_services.temp_top_section_bg_image)? urlAssets.base+urlAssets.background+designData.our_services.image_filename.top_section_bg_image : imageManager.pages.our_services.temp_top_section_bg_image" class="animate__animated animate__bounceIn">
                                <div class="trash p-1" (click)="deleteImg('our_services','top_section_bg_image','temp_top_section_bg_image')"><i class="fa-regular fa-trash-can small"></i></div>
                            </div>
                            <ng-template #ourServicesElseBlock>
                                <div class="img-form" >
                                    <i class="fa-regular fa-image icon"></i>
                                    <div class="txt">Selecciona o arrastra la imagen para fondo de seccion principal de 'Nuestros servicios' <br>{{supported_files.accept_label}}<br><span class="d-inline-flex align-items-center">Proporcion recomendada: {{supported_files.crop_orientation.landscape + " - "+supported_files.crop_aspect_ratio.r3_2.label}}<mat-icon>{{supported_files.crop_aspect_ratio.r3_2.mat_icon}}</mat-icon></span></div>
                                    <input type="file"  name="ourServicesTopSectionBg" [accept]="supported_files.accept_value" (change)="uploadImage($event,'our_services','top_section_bg_image','temp_top_section_bg_image')">
                                </div>
                            </ng-template>
                        </div>
                    </mat-tab>
                    <mat-tab label="Contactanos">
                        <div class="container-inputs-file">
                            <div 
                            *ngIf="(!validationService.isNullOrEmpty(designData.contact_us.image_filename.top_section_bg_image) || !validationService.isNullOrEmpty(imageManager.pages.contact_us.temp_top_section_bg_image)) && !imageManager.pages.contact_us.delete_top_section_bg_image; else contactUsElseBlock"
                            class="img-form" matTooltip="Fondo de sección principal de Contactanos" matTooltipPosition="below">
                                <img [src]="validationService.isNullOrEmpty(imageManager.pages.contact_us.temp_top_section_bg_image) ? urlAssets.base+urlAssets.background+designData.contact_us.image_filename.top_section_bg_image : imageManager.pages.contact_us.temp_top_section_bg_image" class="animate__animated animate__bounceIn">
                                <div class="trash p-1" (click)="deleteImg('contact_us','top_section_bg_image','temp_top_section_bg_image')"><i class="fa-regular fa-trash-can small"></i></div>
                            </div>
                           
                            <ng-template #contactUsElseBlock>
                                <div class="img-form" >
                                    <i class="fa-regular fa-image icon"></i>
                                    <div class="txt">Selecciona o arrastra la imagen para fondo de seccion principal de 'Contactanos' <br>{{supported_files.accept_label}}<br><span class="d-inline-flex align-items-center">Proporcion recomendada: {{supported_files.crop_orientation.landscape + " - "+supported_files.crop_aspect_ratio.r3_2.label}}<mat-icon>{{supported_files.crop_aspect_ratio.r3_2.mat_icon}}</mat-icon></span></div>
                                    <input type="file"  name="contactUsTopSectionBg" [accept]="supported_files.accept_value" (change)="uploadImage($event,'contact_us','top_section_bg_image','temp_top_section_bg_image')">
                                </div>
                            </ng-template>
                            
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </mat-expansion-panel>
          </mat-accordion>
        <div class="form-box__content-actions">
            <button class="btn btn-primary " (click)="update()">{{isUpdate?'Actualizar':'Registrar'}}</button>
        </div>
    </form>
</div>