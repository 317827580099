<section class="form-box-container animate__animated animate__fadeIn">
    <div class="form-box-container-close form-box-container-close-dark" (click)="close()"></div>
    <div class="form-box bg-transparent animate__animated animate__fadeIn">
        <div class="form-box__content form-box__content-h-auto">
            <mat-accordion class="mat-expansion-panel-header-align-between">
                <mat-expansion-panel hideToggle [expanded]="!isUpdate">
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      Checklist
                    </mat-panel-title>
                    <mat-panel-description>
                      Datos básicos
                      <mat-icon>assignment</mat-icon>
                    </mat-panel-description>
                  </mat-expansion-panel-header>
                    <article class="form-box__content-section">
                        <div class="form-box__content-section-header">
                            <mat-form-field appearance="outline" hintLabel="Max {{maxFieldLengthDeviceChecklist.name }} carácteres" class="form-box__content-section-header__title">
                                <mat-label>Nombre del Checklist</mat-label>
                                <input matInput [maxLength]="maxFieldLengthDeviceChecklist.name" required="" (input)="saveChecklistChangesTimer(checklistToEdit,true)" type="text" placeholder="Ejemplo: Control de Estado y Mantenimiento" name="ChecklistName" [(ngModel)]="checklistToEdit.name">
                                <mat-error>El campo es requerido</mat-error>
                                <mat-hint align="end">{{checklistToEdit.name.length || 0}}/{{maxFieldLengthDeviceChecklist.name }}</mat-hint>
                            </mat-form-field>
                            <mat-form-field appearance="outline" hintLabel="Max {{maxFieldLengthDeviceChecklist.description}} carácteres" class="form-box__content-section-header__subtitle">
                                <mat-label>Descripción</mat-label>
                                <textarea matInput (input)="saveChecklistChangesTimer(checklistToEdit,true)" [maxLength]="maxFieldLengthDeviceChecklist.description" name="checklistDescription" [(ngModel)]="checklistToEdit.description" class="textarea" placeholder="Ejemplo: Registra aquí la información esencial sobre la condición y mantenimiento de los vehículos de la flota para garantizar un rendimiento óptimo y la seguridad en la carretera"></textarea>
                                <mat-icon matSuffix [matTooltipClass]="'tooltip-multiline'" matTooltip="Proporciona una breve descripción del propósito de este checklist">help</mat-icon>
                                <mat-hint align="end">{{checklistToEdit.description?.length || 0}}/{{maxFieldLengthDeviceChecklist.description}}</mat-hint>
                            </mat-form-field>
                        </div>
                        <div class="form-control-with-suffix" *ngIf="isUpdate">
                            <mat-form-field appearance="outline">
                                <mat-label>Agregar Categoría de preguntas</mat-label>
                                <mat-select  [(ngModel)]="newCategoryToAdd" name="newCategoryToAdd">
                                    <mat-option *ngFor="let category of deviceChecklistQuestionCategoriesFiltered" (onSelectionChange)="addNewCategoryToChecklist(category)" [value]="category.id">{{category.name}}</mat-option>
                                    <mat-option disabled="" *ngIf="deviceChecklistQuestionCategoriesFiltered.length==0"  [value]="">Sin opciones disponibles</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <button class="btn btn-primary suffix"(click)="openCategoryManagement()" matTooltip="Administrar categorías personalizadas"><mat-icon>add</mat-icon></button>
                        </div>
                        <div class="form-box__content-section-actions" *ngIf="!isUpdate">
                            <button class="btn btn-outline-primary"[disabled]="loading"  (click)="saveChecklistChanges(checklistToEdit)">{{!loading?'Registrar':'Registrando...'}}</button>
                        </div>
                    </article>
                </mat-expansion-panel>
                <mat-expansion-panel [expanded]="isUpdate">
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      Preguntas
                    </mat-panel-title>
                    <mat-panel-description>
                      <span></span>
                      <mat-icon>checklist_rtl</mat-icon>
                    </mat-panel-description>
                  </mat-expansion-panel-header>
  
                    <mat-tab-group dynamicHeight *ngIf="isUpdate" class="animate__animated animate__fadeIn">
                        <mat-tab *ngFor="let questionCategory of checklistQuestionsGroupedByCategories">
                            <ng-template mat-tab-label>
                                <div class="legend">
                                    <span class="description">{{questionCategory.category_name}} 
                                    <button mat-icon-button  [attr.aria-label]="'Agregar pregunta'" (click)="prepareNewQuestion(questionCategory);$event.stopPropagation()" matTooltip="Agregar pregunta ">
                                        <mat-icon  class="text-primary">add_circle</mat-icon>
                                    </button>
                                    </span>
                                </div>
                            </ng-template>
                            <div cdkDropList  (cdkDropListDropped)="dropQuestion($event,questionCategory.questions)">
                                <ng-container *ngFor="let question of questionCategory.questions">
                                    <div class="card-question" cdkDrag *ngIf="question.id !=null">
                                        <div class="draggable-handle draggable-handle-horizontal" cdkDragHandle><mat-icon>drag_indicator</mat-icon></div>
                                        <div class="card-question__header">
                                            <mat-form-field class="card-question-input" appearance="outline" hintLabel="Max {{maxFieldLengthQuestion.label }} carácteres">
                                                <mat-label>Pregunta</mat-label>
                                                <input matInput [maxLength]="maxFieldLengthQuestion.label" (input)="saveQuestionModificationsTimer(question,question.id != null,'input')" type="text" placeholder="" name="ChecklistQuestionName_{{question.id}}" [(ngModel)]="question.label">
                                                <mat-hint align="end">{{question.label.length || 0}}/{{maxFieldLengthQuestion.label }}</mat-hint>
                                            </mat-form-field>
                                            
                                            <mat-form-field appearance="outline" class="card-question__input">
                                                <mat-label>Tipo de campo </mat-label>
                                                <mat-select name="formControlType{{question.id}}" (selectionChange)="saveQuestionModificationsTimer(question,question.id != null,'select')"  [(ngModel)]="question.form_control_type_id">
                                                    <ng-container   *ngFor="let type of  formControlTypes">
                                                        <mat-option [value]="type.id">
                                                            <mat-icon aria-hidden="false" aria-label="icono de opcion de tipo de campo" [fontIcon]="type.name=='checkbox'?'check_box':(type.name=='radio'?'radio_button_checked':type.name=='radio_grid'?'apps':'grid_view')"></mat-icon>
                                                            {{type.label}}
                                                            </mat-option>
                                                    </ng-container>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                        <mat-form-field class="card-question__description" appearance="outline" hintLabel="Max {{maxFieldLengthQuestion.description}} carácteres">
                                            <mat-label>Descripción</mat-label>
                                            <textarea matInput [maxLength]="maxFieldLengthQuestion.description" (input)="saveQuestionModificationsTimer(question,question.id != null,'input')" name="checklistQuestionDescription{{question.id}}" [(ngModel)]="question.description" class="textarea" placeholder=""></textarea>
                                            <mat-hint align="end">{{question.description?.length || 0}}/{{maxFieldLengthQuestion.description}}</mat-hint>
                                        </mat-form-field>
                                        <div class="card-question__options" *ngIf="question.form_control_type_id==FORM_CONTROL_TYPES.CHECKBOX || question.form_control_type_id==FORM_CONTROL_TYPES.RADIO" cdkDropList  (cdkDropListDropped)="dropAnswerOption($event,question.answer_option!,question.id)">
                                            <ng-container  *ngFor="let option of question.answer_option">
                                                <div  class="card-question__options-item" cdkDrag>
                                                    <div class="card-question__options-item-left">
                                                        <div class="draggable-handle" cdkDragHandle><mat-icon>drag_indicator</mat-icon></div>
                                                        <mat-radio-button disabled="" *ngIf="question.form_control_type_id==FORM_CONTROL_TYPES.RADIO"></mat-radio-button>
                                                        <mat-checkbox disabled="" *ngIf="question.form_control_type_id==FORM_CONTROL_TYPES.CHECKBOX"></mat-checkbox>
                                                        <div class="card-question__options-item-left-control">
                                                            <mat-form-field class="card-question__input" appearance="standard" hintLabel="Max {{maxFieldLengthAnswerOption.value }} carácteres">
                                                                <input matInput [maxLength]="maxFieldLengthAnswerOption.value" required="" (input)="saveQuestionAnswerOptionModificationsTimer(option,option.id != null,'input')" type="text" placeholder="" name="ChecklistQuestionAnswerOption{{option.id}}" [(ngModel)]="option.value">
                                                                <mat-error>Indique un valor</mat-error>
                                                                <mat-hint align="end">{{option.value.length || 0}}/{{maxFieldLengthAnswerOption.value }}</mat-hint>
                                                            </mat-form-field>
                                                            <button mat-icon-button mat-button class="text-primary"  (click)="deleteAnswerOption(question.answer_option!,option)" *ngIf="question.answer_option!.length>1"><mat-icon class="text-danger">delete</mat-icon></button>
                                                        </div>
                                                    </div>
                                                    <div class="card-question__options-item-right" *ngIf="question.enabled_actions && question.form_control_type_id == FORM_CONTROL_TYPES.RADIO">
                                                        <mat-form-field appearance="outline" class="card-question__input">
                                                            <mat-label>Asociar acción</mat-label>
                                                            <mat-select name="actionType{{option.id}}" (selectionChange)="saveQuestionAnswerOptionModificationsTimer(option,option.id != null,'select')" [(ngModel)]="option.action_type_id">
                                                                <ng-container *ngFor="let type of checklistAnswerOptionActionTypes">
                                                                    <mat-option [value]="type.id" [selected]="option.action_type_id==type.id">
                                                                        {{type.label}}</mat-option>
                                                                    <mat-option [value]="">
                                                                        Sin acción</mat-option>
                                                                </ng-container>
                                                            </mat-select>
                                                        </mat-form-field>
                                                    </div>
                                                </div> 
                                            </ng-container>
                                            <div class="card-question__options-item">
                                                <div class="card-question__options-item-left">
                                                    <div class="draggable-handle invisible"><mat-icon>drag_indicator</mat-icon></div>
                                                    <mat-radio-button disabled="" *ngIf="question.form_control_type_id==FORM_CONTROL_TYPES.RADIO"></mat-radio-button>
                                                    <mat-checkbox disabled="" *ngIf="question.form_control_type_id==FORM_CONTROL_TYPES.CHECKBOX"></mat-checkbox>
                                                    <div class="card-question__options-item-left-control">
                                                        <mat-form-field class="card-question__input" appearance="standard">
                                                            <input matInput type="text" placeholder="Agregar otra opción" [maxLength]="0" (click)="prepareNewQuestionAnswerOption(question)" name="prepareNewQuestionAnswerOption">
                                                        </mat-form-field>
                                                    </div>
                                                </div>
                                            <div class="card-question__options-item-right"></div>
                                            </div>     
                                        </div>
                                        <div class="card-question__options-grid" *ngIf="question.form_control_type_id==FORM_CONTROL_TYPES.RADIO_GRID || question.form_control_type_id==FORM_CONTROL_TYPES.CHECKBOX_GRID">
                                            <div  class="card-question__options-grid-container" cdkDropList  (cdkDropListDropped)="dropGridSubquestion($event,question.grid_subquestion!,question.id,'grid_subquestion')">
                                                <div class="card-question__options-item pt-1 borderless"><b>Filas</b> </div>
                                                <div class="card-question__options-grid-container-item" *ngFor="let option of question.grid_subquestion;index as index" cdkDrag >
                                                <div class="row">
                                                        <div class="draggable-handle" cdkDragHandle><mat-icon>drag_indicator</mat-icon></div>
                                                        <span>{{index+1}}. </span>
                                                        <div class="fill">
                                                            <mat-form-field class="card-question__input" appearance="standard" hintLabel="Max {{maxFieldLengthGridSubquetion.value }} carácteres">
                                                                <input matInput [maxLength]="maxFieldLengthGridSubquetion.value" required="" (input)="saveGridOptionsModificationsTimer(option,option.id != null,'input','grid_subquestion')" type="text" placeholder="" name="ChecklistGridQuestionOption{{option.id}}" [(ngModel)]="option.value">
                                                                <mat-error>Indique un valor</mat-error>
                                                                <mat-hint align="end">{{option.value.length || 0}}/{{maxFieldLengthGridSubquetion.value }}</mat-hint>
                                                            </mat-form-field>
                                                            <button mat-icon-button mat-button  (click)="deleteGridOption(question.grid_subquestion!,option,'grid_subquestion')" *ngIf="question.grid_subquestion!.length>1"><mat-icon  class="text-danger">delete</mat-icon></button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="card-question__options-grid-container-item"  >
                                                    <div class="row">
                                                         <div class="draggable-handle invisible"></div>
                                                         <mat-form-field class="card-question__input" appearance="standard">
                                                             <input matInput [maxLength]="0" required="" (click)="prepareNewGridOption(question,'grid_subquestion')" type="text" placeholder="Agregar fila" name="ChecklistGridQuestionOptionNew">
                                                         </mat-form-field>
                                                    </div>
                                                 </div>
                                            </div> 
                                            <div  class="card-question__options-grid-container" cdkDropList (cdkDropListDropped)="dropGridSubquestion($event,question.grid_answer_option!,question.id,'grid_answer_option')">
                                                <div class="card-question__options-grid-container-item pt-1 borderless"><b>Columnas</b> </div>
                                                <div class="card-question__options-grid-container-item" *ngFor="let option of question.grid_answer_option" cdkDrag >
                                                    <div class="row">
                                                        <div class="draggable-handle" cdkDragHandle><mat-icon>drag_indicator</mat-icon></div>
                                                        <mat-radio-button disabled="" *ngIf="question.form_control_type_id==FORM_CONTROL_TYPES.RADIO_GRID "></mat-radio-button>
                                                        <mat-checkbox disabled="" *ngIf="question.form_control_type_id==FORM_CONTROL_TYPES.CHECKBOX_GRID"></mat-checkbox>
                                                        <div class="card-question__options-item-left-control">
                                                            <mat-form-field class="card-question__input" appearance="standard" hintLabel="Max {{maxFieldLengthAnswerOption.value }} carácteres">
                                                                <input matInput [maxLength]="maxFieldLengthAnswerOption.value" required="" (input)="saveGridOptionsModificationsTimer(option,option.id != null,'input','grid_answer_option')" type="text" placeholder="" name="ChecklistQuestionAnswerOption{{option.id}}" [(ngModel)]="option.value">
                                                                <mat-error>Indique un valor</mat-error>
                                                                <mat-hint align="end">{{option.value.length || 0}}/{{maxFieldLengthAnswerOption.value }}</mat-hint>
                                                            </mat-form-field>
                                                            <button mat-icon-button mat-button (click)="deleteGridOption(question.grid_answer_option!,option,'grid_answer_option')" *ngIf="question.grid_answer_option!.length>1"><mat-icon  class="text-danger">delete</mat-icon></button>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="card-question__options-item-left-control">
                                                            <mat-form-field appearance="outline" class="card-question__input" *ngIf="question.enabled_actions && question.form_control_type_id == FORM_CONTROL_TYPES.RADIO_GRID">
                                                                <mat-label>Asociar acción</mat-label>
                                                                <mat-select name="actionTypeGrid{{option.id}}" (selectionChange)="saveGridOptionsModificationsTimer(option,option.id != null,'select','grid_answer_option')" [(ngModel)]="option.action_type_id">
                                                                    <ng-container *ngFor="let type of checklistAnswerOptionActionTypes">
                                                                        <mat-option [value]="type.id" [selected]="option.action_type_id==type.id">
                                                                            {{type.label}}</mat-option>
                                                                        <mat-option [value]="">
                                                                            Sin acción</mat-option>
                                                                    </ng-container>
                                                                </mat-select>
                                                            </mat-form-field>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="card-question__options-grid-container-item">
                                                    <div class="row">
                                                        <div class="draggable-handle invisible"></div>
                                                        <mat-radio-button disabled="" *ngIf="question.form_control_type_id==FORM_CONTROL_TYPES.RADIO_GRID "></mat-radio-button>
                                                        <mat-checkbox disabled="" *ngIf="question.form_control_type_id==FORM_CONTROL_TYPES.CHECKBOX_GRID"></mat-checkbox>
                                                        <div class="card-question__options-item-left-control">
                                                            <mat-form-field class="card-question__input" appearance="standard">
                                                                <input matInput [maxLength]="0" required="" (click)="prepareNewGridOption(question,'grid_answer_option')" type="text" placeholder="Agregar columna" name="ChecklistQuestionAnswerOptionNew">
                                                            </mat-form-field>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> 
                                        </div>
                                        <div class="card-question-footer">
                                            <mat-form-field appearance="legacy" class="card-question__input select" >
                                                <mat-select name="questionCategory"  (selectionChange)="saveQuestionModifications(question,true,true)" [(ngModel)]="question.device_checklist_question_category_id">
                                                    <ng-container   *ngFor="let type of deviceChecklistQuestionUsedCategories">
                                                        <mat-option [value]="type.id">
                                                            {{type.name}}</mat-option>
                                                    </ng-container>
                                                </mat-select>
                                            </mat-form-field>
                                            <mat-slide-toggle  color="primary" [(ngModel)]="question.enabled_actions" (change)="toggleAnswerOptionsEnabledActions(question,$event)" [disabled]="loading" *ngIf="question.form_control_type_id == FORM_CONTROL_TYPES.RADIO || question.form_control_type_id == FORM_CONTROL_TYPES.RADIO_GRID" >Agregar acciones</mat-slide-toggle>
                                            <div class="divider-vertical"></div>
                                            <mat-slide-toggle  color="primary" [(ngModel)]="question.enabled" [disabled]="loading" (change)="saveQuestionModificationsTimer(question,question.id != null,'toggle')" >Disponible</mat-slide-toggle>
                                            <button mat-icon-button mat-button (click)="showConfirmDeleteQuestion(question,questionCategory)" [disabled]="loading" matTooltip="Eliminar pregunta"><mat-icon  class="text-danger">delete</mat-icon></button>   
                                        </div>
                                    </div>
                                    <div class="card-question" *ngIf="question.id==null">
                                        <div class="card-question__actions card-question__actions-top">
                                            <button matTooltip="Guardar pregunta" class="text-primary button-icon" mat-button (click)="saveQuestionModifications(question)" [disabled]="loading"><mat-icon>add_circle</mat-icon>Guardar</button>   
                                        </div>
                                        <div class="card-question__header">
                                            <mat-form-field class="card-question-input" appearance="outline" hintLabel="Max {{maxFieldLengthQuestion.label }} carácteres">
                                                <mat-label>Pregunta</mat-label>
                                                <input matInput [maxLength]="maxFieldLengthQuestion.label" required="" type="text" placeholder="" name="ChecklistQuestionName_{{question.id}}" [(ngModel)]="question.label">
                                                <mat-error>Campo obligatorio</mat-error>
                                                <mat-hint align="end">{{question.label.length || 0}}/{{maxFieldLengthQuestion.label }}</mat-hint>
                                            </mat-form-field>
                                            
                                            <mat-form-field appearance="outline" class="card-question__input">
                                                <mat-label>Tipo de campo </mat-label>
                                                <mat-select required="" name="formControlType{{question.id}}"  [(ngModel)]="question.form_control_type_id">
                                                    <ng-container   *ngFor="let type of  formControlTypes">
                                                        <mat-option [value]="type.id">
                                                            <mat-icon aria-hidden="false" aria-label="icono de opcion de tipo de campo" [fontIcon]="type.name=='checkbox'?'check_box':(type.name=='radio'?'radio_button_checked':type.name=='radio_grid'?'apps':'grid_view')"></mat-icon>
                                                            {{type.label}}
                                                            </mat-option>
                                                    </ng-container>
                                                </mat-select>
                                                <mat-error>Campo obligatorio</mat-error>
                                            </mat-form-field>
                                        </div>
                                        <mat-form-field class="card-question__description" appearance="outline" hintLabel="Max {{maxFieldLengthQuestion.description}} carácteres">
                                            <mat-label>Descripción</mat-label>
                                            <textarea matInput [maxLength]="maxFieldLengthQuestion.description" name="checklistQuestionDescription{{question.id}}" [(ngModel)]="question.description" class="textarea" placeholder=""></textarea>
                                            <mat-hint align="end">{{question.description?.length || 0}}/{{maxFieldLengthQuestion.description}}</mat-hint>
                                        </mat-form-field>
                                    </div>
                                    <mat-divider></mat-divider><mat-divider></mat-divider>
                                </ng-container>
                            </div>

                        </mat-tab>
                    </mat-tab-group>
                    <article class="ilustration" *ngIf="loadingChecklistQuestions">
                        <dotlottie-player autoplay loop mode="normal"
                        src="../../../../../../assets/img/loading-checklist-questions.lottie"
                        ></dotlottie-player>
                        <span class="description">Cargando datos</span>
                    </article>
                </mat-expansion-panel>
              </mat-accordion>
        </div>
    </div>
</section>
<!-- Form para la actualizacion o creacion de categorias-->
<mat-menu #deviceStatusForm="matMenu" class="detail-popover">
    <span class="detail-popover__btn-close"></span>
    <span class="detail-popover__title">
        <h2 class="detail-popover__title-value">{{''}}</h2>
    </span>
    <span mat-menu-item [disableRipple]="true" (click)="$event.stopPropagation()" class="detail-popover-content">
        <form class="detail-popover-content__form">
            <div class="detail-popover-actions">
                <button class="btn btn-primary"  mat-raised-button>{{'Accion'}}</button>            
            </div>
        </form>
    </span>
</mat-menu>