<section class="form-box-container animate__animated animate__fadeIn">
    <section class="form-box-container__header">
        <h2 class="form-box-container__header-title"><span class="icon"><i
                    class="fa-solid fa-satellite-dish"></i></span> dispositivos vinculados: <strong
                class="text-primary"> {{totalDevices}}</strong></h2>
        <div class="form-box-container__header-options">

        </div>
    </section>
    <div class="form-box animate__animated animate__fadeIn">
        <div class="form-box__content form-box__content-h-auto">
            <mat-tab-group>
                <mat-tab>
                  <ng-template mat-tab-label>
                    <mat-icon class="text-info">stacked_line_chart</mat-icon>
                    Vinculaciones
                  </ng-template>
                  <div class="form-box__content-section form-box__content-section-shadow mt-2">
                    <div class="d-block">
                        <div class="chart">
                            <div class="chart__content">
                                <div class="chart__content-header-title">{{configDevicesByLinkingStatus.title}} </div>
                                <div class="chart__content-header-options chart__content-header-options--align-left">
                                    <label class="chart__content-header-options-input">Año<input type="number"
                                            [(ngModel)]="filterByYear" [max]="maxYear" (mousewheel)="$event.preventDefault()"
                                            (change)="applyFilter($event)"></label>
                                </div>
                                <div class="chart__content-canvas animate__animated animate__fadeIn" *ngIf="configDevicesByLinkingStatus.updated_chart">
                                    <canvas baseChart [data]="configDevicesByLinkingStatus.data " [options]="configDevicesByLinkingStatus.chart_options"
                                        [plugins]="configDevicesByLinkingStatus.chart_plugins" [type]="configDevicesByLinkingStatus.chart_type">
                                    </canvas>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </mat-tab>
              
                <mat-tab>
                  <ng-template mat-tab-label>
                    <mat-icon class="text-info">insert_chart</mat-icon>
                    Tipos de vehículos
                  </ng-template>
                  <div class="form-box__content-section form-box__content-section-shadow mt-2">

                    <div class="d-block">
                        <div class="chart" *ngIf="configVehicleChartAccordingToType.updated_chart">
                            <div class="chart__content">
                                <div class="chart__content-header-title">{{configVehicleChartAccordingToType.title}}</div>
                                <div class="chart__content-canvas animate__animated animate__fadeIn" *ngIf="configVehicleChartAccordingToType.updated_chart">
                                    <canvas baseChart [data]="configVehicleChartAccordingToType.data" [options]="configVehicleChartAccordingToType.chart_options"
                                        [plugins]="configVehicleChartAccordingToType.chart_plugins" [type]="configVehicleChartAccordingToType.chart_type">
                                    </canvas>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </mat-tab>

                <mat-tab>
                    <ng-template mat-tab-label>
                      <mat-icon class="text-info">bar_chart</mat-icon>
                      Marca de dispositivos
                    </ng-template>
                    <div class="form-box__content-section form-box__content-section-shadow mt-2">

                        <div class="d-block">
                            <div class="chart" *ngIf="configDevicesGroupedByDeviceModel.updated_chart">
                                <div class="chart__content">
                                    <div class="chart__content-header-title">{{configDevicesGroupedByDeviceModel.title}}</div>
                                    <div class="chart__content-canvas animate__animated animate__fadeIn" *ngIf="configDevicesGroupedByDeviceModel.updated_chart">
                                        <canvas baseChart [data]="configDevicesGroupedByDeviceModel.data" [options]="configDevicesGroupedByDeviceModel.chart_options"
                                            [plugins]="configDevicesGroupedByDeviceModel.chart_plugins" [type]="configDevicesGroupedByDeviceModel.chart_type">
                                        </canvas>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                  </mat-tab>

                  <mat-tab>
                    <ng-template mat-tab-label>
                      <mat-icon class="text-info">pie_chart</mat-icon>
                      Operador móvil
                    </ng-template>
                    <div class="form-box__content-section form-box__content-section-shadow mt-2">
    
                        <div class="d-block">
                            <app-circular-chart [circularChartConfigData] ="configDevicesGroupedByTelephoneOperator" ></app-circular-chart>
                        </div>
                    </div>
                  </mat-tab>
                  <mat-tab>
                    <ng-template mat-tab-label>
                      <mat-icon class="text-info">bar_chart</mat-icon>
                      Planes de facturación
                    </ng-template>
                    <div class="form-box__content-section form-box__content-section-shadow mt-2">
  
                      <div class="d-block">
                          <div class="chart" *ngIf="configDeviceBillingPlanSummary.updated_chart">
                              <div class="chart__content">
                                  <div class="chart__content-header-title">{{configDeviceBillingPlanSummary.title}}</div>
                                  <div class="chart__content-header-subtitle">Muestra la cantidad de dispositivos agrupados según el precio indicado en el plan de facturación, sin considerar los períodos de facturación de cada plan.</div>
                                  <div class="chart__content-canvas animate__animated animate__fadeIn" *ngIf="configDeviceBillingPlanSummary.updated_chart">
                                      <canvas baseChart [data]="configDeviceBillingPlanSummary.data" [options]="configDeviceBillingPlanSummary.chart_options"
                                          [plugins]="configDeviceBillingPlanSummary.chart_plugins" [type]="configDeviceBillingPlanSummary.chart_type">
                                      </canvas>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  </mat-tab>
              </mat-tab-group>
        </div>
    </div>
    <div class="loader" *ngIf="loading">
        <img src="../../../../../assets/loader.svg">
    </div>
</section>