import { Component, OnInit,Renderer2 } from '@angular/core';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import { DataService } from 'src/app/core/data/data.service';
import { UtilsService } from 'src/app/core/utils/utils.service';
import { ApiService } from 'src/app/core/api/api.service';
import { Router } from '@angular/router';
import { IViewsAvailable } from 'src/app/shared/models/interfaces/views-available';
import { ValidationService } from '../../core/validation/validation.service';
import { IBasicUserData } from 'src/app/shared/models/interfaces/user-data.model';
import { ObjectInitializationService } from '../../core/object-initialization/object-initialization.service';
import { IUserSessionData } from 'src/app/shared/models/interfaces/iuser-session-data.model';

interface IUrlAsset{
  base:string,
  sign:string,
  logo:string,
}
@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss']
})
export class MyAccountComponent implements OnInit {
  user:IUserSessionData;

  /** Almacena la lista de vistas disponibles en el componente */
  viewsAvailable:IViewsAvailable={
    payment_option:{name:"payment_option",show_view:false},
    basic_data:{name:"basic_data",show_view:true},
    messaging_service_account_setting:{name:"messaging_service_account_setting",show_view:false}
  };
  availableTabs:IViewsAvailable ={
    multimedia:{name:"multimedia",show_view:true},
    business_contact_information:{name:"business_contact_information",show_view:false},
    payment_option:{name:"payment_option",show_view:false, index_in_tab: 2},
    messaging_service_account_setting:{name:"messaging_service_account_setting",show_view:false, index_in_tab: 3},
  }
  loading:boolean=false;
  newDistributor!:IBasicUserData;

  urlAssets:IUrlAsset={
    base:"https://sgc.internationalgpsonline.com/",
    logo:"logo/",
    sign:"sign/"
  }
  selectedFile:boolean=false;
  selectedFileUrl:string="";
  moduleId:number=9;
  permissionsDataSgc:any=[];//permisos sobre el modulo
  constructor(
    public dataService:DataService,
    public utils:UtilsService,
    private api:ApiService,
    private router:Router,
    private objectInitialization:ObjectInitializationService,
    public validationService:ValidationService,
    private renderer:Renderer2
  ){
    this.user = this.dataService.getData("user") as IUserSessionData;
    this.newDistributor = this.objectInitialization.initializeIBasicUserData();
  }
  ngOnInit(): void {
    this.dataService.checkPermissionModule(this.moduleId).then((permissions: any) => {
      this.getBasicUserData();
    }).catch(() => {
      this.utils.showMsg("Página no autorizada","No tiene permisos para ver esta página, contacte al administrador");
      this.router.navigate(['/']);
    });
  }
  showTabMultimedia(){
    this.utils.setSelectedView(this.viewsAvailable,this.viewsAvailable["basic_data"].name);
    this.utils.setSelectedView(this.availableTabs,this.availableTabs['multimedia'].name);
  }
  changeTab(event:any){
    let item:any = Object.values(this.availableTabs).find((item:any,index:number)=>index==parseInt(event.index));
    if(typeof item !="undefined")
      this.utils.setSelectedView(this.availableTabs,item.name);
    if (event.index == this.availableTabs['payment_option'].index_in_tab)
      this.utils.setSelectedView(this.viewsAvailable,this.viewsAvailable["payment_option"].name);  
    else if (event.index == this.availableTabs['messaging_service_account_setting'].index_in_tab)
      this.utils.setSelectedView(this.viewsAvailable,this.viewsAvailable["messaging_service_account_setting"].name);  
  }
  getBasicUserData(){
    this.api.getData("user","?action=getUserData&user_api_hash="+this.user.hash,true).then((data: any) => {
      this.newDistributor= (data.status==1?Object.assign(this.newDistributor,data.data):this.newDistributor);
    }).catch((error:any)=>{
      this.utils.hideLoading((response:boolean)=>this.loading=response);
      this.utils.showMsg("","No se pudo obtener los datos del usuario");
    });
  }
  //metodos para la manipulacion de los datos basicos a actualizar
  selectFile(file:any,type:string){
    return (typeof file =="undefined" || file=="" || file==null)?"sample.png":file;
  }
  showMessageAboutFile(fileName:string|File,name:string){
    return (typeof fileName !="undefined" && fileName !="")?("Cambiar "+name):("Subir "+name);
  }
  loadFileIntoImgElement($event:any,type:string,name:keyof IBasicUserData){
    if (typeof $event.target.files !="undefined" && $event.target.files[0]) { //Revisamos que el input tenga contenido
      let reader = new FileReader(); //Leemos el contenido
      this.newDistributor[name] = $event.target.files[0];
      let input = this.renderer.selectRootElement(`.${type}`,true);
      reader.readAsDataURL($event.target.files[0]);
      reader.onloadend=function(){
        input.src=reader.result;
      }
    }
  }
  updateBasicData(){
    this.loading = true;
    const formData:any = new FormData();
    let newDistributor:any = this.newDistributor;
    newDistributor["user_api_hash"]= this.user.hash;
    newDistributor["action"]="updateMyAccount";
    let error = this.validateBasicDataFields(this.newDistributor);
    if(error!=""){
      this.utils.hideLoading((response:boolean)=>this.loading=response);
      return this.utils.showResultRequest("error","",error);
    }
    this.utils.fillFormData(formData,this.newDistributor,true);

    this.api.createData(formData, "user",true).then((data: any) => {
      let filesmessage = "";        
      let filesUploadedWithError:any = typeof data.result_files_uploaded !="undefined"?data.result_files_uploaded:{};
      if(Object.values(filesUploadedWithError).length>0 && filesmessage!="")  
        filesmessage +="<br><hr><br>";
      Object.values(filesUploadedWithError).forEach((item:any)=>filesmessage += " "+(!item.status?(item.message+(this.validationService.isNullOrEmpty(item.data)?"":": "+item.data)+".<br> "):""));
            
      filesmessage = (filesmessage.trim()!=""?"<br><br><small class='text-start d-block'>"+filesmessage.trim()+"</small>":"");
      this.utils.showResultRequest(data.status && filesmessage==""?"success":"error","",data.status?(filesmessage==""?"Datos registrados":"Se presentó un error al subir los archivos"+filesmessage):("No se pudo registrar los cambios"+filesmessage));
      this.utils.hideLoading((response:boolean)=>this.loading=response);
    }).catch((err: any) => {
      this.utils.hideLoading((response:boolean)=>this.loading=response);
      this.utils.showResultRequest("error","",this.api.getDefaultMessage("datos basicos de usuario",true,false,"POST"));
    });
  }
  validateBasicDataFields(newData:IBasicUserData){
    let error="";
    newData.address.trim();
    newData.business_contact_information.whatsapp.number.trim();
    if(!this.validationService.isNullOrEmpty(newData.business_contact_information.whatsapp.country_code) && this.validationService.isNullOrEmpty(newData.business_contact_information.whatsapp.number)) return "Ha proporcionado un indicador télefonico, por favor indique el número de Whatsapp";
    if(!this.validationService.isNullOrEmpty(newData.business_contact_information.whatsapp.country_code) && !this.validationService.isPhoneValid(newData.business_contact_information.whatsapp.number)) return "El número de whatsapp debe ser solo números y tener al menos 9 dígitos";
    if(!this.validationService.isNullOrEmpty(newData.business_contact_information.whatsapp.number) && this.validationService.isNullOrEmpty(newData.business_contact_information.whatsapp.country_code)) return "Indique el prefijo telefónico para el número de Whatsapp";
    
    if(!this.validationService.isNullOrEmpty(newData.business_contact_information.commercial_contact_email) && !this.validationService.isValidEmail(newData.business_contact_information.commercial_contact_email)) return "El email de contacto comercial no tiene un formato válido.\n Email de ejemplo válido: ventas@dominio.com";
    if(!this.validationService.isNullOrEmpty(newData.business_contact_information.tracking_website_url) && !this.validationService.isWebAddressValid(newData.business_contact_information.tracking_website_url)) return "La dirección web de su sitio no es válida.\n Dirección de ejemplo válida: https://example.com";
    
    if(!this.validationService.isNullOrEmpty(newData.business_contact_information.social_media.facebook) && !this.validationService.isWebAddressValid(newData.business_contact_information.social_media.facebook)) return "La dirección de página de Facebook no es válida.\n Dirección de ejemplo válida: https://facebook.com/mypage/";
    if(!this.validationService.isNullOrEmpty(newData.business_contact_information.social_media.twitter) && !this.validationService.isWebAddressValid(newData.business_contact_information.social_media.twitter)) return "La dirección de página de twitter no es válida.\n Dirección de ejemplo válida: https://twitter.com/myAccount/";
    if(!this.validationService.isNullOrEmpty(newData.business_contact_information.social_media.instagram) && !this.validationService.isWebAddressValid(newData.business_contact_information.social_media.instagram)) return "La dirección de página de instagram no es válida.\n Dirección de ejemplo válida: https://instagram.com/MyAccount/";
    if(!this.validationService.isNullOrEmpty(newData.business_contact_information.social_media.linkedin) && !this.validationService.isWebAddressValid(newData.business_contact_information.social_media.linkedin)) return "La dirección de cuenta de LinkedIn no es válida.\n Dirección de ejemplo válida: https://linkedin.com/company/myAccount/";
    if(!this.validationService.isNullOrEmpty(newData.business_contact_information.social_media.youtube) && !this.validationService.isWebAddressValid(newData.business_contact_information.social_media.youtube)) return "La dirección de página de Youtube no es válida.\n Dirección de ejemplo válida: https://youtube.com/@myChannel/";
    return error;
  }
}