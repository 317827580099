<mat-dialog-content class="mat-typography informative-content informative-content--text-small">
    <section class="informative-content__section m-0 p-0">
        <article class="ilustration ilustration--small animate__animated animate__bounceIn">
            <dotlottie-player autoplay count="1" mode="normal"
                src="../../../../../../assets/img/info.lottie"
            ></dotlottie-player>
        </article>
    </section>
    <section class="informative-content__section">
        <h2 class="informative-content__subtitle informative-content__subtitle-1 text-center"> Importante</h2>
        <p >Al registrar esta cuenta, se aplicará un cargo adicional a tu factura por el servicio de esta integración.
             Este cargo aparecerá en tu factura dentro de las próximas 48 horas.
              La primera factura reflejará un monto proporcional al tiempo restante hasta la 
              próxima fecha de corte de tu servicio. A partir de entonces, el costo del paquete se 
              incluirá automáticamente en tu factura mensual o anual.
        </p>
        <p>
            Disfruta de nuestro servicio de mensajería por solo <strong class="text-primary">{{PRICE_PER_MONTH.price | currency:PRICE_PER_MONTH.monetary_unit_code}}</strong> al mes  <i class="fa-solid fa-tags icon icon-xl text-primary"></i>
            <!--Revisa Nuestros Paquetes y Tarifas  <i class="fa-solid fa-tags icon icon-xl text-primary"></i>-->
        </p>
    </section>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="false" cdkFocusInitial class="me-1">Cancelar</button>
    <button mat-button  [mat-dialog-close]="true" cdkFocusInitial class="btn btn-primary">Continuar</button>
</mat-dialog-actions>
