import { Component, OnInit,Inject,ChangeDetectorRef } from '@angular/core';
import { MatDialog,MAT_DIALOG_DATA } from "@angular/material/dialog";
import {MatTableDataSource} from '@angular/material/table';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/core/api/api.service';
import { DataService } from 'src/app/core/data/data.service';
import { UtilsService } from 'src/app/core/utils/utils.service';
@Component({
  selector: 'app-device-details-by-year',
  templateUrl: './device-details-by-year.component.html',
  styleUrls: ['./device-details-by-year.component.scss']
})
export class DeviceDetailsByYearComponent implements OnInit{
  loading:boolean=true;
  deviceList:any=[];
  displayedColumnsForAInvoice: string[] = ['imei','plate_number','name',"created_at","activation_date","expiration_date","observation"];
  user: any = this.dataService.user;
  loadingExport:boolean=false;

  constructor( 
    private changeDetector: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public utils:UtilsService,    
    private api: ApiService,
    public dataService: DataService){
      this.user = this.dataService.getData("user");
      this.deviceList=this.data;
    }
  ngOnInit(): void {
    this.loading=false;
  }
  exportFile(){
    this.utils.showConfirm("¿Está seguro de exportar los datos?", "Los datos pueden tomar un tiempo en procesarse", "Continuar", "Cancelar").then(() => {
      this.loadingExport = true;
      setTimeout(() => {
        this.utils.downloadFileAsCSV(this.deviceList,  "export_" + new Date().getTime().toString(), this.displayedColumnsForAInvoice);
        this.loadingExport = false;
        this.utils.showMsg("","Datos exportados con éxito");
      }, 2500);
    });
  }
  showLoading(show: boolean = true):void{
    if(show) this.loading = true;
    else setTimeout(() => { this.loading = false; }, 1000);
  }
}