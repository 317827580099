<section class="form-box-container animate__animated animate__fadeIn" *ngIf="viewsAvailable['phone_line_registration_form'].show_view">
    <section class="form-box-container__header">
        <span class="form-box-container__header-back" (click)="closeComponent()"><span class="description">Volver</span> </span>
        <h2 class="form-box-container__header-title"><span class="icon"><i class="fa-brands fa-whatsapp text-primary"></i></span>Cuentas de WhatsApp</h2>
        <div  class="form-box-container__header-options">      
            <button class="btn btn-primary" matTooltip="Ver la lista de cuentas registradas" (click)="openPhoneLinesListView()"><i class="fa-brands fa-whatsapp"></i><span class="description"> Cuentas</span></button>                
        </div>
    </section>
    <div class="form-box  transparent-scroll-vertical two-panel two-panel--automatic-orientation animate__animated animate__fadeIn">
        <div class="form-box__content transparent-scroll-vertical">
            <section class="count-panel-container count-panel-container--automatic-orientation">
                <article class="count-panel-item count-panel-item--sm animate__animated animate__fadeInLeft {{item.class_css_animate_delay + ' ' + item.class_css }}" *ngFor="let item of accountSummaryCards">
                    <span class="count-panel-item-content">
                        <span class="count-panel-item-content__title">{{item.name}} </span>
                        <strong class="count-panel-item-content__quantity count-panel-item-content__quantity--small">{{item.total}}</strong>
                    </span>
                    <span class="count-panel-item-icon"><i class="{{item.icon}}"></i></span>
                </article>
            </section>
            <div class="form-box__content-inputs" *ngIf="accountSummaryCardsByActivationstatus.pending.total < messagingServiceApiPhoneLineService.PENDING_ACTIVATION_PHONE_LINE_LIMIT ">
                    <div class="form-box__content-group-fields">
                        <div class="form-box__content-input-with-button">
                            <mat-form-field appearance="fill" class="fill" hintLabel="Max {{messagingServiceApiPhoneLineService.MAX_FIELD_LENGTH.name}} carácteres">
                                <mat-label>Nombre de la cuenta</mat-label>
                                <input matInput name="messaging_service_phone_line_name" type="text" [maxlength]="messagingServiceApiPhoneLineService.MAX_FIELD_LENGTH.name" placeholder="Asigna un nombre para la linea móvil" [(ngModel)]="userMessagingServicePhoneLine.name">
                                <mat-hint align="end">{{userMessagingServicePhoneLine.name.length}}/{{messagingServiceApiPhoneLineService.MAX_FIELD_LENGTH.name}}</mat-hint>
                            </mat-form-field>
                            <button class="btn btn-primary" (click)="addMobileLine()" [disabled]="userMessagingServicePhoneLine.name.length < 1">Crear</button>
                          </div>
                    </div>
            </div>
            <article class="d-block alert alert-light-warning" *ngIf="accountSummaryCardsByActivationstatus.pending.total >= messagingServiceApiPhoneLineService.PENDING_ACTIVATION_PHONE_LINE_LIMIT">
                <p class="text-small m-0"> 
                    <i class="fa-solid fa-circle-exclamation alert-icon"></i> Tienes {{accountSummaryCardsByActivationstatus.pending.total}} solicitudes de registros de líneas <strong>pendientes</strong>. Una vez sean resueltas dichas solicitudes podrás seguir solicitando más
                  </p>
            </article>
        </div>  
        <div class="form-box__content transparent-scroll-vertical form-box__content-shadow">
            <div class="form-box__content-header form-box__content-header-horizontal form-box__content-header-align-center">
                <strong class="form-box__content-title"><i class="fa-solid fa-circle-exclamation icon icon-lg text-warning "></i> Información importante</strong>
            </div>
            <section class="form-box__content-section">
                <mat-list role="list" class="list">
                    <mat-list-item role="listitem" class="list__item fs-small"><i class="fa-solid fa-calendar-week icon pe-1"></i>Debe de conectar una linea que ya tenga un historial de uso. No use una cuenta totalmente nueva</mat-list-item>
                    <mat-list-item role="listitem" class="list__item fs-small"><i class="fa-solid fa-ban icon pe-1"></i>No debes enviar mensajes No deseados</mat-list-item>
                    <mat-list-item role="listitem" class="list__item fs-small"><i class="fa-solid fa-address-book icon pe-1"></i>Guarda a tus clientes en la lista contactos, y sugiéreles a tus clientes que te agreguen a la lista de contactos</mat-list-item>
                    <mat-divider></mat-divider>
                    <mat-list-item role="listitem" class="fw-bold mt-1"><span class="pe-1">Detalles de la Integración</span>  
                        <mat-icon aria-hidden="false"matRipple class="text-info circle cursor-pointer" aria-label="Información adicional" fontIcon="feed" (click)="openIntegrationDetails()"></mat-icon>
                    </mat-list-item>
                </mat-list>

            </section>
        </div>  
    </div>
    
    <div class="loader" *ngIf="loading">
        <img src="../../../../assets/loader.svg">
    </div>
  </section>

  <app-messaging-service-phone-line-list 
  *ngIf="viewsAvailable['phone_line_list'].show_view" 
  (close)="utils.setSelectedView(viewsAvailable,viewsAvailable['phone_line_registration_form'].name)"></app-messaging-service-phone-line-list>