import {SelectionModel} from '@angular/cdk/collections';
import {Sort} from '@angular/material/sort';
import { Component,Input,Output,OnInit,EventEmitter,Renderer2 } from '@angular/core';
import { DataService } from 'src/app/core/data/data.service';
import { UtilsService } from 'src/app/core/utils/utils.service';
import { ApiService } from 'src/app/core/api/api.service';
import { IDeviceGroupAssociatedWithWebService, IWebService } from "../../models/interfaces/iweb-service.model";
import { PageEvent } from '@angular/material/paginator';
import { IViewsAvailable } from 'src/app/shared/models/interfaces/views-available';
import { IUserSessionData } from 'src/app/shared/models/interfaces/iuser-session-data.model';
import { ValidationService } from '../../../../core/validation/validation.service';
import { RequestMethodHTTP } from 'src/app/shared/enums/common-enums.enum';
import { IApiRequestData, IPagination, ISort } from 'src/app/shared/models/interfaces/iapi-request-data.model';
import { ObjectInitializationService } from '../../../../core/object-initialization/object-initialization.service';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { IWebserviceDevice, SUBSCRIPTION_STATUSES } from '../../integrations.component';
import { Router } from '@angular/router';
import { ApiEndpointSgc } from 'src/app/shared/enums/api-endpoint-sgc.enum';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MessageBoxService } from 'src/app/core/message-box/message-box.service';

///ANALIZAR LOS  COMPONENTES DE web-services-list, ya que entonces si no van a ver muchas variaciones podria entonces crearse un componente comun y usarlo para cargar los datos en relacion al web service a visualizar
@Component({
  selector: 'app-atu-web-service',
  templateUrl: './atu-web-service.component.html',
  styleUrls: ['./atu-web-service.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class AtuWebServiceComponent implements OnInit{

  @Input() data!:IWebService;
  @Input() webServiceId!:number;
  @Output() closeModal = new EventEmitter<boolean>();
  viewsAvailable:IViewsAvailable={
    logs:{name:"logs",show_view:false},
    device_list:{name:"device_list",show_view:true}
  };
  SUBSCRIPTION_STATUSES = SUBSCRIPTION_STATUSES;
  /** @type {Object} Lista de opciones de filtro en cuanto a si mostrar dispositivos suscritos(1) y/o no suscritos(0) */
  deviceSubscriptionStatus:{subscribed: number, not_subscribed: number } = {
    subscribed:1,
    not_subscribed:0
  };
  subscriptionStatusFilter:number[] = [this.deviceSubscriptionStatus.subscribed,this.deviceSubscriptionStatus.not_subscribed];

  displayedColumns: string[] = ['select','activated', 'name', 'imei', 'plate_number', 'device_model','expiration_date'];
  selection = new SelectionModel<any>(true, []);
  /**Datos del usuario logueado */
  user:IUserSessionData;
  /**indica los datos para el ordenamiento de la tabla */
  sort: ISort;
  /**la lista de dispositivos a mostrar en la tabla */
  deviceList:IWebserviceDevice[] = [];
  /**el valor a buscar dentro de la tabla de dispositivos */
  searchDevice = "";
  devices:{activate:IWebserviceDevice[],deactivate:IWebserviceDevice[]}={
    activate:[],
    deactivate:[]
  };

  loading: boolean = false;
  searchTimer!: NodeJS.Timeout;
  pagination: IPagination

  //elementos para gestion de la tabla cuando los datos son agrupados
  paginationDeviceGroup: IPagination
  deviceGroupList: IDeviceGroupAssociatedWithWebService[] = [];
  displayedColumnsInDeviceGroupList: string[] = ['select','activated', 'title','actions'];

  selectedDeviceGroupId: number = 0;
  selectedDeviceGroup: IDeviceGroupAssociatedWithWebService | null = null;
  deviceGroupTableSort: ISort;

  //fin elementos para gestion de la tabla cuando los datos son agrupados
  canViewGroupedDevices: boolean = false;
  moduleId:number = 24;
  permissionsDataSgc:any=[];//permisos sobre el modulo
  constructor(
    public dataService:DataService,
    public utils:UtilsService,
    private api:ApiService,
    private validationService: ValidationService,
    public messageBox: MessageBoxService,
    private objectInitializationService: ObjectInitializationService,
    private router:Router
  ){
    dataService.checkPermission(dataService.MODULE_OPERATIONS_SGC.VIEW_GROUPED_DEVICES, this.permissionsDataSgc.permissions);
    this.sort = this.objectInitializationService.initializeISort();
    this.pagination = this.objectInitializationService.initializeIPagination(this.dataService.PAGE_SIZE_OPTION);
    this.paginationDeviceGroup = this.objectInitializationService.initializeIPagination(this.dataService.PAGE_SIZE_OPTION);
    this.paginationDeviceGroup.page_size = this.pagination.page_size = this.pagination.page_size_options[0];
    this.user= this.dataService.getData("user");

    this.deviceGroupTableSort = this.objectInitializationService.initializeISort();
  }
  ngOnInit(): void {

    this.dataService.checkPermissionModule(this.moduleId).then((permissions: any) => {
      this.permissionsDataSgc = permissions;
      this.canViewGroupedDevices = this.dataService.checkPermission(this.dataService.MODULE_OPERATIONS_SGC.VIEW_GROUPED_DEVICES, this.permissionsDataSgc.permissions);
      if(!this.dataService.checkPermission(this.dataService.MODULE_OPERATIONS_SGC.VIEW_GROUPED_DEVICES, this.permissionsDataSgc.permissions))
        this.getDeviceList();
      else
        this.getDeviceGroupList();
    }).catch(() => {
      this.utils.showMsg("Página no autorizada","No tiene permisos para ver esta página, contacte al administrador");
      this.router.navigate(['/']);
    });
  }
  isDeviceSubscriptionStatus(status:number){
    return this.subscriptionStatusFilter.some((item:any)=>item==status);
  }
  changeDeviceSubscriptionFilter(event:any){
    this.pagination.current_page = 0;
    (event.checked)?this.subscriptionStatusFilter.push(parseInt(event.source.value)):this.subscriptionStatusFilter.splice(this.subscriptionStatusFilter.findIndex((item:number)=>item==parseInt(event.source.value)),1);
    this.getDeviceList(this.selectedDeviceGroupId, true, true);
    if(this.canViewGroupedDevices)
        this.getDeviceGroupList();
  }
  getDeviceList(deviceGroupId?: number, isGet: boolean = true, skipCurrentGroupValidation:boolean = false ):void{
    if(typeof isGet =="undefined" || !isGet)
      return;
    let data = this.api.getCommonQueryParameters("getWebServiceDevices",this.user.hash,this.searchDevice, this.canViewGroupedDevices ? undefined : this.pagination.current_page,this.canViewGroupedDevices ? undefined : this.pagination.page_size,this.sort.by??undefined,this.sort.direction??undefined);
    data +="&web_service_id="+this.webServiceId;
    if(typeof deviceGroupId != "undefined" && deviceGroupId > 0){
      if(this.selectedDeviceGroupId == deviceGroupId && !skipCurrentGroupValidation)
        return;
      data +="&device_group_id="+deviceGroupId;
      this.selectedDeviceGroupId = deviceGroupId;
    }

    for(let item of this.subscriptionStatusFilter){
      data += "&activated_field_values[]=" + item;
    }
    this.loading = true;
    this.deviceList=[];
    this.devices.activate =[];
    this.devices.deactivate=[];
    this.api.getData("device",data,true).then((data: unknown) => {

    if(this.validationService.isResponseApi(data)){
      if(data.status == 1){
        for(let device of data.data){
          Object.entries(device).length > 0 && Number(device.activated) ? this.devices.activate.push(device) : this.devices.deactivate.push(device);
        }
        this.pagination.length_page =data.total ?? 0;
      }
      this.deviceList = data.status?data.data:[];
      this.selection = new SelectionModel<any>(true, []);
    }
    }).catch((error:unknown)=>{
      this.utils.showResultRequest("error", "", this.api.getDefaultMessage("la lista de dispositivos", false, false, RequestMethodHTTP.GET));
    }).finally(()=>this.utils.hideLoading(()=>this.loading = false));
  }

  //inicio grupos
  getDeviceGroupList():void{
    this.loading = true;
    this.deviceGroupList = [];
    this.pagination.length_page = 0;

    let data = this.api.getCommonQueryParameters("getAssociatedWithWebservice",this.user.hash,undefined,this.pagination.current_page,this.pagination.page_size,this.deviceGroupTableSort.by??undefined,this.deviceGroupTableSort.direction??undefined);
    data +="&web_service_id="+this.webServiceId;
    for(let item of this.subscriptionStatusFilter){
      data += "&activated_field_values[]=" + item;
    }
    this.api.getData(ApiEndpointSgc.DEVICE_GROUP_INTGPS,data,true).then((data: unknown) => {
    if(this.validationService.isResponseApi(data)){
      if(data.status == 1){
        this.pagination.length_page =data.total ?? 0;
        this.deviceGroupList = data.data ?? [];
      }
    }
    }).catch((error:unknown)=>{
      this.utils.showResultRequest("error", "", this.api.getDefaultMessage("la lista de grupos de dispositivos", false, false, RequestMethodHTTP.GET));
    }).finally(()=>this.utils.hideLoading(()=>this.loading = false));
  }
  updateDeviceGroupStatus(event: MatSlideToggleChange, deviceGroup: IDeviceGroupAssociatedWithWebService){
    let dataToSend: IApiRequestData & Pick<IDeviceGroupAssociatedWithWebService, "activated" | "web_service_id" | "device_group_id"> = {
      action: deviceGroup.web_service_id  == null ?  "add" : "update",
      user_api_hash: this.user.hash,
      device_group_id: deviceGroup.id,
      activated:event.checked ? 1 : 0,
      web_service_id: this.webServiceId
    };
    let success: boolean = false;
    this.api.createData(dataToSend, ApiEndpointSgc.DEVICE_GROUP_WEB_SERVICE ,true).then((response: unknown) => {
      if(this.validationService.isResponseApi(response)){
        this.messageBox.openSnackBar( response.status == 1 ? "Estado actualizado" : "No se pudo cambiar el estado","Aceptar");
        if(response.status == 1)
          success = true;
      }
    }).catch((error: unknown) => {
      this.utils.showResultRequest("error","Información",this.api.getDefaultMessage("el estado",true, false,RequestMethodHTTP.POST));
    }).finally(()=>{
      if(success)
        deviceGroup.web_service_id = dataToSend.web_service_id;
      deviceGroup.activated = success ? dataToSend.activated : ( dataToSend.activated == SUBSCRIPTION_STATUSES.SUBSCRIBED ? SUBSCRIPTION_STATUSES.NOT_SUBSCRIBED : SUBSCRIPTION_STATUSES.SUBSCRIBED  );
      event.source.checked = !!dataToSend.activated;
      this.utils.hideLoading(()=>this.loading = false);
    });
  }
  //fin grupos

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.devices.activate.length;
    const numRows = this.deviceList.length;
    return numSelected >0 && numSelected === numRows;
  }
  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.devices.activate=[];
      this.devices.deactivate=this.utils.copyObject(this.deviceList);
      this.selection.clear();
      return;
    }
    this.devices.deactivate=[];
    this.devices.activate=this.utils.copyObject(this.deviceList);
    this.selection.select(...this.deviceList);
  }
  changeDeviceSelected(event:MatSlideToggleChange,device:any){
    if(!this.utils.isNullOrEmpty(device))
      (event.checked)?this.toggleSelection("activate","deactivate",device):this.toggleSelection("deactivate","activate",device);
  }
  toggleSelection(property:"activate"|"deactivate",antonymousProperty:"activate"|"deactivate",device:any){
    let propertyIndex = this.devices[property].findIndex((item:any)=>item.id==device.id); 
    let antonymousPropertyIndex = this.devices[antonymousProperty].findIndex((item:any)=>item.id==device.id); 
      if(propertyIndex ==-1)
        this.devices[property].push(device);
      if(antonymousPropertyIndex !=-1)
          this.devices[antonymousProperty].splice(antonymousPropertyIndex,1);
  }
  checkboxLabel(row?: any): string {
    if (!row)
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }
  isSelectedDevice(row:IWebserviceDevice){
    return (this.devices.activate.some((item:any)=>item.id==row.id));
  }
  paginator($event: PageEvent){
    this.pagination.page_size = $event.pageSize;
    this.pagination.current_page = $event.pageIndex;    
    this.getDeviceList(this.selectedDeviceGroupId, true,true);
  }
  sortData(sort: Sort) {
    this.pagination.current_page = 0;
    this.sort.direction=sort.direction;
    this.sort.by=sort.active;
    this.getDeviceList(this.selectedDeviceGroupId, true,true);
  }
  sortDataDeviceGroup(sort: Sort) {
    this.pagination.current_page = 0;
    this.deviceGroupTableSort.direction=sort.direction;
    this.deviceGroupTableSort.by=sort.active;
    this.getDeviceGroupList();
  }
  /** @description Actualiza la lista de dispositivos a reportar al web service */
  updateDevicesSelect(){
    let deviceIdList:{activate:IWebserviceDevice[],deactivate:IWebserviceDevice[]} = {activate:[],deactivate:[]};

    this.devices.activate.forEach((item:any)=>deviceIdList.activate.push(item.id));
    this.devices.deactivate.forEach((item:any)=>deviceIdList.deactivate.push(item.id));
    this.loading = true;
    this.utils.showConfirm("Confirmar cambios", "Confirma la actualización de dispositivos a reportar a ATU", "Confirmar", "Cancelar").then(() => {
      this.api.createData({user_api_hash:this.user.hash,device_id_list:deviceIdList,action:"update",web_service_id:this.webServiceId}, "deviceWebService",true).then((data: any) => {
        this.utils.showResultRequest(data.status==1?"success":"error","Información",data.message);
        this.getDeviceList(this.selectedDeviceGroupId, true,true);
      }).catch((err: any) => {
        this.utils.showResultRequest("error", "Información", this.api.getDefaultMessage("los registros", true, false, RequestMethodHTTP.POST));
      }).finally(()=>this.utils.hideLoading(()=>this.loading = false));
    }).catch(()=>{this.utils.hideLoading(()=>this.loading = false);this.getDeviceList(this.selectedDeviceGroupId, true,true);});
  }
  searchValueInData(){
    clearTimeout(this.searchTimer);
    this.searchTimer = setTimeout(() => {
      this.pagination.current_page = 0;
      this.getDeviceList(this.selectedDeviceGroupId, true,true);
    }, this.dataService.DATA_TABLE_SEARCH_TIMEOUT);
  }
  close() {
    this.closeModal.emit(true);
  }
}