import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/core/api/api.service';
import { DataService } from 'src/app/core/data/data.service';
import { ObjectInitializationService } from 'src/app/core/object-initialization/object-initialization.service';
import { UtilsService } from 'src/app/core/utils/utils.service';
import { ValidationService } from 'src/app/core/validation/validation.service';
import { IDeviceBasicData } from 'src/app/shared/models/interfaces/idevice.model';
import { IUserPlanModule, IUserSessionData } from 'src/app/shared/models/interfaces/iuser-session-data.model';
import { UserDriverDataService } from '../../core/user-driver-data/user-driver-data.service';
import { IDeviceChecklist } from 'src/app/shared/models/interfaces/idevice-checklist.model';
import { IApiRequestData } from 'src/app/shared/models/interfaces/iapi-request-data.model';
import { ROUTES_TO_LOGIN } from 'src/app/core/auth/auth.service';
import { IViewsAvailable } from 'src/app/shared/models/interfaces/views-available';
import { DeviceChecklistAnsweredAvailableFilterFields } from 'src/app/shared/components/device-checklist-answered-history/device-checklist-answered.enum';
import { RouteConstant } from 'src/app/core/constant/RouteConstant';

@Component({
  selector: 'app-driver-device-checklist-management',
  templateUrl: './driver-device-checklist-management.component.html',
  styleUrls: ['./driver-device-checklist-management.component.scss']
})
export class DriverDeviceChecklistManagementComponent implements OnInit {

  user!:IUserSessionData;
  loading:boolean = false;

  viewsAvailable:IViewsAvailable = {
    device_checklist_management:{name:"device_checklist_management",show_view:true},
    device_checklist_answered_history:{name:"device_checklist_answered_history",show_view:false}
  };

  /** @type {DeviceChecklistAnsweredAvailableFilterFields} Indica el campo por el cual se va a filtrar en el historial de busqueda */
  searchHistoryByUserDriver: DeviceChecklistAnsweredAvailableFilterFields = DeviceChecklistAnsweredAvailableFilterFields.USER_DRIVER_ID;
  currentDrivenVehicle!:IDeviceBasicData;

  checklistList:IDeviceChecklist[] = [];
  /** @description El checklist seleccionado para editar*/
  checklistListSelected!:IDeviceChecklist;
  
  moduleId:number = 18;
  /**
   * @type {IUserPlanModule} Permisos sobre el módulo
   */
  permissionsDataSgc!: IUserPlanModule;

  constructor(
    public utils: UtilsService,
    private dataService: DataService,
    private api: ApiService,
    public validationService: ValidationService,
    private router: Router,
    private objectInitializationService: ObjectInitializationService,
    private userDriverDataService:UserDriverDataService
  ){
    this.user = this.dataService.getData("user");
    this.permissionsDataSgc = this.objectInitializationService.initializeIUserPlanModule();
    this.currentDrivenVehicle = this.objectInitializationService.initializeIDeviceBasicData();
  }
  ngOnInit(): void {   

    this.dataService.checkPermissionModule(this.moduleId).then((permissions: IUserPlanModule) => {
      this.permissionsDataSgc = permissions;
      this.getDeviceChecklistList();
    }).catch(() => {
      this.utils.showMsg("Página no autorizada","No tiene permisos para ver esta página, contacte al administrador");
      this.router.navigate([ROUTES_TO_LOGIN.user_driver]);
    });
  }

  /**
   * @description da acceso al checklist para empezar a diligenciarlo
   * @param {IDeviceChecklist} checklist El checklist a diligenciar
   */
  openChecklist(checklistId:number){
    // para agregar parametros opcionales colocar un objeto con {answered:"test"}
    this.router.navigate([RouteConstant.DEVICE_CHECKLIST_VIEWER.url.split(":")[0]+checklistId]);
  }

  /** @description Obtiene la lista de checklist disponibles para el conductor */
  getDeviceChecklistList(){
    this.loading = true;
    let data: IApiRequestData = { user_api_hash:this.user.hash, action:"get"};
    this.api.getDataPost("deviceChecklist",data,true).then((response:unknown)=>{
      if(this.validationService.isResponseApi(response))
        this.checklistList = response.status == 1 ? response.data as IDeviceChecklist[] : []; 
      this.utils.hideLoading(() => this.loading = false);
    }).catch((error:any)=>{
      this.checklistList = [];
    });
  }
}