<h2 mat-dialog-title>Encargados</h2>
<mat-dialog-content class="mat-typography position-relative ">

<section class="form-box-container animate__animated animate__fadeIn"  *ngIf="employeeModal">

    <div class="form-box animate__animated animate__fadeIn">
  
        <div class="form-box__content form-box__content-h-auto">
          <div class="form-box__content-header form-box__content-header-horizontal p-0 form-box__content-header-align-end">
            <h3 class="col p-1 border-bottom-secondary strong" *ngIf="!isUpdateEmployee">Nuevo Encargado</h3>
            <h3 class="col p-1 border-bottom-secondary strong" *ngIf="isUpdateEmployee" >{{newEmployee.name}}</h3>
            <div class="form-box-container-close animate__animated animate__fadeInDown" role="button"(click)="closeEmployeeModal()" [matTooltip]="'Cerrar vista de '+ (isUpdateEmployee?'actualización':'registro')"></div>
          </div>
          <div class="form-box__content-inputs">
            <div class="form-box__content-group-fields">
                
                <mat-form-field appearance="fill">
                    <mat-label>Nombre</mat-label>
                    <input matInput spellcheck="false" name="name" [(ngModel)]="newEmployee.name" type="text" id="inputName">
                </mat-form-field>
                <mat-form-field appearance="fill">
                    <mat-label>Email</mat-label>
                    <input matInput spellcheck="false" name="email" [(ngModel)]="newEmployee.email" type="email" id="inputEmail">
                </mat-form-field>

                <mat-form-field appearance="fill">
                    <mat-label>Teléfono</mat-label>
                    <input matInput spellcheck="false" name="phone" [(ngModel)]="newEmployee.phone" type="tel" id="inputPhone">
                </mat-form-field>
                
                <mat-form-field appearance="fill">
                    <mat-label>Prefijo Telefónico</mat-label>
                    <mat-select  name="phone_prefix"   [(ngModel)]="newEmployee.phone_prefix" id="inputPhonePrefix">
                    <mat-option *ngFor="let item of dataService.phone_prefix" [value]="item.code"  color="primary">{{item.code +" - "+item.country}}</mat-option>                      
                    </mat-select>
                </mat-form-field>
            </div>
          </div>
          <div class="form-box__content-actions">
              <button type="submit" *ngIf="isUpdateEmployee" class="btn btn-primary"(click)="updateEmployee()" >Actualizar</button>
              <button type="submit"  *ngIf="!isUpdateEmployee" class="btn btn-primary" (click)="createEmployee()">Registrar</button>
              <button class="btn btn-secondary"  (click)="closeEmployeeModal()">cancelar</button>
          </div>
        </div>
    </div>
    <div class="loader" *ngIf="false">
        <img src="../../../assets/loader.svg">
    </div>
</section>

<section class="form-box-container animate__animated animate__fadeIn"  *ngIf="!employeeModal">

    <div class="form-box animate__animated animate__fadeIn">
  
        <div class="form-box__content form-box__content-h-auto">
            <div class="form-box__content-header form-box__content-header-horizontal p-0 form-box__content-header-align-end">
                <button matToolTip="crear tarea" class="btn btn-primary" (click)="openCreateEmployeeModal()"><i class="fa-solid fa-user-plus"></i> <span class="description">Agregar</span></button>
                <button matToolTip="Exportar datos" class="btn btn-outline-secondary" (click)="exportFile()"><i class="fa-solid fa-file-export fa-sm"></i> <span  class="description">Exportar</span></button>
            </div>
            <div class="w100 overflow-auto">
                <table mat-table  [dataSource]="employeesList">
    
                    <ng-container matColumnDef="index">
                        <th mat-header-cell *matHeaderCellDef class="mat-header-cell-start"> Item  </th>
                        <td mat-cell *matCellDef="let employee;index as counter"> {{counter + 1}}</td>
                    </ng-container>
        
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef class="mat-header-cell-start"> Nombre  </th>
                        <td mat-cell *matCellDef="let employee"> {{employee.name}}</td>
                    </ng-container>
        
                    <ng-container matColumnDef="email">
                        <th mat-header-cell *matHeaderCellDef class="mat-header-cell-start"> Email  </th>
                        <td mat-cell *matCellDef="let employee"> {{employee.email}}</td>
                    </ng-container>
                    <ng-container matColumnDef="phone">
                        <th mat-header-cell *matHeaderCellDef class="mat-header-cell-start"> Teléfono </th>
                        <td mat-cell *matCellDef="let employee"> {{employee.phone}}</td>
                    </ng-container>
                    <ng-container matColumnDef="phone_prefix">
                        <th mat-header-cell *matHeaderCellDef class="mat-header-cell-start"> Prefijo telefónico </th>
                        <td mat-cell *matCellDef="let employee"> {{employee.phone_prefix}}</td>
                    </ng-container>
                        <ng-container matColumnDef="actions">
                            <th mat-header-cell *matHeaderCellDef > Acciones </th>
                            <td mat-cell *matCellDef="let employee">
                                <div class="actions-container">
                                    <button matTooltip="Editar usuario" (click)="openUpdateEmployeeModal(employee)"><i
                                        class="fa-solid fa-pen-to-square"></i></button>
                                    <button matTooltip="Eliminar usuario" (click)="deleteEmployee(employee.id)"><i
                                        class="fa-solid fa-trash-can"></i></button>            
                                </div>
                            </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        <tr class="mat-row" *matNoDataRow>
                            <td class="mat-cell empty py-1" [attr.colspan]="displayedColumns.length">Sin datos</td>
                        </tr>
                    </table>
            </div>
        </div>
    </div>
    <div class="loader" *ngIf="false">
        <img src="../../../assets/loader.svg">
    </div>
</section>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]="false" class="btn btn-outline-secondary" style="margin-right: 0.2rem;">Salir</button>
  <!--<button mat-button [mat-dialog-close]="true" class="btn btn-primary" cdkFocusInitial>Exportar</button>-->
</mat-dialog-actions>