<h2 mat-dialog-title>Lista de dispositivos {{title}}</h2>
<mat-dialog-content class="mat-typography position-relative ">

  <div class="row overflow-auto position-relative table-container device-list">
    <div class="col">
        <table class="w-100 h-100">
            <thead class="thead position-sticky z-index-1 top-0 bg-white ">
                <tr class="tr">
                    <th class="th">N°</th>
                    <th class="th">Imei</th>
                    <th class="th">Nombre</th>
                    <th class="th">Placa</th>
                    <th class="th">Última conexión</th>
                    <th class="th">Fecha de expiración</th>
                </tr>
            </thead>
            <tbody>
                <tr class="tr" *ngFor="let device of userDevices;index as counter">
                    <td class="td text-start">{{counter+1}}</td>
                    <td class="td text-start">{{device.imei}}</td>
                    <td class="td text-start">{{device.name}}</td>
                    <td class="td text-start">{{device.plate_number}}</td>
                    <td class="td text-start">{{device.last_connection}}</td>
                    <td class="td text-start">{{device.expiration_date | date:"medium" }}</td>
                </tr>
            </tbody>
            
        </table>
    </div>
</div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]="false" class="btn btn-outline-secondary" style="margin-right: 0.2rem;;">Cerrar</button>
  <button mat-button [mat-dialog-close]="true" class="btn btn-primary" cdkFocusInitial>Exportar</button>
</mat-dialog-actions>