<button mat-icon-button [matMenuTriggerFor]="informationMenu">
    <!--
        #menuTrigger ="matMenuTrigger"
        (focus)="showMenu(menuTrigger);$event.stopPropagation()" 
        (blur)="hideMenu(menuTrigger);$event.stopPropagation()"
    -->
    <mat-icon *ngIf="icon.icon_type=='material_icon'" aria-hidden="false" class="popover-btn-icon {{icon.classes}}" aria-label="Información adicional" fontIcon="{{icon.icon}}"></mat-icon>
    <i *ngIf="icon.icon_type=='font_awesome'" aria-hidden="false" class="popover-btn-icon  {{icon.icon+ ' '+ icon.classes}}" aria-label="Información adicional" ></i>
   
</button> 
<mat-menu #informationMenu="matMenu" class="detail-popover">
    <span class="detail-popover__title" *ngIf="title !=null">
        <h2 class="detail-popover__title-value">{{title.value}}</h2>
        <small class="detail-popover__title-label">{{title.label}}</small>
    </span>
    <span mat-menu-item [disableRipple]="true" (click)="$event.stopPropagation()" class="detail-popover-content">
        <mat-list class="list detail-section">
            <ng-container *ngFor="let item of dataList">
                <mat-list-item  class="item"><span class="item__label">{{item.label}}</span><span class="item__value">{{item.value}}</span> </mat-list-item>
                <mat-divider></mat-divider>
            </ng-container>
        </mat-list>
    </span>
</mat-menu>