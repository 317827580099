<section class="form-box-container animate__animated animate__fadeIn"  *ngIf="!modal && !userNotesModal" >
    <section class="form-box-container__header">
        <h2 class="form-box-container__header-title"><span class="icon"><i class="fa-solid fa-list-check"></i></span>Administracion de distribuidores</h2>
        <div  class="form-box-container__header-options">                     
            <button matToolTip="crear distribuidor" class="btn btn-primary" (click)="openCreateUserModal()"><i class="fa-solid fa-user-plus"></i> <span class="description"> Agregar</span></button>
            <button matToolTip="Exportar datos" class="btn btn-outline-secondary" (click)="exportFile()"><i class="fa-solid fa-file-export"></i> <span  class="description"> Exportar</span></button>
            <!--<div class="" (click)="filterModal()" [ngClass]="{active: filter}"> <i class="fa-solid fa-filter"></i> Filtros</div>-->
            <div class="search">
                <input id="inputSearch" type="search" placeholder="Buscar" (input)="search()" [(ngModel)]="searchText">
                <label for="inputsearch"><i class="fa-solid fa-magnifying-glass fa-sm"></i></label>
            </div>
        </div>
    </section>
    <div class="form-box animate__animated animate__fadeIn">
      <div class="form-box__content form-box__content-h-auto">
        <div class="w100 overflow-auto">
          <table mat-table matSort (matSortChange)="sortData($event)" [dataSource]="distributorsToShowInTable">
              
            <ng-container matColumnDef="active">
                <th mat-header-cell *matHeaderCellDef  mat-sort-header sortActionDescription="Ordenar por estado de activación"> Activo </th>
                <td mat-cell *matCellDef="let distributor"> 
                    <mat-slide-toggle
                    [matTooltip]="toggleMessageActive(distributor.active)"
                        [checked]="isActive(distributor.active)"
                        color="primary"
                        (change)="statusChange($event,distributor)">
                    </mat-slide-toggle>   
                </td>
            </ng-container>
            <ng-container matColumnDef="email">
              <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Ordenar por email"> Email </th>
              <td mat-cell *matCellDef="let distributor"> {{distributor.email}} </td>
            </ng-container>
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef  mat-sort-header sortActionDescription="Ordenar por nombre"> Razón social </th>
              <td mat-cell *matCellDef="let distributor"> {{distributor.name}} </td>
            </ng-container>
            <ng-container matColumnDef="country" >
              <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Ordenar por país" > País </th>
              <td mat-cell *matCellDef="let distributor"> {{getCountryName(distributor.country_id)}} </td>
            </ng-container>
            <ng-container matColumnDef="document_number">
              <th mat-header-cell *matHeaderCellDef > Número de documento </th>
              <td mat-cell *matCellDef="let distributor"> {{distributor.document_number }} </td>
            </ng-container>
            <ng-container matColumnDef="contact_name">
              <th mat-header-cell *matHeaderCellDef  mat-sort-header sortActionDescription="Ordenar por nombre de contacto"> Nombre de contacto </th>
              <td mat-cell *matCellDef="let distributor"> {{distributor.contact_name}} </td>
            </ng-container>
            <ng-container matColumnDef="phone_number">
              <th mat-header-cell *matHeaderCellDef  mat-sort-header sortActionDescription="Ordenar por número de teléfono"> Teléfono </th>
              <td mat-cell *matCellDef="let distributor"> {{distributor.phone_number}} </td>
            </ng-container>
            <ng-container matColumnDef="complete_data">
              <th mat-header-cell *matHeaderCellDef  mat-sort-header sortActionDescription="Ordenar por estado de datos" disabled=""> Estado </th>
              <td mat-cell *matCellDef="let distributor" class="text-center"> 
                <div class="status complete" *ngIf="isDataDistributorComplete(distributor)" matTooltip="datos completos"><i class="fa-solid fa-circle-check"></i></div>
                <div class="status incomplete" *ngIf="!isDataDistributorComplete(distributor)" matTooltip="datos incompletos"><i class="fa-solid fa-circle-xmark"></i></div>
              </td>
            </ng-container>
            <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef mat-sort-header disabled class="mat-header-center"> Acciones </th>
              <td mat-cell *matCellDef="let distributor" class="text-center"> 
                <div class="actions-container">
                    <button matTooltip="Editar usuario" (click)="openUpdateDistributorModal(distributor)"><i class="fa-solid fa-pen-to-square"></i></button>
                    <button matTooltip="Eliminar usuario" (click)="deleteDistributor(distributor)"><i class="fa-solid fa-trash-can"></i></button>      
                </div>
              </td>
            </ng-container>
            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell empty py-1" [attr.colspan]="displayedColumns.length">Sin datos</td>
            </tr>
            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          
          </table>
        </div>
            <mat-paginator  [length]="lengthPage"
            [pageSize]="pageSize"
            [pageSizeOptions]="pageSizeOptions"
            (page)="paginator($event)"
            matTooltipPosition="right"
            aria-label="Selecciona página">
            </mat-paginator>
      </div>
    </div>
    <div class="loader" *ngIf="loading">
        <img src="../../../assets/loader.svg">
    </div>
  </section>


  <section class="form-box-container animate__animated animate__fadeIn"  *ngIf="modal && !userNotesModal">
    <section class="form-box-container__header">
        <span class="form-box-container__header-back" (click)="closeModal()"><span class="description">Volver</span> </span>
        <h2 class="form-box-container__header-title"><span class="icon"><i class="fa-solid fa-user"></i></span>{{isUpdate ? 'Actualizar distribuidor' : 'Nuevo distribuidor' }}</h2>
        <div  class="form-box-container__header-options">
            <button class="btn btn-primary " (click)="userNotesModal=true;formModal=false;permissionsListModal=false; " *ngIf="isUpdate" matTooltip="Permite registrar notas sobre el usuario"><i class="fa-solid fa-book"></i> <span class="description">Notas</span></button>
        </div>
    </section>
    <form (submit)="adminDistributor($event,isUpdate)" id="registrationForm" class="form-box animate__animated animate__fadeIn">
        <div class="form-box__content">
        
        <mat-tab-group>
            <mat-tab>
                <ng-template mat-tab-label>
                  <mat-icon class="text-info">description</mat-icon>
                  Principal
                </ng-template>
                <div class="form-box__content">
        
                    <mat-accordion multi>
                        <mat-expansion-panel [expanded]="true">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <mat-icon class="text-info">description</mat-icon> Datos
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <section class="form-box transparent-scroll-vertical two-panel two-panel--automatic-orientation animate__animated animate__fadeIn">
                                <div  class="form-box__content  form-box__content-shadow form-box__content-fill  transparent-scroll-vertical">
                                    <div class="form-box__content-group-fields">
                        
                                        <mat-form-field appearance="fill">
                                            <mat-label  matTooltip="Asigna el plan de acceso a la plataforma">Plan de acceso</mat-label>
                                            <mat-select  name="platform_plan_id" [(ngModel)]="newDistributor.platform_plan_id">
                                            <mat-option *ngFor="let plan of platformList" [value]="plan.id"  color="primary">{{plan.name}}</mat-option>                      
                                            </mat-select>
                                        </mat-form-field>
                            
                                        <mat-form-field appearance="fill">
                                            <mat-label>País</mat-label>
                                            <mat-select name="country_id"(selectionChange)="getIdentificationDocumentTypes();getCitiesList()" [(ngModel)]="newDistributor.country_id">
                                            <mat-option *ngFor="let item of countriesList" [value]="item.id"  color="primary">{{item.name}}</mat-option>                      
                                            </mat-select>
                                        </mat-form-field>
                                        <mat-form-field id="inputCity" appearance="fill">
                                            <mat-label>Ciudad</mat-label>
                                            <mat-select (closed)="clearSearchCity()" [(ngModel)]="newDistributor.city_id"name="city_id" placeholder="Buscar...">
                                                <mat-option>
                                                <ngx-mat-select-search [noEntriesFoundLabel]="'Sin resultados'" placeholderLabel="Buscar ciudad..." [(ngModel)]="cityToSearch" name="cityToSearch" (keyup)="searchCity()">
                                                    <mat-icon (click)="clearSearchCity()" ngxMatSelectSearchClear>close</mat-icon>
                                                </ngx-mat-select-search>
                                                </mat-option>
                                                <mat-option value="" *ngIf="newDistributor.country_id == null">-- {{newDistributor.country_id == null ? 'Seleccione primero un país' : 'Seleccione una opción' }} -- </mat-option>
                                                <mat-option *ngFor="let city of citiesListFilter" [value]="city.id" color="primary">
                                                    {{city.name}}                   
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                        <mat-form-field appearance="fill">
                                            <mat-label >Tipo de documento de identificación</mat-label>
                                            <mat-select  name="identification_document_type_id" (selectionChange)="showIdentificationDocumentType()" [(ngModel)]="newDistributor.identification_document_type_id">
                                            <mat-option value=""  color="primary">-- {{validationService.isNullOrEmpty(newDistributor.country_id)?'Seleccione primero un país':'Seleccione una opción' }} -- </mat-option>                      
                                            <mat-option *ngFor="let item of identificationDocumentTypeList" [value]="item.id"  color="primary">{{ item.name +(validationService.isNullOrEmpty(item.acronym)?"":(" - "+item.acronym)) }}</mat-option>                      
                                            </mat-select>
                                        </mat-form-field>
                                     
                                        <mat-form-field appearance="fill">
                                            <mat-label [matTooltip]="!validationService.isNullOrEmpty(newUserIdentificationDocumentType) && !validationService.isEmptyObject(newUserIdentificationDocumentType)?(newUserIdentificationDocumentType.name+' - '+newUserIdentificationDocumentType.acronym):''" >
                                                {{validationService.isNullOrEmpty(newUserIdentificationDocumentType) || validationService.isEmptyObject(newUserIdentificationDocumentType)?"Número documento":(newUserIdentificationDocumentType.name.length>40?newUserIdentificationDocumentType.acronym:newUserIdentificationDocumentType.name) }}
                                            </mat-label>
                                            <input matInput type="text" name="document_number" [value]="newDistributor.document_number||''">
                                        </mat-form-field>
                            
                                        <mat-form-field appearance="fill">
                                            <mat-label >Tipo de personalidad legal</mat-label>
                                            <mat-select  name="legal_personality_type"   [(ngModel)]="newDistributor.legal_personality_type">
                                            <mat-option value=""  color="primary">-- {{validationService.isNullOrEmpty(newDistributor.country_id)?'Seleccione primero un país':'Seleccione una opción' }} -- </mat-option>                      
                                            <mat-option *ngFor="let item of dataService.legal_personality_types_list" [value]="item.value"  color="primary">{{item.value}}</mat-option>                      
                                            </mat-select>
                                        </mat-form-field>
                                                 
                                        <mat-form-field appearance="fill">
                                            <mat-label [matTooltip]="!validationService.isNullOrEmpty(newUserIdentificationDocumentType) && !validationService.isEmptyObject(newUserIdentificationDocumentType)?(newUserIdentificationDocumentType.name+' - '+newUserIdentificationDocumentType.acronym):''" >
                                                {{newDistributor.legal_personality_type==dataService.legal_personality_types.legal_person || newDistributor.legal_personality_type==dataService.legal_personality_types.natural_person_trader?"Razón social":"Nombre"}}
                                            </mat-label>
                                            <input matInput type="text" name="business_name" [value]="newDistributor.name||''">
                                        </mat-form-field>
                            
                                        <mat-form-field appearance="fill" *ngIf="newDistributor.legal_personality_type==dataService.legal_personality_types.legal_person || newDistributor.legal_personality_type==dataService.legal_personality_types.natural_person_trader">
                                            <mat-label [matTooltip]="!validationService.isNullOrEmpty(newUserIdentificationDocumentType) && !validationService.isEmptyObject(newUserIdentificationDocumentType)?(newUserIdentificationDocumentType.name+' - '+newUserIdentificationDocumentType.acronym):''" >
                                                Nombre de contacto
                                            </mat-label>
                                            <input matInput type="text" name="contact_name" [(ngModel)]="newDistributor.contact_name">
                                        </mat-form-field>
                            
                                        <mat-form-field appearance="fill">
                                            <mat-label >Tratamiento</mat-label>
                                            <mat-select name="courtesy_title" [(ngModel)]="newDistributor.courtesy_title">
                                            <mat-option *ngFor="let item of dataService.courtesy_title" [value]="item.value"  color="primary">{{item.value}}</mat-option>                      
                                            </mat-select>
                                        </mat-form-field>
                            
                                        <mat-form-field appearance="fill" *ngIf="newDistributor.legal_personality_type==dataService.legal_personality_types.legal_person || newDistributor.legal_personality_type==dataService.legal_personality_types.natural_person_trader">
                                            <mat-label>Dirección</mat-label>
                                            <input matInput type="text" name="address" [value]="newDistributor.address||''">
                                        </mat-form-field>
                            
                                        <mat-form-field appearance="fill" >
                                            <mat-label matTooltip="Campo requerido">Email</mat-label>
                                            <input matInput type="email" name="email" [(ngModel)]="newDistributor.email" required="">
                                        </mat-form-field>
                            
                                        <mat-form-field appearance="fill">
                                            <mat-label >Prefijo Telefónico</mat-label>
                                            <mat-select name="phone_prefix" [(ngModel)]="newDistributor.phone_prefix">
                                            <mat-option *ngFor="let item of dataService.phone_prefix" [value]="item.code"  color="primary">{{item.code +" - "+item.country}}</mat-option>                      
                                            </mat-select>
                                        </mat-form-field>
                            
                                        <mat-form-field appearance="fill" >
                                            <mat-label >Teléfono</mat-label>
                                            <input matInput type="tel" name="phone_number" [value]="newDistributor.phone_number||''">
                                        </mat-form-field>
                            
                                        <mat-form-field appearance="fill" *ngIf="isUpdate">
                                            <mat-label>Fecha ingreso</mat-label>
                                            <input matInput [matDatepicker]="picker" disabled=""  name="join_date" [value]="utils.getDate(newDistributor.created_at)">
                                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                            <mat-datepicker #picker></mat-datepicker>
                                        </mat-form-field>
                            
                                        <mat-form-field appearance="fill" *ngIf="!isUpdate || (isUpdate && newDistributor.is_update_password)">
                                            <mat-label matTooltip="Campo requerido">Contraseña</mat-label>
                                            <input matInput autocomplete="off" required="" type="password" name="password"  [(ngModel)]="newDistributor.password">
                                        </mat-form-field>
                            
                                        <mat-form-field appearance="fill" *ngIf="!isUpdate || (isUpdate && newDistributor.is_update_password)">
                                            <mat-label matTooltip="Campo requerido">Confirmar Contraseña</mat-label>
                                            <input matInput type="password"  autocomplete="off"name="password_confirmation" [(ngModel)]="newDistributor.password_confirmation" required="">
                                        </mat-form-field>
                                        <div class="form-box__content-section">
                                            <div class="form-box__content-group-fields" *ngIf="isUpdate" >
                                                <mat-checkbox  color="primary" name="check_enable_password_update" [(ngModel)]="newDistributor.is_update_password">Actualizar Contraseña</mat-checkbox>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-box__content form-box__content-auto form-box__content-flex-basis-medium transparent-scroll-vertical  order-mobile-first">
                                    <div class="form-box__content-section">
                                        <div class="container-inputs-file container-inputs-file__column">
                                            <!-- las clases class="sign-img" class="sign-img" se estan usando para asignar los valores de imagenes a los img-->
                                            <div class="img-form img-form__bordered animate__animated animate__bounceIn"><span class="img-form__title">Logo</span>
                                                <img class="logo-img animate__animated animate__bounceIn" [matTooltip]="showMessageAboutFile(newDistributor.logo_url,'logo')" src="https://sgc.internationalgpsonline.com/logo/{{selectFile(newDistributor.logo_url,'logo')}}">
                                                <input form="registrationForm"(change)="loadFileIntoImgElement($event,'logo-img')"  class="form-control" accept="image/png,image/jpg,image/svg,image/jpeg" type="file" name="file_logo">
                                            </div>
                                            <div class="img-form img-form__bordered animate__animated animate__bounceIn"><span class="img-form__title">Fírma</span>
                                                <img class="sign-img animate__animated animate__bounceIn" [matTooltip]="showMessageAboutFile(newDistributor.sign_url,'firma')" src="https://sgc.internationalgpsonline.com/sign/{{selectFile(newDistributor.sign_url,'sign')}}">
                                                <input form="registrationForm"(change)="loadFileIntoImgElement($event,'sign-img')"  class="form-control" accept="image/png,image/jpg,image/svg,image/jpeg" type="file" name="file_sign">
                                            </div>
                                        </div>
                                    </div>
                                  </div>
                            </section>
                        </mat-expansion-panel>
                        <mat-expansion-panel>
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <mat-icon class="text-success">attach_money</mat-icon> Plan de facturación
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <div class="d-block">
                                <article class="form-box__content-section">
                                <div class="form-box__content-group-fields" >
                                    <mat-radio-group color="primary" class="fill" id="inputBillingPlanType" name="billing_plan_type" [(ngModel)]="this.billingPlanType" (change)="changeBillingPlanType($event)" aria-label="Seleccione uno de los tipos de planes de facturación">
                                        <mat-radio-button  [checked]="billingPlanType=='standardized'" value="standardized">Facturar según los planes estandarizados</mat-radio-button>
                                        <mat-radio-button  [checked]="billingPlanType=='personalized'" value="personalized">Facturar de forma personalizada</mat-radio-button>
                                    </mat-radio-group>
                                </div>
                                </article>
                                <div class="form-box__content-group-fields" >
                                    <ng-container *ngIf="billingPlanType=='personalized'">
                                        <mat-form-field appearance="fill" >
                                            <mat-label  >Precio por dispositivo</mat-label>
                                            <input matInput type="number" (mousewheel)="$event.preventDefault()" [(ngModel)]="newDistributor.personalized_plan.unit_price" [value]="newDistributor.personalized_plan.unit_price||''" name="personalized_plan[unit_price]" min="0" type="number" >
                                        </mat-form-field>
                            
                                        <mat-form-field appearance="fill">
                                            <mat-label>Tipo de moneda</mat-label>
                                            <mat-select [(ngModel)]="newDistributor.personalized_plan.monetary_unit_id" name="personalized_plan[monetary_unit_id]" [(ngModel)]="newDistributor.personalized_plan.monetary_unit_id">
                                            <mat-option *ngFor="let item of monetaryUnitsList" [value]="item.id"  color="primary">{{item.code+' - '+item.currency }}</mat-option>                      
                                            </mat-select>
                                        </mat-form-field>
                
                                        <mat-form-field appearance="fill">
                                            <mat-label>Frecuencia de pago</mat-label>
                                            <mat-select  name="personalized_plan[billing_frequency_id]" [(ngModel)]="newDistributor.personalized_plan.billing_frequency_id">
                                            <mat-option *ngFor="let item of billingFrequenciesList" [value]="item.id"  color="primary">{{item.name}} </mat-option>                      
                                            </mat-select>
                                        </mat-form-field>
                                        
                                        <mat-form-field appearance="fill" >
                                            <mat-label matTooltip="Indique el dia de cada mes el cual se realizará la facturacion" >Día de pago</mat-label>
                                            <input matInput name="personalized_plan[payday]" [(ngModel)]="newDistributor.personalized_plan.payday" [value]="newDistributor.personalized_plan.payday||''" min="1"max="31" type="number">
                                        </mat-form-field>
                                    </ng-container>
                                </div>
                            </div>
                        </mat-expansion-panel>
                    </mat-accordion>

                  </div>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>
                  <mat-icon class="text-info">lock</mat-icon>
                  Permisos
                </ng-template>
                <div class="form-box__content">
                    <div class="permissions-container animate__animated animate__fadeInRight">
                        <mat-tab-group>
                            <mat-tab label="INTGPS">
                                <div class="form-box__content-section">
        
                                    <table class="data-table data-table__align-content-left mt-1">
                                        <thead>
                                            <tr>
                                                <th style="text-align: left">Permiso</th>
                                                <th style="text-align: center">Ver</th>
                                                <th style="text-align: center">Editar</th>
                                                <th style="text-align: center">Eliminar</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr class="group-main ">
                                                <td>
                                                    Dispositivos
                                                </td>
                                                <td style="text-align: center">
                                                    <div class="checkbox">
                                                        <input form="registrationForm" class="perm_checkbox perm_view"[disabled]="isPermissionSelected(isUpdate,'devices',['edit','remove'],newDistributor.permissions ) ||isPermissionDisabled('devices','view')" (change)="modifySelection($event,'view')" [checked]="isPermissionSelected(isUpdate,'devices','view',newDistributor.permissions )"
                                                            name="perms[devices][view]" [(ngModel)]="newDistributor['permissions_to_update']['devices']['view']" type="checkbox" value="1">
                                                        <label for=""></label>
                                                    </div>
                                                </td>
                                                <td style="text-align: center">
                                                    <div class="checkbox">
                                                        <input form="registrationForm" class="perm_checkbox perm_edit" name="perms[devices][edit]" [(ngModel)]="newDistributor['permissions_to_update']['devices']['edit']"  [disabled]="isPermissionDisabled('devices','edit')" (change)="modifySelection($event,'edit')" [checked]="isPermissionSelected(isUpdate,'devices','edit',newDistributor.permissions )"
                                                            type="checkbox" value="1">
                                                        <label for=""></label>
                                                    </div>
                                                </td>
                                                <td style="text-align: center">
                                                    <div class="checkbox">
                                                        <input form="registrationForm" class="perm_checkbox perm_remove" name="perms[devices][remove]"  [(ngModel)]="newDistributor['permissions_to_update']['devices']['remove']" [disabled]="isPermissionDisabled('devices','remove')"(change)="modifySelection($event,'remove')" [checked]="isPermissionSelected(isUpdate,'devices','remove',newDistributor.permissions )"
                                                            type="checkbox" value="1">
                                                        <label for=""></label>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr class="group-main ">
                                                <td>
                                                    Alertas
                                                </td>
                                                <td style="text-align: center">
                                                    <div class="checkbox disabled">
                                                        <input form="registrationForm" [(ngModel)]="newDistributor['permissions_to_update']['alerts']['view']" class="perm_checkbox perm_view" [disabled]="isPermissionSelected(isUpdate,'alerts',['edit','remove'],newDistributor.permissions ) ||isPermissionDisabled('alerts','view')" [checked]="isPermissionSelected(isUpdate,'alerts','view',newDistributor.permissions )" (change)="modifySelection($event,'view')" 
                                                            name="perms[alerts][view]" type="checkbox" value="1" >
                                                        <label for=""></label>
                                                    </div>
                                                </td>
                                                <td style="text-align: center">
                                                    <div class="checkbox">
                                                        <input form="registrationForm" [(ngModel)]="newDistributor['permissions_to_update']['alerts']['edit']" class="perm_checkbox perm_edit" (change)="modifySelection($event,'edit')" [checked]="isPermissionSelected(isUpdate,'alerts','edit',newDistributor.permissions )" [disabled]="isPermissionDisabled('alerts','edit')"
                                                            name="perms[alerts][edit]" type="checkbox" value="1">
                                                        <label for=""></label>
                                                    </div>
                                                </td>
                                                <td style="text-align: center">
                                                    <div class="checkbox">
                                                        <input form="registrationForm" [(ngModel)]="newDistributor['permissions_to_update']['alerts']['remove']" class="perm_checkbox perm_remove" (change)="modifySelection($event,'remove')"[disabled]="isPermissionDisabled('alerts','remove')"  [checked]="isPermissionSelected(isUpdate,'alerts','remove',newDistributor.permissions )"
                                                            name="perms[alerts][remove]" type="checkbox" value="1">
                                                        <label for=""></label>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr class="group-main ">
                                                <td>
                                                    Geocercas
                                                </td>
                                                <td style="text-align: center">
                                                    <div class="checkbox disabled">
                                                        <input form="registrationForm" class="perm_checkbox perm_view"(change)="modifySelection($event,'view')"  [checked]="isPermissionSelected(isUpdate,'geofences','view',newDistributor.permissions )" [disabled]="isPermissionSelected(isUpdate,'geofences',['edit','remove'],newDistributor.permissions ) ||isPermissionDisabled('geofences','view')" 
                                                            name="perms[geofences][view]" [(ngModel)]="newDistributor['permissions_to_update']['geofences']['view']" type="checkbox" value="1" >
                                                        <label for=""></label>
                                                    </div>
                                                </td>
                                                <td style="text-align: center">
                                                    <div class="checkbox">
                                                        <input form="registrationForm" class="perm_checkbox perm_edit"(change)="modifySelection($event,'edit')"  [checked]="isPermissionSelected(isUpdate,'geofences','edit',newDistributor.permissions )" [disabled]="isPermissionDisabled('geofences','edit')"
                                                            name="perms[geofences][edit]"  [(ngModel)]="newDistributor['permissions_to_update']['geofences']['edit']" type="checkbox" value="1">
                                                        <label for=""></label>
                                                    </div>
                                                </td>
                                                <td style="text-align: center">
                                                    <div class="checkbox">
                                                        <input form="registrationForm" class="perm_checkbox perm_remove"(change)="modifySelection($event,'remove')"   [checked]="isPermissionSelected(isUpdate,'geofences','remove',newDistributor.permissions )" [disabled]="isPermissionDisabled('geofences','remove')"
                                                            name="perms[geofences][remove]" [(ngModel)]="newDistributor['permissions_to_update']['geofences']['remove']" type="checkbox" value="1">
                                                        <label for=""></label>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr class="group-main ">
                                                <td>
                                                    Rutas
                                                </td>
                                                <td style="text-align: center">
                                                    <div class="checkbox disabled">
                                                        <input form="registrationForm" class="perm_checkbox perm_view"(change)="modifySelection($event,'view')"  [checked]="isPermissionSelected(isUpdate,'routes','view',newDistributor.permissions )"  [disabled]="isPermissionSelected(isUpdate,'routes',['edit','remove'],newDistributor.permissions ) ||isPermissionDisabled('routes','view')" 
                                                            name="perms[routes][view]" [(ngModel)]="newDistributor['permissions_to_update']['routes']['view']" type="checkbox" value="1">
                                                        <label for=""></label>
                                                    </div>
                                                </td>
                                                <td style="text-align: center">
                                                    <div class="checkbox">
                                                        <input form="registrationForm" class="perm_checkbox perm_edit" (change)="modifySelection($event,'edit')" [checked]="isPermissionSelected(isUpdate,'routes','edit',newDistributor.permissions )" [disabled]="isPermissionDisabled('routes','edit')"
                                                            name="perms[routes][edit]"  [(ngModel)]="newDistributor['permissions_to_update']['routes']['edit']"  type="checkbox" value="1">
                                                        <label for=""></label>
                                                    </div>
                                                </td>
                                                <td style="text-align: center">
                                                    <div class="checkbox">
                                                        <input form="registrationForm" class="perm_checkbox perm_remove" (change)="modifySelection($event,'remove')"  [checked]="isPermissionSelected(isUpdate,'routes','remove',newDistributor.permissions )" [disabled]="isPermissionDisabled('routes','remove')"
                                                            name="perms[routes][remove]"  [(ngModel)]="newDistributor['permissions_to_update']['routes']['remove']"  type="checkbox" value="1">
                                                        <label for=""></label>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr class="group-main ">
                                                <td>
                                                    POI
                                                </td>
                                                <td style="text-align: center">
                                                    <div class="checkbox disabled">
                                                        <input form="registrationForm" class="perm_checkbox perm_view"(change)="modifySelection($event,'view')"  [disabled]="isPermissionSelected(isUpdate,'poi',['edit','remove'],newDistributor.permissions )"  [checked]="isPermissionSelected(isUpdate,'poi','view',newDistributor.permissions )||isPermissionDisabled('poi','view')" 
                                                        name="perms[poi][view]"  [(ngModel)]="newDistributor['permissions_to_update']['poi']['view']" 
                                                            type="checkbox" value="1">
                                                        <label for=""></label>
                                                    </div>
                                                </td>
                                                <td style="text-align: center">
                                                    <div class="checkbox">
                                                        <input form="registrationForm" class="perm_checkbox perm_edit" (change)="modifySelection($event,'edit')"   [checked]="isPermissionSelected(isUpdate,'poi','edit',newDistributor.permissions )" 
                                                        name="perms[poi][edit]"  [(ngModel)]="newDistributor['permissions_to_update']['poi']['edit']" [disabled]="isPermissionDisabled('poi','edit')"
                                                            type="checkbox" value="1">
                                                        <label for=""></label>
                                                    </div>
                                                </td>
                                                <td style="text-align: center">
                                                    <div class="checkbox">
                                                        <input form="registrationForm" class="perm_checkbox perm_remove"  (change)="modifySelection($event,'remove')"  [checked]="isPermissionSelected(isUpdate,'poi','remove',newDistributor.permissions )" [disabled]="isPermissionDisabled('poi','remove')"
                                                            name="perms[poi][remove]"  [(ngModel)]="newDistributor['permissions_to_update']['poi']['remove']" type="checkbox" value="1">
                                                        <label for=""></label>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr class="group-main ">
                                                <td>
                                                    Reportes
                                                </td>
                                                <td style="text-align: center">
                                                    <div class="checkbox disabled">
                                                        <input form="registrationForm" class="perm_checkbox perm_view"(change)="modifySelection($event,'view')" [disabled]="isPermissionSelected(isUpdate,'reports',['edit','remove'],newDistributor.permissions ) ||isPermissionDisabled('reports','view')" [checked]="isPermissionSelected(isUpdate,'reports','view',newDistributor.permissions )" 
                                                            name="perms[reports][view]"  [(ngModel)]="newDistributor['permissions_to_update']['reports']['view']" type="checkbox" value="1" >
                                                        <label for=""></label>
                                                    </div>
                                                </td>
                                                <td style="text-align: center">
                                                    <div class="checkbox">
                                                        <input form="registrationForm" class="perm_checkbox perm_edit"  (change)="modifySelection($event,'edit')"   [checked]="isPermissionSelected(isUpdate,'reports','edit',newDistributor.permissions )" [disabled]="isPermissionDisabled('reports','edit')"
                                                            name="perms[reports][edit]"  [(ngModel)]="newDistributor['permissions_to_update']['reports']['edit']" type="checkbox" value="1">
                                                        <label for=""></label>
                                                    </div>
                                                </td>
                                                <td style="text-align: center">
                                                    <div class="checkbox">
                                                        <input form="registrationForm" class="perm_checkbox perm_remove"   (change)="modifySelection($event,'remove')"   [checked]="isPermissionSelected(isUpdate,'reports','remove',newDistributor.permissions )" [disabled]="isPermissionDisabled('reports','remove')"
                                                            name="perms[reports][remove]"  [(ngModel)]="newDistributor['permissions_to_update']['reports']['remove']" type="checkbox" value="1">
                                                        <label for=""></label>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr class="group-main ">
                                                <td>
                                                    Conductores
                                                </td>
                                                <td style="text-align: center">
                                                    <div class="checkbox disabled">
                                                        <input form="registrationForm" class="perm_checkbox perm_view" (change)="modifySelection($event,'view')" [disabled]="isPermissionSelected(isUpdate,'drivers',['edit','remove'],newDistributor.permissions ) ||isPermissionDisabled('drivers','view')"   [checked]="isPermissionSelected(isUpdate,'drivers','view',newDistributor.permissions )" 
                                                            name="perms[drivers][view]"  [(ngModel)]="newDistributor['permissions_to_update']['drivers']['view']" type="checkbox" value="1" >
                                                        <label for=""></label>
                                                    </div>
                                                </td>
                                                <td style="text-align: center">
                                                    <div class="checkbox">
                                                        <input form="registrationForm" class="perm_checkbox perm_edit"   (change)="modifySelection($event,'edit')"   [checked]="isPermissionSelected(isUpdate,'drivers','edit',newDistributor.permissions )" [disabled]="isPermissionDisabled('drivers','edit')"
                                                            name="perms[drivers][edit]"  [(ngModel)]="newDistributor['permissions_to_update']['drivers']['edit']" type="checkbox" value="1">
                                                        <label for=""></label>
                                                    </div>
                                                </td>
                                                <td style="text-align: center">
                                                    <div class="checkbox">
                                                        <input form="registrationForm" class="perm_checkbox perm_remove"   (change)="modifySelection($event,'remove')"    [checked]="isPermissionSelected(isUpdate,'drivers','remove',newDistributor.permissions )" [disabled]="isPermissionDisabled('drivers','remove')"
                                                            name="perms[drivers][remove]"  [(ngModel)]="newDistributor['permissions_to_update']['drivers']['remove']" type="checkbox" value="1">
                                                        <label for=""></label>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr class="group-main ">
                                                <td>
                                                    Eventos personalizados
                                                </td>
                                                <td style="text-align: center">
                                                    <div class="checkbox disabled">
                                                        <input form="registrationForm" class="perm_checkbox perm_view" (change)="modifySelection($event,'view')" [disabled]="isPermissionSelected(isUpdate,'custom_events',['edit','remove'],newDistributor.permissions ) ||isPermissionDisabled('custom_events','view')" [checked]="isPermissionSelected(isUpdate,'custom_events','view',newDistributor.permissions )"
                                                            name="perms[custom_events][view]"  [(ngModel)]="newDistributor['permissions_to_update']['custom_events']['view']" type="checkbox" value="1">
                                                        <label for=""></label>
                                                    </div>
                                                </td>
                                                <td style="text-align: center">
                                                    <div class="checkbox">
                                                        <input form="registrationForm" class="perm_checkbox perm_edit" (change)="modifySelection($event,'edit')" [checked]="isPermissionSelected(isUpdate,'custom_events','edit',newDistributor.permissions )" [disabled]="isPermissionDisabled('custom_events','edit')"
                                                            name="perms[custom_events][edit]"  [(ngModel)]="newDistributor['permissions_to_update']['custom_events']['edit']" type="checkbox" value="1">
                                                        <label for=""></label>
                                                    </div>
                                                </td>
                                                <td style="text-align: center">
                                                    <div class="checkbox">
                                                        <input form="registrationForm" class="perm_checkbox perm_remove"  (change)="modifySelection($event,'remove')" [checked]="isPermissionSelected(isUpdate,'custom_events','remove',newDistributor.permissions )" [disabled]="isPermissionDisabled('custom_events','remove')"
                                                            name="perms[custom_events][remove]"  [(ngModel)]="newDistributor['permissions_to_update']['custom_events']['remove']" type="checkbox" value="1">
                                                        <label for=""></label>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr class="group-main ">
                                                <td>
                                                    Plantillas GPRS de usuario
                                                </td>
                                                <td style="text-align: center">
                                                    <div class="checkbox disabled">
                                                        <input form="registrationForm" class="perm_checkbox perm_view"(change)="modifySelection($event,'view')" [disabled]="isPermissionSelected(isUpdate,'user_gprs_templates',['edit','remove'],newDistributor.permissions ) ||isPermissionDisabled('user_gprs_templates','view')" [checked]="isPermissionSelected(isUpdate,'user_gprs_templates','view',newDistributor.permissions )" 
                                                            name="perms[user_gprs_templates][view]"  [(ngModel)]="newDistributor['permissions_to_update']['user_gprs_templates']['view']" type="checkbox" value="1">
                                                        <label for=""></label>
                                                    </div>
                                                </td>
                                                <td style="text-align: center">
                                                    <div class="checkbox">
                                                        <input form="registrationForm" class="perm_checkbox perm_edit"  (change)="modifySelection($event,'edit')" [checked]="isPermissionSelected(isUpdate,'user_gprs_templates','edit',newDistributor.permissions )"[disabled]="isPermissionDisabled('user_gprs_templates','edit')"
                                                            name="perms[user_gprs_templates][edit]"  [(ngModel)]="newDistributor['permissions_to_update']['user_gprs_templates']['edit']" type="checkbox" value="1">
                                                        <label for=""></label>
                                                    </div>
                                                </td>
                                                <td style="text-align: center">
                                                    <div class="checkbox">
                                                        <input form="registrationForm" class="perm_checkbox perm_remove"  (change)="modifySelection($event,'remove')"  [checked]="isPermissionSelected(isUpdate,'user_gprs_templates','remove',newDistributor.permissions )" [disabled]="isPermissionDisabled('user_gprs_templates','remove')"
                                                            name="perms[user_gprs_templates][remove]"  [(ngModel)]="newDistributor['permissions_to_update']['user_gprs_templates']['remove']" type="checkbox" value="1">
                                                        <label for=""></label>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr class="group-main ">
                                                <td>
                                                    Plantillas de SMS de usuario
                                                </td>
                                                <td style="text-align: center">
                                                    <div class="checkbox disabled">
                                                        <input form="registrationForm" class="perm_checkbox perm_view"(change)="modifySelection($event,'view')"[disabled]="isPermissionSelected(isUpdate,'user_sms_templates',['edit','remove'],newDistributor.permissions ) ||isPermissionDisabled('user_sms_templates','view')"  [checked]="isPermissionSelected(isUpdate,'user_sms_templates','view',newDistributor.permissions )" 
                                                            name="perms[user_sms_templates][view]"  [(ngModel)]="newDistributor['permissions_to_update']['user_sms_templates']['view']" type="checkbox" value="1">
                                                        <label for=""></label>
                                                    </div>
                                                </td>
                                                <td style="text-align: center">
                                                    <div class="checkbox">
                                                        <input form="registrationForm" class="perm_checkbox perm_edit" (change)="modifySelection($event,'edit')" [checked]="isPermissionSelected(isUpdate,'user_sms_templates','edit',newDistributor.permissions )"  [disabled]="isPermissionDisabled('user_sms_templates','edit')"
                                                            name="perms[user_sms_templates][edit]" [(ngModel)]="newDistributor['permissions_to_update']['user_sms_templates']['edit']"  type="checkbox" value="1">
                                                        <label for=""></label>
                                                    </div>
                                                </td>
                                                <td style="text-align: center">
                                                    <div class="checkbox">
                                                        <input form="registrationForm" class="perm_checkbox perm_remove" (change)="modifySelection($event,'remove')"  [checked]="isPermissionSelected(isUpdate,'user_sms_templates','remove',newDistributor.permissions )"  [disabled]="isPermissionDisabled('user_sms_templates','remove')"
                                                            name="perms[user_sms_templates][remove]"[(ngModel)]="newDistributor['permissions_to_update']['user_sms_templates']['remove']" type="checkbox" value="1">
                                                        <label for=""></label>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr class="group-main ">
                                                <td>
                                                    Gateway SMS
                                                </td>
                                                <td style="text-align: center">
                                                    <div class="checkbox">
                                                        <input form="registrationForm" class="perm_checkbox perm_view"(change)="modifySelection($event,'view')"[disabled]="isPermissionSelected(isUpdate,'sms_gateway',['edit','remove'],newDistributor.permissions ) ||isPermissionDisabled('sms_gateway','view')"  [checked]="isPermissionSelected(isUpdate,'sms_gateway','view',newDistributor.permissions )"  
                                                            name="perms[sms_gateway][view]" [(ngModel)]="newDistributor['permissions_to_update']['sms_gateway']['view']" type="checkbox" value="1">
                                                        <label for=""></label>
                                                    </div>
                                                </td>
                                                <td style="text-align: center">
                                                    <div class="checkbox">
                                                        <input  form="registrationForm" name="perms[sms_gateway][edit]"  [(ngModel)]="newDistributor['permissions_to_update']['sms_gateway']['edit']" (change)="modifySelection($event,'edit')" [checked]="isPermissionSelected(isUpdate,'sms_gateway','edit',newDistributor.permissions )"   [disabled]="isPermissionDisabled('sms_gateway','edit')" type="checkbox" value="1">
                                                        <label for=""></label>
                                                    </div>
                                                </td>
                                                <td style="text-align: center">
                                                    <div class="checkbox">
                                                        <input  form="registrationForm"  name="perms[sms_gateway][remove]"  [(ngModel)]="newDistributor['permissions_to_update']['sms_gateway']['remove']" (change)="modifySelection($event,'remove')" [checked]="isPermissionSelected(isUpdate,'sms_gateway','remove',newDistributor.permissions )"   [disabled]="isPermissionDisabled('sms_gateway','remove')" type="checkbox" value="1">
                                                        <label for=""></label>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr class="group-main ">
                                                <td>
                                                    Enviar comando
                                                </td>
                                                <td style="text-align: center">
                                                    <div class="checkbox">
                                                        <input form="registrationForm" class="perm_checkbox perm_view"  [checked]="isPermissionSelected(isUpdate,'send_command','view',newDistributor.permissions )"(change)="modifySelection($event,'view')"[disabled]="isPermissionSelected(isUpdate,'send_command',['edit','remove'],newDistributor.permissions )||isPermissionDisabled('send_command','view')"  
                                                            name="perms[send_command][view]"  [(ngModel)]="newDistributor['permissions_to_update']['send_command']['view']" type="checkbox" value="1">
                                                        <label for=""></label>
                                                    </div>
                                                </td>
                                                <td style="text-align: center">
                                                    <div class="checkbox">
                                                        <input  form="registrationForm"name="perms[send_command][edit]"  [(ngModel)]="newDistributor['permissions_to_update']['send_command']['edit']" (change)="modifySelection($event,'edit')" [checked]="isPermissionSelected(isUpdate,'send_command','edit',newDistributor.permissions )"   [disabled]="isPermissionDisabled('send_command','edit')"  type="checkbox" value="1">
                                                        <label for=""></label>
                                                    </div>
                                                </td>
                                                <td style="text-align: center">
                                                    <div class="checkbox">
                                                        <input  form="registrationForm"name="perms[send_command][remove]"  [(ngModel)]="newDistributor['permissions_to_update']['send_command']['remove']" (change)="modifySelection($event,'remove')" [checked]="isPermissionSelected(isUpdate,'send_command','remove',newDistributor.permissions )"   [disabled]="isPermissionDisabled('send_command','remove')" type="checkbox" value="1">
                                                        <label for=""></label>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr class="group-main ">
                                                <td>
                                                    Historial
                                                </td>
                                                <td style="text-align: center">
                                                    <div class="checkbox">
                                                        <input form="registrationForm" class="perm_checkbox perm_view"  [checked]="isPermissionSelected(isUpdate,'history','view',newDistributor.permissions )" (change)="modifySelection($event,'view')"[disabled]="isPermissionSelected(isUpdate,'history',['edit','remove'],newDistributor.permissions ) ||isPermissionDisabled('history','view')"  
                                                            name="perms[history][view]"  [(ngModel)]="newDistributor['permissions_to_update']['history']['view']" type="checkbox" value="1">
                                                        <label for=""></label>
                                                    </div>
                                                </td>
                                                <td style="text-align: center">
                                                    <div class="checkbox">
                                                        <input  form="registrationForm" name="perms[history][edit]"  [(ngModel)]="newDistributor['permissions_to_update']['history']['edit']" (change)="modifySelection($event,'edit')" [checked]="isPermissionSelected(isUpdate,'history','edit',newDistributor.permissions )"   [disabled]="isPermissionDisabled('history','edit')" type="checkbox" value="1">
                                                        <label for=""></label>
                                                    </div>
                                                </td>
                                                <td style="text-align: center">
                                                    <div class="checkbox">
                                                        <input  form="registrationForm" name="perms[history][remove]"  [(ngModel)]="newDistributor['permissions_to_update']['history']['remove']" (change)="modifySelection($event,'remove')" [checked]="isPermissionSelected(isUpdate,'history','remove',newDistributor.permissions )"   [disabled]="isPermissionDisabled('history','remove')" type="checkbox" value="1">
                                                        <label for=""></label>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr class="group-main ">
                                                <td>
                                                    Mantenimiento
                                                </td>
                                                <td style="text-align: center">
                                                    <div class="checkbox">
                                                        <input form="registrationForm" class="perm_checkbox perm_view"  [checked]="isPermissionSelected(isUpdate,'maintenance','view',newDistributor.permissions )" (change)="modifySelection($event,'view')"[disabled]="isPermissionSelected(isUpdate,'maintenance',['edit','remove'],newDistributor.permissions ) ||isPermissionDisabled('maintenance','view')"  
                                                            name="perms[maintenance][view]"  [(ngModel)]="newDistributor['permissions_to_update']['maintenance']['view']" type="checkbox" value="1">
                                                        <label for=""></label>
                                                    </div>
                                                </td>
                                                <td style="text-align: center">
                                                    <div class="checkbox">
                                                        <input  form="registrationForm" name="perms[maintenance][edit]" [(ngModel)]="newDistributor['permissions_to_update']['maintenance']['edit']" (change)="modifySelection($event,'edit')" [checked]="isPermissionSelected(isUpdate,'maintenance','edit',newDistributor.permissions )"   [disabled]="isPermissionDisabled('maintenance','edit')"  type="checkbox" value="1">
                                                        <label for=""></label>
                                                    </div>
                                                </td>
                                                <td style="text-align: center">
                                                    <div class="checkbox">
                                                        <input  form="registrationForm"name="perms[maintenance][remove]" [(ngModel)]="newDistributor['permissions_to_update']['maintenance']['remove']" (change)="modifySelection($event,'remove')" [checked]="isPermissionSelected(isUpdate,'maintenance','remove',newDistributor.permissions )"   [disabled]="isPermissionDisabled('maintenance','remove')"    type="checkbox" value="1">
                                                        <label for=""></label>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr class="group-main ">
                                                <td>
                                                    Cámara / Medios
                                                </td>
                                                <td style="text-align: center">
                                                    <div class="checkbox disabled">
                                                        <input form="registrationForm" class="perm_checkbox perm_view" (change)="modifySelection($event,'view')"[disabled]="isPermissionSelected(isUpdate,'camera',['edit','remove'],newDistributor.permissions )"  [checked]="isPermissionSelected(isUpdate,'camera','view',newDistributor.permissions ) ||isPermissionDisabled('camera','view')"   
                                                            name="perms[camera][view]" [(ngModel)]="newDistributor['permissions_to_update']['camera']['view']" type="checkbox" value="1">
                                                        <label for=""></label>
                                                    </div>
                                                </td>
                                                <td style="text-align: center">
                                                    <div class="checkbox">
                                                        <input form="registrationForm" class="perm_checkbox perm_edit"  (change)="modifySelection($event,'edit')" [checked]="isPermissionSelected(isUpdate,'camera','edit',newDistributor.permissions )" [disabled]="isPermissionDisabled('camera','edit')"
                                                            name="perms[camera][edit]" [(ngModel)]="newDistributor['permissions_to_update']['camera']['edit']" type="checkbox" value="1">
                                                        <label for=""></label>
                                                    </div>
                                                </td>
                                                <td style="text-align: center">
                                                    <div class="checkbox">
                                                        <input  form="registrationForm" 
                                                        name="perms[camera][remove]" [(ngModel)]="newDistributor['permissions_to_update']['camera']['remove']" (change)="modifySelection($event,'remove')" [checked]="isPermissionSelected(isUpdate,'camera','remove',newDistributor.permissions )"   [disabled]="isPermissionDisabled('camera','remove')" type="checkbox" value="1">
                                                        <label for=""></label>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr class="group-main ">
                                                <td>
                                                    Cámara del dispositivo
                                                </td>
                                                <td style="text-align: center">
                                                    <div class="checkbox disabled">
                                                        <input form="registrationForm" class="perm_checkbox perm_view" (change)="modifySelection($event,'view')"[disabled]="isPermissionSelected(isUpdate,'device_camera',['edit','remove'],newDistributor.permissions )||isPermissionDisabled('device_camera','view')" [checked]="isPermissionSelected(isUpdate,'device_camera','view',newDistributor.permissions )"  
                                                            name="perms[device_camera][view]" [(ngModel)]="newDistributor['permissions_to_update']['device_camera']['view']" type="checkbox" value="1" >
                                                        <label for=""></label>
                                                    </div>
                                                </td>
                                                <td style="text-align: center">
                                                    <div class="checkbox">
                                                        <input form="registrationForm" class="perm_checkbox perm_edit"  (change)="modifySelection($event,'edit')"[checked]="isPermissionSelected(isUpdate,'device_camera','edit',newDistributor.permissions )"    [disabled]="isPermissionDisabled('device_camera','edit')"
                                                            name="perms[device_camera][edit]" [(ngModel)]="newDistributor['permissions_to_update']['device_camera']['edit']" type="checkbox" value="1">
                                                        <label for=""></label>
                                                    </div>
                                                </td>
                                                <td style="text-align: center">
                                                    <div class="checkbox">
                                                        <input  form="registrationForm" name="perms[device_camera][remove]" [(ngModel)]="newDistributor['permissions_to_update']['device_camera']['remove']"  (change)="modifySelection($event,'remove')" [checked]="isPermissionSelected(isUpdate,'device_camera','remove',newDistributor.permissions )"   [disabled]="isPermissionDisabled('device_camera','remove')"  type="checkbox" value="1">
                                                        <label for=""></label>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr class="group-main ">
                                                <td>
                                                    Tareas
                                                </td>
                                                <td style="text-align: center">
                                                    <div class="checkbox disabled">
                                                        <input form="registrationForm" class="perm_checkbox perm_view"(change)="modifySelection($event,'view')" [disabled]="isPermissionSelected(isUpdate,'tasks',['edit','remove'],newDistributor.permissions ) ||isPermissionDisabled('tasks','view')" [checked]="isPermissionSelected(isUpdate,'tasks','view',newDistributor.permissions )"  
                                                            name="perms[tasks][view]"  [(ngModel)]="newDistributor['permissions_to_update']['tasks']['view']" type="checkbox" value="1">
                                                        <label for=""></label>
                                                    </div>
                                                </td>
                                                <td style="text-align: center">
                                                    <div class="checkbox">
                                                        <input form="registrationForm" class="perm_checkbox perm_edit" (change)="modifySelection($event,'edit')" [checked]="isPermissionSelected(isUpdate,'tasks','edit',newDistributor.permissions )"  [disabled]="isPermissionDisabled('tasks','edit')"
                                                            name="perms[tasks][edit]" [(ngModel)]="newDistributor['permissions_to_update']['tasks']['edit']" type="checkbox" value="1">
                                                        <label for=""></label>
                                                    </div>
                                                </td>
                                                <td style="text-align: center">
                                                    <div class="checkbox">
                                                        <input  form="registrationForm" name="perms[tasks][remove]" [(ngModel)]="newDistributor['permissions_to_update']['tasks']['remove']" (change)="modifySelection($event,'remove')" [checked]="isPermissionSelected(isUpdate,'tasks','remove',newDistributor.permissions )"   [disabled]="isPermissionDisabled('tasks','remove')" type="checkbox" value="1">
                                                        <label for=""></label>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr class="group-main ">
                                                <td>
                                                    Chat
                                                </td>
                                                <td style="text-align: center">
                                                    <div class="checkbox disabled">
                                                        <input form="registrationForm" class="perm_checkbox perm_view" (change)="modifySelection($event,'view')"  [checked]="isPermissionSelected(isUpdate,'chat','view',newDistributor.permissions )" [disabled]="isPermissionSelected(isUpdate,'chat',['edit','remove'],newDistributor.permissions )||isPermissionDisabled('chat','view')" [checked]="isPermissionSelected(isUpdate,'chat','view',newDistributor.permissions )" 
                                                            name="perms[chat][view]"  [(ngModel)]="newDistributor['permissions_to_update']['chat']['view']" type="checkbox" value="1">
                                                        <label for=""></label>
                                                    </div>
                                                </td>
                                                <td style="text-align: center">
                                                    <div class="checkbox">
                                                        <input form="registrationForm" class="perm_checkbox perm_edit"  (change)="modifySelection($event,'edit')" [checked]="isPermissionSelected(isUpdate,'chat','edit',newDistributor.permissions )"  [disabled]="isPermissionDisabled('chat','edit')"
                                                            name="perms[chat][edit]"  [(ngModel)]="newDistributor['permissions_to_update']['chat']['edit']" type="checkbox" value="1">
                                                        <label for=""></label>
                                                    </div>
                                                </td>
                                                <td style="text-align: center">
                                                    <div class="checkbox">
                                                        <input  form="registrationForm" 
                                                        name="perms[chat][remove]"  [(ngModel)]="newDistributor['permissions_to_update']['chat']['remove']" (change)="modifySelection($event,'remove')" [checked]="isPermissionSelected(isUpdate,'chat','remove',newDistributor.permissions )"   [disabled]="isPermissionDisabled('chat','remove')" type="checkbox" value="1">
                                                        <label for=""></label>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr class="group-main ">
                                                <td>
                                                    Compartir
                                                </td>
                                                <td style="text-align: center">
                                                    <div class="checkbox disabled">
                                                        <input form="registrationForm" class="perm_checkbox perm_view"(change)="modifySelection($event,'view')"[disabled]="isPermissionSelected(isUpdate,'sharing',['edit','remove'],newDistributor.permissions )||isPermissionDisabled('sharing','view')"  [checked]="isPermissionSelected(isUpdate,'sharing','view',newDistributor.permissions )" 
                                                            name="perms[sharing][view]"  [(ngModel)]="newDistributor['permissions_to_update']['sharing']['view']" type="checkbox" value="1">
                                                        <label for=""></label>
                                                    </div>
                                                </td>
                                                <td style="text-align: center">
                                                    <div class="checkbox">
                                                        <input form="registrationForm" class="perm_checkbox perm_edit" (change)="modifySelection($event,'edit')" [checked]="isPermissionSelected(isUpdate,'sharing','edit',newDistributor.permissions )" [disabled]="isPermissionDisabled('sharing','edit')"
                                                            name="perms[sharing][edit]" [(ngModel)]="newDistributor['permissions_to_update']['sharing']['edit']" type="checkbox" value="1">
                                                        <label for=""></label>
                                                    </div>
                                                </td>
                                                <td style="text-align: center">
                                                    <div class="checkbox">
                                                        <input  form="registrationForm"
                                                        name="perms[sharing][remove]"[(ngModel)]="newDistributor['permissions_to_update']['sharing']['remove']" (change)="modifySelection($event,'remove')" [checked]="isPermissionSelected(isUpdate,'sharing','remove',newDistributor.permissions )" [disabled]="isPermissionDisabled('sharing','remove')" type="checkbox" value="1">
                                                        <label for=""></label>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr class="group-main ">
                                                <td>
                                                    Activación de dispositivo GPS
                                                </td>
                                                <td style="text-align: center">
                                                    <div class="checkbox">
                                                        <input form="registrationForm" class="perm_checkbox perm_view"(change)="modifySelection($event,'view')" [disabled]="isPermissionSelected(isUpdate,'device_configuration',['edit','remove'],newDistributor.permissions )||isPermissionDisabled('device_configuration','view')" [checked]="isPermissionSelected(isUpdate,'device_configuration','view',newDistributor.permissions )" 
                                                            name="perms[device_configuration][view]" [(ngModel)]="newDistributor['permissions_to_update']['device_configuration']['view']" type="checkbox" value="1">
                                                        <label for=""></label>
                                                    </div>
                                                </td>
                                                <td style="text-align: center">
                                                    <div class="checkbox">
                                                        <input  form="registrationForm" 
                                                        name="perms[device_configuration][edit]"  [(ngModel)]="newDistributor['permissions_to_update']['device_configuration']['edit']" (change)="modifySelection($event,'edit')" [checked]="isPermissionSelected(isUpdate,'device_configuration','edit',newDistributor.permissions )" [disabled]="isPermissionDisabled('device_configuration','edit')"  type="checkbox" value="1">
                                                        <label for=""></label>
                                                    </div>
                                                </td>
                                                <td style="text-align: center">
                                                    <div class="checkbox">
                                                        <input  form="registrationForm" 
                                                        name="perms[device_configuration][remove]" [(ngModel)]="newDistributor['permissions_to_update']['device_configuration']['remove']" (change)="modifySelection($event,'remove')" [checked]="isPermissionSelected(isUpdate,'device_configuration','remove',newDistributor.permissions )" [disabled]="isPermissionDisabled('device_configuration','rmeove')" type="checkbox" value="1">
                                                        <label for=""></label>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr class="group-main ">
                                                <td>
                                                    Tipo de ruta del dispositivo
                                                </td>
                                                <td style="text-align: center">
                                                    <div class="checkbox">
                                                        <input  form="registrationForm" class="perm_checkbox perm_view" 
                                                        name="perms[device_route_types][view]" [(ngModel)]="newDistributor['permissions_to_update']['device_route_types']['view']" (change)="modifySelection($event,'view')" [disabled]="isPermissionSelected(isUpdate,'device_route_types',['edit','remove'],newDistributor.permissions )||isPermissionDisabled('device_route_types','view')" [checked]="isPermissionSelected(isUpdate,'device_route_types','view',newDistributor.permissions )"   type="checkbox" value="1">
                                                        <label for=""></label>
                                                    </div>
                                                </td>
                                                <td style="text-align: center">
                                                    <div class="checkbox">
                                                        <input  form="registrationForm"  
                                                        name="perms[device_route_types][edit]" [(ngModel)]="newDistributor['permissions_to_update']['device_route_types']['edit']" class="perm_checkbox perm_edit" (change)="modifySelection($event,'edit')" [checked]="isPermissionSelected(isUpdate,'device_route_types','edit',newDistributor.permissions )" [disabled]="isPermissionDisabled('device_route_types','edit')" type="checkbox" value="1">
                                                        <label for=""></label>
                                                    </div>
                                                </td>
                                                <td style="text-align: center">
                                                    <div class="checkbox">
                                                        <input  form="registrationForm"  
                                                        name="perms[device_route_types][remove]"[(ngModel)]="newDistributor['permissions_to_update']['device_route_types']['remove']" class="perm_checkbox perm_remove" (change)="modifySelection($event,'remove')" [checked]="isPermissionSelected(isUpdate,'device_route_types','remove',newDistributor.permissions )" [disabled]="isPermissionDisabled('device_route_types','remove')"  type="checkbox" value="1">
                                                        <label for=""></label>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr class="group-main ">
                                                <td>
                                                    Acciones de llamada
                                                </td>
                                                <td style="text-align: center">
                                                    <div class="checkbox">
                                                        <input  form="registrationForm" 
                                                        name="perms[call_actions][view]" [(ngModel)]="newDistributor['permissions_to_update']['call_actions']['view']" (change)="modifySelection($event,'view')" [disabled]="isPermissionSelected(isUpdate,'call_actions',['edit','remove'],newDistributor.permissions )||isPermissionDisabled('call_actions','view')" [checked]="isPermissionSelected(isUpdate,'call_actions','view',newDistributor.permissions )"  
                                                        type="checkbox" value="1">
                                                        <label for=""></label>
                                                    </div>
                                                </td>
                                                <td style="text-align: center">
                                                    <div class="checkbox">
                                                        <input  form="registrationForm" 
                                                        name="perms[call_actions][edit]"  [(ngModel)]="newDistributor['permissions_to_update']['call_actions']['edit']" (change)="modifySelection($event,'edit')" [checked]="isPermissionSelected(isUpdate,'call_actions','edit',newDistributor.permissions )" [disabled]="isPermissionDisabled('call_actions','edit')" type="checkbox" value="1">
                                                        <label for=""></label>
                                                    </div>
                                                </td>
                                                <td style="text-align: center">
                                                    <div class="checkbox">
                                                        <input  form="registrationForm" 
                                                        name="perms[call_actions][remove]"  [(ngModel)]="newDistributor['permissions_to_update']['call_actions']['remove']" (change)="modifySelection($event,'remove')" [checked]="isPermissionSelected(isUpdate,'call_actions','remove',newDistributor.permissions )" [disabled]="isPermissionDisabled('call_actions','remove')" type="checkbox" value="1">
                                                        <label for=""></label>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr class="group-main ">
                                                <td>
                                                    Usuarios
                                                </td>
                                                <td style="text-align: center">
                                                    <div class="checkbox">
                                                        <input  form="registrationForm" 
                                                        name="perms[users][view]"  [(ngModel)]="newDistributor['permissions_to_update']['users']['view']" (change)="modifySelection($event,'view')" [disabled]="isPermissionSelected(isUpdate,'users',['edit','remove'],newDistributor.permissions )||isPermissionDisabled('users','view')" [checked]="isPermissionSelected(isUpdate,'users','view',newDistributor.permissions )"  
                                                        type="checkbox" value="1">
                                                        <label for=""></label>
                                                    </div>
                                                </td>
                                                <td style="text-align: center">
                                                    <div class="checkbox">
                                                        <input  form="registrationForm" 
                                                        name="perms[users][edit]" [(ngModel)]="newDistributor['permissions_to_update']['users']['edit']" (change)="modifySelection($event,'edit')" [checked]="isPermissionSelected(isUpdate,'users','edit',newDistributor.permissions )" [disabled]="isPermissionDisabled('users','edit')" type="checkbox" value="1">
                                                        <label for=""></label>
                                                    </div>
                                                </td>
                                                <td style="text-align: center">
                                                    <div class="checkbox">
                                                        <input  form="registrationForm" 
                                                        name="perms[users][remove]" [(ngModel)]="newDistributor['permissions_to_update']['users']['remove']" (change)="modifySelection($event,'remove')" [checked]="isPermissionSelected(isUpdate,'users','remove',newDistributor.permissions )" [disabled]="isPermissionDisabled('users','remove')" type="checkbox" value="1">
                                                        <label for=""></label>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <details class="device-permissions-container" open>
                                        <summary class="cursor-pointer">Device</summary>
                                        <table class="data-table data-table__align-content-left">
                                            <thead>
                                                <tr>
                                                    <th style="text-align: left">Permiso</th>
                                                    <th style="text-align: center">Ver</th>
                                                    <th style="text-align: center">Editar</th>
                                                    <th style="text-align: center">Eliminar</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr class="group-device collapse">
                                                    <td>
                                                        IMEI
                                                    </td>
                                                    <td style="text-align: center">
                                                        <div class="checkbox disabled">
                                                            <input form="registrationForm" class="perm_checkbox perm_view"(change)="modifySelection($event,'view')" [disabled]="isPermissionSelected(isUpdate,'device.imei',['edit','remove'],newDistributor.permissions ) ||isPermissionDisabled('device.imei','view')" [checked]="isPermissionSelected(isUpdate,'device.imei','view',newDistributor.permissions )" 
                                                                name="perms[device.imei][view]" [(ngModel)]="newDistributor['permissions_to_update']['device.imei']['view']" type="checkbox" value="1">
                                                            <label for=""></label>
                                                        </div>
                                                    </td>
                                                    <td style="text-align: center">
                                                        <div class="checkbox">
                                                            <input form="registrationForm" class="perm_checkbox perm_edit" (change)="modifySelection($event,'edit')" [checked]="isPermissionSelected(isUpdate,'device.imei','edit',newDistributor.permissions )"  [disabled]="isPermissionDisabled('device.imei','edit')" 
                                                                name="perms[device.imei][edit]"  [(ngModel)]="newDistributor['permissions_to_update']['device.imei']['edit']" type="checkbox" value="1">
                                                            <label for=""></label>
                                                        </div>
                                                    </td>
                                                    <td style="text-align: center">
                                                        <div class="checkbox">
                                                            <input  form="registrationForm" 
                                                            name="perms[device.imei][remove]"[(ngModel)]="newDistributor['permissions_to_update']['device.imei']['remove']"  (change)="modifySelection($event,'remove')" [checked]="isPermissionSelected(isUpdate,'device.imei','remove',newDistributor.permissions )"  [disabled]="isPermissionDisabled('device.imei','remove')"  type="checkbox" value="1">
                                                            <label for=""></label>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr class="group-device collapse">
                                                    <td>
                                                        Número SIM
                                                    </td>
                                                    <td style="text-align: center">
                                                        <div class="checkbox disabled">
                                                            <input form="registrationForm" class="perm_checkbox perm_view"(change)="modifySelection($event,'view')"[disabled]="isPermissionSelected(isUpdate,'device.sim_number',['edit','remove'],newDistributor.permissions )||isPermissionDisabled('device.sim_number','view')"   [checked]="isPermissionSelected(isUpdate,'device.sim_number','view',newDistributor.permissions )"  
                                                                name="perms[device.sim_number][view]" [(ngModel)]="newDistributor['permissions_to_update']['device.sim_number']['view']" type="checkbox" value="1">
                                                            <label for=""></label>
                                                        </div>
                                                    </td>
                                                    <td style="text-align: center">
                                                        <div class="checkbox">
                                                            <input form="registrationForm" class="perm_checkbox perm_edit"  (change)="modifySelection($event,'edit')" [checked]="isPermissionSelected(isUpdate,'device.sim_number','edit',newDistributor.permissions )" [disabled]="isPermissionDisabled('device.sim_number','edit')" 
                                                                name="perms[device.sim_number][edit]"  [(ngModel)]="newDistributor['permissions_to_update']['device.sim_number']['edit']" type="checkbox" value="1">
                                                            <label for=""></label>
                                                        </div>
                                                    </td>
                                                    <td style="text-align: center">
                                                        <div class="checkbox">
                                                            <input  form="registrationForm" 
                                                            name="perms[device.sim_number][remove]" [(ngModel)]="newDistributor['permissions_to_update']['device.sim_number']['remove']" (change)="modifySelection($event,'remove')" [checked]="isPermissionSelected(isUpdate,'device.sim_number','remove',newDistributor.permissions )"  [disabled]="isPermissionDisabled('device.sim_number','remove')"  type="checkbox" value="1">
                                                            <label for=""></label>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr class="group-device collapse">
                                                    <td>
                                                        Adelante
                                                    </td>
                                                    <td style="text-align: center">
                                                        <div class="checkbox disabled">
                                                            <input form="registrationForm" class="perm_checkbox perm_view"(change)="modifySelection($event,'view')"[disabled]="isPermissionSelected(isUpdate,'device.forward',['edit','remove'],newDistributor.permissions ) ||isPermissionDisabled('device.forward','view')"  [checked]="isPermissionSelected(isUpdate,'device.forward','view',newDistributor.permissions )" 
                                                                name="perms[device.forward][view]" [(ngModel)]="newDistributor['permissions_to_update']['device.forward']['view']" type="checkbox" value="1">
                                                            <label for=""></label>
                                                        </div>
                                                    </td>
                                                    <td style="text-align: center">
                                                        <div class="checkbox">
                                                            <input form="registrationForm" name="perms[device.forward][edit]" class="perm_checkbox perm_edit" (change)="modifySelection($event,'edit')"  [checked]="isPermissionSelected(isUpdate,'device.forward','edit',newDistributor.permissions )" [disabled]="isPermissionDisabled('device.forward','edit')"
                                                                name="perms[device.forward][edit]" [(ngModel)]="newDistributor['permissions_to_update']['device.forward']['edit']" type="checkbox" value="1">
                                                            <label for=""></label>
                                                        </div>
                                                    </td>
                                                    <td style="text-align: center">
                                                        <div class="checkbox">
                                                            <input name="perms[device.forward][remove]" [(ngModel)]="newDistributor['permissions_to_update']['device.forward']['remove']" form="registrationForm" (change)="modifySelection($event,'remove')"  [checked]="isPermissionSelected(isUpdate,'device.forward','remove',newDistributor.permissions )" [disabled]="isPermissionDisabled('device.forward','remove')"  type="checkbox" value="1">
                                                            <label for=""></label>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr class="group-device collapse">
                                                    <td>
                                                        Protocolo
                                                    </td>
                                                    <td style="text-align: center">
                                                        <div class="checkbox">
                                                            <input form="registrationForm" class="perm_checkbox perm_view"(change)="modifySelection($event,'view')"[disabled]="isPermissionSelected(isUpdate,'device.protocol',['edit','remove'],newDistributor.permissions ) ||isPermissionDisabled('device.protocol','view')"   [checked]="isPermissionSelected(isUpdate,'device.protocol','view',newDistributor.permissions )"
                                                                name="perms[device.protocol][view]" [(ngModel)]="newDistributor['permissions_to_update']['device.protocol']['view']" type="checkbox" value="1">
                                                            <label for=""></label>
                                                        </div>
                                                    </td>
                                                    <td style="text-align: center">
                                                        <div class="checkbox">
                                                            <input  form="registrationForm"
                                                            name="perms[device.protocol][edit]" [(ngModel)]="newDistributor['permissions_to_update']['device.protocol']['edit']" (change)="modifySelection($event,'edit')"  [checked]="isPermissionSelected(isUpdate,'device.protocol','edit',newDistributor.permissions )" [disabled]="isPermissionDisabled('device.protocol','edit')"  type="checkbox" value="1">
                                                            <label for=""></label>
                                                        </div>
                                                    </td>
                                                    <td style="text-align: center">
                                                        <div class="checkbox">
                                                            <input  form="registrationForm" (change)="modifySelection($event,'remove')"  [checked]="isPermissionSelected(isUpdate,'device.protocol','remove',newDistributor.permissions )" [disabled]="isPermissionDisabled('device.protocol','remove')" type="checkbox" value="1">
                                                            <label for=""></label>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr class="group-device collapse">
                                                    <td>
                                                        Fecha de vencimiento
                                                    </td>
                                                    <td style="text-align: center">
                                                        <div class="checkbox">
                                                            <input form="registrationForm" class="perm_checkbox perm_view"(change)="modifySelection($event,'view')"[disabled]="isPermissionSelected(isUpdate,'device.expiration_date',['edit','remove'],newDistributor.permissions ) ||isPermissionDisabled('device.expiration_date','view')"  [checked]="isPermissionSelected(isUpdate,'device.expiration_date','view',newDistributor.permissions )" 
                                                                name="perms[device.expiration_date][view]" [(ngModel)]="newDistributor['permissions_to_update']['device.expiration_date']['view']" type="checkbox" value="1">
                                                            <label for=""></label>
                                                        </div>
                                                    </td>
                                                    <td style="text-align: center">
                                                        <div class="checkbox">
                                                            <input  form="registrationForm"
                                                            name="perms[device.expiration_date][edit]"  [(ngModel)]="newDistributor['permissions_to_update']['device.expiration_date']['edit']" (change)="modifySelection($event,'edit')"  [checked]="isPermissionSelected(isUpdate,'device.expiration_date','edit',newDistributor.permissions )" [disabled]="isPermissionDisabled('device.expiration_date','edit')"type="checkbox" value="1">
                                                            <label for=""></label>
                                                        </div>
                                                    </td>
                                                    <td style="text-align: center">
                                                        <div class="checkbox">
                                                            <input  form="registrationForm" 
                                                            name="perms[device.expiration_date][remove]"  [(ngModel)]="newDistributor['permissions_to_update']['device.expiration_date']['remove']" (change)="modifySelection($event,'remove')"  [checked]="isPermissionSelected(isUpdate,'device.expiration_date','remove',newDistributor.permissions )" [disabled]="isPermissionDisabled('device.expiration_date','remove')" type="checkbox" value="1">
                                                            <label for=""></label>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr class="group-device collapse">
                                                    <td>
                                                        Fecha de instalación
                                                    </td>
                                                    <td style="text-align: center">
                                                        <div class="checkbox disabled">
                                                            <input form="registrationForm" class="perm_checkbox perm_view" (change)="modifySelection($event,'view')" [disabled]="isPermissionSelected(isUpdate,'device.installation_date',['edit','remove'],newDistributor.permissions )||isPermissionDisabled('device.installation_date','view')" [checked]="isPermissionSelected(isUpdate,'device.installation_date','view',newDistributor.permissions )"
                                                                name="perms[device.installation_date][view]"  [(ngModel)]="newDistributor['permissions_to_update']['device.installation_date']['view']" type="checkbox" value="1">
                                                            <label for=""></label>
                                                        </div>
                                                    </td>
                                                    <td style="text-align: center">
                                                        <div class="checkbox">
                                                            <input form="registrationForm" class="perm_checkbox perm_edit" (change)="modifySelection($event,'edit')"  [checked]="isPermissionSelected(isUpdate,'device.installation_date','edit',newDistributor.permissions )" [disabled]="isPermissionDisabled('device.installation_date','edit')"
                                                                name="perms[device.installation_date][edit]"  [(ngModel)]="newDistributor['permissions_to_update']['device.installation_date']['edit']" type="checkbox" value="1">
                                                            <label for=""></label>
                                                        </div>
                                                    </td>
                                                    <td style="text-align: center">
                                                        <div class="checkbox">
                                                            <input  form="registrationForm"
                                                            name="perms[device.installation_date][remove]" [(ngModel)]="newDistributor['permissions_to_update']['device.installation_date']['remove']" (change)="modifySelection($event,'remove')"  [checked]="isPermissionSelected(isUpdate,'device.installation_date','remove',newDistributor.permissions )" [disabled]="isPermissionDisabled('device.installation_date','remove')" type="checkbox" value="1">
                                                            <label for=""></label>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr class="group-device collapse">
                                                    <td>
                                                        Fecha de activación de SIM
                                                    </td>
                                                    <td style="text-align: center">
                                                        <div class="checkbox disabled">
                                                            <input form="registrationForm" class="perm_checkbox perm_view"(change)="modifySelection($event,'view')" [disabled]="isPermissionSelected(isUpdate,'device.sim_activation_date',['edit','remove'],newDistributor.permissions ) ||isPermissionDisabled('device.sim_activation_date','view')" [checked]="isPermissionSelected(isUpdate,'device.sim_activation_date','view',newDistributor.permissions )"  
                                                                name="perms[device.sim_activation_date][view]" [(ngModel)]="newDistributor['permissions_to_update']['device.sim_activation_date']['view']" type="checkbox" value="1">
                                                            <label for=""></label>
                                                        </div>
                                                    </td>
                                                    <td style="text-align: center">
                                                        <div class="checkbox">
                                                            <input form="registrationForm" class="perm_checkbox perm_edit"  (change)="modifySelection($event,'edit')"[checked]="isPermissionSelected(isUpdate,'device.sim_activation_date','edit',newDistributor.permissions )"  [disabled]="isPermissionDisabled('device.sim_activation_date','edit')"
                                                                name="perms[device.sim_activation_date][edit]" [(ngModel)]="newDistributor['permissions_to_update']['device.sim_activation_date']['edit']" type="checkbox" value="1">
                                                            <label for=""></label>
                                                        </div>
                                                    </td>
                                                    <td style="text-align: center">
                                                        <div class="checkbox">
                                                            <input  form="registrationForm" 
                                                            name="perms[device.sim_activation_date][remove]"[(ngModel)]="newDistributor['permissions_to_update']['device.sim_activation_date']['remove']" (change)="modifySelection($event,'remove')"[checked]="isPermissionSelected(isUpdate,'device.sim_activation_date','remove',newDistributor.permissions )"  [disabled]="isPermissionDisabled('device.sim_activation_date','remove')"  type="checkbox" value="1">
                                                            <label for=""></label>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr class="group-device collapse">
                                                    <td>
                                                        Fecha de vencimiento de SIM
                                                    </td>
                                                    <td style="text-align: center">
                                                        <div class="checkbox disabled">
                                                            <input form="registrationForm" class="perm_checkbox perm_view"(change)="modifySelection($event,'view')" [disabled]="isPermissionSelected(isUpdate,'device.sim_expiration_date',['edit','remove'],newDistributor.permissions ) ||isPermissionDisabled('device.sim_expiration_date','view')"  [checked]="isPermissionSelected(isUpdate,'device.sim_expiration_date','view',newDistributor.permissions )" 
                                                                name="perms[device.sim_expiration_date][view]" [(ngModel)]="newDistributor['permissions_to_update']['device.sim_expiration_date']['view']" type="checkbox" value="1">
                                                            <label for=""></label>
                                                        </div>
                                                    </td>
                                                    <td style="text-align: center">
                                                        <div class="checkbox">
                                                            <input form="registrationForm" class="perm_checkbox perm_edit"  (change)="modifySelection($event,'edit')"[checked]="isPermissionSelected(isUpdate,'device.sim_expiration_date','edit',newDistributor.permissions )" [disabled]="isPermissionDisabled('device.sim_expiration_date','edit')||isPermissionDisabled('device.sim_expiration_date','view')"
                                                                name="perms[device.sim_expiration_date][edit]" [(ngModel)]="newDistributor['permissions_to_update']['device.sim_expiration_date']['edit']" type="checkbox" value="1">
                                                            <label for=""></label>
                                                        </div>
                                                    </td>
                                                    <td style="text-align: center">
                                                        <div class="checkbox">
                                                            <input  form="registrationForm"
                                                            name="perms[device.sim_expiration_date][remove]" [(ngModel)]="newDistributor['permissions_to_update']['device.sim_expiration_date']['remove']" (change)="modifySelection($event,'remove')"[checked]="isPermissionSelected(isUpdate,'device.sim_expiration_date','remove',newDistributor.permissions )" [disabled]="isPermissionDisabled('device.sim_expiration_date','remove')||isPermissionDisabled('device.sim_expiration_date','view')" type="checkbox" value="1">
                                                            <label for=""></label>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr class="group-device collapse">
                                                    <td>
                                                        MSISDN
                                                    </td>
                                                    <td style="text-align: center">
                                                        <div class="checkbox disabled">
                                                            <input form="registrationForm" class="perm_checkbox perm_view" (change)="modifySelection($event,'view')"[disabled]="isPermissionSelected(isUpdate,'device.msisdn',['edit','remove'],newDistributor.permissions ) ||isPermissionDisabled('device.msisdn','view')"  [checked]="isPermissionSelected(isUpdate,'device.msisdn','view',newDistributor.permissions )"
                                                                name="perms[device.msisdn][view]" [(ngModel)]="newDistributor['permissions_to_update']['device.msisdn']['view']" type="checkbox" value="1">
                                                            <label for=""></label>
                                                        </div>
                                                    </td>
                                                    <td style="text-align: center">
                                                        <div class="checkbox">
                                                            <input form="registrationForm" class="perm_checkbox perm_edit"  (change)="modifySelection($event,'edit')" [checked]="isPermissionSelected(isUpdate,'device.msisdn','edit',newDistributor.permissions )"
                                                                name="perms[device.msisdn][edit]" [(ngModel)]="newDistributor['permissions_to_update']['device.msisdn']['edit']" type="checkbox" value="1">
                                                            <label for=""></label>
                                                        </div>
                                                    </td>
                                                    <td style="text-align: center">
                                                        <div class="checkbox">
                                                            <input  form="registrationForm" disabled="disabled" type="checkbox" value="0">
                                                            <label for=""></label>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </details>
                                    <details class="device-permissions-container">
                                        <summary class="cursor-pointer">User</summary>
                                        <table class="data-table data-table__align-content-left">
                                            <thead>
                                                <tr>
                                                    <th style="text-align: left">Permiso</th>
                                                    <th style="text-align: center">Ver</th>
                                                    <th style="text-align: center">Editar</th>
                                                    <th style="text-align: center">Eliminar</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr class="group-device collapse">
                                                    <td>
                                                        Identificación del cliente
                                                    </td>
                                                    <td style="text-align: center">
                                                        <div class="checkbox disabled">
                                                            <input form="registrationForm" class="perm_checkbox perm_view"(change)="modifySelection($event,'view')" [disabled]="isPermissionSelected(isUpdate,'user.client_id',['edit','remove'],newDistributor.permissions ) ||isPermissionDisabled('user.client_id','view')" [checked]="isPermissionSelected(isUpdate,'user.client_id','view',newDistributor.permissions )" 
                                                                name="perms[user.client_id][view]" [(ngModel)]="newDistributor['permissions_to_update']['user.client_id']['view']" type="checkbox" value="1">
                                                            <label for=""></label>
                                                        </div>
                                                    </td>
                                                    <td style="text-align: center">
                                                        <div class="checkbox">
                                                            <input form="registrationForm" class="perm_checkbox perm_edit" (change)="modifySelection($event,'edit')" [checked]="isPermissionSelected(isUpdate,'user.client_id','edit',newDistributor.permissions )"  [disabled]="isPermissionDisabled('user.client_id','edit')" 
                                                                name="perms[user.client_id][edit]" [(ngModel)]="newDistributor['permissions_to_update']['user.client_id']['edit']" type="checkbox" value="1">
                                                            <label for=""></label>
                                                        </div>
                                                    </td>
                                                    <td style="text-align: center">
                                                        <div class="checkbox">
                                                            <input  form="registrationForm"
                                                            name="perms[user.client_id][remove]" [(ngModel)]="newDistributor['permissions_to_update']['user.client_id']['remove']"  (change)="modifySelection($event,'remove')" [checked]="isPermissionSelected(isUpdate,'user.client_id','remove',newDistributor.permissions )"  [disabled]="isPermissionDisabled('user.client_id','remove')"  type="checkbox" value="1">
                                                            <label for=""></label>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </details>
                                </div>
                            </mat-tab>
                            <mat-tab label="SGC" *ngIf="isUpdate && !validationService.isNullOrEmpty(newDistributor.platform_plan_id)">
                                <app-sgc-permissions [distributorId]="newDistributor.id" (closeUpdateModal)="closeModal()"></app-sgc-permissions>
                            </mat-tab>
                            <mat-tab label="Permisos otorgables" *ngIf="isUpdate && !validationService.isNullOrEmpty(newDistributor.platform_plan_id)">
                                <app-assignable-module-operations [distributorId]="newDistributor.id" (closeUpdateModal)="closeModal()"></app-assignable-module-operations>
                            </mat-tab>
                          </mat-tab-group>
                    </div>
                </div>
            </mat-tab>
        </mat-tab-group>
        <div class="form-box__content-actions">
            <button type="submit" class="btn btn-primary"> {{ isUpdate ? 'Actualizar' : 'Registrar' }} </button>
            <button class="btn btn-secondary"  (click)="closeModal()">cancelar</button>
        </div>
        </div>
    </form>
  </section>

<app-user-notes *ngIf="userNotesModal" [userId]="userIdToAddNote" (close)="closeNotesView()"></app-user-notes>
<div class="loader" *ngIf="loading">
    <img src="../../../../assets/loader.svg">
</div>