<section class="form-box-container animate__animated animate__fadeIn" *ngIf="viewsAvailable['device_checklist_management'].show_view">
    <section class="form-box-container__header">
        <!--<span class="form-box-container__header-back" (click)="back()"><span class="description">Volver</span> </span>-->
        <h2 class="form-box-container__header-title"><span class="icon"><i class="fa-solid fa-list-check"></i></span>Gestión de Checklist</h2>
        <div class="form-box-container__header-options">
            <button class="btn btn-primary"  matTooltip="Nueva lista de verificación" (click)="openChecklistEditForm()"> <i class="fa-solid fa-circle-plus"></i><span class="description"> Nuevo</span></button>
            <button 
            (click)="viewDeviceChecklistAnswers()"
            class="btn btn-outline-secondary"  
            matTooltip="Ver el historial general de respuestas"> <i class="fa-solid fa-folder-open"></i><span class="description"> Historial</span></button>
        </div>
        
    </section>
    <div class="form-box animate__animated animate__fadeIn">
        <div class="form-box__content form-box__content-h-auto">
            <div class="form-box__content-inputs mt-2" *ngIf="checklistList.length>0">
                <div class="form-box__content-group-fields">
                        <mat-card *ngFor="let checklist of checklistList"  class="card">
                            <mat-card-title-group>
                                <mat-card-title>{{checklist.name}}</mat-card-title>
                                <mat-card-subtitle> {{(validationService.isNullOrEmpty(checklist.updated_at)?("Registrado el "):("Actualizado el ")) + ((validationService.isNullOrEmpty(checklist.updated_at)?checklist.created_at:checklist.updated_at) | date:"d/M/Y, h:mm a")}}</mat-card-subtitle>
                                <img mat-card-sm-image src="../../../../../assets/img/checklist.svg" alt="imagen de checklist" >
                            </mat-card-title-group>
                            <mat-card-content>
                                {{checklist.description}}
                            </mat-card-content>
                            <mat-card-actions align="end" class="card__actions">
                                <mat-slide-toggle  color="primary" [(ngModel)]="checklist.available" (change)="changeAvailable($event,checklist)">Disponible</mat-slide-toggle>
                                <div>
                                    <button mat-icon-button mat-button (click)="deleteCheckList(checklist)"><mat-icon class="text-danger">delete</mat-icon></button>
                                    <button mat-icon-button mat-button matTooltip="Ver la lista de respuestas dadas al checklist" (click)="viewDeviceChecklistAnswers(checklist)"><mat-icon class="text-info">info</mat-icon></button>
                                    <button mat-icon-button mat-button (click)="openChecklistEditForm(checklist)" matTooltip="Editar checklist"><mat-icon class="text-primary">edit_document</mat-icon> </button>
                                </div>
                                </mat-card-actions>
                        </mat-card>
                </div>
            </div>
            <div class="form-box__content-empty" *ngIf="checklistList.length==0">
                <img src="../../../../../assets/img/empty-checklist.svg">
                <span class="form-box__empty-label">Sin datos</span>
            </div>
        </div>
    </div>
    <div class="loader" *ngIf="loading">
        <img src="../../../../../assets/loader.svg">
    </div>
</section>
<app-device-checklist-form [checklist]="checklistListSelected" (closeForm)="closeForm($event)"  *ngIf="viewsAvailable['device_checklist_form'].show_view"></app-device-checklist-form>
<app-device-checklist-answered-history 
[searchByField]="searchFieldForChecklistHistory"
[fieldValue]="searchFieldValueForChecklistHistory"
(close)="utils.setSelectedView(this.viewsAvailable,this.viewsAvailable['device_checklist_management'].name)"
*ngIf="viewsAvailable['device_checklist_answered_history'].show_view"></app-device-checklist-answered-history>