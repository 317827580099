import { UtilsService } from 'src/app/core/utils/utils.service';
import { ApiService } from 'src/app/core/api/api.service';
import { DataService } from 'src/app/core/data/data.service';
import { Injectable } from '@angular/core';
@Injectable({
    providedIn: 'root'
  })
export class PaymentNotification{
    user:any={};
    constructor(
        private api:ApiService,
        private dataService:DataService,
        private utils:UtilsService
    ){
        this.user=this.dataService.getData("user");
    }
  //metodos para realizar el seguimiento de si ya se actualizaron los datos de la factura
  supportsNotifications():boolean{
    return ("Notification" in window);
  }
  spawnNotification(title:string,options:any):Notification{
      const notification = new Notification(title,
          {
              body:options.body,
              icon:"../../../favicon.ico"
          });
      return notification;
  }
  registerServiceWorker(){
    navigator.serviceWorker.register('/assets/js/sw.js').then(function(swReg) {
    })
    .catch(function(error:any) {
    });
  }
  spawnNotificationInMobileDevice(title:string,options:any){
    navigator.serviceWorker.getRegistrations().then(function(registrations) {
      registrations[0].showNotification(title, 
        {
          body:options.message,
          icon:"../../../favicon.ico",
          //vibrate: [200, 100, 200, 100, 200, 100, 200]
      });
    }).catch((error)=>{console.log("serverworker",error);});
    /*navigator.serviceWorker.ready.then((registration) => {
      console.log("registration ",registration);
        registration.showNotification(title, {
        body: options.message,
        icon: "../../../favicon.ico"
        //vibrate: [200, 100, 200, 100, 200, 100, 200],
        //tag: "payment-notification",
        });
        
    });*/
  }
  requestInvoiceStatus(data:any){
    /*
    {
        reference:referencia de a factura,
        payment_option:paypal u otro medio,
        setIntervalId:identificador del intervalo que se ejecuta,
        action:"checkInvoiceStatus"
    }
    */
    data["action"]="checkInvoiceStatus";
    data["user_api_hash"]=this.user.hash;
    return new Promise((resolve,reject)=>{
        this.api.getDataPost("invoice",data,true).then( (data: any) => {
            console.log("datos de estado de factura",data);
            if(data.status==1){
              resolve ({status:true,details:data.invoice.details});
            }
            reject ({status:false});
          }).catch((error:any)=>{console.log("error",error); reject({status:false})}); 
          
    });
  }

  validateInvoiceStatus(data:any){
    let invoiceToConsultStatus:any = this.dataService.getData("checkStatusPayment");
    invoiceToConsultStatus["order_"+data.reference]=data;
    let dataToSend=invoiceToConsultStatus["order_"+data.reference];
    
    let intervalId= setInterval(()=>{
      this.requestInvoiceStatus(dataToSend).then((response:any)=>{
        if(response.status){
            let dataTemp= this.dataService.getData("checkStatusPayment");
            let detailsQuantity = response.details.length;
            let title = (detailsQuantity>1?"Dispositivos Actualizados":"Dispositivo Actualizado");
            let message = "La fecha de expiración de "+(detailsQuantity>1?"sus dispositivos han":"su dispositivo ha")+" sido actualizada"+(detailsQuantity>1?"s":"");  
            if(!this.isAMobileBrowser()){
              if (document.visibilityState === "visible") {
                this.utils.showMsg(title,message);
              } else {
                this.spawnNotification(title,{body:message});                
              }
            }
            else{
              document.addEventListener("visibilitychange", () => {
                if (document.visibilityState === "visible") {
                  this.utils.showMsg(title,message);
                } else {
                  this.spawnNotificationInMobileDevice(title,{"message":message});
                }
              });
            }
            let invoicePaid= dataTemp["order_"+dataToSend.reference];
            if(Object.entries(dataTemp).length>0 && dataTemp["order_"+invoicePaid.reference].intervalId !=null){
              clearInterval(invoicePaid.intervalId);
              delete dataTemp["order_"+invoicePaid.reference];
              this.dataService.setData("checkStatusPayment",dataTemp);
          }
        }
      }).catch((error:any)=>{console.log(error);});
    },10*1000,dataToSend);
    invoiceToConsultStatus["order_"+data.reference]["invoiceStatus"]=false;
    invoiceToConsultStatus["order_"+data.reference]["intervalId"]=intervalId;
    this.dataService.setData("checkStatusPayment",invoiceToConsultStatus);
  }

  isAMobileBrowser(){
    let navegador = navigator.userAgent;
    if (navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/webOS/i) || navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPod/i) || navigator.userAgent.match(/BlackBerry/i) || navigator.userAgent.match(/Windows Phone/i)) {
        return true;
    }
    return false;
  }
}