<section class="form-box-container animate__animated animate__fadeIn">
    <section class="form-box-container__header">
        <h2 class="form-box-container__header-title"><span class="icon"><i class="fa-solid fa-route"></i></span>Productividad de flota</h2>
        <div class="form-box-container__header-options">
            <button class="btn btn-primary mat-datepicker overflow-hidden"  matTooltip="Filtrar por rango de fecha">
                <mat-datepicker-toggle style="display:inline-block; font-size: 0.5rem;opacity:0;position:absolute;inset:0;" matIconSuffix [for]="queryDateRange"></mat-datepicker-toggle>
                <i class="fa-solid fa-calendar-week fa-sm"></i> <span  class="description"> Filtrar</span>
                <mat-form-field class="animate__animated animate__fadeIn " style="width: 0;height: 0;position:absolute;opacity: 0;"  appearance="fill">
                    <mat-date-range-input  class="d-none"  disabled [formGroup]="dateRangeToConsult" [rangePicker]="queryDateRange">
                      <input formControlName="start"   matStartDate placeholder="yyyy-mm-dd">
                      <input formControlName="end" matEndDate placeholder="yyyy-mm-dd">
                    </mat-date-range-input>
                    
                    <mat-date-range-picker class="d-none" #queryDateRange disabled="false">
                    <mat-date-range-picker-actions>
                        <button class="btn btn-outline-secondary" style="margin:0.1rem;" mat-button matDateRangePickerCancel>Cancelar</button>
                        <button class="btn btn-primary" mat-raised-button color="primary" (click)="getDeviceList()"  matDateRangePickerApply>Aceptar</button>
                    </mat-date-range-picker-actions>
                    </mat-date-range-picker>
                </mat-form-field>
            </button>
            <button class="btn btn-outline-secondary" name="exportMenu" [disabled]="deviceList.length==0" [matTooltip]="deviceList.length == 0 ? 'Sin datos':'Exportar datos'" matTooltipPosition="below"
            [matMenuTriggerFor]="exportMenu"><i class="fa-solid fa-download fa-sm"></i><span  class="description"> Exportar</span></button>
            <mat-menu #exportMenu="matMenu" > 
                <!--<button class="text-primary" mat-menu-item (click)="exportFile('PDF')"><i class="fa-solid fa-file-pdf"></i> PDF</button>-->
                <button class="text-primary" mat-menu-item (click)="exportFile('CSV')"><i class="fa-solid fa-file-csv"></i> CSV</button>         
            </mat-menu>
        </div>
    </section>
    <div class="form-box two-panel two-panel--automatic-orientation animate__animated animate__fadeIn">
        <div class="form-box__content">
            <div class="w-100 overflow-auto">
                <table mat-table matSort (matSortChange)="sortData($event)" [dataSource]="sortedDeviceList">
                    <ng-container matColumnDef="device_date">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Ordenar por fecha"> Fecha </th>
                      <td mat-cell *matCellDef="let device"> {{device.device_date}} </td>
                    </ng-container>
                    <ng-container matColumnDef="plate_number">
                      <th mat-header-cell *matHeaderCellDef  mat-sort-header sortActionDescription="Ordenar por placa"> Placa </th>
                      <td mat-cell *matCellDef="let device"> 
                        {{validationService.isNullOrEmpty(device.plate_number)?device.name:device.plate_number}}
                        <!--<mat-icon aria-hidden="false" class="text-warning" aria-label="dispositivo no tiene definido un número de placa" fontIcon="info"></mat-icon>-->
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="distance">
                      <th mat-header-cell *matHeaderCellDef> Kilometraje </th>
                      <td mat-cell *matCellDef="let device"> {{device.distance | number:'1.0-2' }} </td>
                    </ng-container>
                    <ng-container matColumnDef="position">
                      <th mat-header-cell *matHeaderCellDef  mat-sort-header sortActionDescription="Ordenar por posición"> Posición </th>
                      <td mat-cell *matCellDef="let device"> {{device.position}} </td>
                    </ng-container>
                    <ng-container matColumnDef="footer">
                      <td mat-footer-cell *matFooterCellDef [colSpan]="displayedColumns.length"  class="text-small">
                        Filtro {{' desde '+(footerData.start_date | date:'mediumDate')+' a '+(footerData.end_date | date:'mediumDate')}}
                      </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    <tr mat-footer-row *matFooterRowDef="['footer'];sticky:true"></tr>
                    <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell empty py-1" [attr.colspan]="displayedColumns.length">Sin datos</td>
                    </tr>
                </table>
            </div>
              <mat-paginator [length]="lengthPage" [pageSize]="pageSize" [pageIndex]="currentPage"
              [pageSizeOptions]="pageSizeOptions" (page)="paginator($event)"
              aria-label="Selecciona pagina de la lista de dispositivos">
            </mat-paginator>
            <div class="loader" *ngIf="tableLoading">
              <img src="../../../../../assets/loader.svg">
          </div>
        </div>
        <div class="form-box__content order-mobile-first">
          <app-circular-chart [circularChartConfigData]="circularChartConfigData"></app-circular-chart>
      </div>
    </div>
    <div class="loader" *ngIf="generalLoading">
      <img src="../../../../../assets/loader.svg">
  </div>
</section>