
<section class="form-box-container animate__animated animate__fadeIn" *ngIf="viewsAvailable['device_list'].show_view" >
    <section class="form-box-container__header">
        <span class="form-box-container__header-back" (click)="close()"><span class="description">Volver</span> </span>
        <h2 class="form-box-container__header-title"><span class="icon"><i class="fa-solid fa-satellite"></i></span>Caserones OWL </h2>
        <div  class="form-box-container__header-options">                     
          <button (click)="updateDevicesSelect()" matToolTip="Actualizar selecciones" class="btn btn-primary"><i class="fa-solid fa-file-pen fa-sm"></i> <span class="description"> Actualizar</span></button>
          <button (click)="utils.setSelectedView(viewsAvailable,viewsAvailable['logs'].name)" matToolTip="Ver registros enviados a Caserones OWL" class="btn btn-outline-secondary"><i class="fa-solid fa-file-pen fa-sm"></i> <span class="description"> Registros</span></button>
          <div class="search">
            <input id="inputSearch" type="search" placeholder="Buscar" (input)="searchValueInData()"
              [(ngModel)]="searchDevice">
            <label for="inputsearch"><i class="fa-solid fa-magnifying-glass fa-sm"></i></label>
          </div>
        </div>
    </section>
    <div class="form-box animate__animated animate__fadeIn">
  
        <div class="form-box__content form-box__content-h-auto">
          <div class="form-box__content-header form-box__content-header-horizontal p-0 form-box__content-header-align-end">
            <mat-checkbox 
            matTooltip="Incluir dispositivos suscritos" 
            color="primary"
            class="scale-75"
            name="availabilityStatus"
            [value]="deviceSubscriptionStatus.subscribed+''"
            [checked]="isDeviceSubscriptionStatus(deviceSubscriptionStatus.subscribed)"
            (change)="changeDeviceSubscriptionFilter($event)"
            >Suscritos</mat-checkbox>
            <mat-checkbox 
            matTooltip="Incluir dispositivos por suscribir" 
            color="primary"
            class="scale-75"
            name="availabilityStatus"
            [value]="deviceSubscriptionStatus.not_subscribed+''"
            [checked]="isDeviceSubscriptionStatus(deviceSubscriptionStatus.not_subscribed)"
            (change)="changeDeviceSubscriptionFilter($event)"
            >Por suscribir</mat-checkbox>
          </div>
          <div class="w100 overflow-auto">
            <table mat-table matSort (matSortChange)="sortData($event)" [dataSource]="deviceList">
              <ng-container matColumnDef="select">
                <th mat-header-cell *matHeaderCellDef>
                  <mat-slide-toggle
                      color="primary"
                      (change)="$event ? toggleAllRows() : null"
                      [checked]="isAllSelected()"
                      [aria-label]="checkboxLabel()">
                  </mat-slide-toggle>
                </th>
                <td mat-cell *matCellDef="let row">
                  <mat-slide-toggle
                      [checked]="isSelectedDevice(row)"
                      color="primary"
                      class="z-index-last"
                      (click)="$event.stopPropagation()"
                      (change)="$event ? selection.toggle(row) : null;changeDeviceSelected($event,row)"
                      [aria-label]="checkboxLabel(row)">
                  </mat-slide-toggle>
                </td>
              </ng-container>
    
              <ng-container matColumnDef="activated">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Ordenar por activación"> Activado </th>
                <td mat-cell *matCellDef="let element" class="text-center">
                  <div class="status complete" *ngIf="!utils.isNullOrEmpty(element.activated) && element.activated==1" matTooltip="Activo en Caserones OWL" matTooltipPosition="right"><i class="fa-solid fa-circle-check"></i></div>
                  <div class="status incomplete" *ngIf="utils.isNullOrEmpty(element.activated) || element.activated!=1" matTooltip="No activo en Caserones OWL" matTooltipPosition="right"><i class="fa-solid fa-circle-xmark"></i></div>
                                  
                </td>
              </ng-container>
              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Ordenar por nombre"> Nombre </th>
                <td mat-cell *matCellDef="let element"> {{element.name}} </td>
              </ng-container>
              <ng-container matColumnDef="imei">
                <th mat-header-cell *matHeaderCellDef  mat-sort-header sortActionDescription="Ordenar por imei"> Imei </th>
                <td mat-cell *matCellDef="let element"> {{element.imei}} </td>
              </ng-container>
              <ng-container matColumnDef="plate_number">
                <th mat-header-cell *matHeaderCellDef  mat-sort-header sortActionDescription="Ordenar por placa"> Placa </th>
                <td mat-cell *matCellDef="let element"> {{element.plate_number}} </td>
              </ng-container>
              <ng-container matColumnDef="device_model">
                <th mat-header-cell *matHeaderCellDef  mat-sort-header sortActionDescription="Ordenar por GPS"> GPS </th>
                <td mat-cell *matCellDef="let element"> {{element.device_model}} </td>
              </ng-container>
    
              <ng-container matColumnDef="expiration_date">
                <th mat-header-cell *matHeaderCellDef  mat-sort-header sortActionDescription="Ordenar por fecha de expiración"> Fecha de expiración </th>
                <td mat-cell *matCellDef="let element"> {{element.expiration_date}} </td>
              </ng-container>
    
              <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selection.toggle(row)">
              </tr>
              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell empty py-1" [attr.colspan]="displayedColumns.length">Sin datos</td>
            </tr>
            </table>
          </div>
          <mat-paginator [length]="pagination.length_page" [pageSize]="pagination.page_size" [pageIndex]="pagination.current_page"
            [pageSizeOptions]="pagination.page_size_options" (page)="paginator($event)"
            aria-label="Selecciona pagina de la lista de dispositivos">
          </mat-paginator>
        </div>
    </div>
    <div class="loader" *ngIf="loading">
        <img src="../../../../../assets/loader.svg">
    </div>
  </section>
    
<ng-container *ngIf="viewsAvailable['logs'].show_view">
    <app-caserones-owl-logs-web-service (closeModal)="utils.setSelectedView(viewsAvailable,viewsAvailable['device_list'].name)"></app-caserones-owl-logs-web-service>
</ng-container>