import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/core/data/data.service';
import { UtilsService } from 'src/app/core/utils/utils.service';
import { Router } from '@angular/router';

import {MatDialog} from '@angular/material/dialog';


@Component({
  selector: 'app-my-portfolio',
  templateUrl: './my-portfolio.component.html',
  styleUrls: ['./my-portfolio.component.scss']
})

export class MyPortfolioComponent implements OnInit{
  user:any={};
  moduleId:number=7;
  permissionsDataSgc:any=[];//permisos sobre el modulo
  constructor(
    private dataService:DataService,
    private utils:UtilsService,
    private router:Router,
    public matDialog: MatDialog
  ){}
  ngOnInit(): void {
    this.dataService.checkPermissionModule(this.moduleId).then((permissions: any) => {
      this.permissionsDataSgc = permissions;
    }).catch(() => {
      this.utils.showMsg("Página no autorizada","No tiene permisos para ver esta página, contacte al administrador");
      this.router.navigate(['/']);
    });
  }
}