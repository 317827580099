import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from 'src/app/core/data/data.service';
import { UtilsService } from 'src/app/core/utils/utils.service';

@Component({
  selector: 'app-fleet-control',
  templateUrl: './fleet-control.component.html',
  styleUrls: ['./fleet-control.component.scss']
})
export class FleetControlComponent {

  moduleId:number=16;
  permissionsDataSgc:any=[];//permisos sobre el modulo
  permissionsDataIntgps:any=[]//permisos INTGPS
  userRole:any={};
  constructor(
    public dataService: DataService,
    public utils:UtilsService,
    private route:Router) {}

  ngOnInit(): void {
    this.dataService.checkPermissionModule(this.moduleId).then((permissions: any) => {
      this.permissionsDataSgc = permissions;
      this.userRole = this.dataService.getData("user").role_id;
      console.log( this.dataService.getData("user"));
    }).catch(() => {
      this.utils.showMsg("Página no autorizada","No tiene permisos para ver esta página, contacte al administrador");
      this.route.navigate(['/']);
    });
  }
}
