import { Component, Input, Output, OnInit, ChangeDetectorRef, EventEmitter } from '@angular/core';
import { ApiService } from 'src/app/core/api/api.service';
import { DataService } from 'src/app/core/data/data.service';
import { UtilsService } from 'src/app/core/utils/utils.service';
import { ValidationService } from '../../../core/validation/validation.service';
import { IDeviceGeneral, IDeviceIntgps, IDeviceModel, IDevicePersonalizedPlan, IDeviceSgc } from '../../models/interfaces/idevice.model';
import { ObjectInitializationService } from 'src/app/core/object-initialization/object-initialization.service';
import { IUserSessionData } from '../../models/interfaces/iuser-session-data.model';
import { IApiRequestData } from '../../models/interfaces/iapi-request-data.model';
import { IUserContactInfo } from '../../models/interfaces/user-data.model';
import { MatDatepicker } from '@angular/material/datepicker';
import { IViewsAvailable } from '../../models/interfaces/views-available';
import { IBillingFrequency, IMonetaryunit, ITelephoneOperator, IVehicleType } from '../../models/interfaces/iutils-data.model';

export const vehicleLoadCapacityInTons: number[] = [1, 2, 3, 4, 6, 10, 15, 20, 34];
@Component({
  selector: 'app-device-form',
  templateUrl: './device-form.component.html',
  styleUrls: ['./device-form.component.scss']
})
export class DeviceFormComponent implements OnInit {
  configFirstBillingDate: { min_value: Date | null, value: Date | null } = { min_value: null, value: null };

  loading: boolean = false;//para loading
  user: IUserSessionData;

  viewsAvailable: IViewsAvailable = {
    form:{name:"form",show_view:true},
    device_notes:{name:"device_notes",show_view:false}
  };
  
  @Output() close = new EventEmitter<{ close: boolean, updated: boolean }>();
  modifiedData: boolean = false;//indica cuando suceda una actualizacion o creacion de dispositivo
  @Input() isUpdateDevice: boolean = false;//indica si es para actualizar dispostivo. usado en el modal
  formModal: boolean = true;
  deviceUsers: IUserContactInfo[] = [];//almacena la lista de usuarios vinculados al dispositivo 

  emailToSearch: string = "";//email del usuario a buscar
  @Input() usersList: IUserContactInfo[] = [];//para la lista de usuarios, con email y id
  
  usersListFilter: IUserContactInfo[] = [];
  responsableEmailToSearch: string = "";
  
  potentialResponsiblesListFilter: IUserContactInfo[] = [];
  potentialResponsiblesList: IUserContactInfo[] = [];//para la lista de usuarios posibles responsables, con email y id

  @Input() newDevice: IDeviceGeneral;//almacena los datos del dispositivo al que se va a realizar la actualizacion de datos

  vehicleTypeList: IVehicleType[] = [];
  telephoneOperatorsList: ITelephoneOperator[] = [];
  deviceModelList: IDeviceModel[] = [];
  monetaryUnitsList: IMonetaryunit[] = [];
  billingFrequenciesList: IBillingFrequency[] = [];

  vehicleLoadCapacityInTons: number[] = [];// lista predefinida de valores de capacidad de carga de vehiculos
  filteredvehicleLoadCapacityInTons: number[] = [];
  maxVehicleLoadCapacityInTons: number = 999;

  deviceIdToAddNote!: number; 

  constructor(
    private api: ApiService,
    public validationService: ValidationService,
    public dataService: DataService,
    public utils: UtilsService,
    private cdRef: ChangeDetectorRef,
    private objectInitializationService: ObjectInitializationService,
  ) {
    this.user = this.dataService.getData("user");
    this.newDevice = this.objectInitializationService.initializeIDeviceGeneral();
    this.vehicleLoadCapacityInTons = vehicleLoadCapacityInTons;
    this.filteredvehicleLoadCapacityInTons = vehicleLoadCapacityInTons;
  }
  ngOnInit(): void {
    this.dataService.fillDevicesModel(this.deviceModelList);
    this.dataService.fillTelephoneOperators(this.telephoneOperatorsList);
    this.dataService.fillMonetaryunits(this.monetaryUnitsList);
    this.dataService.fillBillingFrequencies(this.billingFrequenciesList);
    this.dataService.fillVehicleTypes(this.vehicleTypeList);

    this.usersListFilter = this.utils.copyObject(this.usersList);

    let nextMonthDate = new Date();
    nextMonthDate.setDate(1);
    nextMonthDate.setMonth(nextMonthDate.getMonth() + 1);
    if (this.isUpdateDevice) {
      this.deviceIdToAddNote = this.newDevice.id;
      this.newDevice = JSON.parse(JSON.stringify(this.newDevice)) as IDeviceGeneral;
      if (Array.isArray(this.newDevice.users)) {
        this.deviceUsers = this.newDevice.users.slice();
      }
      else {
        let items: IUserContactInfo[] = Object.values(this.newDevice.users) as IUserContactInfo[];
        for (let item of items) {
          this.deviceUsers.push(item);
        }
      }
      this.potentialResponsiblesList = this.deviceUsers.filter((potentialResponsible: any) => potentialResponsible.id != this.user.id && this.usersList.some((user: any) => user.id == potentialResponsible.id));
      this.potentialResponsiblesListFilter = this.utils.copyObject(this.potentialResponsiblesList);
      this.cdRef.detectChanges();
      let firstBillingDate = this.newDevice.personalized_plan?.first_billing_date;

      this.configFirstBillingDate.value = (firstBillingDate != null && firstBillingDate != "") ? (new Date(this.newDevice.personalized_plan!.first_billing_date! + " 00:00:00")) : nextMonthDate;
    
      if(typeof this.newDevice.warranty_date == "string" && this.newDevice.warranty_date != "" )
        this.newDevice.warranty_date = new Date(this.newDevice.warranty_date + " 00:00:00");
    } else {
      this.deviceUsers = [];
      this.deviceUsers.push({ id: this.user.id, email: this.user.email });
      this.potentialResponsiblesList = this.deviceUsers.filter((user: any) => user.id != this.user.id);
      this.potentialResponsiblesListFilter = this.potentialResponsiblesList;
      this.cdRef.detectChanges();
      this.configFirstBillingDate.value = nextMonthDate;
    }
    this.configFirstBillingDate.min_value = new Date(); 
  }
  clearUserSearchFilter(){
    this.emailToSearch = "";
    this.searchUserEmail();
  }
  isAValidPositiveNumber(event: any): boolean {
    return (this.validationService.isOnlyNumbers(event.key) || (event.key == "." && !this.validationService.isNullOrEmpty(this.newDevice.payload_capacity_in_tons) && !this.newDevice.payload_capacity_in_tons!.toString().includes(".")))
  }
  adminDevice($event: any, isUpdate: boolean): void {
    $event.preventDefault();
    let deviceSgcToSend: any = this.objectInitializationService.initializeIDeviceSgc();
    let error: string = "";
    let formDataIntgps: any = new FormData($event.target);

    let fieldsToSend: (keyof IDeviceIntgps)[] = ["icon_id", "object_owner","group_id","timezone_id","imei","name","sim_number","device_model","plate_number","fuel_measurement_id","tail_length", "min_moving_speed", "min_fuel_fillings", "min_fuel_thefts", "fuel_quantity", "fuel_price", "group_id", "vin", "registration_number", "additional_notes"];
    for (let field of fieldsToSend) {
      if(this.newDevice[field] !== null && typeof this.newDevice[field] != "undefined")
        formDataIntgps.set(field, this.newDevice[field]);
    }
    //requeridos para actualizar o registrar
    formDataIntgps.append("user_api_hash", this.user.hash);//envio el hash del distribuidor
    formDataIntgps.append("lang", "en");
    /*** elimina campos que no son parte de la estrcutura de datos de INTGPS */
    let dataToNoSend: string[] = ["vehicle_type", "vehicle_brand", "cylinder_capacity", "vehicle_color", "owner_phone", "search_email"];
    for (let field of dataToNoSend) {
      formDataIntgps.delete(field);
    }
    let fieldIDeviceSgc: (keyof IDeviceSgc)[] = ["id", "cylinder_capacity", "owner_phone", "payload_capacity_in_tons","trailer_plate_number", "personalized_plan", "personalized_plan_id", "plan_type", "responsible_user", "telephone_operator_id", "vehicle_brand", "vehicle_color", "vehicle_model", "vehicle_type", "warranty_date"];
    let field: keyof IDeviceSgc;
    for (field of fieldIDeviceSgc) {
      let value: string | number | IDevicePersonalizedPlan | IUserContactInfo | null | undefined | Date = this.newDevice[field];
      if (typeof deviceSgcToSend[field] != "undefined")
        deviceSgcToSend[field] = value as string;
    }
    let dataSgcToSend: Partial<IDeviceSgc> & Partial<IApiRequestData> & { user_id?: number, user_email?: string, device_group_id?: number, user_to_associate?: any } = deviceSgcToSend as Partial<IDeviceSgc>;
    dataSgcToSend.device_group_id = this.newDevice.group_id;//el grupo al que pertenece el dispositivo para el usuario distribuidor
    dataSgcToSend.user_to_associate = this.deviceUsers;//arreglo con las asociaciones de dispositivos y usuarios    
    dataSgcToSend.user_api_hash = this.user.hash;
    dataSgcToSend.user_id = this.user.id;
    dataSgcToSend.user_email = this.user.email;
    dataSgcToSend.plan_type ="personalized";

    if(dataSgcToSend.warranty_date != null && (dataSgcToSend.warranty_date instanceof Date))
      dataSgcToSend.warranty_date = this.utils.getDatetime(dataSgcToSend.warranty_date);
    
    dataSgcToSend.personalized_plan!.disabled = typeof dataSgcToSend.personalized_plan!.disabled =="boolean" ? (dataSgcToSend.personalized_plan!.disabled ? 1 : 0) : dataSgcToSend.personalized_plan!.disabled; 
    let firstBillingDate = this.configFirstBillingDate.value;
    dataSgcToSend.personalized_plan!.first_billing_date = firstBillingDate?.getFullYear() + "-" + this.utils.evaluateDateNumber((firstBillingDate?.getMonth() ?? 0) + 1) + "-01";

    if (formDataIntgps.get("imei").trim() == "") 
      error = "El número de imei es obligatorio";
    else if (formDataIntgps.get("name").trim() == "") 
      error = "El nombre del dispositivo es obligatorio";
    else if (!this.validationService.isNullOrEmpty(dataSgcToSend.payload_capacity_in_tons) && Number(dataSgcToSend.payload_capacity_in_tons) > this.maxVehicleLoadCapacityInTons)
      error = "La capacidad de carga indicada es superior a " + this.maxVehicleLoadCapacityInTons + ", Revisa el valor y ajustalo";
    else 
      error = this.validateBillingPlanFields(dataSgcToSend.personalized_plan);
    if (error != "") {
      this.utils.showResultRequest("error",error);
      return;
    }
    this.loading = true;
    if (isUpdate) {
      formDataIntgps.append("device_id", this.newDevice.id);

      dataSgcToSend.id = this.newDevice.id;//el id del dispositivo
      dataSgcToSend.action = "edit";

      formDataIntgps.set("icon_moving", this.newDevice.icon_colors.moving);
      formDataIntgps.set("icon_stopped", this.newDevice.icon_colors.stopped);
      formDataIntgps.set("icon_offline", this.newDevice.icon_colors.offline);
      formDataIntgps.set("icon_engine", this.newDevice.icon_colors.engine);
      formDataIntgps.set("group_id", this.newDevice.group_id);
      this.api.createData(formDataIntgps, "api/edit_device").then((data: any) => {
        if (data.status == 1) {

          this.api.createData(dataSgcToSend, "device", true).then((response: unknown) => {
            if(this.validationService.isResponseApi(response)){
              if (response.status == 1) {
                this.modifiedData = true;
                this.closeModal();
              }
              this.utils.showResultRequest(response.status == 1 ? "success" : "error", response.status == 1 ? "Datos actualizados con éxito" : "No se pudo actualizar los datos en SGC");
            }
          }).catch((error:unknown) => {
            this.utils.showResultRequest("error","Información",this.api.getDefaultMessage("el dispositivo",true, false,"POST"));        
          }).finally(() => {this.utils.hideLoading(()=>this.loading = false);});
          //fin registro en nuestra base de datos SGC
        } else{
          this.utils.showResultRequest("error","Información","No se pudo actualizar los datos");        
          this.utils.hideLoading(()=>this.loading = false);
        } 
      }).catch((err: any) => {
        let message = "Hubo problemas al actualizar los datos";
        if (typeof err.error.message != "undefined")
          message = err.error.message;
        this.utils.showMsg("", message);
        this.utils.hideLoading(()=>this.loading = false);
      });
    } else { 
      formDataIntgps.set("icon_id", 0);
      formDataIntgps.set("tail_length", 5);
      formDataIntgps.set("min_moving_speed", 6);
      formDataIntgps.set("min_fuel_fillings", 10);
      formDataIntgps.set("min_fuel_thefts", 10);
      formDataIntgps.set("fuel_measurement_id", 1);//opcion de l/100km
      this.api.createData(formDataIntgps, "api/add_device", false, this.api.CONTENT_TYPE.FORM_DATA).then((dataIntgps: any) => {
        if (dataIntgps.status == 1) {
          dataSgcToSend.action = "add";
          dataSgcToSend.id = dataIntgps.id;//el id del dispositivo
          this.api.createData(dataSgcToSend, "device", true).then((response: unknown) => {
            if(this.validationService.isResponseApi(response)){
              if (response.status == 1) {
                this.modifiedData = true;
                this.closeModal();
              }
              this.utils.showResultRequest(response.status == 1 ? "success" : "error", response.status == 1 ? "Dispositivo agregado con éxito" : "No se pudo registrar el dispositivo en SGC");
            }
          }).catch((error:any)=>{console.log(error)}).finally(() => {this.utils.hideLoading(()=>this.loading = false);});
          //fin registro en nuestra base de datos SGC
        } else 
          this.utils.showResultRequest("error","Información",this.api.getDefaultMessage("el dispositivo",false, false,"POST"));        
      }).catch((err: any) => {
        let message = "Se ha presentado un error al crear el dispositivo<span class='text-small'>Verifique los datos enviados. En caso de volver a presentar fallas comuniquese con el administrador del sitio</span>";
        if (typeof err.error.message != "undefined") {
          message = "<small>" + err.error.message + "</small>";
        }
        this.utils.showResultRequest("error", message);
      }).finally(() => {this.utils.hideLoading(()=>this.loading = false);});
    }
  }
  validateBillingPlanFields(personalized_plan: any) {
    let fields = [{ name: "unit_price", label: "precio" }, { name: "monetary_unit_id", label: "unidad monetaria" }, { name: "billing_frequency_id", label: "Frecuencia de pago" }, { name: "payday", label: "Dia de pago" }];
    let message = "";
    if (fields.some((field: any) => !this.utils.isNullOrEmpty(personalized_plan[field.name]))) {
      let r = fields.some((field: any) => { return this.utils.isNullOrEmpty(personalized_plan[field.name]) ? (message = "Indique un valor para el campo <b>'" + field.label + "'</b>") : ""; });
      return message;
    }
    return "";
  }
  printDeviceUsers() {
    let text = this.deviceUsers[0].email;
    for (let index = 1; index < this.deviceUsers.length; index++) {
      text += ", " + this.deviceUsers[index].email;
    }
    return text;
  }
  compareDeviceUsersSelected(user: any, userSelected: any) {
    return (user !== null && typeof user != "undefined"  && typeof user.id != "undefined") && (userSelected !== null && typeof userSelected != "undefined" && typeof userSelected.id != "undefined") && user.id == userSelected.id;
  }
  assignResponsibleList(event: any) {
    this.potentialResponsiblesListFilter = this.utils.copyObject(event.value.filter((user: any) => user.id != this.user.id));
    this.potentialResponsiblesList = this.utils.copyObject(this.potentialResponsiblesListFilter);
  }
  searchUserEmail() {
    this.emailToSearch = this.emailToSearch.toLowerCase();
    if (this.emailToSearch.length > 0)
      this.usersListFilter = this.usersList.filter((user: any) => user.email.toLowerCase().includes(this.emailToSearch));
    else 
      this.usersListFilter = this.utils.copyObject(this.usersList);
  }
  filterVehicleLoadCapacityInTons() {
    this.filteredvehicleLoadCapacityInTons = this.validationService.isNullOrEmpty(this.newDevice.payload_capacity_in_tons) ? vehicleLoadCapacityInTons : this.vehicleLoadCapacityInTons.filter((item: number) => item.toString().includes(this.newDevice.payload_capacity_in_tons?.toString() ?? ""));
  }
  selectResponsibleUser(checked: any) {
    let user = this.potentialResponsiblesListFilter.find((user: any) => user.id == checked.value);
    if(typeof user != "undefined"){
      this.newDevice.responsible_user!.id = user.id;
      this.newDevice.responsible_user!.email = user.email;
    }
  }
  clearSearchResponsibleEmail(){
    this.responsableEmailToSearch = "";
    this.searchResponsibleEmail();  
  }
  searchResponsibleEmail() {
    this.responsableEmailToSearch = this.responsableEmailToSearch.toLowerCase()??"";
    if (this.responsableEmailToSearch.length > 0)
      this.potentialResponsiblesListFilter = this.potentialResponsiblesList.filter((user: IUserContactInfo) => user.email.toLowerCase().includes(this.responsableEmailToSearch));
    else 
      this.potentialResponsiblesListFilter = this.utils.copyObject(this.potentialResponsiblesList) as IUserContactInfo[];
  }
  closeModal() {
    this.deviceUsers = [];
    this.deviceIdToAddNote = 0;
    this.close.emit({ close: true, updated: this.modifiedData });
  }
  setFirstBillingMonth(date: Date, datepicker: MatDatepicker<any>) {
    this.configFirstBillingDate.value = date;
    datepicker.close();
  }
}