<section class="form-box-container animate__animated animate__fadeIn">
    
    <section class="form-box-container__header">
        <span class="form-box-container__header-back" (click)="closeComponent()"><span class="description">Volver</span> </span>
        <h2 class="form-box-container__header-title"><span class="icon"><i class="fa-solid fa-microchip"></i></span>Notas de usuario</h2>
        <div class="form-box-container__header-options">
            <button class="btn btn-primary" (click)="addNoteToUser()"><i class="fa-solid fa-notes-medical icon"></i> <span class="description"> Agregar</span></button>
        </div>
    </section>
    <div class="form-box animate__animated animate__fadeInRight">
        <div  class="form-box__content form-box__content-h-auto">
            <div class="w-100 overflow-auto">
                <table mat-table  matSort [dataSource]="userNotes">
                    <ng-container matColumnDef="creation_date" >
                      <th mat-header-cell *matHeaderCellDef> Fecha </th>
                      <td mat-cell *matCellDef="let element"> {{element.creation_date | date:"medium"}} </td>
                  </ng-container>
                    <ng-container matColumnDef="description" >
                        <th mat-header-cell *matHeaderCellDef > descripción </th>
                        <td mat-cell *matCellDef="let element"> {{element.description}} </td>
                    </ng-container>
                    <ng-container matColumnDef="actions">
                      <th mat-header-cell *matHeaderCellDef class="mat-header-cell-center"> Acciones </th>
                      <td mat-cell *matCellDef="let element" >
                          <div class="actions-container">
                            <button matTooltipPosition="left" matTooltip="Eliminar nota" (click)="deleteUserNote(element.id)"><i
                                class="fa-solid fa-trash-can fa-sm"></i></button>
                          </div>
                      </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell empty py-1" [attr.colspan]="displayedColumns.length">Sin datos</td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</section>
<div class="loader" *ngIf="loading">
    <img src="../../../../assets/loader.svg">
</div>