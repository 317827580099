import { Injectable } from '@angular/core';
import { IAboutUsFileToUpload, ICommonFileStructure, IHomeFileToUpload, IWebTemplate, IWebsiteFile } from "../models/interfaces/iweb-template.model";
import { IWebTemplateEditMenu } from '../models/interfaces/iweb-template-edit-menu.model';
import { WebTemplatePageIcons, WebTemplatePageLabels, WebTemplatePages } from '../website.types';

@Injectable({
  providedIn: 'root'
})
export class WebsiteInitializationService {

  constructor() { }
  initializeWebsite(userId:number):IWebTemplate{
    return {
      id:null,
      user_id:userId,
      created_at:"",
      updated_at:"",
      status:1,
      custom_css:"",
      custom_js:"",
      color_palette:{
        primary_color:"#ffffff",
        dark_primary_color:"",
        light_primary_color:"",
        contrast_primary_color:"",
    
        secondary_color:"#423f40",
        dark_secondary_color:"",
        light_secondary_color:"",
        contrast_secondary_color:"",
    
        accent_color:"#44aa44",
        dark_accent_color:"",
        light_accent_color:"",
        contrast_accent_color:"",
    
        title_color:"#2a2929",
        text_color:"#2a2929",
      },
      typography:{
        title:"Roboto",
        paragraph:"Roboto"
      },
      metadata:{
        title:"",
        description:"",
        robots:"",
        author:"",
        copyright:"",
        social_media:{
          title:"",
          description:"",
          site_name:""
        },
        google_site_verification:"",
        ga_measurement_id:"",
        keywords:""
      },
      url_identifier:"",
      design_versions:{
        global:{
          nav:"nav-v1",
          footer:"footer-v1"
        },
        home:null,
        about_us:null,
        our_services:null,
        contact_us:null,
      },
      global_image_filenames:{
        favicon:"",
        logo:""
      },
      home:{
        top:{
          title:"",
          description:"",
          btn_cta:""
        },
        middle:{
          title:"",
          subtitle:"",
          content:""
        },
        bottom:{
          title:"",
          subtitle:"",
          content:""
        },
        image_filename:{
          top_section_bg_image:"",
          top_section_presentation_image:""
        }
      },
      about_us:{
        title:"",
        description:"",
        btn_cta:"",
        corporate_identity:{
            mission:{
              title:"",
              description:"",
              action_button:""
            },
            vision:{
              title:"",
              description:"",
              action_button:""
            },
            values:{
              title:"",
              description:"",
              action_button:""
            }
        },
        image_filename:{
          top_section_bg_image:"",
          corporate_identity_image_mission:"",
          corporate_identity_image_vision:"",
          corporate_identity_image_values:""
        }
      },
      our_services:{
        title:"",
        description:"",
        btn_cta:"",
        important_data_content:"",
        our_services_content:{
          about_our_services:"", 
          more_of_our_services:""
        },
        image_filename:{
          top_section_bg_image:""
        }
      },
      contact_us:{
        title:"",
        description:"",
        additional_Information:{
            title:"",
            description:""
        },
        office_hours:{
            title:"",
            content:{
              week:{
                title:"",
                content:""
              },
              sunday:{
                title:"",
                content:""
              },
              saturday:{
                title:"",
                content:""
              }
            }
        },
        map_embed_html:"",
        image_filename:{
          top_section_bg_image:""
        }
      },
    };
  }
  initializeICommonFileStructure():ICommonFileStructure{
    return {
      top_section_bg_image:null,
      temp_top_section_bg_image:"",
      delete_top_section_bg_image:false
    };
  }
  initializeFileToUpload():IWebsiteFile{
    return {
      global:{
        favicon:null,
        temp_favicon:"",
        delete_favicon:false,
        logo:null,
        temp_logo:"",
        delete_logo:false
      },
      pages:{
        home:{...this.initializeICommonFileStructure(),...this.initializeIHomeFileToUpload()},
        about_us:{...this.initializeICommonFileStructure(),...this.initializeIAboutUsFileToUpload()},
        our_services:this.initializeICommonFileStructure(),
        contact_us:this.initializeICommonFileStructure()
      }
    }
  }
  initializeIHomeFileToUpload():IHomeFileToUpload{
    return{
      top_section_presentation_image:null,
      temp_top_section_presentation_image:"",
      delete_top_section_presentation_image:false
    };
  }
  initializeIAboutUsFileToUpload():IAboutUsFileToUpload{
    return {
      corporate_identity_image_mission:null,
      temp_corporate_identity_image_mission:"",
      delete_corporate_identity_image_mission:false,
      
      corporate_identity_image_vision:null,
      temp_corporate_identity_image_vision:"",
      delete_corporate_identity_image_vision:false,
      
      corporate_identity_image_values:null,
      temp_corporate_identity_image_values:"",
      delete_corporate_identity_image_values:false
    };
  }
  initializeIWebTemplateEditMenu():IWebTemplateEditMenu[]{
    let menuItems:WebTemplatePages[] = ["global","home","about_us","our_services","contact_us"];
    let menuItemLabels:WebTemplatePageLabels =  {
        contact_us: "Contactanos",
        our_services: "Nuestros servicios",
        about_us: "¿Quiénes somos?",
        home: "Inicio",
        global: "Global",
    };
    let menuItemIcons:WebTemplatePageIcons =  {
      contact_us: "fa-phone-volume",
      our_services: "fa-people-carry-box",
      about_us: "fa-people-roof",
      home: "fa-house",
      global: "fa-sitemap",
  };
    let pageSection = {
      global:[
        {
          id:"",
          parent:"",
          label:"Pie de página",
          name:"footer",
          versions:[{"label":"Versión 1","value":"footer-v1"},{"label":"Versión 2","value":"footer-v2"},{"label":"Versión 3","value":"footer-v3"}],
        },
        { 
          id:"",
          parent:"",
          label:"Menu de navegación",
          name:"nav",
          versions:[{"label":"Versión 1","value":"nav-v1"},{"label":"Versión 2","value":"nav-v2"},{"label":"Versión 3","value":"nav-v3"}],
        }
      ],
      contact_us:[],
      our_services:[],
      about_us:[],
      home:[],
    };
    let menu:IWebTemplateEditMenu[] = [];
    for(let item in menuItems){
      let itemIncreased:string = String(parseInt(item)+1);  
      let obj:IWebTemplateEditMenu = {
        id:itemIncreased,
        name: menuItems[item],
        icon: menuItemIcons[menuItems[item]],
        label:menuItemLabels[menuItems[item]],
        sections:[],
      };
      let sections = pageSection[menuItems[item]];
      for(let sectionIndex in sections){
        sections[sectionIndex].parent = itemIncreased;
        sections[sectionIndex].id = itemIncreased+"_"+(parseInt(sectionIndex)+1); 
      }
      obj.sections = sections;
      menu.push(obj);
    }
    return menu;
    
  }
}