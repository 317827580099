import { Component, OnInit,Inject } from '@angular/core';
import { ApiService } from 'src/app/core/api/api.service';
import { DataService } from 'src/app/core/data/data.service';
import { UtilsService } from 'src/app/core/utils/utils.service';
import { MatDialog,MAT_DIALOG_DATA } from "@angular/material/dialog";

import Swal from 'sweetalert2';
import { Router } from '@angular/router';

@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss']
})
export class CommentsComponent implements OnInit {
constructor(
  private api: ApiService,
  public utils:UtilsService,
  public dataService: DataService,
  private router:Router,
  @Inject(MAT_DIALOG_DATA) public data:any    
){}
moduleId:number=8;
permissionsDataSgc:any=[];//permisos sobre el modulo
ngOnInit(): void {
  this.dataService.checkPermissionModule(this.moduleId).then((permissions: any) => {
    this.permissionsDataSgc = permissions;
    this.user= this.dataService.getData("user");
    this.newTask=this.data.task;
  }).catch(() => {
    this.utils.showMsg("Página no autorizada","No tiene permisos para ver esta página, contacte al administrador");
    this.router.navigate(['/']);
  });
}

  updateTasks:boolean=false;
  user:any={};
  newTask:any={
    id:null,
    comments:[],
    comment:""
  };

  addComment(){
    if(this.newTask.comment ==""){
      this.utils.showMsg("Campo vacio","Por favor, ingrese un comentario para la tarea");
    }
    let dataToSend={
      task_id:this.newTask.id,
      description:this.newTask.comment,
      user_api_hash:this.user.hash,
      action:"addComment",
      created_at:this.utils.getDatetime(new Date())
    };
    this.utils.showMakingRequest("Registrando comentario","comment");
    this.api.createData(dataToSend, "task",true).then((data: any) => {
      console.log("respuesta ",data);
      if(data.status==1){
        this.updateTasks=true;
        this.newTask.comment="";
        this.newTask.comments.push({created_at:dataToSend.created_at,description:dataToSend.description});
        this.utils.showResultTask(true,true,"Se ha agregado el comentario");
      } else {
        this.utils.showResultTask(true,true,"No se pudo agregar el comentario");
      }
      //this.getList(this.currentPage);
    }).catch((err: any) => {
      console.log(err);
      this.utils.showResultTask(true,true,"Se ha presentado un error al guardar el comentario");
    });
  }
}
