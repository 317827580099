import { Component, Inject, Input, OnInit, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApiService } from 'src/app/core/api/api.service';
import { DataService } from 'src/app/core/data/data.service';
import { MessageBoxService } from 'src/app/core/message-box/message-box.service';
import { ObjectInitializationService } from 'src/app/core/object-initialization/object-initialization.service';
import { UtilsService } from 'src/app/core/utils/utils.service';
import { ValidationService } from 'src/app/core/validation/validation.service';
import { IUserMessagingServiceApiPhoneLine, UserMessagingServicePhoneLineActivationStatus, UserMessagingServicePhoneLineConnectionValues, IUserMessagingServiceApiPhoneLineQrCode } from 'src/app/shared/models/interfaces/imessaging-service-api.model';
import { IUserSessionData } from 'src/app/shared/models/interfaces/iuser-session-data.model';
import { MessagingServiceApiPhoneLineService } from '../services/messaging-service-api-phone-line/messaging-service-api-phone-line.service';
import { IResponseApi } from 'src/app/shared/models/interfaces/iresponse-api.model';
import { IApiRequestData } from 'src/app/shared/models/interfaces/iapi-request-data.model';
import { ApiEndpointSgc } from 'src/app/shared/enums/api-endpoint-sgc.enum';
import { RequestMethodHTTP } from 'src/app/shared/enums/common-enums.enum';

@Component({
  selector: 'app-phone-line-qr-code-scanner',
  templateUrl: './phone-line-qr-code-scanner.component.html',
  styleUrls: ['./phone-line-qr-code-scanner.component.scss']
})
export class PhoneLineQrCodeScannerComponent implements OnInit, OnDestroy{

  user:IUserSessionData;
  loading: boolean = false;

  stepsToScanQrCode:string[] = [
    "Abre WhatsApp en tu teléfono",
    "Toca menú <i class='fa-solid fa-ellipsis-vertical icon mx-1'></i> o configuración <i class='fa-solid fa-gear icon mx-1'></i>",
    "Toca <strong>Dispositivos vinculados</strong> y luego <strong>Vincular un dispositivo</strong>",
    "Cuando se active la cámara, Apunta tu teléfono hacia esta pantalla para escanear el código QR"
  ]; 

  phoneLineConnectionValues: typeof UserMessagingServicePhoneLineConnectionValues = UserMessagingServicePhoneLineConnectionValues;
  phoneLineActivationStatus: typeof UserMessagingServicePhoneLineActivationStatus = UserMessagingServicePhoneLineActivationStatus;

  maxQrCodeRequestsInInterval: number = 6;
  countQrCodeRequestsInInterval: number = 0;
  /** @type {NodeJS.Timeout} Indica el id del timer usado para enviar solicitud de obtener nuevo codigo qr */
  refreshQrCodeTimer!: NodeJS.Timeout;
  /** @type {NodeJS.Timeout} Indica el id del timer usado para actualizar el valor de duracion del codigo Qr */
  qrCodeDurationUpdateTimer!: NodeJS.Timeout;

  /** @type {NodeJS.Timeout} Indica el id del timer usado para  consultar los datos de la linea y observar estado de conexion*/
  phoneLineDataQueryTimer!: NodeJS.Timeout;
  
  minTimeIntervalForQrCodeRequest: number;
  phoneLineQrCode: IUserMessagingServiceApiPhoneLineQrCode;
  /**
   * @type {boolean} Indica si el escaneo del codigo QR fue exitoso
   */
  successfulScan: boolean = false;
  @Input() userMessagingServicePhoneLine: IUserMessagingServiceApiPhoneLine;
  constructor(
    private api: ApiService,
    public dataService: DataService,
    public utils:UtilsService,
    public messageBox: MessageBoxService,
    private objectInitializationService: ObjectInitializationService,
    private validationService: ValidationService,
    private dialogRef: MatDialogRef<PhoneLineQrCodeScannerComponent>,
    public messagingServiceApiPhoneLineService: MessagingServiceApiPhoneLineService,
    @Inject(MAT_DIALOG_DATA) public data: {userMessagingServicePhoneLine: IUserMessagingServiceApiPhoneLine}
  ){
    this.user = this.dataService.getData("user") as IUserSessionData;
    this.userMessagingServicePhoneLine = this.objectInitializationService.initializeIUserMessagingServicePhoneLine();
    this.phoneLineQrCode = this.objectInitializationService.initializeIUserMessagingServiceApiPhoneLineQrCode();
    this.minTimeIntervalForQrCodeRequest = this.dataService.OneSecond * 10;
  }
  ngOnInit(): void {
    this.userMessagingServicePhoneLine = this.data.userMessagingServicePhoneLine;
    if(this.userMessagingServicePhoneLine.id > 0 && this.userMessagingServicePhoneLine.connected == this.phoneLineConnectionValues.DISCONNECTED && this.userMessagingServicePhoneLine.activation_status == this.phoneLineActivationStatus.ACTIVE){
      this.updateQrCodeRemainingDuration();
      setTimeout(() => this.requestQrCode(), this.dataService.OneSecond * 2 );
      if(typeof this.phoneLineDataQueryTimer == "number")
        clearInterval(this.phoneLineDataQueryTimer);
      this.phoneLineDataQueryTimer = setInterval( ()=> this.checkDeviceConnection() , this.dataService.OneSecond * 3.5);
    }
    this.checkDeviceConnection();
  }
  closeDialog(){
    this.dialogRef.close(this.successfulScan);
  }
  async requestQrCode(){
    if(typeof this.refreshQrCodeTimer == "number" && this.countQrCodeRequestsInInterval >= (this.maxQrCodeRequestsInInterval - 1)){
      clearTimeout(this.refreshQrCodeTimer);
      this.countQrCodeRequestsInInterval += 2;
      return;
    }
    let response: IResponseApi | null | unknown = null;
    try {
      let dataToSend: IApiRequestData = { user_api_hash: this.user.hash, action: "login", id: this.userMessagingServicePhoneLine.id};
      response = await this.api.createData(dataToSend, ApiEndpointSgc.USER_MESSAGING_SERVICE_API_PHONE_LINE, true);   
    } catch (error: unknown) {
      this.utils.showResultRequest("error","Información",this.api.getDefaultMessage("la solicitud de vinculación de la línea de teléfono con API de servicio de mensajería ",true, false, RequestMethodHTTP.GET));
    } finally {
      if (this.validationService.isResponseApi(response)) {
        if(response.status == 1 && this.messagingServiceApiPhoneLineService.isIUserMessagingServiceApiPhoneLineQrCode(response.data)){
          let responseData = response.data as IUserMessagingServiceApiPhoneLineQrCode;
          this.phoneLineQrCode.qr_url = responseData.qr_url;
          this.phoneLineQrCode.qr_duration = responseData.qr_duration;
          //duracion de codigo QR en milisegundos
          let qrDuration = responseData.qr_duration * this.dataService.OneSecond;
          if(this.countQrCodeRequestsInInterval < this.maxQrCodeRequestsInInterval){
            setTimeout(() => this.requestQrCode(), qrDuration);
          }
        }else{
          if(typeof this.phoneLineDataQueryTimer == "number")
            clearInterval(this.phoneLineDataQueryTimer);
        }
      }
      this.countQrCodeRequestsInInterval++;
    }
  }
  updateQrCodeRemainingDuration(){
    this.qrCodeDurationUpdateTimer = setInterval(()=> this.phoneLineQrCode.qr_duration > 0 ? --this.phoneLineQrCode.qr_duration : 0 ,this.dataService.OneSecond);
  }
  checkDeviceConnection(){
    let data:IApiRequestData =  this.api.structureParametersForConsult(RequestMethodHTTP.POST,"checkConnection",this.user.hash) as IApiRequestData;
    data["id"] = this.userMessagingServicePhoneLine.id;
    this.api.getDataPost(ApiEndpointSgc.USER_MESSAGING_SERVICE_API_PHONE_LINE,data,true).then((data: unknown)=>{
      if(this.validationService.isResponseApi(data) && data.status == 1){
        let responseData:IUserMessagingServiceApiPhoneLine = data.data as IUserMessagingServiceApiPhoneLine;
        if(responseData.connected == this.phoneLineConnectionValues.CONNECTED){
          this.successfulScan  = true;
          this.userMessagingServicePhoneLine.connected = responseData.connected;
          this.utils.showResultRequest("success", "Conexión exitosa");
          setTimeout(() => this.closeDialog(), this.dataService.OneSecond * 4);
        }
      }
    }).catch((error:unknown)=>{
      if(typeof this.phoneLineDataQueryTimer == "number")
        clearInterval(this.phoneLineDataQueryTimer);
      this.utils.showResultRequest("error","Información",this.api.getDefaultMessage("la revisión de estado de vinculación de la cuenta",false,false,"GET"));
    });
  }
  ngOnDestroy(): void {
    if(typeof this.refreshQrCodeTimer == "number")
      clearInterval(this.refreshQrCodeTimer);
    if(typeof this.qrCodeDurationUpdateTimer == "number")
      clearInterval(this.qrCodeDurationUpdateTimer);
    if(typeof this.phoneLineDataQueryTimer == "number")
      clearInterval(this.phoneLineDataQueryTimer);
  }
}