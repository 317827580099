import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { IDeviceStatusRecord } from '../../models/interfaces/idevice-status-option.model';
import { IDeviceBasicData } from '../../models/interfaces/idevice.model';
import { ObjectInitializationService } from '../../../core/object-initialization/object-initialization.service';
import { ValidationService } from '../../../core/validation/validation.service';
import { IResponseApi } from '../../models/interfaces/iresponse-api.model';
import { IApiRequestData } from '../../models/interfaces/iapi-request-data.model';
import { IUserSessionData } from '../../models/interfaces/iuser-session-data.model';
import { DataService } from 'src/app/core/data/data.service';
import { ApiService } from 'src/app/core/api/api.service';

@Component({
  selector: 'app-device-status-record-summary-popover',
  templateUrl: './device-status-record-summary-popover.component.html',
  styleUrls: ['./device-status-record-summary-popover.component.scss']
})
export class DeviceStatusRecordSummaryPopoverComponent implements OnInit {
   @ViewChild(MatMenuTrigger) matMenuTrigger!: MatMenuTrigger;
   deviceStatusRecordSummary:IDeviceStatusRecord[] = [];
   @Input() deviceToConsultStatusRecord:IDeviceBasicData = this.objectInitializationService.initializeIDeviceBasicData();

   @Input() showTriggerType:"button"|"icon" = "button";
   /**@type {boolean} Indica si se debe de validar, cuando se realiza la consulta de historial de estados, si existe un ultimo registro de estado enlazado en los datos del dispositivo */
   @Input() validateLastDeviceStatusRecord:boolean = false;
  user!:IUserSessionData;
  constructor(
    public validationService: ValidationService,
    private dataService: DataService,
    private objectInitializationService:ObjectInitializationService,
    private api: ApiService
  ){
    this.user = this.dataService.getData("user") as IUserSessionData;
  }
  ngOnInit(): void {
  }
  async showDeviceStatusRecordSummary(device:any):Promise<void>{
    this.deviceToConsultStatusRecord = device as IDeviceBasicData;
    this.deviceStatusRecordSummary = [this.objectInitializationService.initializeIDeviceStatusRecord(this.user.id,0,null)]
    this.deviceStatusRecordSummary[0].name = "Buscando datos...";
    if(this.validateLastDeviceStatusRecord && this.validationService.isNullOrEmpty(device.device_status_record)){
      this.deviceStatusRecordSummary[0].name = "Sin datos";
      return;
    }
    try{
      let response = await this.getStateChangeHistory(device.id);
      if(this.validationService.isResponseApi(response))
        this.deviceStatusRecordSummary = response.status == 1 ? response.data as IDeviceStatusRecord[] : [];
      if(this.deviceStatusRecordSummary.length == 0){
        this.deviceStatusRecordSummary = [this.objectInitializationService.initializeIDeviceStatusRecord(this.user.id,0,null)]
        this.deviceStatusRecordSummary[0].name = "No se han encontrado datos";
      }
    }catch(error:any){
      this.deviceStatusRecordSummary = [this.objectInitializationService.initializeIDeviceStatusRecord(this.user.id,0,null)]
      this.deviceStatusRecordSummary[0].name = "Sin datos";
    }
  }
      /**
   * @description Obtiene el historial de registro de estados del dispositivo actual del conductor
   * @param {number} deviceId Indica el id del dispositivo al cual se le busca los datos de resumen de estados
    */
      async getStateChangeHistory(deviceId:number):Promise<IResponseApi>{
        let data:IApiRequestData = { user_api_hash:this.user.hash, action:"get",device_id: deviceId,include_details:true, order:{by:"id",direction:"desc"}};
        try {
          let response:unknown = await this.api.getDataPost("deviceStatusRecord",data,true); 
          if(this.validationService.isResponseApi(response)){
            return response;
          }
          return {status:0};
        } catch (error) {
         return {status:0}; 
        }
      }
  showSummary(): void {
    
    this.matMenuTrigger.openMenu();
  }
  hideSummary(): void {
    this.matMenuTrigger.closeMenu();
  }
}
