import {SelectionModel} from '@angular/cdk/collections';
import {Sort} from '@angular/material/sort';
import { Component,Input,Output,OnInit,EventEmitter,Renderer2 } from '@angular/core';
import { DataService } from 'src/app/core/data/data.service';
import { UtilsService } from 'src/app/core/utils/utils.service';
import { ApiService } from 'src/app/core/api/api.service';
import { IWebService } from "../../models/interfaces/iweb-service.model";
import { PageEvent } from '@angular/material/paginator';
import { IWebserviceDevice } from '../../integrations.component';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { IPagination, ISort } from 'src/app/shared/models/interfaces/iapi-request-data.model';
import { ValidationService } from 'src/app/core/validation/validation.service';
import { ObjectInitializationService } from 'src/app/core/object-initialization/object-initialization.service';
import { IUserSessionData } from 'src/app/shared/models/interfaces/iuser-session-data.model';
import { IViewsAvailable } from 'src/app/shared/models/interfaces/views-available';
import { RequestMethodHTTP } from 'src/app/shared/enums/common-enums.enum';

///ANALIZAR LOS  COMPONENTES DE web-services-list, ya que entonces si no van a ver muchas variaciones podria entonces crearse un componente comun y usarlo para cargar los datos en relacion al web service a visualizar
@Component({
  selector: 'app-caserones-owl-web-service',
  templateUrl: './caserones-owl-web-service.component.html',
  styleUrls: ['./caserones-owl-web-service.component.scss']
})
export class CaseronesOwlWebServiceComponent implements OnInit{

  @Input() data!:IWebService;
  @Input() webServiceId!:number;
  @Output() closeModal = new EventEmitter<boolean>();
  viewsAvailable:IViewsAvailable={
    logs:{name:"logs",show_view:false},
    device_list:{name:"device_list",show_view:true}
  };
  /** @type {Object} Lista de opciones de filtro en cuanto a si mostrar dispositivos suscritos(1) y/o no suscritos(0) */
  deviceSubscriptionStatus:{subscribed: number, not_subscribed: number } = {
    subscribed:1,
    not_subscribed:0
  };
  subscriptionStatusFilter:number[] = [this.deviceSubscriptionStatus.subscribed,this.deviceSubscriptionStatus.not_subscribed];

  displayedColumns: string[] = ['select','activated', 'name', 'imei', 'plate_number', 'device_model','expiration_date'];
  selection = new SelectionModel<any>(true, []);
  /**Datos del usuario logueado */
  user:IUserSessionData;
  /**indica los datos para el ordenamiento de la tabla */
  sort: ISort;
  /**la lista de dispositivos a mostrar en la tabla */
  deviceList:IWebserviceDevice[] = [];
  /**el valor a buscar dentro de la tabla de dispositivos */
  searchDevice = "";
  devices:{activate:IWebserviceDevice[],deactivate:IWebserviceDevice[]}={
    activate:[],
    deactivate:[]
  };
  loading: boolean = false;
  searchTimer!: NodeJS.Timeout;
  pagination: IPagination

  moduleId:number = 25;
  constructor(
    private dataService:DataService,
    public utils:UtilsService,
    private api:ApiService,
    private validationService: ValidationService,
    private objectInitializationService: ObjectInitializationService
  ){
    this.sort = this.objectInitializationService.initializeISort();
    this.pagination = this.objectInitializationService.initializeIPagination(this.dataService.PAGE_SIZE_OPTION);
    this.pagination.page_size = this.pagination.page_size_options[0];
    this.user= this.dataService.getData("user");
  }
  ngOnInit(): void {
    this.getDeviceList();
  }
  isDeviceSubscriptionStatus(status:number){
    return this.subscriptionStatusFilter.some((item:any)=>item==status);
  }
  changeDeviceSubscriptionFilter(event:any){
    this.pagination.current_page = 0;
    (event.checked)?this.subscriptionStatusFilter.push(parseInt(event.source.value)):this.subscriptionStatusFilter.splice(this.subscriptionStatusFilter.findIndex((item:number)=>item==parseInt(event.source.value)),1);
    this.getDeviceList();
  }
  getDeviceList():void{
    this.loading = true;
    this.deviceList=[];
    this.devices.activate =[];
    this.devices.deactivate=[];
    let data = this.api.getCommonQueryParameters("getWebServiceDevices",this.user.hash,this.searchDevice,this.pagination.current_page,this.pagination.page_size,this.sort.by??undefined,this.sort.direction??undefined);
    data +="&web_service_id="+this.webServiceId;;
    for(let item of this.subscriptionStatusFilter){
      data += "&activated_field_values[]=" + item;
    }
    console.log(data);
    this.api.getData("device",data,true).then((data: unknown) => {
    if(this.validationService.isResponseApi(data)){
      if(data.status == 1){
        for(let device of data.data){
          Object.entries(device).length > 0 && Number(device.activated) ? this.devices.activate.push(device) : this.devices.deactivate.push(device);
        }
        this.pagination.length_page =data.total ?? 0;
      }
      this.deviceList = data.status?data.data:[];
      this.selection = new SelectionModel<any>(true, []);
    }
    }).catch((error:unknown)=>{
      this.utils.showResultRequest("error", "", this.api.getDefaultMessage("la lista de dispositivos", false, false, RequestMethodHTTP.GET));
    }).finally(()=>this.utils.hideLoading(()=>this.loading = false));
  }
  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.devices.activate.length;
    const numRows = this.deviceList.length;
    return numSelected >0 && numSelected === numRows;
  }
  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.devices.activate=[];
      this.devices.deactivate=this.utils.copyObject(this.deviceList);
      this.selection.clear();
      return;
    }
    this.devices.deactivate=[];
    this.devices.activate=this.utils.copyObject(this.deviceList);
    this.selection.select(...this.deviceList);
  }
  changeDeviceSelected(event:MatSlideToggleChange,device:any){
    if(!this.utils.isNullOrEmpty(device))
      (event.checked)?this.toggleSelection("activate","deactivate",device):this.toggleSelection("deactivate","activate",device);
  }
  toggleSelection(property:"activate"|"deactivate",antonymousProperty:"activate"|"deactivate",device:any){
    let propertyIndex = this.devices[property].findIndex((item:any)=>item.id==device.id); 
    let antonymousPropertyIndex = this.devices[antonymousProperty].findIndex((item:any)=>item.id==device.id); 
      if(propertyIndex ==-1)
        this.devices[property].push(device);
      if(antonymousPropertyIndex !=-1)
          this.devices[antonymousProperty].splice(antonymousPropertyIndex,1);
  }
  checkboxLabel(row?: any): string {
    if (!row)
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }
  isSelectedDevice(row:IWebserviceDevice){
    return (this.devices.activate.some((item:any)=>item.id==row.id));
  }
  paginator($event: PageEvent){
    this.pagination.page_size = $event.pageSize;
    this.pagination.current_page = $event.pageIndex;    
    this.getDeviceList();
  }
  sortData(sort: Sort) {
    this.sort.direction=sort.direction;
    this.sort.by=sort.active;
    this.getDeviceList();
  }
  /** @description Actualiza la lista de dispositivos a reportar al web service */
  updateDevicesSelect(){
    let deviceIdList:{activate:IWebserviceDevice[],deactivate:IWebserviceDevice[]} = {activate:[],deactivate:[]};

    this.devices.activate.forEach((item:any)=>deviceIdList.activate.push(item.id));
    this.devices.deactivate.forEach((item:any)=>deviceIdList.deactivate.push(item.id));
    this.loading = true;
    this.utils.showConfirm("Confirmar cambios", "Confirma la actualización de dispositivos a reportar a Caserones OWL", "Confirmar", "Cancelar").then(() => {
      this.api.createData({user_api_hash:this.user.hash,device_id_list:deviceIdList,action:"update",web_service_id:this.webServiceId}, "deviceWebService",true).then((data: any) => {
        this.utils.showResultRequest(data.status==1?"success":"error","Información",data.message);
        this.getDeviceList();
      }).catch((err: any) => {
        this.utils.showResultRequest("error", "Información", this.api.getDefaultMessage("los registros", true, false, RequestMethodHTTP.POST));
      }).finally(()=>this.utils.hideLoading(()=>this.loading = false));
    }).catch(()=>{this.utils.hideLoading(()=>this.loading = false);this.getDeviceList();});
  }
  searchValueInData(){
    clearTimeout(this.searchTimer);
    this.searchTimer = setTimeout(() => {
      this.pagination.current_page = 0;
      this.getDeviceList();
    }, this.dataService.DATA_TABLE_SEARCH_TIMEOUT);
  }
  close() {
    this.closeModal.emit(true);
  }
}