export interface IRouteConstant{
    url:string,
    id:number | null
};
export enum WebDomainKey  {SGC="SGC",LOCALHOST="LOCALHOST",SGC_TEST="SGC_TEST",UNIVERSAL_TRACKING="UNIVERSAL_TRACKING",INTGPS="INTGPS",CORPORATE_WEBSITE_INTGPS = "CORPORATE_WEBSITE_INTGPS"};
export interface IWebDomain{
    [WebDomainKey.SGC]: IWebDomainElement,
    [WebDomainKey.SGC_TEST]: IWebDomainElement,
    [WebDomainKey.LOCALHOST]: IWebDomainElement,
    [WebDomainKey.UNIVERSAL_TRACKING]: IWebDomainElement,
    [WebDomainKey.INTGPS]: IWebDomainElement,
    [WebDomainKey.CORPORATE_WEBSITE_INTGPS]: IWebDomainElement
}
export interface IWebDomainElement{
    domain: string,
    url:string
}
export class RouteConstant{

    static readonly WEB_DOMAIN:IWebDomain = {
        SGC:{
            url:"https://sgc.internationalgpsonline.com",
            domain: "sgc.internationalgpsonline.com"
        },
        SGC_TEST:{
            url:"https://sgc-test.internationalgpsonline.com",
            domain: "sgc-test.internationalgpsonline.com"
        },
        LOCALHOST:{
            url:"http://localhost:4200/",
            domain: "localhost"
        },
        INTGPS:{
            url:"https://internationalgpsonline.com",
            domain: "internationalgpsonline.com"
        },
        UNIVERSAL_TRACKING:{
            url:"https://universaltracking.top",
            domain: "universaltracking.top"
        },
        CORPORATE_WEBSITE_INTGPS:{
            url:"https://corporate.internationalgpsonline.com",
            domain: "corporate.internationalgpsonline.com"
        },
    };
    
    static readonly LOGIN:IRouteConstant = {url:"login",id: null};
    static readonly SPECIAL_USER_LOGIN:IRouteConstant = {url:"login/:user_type",id:null};
    
    static readonly DASHBOARD:IRouteConstant = {url:"dashboard",id:null};
    static readonly DASHBOARD_HOME:IRouteConstant = {url:RouteConstant.DASHBOARD.url+"/home",id:1};
    static readonly FLEET_CONTROL:IRouteConstant = {url:"fleet-control",id:16};
    static readonly DEVICE_CHECKLIST_MANAGEMENT:IRouteConstant = {url:RouteConstant.FLEET_CONTROL.url+"/device-checklist-management",id:18};
    static readonly DRIVER_DEVICE_CHECKLIST_MANAGEMENT:IRouteConstant = {url:RouteConstant.FLEET_CONTROL.url+"/driver-device-checklist-management",id:18};
    static readonly DRIVER_DEVICE_STATE_MANAGEMENT:IRouteConstant =  {url:RouteConstant.FLEET_CONTROL.url+"/driver-device-state-management",id:19};
    static readonly DEVICE_CHECKLIST_VIEWER:IRouteConstant = {url:"device-checklist-viewer/:id",id:RouteConstant.DRIVER_DEVICE_CHECKLIST_MANAGEMENT.id};
    static readonly DEVICE_CHECKLIST_ANSWERED_VIEWER:IRouteConstant = {url:"device-checklist-viewer/:id/:device_checklist_answered_id",id:RouteConstant.DRIVER_DEVICE_CHECKLIST_MANAGEMENT.id};

    static existsHostNameInAllowedWebDomain(hostname:string):{item_name: string | null, value: IWebDomainElement | null} {
        let item = Object.entries(RouteConstant.WEB_DOMAIN).find(([key,item])=>item.domain == hostname)??null;
        if(typeof item != "undefined" && item != null)
            return {item_name:item[0],value:item[1]};
        else
           return {item_name:null,value:null};
    }
}