<h2 mat-dialog-title>{{processingData ? 'Procesando...' : ( successfulRegistration ? 'Registro exitoso' : 'Por favor, dibuje su firma para confirmar el registro del checklist')}}</h2>
<mat-dialog-content class="mat-typography position-relative ">
<section class="signature" [ngClass]="{'d-none': !(!processingData && !successfulRegistration)}" >
  <article class="signature__actions">
    <button mat-raised-button (click)="clearSignature()" matTooltip="limpia el lienzo de dibujo de la firma">Limpiar</button>
  </article>  
  <signature-pad #signature [options]="signaturePadOptions" class="signature__container" (drawStart)="drawStart($event)" (drawEnd)="drawComplete($event)"></signature-pad>
</section>
<article class="ilustration animate__animated animate__bounceIn" *ngIf="processingData">
  <dotlottie-player autoplay loop mode="normal"
  src="../../../../../assets/img/card-skeletons.lottie"
  ></dotlottie-player>
  <span class="description">Registrando datos</span>
</article>
<p *ngIf="!processingData && successfulRegistration">Respuestas disponibles en el historial de checklist</p>
</mat-dialog-content>
<mat-dialog-actions align="end" *ngIf="!processingData">
  <button mat-button [mat-dialog-close]="successfulRegistration" class="btn btn-outline-secondary" style="margin-right: 0.2rem;">Regresar</button>
  <button mat-button class="btn btn-primary animate__animated" (click)="saveAnswers()" *ngIf="driverHasSigned && (!processingData && !successfulRegistration)" cdkFocusInitial>Confirmar</button>
</mat-dialog-actions>