import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/core/api/api.service';
import { DataService } from 'src/app/core/data/data.service';
import { UtilsService } from 'src/app/core/utils/utils.service';
import { ValidationService } from 'src/app/core/validation/validation.service';
import { IUserSessionData } from 'src/app/shared/models/interfaces/iuser-session-data.model';
import { IApiRequestDataForReport } from '../../pvs-reports.service';
import { Sort } from '@angular/material/sort';
import { IApiRequestData } from 'src/app/shared/models/interfaces/iapi-request-data.model';
import { IChartConfigData } from 'src/app/shared/models/interfaces/iutil.model';
import { ColorManipulationService } from 'src/app/core/color-manipulation/color-manipulation.service';
import { ChartTypeChartjs } from 'src/app/shared/enums/common-enums.enum';
import { ObjectInitializationService } from 'src/app/core/object-initialization/object-initialization.service';

interface IProductivityApiResponse{
  summary:IDeviceGroupSummary[],
  device_list:IDeviceList[];
}
interface IDeviceGroupSummary{
  min:number,
  max:number,
  data:IDeviceList[]
}
interface IDeviceList{
  device_id:number,
  device_date:string,
  plate_number:string,
  name:string,
  distance:number,
  traccar_device_id:number,
  current_driver_id:number| null,
  position:number
}
@Component({
  selector: 'app-fleet-productivity',
  templateUrl: './fleet-productivity.component.html',
  styleUrls: ['./fleet-productivity.component.scss']
})
export class FleetProductivityComponent implements OnInit {
  /** @type { boolean } Indicador de crga de datos para la seccion general */
  generalLoading:boolean = false;
  /** @type { boolean } Indicador de crga de datos para la tabla */
  tableLoading:boolean=false;
  user!:IUserSessionData;
  /** @type {IChartConfigData} Configuracion de datos para grafico circular */
  circularChartConfigData:IChartConfigData = {title:"",data:{labels:["Sin datos"],datasets:[{label:"",data:[100],backgroundColor:this.colorManipulationService.DEFAULT_COLORS}]},chart_type:ChartTypeChartjs.DOUGHTNUT,updated_chart:true};
  /**@type {any} LaLista de datos a mostrar de los dispositivos */
  deviceList:IDeviceList[]=[];

  /**@type {any} LaLista de datos agrupados como resumen para grafico*/
  dataSummary:IDeviceGroupSummary[]=[];
  
  /**@type {any} La Lista de datos ordenados a mostrar en la tabla de dispositivos */
  sortedDeviceList:IDeviceList[]=[];
  sort={ by:"", direction:""};
  /** Indica las fechas de filtro usadas */
  footerData!:{start_date:string,end_date:string};
  
  //para paginacion
  lengthPage:number=0;
  pageSize:number = 20;
  pageSizeOptions:number[]=[];
  pageEvent!:PageEvent;
  currentPage=0;
  /** @type {FormGroup} Indica el rango de fecha para filtrar datos por fecha, cuando la seleccion es un solo dispositivo */
  dateRangeToConsult!:FormGroup;
  displayedColumns:any =["device_date","plate_number","distance","position"];

  moduleId=23;
  permissionsDataSgc:any=[];//permisos sobre el modulo
  constructor(
    private router:Router,
    public utils: UtilsService,
    public validationService:ValidationService,
    private api:ApiService,
    private dataService:DataService,
    private colorManipulationService:ColorManipulationService,
    private objectInitializationService: ObjectInitializationService
  ){
    this.user = this.dataService.getData("user") as IUserSessionData;
    const date:Date = new Date();
    this.dateRangeToConsult = new FormGroup({
      start: new FormControl(new Date(date.getFullYear(),date.getMonth(),1)),
      end: new FormControl(new Date(date.getFullYear(),(date.getMonth()),date.getDate())),
    });
    this.footerData = {start_date:"",end_date:""};
  }
  ngOnInit(): void {
    this.dataService.checkPermissionModule(this.moduleId).then((permissions: any) => {
      this.permissionsDataSgc = permissions;
      this.getDeviceList();
    }).catch(() => {
      this.utils.showMsg("Página no autorizada","No tiene permisos para ver esta página, contacte al administrador");
      this.router.navigate(['/']);
    });
  }
  paginator(event:PageEvent){
    this.tableLoading = true;
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    setTimeout(() => {this.processNewPage(this.deviceList);}, 250);
  }
  /** @description Procesa nueva pagina a mostrar en la tabla */
  processNewPage(deviceList:IDeviceList[]){
    let from = this.currentPage * this.pageSize;
    this.sortedDeviceList = deviceList.slice(from,(from +this.pageSize));
    this.utils.hideLoading(()=>this.tableLoading = false);
  }
  sortData(sort:Sort){
    this.sort.direction = sort.direction;
    this.sort.by = sort.active;
    const data:IDeviceList[] = this.deviceList.slice();
    if (!sort.active || sort.direction === '') {
      //this.sortedDeviceList = data;
      this.processNewPage(data);
      return;
    }
    this.sortedDeviceList = data.sort((a:IDeviceList, b:IDeviceList) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'plate_number':
          return this.utils.compare(a.plate_number, b.plate_number, isAsc);
        case 'position':
          return this.utils.compare(a.position, b.position, isAsc);
        case 'device_date':
          return this.utils.compare(new Date(a.device_date).getTime(), new Date(b.device_date).getTime(), isAsc);
        default:
          return 0;
      }
    });
    this.processNewPage(this.sortedDeviceList);
    return data;
  }
  prepareCircularChartConfig(){
    this.circularChartConfigData.updated_chart = false;
    this.circularChartConfigData.data.labels = [];
    let totalItems:number = 0;
    this.circularChartConfigData.data.datasets[0].backgroundColor = this.colorManipulationService.DEFAULT_COLORS;
    this.circularChartConfigData.data.datasets[0].data = [];
    if(this.dataSummary.length == 0 ){
      this.circularChartConfigData.data.labels.push("Sin datos");
      this.circularChartConfigData.data.datasets[0].data.push(100);
    }else{
      this.dataSummary.forEach((group:IDeviceGroupSummary)=>totalItems += group.data.length);
      for(let group of this.dataSummary){
        this.circularChartConfigData.data.labels.push("Más de "+group.min.toLocaleString()+" a "+group.max.toLocaleString());
        this.circularChartConfigData.data.datasets[0].data.push(Number(((group.data.length*100)/totalItems).toFixed(0)));
      }
    }
    setTimeout(() => {this.circularChartConfigData.updated_chart = true;}, 100);
  }
  async getDeviceList(){
    this.tableLoading = true;
    this.generalLoading = true;
    let dateRange:{start:string,end:string} = this.utils.getDateRange(this.dateRangeToConsult.value.start,this.dateRangeToConsult.value.end);
    let dataTosend:IApiRequestDataForReport & IApiRequestData = {
      start_date:dateRange.start+ " 00:00:00", end_date:dateRange.end+ " 23:59:59", action : "getDeviceProductivity",user_api_hash: this.user.hash,
      order:{ by:!this.validationService.isNullOrEmpty(this.sort.by??null)?this.sort.by:null, 
      direction:!this.validationService.isNullOrEmpty(this.sort.direction??null)?this.sort.direction:null}
    };
    if(this.dateRangeToConsult.value.end ===false || this.dateRangeToConsult.value.end === null){
      dataTosend.end_date = dateRange.start +" 23:59:59";
    }
    if( ((this.dateRangeToConsult.value.start === null || this.dateRangeToConsult.value.start ===false)) || 
         new Date(this.dateRangeToConsult.value.start).getTime() > new Date(dataTosend.end_date).getTime()){
      this.utils.showMsg("","No ha seleccionado un rango de fechas válida");
      this.utils.hideLoading(()=>{this.tableLoading = false;this.generalLoading = false;});
      return;
    }
    let endDate:Date = new Date(dataTosend.end_date);
    if(this.dateRangeToConsult.value.start.getFullYear()+this.dateRangeToConsult.value.start.getMonth() != endDate.getFullYear() + endDate.getMonth()){
      this.utils.showMsg("","El rango de fecha debe de estar dentro del mismo mes");
      this.utils.hideLoading(()=>{this.tableLoading = false;this.generalLoading = false;});
      return;
    }
    this.footerData = {start_date:dataTosend!.start_date,end_date:dataTosend!.end_date};
    this.resetFields();
    this.api.getDataPost("report",dataTosend,true).then((data: any) => {
      let responseData:IProductivityApiResponse = data.status ? data.data as IProductivityApiResponse:{device_list:[],summary:[]};
      this.deviceList = data.status == 1 ? responseData.device_list:[];
      this.dataSummary = data.status ==1 ? responseData.summary:[];
      this.sortedDeviceList = this.deviceList.slice();
      this.lengthPage = data.status ? data.total: 0;
      data.status ? this.processNewPage(this.deviceList): null;
      this.prepareCircularChartConfig();
      this.utils.hideLoading(()=>{this.tableLoading = false;this.generalLoading = false;});
    }).catch((error)=>{
      this.utils.showResultRequest("error","Información",this.api.getDefaultMessage("la lista de estados de dispositivos",false,false,"GET"));
      this.utils.hideLoading(()=>{this.tableLoading=false;this.generalLoading = false;});
    });
  }
  resetFields(){
    this.lengthPage = 0;
    this.deviceList = [];
    this.sortedDeviceList = [];
    this.dataSummary = [];
    this.circularChartConfigData = this.initializeChartConfigData();
  }
  initializeChartConfigData(): IChartConfigData{
    return this.objectInitializationService.initializeIChartConfigData("", ChartTypeChartjs.DOUGHTNUT,
      {labels:["Sin datos"],datasets:[{label:"",data:[100],backgroundColor:this.colorManipulationService.DEFAULT_COLORS}]}, false,false);
  }
  exportFile(fileType:"PDF"|"CSV"){
    if(fileType=="CSV")
      this.utils.processFileDownload("device_report_",
      async()=>{return {data:this.deviceList,headers:this.displayedColumns}},
      (response:boolean)=>this.tableLoading = response);
  }
}