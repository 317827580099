import { Component, OnInit,Renderer2,HostListener } from '@angular/core';
import { DataService } from '../core/data/data.service';
import { Router } from '@angular/router';
import { UtilsService } from '../core/utils/utils.service';
import { debounceTime, fromEvent } from "rxjs";
import Swal from 'sweetalert2';
import { ApiService } from '../core/api/api.service';
import { DatePipe } from '@angular/common';
import { PaymentNotification } from '../shared/models/payment-notification.model';
import { WebMenuManagementService } from '../core/web-menu-management/web-menu-management.service';
import { WebTemplateEditMenuComponent }from  "./menus/web-template-edit-menu/web-template-edit-menu.component";
import { ValidationService } from '../core/validation/validation.service';
import { IUserSessionData } from '../shared/models/interfaces/iuser-session-data.model';
import { IDashboardMenu } from '../shared/models/interfaces/idashboard-menu.model';
import { UserType } from '../shared/types/common-types.type';
import { AuthService } from '../core/auth/auth.service';
import { IRouteConstant, RouteConstant } from '../core/constant/RouteConstant';
import { Breakpoints } from '../shared/enums/common-enums.enum';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if(typeof window != "undefined" &&  window.innerWidth < Breakpoints.MEDIUM)
      this.isOpenSidebar = true;
  }
  menu: IDashboardMenu[] = [];
  user: IUserSessionData = this.dataService.user;
  /** @type {boolean} Indica si se cierra el menu sidebar */
  isOpenSidebar:boolean = true;
    /** @type {boolean} Indica si el menu sidebar esta abierto. en tamaños considerado movil */
  isOpenSidebarMobile:boolean = true;
  logoutButtonTitle: string = "Cerrar sesión";
  
  idleTimer: any;//id del temporizador de tiempo de inactividad
  /**@type {string} El nombre de la variable localStorage que almacena el id del timeout de verificacion de inicio de sesión */
  timeoutIdUserSession:string="timeoutIdUserSession";
  /**Indica el tiempo (fecha) en minutos en la cual inicio actividad de uso de la plataforma. Se reinicia cad vez que se detecta uso de SGC */
  startTimeOfUse:number = 0;
  /**Tiempo en minutos que puede durar la sesion inactiva */
  MaximumSessionIdleTime:number = 0;
  
  userRole:string = "";
  /** indica el tipo de usuario especial que se loguea, en caso de haberse logueado */
  specialUser!: string;
  userName:string;
  userSubscriptionPlan:any = [];
  
  actualView: number = 0;
  actualSubMenu: number = 0;
  version: string = "1.22.2-beta-2";//version beta x.y.z-beta-n

  tasksList:any=[];//almacena la lista de tareas mas cercanas, las de hoy y mañana
  employeesList:any=[];

  /** @type {UserType} Almacena el tipo de usuario que esta logueado */
  userTypeLogged:UserType | null;

  barMenu:boolean=false;
  permissionUpdateReviewId:any;
  @HostListener('window:mousemove')
  @HostListener('window:keyup')
  resetTimer() {
    clearTimeout(this.idleTimer);
    if(typeof this.dataService.getData(this.timeoutIdUserSession) ==="number")
      clearTimeout(this.dataService.getData(this.timeoutIdUserSession));  
    this.dataService.setData("startTimeOfUse",(new Date()).getTime()/this.dataService.OneMinute);  
    this.idleTimer = setTimeout(() => {
      if(this.validationService.isNullOrEmpty(this.dataService.getData("user")) || this.utils.isEmptyObject(this.dataService.getData("user"))){
        clearTimeout(this.idleTimer);
        clearTimeout(this.dataService.getData(this.timeoutIdUserSession));    
        return;
      }
      if(typeof this.dataService.getData(this.timeoutIdUserSession) =="undefined" || 
        typeof this.dataService.getData("startTimeOfUse") =="undefined"
      )
        return;
      if(( (new Date()).getTime()/this.dataService.OneMinute) - this.dataService.getData("startTimeOfUse") > this.MaximumSessionIdleTime){
        this.utils.showResultRequest("info","Sesión expirada","La sesión ha sido cerrada debido al tiempo de inactividad");
        this.logout(false);
      }
    }, this.dataService.OneMinute*this.MaximumSessionIdleTime);
    this.dataService.setData(this.timeoutIdUserSession,this.idleTimer);
    this.dataService.updateIntervalIdsList(this.idleTimer);
  }
  constructor(
    public dataService:DataService,
    private route:Router,
    private utils:UtilsService,
    private api:ApiService,
    private renderer:Renderer2,
    public datepipe:DatePipe,
    private paymentNotification:PaymentNotification,
    public webMenuManagementService:WebMenuManagementService,
    public validationService: ValidationService,
    private authService:AuthService
  ) {
      this.user= this.dataService.getData("user") as IUserSessionData;
      this.userTypeLogged = this.validationService.isNullOrEmpty(this.user.special_user_type??null) ? this.dataService.USER_TYPE.user : (this.user.special_user_type == this.dataService.SPECIAL_USER.user_driver ? this.dataService.USER_TYPE.user_driver : null);
      
      if(typeof this.user.special_user_type != "undefined" && this.user.special_user_type == this.dataService.SPECIAL_USER.user_driver){
        this.specialUser = this.user.special_user_type_name??"";
        this.menu = this.dataService.USER_DRIVER_MENU;
      }
      else
        this.menu = this.dataService.MENU;

      this.userRole=this.user.role_name;
      this.userName= this.user.name||this.user.email||"";
      this.MaximumSessionIdleTime = this.dataService.getUserTypeLogged() == this.dataService.USER_TYPE.user_driver ? 240 : 30;
    }
  ngOnInit(): void {
    if(Object.entries(this.dataService.getData("actualView")).length>0){
      this.actualView = this.dataService.getData("actualView");
      this.dataService.removeData("actualView");
      this.goTab(this.actualView);
    }
    this.resetTimer();
    this.permissionUpdateReview();
    this.userSubscriptionPlan = this.dataService.getData("userSubscriptionPlan");
    this.user=this.dataService.getData("user");
    fromEvent(window,"offline").pipe(debounceTime(100)).subscribe((event:Event)=>{
      this.utils.showNetStatus(false);
    });
    this.menu.forEach((element: any) => {
      if(element.tab){
        if(this.route.url.includes(element.tab)){
          this.actualView = element.id;
        }
      }
    });
    this.reviewPendingInvoice();
    this.getNearestTasks();
  }
  toggleBarMenu(){
    this.barMenu = !this.barMenu; 
  }
  goTab(item: any, sub: boolean = false,onlyOpenSubMenu:boolean = false){
    this.barMenu=false;
    if((item.children && onlyOpenSubMenu) || (item.children.length>0 ) ){
      if(item.tab != null){
        this.actualView = item.id;
        this.route.navigate([item.tab]);
      }
      if(item.children.length > 0){
        if(this.actualSubMenu>0){
          if(!sub) this.actualSubMenu = 0;
        } else 
          this.actualSubMenu = item.id;
      } else {
        this.actualView = item.id;
        this.route.navigate([item.tab]);
      }
    } else {
      this.actualView = item.id;
      this.route.navigate([item.tab]);
    }
    this.isOpenSidebarMobile = true;
    this.dataService.setData("actualView",this.actualView);
  }

  hasPermission(id: number){
   return typeof this.userSubscriptionPlan.modules != "undefined" && Array.isArray(this.userSubscriptionPlan.modules) && this.userSubscriptionPlan.modules.length>0 && this.userSubscriptionPlan.modules.some((module:any)=> module.module_id == id);
  }
  getNearestTasks(){
    if(typeof this.user.notify_tasks !="undefined" && this.user.notify_tasks>0 && (typeof this.user.showedTasksNotification =="undefined" || this.user.showedTasksNotification ==false)  ){
    this.user["showedTasksNotification"]=true;
    this.dataService.setData("user",this.user);
      const dateToSend =(this.utils.getUTCDatetime()).split(" ")[0];
      this.api.getData("task","?action=getTasksByExpirationDate&user_api_hash="+this.user.hash+"&date="+dateToSend,true).then((data:any)=>{
        if(data.status==1){
          this.tasksList=data.data;

          this.api.getData("user","?action=getEmployees&user_api_hash="+this.user.hash,true).then((employeesData:any)=>{
            if(employeesData.status==1){
              this.employeesList=employeesData.data;
              this.showTasksDialog();
            }
          }).catch((error:any)=>{});          
        }
      }).catch((error)=>{});
    }
  }

  showTasksDialog(){
    if(this.tasksList.today.length==0 && this.tasksList.tomorrow.length==0){
      return;
    }
    let dialog:any={
      title: '¡Tareas pendientes!',
      icon:"info",
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      showCancelButton: false,
      showConfirmButton: true,
      confirmButtonText:"Ok",
      
    };

    dialog["html"]="<div class='container-notify-tasks'>";
    if(this.tasksList.today.length>0){
      dialog["html"] +=this.createTasksList(this.tasksList.today,"Para hoy");      
    }
    if(this.tasksList.tomorrow.length>0){
      dialog["html"] +=this.createTasksList(this.tasksList.tomorrow,"Para mañana",true);
    }
    dialog["html"] +=`
      <div class="form-check">
            <input class="form-check-input" type="checkbox" value="0" id="checkNotificationsTasks">
            <label class="form-check-label" for="checkNotificationsTasks"> No recordarme más hoy</label>
      </div>
    </div>`;

    Swal.fire(dialog).then((result)=>{
      if(result.isConfirmed){
        let noNotifyTasks:any =this.renderer.selectRootElement("#checkNotificationsTasks",true);
        if(noNotifyTasks.checked){
          this.changeStatusNotifications(noNotifyTasks.value,this.user.id);
        }
      }
    });
  }
  createTasksList(data:any,title:string,isForTomorrow:boolean=false){
    let list="";
    if(data.length>0){
      list=
      `<details open class='notify-tasks  position-relative'>
      `;

      list +="<summary class='notify-tasks__title position-sticky top-0'>"+title+"</summary>";
      
      list +=`  
        <ol class='notify-tasks__content'>
      `;
      for(let task of data){
        let employee =this.employeesList.find((employee:any)=>employee.id ==task.employee_id);
        employee =typeof employee !="undefined"?employee["name"]:"Sin encargado";
        
        list +=
        "<li class='notify-tasks__item'>";
        list +="<span class='title'>"+task.title+"</span>";
        list +="<span class='responsible' matToolTip='Encargado'>"+employee+"</span>";
        if(task.all_day==1){
          list +="<date class='date'>Hasta: "+this.datepipe.transform(task.expiration_date,"h:mm a")+"</date>";
        }
        else{
          let expirationDate;
          let todayDate=new Date();
          let dayToEvaluate =todayDate.getFullYear()+"-"+this.utils.evaluateDateNumber((todayDate.getMonth()+1))+"-"+this.utils.evaluateDateNumber((todayDate.getDate()));
          if(isForTomorrow){
            let tomorrow =new Date(todayDate.getFullYear(),todayDate.getMonth(),todayDate.getDate()+1);
            dayToEvaluate =tomorrow.getFullYear()+"-"+this.utils.evaluateDateNumber((tomorrow.getMonth()+1))+"-"+this.utils.evaluateDateNumber((tomorrow.getDate()));
          }
          if(new Date(dayToEvaluate).getTime()==new Date(this.utils.getDate(task.expiration_date)).getTime()){
            expirationDate =this.datepipe.transform(task.expiration_date,"h:mm a");
          }else{
            expirationDate=this.datepipe.transform(task.expiration_date,"shortDate")+" "+this.datepipe.transform(task.expiration_date,"h:mm a");
          }
          
          list +="<date class='date'>Desde: "+this.datepipe.transform(task.start_date,"h:mm a")+", hasta: "+expirationDate+"</date>";
        }

        list +="</li>";
      }
      list +=`
        </ol>
      </details>
      `;
    }
    return list;
  }
  changeStatusNotifications(notifyStatus:number,userId:number){
    let dataToSend={
      notify_status:notifyStatus,
      id:userId,
      action:"changeTasksNotificationStatus",
      user_api_hash:this.user.hash
    };
    this.api.createData(dataToSend, "user",true).then((data: any) => {
      if(data.status==1){
        this.user.notify_tasks=notifyStatus;
        this.dataService.setData("user",this.user);
      }
    }).catch((err: any) => {});
  }
  //para revisar si hay datos de facturas sin notificar al cliente sobre que ya se han actualizado
  reviewPendingInvoice(){
    if(this.paymentNotification.supportsNotifications()){
      if(Notification.permission === 'granted'){
        for(let [name,value] of Object.entries(this.dataService.getData("checkStatusPayment"))){
          this.paymentNotification.validateInvoiceStatus(value);
        }
      }else{
        Notification.requestPermission().then((permission:any)=>{
          if (permission === "granted"){
            for(let [name,value] of Object.entries(this.dataService.getData("checkStatusPayment"))){
              this.paymentNotification.validateInvoiceStatus(value);
            }
          }
        });
      }
    }
  }
  permissionUpdateReview(){
     this.permissionUpdateReviewId = setInterval(()=>{
      if(!navigator.onLine)
        return;
      this.api.getData("platformPlan","?action=getPlatformPlan&user_api_hash="+this.user.hash,true)
      .then((response:any)=>{
        if(this.utils.isEmptyObject(this.dataService.getData("user"))){
          clearInterval(this.permissionUpdateReviewId);
          return;
        }
        if(response.status==1){
          if(response.data.modules==0){
            this.utils.showResultRequest("info","Información","La sesión ha sido cerrada debido a que tu plan no tiene modulos disponibles");
            clearTimeout(this.permissionUpdateReviewId);
            this.logout(false);
          } 
          else if(JSON.stringify(response.data.modules)!=JSON.stringify(this.userSubscriptionPlan.modules)){
            this.userSubscriptionPlan =response.data;
            this.dataService.setData("userSubscriptionPlan",response.data);
            if(this.userSubscriptionPlan.modules.some((module:any)=>module.module_id!=this.actualView && module.module_id!=this.actualSubMenu)){
              this.actualView=this.menu[0].id;
              this.route.navigate([this.menu[0].tab]);
            }
          }
        }else if(typeof response.no_found!="undefined" && response.no_found){
          this.utils.showResultRequest("info","Información","La sesión ha sido cerrada debido que no se ha encontrado un plan asignado a tu cuenta");
          clearTimeout(this.permissionUpdateReviewId);
          this.logout(false);
        }
      }).catch((error:any)=>{});
    }, this.dataService.OneMinute*2.5);
  }
  logout(confirm:boolean=true){
   if(confirm){
     this.utils.showConfirm("¿Está seguro de cerrar sesión?", "", "Cerrar Sesión", "Cancelar").then((result: any) => {
      this.authService.logOut();
     });
   }else{
    this.dataService.clearAllData();
    let route:IRouteConstant = this.userTypeLogged  == this.dataService.USER_TYPE.user_driver ? RouteConstant.SPECIAL_USER_LOGIN : RouteConstant.LOGIN;
    this.route.navigate([route.url]);
   }
  }
}
