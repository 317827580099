import { Injectable } from '@angular/core';
import { ICountry } from 'src/app/shared/models/interfaces/country.model';
import { IUserPlanModule, IUserSessionData } from 'src/app/shared/models/interfaces/iuser-session-data.model';
import { ApiService } from '../api/api.service';
import { ValidationService } from '../validation/validation.service';
import { IResponseApi } from 'src/app/shared/models/interfaces/iresponse-api.model';
import { ObjectInitializationService } from '../object-initialization/object-initialization.service';
import { SpecialUser, UserType } from 'src/app/shared/types/common-types.type';
import { IDashboardMenu } from 'src/app/shared/models/interfaces/idashboard-menu.model';
import { IDeviceBasicData, IDeviceModel } from 'src/app/shared/models/interfaces/idevice.model';
import { IWebDomainElement, WebDomainKey } from '../constant/RouteConstant';
import { InvoiceObservation, MODULE_OPERATIONS_SGC } from 'src/app/shared/enums/common-enums.enum';
import { IBillingFrequency, IMonetaryunit, ITelephoneOperator, IVehicleType } from 'src/app/shared/models/interfaces/iutils-data.model';
import { ApiEndpointSgc } from 'src/app/shared/enums/api-endpoint-sgc.enum';

interface IActiveMenu{
  dashboard:{
    readonly name:"dashboard",
    show_view:boolean
  },
  web_tempate:{
    readonly name:"webTemplate",
    show_view:boolean
  }
}
type UTILS_DATA_NAME_LIST ="COUNTRIES"|"BASIC_USER_DEVICE_DATA"| "DEVICES_MODEL"|"TELEPHONE_OPERATORS" | "MONETARY_UNITS"|"VEHICLE_TYPES"|"BILLING_FECUENCIES"| "AUTOMATION_TYPE_LIST"; 

export interface IVehicleTypeIconItem{
  id:number, 
  name:string,
  icon:string,
  icon_type:"material_icon"|"font_awesome"
}
export interface IVehicleTypeIcon{
  bus:IVehicleTypeIconItem,
  car:IVehicleTypeIconItem,
  motorcycle:IVehicleTypeIconItem,
  truck:IVehicleTypeIconItem,
  machinery:IVehicleTypeIconItem,
  motorcar:IVehicleTypeIconItem,
  bicycle:IVehicleTypeIconItem,
  laptop:IVehicleTypeIconItem,
  Tractor_Trailer:IVehicleTypeIconItem,
  van:IVehicleTypeIconItem,
  electric_vehicle:IVehicleTypeIconItem,
  ambulance:IVehicleTypeIconItem,
  dump_truck:IVehicleTypeIconItem,
  tractor_truck:IVehicleTypeIconItem,
  truck_3_4:IVehicleTypeIconItem,
  taxi:IVehicleTypeIconItem,
  jeep:IVehicleTypeIconItem
}
@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(
    private api : ApiService,
    private validationService: ValidationService,
    private objectInitializationService:  ObjectInitializationService
  ) { }

  readonly UNKNOWN_ITEM_TAG = {
    CODE:"unknown",
    LABEL: "Sin información"
  };

  readonly MODULE_OPERATIONS_SGC = MODULE_OPERATIONS_SGC;
  /** 
   * @type {number} La cantidad de milisegundo en un segundo
  */
  OneSecond:number = 1000;//mileseconds
  /** 
   * @type {number} La cantidad de milisegundo en un minuto
  */
  OneMinute:number = this.OneSecond*60;
  /** 
   * @type {number} La cantidad de milisegundo en un día
  */
  OneDay:number = this.OneMinute * 60 * 24;
  
  /** Indica el dato de nombre de la columna de encabezado de numeración usadas en las tablas */
  public readonly NUMBERING_COLUMN_HEADER:{NAME:string,LABEL:string} = {NAME:"nro.",LABEL:"#"};
  /** indica el dato de nombre de columna de encabezado de las tablas que es usada para las acciones diponibles para cada fila */
  public readonly ACTION_COLUMN_HEADER:{NAME:string,LABEL:string} = {NAME:"action",LABEL:"Acción"};
  readonly PAGE_SIZE_OPTION: number[] =[20, 40, 60, 80];
  /**
   * @type {number} Indica el tiempo de espera para e lenvio de solicitud de datos a filtrar en una tabla
   */
  readonly DATA_TABLE_SEARCH_TIMEOUT: number = this.OneSecond * 0.8;
  /**
   * @type {number} Indica el tiempo de espera para el uso de acciones de comopnentes con spinner
   */
  readonly SPINNER_TIMEOUT: number = this.OneSecond * 0.1;
  
  /** @type {number} Cantidad maxima de caracteres de una etiqueta de columna */
  maxColumLabelLength:number = 30;
  
  /** @type {IUserPlanModule} Indica los permisos del modulo que se encuentre actualmente seleccionado */
  currentSelectedModulePermissionsSgc!:IUserPlanModule;
  /** Los  usuarios  especiales que se pueden loguear en SGC */
  SPECIAL_USER: Record<SpecialUser,SpecialUser> = { user_driver:"user_driver"};
  USER_TYPE: Record<UserType,UserType> = {...this.SPECIAL_USER,user:"user"}; 
  readonly TIME_UNITS:any = {"minutes":{value:"minutes",label:"Minuto",plural_label:"Minutos"},"hours":{value:"hours",label:"Hora",plural_label:"Horas"},"days":{value:"days",label:"Día",plural_label:"Días"},"months":{value:"months",label:"Mes",plural_label:"Meses"}};
  readonly USER_ROLES = {
    ADMIN:1,
    MANAGER:3,
    USER:2
  };
  /**
   * @type {IWebDomainElement } Almacena datos del dominio usado para conectarse al sitio web del proyecto
   */
  accessHostName: IWebDomainElement  | null = null; 
  /**
   * @type {WebDomainKey} la key de los datos de accessHostName que da acceso a dichos valores en IWebDomain
   */
  accessHostNameKey: WebDomainKey | null = null; 
  /**
   *  @type {UserType} Indica el tipo de usuario que esta logueado
   */
  private userTypeLogged: UserType = this.USER_TYPE.user;
  getUserTypeLogged(){
    return this.userTypeLogged;
  }
  setUserTypeLogged(value:UserType){
    return this.userTypeLogged = value;
  }
  readonly DATA_EXPORT_FORMAT = {
    csv:{label:"csv",code:"csv"},
    pdf:{label:"pdf",code:"pdf"},
    xlsx:{label:"Excel",code:"xlsx"}
  };

  readonly VEHICLE_TYPES_ICONS: IVehicleTypeIcon = {
    bus:{id:1, name:"Autobús",icon:"directions_bus",icon_type:"material_icon"},
    car:{id:2, name:"Automóvil",icon:"fa-solid fa-car-side",icon_type:"font_awesome" },
    motorcycle:{id:3, name:"Motocicleta",icon:"two_wheeler",icon_type:"material_icon" },
    truck:{id:4, name:"Camión",icon:"fa-solid fa-truck-front",icon_type:"font_awesome" },
    machinery:{id:5, name:"Maquinaria",icon:"forklift",icon_type:"material_icon" },
    motorcar:{id:6, name:"Motocarro",icon:"two_wheeler",icon_type:"material_icon" },
    bicycle:{id:7, name:"Bicicleta",icon:"pedal_bike",icon_type:"material_icon" },
    laptop:{id:8, name:"Portátil",icon:"laptop",icon_type:"material_icon" },
    Tractor_Trailer:{id:9, name:"Tractomula",icon:"fa-solid fa-truck-moving",icon_type:"font_awesome" },
    van:{id:10, name:"Camioneta",icon:"fa-solid fa-truck",icon_type:"font_awesome" },
    electric_vehicle:{id:11, name:"Vehículo eléctrico",icon:"electric_car",icon_type:"material_icon" },
    ambulance:{id:12, name:"Ambulancia",icon:"fa-solid fa-truck-medical",icon_type:"font_awesome" },
    dump_truck:{id:13, name:"Volqueta",icon:"fa-solid fa-truck-field",icon_type:"font_awesome" },
    tractor_truck:{id:14, name:"Tractocamión",icon:"fa-solid fa-truck-front",icon_type:"font_awesome" },
    truck_3_4:{id:15, name:"Camión 3/4",icon:"local_shipping",icon_type:"material_icon" },
    taxi:{id:16, name:"Taxi",icon:"local_taxi",icon_type:"material_icon" },
    jeep:{id:17, name:"Jeep",icon:"directions_car",icon_type:"material_icon" }
  };
  /**
   * Almacena la lista de nombres de datos almacenados en caché usados para formularios y demas carga de datos primarios desde el servidor 
   * @type {string} BASIC_USER_DEVICE_DATA Datos basicos de dispositivos asociados al usuario logueado  
   * @type {string} COUNTRIES  lista de paises  
   * */
  readonly UTILS_DATA_LIST:Record<UTILS_DATA_NAME_LIST,string> ={
    COUNTRIES:"countries",
    BASIC_USER_DEVICE_DATA:"basicUserDeviceData",
    DEVICES_MODEL:"DevicesModel",
    TELEPHONE_OPERATORS: "telephoneOperators",
    MONETARY_UNITS: "monetaryunits",
    BILLING_FECUENCIES: "billingFrequency",
    VEHICLE_TYPES: "vehicleTypes",
    AUTOMATION_TYPE_LIST: "automationTypeList"
  };
  /**
   * Almacena el id de los tipos de control de formularios disponibles
   */
  readonly FORM_CONTROL_TYPES:Record<"RADIO"|"CHECKBOX"|"RADIO_GRID"|"CHECKBOX_GRID",number> =  {"RADIO":1,"CHECKBOX":2,"RADIO_GRID":3,"CHECKBOX_GRID":4};
  /**
   * Almacena los tipos de accciones que pueden asociarse a una opcion de respuesta en un checklist
   */
  readonly CHECKLIST_ANSWER_OPTION_TYPE:Record<"REQUEST_IMAGE",number> = {REQUEST_IMAGE:1};

  activeMenu:IActiveMenu ={
    dashboard:{
      name:"dashboard",
      show_view:true
    },
    web_tempate:{
      name:"webTemplate",
      show_view:false
    }
  }; 
  typefaces: string[] = [
    "Arial",
    "Baskerville Old Face",
    "Times New Roman",
    "Helvetica",
    "Calibri",
    "Georgia",
    "Gill Sans MT",
    "Goudy Old Style",
    "Cambria",
    "Franklin Gothic Book",
    "Lucida Bright",
    "Lucida Sans",
    "Verdana",
    "Tahoma",
    "Trebuchet MS",
    "Century Gothic",
    "Didot",
    "Bodoni MT",
    "Calisto MT",
    "Candara",
    "Prompt",
    "Roboto",
    "Perpetua",
    "Palatino Linotype",
    "Courier New",
    "Rockwell",
    "Garamond",
    "Impact"
  ];
  booldTypes:Record<string,string>[]=[{value:"A+",label:"A positivo"},{value:"A-",label:"A negativo"},
  {value:"B+",label:"B positivo"},{value:"B-",label:"B negativo"},
  {value:"AB+",label:"AB positivo"},{value:"AB-",label:"AB negativo"},
  {value:"O+",label:"O positivo"},{value:"O-",label:"O negativo"}];
  readonly MENU: any = [
    {
      id: 1,
      rol: 0,
      name: "Inicio",
      icon: "fa-house",
      tab: "dashboard/home",
      children: []
    },
    {
      id: 2,
      rol: "administrator",//cambiar por administrator
      name: "Facturación",
      icon: "fa-file-invoice",
      tab: "dashboard/billing",
      children: []
    },
    {
      id: 16,
      rol: "user",
      name: "Control de flota",
      icon: "fa-car",
      tab: "dashboard/fleet-control",
      children: [
        {
          id: 18,
          rol: "user",
          name: "Gestion de checklist",
          icon: "fa-bars-progress",
          tab: "dashboard/fleet-control/device-checklist-management",
          children: []
        },
        {
          id: 19,
          rol: "user",
          name: "Gestión de estados",
          icon: "fa-clipboard-list",
          tab: "dashboard/fleet-control/device-state-management",
          children: []
        },
      ]
    },
    {
      id: 20,
      rol: "user",
      name: "Reportes PVS",
      icon: "fa-file-contract",
      tab: null,
      children: [
        {
          id: 21,
          rol: "user",
          name: "Control Rutas",
          icon: "fa-route",
          tab: "dashboard/pvs-reports/route-control-report",
          children: []
        },
        {
          id: 22,
          rol: "user",
          name: "Comportamiento conductor",
          icon: "fa-chart-column",
          tab: "dashboard/pvs-reports/driver-behavior",
          children: []
        },
        {
          id: 23,
          rol: "user",
          name: "Productividad flota",
          icon: "fa-arrow-up-right-dots",
          tab: "dashboard/pvs-reports/fleet-productivity",
          children: []
        },
      ]
    },
    {
      id: 17,
      rol: "user",
      name: "Gestión de conductores",
      icon: "fa-id-card",
      tab: "dashboard/driver-management",
      children: []
    },
    {
      id: 3,
      rol: "distributor",//cambiar por distributor
      name: "Clientes",
      icon: "fa-user",
      tab: "dashboard/clients",
      children: []
    },
    {
      id: 4,
      rol: "administrator",//cambiar por administrator
      name: "Distribuidores",
      icon: "fa-building",
      tab: "dashboard/distributors",
      children: []
    },
    {
      id: 5,
      rol: "distributor",//cambiar por distributor
      name: "Mis GPS",
      icon: "fa-list",
      tab: "dashboard/my-gps",
      children: []
    },
    {
      id: 6,
      rol: "distributor",//distribuidor
      name: "Pagos",
      icon: "fa-money-bill",
      tab: "dashboard/payments",
      children: []
    },
    {
      id: 7,
      rol: "distributor",//distribuidor
      name: "Mi cartera",
      icon: "fa-clipboard",
      tab: "dashboard/my-portfolio",
      children: []
    },
    {
      id: 8,
      rol: "distributor",//distribuidor
      name: "Tareas",
      icon: "fa-list-check",
      tab: "dashboard/tasks",
      children: []
    },
    {
      id: 9,
      rol: "distributor",//distribuidor
      name: "Mi cuenta",
      icon: "fa-building",
      tab: "dashboard/my-account",
      children: []
    },
    {
      id: 14,
      rol: "distributor",//distribuidor
      name: "Sitio web",
      icon: "fa-pager",
      tab: "dashboard/website",
      children: []
    },
    {
      id: 10,
      rol: "administrator",//admin
      name: "Gestión de planes",
      icon: "fa-address-card",
      tab: "dashboard/admin-plans",
      children: []
    },
    {
      id: 11,
      rol: "distributor",
      name: "Integraciones",
      icon: "fa-users",
      tab: "dashboard/integrations",
      children: []
    },
    {
      id: 26,
      rol: "distributor",
      name: "Automatización",
      icon: "fa-robot",
      tab: "dashboard/automations",
      children: []
    },
    {
      id: 28,
      rol: "user",
      name: "Reportes",
      icon: "fa-file-lines",
      tab: "dashboard/reports",
      children: []
    },
  ];

  readonly USER_DRIVER_MENU:IDashboardMenu[] = [
    {
      id: 18,
      rol: null,
      special_user:"user_driver",
      name: "Gestion de checklist",
      icon: "fa-bars-progress",
      tab: "dashboard/fleet-control/driver-device-checklist-management",
      children: []
    },
    {
      id: 19,
      rol: null,
      special_user:"user_driver",
      name: "Gestión de estados",
      icon: "fa-clipboard-list",
      tab: "dashboard/fleet-control/driver-device-state-management",
      children: []
    },
  ];
fuel_measurement=[
  {
    id:1,
    name:"l/100km"
  },
  {
    id:2,
    name:"MPG"
  },
  {
    id:3,
    name:"KWh/km"
  },
];
vehicleColors=[
  {id:1,name:"Azul"},
  {id:2,name:"Amarillo"},
  {id:3,name:"Blanco"},
  {id:4,name:"Beige"},
  {id:5,name:"Café"},
  {id:6,name:"Dorado"},
  {id:7,name:"Gris"},
  {id:8,name:"Negro"},
  {id:9,name:"Rojo"},
  {id:10,name:"Verde"},
  {id:11,name:"Extracto perla"},
  {id:12,name:"Otro"}
];

phone_prefix=[
  {
    code:"54",
    country:"Argentina"
  },
  {
    code:"591",
    country:"Bolivia"
  },
  {
    code:"55",
    country:"Brasil"
  },
  {
    code:"56",
    country:"Chile"
  },
  {
    code:"57",
    country:"Colombia"
  },
  {
    code:"593",
    country:"Ecuador"
  },
  {
    code:"34",
    country:"España"
  },
  {
    code:"33",
    country:"Francia"
  },
  {
    code:"595",
    country:"Paraguay"
  },
  {
    code:"51",
    country:"Perú"
  },
  {
    code:"598",
    country:"Uruguay"
  },
  {
    code:"58",
    country:"Venezuela"
  },
  {
    code:"1",
    country:"Canadá"
  },
  {
    code:"1",
    country:"Estados unidos"
  },
  {
    code:"52",
    country:"Mexico"
  },
  {
    code:"506",
    country:"Costa Rica"
  },
  {
    code:"53",
    country:"Cuba"
  },
  {
    code:"502",
    country:"Guatemala"
  },
  {
    code:"504",
    country:"Honduras"
  },
  {
    code:"876",
    country:"Jamaica"
  },
  {
    code:"505",
    country:"Nicaragua"
  },
  {
    code:"507",
    country:"Panamá"
  }, 
  {
    code:"1787",
    country:"Puerto Rico"
  },  
  {
    code:"809",
    country:"República Dominicana"
  }
];

readonly legal_personality_types ={individual_person:"Persona natural",natural_person_trader:"Persona natural comerciante",legal_person:"Persona jurídica"}; 
legal_personality_types_list =[{name:"individual_person",value:"Persona natural"},{name:"natural_person_trader",value:"Persona natural comerciante"},{name:"legal_person",value:"Persona jurídica"}];
courtesy_title = [{name:"mr",value:"El señor"},{name:"ms",value:"La señora"},{name:"company",value:"La empresa"}];
user:IUserSessionData = this.objectInitializationService.initializeIUserSessionData();

  currenciesAcceptedByPayPal:any=[
    {
      code:"AUD",
      currency:"dólar australiano"
    },
    {
      code:"BRL",
      currency:"reales brasileños"
    },
    {
      code:"CAD",
      currency:"Dolar canadiense"
    },
    {
      code:"CNY",
      currency:"Renmenbi chino"
    },
    {
      code:"CZK",
      currency:"corona checa"
    },
    {
      code:"DKK",
      currency:"corona danesa"
    },
    {
      code:"EUR",
      currency:"Euro"
    },
    {
      code:"HKD",
      currency:"Dolar de Hong Kong"
    },
    {
      code:"HUF",
      currency:"florín húngaro"
    },
    {
      code:"ILS",
      currency:"nuevo shekel israelí"
    },
    {
      code:"JPY",
      currency:"yen japonés"
    },
    {
      code:"MYR",
      currency:"ringgit malasio"
    },
    {
      code:"MXN",
      currency:"peso mexicano"
    },
    {
      code:"TWD",
      currency:"Nuevo dólar taiwanés"
    },
    {
      code:"NZD",
      currency:"Dolar de Nueva Zelanda"
    },
    {
      code:"NOK",
      currency:"corona noruega"
    },
    {
      code:"PHP",
      currency:"peso filipino"
    },
    {
      code:"PLN",
      currency:"złoty polaco"
    },
    {
      code:"GBP",
      currency:"Libra esterlina"
    },
    {
      code:"RUB",
      currency:"Rublo ruso"
    },
    {
      code:"SGD",
      currency:"dolar de Singapur"
    },
    {
      code:"SEK",
      currency:"Corona sueca"
    },
    {
      code:"CHF",
      currency:"Franco suizo"
    },
    {
      code:"THB",
      currency:"baht tailandés"
    },
    {
      code:"USD",
      currency:"dólar de los Estados Unidos"
    }
  ];
  /**
   * @type {InvoiceObservation[]} Las observaciones posibles dentro de una factura
   */
  readonly INVOICE_OBSERVATION:InvoiceObservation[]= [InvoiceObservation.PAID,InvoiceObservation.PENDING,InvoiceObservation.CANCELLED];
  //alamcena las opciones de notificacion que se pueden usar para enviar recordatorios a clientes 
  notificationOptions:any=[
    {
      id:1,
      name:"WhatsApp"
    }/* ,
    {
      id:2,
      name:"Email"
    } */
  ];
  bankAccountTypesList:string[] = ["Cuenta corriente","Cuenta de ahorro","Chequera electrónica","Cuenta Vista"];
  readonly deviceActivationStates:any={renewed:{name:"renewed",label:"Renovado"},expired:{name:"expired",label:"Expirado"},new:{name:"new",label:"Nuevo"},unlimited:{name:"unlimited",label:"Ilimitado"}};
  
  localData={//almacena la lista de nombres de variables localStorage usadas en la plataforma
    intervals:{
      idsList:"idsList",
      idUserDeviceList:"idUserDeviceList",
    }    
  };
  setData(name: any, data: any){
    localStorage.setItem(name + "_intgps_data", JSON.stringify(data));
  }
  removeData(name: any){
    localStorage.removeItem(name + "_intgps_data");
  }

  getData(name: any){
    return JSON.parse(localStorage.getItem(name + "_intgps_data") || "{}");
  }

  updateIntervalIdsList(id:any){
    if(Object.entries(this.getData(this.localData.intervals.idsList)).length<1)
      this.setData(this.localData.intervals.idsList,id);
    else
      this.setData(this.localData.intervals.idsList,this.getData(this.localData.intervals.idsList).push(id));
  }
  clearAllData(){
    localStorage.clear();
  }

  async getCountries():Promise<IResponseApi>{
      try{
        let responseData:IResponseApi = {status:0, data: []};
        let countries:ICountry[] = this.getData(this.UTILS_DATA_LIST.COUNTRIES) as ICountry[];
        if(Object.entries(countries).length>0)
          return {status:1, data: countries};
        let user: IUserSessionData = this.getData("user");
        let response:unknown = await this.api.getData("country","?action=getCountries"+(!this.validationService.isEmptyObject(user) ? ("&user_api_hash="+user.hash) : ""),true);  
        if(this.validationService.isResponseApi(response)){
          responseData.data = response.status ==1 ? response.data as ICountry[] : [];
          responseData.status = response.status; 
          this.setData(this.UTILS_DATA_LIST.COUNTRIES, countries);
        }
        return responseData;
    }catch(error){
      return {status:0, data: []};
    }
  }
  /**
   * @param countries El objeto a poblar de datos. Enviar un objeto vacío
   */
  async fillCountries(countries:ICountry[]):Promise<void>{
    try{
      let countriesList:ICountry[] = this.getData(this.UTILS_DATA_LIST.COUNTRIES) as ICountry[];
      if(Object.entries(countriesList).length>0){
        countries.push(...countriesList) ;
        return;
      }
      let user: IUserSessionData = this.getData("user");
      let response:unknown = await this.api.getData("country","?action=getCountries"+(!this.validationService.isEmptyObject(user) ? ("&user_api_hash="+user.hash) : ""),true);  
      if(this.validationService.isResponseApi(response) && response.status == 1){
        countries.push(...response.data as ICountry[]);
        this.setData(this.UTILS_DATA_LIST.COUNTRIES, countriesList);
      }
    }catch(error){}
  }
  /**
   * @param devicesModel El objeto a poblar de datos. Enviar un objeto vacío
   */
  async fillDevicesModel(devicesModel:IDeviceModel[]):Promise<void>{
    try{
      let devicesModelList:IDeviceModel[] = this.getData(this.UTILS_DATA_LIST.DEVICES_MODEL) as IDeviceModel[];
      if(Object.entries(devicesModelList).length>0){
        devicesModel.push(...devicesModelList) ;
        return;
      }
      let user: IUserSessionData = this.getData("user");
      let response:unknown = await this.api.getData("device","?action=getDevicesModel"+(!this.validationService.isEmptyObject(user) ? ("&user_api_hash="+user.hash) : ""),true);  
      if(this.validationService.isResponseApi(response) && response.status == 1){
        devicesModel.push(...response.data as IDeviceModel[]);
        this.setData(this.UTILS_DATA_LIST.DEVICES_MODEL, devicesModelList);
      }
    }catch(error){}
  }

  /**
 * @param telephoneOperatorsList El objeto a poblar de datos. Enviar un objeto vacío
 */
  async fillTelephoneOperators(telephoneOperators:ITelephoneOperator[]):Promise<void>{
    try{
      let telephoneOperatorsList:IDeviceModel[] = this.getData(this.UTILS_DATA_LIST.TELEPHONE_OPERATORS) as ITelephoneOperator[];
      if(Object.entries(telephoneOperatorsList).length>0){
        telephoneOperators.push(...telephoneOperatorsList) ;
        return;
      }
      let user: IUserSessionData = this.getData("user");
      let response:unknown = await this.api.getData("telephoneOperator","?action=getTelephoneOperators"+(!this.validationService.isEmptyObject(user) ? ("&user_api_hash="+user.hash) : ""),true);  
      if(this.validationService.isResponseApi(response) && response.status == 1){
        telephoneOperators.push(...response.data as ITelephoneOperator[]);
        this.setData(this.UTILS_DATA_LIST.TELEPHONE_OPERATORS, telephoneOperatorsList);
      }
    }catch(error){}
  }

  /**
 * @param monetaryunits El objeto a poblar de datos. Enviar un objeto vacío
 */
  async fillMonetaryunits(monetaryunits:IMonetaryunit[]):Promise<void>{
    try{
      let monetaryunitsList:IMonetaryunit[] = this.getData(this.UTILS_DATA_LIST.MONETARY_UNITS) as IMonetaryunit[];
      if(Object.entries(monetaryunitsList).length>0){
        monetaryunits.push(...monetaryunitsList) ;
        return;
      }
      let user: IUserSessionData = this.getData("user");
      let response:unknown = await this.api.getData("plan","?action=getMonetaryUnits"+(!this.validationService.isEmptyObject(user) ? ("&user_api_hash="+user.hash) : ""),true);  
      if(this.validationService.isResponseApi(response) && response.status == 1){
        monetaryunits.push(...response.data as IMonetaryunit[]);
        this.setData(this.UTILS_DATA_LIST.MONETARY_UNITS, monetaryunitsList);
      }
    }catch(error){}
  }

  
/**
 * @param vehicleTypes El objeto a poblar de datos. Enviar un objeto vacío
 */
  async fillVehicleTypes(vehicleTypes:IVehicleType[]):Promise<void>{
    try{
      let vehicleTypesList:IVehicleType[] = this.getData(this.UTILS_DATA_LIST.VEHICLE_TYPES) as IVehicleType[];
      if(Object.entries(vehicleTypesList).length>0){
        vehicleTypes.push(...vehicleTypesList) ;
        return;
      }
      let user: IUserSessionData = this.getData("user");
      let response: unknown = await this.api.getData("vehicleType","?"+(!this.validationService.isEmptyObject(user) ? ("&user_api_hash="+user.hash) : ""),true);  
      if(this.validationService.isResponseApi(response) && response.status == 1){
        vehicleTypes.push(...response.data as IVehicleType[]);
        this.setData(this.UTILS_DATA_LIST.VEHICLE_TYPES, vehicleTypesList);
      }
    }catch(error){}
  }

  /**
 * @param billingFrequencies El objeto a poblar de datos. Enviar un objeto vacío
 */
  async fillBillingFrequencies(billingFrequencies:IBillingFrequency[]):Promise<void>{
    try{
      let billingFrequenciesList:IBillingFrequency[] = this.getData(this.UTILS_DATA_LIST.BILLING_FECUENCIES) as IBillingFrequency[];
      if(Object.entries(billingFrequenciesList).length>0){
        billingFrequencies.push(...billingFrequenciesList) ;
        return;
      }
      let user: IUserSessionData = this.getData("user");
      let response: unknown = await this.api.getData("plan","?action=getBillingFrequencies&"+(!this.validationService.isEmptyObject(user) ? ("&user_api_hash="+user.hash) : ""),true);  
      if(this.validationService.isResponseApi(response) && response.status == 1){
        billingFrequencies.push(...response.data as IBillingFrequency[]);
        this.setData(this.UTILS_DATA_LIST.BILLING_FECUENCIES, billingFrequenciesList);
      }
    }catch(error){}
  }
  async getAvailableMaps():Promise<IResponseApi>{
    try{
      let user: IUserSessionData = this.getData("user");
      let responseData:IResponseApi = {status:0, data: []};
      let response:unknown = await this.api.getDataPost(ApiEndpointSgc.CONFIGS,{action: "getAvailableMaps", user_api_hash: user.hash},true);  
      if(this.validationService.isResponseApi(response)){
        responseData.data = response.status ==1 ? response.data as ICountry[] : [];
        responseData.status = response.status; 
      }
      return responseData;
    }catch(error){
      return {status:0, data: {}};
    }
  }
    
  /**
   * @description Obtiene la lista de dispositivos asociados al usuario 
   * @return {Promise<IResponseApi & {data?:IDeviceBasicData[]}>} Retorna la lista de datos basicos de dispositivos del usuario */
  async getAvailableDevicesBasicDataList(deviceId?:number):Promise<IResponseApi & {data?:IDeviceBasicData[]}>{
    let user: IUserSessionData = this.getData("user");
    if(this.validationService.isEmptyObject(user))
      return {status:0};
    let data = "?action=getBasicDeviceData&user_api_hash="+user.hash + (typeof deviceId !="undefined" ? ("&id="+deviceId) : "");    
    try{
      let devicesData:unknown = await this.api.getData("device",data,true);
      return this.validationService.isResponseApi(devicesData) ? devicesData : {status:0};
    }catch(error:any){
      return {status:0};
    }
  }
  //para los permisos de INTGPS
  checkPermissionIntgpsModule(module: any){
    return new Promise((resolve, reject) => {
      let user_permissions = this.getData("permissionsIntgps");
        user_permissions = user_permissions[module];
        if(typeof user_permissions !="undefined"){
          resolve(user_permissions);
        }else{
          reject(false);
        }
      
    });
  }
  checkPermissionIntgps(name: any, data: any){
      return data[name]==true;
  }
  checkPermissionModule(moduleId: number):Promise<IUserPlanModule>{
    return new Promise((resolve, reject) => {
      let permissions =  this.getData("userSubscriptionPlan").modules.find((module:any)=>{
        if(module.module_id==moduleId)return module.permissions;});
      typeof permissions !="undefined"?resolve(permissions):reject(false);
    });
  }
  checkPermission(id: any, data: any){
    return typeof data != "undefined" &&  (data.find((perm: any) => perm.module_operation_id == id) ?? false);
  }
}
