<section class="form-box-container animate__animated animate__fadeIn">
    <section class="form-box-container__header">
        <h2 class="form-box-container__header-title"><span class="icon"><i
                    class="fa-solid fa-satellite-dish"></i></span> Total de distribuidores: <strong
                class="text-primary"> {{distributorsTotal}}</strong></h2>
        <div class="form-box-container__header-options">
            <button class="btn btn-primary animate__animated animate__fadeInDown"
                [ngClass]="{'animate__fadeOutUp':distributorsQuantityChartView}" role="button"
                (click)="distributorsQuantityChartView=true"> <i class="fa-solid fa-chart-simple icon"></i> <span
                    class="description">Gráfico</span></button>
            <div class="search">
                <input id="inputSearch" type="search" placeholder="Buscar" (input)="search()" name="searchText" [(ngModel)]="searchText">
                <label for="inputsearch"><i class="fa-solid fa-magnifying-glass"></i></label>
            </div>
        </div>
    </section>
    <div class="form-box animate__animated animate__fadeIn">
        <div class="form-box__content  form-box__content-h-auto  animate__animated animate__fadeIn"
            *ngIf="distributorsQuantityChartView">
            <div
                class="form-box__content-header form-box__content-header-horizontal form-box__content-header-align-end  animate__animated animate__fadeIn">
                <div class="form-box-container-close animate__animated animate__fadeInDown " role="button"
                    (click)="distributorsQuantityChartView=false">
                </div>
            </div>
            <div class="form-box__content-section animate__animated animate__fadeIn">
                <div class="d-block">
                    <div class="chart" *ngIf="configDistibutorsCharts.data.datasets[0].data.length > 0">
                        <div class="chart__content">
                            <div class="chart__content-header">
                                <div class="chart__content-header-titles-group">
                                    <div class="chart__content-header-title">Cantidad de distribuidores por mes de registro</div>
                                    <div class="chart__content-header-subtitle">{{annotation}}</div>
                                </div>
                                <div class="chart__content-header-options">
                                    <button matTooltipPosition="left" class="btn btn-primary" matTooltip="Filtrar por rango de fecha">
                                        <i class="fa-solid fa-calendar-week"></i> <span  class="description"> Fecha</span>
                                        
                                        <mat-datepicker-toggle matIconSuffix [for]="rangePicker"  class="full-cover-container transparent"></mat-datepicker-toggle>

                                        <mat-date-range-input disabled [formGroup]="range" [rangePicker]="rangePicker" class="full-cover-container transparent z-index-last">
                                            <input formControlName="start" matStartDate>
                                            <input formControlName="end" matEndDate>
                                        </mat-date-range-input>
                                        <mat-date-range-picker #rangePicker disabled="false" class="full-cover-container transparent z-index-last">
                                            <mat-date-range-picker-actions>
                                                <button class="btn btn-outline-secondary"
                                                    mat-button matDateRangePickerCancel>Cancelar</button>
                                                <button class="btn btn-primary" mat-raised-button color="primary"
                                                    (click)="filterDistributorsBydateRange(range.value.start,range.value.end,true)"
                                                    matDateRangePickerApply>Aceptar</button>
                                            </mat-date-range-picker-actions>
                                        </mat-date-range-picker>
                                    </button>
                                </div>
                            </div>
                            <div class="chart__content-canvas animate__animated animate__fadeIn"
                                *ngIf="configDistibutorsCharts.updated_chart">
                                <canvas baseChart [data]="configDistibutorsCharts.data" [options]="configDistibutorsCharts.chart_options"
                                    [plugins]="configDistibutorsCharts.chart_plugins" [type]="configDistibutorsCharts.chart_type">
                                </canvas>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="form-box__content form-box__content-h-auto  animate__animated animate__fadeIn"
            *ngIf="!distributorsQuantityChartView">
            <div class="w-100 overflow-auto">
                <table mat-table matSort (matSortChange)="sortData($event)" [dataSource]="distributorsData">
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                            sortActionDescription="Ordenar por nombre"> Nombre </th>
                        <td mat-cell *matCellDef="let distributor"> {{distributor.name!='' && distributor.name != null?distributor.name:
                            distributor.email}} </td>
                    </ng-container>
                    <ng-container matColumnDef="platform_plan_name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                            sortActionDescription="Ordenar plan suscrito"> Plan suscrito </th>
                        <td mat-cell *matCellDef="let distributor"> {{distributor.platform_plan_name}} </td>
                    </ng-container>
                    <ng-container matColumnDef="total_devices">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                            sortActionDescription="Ordenar por total de dispositivos"> Total de dispositivos </th>
                        <td mat-cell class="text-end" *matCellDef="let distributor"> {{distributor.total_devices}} </td>
                    </ng-container>
                    <ng-container matColumnDef="total_users">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                            sortActionDescription="Ordenar por total de usuarios"> total de usuarios </th>
                        <td mat-cell class="text-end" *matCellDef="let distributor"> {{distributor.total_users}} </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumnsInDistributortable; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumnsInDistributortable;"></tr>
                    <tr *matNoDataRow>
                        <td class="mat-cell empty py-1" [attr.colspan]="displayedColumnsInDistributortable.length">Sin
                            datos</td>
                    </tr>
                </table>
            </div>
            <mat-paginator [length]="pagination.length_page" [pageSize]="pagination.page_size" [pageIndex]="pagination.current_page"
            [pageSizeOptions]="pagination.page_size_options" (page)="paginator($event)"
            aria-label="Selecciona pagina de la lista de dispositivos">
          </mat-paginator>
        </div>
    </div>
    <div class="loader" *ngIf="loading">
        <img src="../../../assets/loader.svg">
    </div>
</section>