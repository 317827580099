import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UtilsService } from 'src/app/core/utils/utils.service';
import { IDeviceStatusOption, IDeviceStatusOptionConfiguration } from 'src/app/shared/models/interfaces/idevice-status-option.model';
import { ValidationService } from '../../../../core/validation/validation.service';
import { ApiService } from 'src/app/core/api/api.service';
import { IApiRequestData } from 'src/app/shared/models/interfaces/iapi-request-data.model';
import { IUserSessionData } from 'src/app/shared/models/interfaces/iuser-session-data.model';
import { DataService } from 'src/app/core/data/data.service';
import { ObjectInitializationService } from '../../../../core/object-initialization/object-initialization.service';

import { MatMenuTrigger } from '@angular/material/menu';
import { MessageBoxService } from 'src/app/core/message-box/message-box.service';

type MaxFieldLength = Pick <IDeviceStatusOption,"name"|"description">;
interface IDeviceStatusToUpdate{
  name:string,
  description:string|null,
  enabled:1|0,
  user_id:number|null,
  id:number|null
  position?:number
}
@Component({
  selector: 'app-device-state-management',
  templateUrl: './device-state-management.component.html',
  styleUrls: ['./device-state-management.component.scss']
})
export class DeviceStateManagementComponent implements OnInit {

  @ViewChild(MatMenuTrigger) deviceStatusForm!: MatMenuTrigger;

  loading:boolean=false;
  user!:IUserSessionData;
  deviceStatusList: IDeviceStatusOption[]=[];
  /** @type {IDeviceStatusOption}  Almacena el estado seleccionado por el usuario para ser editado o creado */
  deviceStatusSelected!:IDeviceStatusOption;
  /** Almacena los datos concretos del estado a actualizar o crear */
  deviceStatusToUpdate!: Pick <IDeviceStatusOption,"name"|"description"|"user_id"|"id"> & Pick <IDeviceStatusOptionConfiguration,"enabled">;

  maxFieldLength!: Record<keyof MaxFieldLength,number>
  moduleId=19;
  permissionsDataSgc:any=[];//permisos sobre el modulo
  constructor(
    private router:Router,
    private utils: UtilsService,
    public validationService:ValidationService,
    private api:ApiService,
    private dataService:DataService,
    private initializationService:ObjectInitializationService,
    private route:Router,
    private messageBox: MessageBoxService
  ){
    this.user = this.dataService.getData("user") as IUserSessionData;
    this.deviceStatusSelected = this.initializationService.initializeIDeviceStatusOption(this.user.id)
    this.deviceStatusToUpdate = {name:"",description:"",enabled:1,id:null,user_id:this.user.id};
    this.maxFieldLength = {
      name:50,
      description:200
    };
  }

  ngOnInit(): void {
    this.dataService.checkPermissionModule(this.moduleId).then((permissions: any) => {
      this.permissionsDataSgc = permissions;
      this.getDeviceStatusConfigList();
    }).catch(() => {
      this.utils.showMsg("Página no autorizada","No tiene permisos para ver esta página, contacte al administrador");
      this.router.navigate(['/dashboard/fleet-control']);
    });
  }
  getDeviceStatusConfigList(){
    let data:IApiRequestData = { user_api_hash:this.user.hash, action:"get",include_status_config:true };
    this.loading = true;
    this.api.getDataPost("deviceStatusOption",data,true).then((data: any) => {  
      this.deviceStatusList = data.status==1 ? data.data:[];
      console.log(this.deviceStatusList);
      this.utils.hideLoading(()=>this.loading=false);
    }).catch((error)=>{
      this.utils.showResultRequest("error","Información",this.api.getDefaultMessage("la lista de estados de dispositivos",false,false,"GET"));
      this.utils.hideLoading(()=>this.loading=false);
  });
  }
  prepareStatusToEdit(status:IDeviceStatusOption|null=null){
    this.deviceStatusSelected = status !=null ? this.utils.copyObject(status):this.initializationService.initializeIDeviceStatusOption(this.user.id);
    this.deviceStatusToUpdate.name = status !=null ?(
      this.deviceStatusSelected.user_id == null?(
        this.validationService.isNullOrEmpty(this.deviceStatusSelected.device_status_option_configuration?.custom_name)?this.deviceStatusSelected.name:(
          this.deviceStatusSelected.device_status_option_configuration!.custom_name??"")):(this.deviceStatusSelected.name)
    ):("");
    this.deviceStatusToUpdate.description = status !=null ?(
      this.deviceStatusSelected.user_id == null?(
        this.validationService.isNullOrEmpty(this.deviceStatusSelected.device_status_option_configuration?.custom_description)?this.deviceStatusSelected.description:(
          this.deviceStatusSelected.device_status_option_configuration!.custom_description??"")):(this.deviceStatusSelected.description)
    ):("");
    this.deviceStatusToUpdate.enabled = this.deviceStatusSelected.device_status_option_configuration!.enabled??1;
    this.deviceStatusToUpdate.id = this.deviceStatusSelected.id;
    this.deviceStatusToUpdate.user_id = this.deviceStatusSelected.user_id;
  }
  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.deviceStatusList, event.previousIndex, event.currentIndex);
    if(event.currentIndex ==event.previousIndex)
      return;
    let statusOption: ({device_status_option_id:number|null,position:number})[] =[];
    this.deviceStatusList.forEach((item:IDeviceStatusOption,index:number)=>statusOption.push({device_status_option_id:item.id,position:index+1}));
    let data: IApiRequestData = { user_api_hash:this.user.hash, action:"updatePositions", device_status_option: statusOption};
    this.loading=true;
    this.api.createData(data, "deviceStatusOptionConfiguration",true).then((data: any) => {
      this.messageBox.openSnackBar(data.message,"Aceptar");
      this.utils.hideLoading(()=>this.loading=false);
    }).catch((err: any) => {
      this.utils.hideLoading(()=>this.loading=false);
      this.utils.showResultRequest("error","Información",this.api.getDefaultMessage("posiciones de la lista de estados de dispositivos",true,false,"POST"));
    });
  }
  deleteStatus(status:IDeviceStatusOption){
    this.prepareStatusToEdit(status);
    this.utils.showConfirm("¿Eliminar estado de dispositivo <strong>'"+this.deviceStatusToUpdate.name+"'</strong>?",this.validationService.isNullOrEmpty(status.user_id)?"Este estado es uno predeterminado, por lo cual solo se borrará la configuración asociada a ella":"","Confirmar","Cancelar").then(()=>{
      let data: IApiRequestData & {id?:number,device_status_option_id?:number} = { user_api_hash:this.user.hash, action:"delete" };
      data[this.validationService.isNullOrEmpty(status.user_id)?"device_status_option_id":"id"] = status.id??0;
      this.api.createData(data, this.validationService.isNullOrEmpty(status.user_id)?"deviceStatusOptionConfiguration":"deviceStatusOption",true).then((data: any) => {
        data.status==1?this.getDeviceStatusConfigList():null;
        this.utils.showResultRequest(data.status==1?"success":"error","Información",data.message);
        this.utils.hideLoading(()=>this.loading=false);
      }).catch((err: any) => {
        this.utils.hideLoading(()=>this.loading=false);
        this.utils.showResultRequest("error","Información",this.api.getDefaultMessage("el estado de dispositivo",false,false,"DELETE"));
      });
    }).catch(()=>{ });
  }
  updateStatus(){
    let data:IDeviceStatusToUpdate & IApiRequestData  = {
      user_api_hash:this.user.hash,
      action:this.deviceStatusToUpdate.id==null?"add":"update",
      ...this.deviceStatusToUpdate
    };
    this.loading=true;
    this.api.createData(data, "deviceStatusOption",true).then((data: any) => {
      data.status==1?this.getDeviceStatusConfigList():null;
      data.status==1?this.closeEditForm():null;
      this.utils.showResultRequest(data.status==1?"success":"error","Información",data.message);
      this.utils.hideLoading(()=>this.loading=false);
    }).catch((err: any) => {
      this.utils.hideLoading(()=>this.loading=false);
      this.utils.showResultRequest("error","Información",this.api.getDefaultMessage("la lista de estados de dispositivos",!this.deviceStatusToUpdate.id==null,false,"POST"));
    });
  }
  closeEditForm(): void {
    this.deviceStatusForm.closeMenu();
  }
  /*back(): void {
    this.router.navigate(["/dashboard/fleet-control"]);
  }*/
}