<section class="form-box-container animate__animated animate__fadeIn">
    <section class="form-box-container__header">
      <span class="form-box-container__header-back" (click)="closeComponent()"><span class="description">Volver</span> </span>
        
        <h2 class="form-box-container__header-title"><span class="icon"><i class="fa-solid fa-money-bill-trend-up"></i></span>Renovaciones</h2>
        <div class="form-box-container__header-options">
            <button class="btn btn-primary" [matMenuTriggerFor]="actionsConfig"><i class="fa-solid fa-file-arrow-down"></i>  <span class="description">Exportar</span></button>
            <mat-menu #actionsConfig="matMenu" class="actions">
                <button class="text-primary" *ngIf="!isUserDevicesSelected" [ngClass]="{'animate__fadeOutLeft': isUserDevicesSelected,' animate__fadeInDown': !isUserDevicesSelected}"(click)="exportFile(distributorsByTotalExpGpsSorted,['email','name','devicesExpiredTotal','soonToDevicesExpireTotal'],'distByTotalExpDateDevices')" mat-menu-item  matTooltip="Tabla de resumen de distribuidores" matTooltipPosition="right"><i class="fa-solid fa-users-viewfinder"></i> Distribuidores</button>
                <button  class="text-primary"mat-menu-item (click)="exportFile(devicesWithExpDateSorted,devicesExpHeader,'devicesByExpDate')"   matTooltip="Tabla de dispositivos" matTooltipPosition="right" ><i class="fa-solid fa-microchip"></i> Dispositivos</button>
            </mat-menu>

            <button class="btn btn-outline-secondary" [matMenuTriggerFor]="dataPresentationMenu" matTooltip="Permite seleccionar que datos se cargan en la tabla general"><i class="fa-solid fa-table-list"></i>  <span class="description">Presentación</span></button>            
            <mat-menu #dataPresentationMenu="matMenu">
                <button mat-menu-item matTooltipPosition="left" (click)="toggleLoadExpirationTableDate()" [ngClass]="{'text-primary': deviceExpTableDataSource =='soonToExpire'}" matTooltip="Cargar en la tabla general los dispositivos expirados" [disabled]="deviceExpTableDataSource !=='soonToExpire'"><i class="fa-solid fa-list-ol"></i> {{'Expirados'}}</button>
                <button mat-menu-item matTooltipPosition="left" (click)="toggleLoadExpirationTableDate()"  matTooltip="Cargar en la tabla general los dispositivos próximos a vencer" [ngClass]="{'text-primary': deviceExpTableDataSource !='soonToExpire'}" [disabled]="deviceExpTableDataSource =='soonToExpire'"><i class="fa-solid fa-list-ol"></i> {{'Próximos a vencer'}}</button>
            </mat-menu>
            <div class="search">
                <input id="inputSearchEmailInExp" type="search" placeholder="Buscar email"(input)="search('distributorsByTotalExpGps')" [(ngModel)]="searchEmailByExpirations">
                <label for="inputSearchEmailInExp"><i class="fa-solid fa-magnifying-glass fa-sm"></i></label>
            </div>
        </div>
        
    </section>
    <div class="form-box two-panel two-panel--vertical  animate__animated animate__fadeIn">
        <div class="form-box__content two-panel two-panel--horizontal form-box__content-fill" >
            <section class="form-box__content-section form-box__content-fill">
                <div class="w100 overflow-auto">
                    <table mat-table matSort (matSortChange)="sortData($event,'distributorsByTotalExpGps')" [dataSource]="distributorsByTotalExpGpsSorted">
              
                      <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Ordenar por nombre"> Nombre </th>
                        <td mat-cell *matCellDef="let distributor"> {{distributor.name!=''?distributor.name: distributor.email}} </td>
                      </ng-container>
                      <ng-container matColumnDef="devicesExpiredTotal">
                        <th mat-header-cell *matHeaderCellDef  mat-sort-header [matTooltip]="deviceExpTableDataSource=='expired' ? 'Datos cargados en la tabla general' : ''" sortActionDescription="Ordenar por fecha de modelo" [ngClass]="{'text-warning':deviceExpTableDataSource=='expired'}"> 
                            Expirados 
                        </th>
                        <td mat-cell *matCellDef="let distributor"> 
                            <div class="d-flex justify-content-between align-items-center">
                                <span class="text-end">{{distributor.devicesExpiredTotal}}</span>
                                <span>
                                    <button  class="btn btn-primary btn-icon ms-auto" [disabled]="distributor.devicesExpiredTotal==0" [ngClass]="{'disabled':distributor.devicesExpiredTotal==0}" (click)="loadUserDevicesByExpiration(expirationRange.value.start,expirationRange.value.end,distributor.id,'expired',distributor.email)" matTooltip="Ver dispositivos"><i class="fa-solid fa-eye fa-sm"></i></button>
                                </span>
                            </div>
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="soonToDevicesExpireTotal">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header  [matTooltip]="deviceExpTableDataSource=='soonToExpire' ? 'Datos cargados en la tabla general' : ''" [ngClass]="{'text-warning':deviceExpTableDataSource=='soonToExpire'}"> 
                            Próximas expiraciones 
                        </th>
                        <td mat-cell *matCellDef="let distributor"> 
                            <div class="d-flex justify-content-between align-items-center">
                                <span class="text-end">{{distributor.soonToDevicesExpireTotal}}</span>
                                <span>
                                    <button  class="btn btn-primary btn-icon" [disabled]="distributor.soonToDevicesExpireTotal==0" [ngClass]="{'disabled':distributor.soonToDevicesExpireTotal==0}" (click)="loadUserDevicesByExpiration(expirationRange.value.start,expirationRange.value.end,distributor.id,'soonToExpire',distributor.email)" matTooltip="Ver dispositivos"><i class="fa-solid fa-eye fa-sm"></i></button>
                                </span>
                            </div>
                        </td>
                      </ng-container>
              
                      <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell empty py-1" [attr.colspan]="displayedColumnsInDataSummary.length">Sin datos</td>
                    </tr>
                      <tr mat-header-row *matHeaderRowDef="displayedColumnsInDataSummary; sticky: true"></tr>
                      <tr mat-row *matRowDef="let row; columns: displayedColumnsInDataSummary;"></tr>
                    
                    </table>
                  </div>
            </section>
            <section class="form-box__content-section form-box__content-auto">
                <strong class="form-box__content-title">Rango de fecha de datos filtrados</strong>
                <!--<section class="form-box__content-header">
                    <span class="form-box__content-subtitle"> Del {{(range.value.start |date:'longDate') + " - " + (range.value.end |date:'longDate')}}</span>
                </section>-->
                <div class="d-flex justify-content-center align-items-center ">
                    <mat-form-field class="animate__animated animate__fadeIn " *ngIf="isDatePickerRange" appearance="fill" class="example-form-field">
                        <mat-label>Ingrese un rango de fecha</mat-label>
    
                        <mat-date-range-input  disabled [formGroup]="expirationRange" [rangePicker]="expirationRangePicker">
                        <input formControlName="start"   matStartDate placeholder="yyyy-mm-dd">
                        <input formControlName="end" matEndDate placeholder="yyyy-mm-dd">
                        </mat-date-range-input>
                        <mat-datepicker-toggle matIconSuffix [for]="expirationRangePicker"></mat-datepicker-toggle>
                        <mat-date-range-picker  #expirationRangePicker disabled="false">
                        <mat-date-range-picker-actions>
                            <button class="btn btn-outline-secondary" style="margin:0.1rem;" mat-button matDateRangePickerCancel>Cancelar</button>
                            <button class="btn btn-primary" mat-raised-button color="primary" (click)="filterDistByExpRange(expirationRange.value.start,expirationRange.value.end)"  matDateRangePickerApply>Aceptar</button>
                        </mat-date-range-picker-actions>
                        </mat-date-range-picker>
                    </mat-form-field> 
                </div>
            </section>
        </div>

        <div class="form-box__content form-box__content-fill" >
            <section class="form-box__content-section">
                <div class="w100 overflow-auto">
                    <table mat-table matSort (matSortChange)="sortData($event,'devicesWithExpDate')" [dataSource]="devicesWithExpDateSorted">
                
                        <ng-container matColumnDef="index">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Ordenar por nombre"> Item </th>
                        <td mat-cell *matCellDef="let device; index as counter"> {{counter + 1}} </td>
                        </ng-container>

                        <ng-container matColumnDef="distributorName" *ngIf="!isUserDevicesSelected">
                        <th mat-header-cell *matHeaderCellDef  mat-sort-header sortActionDescription="Ordenar por nombre de distribuidor"> Distribuidor </th>
                        <td mat-cell *matCellDef="let device">
                            {{device.distributorName || device.distributorEmail}}
                        </td>
                        </ng-container>

                        <ng-container matColumnDef="imei">
                        <th mat-header-cell *matHeaderCellDef  mat-sort-header sortActionDescription="Ordenar por imei"> Imei </th>
                        <td mat-cell *matCellDef="let device">{{device.imei}}</td>
                        </ng-container>

                        <ng-container matColumnDef="deviceName">
                        <th mat-header-cell *matHeaderCellDef  mat-sort-header sortActionDescription="Ordenar por nombre de dispositivo"> Nombre </th>
                        <td mat-cell *matCellDef="let device">{{device.name}}</td>
                        </ng-container>

                        <ng-container matColumnDef="plate_number">
                        <th mat-header-cell *matHeaderCellDef  mat-sort-header sortActionDescription="Ordenar por numero de placa"> Placa </th>
                        <td mat-cell *matCellDef="let device">{{device.plate_number}}</td>
                        </ng-container>

                        <ng-container matColumnDef="last_connection">
                        <th mat-header-cell *matHeaderCellDef  mat-sort-header sortActionDescription="Ordenar por Última conexión"> Última conexión </th>
                        <td mat-cell *matCellDef="let device">{{device.last_connection}}</td>
                        </ng-container>

                        <ng-container matColumnDef="expiration_date">
                        <th mat-header-cell *matHeaderCellDef  mat-sort-header sortActionDescription="Ordenar por fecha de expiración"> Fecha de expiración </th>
                        <td mat-cell *matCellDef="let device">{{device.expiration_date}}</td>
                        </ng-container>

                        <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell empty py-1" [attr.colspan]="displayedColumnsInGeneralTable.length - (!isUserDevicesSelected ? 0 : 1)">Sin datos</td>
                    </tr>
                        <tr mat-header-row *matHeaderRowDef="displayedColumnsInGeneralTable; sticky: true"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumnsInGeneralTable;"></tr>
                    
                    </table>
                    </div>
                    <div class="loader" *ngIf="loadingExpDeviceList">
                    <img src="../../../../../assets/loader.svg">
                </div>
            </section>

        </div>
    </div>
    <div class="loader" *ngIf="loading">
        <img src="../../../../../assets/loader.svg">
    </div>
</section>