<section class="login-box animate__animated animate__fadeIn ">
    <article class="login-box__left">
        <div class="login-box__image">
            <img src="../../../assets/img/driver-login.png" alt="logo inicio de sesión">
        </div>
    </article>
    <article class="login-box__right">
        <form *ngIf="selectedUserType == dataService.SPECIAL_USER.user_driver" class="login-box__form">
            <h2 class="login-box__form-title" hidden>Iniciar Sesión</h2>
            <div class="login-box__form-inputs">
                <mat-form-field appearance="standard" class="mat-form-field">
                    <mat-label>Número de documento</mat-label>
                    <input tabindex="0" name="document_number" matInput type="text" [(ngModel)]="driverData.document_number">
                    <mat-icon matPrefix class="icon">pin</mat-icon>
                  </mat-form-field>

                  <mat-form-field appearance="standard">
                    <mat-label>País</mat-label>
                    <mat-select  name="country_id" [(ngModel)]="driverData.country_id">
                      <mat-option *ngFor="let country of countriesList" [value]="country.id">
                        {{country.name}}
                      </mat-option>
                    </mat-select>
                    <mat-icon class="icon" matPrefix>public</mat-icon>
                  </mat-form-field>

                  <mat-form-field appearance="standard">
                    <mat-label>Contraseña</mat-label>
                    <input matInput  autocomplete="off" [type]="typePassword" name="password" [(ngModel)]="driverData.password">
                    <mat-icon class="icon" matPrefix>lock</mat-icon>
                    <button mat-icon-button matSuffix (click)="changeTypePasswordInput()" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="typePassword=='password'">
                      <mat-icon class="icon">{{typePassword =="text" ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </button>
                  </mat-form-field>
            </div>
            <div class="login-box-actions">
                <button class="btn btn-secondary" mat-flat-button (click)="login()" [disabled]="loading">Ingresar</button>
            </div>
            <button role="link" class="btn-back" (click)="goToMainLogin()"><span class="link"><mat-icon class="icon">arrow_back</mat-icon> Ir a seccion inicial</span> </button>
            <div class="loader-login animate__animated animate__bounceIn" *ngIf="loading">
                <img src="../../assets/loader.svg">
            </div>
        </form>
    </article>
</section>