import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApiService } from 'src/app/core/api/api.service';
import { DataService } from 'src/app/core/data/data.service';
import { ObjectInitializationService } from 'src/app/core/object-initialization/object-initialization.service';
import { UtilsService } from 'src/app/core/utils/utils.service';
import { ValidationService } from 'src/app/core/validation/validation.service';
import { AlignDataTable, PipeTypes, RequestMethodHTTP } from 'src/app/shared/enums/common-enums.enum';
import { IApiRequestData } from 'src/app/shared/models/interfaces/iapi-request-data.model';
import { IPaymentAgreement } from 'src/app/shared/models/interfaces/ipayment.model';
import { IUserSessionData } from 'src/app/shared/models/interfaces/iuser-session-data.model';
import { IColumnStructureToDisplayInTable } from 'src/app/shared/models/interfaces/iutil.model';
import { IViewsAvailable } from 'src/app/shared/models/interfaces/views-available';

type TableColumnName = Pick<IPaymentAgreement, "id"|"invoice_id"|"created_at"|"agreement_datetime"|"comment" >;


type PaymentAgreementfieldName = keyof IPaymentAgreement;
type MaxPaymentAgreementFieldLength = Pick <IPaymentAgreement, "comment" >;
export const MAX_PAYMENT_AGREEMENT_FIELD_LENGTH: Record<keyof MaxPaymentAgreementFieldLength,number> = {comment:255};
export const MAX_AGREEMENTS_PER_INVOICE: number = 2;
export const MAX_AGREEMENTS_PER_INVOICE_LEGEND: string = "La cantidad máxima de acuerdos de pagos por factura es de "+ MAX_AGREEMENTS_PER_INVOICE;
/**
 * @type { number } La cantidad maxima de acuerdos de pagos por factura
 */
export const MAXIMUM_RECORD_QUANTITY_PER_INVOICE: number = 2;
@Component({
  selector: 'app-payment-agreement',
  templateUrl: './payment-agreement.component.html',
  styleUrls: ['./payment-agreement.component.scss']
})
export class PaymentAgreementComponent  implements OnInit{
  currentDate: Date = new Date();

  user: IUserSessionData;
  loading: boolean = false;
  /**
   * Indica cuando ya se ha consultado los registros de acuerdos de pagos 
   */
  consultedPaymentAgreements: boolean = false;
  /** @type {boolean} Indica si se registró un acuerdo de pago  */
  paymentAgreementCreated: boolean = false;
  /**
   * @type {IPaymentAgreement} Indica los dato de acuerdo de pago a aplicar a la factura
   */
  paymentAgreement: IPaymentAgreement;
  readonly MAX_AGREEMENTS_PER_INVOICE_LEGEND: string = MAX_AGREEMENTS_PER_INVOICE_LEGEND;
  readonly MAX_AGREEMENTS_PER_INVOICE:  number = MAX_AGREEMENTS_PER_INVOICE;
  readonly MAX_PAYMENT_AGREEMENT_FIELD_LENGTH: Record<keyof MaxPaymentAgreementFieldLength,number> = MAX_PAYMENT_AGREEMENT_FIELD_LENGTH;
  /** Almacena la lista de vistas disponibles en el componente */
  viewsAvailable:IViewsAvailable={
    history:{name:"history",show_view:false},
    registration_form:{name:"registration_form",show_view:true}
  };
  pipeTypes: typeof PipeTypes = PipeTypes;
  /**
   * @type {number} Indica la factura a la que se toma como referencia para la consulta de datos y registro de acuerdos de pagos
   */
  invoiceId: number | null= null;
  see_only_historical: boolean = false;
  alignDataTable: typeof AlignDataTable = AlignDataTable;
  /** La lista de acuerdos de pago*/
  paymentAgreementList: IPaymentAgreement[] = [];
  displayedColumnsInTable: PaymentAgreementfieldName[] = ["id","invoice_id","created_at","agreement_datetime","comment"];
  
  tableColumnInformation: Record<keyof TableColumnName, IColumnStructureToDisplayInTable> = {
    id: this.objectInitializationService.initializeIColumnStructureToDisplayInTable("id", "ID", undefined, undefined, undefined, false, AlignDataTable.LEFT, AlignDataTable.LEFT, false, true),
    invoice_id: this.objectInitializationService.initializeIColumnStructureToDisplayInTable("invoice_id", "De factura Nro.", undefined, undefined, undefined, false, AlignDataTable.LEFT, AlignDataTable.LEFT, false, true),
    created_at: this.objectInitializationService.initializeIColumnStructureToDisplayInTable("created_at", "Fecha de registro", undefined, undefined, undefined, false, AlignDataTable.LEFT, AlignDataTable.LEFT, false, true),
    agreement_datetime: this.objectInitializationService.initializeIColumnStructureToDisplayInTable("agreement_datetime", "Fecha acordada", PipeTypes.DATE, undefined, undefined, false, AlignDataTable.LEFT, AlignDataTable.LEFT, false, true,undefined,"yyyy-MM-dd"),
    comment: this.objectInitializationService.initializeIColumnStructureToDisplayInTable("comment", "Comentario", undefined, undefined, undefined, false, AlignDataTable.LEFT, AlignDataTable.LEFT, false, true),
  };
  columnStructureToDisplay: IColumnStructureToDisplayInTable[] = Object.values(this.tableColumnInformation);
  
  constructor(
    @Inject(MAT_DIALOG_DATA) public data:{invoice_id: number,see_only_historical: boolean},
    private dialogRef: MatDialogRef<PaymentAgreementComponent>,
    private api:ApiService,
    public dataService: DataService,
    public validationService:ValidationService,
    public utils: UtilsService,    
    private objectInitializationService: ObjectInitializationService

  ){
    setInterval(() => this.currentDate = new Date(), this.dataService.OneMinute);
    this.invoiceId = this.data.invoice_id ?? null;
    this.user = this.dataService.getData("user") as IUserSessionData;
    if(this.data.see_only_historical)
      this.utils.setSelectedView(this.viewsAvailable,this.viewsAvailable['history'].name);
    this.paymentAgreement = this.objectInitializationService.initializeIPaymentAgreement(this.invoiceId);
  }
  ngOnInit(): void {
    if(this.invoiceId != null)
      this.getPaymentAgreement(this.invoiceId);
  }
  getPaymentAgreement(invoiceId: number){
    this.paymentAgreementList = [];
    let dataToSend:IApiRequestData={user_api_hash:this.user.hash, action: "get", invoice_id: invoiceId};
    this.api.getDataPost("paymentAgreement",dataToSend,true).then((data: unknown) => {
      if(this.validationService.isResponseApi(data) && data.status == 1 ){
        this.paymentAgreementList = data.data as IPaymentAgreement[];
      }
      this.consultedPaymentAgreements = true;
    }).catch((err: unknown) => {
      this.utils.showResultRequest("error","Información",this.api.getDefaultMessage("el acuerdo de pago",false, false,RequestMethodHTTP.GET));
    }).finally(()=>this.utils.hideLoading(()=>this.loading = false));
  }
  addPaymentAgreement(){
    this.utils.showConfirm("Confirmar acción","¿Esta seguro de registrar el acuerdo de pago?","Confirmar","Cancelar").then(()=>{
      this.loading = true;
      let errorMessage: string = "";
      let dataToSend:IApiRequestData={user_api_hash:this.user.hash, action: "add"};

      if( this.validationService.isNullOrEmpty(this.paymentAgreement.agreement_datetime) || isNaN(Date.parse(this.paymentAgreement.agreement_datetime)))
        errorMessage = "Debe de indicar una fecha para el acuerdo de pago";

      if(errorMessage != ""){
        this.utils.hideLoading(()=>this.loading = false);
        return this.utils.showResultRequest("error","Información", errorMessage);
      }
      let paymentAgreement = this.utils.copyObject(this.paymentAgreement)as IPaymentAgreement;
      let paymentAgreementDate: string = "";
      paymentAgreementDate =  this.utils.getDatetime(new Date(paymentAgreement.agreement_datetime)).split(" ")[0] + " 23:59:59";
      paymentAgreementDate = this.utils.getUTCDatetime(new Date(paymentAgreementDate));

      paymentAgreement.agreement_datetime = paymentAgreementDate;
      dataToSend = {...dataToSend,...paymentAgreement};
      this.api.createData(dataToSend, "paymentAgreement",true).then((data: unknown) => {
        if(this.validationService.isResponseApi(data)){
          this.utils.showResultRequest(data.status==1 ? "success" : "error","Información",data.message);
          if(data.status == 1)
            this.dialogRef.close(true);
        }
      }).catch((err: unknown) => {
        this.utils.showResultRequest("error","Información",this.api.getDefaultMessage("el acuerdo de pago",false, false,RequestMethodHTTP.POST));
      }).finally(()=>this.utils.hideLoading(()=>this.loading = false));
    });
  }
}