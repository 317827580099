<section class="form-box-container animate__animated animate__fadeIn" *ngIf="!modalEditPlatformPlan">
    <section class="form-box-container__header">
        <h2 class="form-box-container__header-title"><span class="icon"><i class="fa-solid fa-list-check"></i></span>Gestión de planes</h2>
        <div class="form-box-container__header-options">
            <button matTooltip="Crear un nuevo plan" class="btn btn-primary animate__animated animate__fadeIn" *ngIf="!isCreatePlatformPlan" (click)="openCreatePermissions()"><i class="fa-solid fa-file-invoice">
            </i><span class="description"> Agregar</span></button>
        </div>
        
    </section>
    <div class="form-box animate__animated animate__fadeIn">
        <div class="form-box__content form-box__content-h-auto" >
            <div class="form-box__content-inputs mt-2">
                <div class="form-box__content-group-fields">
                    <mat-card *ngFor="let platformPlan of platformPlans"  class="card">
                        <mat-card-title-group>
                            <mat-card-title>{{platformPlan.name}}</mat-card-title>
                            <mat-card-subtitle>Plan <ng-container *ngIf="!utils.isNullOrEmpty(platformPlan.updated_at)"> | Actualizado el {{platformPlan.updated_at|date:"mediumDate"}}</ng-container></mat-card-subtitle>
                            <mat-icon class="text-info">domain</mat-icon>
                        </mat-card-title-group>
                        <mat-card-content>
                            {{platformPlan.description}}
                        </mat-card-content>
                        <mat-card-actions align="end">
                            <button mat-button class="text-danger" (click)="deletePlatformPlan(platformPlan)">Eliminar</button>
                            <button mat-button class="btn btn-outline-primary" (click)="openPlanPermissionsManager(platformPlan)">Editar</button>
                            </mat-card-actions>
                    </mat-card>
                </div>
            </div>
            <div class="form-box__content-empty" *ngIf="platformPlans.length==0">
                <img src="../../../../../assets/img/files-and-folder-two-color.svg">
                <span class="form-box__empty-label">Sin datos</span>
            </div>
        </div>
    </div>
    <div class="loader" *ngIf="loading">
        <img src="../../../assets/loader.svg">
    </div>
</section>

<app-platform-plan-form *ngIf="modalEditPlatformPlan" [newPlatformPlan]="newPlatformPlan" [isCreatePlatformPlan]="isCreatePlatformPlan" (closeForm)="closeModal($event)"></app-platform-plan-form>
