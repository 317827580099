import { Injectable } from '@angular/core';
import { IChecklistDataNameUtil, IDeviceChecklistAnswerAnswered, IDeviceChecklistQuestionCategory } from '../../shared/models/interfaces/idevice-checklist.model';
import { ApiService } from '../api/api.service';
import { IUserSessionData } from '../../shared/models/interfaces/iuser-session-data.model';
import { UtilsService } from '../utils/utils.service';
import { DataService } from '../data/data.service';
import { IChecklistAnswerOptionActionType } from '../../shared/models/interfaces/ichecklist-answer-option-action-type.model';
import { IFormControlType } from '../../shared/models/interfaces/iform-control-type.model';
import { ValidationService } from '../validation/validation.service';
import { IResponseApi } from '../../shared/models/interfaces/iresponse-api.model';
import { RouteConstant } from '../constant/RouteConstant';

@Injectable({
  providedIn: 'root'
})
export class DeviceChecklistDataService {

  private user!:IUserSessionData;

  readonly CHECKLIST_DATA_NAME_LIST: Record<keyof IChecklistDataNameUtil,keyof IChecklistDataNameUtil> = {
    FORM_CONTROL_TYPES:"FORM_CONTROL_TYPES",
    CHECKLIST_ANSWER_OPTION_ACTION_TYPES:"CHECKLIST_ANSWER_OPTION_ACTION_TYPES",
    DEVICE_CHECKLIST_QUESTION_CATEGORIES:"DEVICE_CHECKLIST_QUESTION_CATEGORIES"  
  }
  readonly UTILS_DATA_LIST:IChecklistDataNameUtil ={
    FORM_CONTROL_TYPES:"formControlType",
    CHECKLIST_ANSWER_OPTION_ACTION_TYPES:"checklistAnswerOptionActionType",
    DEVICE_CHECKLIST_QUESTION_CATEGORIES:"deviceChecklistQuestionCategory"
  };
  /**
   * @type {RouteConstant} la ruta a donde retornar cuando el usuario da click en retornar en la visualizacion del formulario 
   *  */ 
  private urlToReturn: RouteConstant | null = null;
  public getUrlToReturn(): RouteConstant | null{
    return this.urlToReturn;
  }
  public setUrlToReturn(value: RouteConstant | null){
    this.urlToReturn = value;
  }
  constructor(
    private api:ApiService,
    private utils: UtilsService,
    private dataService:DataService,
    public validationService:ValidationService,
  ) { 
    this.user = this.dataService.getData("user") as IUserSessionData;
  }

    isIDeviceChecklistAnswerAnswered(value:any):value is IDeviceChecklistAnswerAnswered{
      return typeof value.id !="undefined" && typeof value.device_checklist_id != "undefined" && typeof value.device_i != "undefined" && typeof value.user_driver_id != "undefined"
    }
    /** @description Obtiene los datos de utilidades segun los target disponibles*/
    async getUtilityData(target:keyof IChecklistDataNameUtil):Promise<IResponseApi>{
      try{
        let responseData = this.dataService.getData(this.UTILS_DATA_LIST[target]);
        if(typeof responseData !="undefined" &&  Object.entries(responseData).length>0 && this.CHECKLIST_DATA_NAME_LIST.DEVICE_CHECKLIST_QUESTION_CATEGORIES != target){        
          return {status:1,data: responseData};
        }
        let response:any = await this.api.createData({ user_api_hash:this.user.hash, action:"get" }, this.UTILS_DATA_LIST[target],true);
        if(this.validationService.isResponseApi(response) && response.status == 1){
          this.dataService.setData(this.UTILS_DATA_LIST[target], response.data);
          return response;
        }
        return {status:0};
      }catch(error:any){
        this.utils.showResultRequest("error","Información",this.api.getDefaultMessage( target == this.CHECKLIST_DATA_NAME_LIST.FORM_CONTROL_TYPES ?"tipos de campos de formulario": (target == this.CHECKLIST_DATA_NAME_LIST.CHECKLIST_ANSWER_OPTION_ACTION_TYPES ?"los tipos de acciones disponibles para las preguntas":"Las categorías de preguntas"),false,false,"GET"));
        return {status:0}
      }
    }
}
