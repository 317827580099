import { Injectable, Renderer2 } from '@angular/core';
import { RequestMethodHTTP, swalIcon } from 'src/app/shared/enums/common-enums.enum';
import  Swal, { SweetAlertIcon, SweetAlertPosition }  from "sweetalert2";

interface ISwalTimerConfig{
  /** Tiempo en segundos que durará el modal visualizandose en pantalla */
  /** Indica si se muestra barra de progreso para el timer */
  timer:number,
  timerProgressBar:boolean
}
@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor(
  ) { }
   //Function to export csv from json form angular 
  downloadFileAsCSV(data: any, filename = 'data', headers: any) {
    let csvData = this.ConvertToCSV(data, headers);
    let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
    if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
      dwldLink.setAttribute("target", "_blank");
    }
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", filename + ".csv");
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

  /**
   * qdescription Permite descargar un archivo como PDF
   * @param blob Los datos del archivo a ser procesados
   */
  downloadFileAsPDF(blob: Blob,fileName:string ) {
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName+'.pdf';
    document.body.appendChild(a);
    a.click();
    URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }
  
   downloadFile(blob: Blob, fileName: string, fileType: string) {
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${fileName}.${fileType}`;
    document.body.appendChild(a);
    a.click();
    URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }
  /** Realiza exportacion de datos teniendo en cuenta un conjunto de procesamientos previos y posteriores */
  processFileDownload(fileName:string="export_",requestPreprocessing:()=>Promise<{data:any,headers:string[]}>,loadingCallback:(loading:boolean)=>{}){
    this.showConfirm("¿Está seguro de exportar los datos?", "Los datos pueden tomar un tiempo en procesarse", "Continuar", "Cancelar").then(async () => {
      loadingCallback(true);
      let response:{data:any,headers:string[]} =  await requestPreprocessing();
      setTimeout(() => {
        if(response.data.length == 0){
          this.showMsg("","Sin datos para procesar");
          return;
        }
        this.downloadFileAsCSV(response.data,  fileName + new Date().getTime().toString(), response.headers);
        loadingCallback(false);
        this.showMsg("","Datos exportados con éxito");
      }, 1200);
    });
  }
  ConvertToCSV(objArray: any, headerList: any) {
    let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    let row = 'Nro;';
    for (let index in headerList) {
      row += headerList[index] + ';';
    }
    row = row.slice(0, -1);
    str += row + '\r\n';
    for (let i = 0; i < array.length; i++) {
      let line = (i + 1) + '';
      for (let index in headerList) {
        let head = headerList[index];
        line += ';' + array[i][head];
      }
      str += line + '\r\n';
    }
    return str;
  }
  /**
   * 
   * @param dataURL convierte la informacion de un archivo representada en string a un tipo de dato Blob
   * @returns retorna valor de tipo Blob en caso de exito o null si ha fallado
   */
  dataURLtoBlob(dataURL: string): Blob | BlobPart[] | null {
    const arr = dataURL.split(',');
    if (arr.length < 2) 
      return null;
    const mimeMatch = arr[0].match(/:(.*?);/);
    if (!mimeMatch)
      return null;
    const mime = mimeMatch[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  }
  async fileToDataUrl(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        if (typeof reader.result === 'string') {
          resolve(reader.result);
        } else {
          reject('');
        }
      };
      reader.onerror = () => {
        reject('Error al leer el Blob como cadena de texto.');
      };
    });
  }
  sortNumbers(array:Array<Number>) {
    // Using the sort method with the comparison function
    return array.sort((a:any, b:any) => a - b);
  }
  /**
   * 
   * @param {string} value Indica el texto a remover acentos  
   * @returns 
   */
  removeAccentsFromString(value:string) {
    return value.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}
  /**
   * 
   * @param value el margen a agregar en px
   * @returns 
   */
  adjustLegendMarginChartjs(value: number = 35) {
    return {
      id:"legendMargin",
      beforeInit(chart:any, legend:any,options:any){
        const originalFit = chart.legend.fit;  
        chart.legend.fit = function fit() {
          originalFit.bind(chart.legend)();
          this.height += value;
        }
      }
    };
  }
  //Pasar a validation service
  ValidateEmail(email:string){
    return (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email))
   }
  isOnlyNumbers(numbers:any){
    return /^(\d){1,}$/.test(numbers);
  }
   HasSpecialCharacter(value:string):boolean{
    let regex = /[!@#$%^&*()\-_=+[{\]}\\|:;"'<>,\/¿?`~.¨°¬´]/;
    return regex.test(value);
  }
   hasUppercase(value:string):boolean{
    return /[A-Z]/.test(value);
  }
   hasLowercase(value:string):boolean{
    return /[a-z]/.test(value);
  }
   hasWhitespace(value:string):boolean{
    return /\s/.test(value);
  }
   hasDigit(value:string):boolean{
    return /\d/.test(value);
  }
   isValidPhone(value:string):boolean{
    return /^(\d){10,}$/.test(value);
  }
   isValidEmail(value:string):boolean{
    return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value);
   }
  //expresion regular que acepta una url https://www.dominio.com/ruta/sub?param1=11&param2=22
  isWebAddressValid(address:string){
    return /^(https?:\/\/){1}([a-zA-Z0-9]([^ @&%$\\\/()=?¿!.,:;]|\d)*[a-zA-Z0-9][\.])+[a-zA-Z]{2,4}([\.][a-zA-Z]{2})?\/?((?<=\/)(([^ @&$#\\\/()+=?¿!,:;'&quot;^´*%|]|\d)+[\/]?)*(#(?<=#)[^ @&$#\\\/()+=?¿!,:;'&quot;^´*%|]*)?(\?(?<=\?)([^ @&$#\\\/()+=?¿!,:;'&quot;^´*|]+[=][^ @&$#\\\/()+=?¿!,:;'&quot;^´*|]+(&(?<=&)[^ @&$#\\\/()+=?¿!,:;'&quot;^´*|]+[=][^ @&$#\\\/()+=?¿!,:;'&quot;^´*|]+)*))?)?/.test(address);
  }
  isWebDomainValid(domain:string){
    return /^(?=.{1,253}\.?$)(?:(?!-)[A-Za-z0-9-]{1,63}(?<!-)\.?)+(?:[A-Za-z]{2,}|xn--[A-Za-z0-9-]{2,})$/.test(domain);
  }
  isGoogleAnalyticsIdValid(id:string){
    return /^UA-\d{4,}-\d{1,}$/gm.test(id);
  }
  isGoogleSearchConsoleIdValid(id:string){
    return /^[a-zA-Z0-9_-]{43}$/.test(id);
  }
  //para determinar si la cadena usada en la personalizacion de la url para el sitio web personalzado es valido
  isUrlIdentifierValid(value:string){
    return /^(?!-)[a-z0-9-]{5,}(?<!-)$/.test(value);
  }///////////
  showConfirm(title: string, msg: string, confirm: string, cancel: string, icon: swalIcon | null = null) : any {
    return new Promise<boolean>((resolve, reject) => {
      Swal.fire({
        title: title,
        html: msg,
        icon: icon != null ? icon : undefined,
        showCancelButton: true,
        cancelButtonText: cancel,
        confirmButtonText: confirm,
        showClass: {
          popup: 'animate__animated animate__fadeInDown'
        },
      }).then((result) => {
        if (result.isConfirmed) {
          resolve(true);
        } else {
          reject(false);
        }
      }).catch(()=>reject(false));
    });
  }
  showMsg(title: string, msg: string, ok: boolean = true){
    Swal.fire({
      title: title,
      html: msg,
      confirmButtonText: ok ? 'OK' : '',
      showConfirmButton: ok,
      showCloseButton: !ok,
      showClass: {
        popup: 'animate__animated animate__fadeInDown'
      },
    });
  }
  
  showNetStatus(connected: boolean = false){
    let dialog:any={
      title: !connected ? 'Upps, al parecer no hay conexión a internet...' : 'Conexión restaurada',
      html: '<i class="fa-solid fa-globe fa-flip fa-3x"></i>',
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      showCancelButton: false,
      showConfirmButton: true,
    };
    if(!connected)
      dialog["html"]="Revisa tu conexion e intenta cargar de nuevo<br><br>"+dialog["html"];
    dialog["timer"]=7000;

    Swal.fire(dialog);
  }

  getCurrentDate(setSeconds:boolean = true):Date{
    let date:Date = new Date();
    return new Date(date.getFullYear(),date.getMonth(),date.getDate(),date.getHours(),date.getMinutes(), setSeconds ? date.getSeconds() : 0);
  }
  /** Realiza comparacion de dos elementos para determinar el orden. Usada en los metodos sort de arrays */
  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
  //obtiene string de fecha en formato yyy-mm-dd
  getDate(datetime:string):string{
    let response='';
    if(typeof datetime!="undefined" && datetime!=""){
      let dateArray= datetime.split("-");
      let day=dateArray[2].split(" ")[0];
      response = dateArray[0]+"-"+dateArray[1]+"-"+day;
    }
    return response;
    
  }
  //retorna una objeto date. Esta funcion proviene de My GPs, evaluar la eliminacion del modulo My GPS
  formatDate(date:any):Date{
    let dateArray= date.split("-");
    let day=dateArray[2].split(" ")[0];
    return new Date(dateArray[0],(dateArray[1]-1),day);// es con -1 porque se especifica el mes de enero en 0 y diciembre en 11
  }
  /**
   * Obtiene la fecha como un string yyyy-mm-dd hh:mm:ss
   * @param date La fecha a evaluar. Si no se, indica toma la fecha actual
   * @returns {string} la fecha yyyy-mm-dd hh:mm:ss
   */
  getDatetime(date?:Date):string{
    let datetime=(typeof date =="undefined")?new Date():date;
    let  response = datetime.getFullYear()+"-"+this.evaluateDateNumber(datetime.getMonth()+1)+"-"+this.evaluateDateNumber(datetime.getDate())+" "+this.evaluateDateNumber(datetime.getHours())+":"+this.evaluateDateNumber(datetime.getMinutes())+":"+this.evaluateDateNumber(datetime.getSeconds());
    return response;
  }
  /**
   * Retorna la fecha actual como añoMesDia horaMinutoSegundo
   * @returns 
   */
  getDateTimeNumericString(){
    let datetime = new Date();
    return datetime.getFullYear()+""+this.evaluateDateNumber(datetime.getMonth()+1)+""+this.evaluateDateNumber(datetime.getDate())+" "+this.evaluateDateNumber(datetime.getHours())+""+this.evaluateDateNumber(datetime.getMinutes())+""+this.evaluateDateNumber(datetime.getSeconds());

  }
  /**
   *@description metodo para obtener un string de fecha UTC en formato YYYY-mm-dd HH:mm:ss 
   * @param {Date} date, indica la fecha a evaluar 
   * @returns {string} retorna una cadena de la hora UTC con formato yyyy-mm-dd hh:mm:ss 
   */
  getUTCDatetime(date?:Date,setSeconds:boolean = true){
    let datetime=(typeof date =="undefined")?new Date():date;
    let  response = datetime.getUTCFullYear()+"-"+this.evaluateDateNumber(datetime.getUTCMonth()+1)+"-"+this.evaluateDateNumber(datetime.getUTCDate())+" "+this.evaluateDateNumber(datetime.getUTCHours())+":"+this.evaluateDateNumber(datetime.getUTCMinutes())+":"+this.evaluateDateNumber( setSeconds ? datetime.getUTCSeconds():0);
    return response;
  }
  SubYearToDate(date:Date,quantity:number){
    let expirationDate = new Date(date);
    expirationDate.setFullYear(expirationDate.getFullYear()-quantity);
    return expirationDate;
  }
  getNowDate(data:string|Date){
    return new Date(data).getTime();
  }

  //usado para obtener el formato yyy-mm-dd, pasandoles dos fechas dadas en el input de datepicker
  getDateRange(startDate:Date,endDate:Date):{start:string, end: string} {
    let start= new Date(startDate);
    let end= new Date(endDate);
    let newStart= start.getFullYear()+"-"+this.evaluateDateNumber(start.getMonth()+1)+"-"+this.evaluateDateNumber(start.getDate());
    let newEnd= end.getFullYear()+"-"+this.evaluateDateNumber(end.getMonth()+1)+"-"+this.evaluateDateNumber(end.getDate());
    let newStartDate =newStart;
    let newEndDate=newEnd;
    return {
      start:newStartDate,
      end:newEndDate
    };
}
  copyObject(object:Object){
    return JSON.parse(JSON.stringify(object));
  }
  //PASAR a validation
  isEmptyObject(object:Object){
    return Object.entries(object).length<1;
  }
  isNullOrEmpty(value:any){
    return typeof value =="undefined" ||value ==null || value =="";
  }
  isClipboardApiAvailable():boolean {
    return !!navigator && !!navigator.clipboard && !!navigator.clipboard.writeText;
  };//////////
  copyToClipboard(value:string) {
    if (navigator && navigator.clipboard && navigator.clipboard.writeText)
      return navigator.clipboard.writeText(value);
    return Promise.reject("The Clipboard API is not available.");
  }
  redirectToMap(latitude:number,longitude:number){
    window.open("https://www.google.com/maps?q="+latitude+","+longitude+"&z=17");
  }
  /**
   * 
   * @param {Object} views representa las vistas disponibles en el componente con estructura {view_name:{name:string,show_view:boolean},...} 
   * @param selectedView Nombre de la vista seleccionada
   */
  setSelectedView(views:any,selectedView:string){
    Object.values(views).forEach((view:any)=>views[view.name].show_view = view.name==selectedView);
  }
  /**
   * @description Obtiene el objeto formData con los datos a enviar a la API 
   * @param formData El formData a poblar
   * @param data Los datos a agregar al formData
   * @param firstLevel Indica si es el primer nivel en el objeto que se recorre
   * @param nameProperty Nombre de propiedad que se va agregando a medida que se recorre el objeto de forma recursiva
   * @param addFiles Indica si se agrega vaores de tipo Files en caso de existir
   * @returns 
   */
  fillFormData(formData:FormData,data:any,firstLevel:boolean,nameProperty?:string,addFiles:boolean = true):FormData{
    let name:any,value:any;
    if(typeof data =="object" && data !== null){
      for([name,value] of Object.entries(data)){
        if(typeof value =="object" && !(value instanceof File)){
          this.fillFormData(formData,value,false,(typeof nameProperty !="undefined"?nameProperty:"") + (!firstLevel?"["+name+"]":name),addFiles);
        }else{
            //esto se hace para excluir a las propiedades que almacenan las imagenes que se cargan de forma temporal en log elementos HTML img,
            if((/temp_.{1,}/.test(name)))
              continue;
            if(value instanceof File){
              if(addFiles)
                formData.set((firstLevel?"":(typeof nameProperty !="undefined" && nameProperty !=""?(nameProperty.replace(/\[[^\]]+\]/g, match => "_" + match.slice(1, -1).replace(/\[|\]/g, "_"))+"_"):""))+name,value);            
            }
            else{
              formData.set((firstLevel?"":nameProperty)+(firstLevel?name:`[${name}]`),value);            
            }
        }
      }
    }
    return formData;
  }

    /**
   * @description Obtiene el objeto formData con los datos a enviar a la API sobre los permisos
   * @param formData El formData a poblar
   * @param data Los datos a agregar al formData
   * @returns 
   */
    fillPermissionsFormData(formData:FormData,data:any,addOnlyEnabled: boolean = true):FormData{
      console.log(formData,data);
      let name:any,value:any;
        for([name,value] of Object.entries(data)){
            if(typeof  value.edit != "undefined" && value.edit == 1  || !addOnlyEnabled)
              formData.set("perms["+value.name+"][edit]",value.edit);
            if(typeof  value.view != "undefined" && value.view == 1 || !addOnlyEnabled)
              formData.set("perms["+value.name+"][view]",value.view);            
            if(typeof  value.remove != "undefined" && value.remove == 1 || !addOnlyEnabled)
              formData.set("perms["+value.name+"][remove]",value.remove);            
        }
      return formData;
    }
  public evaluateDateNumber(data:number){
    return data<10?"0"+data:data;
  }
  getMonthsLabels($quantity:number){
    let months=[ 'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre' ];
    months.splice($quantity,12-$quantity);
    return months;
  }
  showMakingRequest(title:string,icon:string){
    let message:any={
      title:  title,
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      showCancelButton: false,
      showConfirmButton: false,
    };
    if(icon=="download"){
      message["html"]='<i class="fa-solid fa-download fa-2x fa-beat-fade"></i>';
    }
    else if(icon=="task"){
      message["html"]='<i class="fa-solid fa-file-pen fa-2x fa-beat-fade"></i>';
    }
    else if(icon=="comment"){
      message["html"]='<i class="fa-solid fa-comment-dots fa-2x fa-beat-fade"></i>';
    }
    Swal.fire(message);
  }
  showResultTask(update:boolean,success:boolean,description?:string){
    let content="Tarea"+(update?" actualizada":" registrada");
    if(!success){
      content="No se pudo realizar "+(update?"la actualización":"el registro");
    }
    let message:any={
      title: content,
      icon:success?"success":"error",
      html:description,
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      showCancelButton: false,
      showConfirmButton: true,
      showConfirmButtonText:"Ok",
      
    };
    Swal.fire(message);
  }
  showLoading(show: boolean = true,callback:Function=()=>{}){
    if(show) return  true;
    else setTimeout(() => { callback(show);}, 500);
    return false;
  }
  hideLoading(callback:Function=()=>{}){
    setTimeout(() => { callback(false);}, 400);
  }
  /**
   * 
   * @param swalIcon 
   * @param title 
   * @param description 
   * @param {} timerConfig indica los valores para el tiempo en que se muestra en mensaje,los campos son  
   * @param image 
   */
  showResultRequest(swalIcon:"success"|"error"|"warning"|"info"|"question"|"",title:string,description?:string,timerConfig:ISwalTimerConfig | null = null,image:any=null){
    let message:any={
      title: title,
      icon:swalIcon,
      html:description,
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      showCancelButton: false,
      showConfirmButton: true,
      showConfirmButtonText:"Ok",
    };
    if(image!=null){
      message["imageUrl"] = image.imageUrl;
      message["imageWidth"] = image.imageWidth;
      message["imageHeight"] = image.imageHeight;
      message["imageAlt"] = image.imageAlt;
    }
    if(timerConfig!=null){
      let timerInterval:any=null;
      message["timer"]=timerConfig.timer*1000;
      message["timerProgressBar"]=timerConfig.timerProgressBar;
      message["didOpen"]=() => {
        timerConfig.timerProgressBar?Swal.showLoading():"";
        const b:any= Swal.getHtmlContainer()!.querySelector('b')||undefined;
        timerInterval = setInterval(() => {
          b.textContent = (Swal.getTimerLeft()!/1000).toFixed(0);
        }, 1000)
      },
      message["willClose"]= () => {
        clearInterval(timerInterval)
      }
    }
    Swal.fire(message);
  }

  showToast(icon:SweetAlertIcon ,title:string,showProgressBar:boolean=true,position:SweetAlertPosition ="top-end",timer:number=3000){
    const Toast = Swal.mixin({
      toast: true,
      position: position,
      showConfirmButton: false,
      timer: timer,
      timerProgressBar: showProgressBar,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    })
    Toast.fire({
      icon: icon,
      title: title
    })
  }

    getGoogleMapsformatted(html: string): string | "" {
    // Crear un elemento temporal para analizar el HTML
    const tempElement = document.createElement('div');
    tempElement.innerHTML = html;
    // Buscar el elemento iframe que representa el mapa de Google Maps
    const iframeElement = tempElement.querySelector('iframe');
    // Verificar si se encontró un iframe
    if (iframeElement) {
      // Obtener el valor del atributo 'src' del iframe
      const src = iframeElement.getAttribute('src');
      // Comprobar si se encontró un valor 'src'
      if (src) {
        // Construir la cadena de atributos requeridos
        const attributes = [
          'width="100%"',
          'height="auto"',
          'style="border:1;"',
          'allowfullscreen=""',
          'loading="lazy"',
          'referrerpolicy="no-referrer-when-downgrade"',
        ];
        // Combinar el 'src' con los atributos requeridos
        const FinalHTMLCode = `<iframe src="${src}" ${attributes.join(' ')}></iframe>`;
        return FinalHTMLCode;
      }
    }
    // Devolver vacio si no se encontró un iframe o src
    return "";
  }

  /**
   * @description  Crea un formulario HTML y asigna los valores indicados 
   * @param {Renderer2} renderer Instancia para la gestion de creacion de los elementos HTML
   * @param {Record<string, string>} values Los valores a agregar como campos al mismo, estos se agregaran como campos de tipo input
   * @param {RequestMethodHTTP} method El metodo usado para el envio del formulario 
   * @param {string} action La url indicada para la redireccion del formulario
   * @returns 
   */
  public createForm(renderer: Renderer2, values:Record<string,string>,method:RequestMethodHTTP,action:string): HTMLFormElement {
    const form = renderer.createElement('form');
    renderer.setProperty(form, 'method', method);
    renderer.setProperty(form, 'action', action);
    if(method == RequestMethodHTTP.POST){
      for(let [key, value] of Object.entries(values) ){
        this.addFieldToForm(renderer,form,key,value);
      }
    }
    return form;
  }
  /**
   * @description Agrega los campos con valores determinados al formulario indicado
   * @param {HTMLFormElement} form El formulario objetivo
   * @param {string} name Nombre para el campo
   * @param {string} value Valor a asociar al campo 
   */
  private addFieldToForm(renderer: Renderer2,form: HTMLFormElement, name: string, value: string): void {
    const input = renderer.createElement('input');
    renderer.setProperty(input, 'type', 'hidden');
    renderer.setProperty(input, 'name', name);
    renderer.setProperty(input, 'value', value);
    renderer.appendChild(form, input);
  }
}