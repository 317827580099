import { Component, OnInit, Renderer2 } from '@angular/core';
import { ApiService } from 'src/app/core/api/api.service';
import { DataService } from 'src/app/core/data/data.service';
import { UtilsService } from 'src/app/core/utils/utils.service';
import { Sort } from '@angular/material/sort';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { ValidationService } from 'src/app/core/validation/validation.service';
import { ObjectInitializationService } from '../../core/object-initialization/object-initialization.service';
import { IPermissionsIntgps } from 'src/app/shared/models/interfaces/ipermissions-intgps.model';
import { IApiRequestData, IPagination, ISort } from 'src/app/shared/models/interfaces/iapi-request-data.model';
import { IViewsAvailable } from 'src/app/shared/models/interfaces/views-available';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { IUserPlanModule, IUserSessionData } from 'src/app/shared/models/interfaces/iuser-session-data.model';
import { DevicePlanType, RequestMethodHTTP } from 'src/app/shared/enums/common-enums.enum';
import { IUserGeneral, IUserSetting } from 'src/app/shared/models/interfaces/user-data.model';
import { IBillingFrequency, ICity, IIdentificationDocumentType, IMonetaryunit } from 'src/app/shared/models/interfaces/iutils-data.model';
import { PageEvent } from '@angular/material/paginator';
import { ITimeUnit, ITimeUnitItem } from 'src/app/shared/models/interfaces/iutil.model';
import { ICountry } from 'src/app/shared/models/interfaces/country.model';
import { IAutomation, IAutomationAssociatedWithAutomationTargetUser, IAutomationTargetUser } from 'src/app/shared/models/interfaces/iautomation.model';
import { AuthService } from 'src/app/core/auth/auth.service';
import { ComponentIdentifier } from 'src/app/core/constant/component-identifier';
import { ApiEndpointSgc } from 'src/app/shared/enums/api-endpoint-sgc.enum';
import { MessageBoxService } from 'src/app/core/message-box/message-box.service';

type TimeUnitForInvoiceUserSetting = Pick<ITimeUnit,"day"|"week"|"month"|"year">;

//REFACTORIZAR  EN CONJUNTO CON view/distributors
@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.scss']
})
export class ClientsComponent implements OnInit {

  //almacena la lista de vistas disponibles en el componente.
  viewsAvailable: IViewsAvailable = {
    user_form: { name: "user_form", show_view: false },
    user_notes: { name: "user_notes", show_view: false, index_in_tab: 3 },
    selected_user_devices: { name: "selected_user_devices", show_view: false },
    user_list: { name: "user_list", show_view: true }
  };

  searchText: string = "";//variable para realizar la busqueda, usado en el input search
  //usersList:any=[];
  headers: any = ["email", "name", "document_number", "phone_number"];//propiedades a exportar de los usuarios
  /** Columnas de la tabla */
  displayedColumns: string[] = ["active", "email", "name", "document_number", "phone_number", "status", "actions"];
  pagination: IPagination;
  sort: ISort;

  user: IUserSessionData;
  isUpdate: boolean = false;//para indicar si es actualizacion en el modal de registro o actualizacion de usuario

  billingFrequenciesList: IBillingFrequency[] = [];
  monetaryUnitsList: IMonetaryunit[] = [];
  loading: boolean = false;//para loading

  newUserIdentificationDocumentType: IIdentificationDocumentType;//almacena el tipo de documento seleccionado para el usuario editado

  permissionsIntgps: any = [];
  users: IUserGeneral[] = [];
  
  newUser: IUserGeneral;
  /**
   * @type {IAutomationTargetUser} La lista de auomatizaciones asociadas al usuario que se pretende editar
   */
  automationsAssociatedWithUserToEdit: IAutomationTargetUser[]= [];
  
  timeUnitForInvoiceUserSetting: TimeUnitForInvoiceUserSetting;
  timeUnitArray: ITimeUnitItem[];

  hasAccessToAutomation: boolean = false;
  automationsList: IAutomationAssociatedWithAutomationTargetUser[] = [];
    
  countriesList: ICountry[] = [];//almacena la lista de paises;
  /**
   * @type {string} El valor de ciduad a buscar dentro de la lista de ciudades
   */
  cityToSearch: string = "";
  citiesListFilter: ICity[] = [];//almacena la lista de ciudades;
  citiesList: ICity[] = [];//almacena la lista de ciudades;
  identificationDocumentTypeList: IIdentificationDocumentType[] = [];/**Almacena la lista de tipos de documentos disponibles para asignar al usuario segun el pais seleccionado */
  userDevices: any = [];//almacena la lista de dispositivos que se muestran en el campo select para la parte de edicion de usuarios
  
  devicePlanType:typeof DevicePlanType = DevicePlanType;
  readonly availableMaps = [28, 29, 30, 31, 32, 33];
  moduleId: number = 3;
  permissionsDataSgc: any = [];//permisos sobre el modulo

  /** @type {NodeJS.Timeout} Indica el id del timer usado para enviar solicitud de busqueda de un valor determinado en los datos de la tabla */
  timerIdSearchValueInTable!: NodeJS.Timeout;
  /** @type {NodeJS.Timeout} Indica el id del timer usado para el timeout del campo de edicion de usuario para aumento / decremento de configuracion de tiempo de expiracion de facturas */
  timerIdInvoiceDueTimeQuantity!: NodeJS.Timeout;
  constructor(
    private api: ApiService,
    public dataService: DataService,
    public utils: UtilsService,
    private renderer: Renderer2,
    private route: Router,
    public validationService: ValidationService,
    public objectInitialization: ObjectInitializationService,
    private authService: AuthService,
    public messageBox: MessageBoxService,

  ) {
    this.user = this.dataService.getData("user") as IUserSessionData;
    this.newUser = this.objectInitialization.initializeIUserGeneral(this.user.id,this.availableMaps,DevicePlanType.PERSONALIZED);

    this.newUserIdentificationDocumentType = this.objectInitialization.initializeIIdentificationDocumentType();
    this.pagination = this.objectInitialization.initializeIPagination(this.dataService.PAGE_SIZE_OPTION);
    this.pagination.page_size = this.dataService.PAGE_SIZE_OPTION[0];
    this.sort = this.objectInitialization.initializeISort();

    let timeUnit: ITimeUnit = this.objectInitialization.initializeITimeUnit();
    this.timeUnitForInvoiceUserSetting = {day:timeUnit.day,week: timeUnit.week ,month:timeUnit.month,year: timeUnit.year};
    this.timeUnitArray = Object.values(this.timeUnitForInvoiceUserSetting);
  }
  ngOnInit(): void {
    this.dataService.checkPermissionModule(this.moduleId).then((permissions: any) => {
      this.permissionsDataSgc = permissions;

      this.permissionsIntgps = this.dataService.getData("permissionsIntgps");
      this.getList();
      this.dataService.fillCountries(this.countriesList);
      this.dataService.fillMonetaryunits(this.monetaryUnitsList);
      this.dataService.fillBillingFrequencies(this.billingFrequenciesList);

      let accessToAutomationModule:{status:boolean,data:IUserPlanModule} = this.authService.checkPermissionModule(ComponentIdentifier.AUTOMATION);
      if(accessToAutomationModule.status)
        this.hasAccessToAutomation = accessToAutomationModule.status;
    }).catch((error:any) => {
      this.utils.showMsg("Página no autorizada", "No tiene permisos para ver esta página, contacte al administrador");
      this.route.navigate(['/']);
    });

  }
  //para cambiar el estado de activo/inactivo del usuario
  statusChange(event: any, user: IUserGeneral) {
    let userData = {
      user_api_hash: this.user.hash,
      id: user.id,
      active: event.checked ? 1 : 0,
      action: "changeStatus"
    };
    this.api.createData(userData, "user", true).then((data: unknown) => {
      if(this.validationService.isResponseApi(data))
        this.utils.showResultRequest(data.status == 1 ? "success" : "error","Información",data.status == 1 ? "Estado de usuario actualizado" : "No se pudo cambiar el estado del usuario");
    }).catch((err: any) => {
      this.utils.showResultRequest("error","Información",this.api.getDefaultMessage("el estado del usuario", true,false,RequestMethodHTTP.POST));
    }).finally(()=>this.utils.hideLoading(() => this.loading = false));
  }
  //metodo para la tabla de clientes, que indica si el usuario esta activo o inactivo
  isActive(activeUser: number) {
    return activeUser == 1;
  }
  toggleMessageActive(activeUser: number) {
    return activeUser == 1 ? "Desactivar usuario" : "Activar usuario";
  }
  async getIdentificationDocumentTypes(): Promise<void> {
    if (this.validationService.isNullOrEmpty(this.newUser.country_id))
      return;
    try {
      let identificationDocumentTypeResponse: unknown = await this.api.getData("identificationDocumentType", "?action=getDocumentTypes&user_api_hash=" + this.user.hash + "&country_id=" + this.newUser.country_id, true);      
      if(this.validationService.isResponseApi(identificationDocumentTypeResponse)){
        this.identificationDocumentTypeList = identificationDocumentTypeResponse.status ? identificationDocumentTypeResponse.data : [];
        this.showIdentificationDocumentType();
      }
    } catch (error:unknown) { this.identificationDocumentTypeList = [];}
  }
    /**
   * 
   * @param {boolean} resetSelectedCity Indica si se va a reiniciar el valor de la ciudad actualmente asignada a lusuario
   * @returns 
   */
  async getCitiesList(resetSelectedCity:boolean = true): Promise<void> {
    if(resetSelectedCity)
      this.newUser.city_id = null;
    if (this.validationService.isNullOrEmpty(this.newUser.country_id))
      return;
    try {
      this.citiesList = [];
      let data: IApiRequestData = {user_api_hash:this.user.hash, action:"get", country_id: this.newUser.country_id};
      let response: unknown = await this.api.getDataPost("city", data, true);      
      if(this.validationService.isResponseApi(response))
        this.citiesList = response.status ? ( response.data as ICity[] ) : [];
      this.citiesListFilter = this.utils.copyObject(this.citiesList);
    } catch (error:unknown) { this.citiesList = [];}
  }
  showIdentificationDocumentType() {
    let newUserIdentificationDocumentType: IIdentificationDocumentType | undefined = this.identificationDocumentTypeList.find((type: IIdentificationDocumentType) => type.id == this.newUser.identification_document_type_id);
    if(typeof newUserIdentificationDocumentType != "undefined")
      this.newUserIdentificationDocumentType = newUserIdentificationDocumentType;
  }
  paginator(event: PageEvent) {
    this.pagination.current_page = event.pageIndex;    
    this.pagination.page_size = event.pageSize;
    this.getList();
  }
  sortData(sort: Sort) {
    this.pagination.current_page = 0;
    this.sort.direction=sort.direction;
    this.sort.by=sort.active;
    this.getList();
  }
  //comprueba si aquellos datos del usuario que hacen parte  de la tabla donde se muestran los usuarios estan completos
  isDataUserComplete(user: any): boolean {
    return (!this.utils.isNullOrEmpty(user.name) && !this.utils.isNullOrEmpty(user.document_number) && !this.utils.isNullOrEmpty(user.phone_number));
  }
  getUserDevices(user: IUserGeneral) {
    this.newUser = JSON.parse(JSON.stringify(user)) as IUserGeneral;
    this.utils.setSelectedView(this.viewsAvailable, this.viewsAvailable['selected_user_devices'].name);
  }
  search() {
    this.pagination.current_page = 0;
    clearTimeout(this.timerIdSearchValueInTable);
    this.timerIdSearchValueInTable = setTimeout(() => {this.getList();}, this.dataService.DATA_TABLE_SEARCH_TIMEOUT);
  }

  clearSearchCity(){
    this.cityToSearch = "";
    this.searchCity();  
  }
  searchCity() {
    this.cityToSearch = this.cityToSearch.toLowerCase() ?? "";
    this.citiesListFilter = this.cityToSearch.length > 0 ? this.citiesList.filter((user: ICity) => user.name.toLowerCase().includes(this.cityToSearch)) : this.citiesList;
  }
  getList() {
    this.loading = true;
    let dataSgc:IApiRequestData = this.api.structureParametersForConsult(RequestMethodHTTP.POST,"get",this.user.hash,this.searchText,this.pagination.current_page,this.pagination.page_size,this.sort) as IApiRequestData;
    this.users = [];
    this.pagination.length_page = 0;
    this.api.getDataPost("user", dataSgc, true).then((usersData: unknown) => {
      if(this.validationService.isResponseApi(usersData)){
        if (usersData.status == 1) {
          this.pagination.length_page = usersData.total??0;
          this.users = usersData.data;
        }
      }
    }).catch((error) => {
      this.utils.showResultRequest("error","Información",this.api.getDefaultMessage("la lista de usuarios",false,false,"GET"));
    }).finally(()=>this.utils.hideLoading(() => this.loading = false));
  }
  async openUpdateUserModal(user: IUserGeneral): Promise<void> {
    this.newUser = Object.assign(this.objectInitialization.initializeIUserGeneral(this.user.id, this.availableMaps,DevicePlanType.PERSONALIZED,user.id), JSON.parse(JSON.stringify(user)));
    this.structureNewuserPermissionsToUpdate();

    this.utils.setSelectedView(this.viewsAvailable, this.viewsAvailable['user_form'].name);

    this.isUpdate = true;
    this.getIdentificationDocumentTypes();
    this.getCitiesList(false);
    this.loading = true;
    try {
      let [userPermissionResponse,userDevicesResponse, userSettingResponse] = await Promise.all(
        [this.api.getData("user", "?action=getPermissionsList&user_api_hash=" + this.user.hash + "&user_id=" + user.id, true),
        this.api.getData("device", "?action=get&user_api_hash=" + this.user.hash + "&user_id=" + user.id + "&columns_to_get=id,plate_number,name&attach_managed_user_devices=false", true),
        this.api.getDataPost("userSetting", {action:"get",user_api_hash:this.user.hash,user_id:this.newUser.id } , true)
      ]);
      if(this.validationService.isResponseApi(userPermissionResponse) && userPermissionResponse.status == 1)
        this.structureNewuserPermissionsToUpdate(userPermissionResponse.data);
      if(this.validationService.isResponseApi(userDevicesResponse) && userDevicesResponse.status == 1)
        this.userDevices = userDevicesResponse.data;
      if(this.validationService.isResponseApi(userSettingResponse) && userSettingResponse.status == 1)
        this.newUser.user_setting = userSettingResponse.data as IUserSetting;
      else
        this.newUser.user_setting = this.objectInitialization.initializeIUserSetting(this.newUser.id);
      this.getAutomationsList();
    } catch (error:unknown) {}
    finally{
      this.utils.hideLoading(() => this.loading = false);
    }
  }
  structureNewuserPermissionsToUpdate(userPermissions: IPermissionsIntgps[] = []) {
    let userPerms: IPermissionsIntgps[] = userPermissions;
    let newPerms: IPermissionsIntgps[] = [];
    this.newUser.permissions_to_update = [];
    for (let permission of this.objectInitialization.initializeIPermissionsIntgps(this.newUser.id)) {
      let permissionIndex = userPerms.findIndex((perm: IPermissionsIntgps) => perm.name == permission.name);
      let newPermission = (permissionIndex !== -1) ? userPerms[permissionIndex] : permission;
      newPerms.push(newPermission);
      this.newUser.permissions_to_update[newPermission.name] = {};
      Object.defineProperties(this.newUser.permissions_to_update[newPermission.name], {
        view: { value: parseInt(newPermission.view.toString()), writable: true },
        edit: { value: parseInt(newPermission.edit.toString()), writable: true },
        remove: { value: parseInt(newPermission.remove.toString()), writable: true },
      });
    }
    this.newUser["permissions"] = newPerms;
  }
  predefinePermissionsForNewUser() {
    for (let permission of this.newUser.permissions!) {
      if (permission.name == "devices") {
        this.newUser.permissions_to_update[permission.name]["view"] = 1;
        this.newUser.permissions_to_update[permission.name]["edit"] = this.newUser.permissions_to_update[permission.name]["remove"] = 0;
      } else if (permission.name == "tasks") {
        this.newUser.permissions_to_update[permission.name]["edit"] = this.newUser.permissions_to_update[permission.name]["view"] = 1;
        this.newUser.permissions_to_update[permission.name]["remove"] = 0;
      } else if (permission.name == "call_actions" || permission.name == "device_route_types" || permission.name == "users") {
        this.newUser.permissions_to_update[permission.name]["remove"] = this.newUser.permissions_to_update[permission.name]["edit"] = this.newUser.permissions_to_update[permission.name]["view"] = 0;

      }
      else {
        let managerperms = this.permissionsIntgps[permission.name];
        this.newUser.permissions_to_update[permission.name].view = managerperms.view;
        this.newUser.permissions_to_update[permission.name].edit = managerperms.edit;
        this.newUser.permissions_to_update[permission.name].remove = managerperms.remove;
      }
    }
  }
  openCreateUserModal(): void {
    this.newUser = this.objectInitialization.initializeIUserGeneral(this.user.id,this.availableMaps,DevicePlanType.PERSONALIZED, this.newUser.id);
    this.structureNewuserPermissionsToUpdate();
    this.predefinePermissionsForNewUser();
    this.citiesList = this.citiesListFilter = [];
    this.utils.setSelectedView(this.viewsAvailable, this.viewsAvailable['user_form'].name);
    this.isUpdate = false;
  }
  deleteUser(userToDelete: IUserGeneral) {
    this.utils.showConfirm("Confirmar acción", "Esta seguro de eliminar al usuario '" + userToDelete.email + "'", "Eliminar", "Cancelar").then(() => {
      let data = "?action=delete&user_api_hash=" + this.user.hash + "&id=" + userToDelete.id + "&email=" + userToDelete.email;
      this.api.deleteData("user", data, true).then((data: unknown) => {
      if(this.validationService.isResponseApi(data)){
        if (data.status == 1) 
          this.getList();
        this.utils.showResultRequest( data.status == 1 ? "success" : "error","Información", data.status == 1 ? "Se ha eliminado al usuario" : data.message);
      }
      }).catch((err: any) => {
        this.utils.showResultRequest("error","Información",this.api.getDefaultMessage("al usuario en SGC",false,false,RequestMethodHTTP.DELETE));
      }).finally(()=>this.utils.hideLoading(() => this.loading = false));
    }).catch(() => { return;});
  }
  //permite saber si un mapa ha sido seleccionado por el usuario
  isMapSelected(maps: number[], mapId: number): boolean {
    return maps.length > 0 && maps.some((item: number) => Number(item) == Number(mapId));
  }
  //modifica seleccion de permisos
  modifySelection($event: any, option?: string) {
    let row = this.renderer.parentNode(this.renderer.parentNode(this.renderer.parentNode($event.target)));
    let viewInput = row.children[1].querySelector("input");//el uno es el input view
    if ($event.target.checked && (option == "edit" || option == "remove")) {
      viewInput.checked = true;
      viewInput.disabled = true;
    } else if ($event.target.checked == false && (option == "edit" || option == "remove")) {
      let editInput = row.children[2].querySelector("input");//el uno es el input edit
      let removeInput = row.children[3].querySelector("input");//el uno es el input remove
      if (editInput.checked == false && removeInput.checked == false) {
        viewInput.disabled = false;
      }
    }
  }
  isPermissionDisabled(permissionName: string, permissionType: string) {
    return typeof this.permissionsIntgps[permissionName][permissionType] == "undefined" || !this.permissionsIntgps[permissionName][permissionType];
  }
  //permite saber si un permiso ha sido seleccionado por el usuario
  isPermissionSelected(isUpdate: boolean, permissionName: string, permissionType: any, permissions?: any): boolean {
    let response = false;
    //primero los permisos del administrador para habilitar dichas opciones
    if (isUpdate) {
      let permission: any = [];
      //en services esta almacenado los servicios que han sido seleccionados por el usuario
      if (permissions.length > 0) {
        permission = permissions.find((permission: any) => permission.name == permissionName);
        if (typeof permission != "undefined" && typeof permissionType != "undefined") {
          if (typeof permissionType != "object") {
            response = permission[permissionType] == 0 ? false : true;
          } else {
            for (let type of permissionType) {
              response = permission[type] == 0 ? false : true;
              if (response == true) {
                break;
              }
            }
          }
        }
      }
    } else {
      if (typeof permissionType != "object") {
        if ((permissionName == "devices" && (permissionType == "edit" || permissionType == "remove")) ||
          (permissionName == "tasks" && permissionType == "remove") ||
          (permissionName == "history" && permissionType == "remove") ||
          (permissionName == "call_actions" || permissionName == "device_route_types")) {
          response = false;
        }
        else {
          response = this.permissionsIntgps[permissionName][permissionType];
        }
      } else {
        for (let type of permissionType) {//edit y remove
          response = this.permissionsIntgps[permissionName][type];
          if (response == true)
            break;
        }

        if (permissionName == "devices" || permissionName == "device_route_types" || permissionName == "call_actions") {
          response = false;
        }

      }
    }
    return response;
  }
  //agrega el valor  del mapa a los datos del usuario a manipular
  modifyAvailableMaps($event: any, value: number) {
    if ($event.checked && this.newUser.available_maps.findIndex((item: number) => parseInt(item.toString()) == parseInt(value.toString())) == -1) {
      this.newUser.available_maps.push(value);
    } else if (!$event.checked) {
      const index = this.newUser.available_maps.findIndex((item: number) => parseInt(item.toString()) == parseInt(value.toString()));
      if (index !== -1) {
        this.newUser.available_maps.splice(index, 1);
      }
    }
  }
  adminUser($event: any, isUpdate: boolean) {
    $event.preventDefault();
    let error = "";
    let validatePassword: boolean = !isUpdate || (isUpdate && typeof this.newUser.is_update_password != "undefined" && this.newUser.is_update_password);
    this.newUser.email = this.newUser.email.trim();
    if (this.newUser.email.trim() == "")
      error = "Falta ingresar el email";
    else if (validatePassword && this.newUser.password?.trim() == "")
      error = "Falta ingresar contraseña";
    else if (validatePassword && this.newUser.password_confirmation?.trim() == "")
      error = "Falta que confirme la contraseña";
    else if (validatePassword && this.newUser.password_confirmation != this.newUser.password)
      error = "Las contraseñas no coinciden";
    if (error != "") {
      this.utils.showMsg("", error, false);
      this.utils.hideLoading(() => this.loading = false);
      return;
    }
    this.utils.showConfirm("Confirmar accion", "Está seguro de " + (isUpdate ? "actualizar" : "crear") + " a este usuario", (isUpdate ? "actualizar" : "crear"), "Cancelar").then((data: any) => {
      this.showUserLoading(isUpdate);
      //formData a enviar A INTGPS
      let formData: any = new FormData();
      formData.append("user_api_hash", this.user.hash);
      formData.set("group_id", 2);
      formData.append("manager_id", this.user.id);
      formData.append("email", this.newUser.email);
      formData.append("phone_number", this.newUser.phone_number);

      formData.append("available_maps", "");
      let mapsToReview:number[] = [...(this.newUser.available_maps??[]), ...this.availableMaps ];
      
      for (let map of this.newUser.available_maps) {
        if (mapsToReview.some((mapId: number) => Number(map) == Number(mapId)))
          formData.append("available_maps[]", map);
      }
      if (validatePassword) {
        formData.append("password", this.newUser.password);
        formData.append("password_confirmation", this.newUser.password_confirmation);
      }
      let namePermission: any, valuesPermission: any;
      for ([namePermission, valuesPermission] of Object.entries(this.newUser.permissions_to_update)) {
        formData.append("perms[" + namePermission + "]" + "[view]", valuesPermission.view ? 1 : 0);
        formData.append("perms[" + namePermission + "]" + "[edit]", valuesPermission.edit ? 1 : 0);
        formData.append("perms[" + namePermission + "]" + "[remove]", valuesPermission.remove ? 1 : 0);
      }

      //datos a enviar a API SGC para actualizar usuario
      let userData = {
        user_api_hash: formData.get("user_api_hash"),
        action: isUpdate ? "update" : "add",
        personalized_plan: this.newUser.personalized_plan,
        plan_type: isUpdate ? "personalized" : this.newUser.plan_type,
        manager_id: isUpdate ? this.newUser.manager_id : this.user.id,
        email: this.newUser.email.toLowerCase(),
        document_number: this.newUser.document_number,
        phone_prefix: this.newUser.phone_prefix,
        name: this.newUser.name,
        role_id: isUpdate ? this.newUser.group_id : formData.get("group_id"),
        id: isUpdate ? this.newUser.id : null,
        personalized_plan_id: this.newUser.personalized_plan_id ?? null,
        country_id: this.newUser.country_id,
        city_id: this.newUser.city_id,
        contact_name: this.newUser.contact_name,
        courtesy_title: this.newUser.courtesy_title,
        legal_personality_type: this.newUser.legal_personality_type,
        identification_document_type_id: this.newUser.identification_document_type_id,
        address: this.newUser.address,
        user_setting:{invoice_due_time_quantity: this.newUser.user_setting?.invoice_due_time_quantity, invoice_due_time_unit: this.newUser.user_setting?.invoice_due_time_unit}
      };
      if (isUpdate) {//es actualizar
        formData.set("group_id", this.newUser.group_id);
        formData.set("id", this.newUser.id);
        formData.set("_method", "PUT");
        formData.set("active", this.newUser.active);
        formData.set("billing_plan_id", this.newUser.billing_plan_id);
        formData.set("email", this.newUser.email);
        formData.set("map_id", this.newUser.map_id);
        if (!this.validationService.isNullOrEmpty(this.newUser.subscription_expiration) &&  this.newUser.subscription_expiration != "0000-00-00 00:00:00") {
          formData.set("enable_expiration_date", 1);
          formData.set("expiration_date", this.newUser.subscription_expiration);
        }

        formData.set("week_start_day", this.newUser.week_start_day);
        formData.set("top_toolbar_open", this.newUser.top_toolbar_open);
        formData.set("unit_of_altitude", this.newUser.unit_of_altitude);
        formData.set("lang", this.newUser.lang);
        formData.set("unit_of_distance", this.newUser.unit_of_distance);
        formData.set("unit_of_capacity", this.newUser.unit_of_capacity);
        formData.set("timezone_id", this.newUser.timezone_id);
        formData.set("sms_gateway", this.newUser.sms_gateway);
        formData.set("sms_gateway_url", this.newUser.sms_gateway_url);

        if (this.newUser.devices_limit == null) {
          formData.set("devices_limit", 0);
        } else {
          formData.set("enable_devices_limit", 1);
          formData.set("devices_limit", this.newUser.devices_limit);
        }
        this.api.createData(formData, "api/admin/client", false).then((data: any) => {
          if (data.status == 1) {
            this.api.createData(userData, "user", true).then((data: any) => {
              if (data.status == 1) {
                this.closeModal();
                this.utils.showMsg("", "Usuario actualizado con exito");

              } else {
                this.utils.showMsg("", "No se pudo actualizar al usuario en SGC");
              }
              this.getList();
            }).catch((err: any) => {
              this.utils.hideLoading(() => this.loading = false);
              this.utils.showMsg("", typeof err.error.message != "undefined" ? err.error.message : "Se ha presentado un error al actualizar al usuario en SGC, contacte al administrador");
            });
          }
        }).catch((err: any) => {
          this.utils.hideLoading(() => this.loading = false);
          this.utils.showMsg("", typeof err.error.message != "undefined" ? err.error.message : "No se pudo realizar la actualización en INTGPS<br><small>intente nuevamente. En caso de persistir el error contacte al administrador</small>");
        });

      } else {//es crear
        this.api.createData(formData, "api/admin/client", false).then((data: any) => {
          if (data.status > 0) {
            this.api.createData(userData, "user", true).then((data: any) => {
              if (data.status == 1) {
                this.utils.showMsg("", data.message);
                this.getList();
                this.closeModal();
              } else {
                this.utils.showMsg("", "Usuario creado.<br> " + "<small>" + data.message + "</small>");
              }
            }).catch((err: any) => {
              this.utils.hideLoading(() => this.loading = false);
              this.utils.showMsg("", err.error.message);
            });

          }
        }).catch((err: any) => {
          this.utils.hideLoading(() => this.loading = false);
          this.utils.showMsg("", typeof err.error.message != "undefined" ? err.error.message : "Error al registrar al usuario, <small>intente nuevamente. En caso de persistir el error contacte al administrador</small>");
        });
      }

    }).catch((error: any) => {
      return;
    });

  }
  getAutomationsList(){
    this.loading = true;
    this.automationsAssociatedWithUserToEdit = [];
    let data:string =  this.api.getCommonQueryParameters("get",this.user.hash);
    if(this.newUser.id>0)
      data += "&apply_filter_active=1&active_value=1&user_target_id="+this.newUser.id;
    this.api.getData(ApiEndpointSgc.AUTOMATION,data,true).then((data: unknown)=>{
      if(this.validationService.isResponseApi(data) && data.status == 1)
        this.automationsList = data.data as IAutomationAssociatedWithAutomationTargetUser[];   
    }).catch((error:unknown)=>{
      this.utils.showResultRequest("error","Información",this.api.getDefaultMessage("la lista de automatizaciones",false,false,"GET"));
    }).finally(()=>this.utils.hideLoading(()=>this.loading = false));
  }
  async updateUserAssociationWithAutomation(automation: IAutomationAssociatedWithAutomationTargetUser){
      let dataToSend: IApiRequestData & {active:1|0} = {
        action: automation.user_id ==this.newUser.id ? "update" : "add",
        user_api_hash: this.user.hash,
        user_id: this.newUser.id,
        automation_id: automation.id,
        active: automation.active_user == 1 ? 0 : 1
      };
      this.api.createData(dataToSend, ApiEndpointSgc.AUTOMATION_TARGET_USER,true).then((response: unknown) => {
        if(this.validationService.isResponseApi(response)){
          automation.active_user = dataToSend.active;
          this.messageBox.openSnackBar( response.status == 1 ? "asociación actualizada" : "No se pudo actualizar la asociación","Aceptar");
          response.status != 1 ? this.getAutomationsList() : null; 
      }
      }).catch((error: unknown) => {
        this.getAutomationsList();
        this.utils.showResultRequest("error","Información",this.api.getDefaultMessage("la asociación de usuario con la automatización",true, false,RequestMethodHTTP.POST));
      }).finally(()=>this.utils.hideLoading(()=>this.loading = false));
    
  }
  userIsSubscribedToAutomation(automationId: number){
    return this.automationsAssociatedWithUserToEdit.some((associatedAutomation: IAutomationTargetUser) => associatedAutomation.automation_id == automationId && associatedAutomation.active == 1);
  }



  intervalChangeExpirationTimeAmount(increment:boolean){
    this.timerIdInvoiceDueTimeQuantity = setInterval(()=>{
      this.changeExpirationTimeAmount(increment);
    },100);
  }
  clearIntervalChangeExpirationTimeAmount(){
    clearInterval(this.timerIdInvoiceDueTimeQuantity!);
  }
  changeExpirationTimeAmount(increment:boolean){
    this.newUser.user_setting!.invoice_due_time_quantity = this.newUser.user_setting?.invoice_due_time_quantity == null || isNaN(this.newUser.user_setting?.invoice_due_time_quantity) ? 0 : this.newUser.user_setting?.invoice_due_time_quantity;
    if(!increment)
      this.newUser.user_setting!.invoice_due_time_quantity = (this.newUser.user_setting!.invoice_due_time_quantity < 2 ) ? 0 : (--this.newUser.user_setting!.invoice_due_time_quantity);
    else
      ++this.newUser.user_setting!.invoice_due_time_quantity;
  }
  showUserLoading(update: boolean = false) {
    Swal.fire({
      title: !update ? 'Creando usuario...' : 'Actualizando usuario...',
      html: '<i class="fa-solid fa-user-plus fa-2x fa-beat-fade"></i>',
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      showCancelButton: false,
      showConfirmButton: false,
    });
  }
  exportFile() {
    let dataSgc:IApiRequestData = this.api.structureParametersForConsult(RequestMethodHTTP.POST,"get",this.user.hash,this.searchText,undefined,undefined,this.sort,undefined,["email","name","document_number","phone_number"]) as IApiRequestData;
    this.utils.processFileDownload("users_",
    async ()=>{
     let data: Partial<IUserGeneral>[] = [];
     try {
       let response:unknown = await this.api.getDataPost("user",dataSgc,true);
       if(this.validationService.isResponseApi(response))
         data = response.status == 1 ? response.data : [];
     } catch (error:unknown) { 
       this.loading = false;
       this.utils.showResultRequest("error","Información",this.api.getDefaultMessage("la lista de usuarios",false,false,"GET"));
     }
     return {data:data,headers:this.headers};
   },
   (response:boolean)=>this.loading = response);
  }
  closeModal() {
    this.utils.setSelectedView(this.viewsAvailable, this.viewsAvailable['user_list'].name);
    this.isUpdate = false;
    this.newUser = this.objectInitialization.initializeIUserGeneral(this.user.id,this.availableMaps,DevicePlanType.PERSONALIZED, this.newUser.id);
    this.searchText = "";
  }
  validateComponentToShow(event: MatTabChangeEvent) {
    if (event.index == this.viewsAvailable['user_notes'].index_in_tab)
      this.utils.setSelectedView(this.viewsAvailable, this.viewsAvailable['user_notes'].name);
  }
}