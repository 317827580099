import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { ApiService } from 'src/app/core/api/api.service';
import { DataService } from 'src/app/core/data/data.service';
import { UtilsService } from 'src/app/core/utils/utils.service';

@Component({
  selector: 'app-logs-pegaso',
  templateUrl: './logs-pegaso.component.html',
  styleUrls: ['./logs-pegaso.component.scss']
})
export class LogsPegasoComponent {
  @Output() closeModal = new EventEmitter<boolean>();

  user:any=[];

  searchTimer:any;
  displayedColumns: string[] = ['device_imei', 'device_plate_number', 'alert_name_convention', 'created_at','device_time',"result"];
  /**el valor a buscar dentro de la lista de registros */
  searchValue="";
  //para paginacion
  lengthPage=0;
  pageSize=20;
  pageSizeOptions:any=[20];
  pageEvent:any;
  currentPage=0;
  loading:boolean=false;
  /**indica los datos para el ordenamiento de la tabla */
  sort={
    by:"",
    direction:""
  };
  devices:any = [];
  constructor(
    private dataService:DataService,
    public utils:UtilsService,
    private api:ApiService,
  ){}
  ngOnInit(): void {
    this.user= this.dataService.getData("user");
    this.getLogs();
  }
  getLogs(){
    let data="?action=getLogs&user_api_hash="+this.user.hash+"&page="+(this.currentPage);
    if(this.sort.by!="")
      data +="&sort_by="+this.sort.by+"&sort_direction="+this.sort.direction;
    if(this.searchValue !="")
      data +="&search_value="+this.searchValue;
    this.api.getData("pegasoLog",data,true).then((data: any) => {
      this.devices = data.status==1?data.data:[];
      this.lengthPage =data.status==1?data.total:0;
    
    this.utils.hideLoading((response:boolean)=>this.loading=response); 
    }).catch((error:any)=>{
      this.devices = [];
      this.lengthPage = 0;
      this.utils.showMsg("","No se han podido obtener la lista de registros");
      this.utils.hideLoading((response:boolean)=>this.loading=response); 
    });
  }
  searchValueInData(){
    clearTimeout(this.searchTimer);
    this.searchTimer = setTimeout(() => {
      this.currentPage=0;
      this.getLogs();
    }, 800);
  }
  sortData(sort: Sort) {
    this.sort.direction=sort.direction;
    this.sort.by=sort.active;
    this.getLogs();
  }
  paginator($event:any){
    this.currentPage = $event.pageIndex;  
    this.pageSize = $event.pageSize;
    this.getLogs();
  }
  close() {
    this.closeModal.emit(true);
  }
}