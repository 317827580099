import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { ApiService } from 'src/app/core/api/api.service';
import { DataService } from 'src/app/core/data/data.service';
import { ObjectInitializationService } from 'src/app/core/object-initialization/object-initialization.service';
import { UtilsService } from 'src/app/core/utils/utils.service';
import { ValidationService } from 'src/app/core/validation/validation.service';
import { IDeviceBasicData } from 'src/app/shared/models/interfaces/idevice.model';
import { IUserSessionData } from 'src/app/shared/models/interfaces/iuser-session-data.model';
import { UserDriverDataService } from 'src/app/user-driver/core/user-driver-data/user-driver-data.service';

@Component({
  selector: 'app-driver-device-selector',
  templateUrl: './driver-device-selector.component.html',
  styleUrls: ['./driver-device-selector.component.scss']
})
export class DriverDeviceSelectorComponent implements OnInit{

  user!:IUserSessionData;
  /** @type {IDeviceBasicData[]} Lista de dispositivos disponibles pora el usuario. Aquellos que han sido asignados a su lista de disponibles */
  availableDevicesList:IDeviceBasicData[] = [];
  availableDeviceListFiltered:IDeviceBasicData[] = [];

  /** @type {NodeJS.Timeout} Almacena el timeout usado para filtro de busqueda de dispositivo a ocupar */
  timerIdSearchDevice!:NodeJS.Timeout;
  @Input() currentDrivenVehicle:IDeviceBasicData = this.objectInitializationService.initializeIDeviceBasicData();
  
  /** @type {boolean} Indica si desde el  componente se debe de buscar el dispositivo actual del conductor */
  @Input() searchCurrentDevice:boolean = true;
  /**
   * @type {EventEmitter <IDeviceBasicData>} Retorna el dispositivo actual que tenga el conductor. Lo emite cuando se obtiene por primera vez dicho dato y cuando este es cambiado
   */
  @Output() currentDeviceHasBeenChanged = new EventEmitter<IDeviceBasicData>();
  dataToSearch:string = "";
  constructor(
    public utils: UtilsService,
    private dataService: DataService,
    private api: ApiService,
    public validationService: ValidationService,
    private objectInitializationService: ObjectInitializationService,
    private userDriverDataService:UserDriverDataService
  ){
    this.user = this.dataService.getData("user");
  }
  ngOnInit(): void {
    this.getAvailableDevicesBasicDataList();
    if(this.searchCurrentDevice)
      this.getDrivenVehicle();
  }
  async getAvailableDevicesBasicDataList():Promise<void>{
    let response:unknown = await this.dataService.getAvailableDevicesBasicDataList();
    if(this.validationService.isResponseApi(response)){
      this.availableDevicesList = response.data  as IDeviceBasicData[];
      this.availableDeviceListFiltered = this.utils.copyObject(this.availableDevicesList);
    }
  }

    /**
   * @description Cambia el conductor actual del dispositivo 
   * @param {MatSelectChange} event Datos del evento del Select para la obtención del dispositivo seleccionado
   * @returns {void}
   */
    changeAvailability(event:MatSelectChange){
      let isCurrentDrivenDevicelEmpty:boolean = this.utils.isEmptyObject(this.currentDrivenVehicle);
      let errorMessage:string ="";
      if(!isCurrentDrivenDevicelEmpty && this.currentDrivenVehicle.id != event.value)
        errorMessage = "Ya cuentas con un dispositivo ocupado";
      else{
        let device:IDeviceBasicData | undefined = this.availableDevicesList.find((item:IDeviceBasicData)=>item.id == event.value);
        if(typeof device !="undefined" && device.current_driver_id != null && device.current_driver_id != this.user.id)
          errorMessage = "El dispositivo está siendo ocupado por otro conductor";
      }
      if(errorMessage != ""){
        this.utils.showResultRequest("info",errorMessage);
        event.source.value = this.currentDrivenVehicle.id;
        return;
      }
      this.utils.showConfirm(isCurrentDrivenDevicelEmpty?"¿Ocupar dispositivo?":(this.currentDrivenVehicle.id == event.value ? ("Liberar dispositivo?") : ""), "", "Confirmar", "Cancelar").then(() => {  
        let deviceData={
          user_api_hash:this.user.hash,
          id:event.value,
          action:"updateCurrentDriver"
        };
        this.api.createData(deviceData, "device",true).then((data: any) => {
          this.utils.showResultRequest(data.status==1?"success":"error","Información",data.message);
          if(data.status==1){
            this.getDrivenVehicle();
          }else
            event.source.value = this.currentDrivenVehicle.id;
        }).catch((err: any) => {
          this.utils.showResultRequest("error","Error","Se ha presentado un error al actualizar el estado");
        });
      }).catch(()=>{
        event.source.value = this.currentDrivenVehicle.id;
      });
    }
    clearSearchDevice(){
      this.dataToSearch = "";
      this.searchDevice();  
    }
    searchDevice(){
      this.dataToSearch = this.dataToSearch.toLowerCase();
      clearTimeout(this.timerIdSearchDevice);
      this.timerIdSearchDevice = setTimeout(() => {
        let FilteredList:IDeviceBasicData[] = !this.validationService.isNullOrEmpty(this.dataToSearch) && this.dataToSearch.length > 0? this.availableDevicesList.filter(( device: IDeviceBasicData ) => (device.plate_number??"").includes(this.dataToSearch) || device.name.toLowerCase().includes(this.dataToSearch)):this.availableDevicesList;
        this.availableDeviceListFiltered = this.utils.copyObject(FilteredList) as IDeviceBasicData[];
      }, 500);
    }
    async getDrivenVehicle(){
      let response:unknown = await this.userDriverDataService.getDrivenVehicle(this.user.hash);
      if(this.validationService.isResponseApi(response))
        this.currentDrivenVehicle = response.status == 1 ? response.data as IDeviceBasicData : this.objectInitializationService.initializeIDeviceBasicData();
      else
        this.currentDrivenVehicle = this.objectInitializationService.initializeIDeviceBasicData();
      this.currentDeviceHasBeenChanged.emit(this.currentDrivenVehicle);
    }
}