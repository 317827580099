import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IDeviceChecklistAnswered } from '../../models/interfaces/idevice-checklist.model';
import { DataService } from 'src/app/core/data/data.service';
import { ValidationService } from 'src/app/core/validation/validation.service';
import { UtilsService } from 'src/app/core/utils/utils.service';
import { DeviceChecklistDataService } from 'src/app/core/device-checklist-data/device-checklist-data.service';
import { ApiService } from 'src/app/core/api/api.service';
import { Router } from '@angular/router';
import { IUserSessionData } from '../../models/interfaces/iuser-session-data.model';
import { PageEvent } from '@angular/material/paginator';
import { IApiRequestData, ISort } from '../../models/interfaces/iapi-request-data.model';
import { Sort } from '@angular/material/sort';
import { ObjectInitializationService } from '../../../core/object-initialization/object-initialization.service';
import { DeviceChecklistAnsweredAvailableFilterFields } from './device-checklist-answered.enum';
import { UserType } from '../../types/common-types.type';
import { ROUTES_TO_LOGIN } from 'src/app/core/auth/auth.service';
import { IRouteConstant, RouteConstant } from 'src/app/core/constant/RouteConstant';

@Component({
  selector: 'app-device-checklist-answered-history',
  templateUrl: './device-checklist-answered-history.component.html',
  styleUrls: ['./device-checklist-answered-history.component.scss']
})
export class DeviceChecklistAnsweredHistoryComponent implements OnInit{

  user!:IUserSessionData;
  /** @type {UserType} Almacena el tipo de usuario que esta logueado */
  userType:UserType | null;
  /** @type {boolean} Indica la carga de datos de forma general */
  loading:boolean = false;
  /** * @type {DeviceChecklistAnsweredAvailableFilterFields | null} Indica el campo por el cual se desea filtrar los datos */
  @Input() searchByField: DeviceChecklistAnsweredAvailableFilterFields | null = null;
  /** @type {number | null} El valor a buscar cuando la variable miembro searchByField tiene un valor asignado */
  @Input() fieldValue: number | null = null;
  /**
   * @type {IRouteConstant} la ruta a donde retornar cuando el usuario da click en retornar en la visualizacion del formulario 
   *  */ 
  @Input() urlToReturn: IRouteConstant | null = null;
  /***
   * @type {boolean} Indica si se va a mostrar el encabezado de la plantilla del componente
   */
  @Input() showOnlyTable:boolean = false;
  /***
   * @type {boolean} Indica si se se muestra el titulo de la tabla
   */
  @Input() showTableTitle:boolean = false;
  
  @Output() close = new EventEmitter<boolean>();
  /**
   * @type { boolean } Indica si la solicitud de datos de historial ya ha sido completada
   */
  @Output() dataRequestCompleted = new EventEmitter<boolean>();

  /**
   * @type {IDeviceChecklistAnswered} La lista de checklist resueltos
   */
  deviceChecklistAnswerdList: IDeviceChecklistAnswered[] = [];
  displayedColumns:string[] =["id","created_at","device_plate_number","device_checklist_name","actions"];

  //para paginacion
  lengthPage:number = 0;
  pageSize!:number;
  pageSizeOptions:number[] = [];
  pageEvent!:PageEvent;
  currentPage = 0;
  sort: ISort;
  constructor(
    private router: Router,
    public dataService: DataService,
    public validationService:ValidationService,
    public utils: UtilsService,
    private deviceChecklistDataService:DeviceChecklistDataService,
    private api:ApiService,
    private objectInitializationService: ObjectInitializationService
  ){
    this.pageSizeOptions = this.dataService.PAGE_SIZE_OPTION;
    this.pageSize = this.pageSizeOptions[0];
    this.user = this.dataService.getData("user");
    this.sort = this.objectInitializationService.initializeISort();
    this.userType = this.validationService.isNullOrEmpty(this.user.special_user_type??null) ? this.dataService.USER_TYPE.user : (this.user.special_user_type == this.dataService.SPECIAL_USER.user_driver ? this.dataService.USER_TYPE.user_driver : null);
    if(this.userType == null)
      this.router.navigateByUrl(ROUTES_TO_LOGIN.user);
    if(this.userType != this.dataService.USER_TYPE.user_driver){
      let index:number | undefined = this.displayedColumns.findIndex((item:string)=>item == "device_plate_number");
      this.displayedColumns.splice(typeof index != "undefined" ?index : 1,0,"driver_name");
    }
  }
  ngOnInit(): void {
    this.deviceChecklistDataService.setUrlToReturn(this.urlToReturn);
    this.getDeviceChecklistAnswered();
  }
  /**
   * @description Permite el acceso a vsualizacion de detalles de checklist resuelto
   * @param checklistAnswered El checklist respondido objetivo
   */
  viewDeviceChecklistDetails(checklistAnswered: IDeviceChecklistAnswered){
    this.deviceChecklistDataService.setUrlToReturn(this.urlToReturn); 
    this.router.navigate([RouteConstant.DEVICE_CHECKLIST_VIEWER.url.split(":")[0]+checklistAnswered.device_checklist_id+"/"+checklistAnswered.id]);
  }
  sortData(sort: Sort) {
    this.sort.direction = sort.direction;
    this.sort.by= sort.active;
    this.getDeviceChecklistAnswered();
  }
  getDeviceChecklistAnswered(){
    let data: IApiRequestData = {user_api_hash: this.user.hash,action:"get",page:this.currentPage,page_size:this.pageSize, order:{by:this.sort.by,direction:this.sort.direction}};
    if(this.searchByField != null)
      data[this.searchByField] = this.fieldValue;
    this.loading = true;
    this.api.getDataPost("deviceChecklistAnswered",data,true).then((response: unknown) => {
      if(this.validationService.isResponseApi(response)){
        this.lengthPage = response.status == 1 ? (response.total??0) : 0;
        this.deviceChecklistAnswerdList = response.status == 1 ? response.data as IDeviceChecklistAnswered[] :  [];
        //if(response.status != 1)
          //this.utils.showResultRequest("error","Información", response.message??"Error al cargar los datos de historial de respuestas de checklist");
      }else{
        this.lengthPage = 0;
        this.deviceChecklistAnswerdList = [];
      }
    }).catch((error:any)=>{
      this.lengthPage = 0;
      this.deviceChecklistAnswerdList = [];
      this.utils.showResultRequest("error","Información",this.api.getDefaultMessage("historial de respuestas de checklist",false,false,"GET"));
    }).finally(() =>{
      this.utils.hideLoading(()=>this.loading = false);
      this.dataRequestCompleted.emit(true);
    });
  }
  paginator(event:PageEvent){
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;    
    this.getDeviceChecklistAnswered();
  }
  closeComponent(){
    this.close.emit(true);
  }
}
