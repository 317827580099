
<h2 mat-dialog-title>Lista de próximas facturas <small>(próximas 6)</small></h2>
<mat-dialog-content class="mat-typography position-relative ">
    <div class="container position-relative  animate__animated animate__fadeIn" >
        <div class=" overflow-auto position-relative">
            <div class="container-table overflow-auto">
                <table class="w-100 h-100">
                    <thead class="thead position-sticky z-index-1 top-0 bg-white ">
                        <tr class="tr">
                            <th class="th">N°</th>
                            <th class="th">Cliente</th>
                            <th class="th">Email del cliente</th>
                            <th class="th">Fecha de registro</th>
                            <th class="th">Observación</th>
                            <th class="th">Precio</th>
                            <th class="th">Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="tr" *ngFor="let invoice of upcomingInvoicesList;index as counter">
                            <td class="td text-start">{{counter+1}}</td>
                            <td class="td text-start">{{invoice.buyer_name}}</td>
                            <td class="td text-start">{{invoice.buyer_email}}</td>
                            <td class="td text-start">{{invoice.created_at |date:'mediumDate'}}</td>
                            <td class="td text-start">{{invoice.observation}}</td>
                            <td class="td text-start">$ {{invoice.details.price | currency:invoice.details.monetary_unit_code}}</td>
                            <td class="td actions">
                                <button matTooltip="Generar factura" (click)="createInvoice(invoice)" matTooltipPosition="left" matTooltip="Registrar factura de forma anticipada"><i class="fa-solid fa-receipt"></i></button>                                
                            </td>
                        </tr>
                    </tbody>
                    
                </table>
            </div>
        </div>
        <div class="summary-total-projection">
            <div class="label">Total próximos  <input type="number" [(ngModel)]="totalToProject" min="1" max="6"(input)="calculateProjectionPrice()" step="1"> periodos</div>
            <span class="total">{{totalProjectionPrice}}</span>
        </div>
    </div>
    <div class="loader" *ngIf="loading">
        <img src="../../../assets/loader.svg">
    </div>
    <br>
    <small>Proyecciones realizadas para el dispositivo '{{device.name}}' con <strong> imei: {{device.imei}}</strong></small>
    <small> Los calculos de estas proyecciones son realizadas teniendo en cuenta los datos proporcionados en la definición del <strong>plan para este dispositivo.</strong></small>
    <small> Si confirma la creacion de alguna de estas proyecciones, todas las anteriores a ella también serán registradas y estarán disponibles en el módulo <strong>Mi Cartera</strong> para las mismas fechas indicadas en cada proyección.</small>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]="false"  class="btn btn-outline-secondary" style="margin-right: 0.2rem;">Cerrar</button>
</mat-dialog-actions>