import { Component, OnInit } from '@angular/core';
import { UtilsService } from 'src/app/core/utils/utils.service';
import { ApiService } from 'src/app/core/api/api.service';
import { DataService } from 'src/app/core/data/data.service';
import {MatDialog} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { IViewsAvailable } from 'src/app/shared/models/interfaces/views-available';

const date =new Date();

@Component({
  selector: 'app-billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.scss']
})
export class BillingComponent implements OnInit {

  viewsAvailable:IViewsAvailable = {
    binding_list: {name:"binding_list", show_view:true},
    renovations_modal: {name:"renovations_modal", show_view:false}
  };

  user:any=[];//alamcena los datosdel usuario logeado

  moduleId:number=2;
  permissionsDataSgc:any=[];//permisos sobre el modulo
  constructor(
    public utils:UtilsService,
    private api: ApiService,
    public dataService: DataService,
    private router:Router,
    public userDevicesdialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.dataService.checkPermissionModule(this.moduleId).then((permissions: any) => {
      this.permissionsDataSgc=permissions;
      this.user = this.dataService.getData("user");
    }).catch(() => {
      this.utils.showMsg("Página no autorizada","No tiene permisos para ver esta página, contacte al administrador");
      this.router.navigate(['/']);
    });
  }
  openRenovationsModal(startDate?:any,endDate?:any){
    this.utils.setSelectedView(this.viewsAvailable,this.viewsAvailable['renovations_modal'].name);
  }

  closeModal() { 
    this.utils.setSelectedView(this.viewsAvailable,this.viewsAvailable['binding_list'].name);
  }  
}