import { NgModule,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DriverDeviceStateManagementComponent } from './views/driver-device-state-management/driver-device-state-management.component';
import { DriverDeviceChecklistManagementComponent } from './views/driver-device-checklist-management/driver-device-checklist-management.component';
import { UserDriverRoutingModule } from "./user-driver-routing.module";
import { UserDriverComponent } from './user-driver.component';
import { AngularMaterialModule } from "../angular-material.module";
import { ReactiveFormsModule,FormsModule } from '@angular/forms';
import { DriverDeviceSelectorComponent } from './shared/components/driver-device-selector/driver-device-selector.component';

/** Derivados de angular */
import {
  NgxMatDatetimePickerModule, 
  NgxMatNativeDateModule, 
  NgxMatTimepickerModule 
} from '@angular-material-components/datetime-picker';
import { DeviceTripComponent } from './views/driver-device-state-management/device-trip/device-trip.component';
import { SharedModule } from '../shared/shared.module';
import { UserDriverDataService } from './core/user-driver-data/user-driver-data.service';

@NgModule({
  declarations: [
    DriverDeviceStateManagementComponent,
    DriverDeviceChecklistManagementComponent,
    UserDriverComponent,
    DeviceTripComponent,
    DriverDeviceSelectorComponent
  ],
  imports: [
    CommonModule,
    UserDriverRoutingModule,
    AngularMaterialModule,
    FormsModule,
    SharedModule,
    //CommonModule,
    ReactiveFormsModule,

    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatNativeDateModule,
    
  ],
  schemas:[CUSTOM_ELEMENTS_SCHEMA],
  exports:[],
  providers:[UserDriverDataService]
})
export class UserDriverModule { }
